import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import numeral from "numeral";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(3),
    },
    chipRoot: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    list: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: "inherit",
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0,
    },
});

function LFDialogHeader(props) {
    const {children, classes, selectedAutoMatchings, selectedIncome, avalableAmount, ...other} = props;

    return (
        <>
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <div className="flex flex-row justify-between items-center">
                    <Typography className="inline-flex" variant="subtitle2">
                        {<Translate id={"income.Distribute to selected orders"} />}
                        <strong className={"ml-2"}>({selectedAutoMatchings.length})</strong>
                    </Typography>
                    <Typography className="inline-flex" variant="subtitle2">
                        {<Translate id={"income.Distributable amount: "} />}
                        <strong className={"ml-2"}>￥{numeral(avalableAmount).format("0,0.00")}</strong>
                    </Typography>
                </div>
            </MuiDialogTitle>
        </>
    );
}

export default withStyles(styles)(LFDialogHeader);
