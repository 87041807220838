import {useMUIDialog} from "../../@fuse/hooks";
import {GDialogRegistries} from "../../@fuse/state/constant";
import NewMatchingOrderDialog from "../main/incomes/dialogs/NewMatchingOrderDialog";

function IncomeModuleCom() {
    const matchingOrderActions = useMUIDialog({
        registry: GDialogRegistries.matchingOrder,
    });
    return <>{matchingOrderActions?.open && <NewMatchingOrderDialog />}</>;
}

export default IncomeModuleCom;
