import {lazy} from "react";

export const ConfigBCMProjectNewTab = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                newFooter: false,
                rightSidePanel: {
                    display: true,
                },
                leftSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {
            path: "/bcm/project/:projectId/quotation/:quotationId",
            component: lazy(() => import("./single/quotation/QuotationTable")),
        },
        {
            path: "/bcm/project/:projectId/quotation",
            component: lazy(() => import("./single/quotation/QuotationTable")),
        },
        {
            path: "/bcm/project/:projectId/budgets/:quotationId",
            component: lazy(() => import("./single/quotation/BudgetTable")),
        },
        {
            path: "/bcm/project/:projectId/budgets",
            component: lazy(() => import("./single/quotation/BudgetTable")),
        },
    ],
};
