import {combineReducers} from "redux";
import incomeRecongnitionReducer from "./incomeRecognition.reducers";
import myincomeReducer from "./myIncome.reducers";
import incomeSureManagerReducer from "./incomeSureManager.reducers";
import incomeOrdersDistrubuteReducer from "./incomeOrdersDistribution.reducers";
import accountFilterReducer from "./accountFilter.reducer";

const incomeModuleReducer = combineReducers({
    incomeRecongnitionReducer,
    myincomeReducer,
    incomeSureManagerReducer,
    incomeOrdersDistrubuteReducer,
    accountFilterReducer,
});

export default incomeModuleReducer;
