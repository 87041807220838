import {Dialog} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {useMemoizedFn} from "ahooks";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useMUIDialog, useShowMessage} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useDefinitionStore} from "../definitions/state/useDefinitionStore";

function NewEditDefinitionDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.createInstance
    });

    const {showMessage} = useShowMessage();

    const [loading, setLoading] = useState(false);

    const [category, setCategory] = useState("");

    const currentDefinition = useDefinitionStore((state) => state.currentDefinition);

    const handleChange = useMemoizedFn((e) => {
        setCategory(e.target.value);
    });

    const handleCloseDialog = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleUpload = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        const params = {
            id: currentDefinition?.id,
            category: category
        };
        axios
        .put(`${flowPrefix}/processDefinition`, params)
        .then(() => {
            finshParams?.onFinish();
            const params = {
                variant: "success",
                message: "message.Definition edit successfully!"
            };
            showMessage(params);
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    });

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <DialogTitle>
                    <Typography variant="h6" className={"ml-20 mt-20"}>
                        <Translate id={"process.Edit definition"} />
                        <Chip label={currentDefinition?.name} className={"ml-1"} color={"secondary"} size={"small"} />
                    </Typography>
                </DialogTitle>

                <DialogContent className="flex flex-col">
                    <TextField
                        id="standard-category"
                        fullWidth
                        label={<Translate id={"process.category"} />}
                        value={category}
                        onChange={handleChange}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleCloseDialog}>
                        <Translate id="process.Cancel" />
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={category === "" || loading}
                        onClick={handleUpload}
                        color="secondary"

                    >
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewEditDefinitionDialog;
