import {showMyDialog, turnOn} from "@fuseActions";
import {FormControl, Grid, Icon, ListItem, ListItemText} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import orange from "@material-ui/core/colors/orange";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import {LocalTimeStamp, MyTooltip, NameLabel} from "@ui";
import _ from "lodash";
import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import Select2 from "react-select/async";
import EventBus, {
    UPDATE_COMPANY_SELECTOR_AFTER_CREATE_A_COMPANY_NOTIFI,
    UPDATE_CONTACT_SELECTOR_AFTER_CREATE_A_CONTACT_NOTIFI,
    UPDATE_DEAL_SELECTOR_AFTER_CREATE_A_DEAL_NOTIFI,
} from "utils/EventBus";
import {components} from "utils/Select2Components";
import axios, {crmPrefix} from "../../../myaxios";
import store from "../../store";
import {getList, selectType} from "./store/actions";

const ASYCN_UPDATE_GRID_LIST_NOTIFER_NAME = "ASYCN_UPDATE_GRID_LIST_NOTIFER";

export const isObjectArray = (targetArray) => {
    let tag = true;

    if (targetArray && targetArray.length > 0) {
        for (const t in targetArray) {
            if (_.isString(t)) {
                tag = false;
                break;
            }
        }
    }
    return tag;
};

export const haveDeleteAble = (item) => {
    return item.deleteDate && item.deleteDate !== 0 && item.deleteDate !== undefined && item.deleteDate !== null;
};

export const CreateAndActiveDateItem = ({sourceItem}) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={12}>
                <div className="flex flex-row justify-between px-4 my-2">
                    {sourceItem.createDate ? (
                        <div className="flex flex-col">
                            <Typography variant="caption" className={"flex w-full"}>
                                <LocalTimeStamp datetime={sourceItem.createDate} />
                            </Typography>
                            <span className="flex text-grey-dark">{<Translate id={"market.create date"} />}</span>
                        </div>
                    ) : (
                        ""
                    )}

                    {sourceItem.activeDate ? (
                        <div className="flex flex-col">
                            <Typography variant="caption" className={"flex w-full"}>
                                <LocalTimeStamp datetime={sourceItem.activeDate} />
                            </Typography>
                            <span className="flex text-grey-dark">{<Translate id={"market.Active date"} />}</span>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export const CreateDateItem = ({sourceItem}) => {
    return (
        <ListItem>
            <ListItemText primary={<LocalTimeStamp datetime={sourceItem.createDate} />} secondary={<Translate id={"market.create date"} />} />
        </ListItem>
    );
};

export const compareToNow = (date) => {
    const now = new Date();
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth() + 1;
    const nowDay = now.getDate();
    const nowTime = new Date(`${nowYear}-${nowMonth}-${nowDay}`).getTime();
    const otherDate = new Date(date);
    const otherYear = otherDate.getFullYear();
    const otherMonth = otherDate.getMonth() + 1;
    const otherDay = otherDate.getDate();
    const otherTime = new Date(`${otherYear}-${otherMonth}-${otherDay}`).getTime();
    if (otherTime === nowTime) {
        return "equal";
    } else if (otherTime > nowTime) {
        return "new";
    } else {
        return "old";
    }
};

//  marketing  Gridlist  各个 模块  的通知名字
export const getGridListAsycnNotificationName = (name) => {
    return `${ASYCN_UPDATE_GRID_LIST_NOTIFER_NAME} - ${name}`;
};

export const calTwoDates = (overDate) => {
    if (overDate < 1) {
        return 0;
    } else if (overDate >= 6 && overDate < 12) {
        return 6;
    } else if (overDate >= 3 && overDate < 6) {
        return 3;
    } else if (overDate >= 1 && overDate < 3) {
        return 1;
    } else {
        return 12;
    }
};

const mapDC = {
    0: "#38C172",
    1: "#B8C2CC",
    3: orange[500],
    6: pink[300],
    12: red[500],
};

export const ActiveDateChip = ({overDate}) => {
    return (
        <MyTooltip
            title={
                overDate === 0 ? (
                    <Translate id={"market.Active"} />
                ) : overDate === 1 ? (
                    <Translate id={"market.Not contacted for a month"} />
                ) : overDate === 3 ? (
                    <Translate id={"market.Not contacted for three months"} />
                ) : overDate === 6 ? (
                    <Translate id={"market.Not contacted for half a year"} />
                ) : (
                    <Translate id={"market.Not contacted for one year"} />
                )
            }>
            <Chip
                size={"small"}
                component={"span"}
                className={"mr-2"}
                style={{backgroundColor: mapDC[overDate], color: "white", fontWeight: "bold"}}
                label={overDate}
            />
        </MyTooltip>
    );
};

export const LabelChip = ({label}) => {
    return (
        <Chip
            size={"small"}
            component={"span"}
            className={"text-white font-bold mr-2 text-xs h-16"}
            style={{backgroundColor: label.color, color: "white", fontWeight: "bold"}}
            label={label.title}
        />
    );
};

export const deleteLabel = (id, source) => {
    const language = localStorage.getItem("i18nextLng");
    const tTtile = language === "en" ? "Do you want to delete this label?" : "确定要删除该标签吗?";
    const tConfirmButtonText = language === "en" ? "Delete" : "删除";
    const tSuccessM = language === "en" ? "Delete success!" : "删除成功!";
    const successFunc = (success) => {
        store.dispatch(getList({source}));
        if (success instanceof Function) {
            success();
        }
    };
    const request = (success, failed) => {
        axios
            .delete(`${crmPrefix}/labels/${id}`)
            .then((res) => {
                success();
            })
            .catch(() => {
                failed();
            });
    };
    store.dispatch(showMyDialog(tTtile, request, successFunc, tSuccessM, tConfirmButtonText));
    store.dispatch(turnOn("MyDialog"));
};

export const EnumFormControl = ({type, formData, name, handleFormData, required, deleteParam}) => {
    const [data, setData] = useState([]);
    // const [selected, setSelected] = useState("");

    const getEnumProperties = () => {
        axios
            .get(`${crmPrefix}/salesEnumProperties`, {
                params: {type},
            })
            .then((r) => {
                let data = r.data;
                if (deleteParam) data = r.data.filter((__) => __.key !== deleteParam);
                setData(data);
            });
    };

    useEffect(() => {
        getEnumProperties();
    }, []);

    // console.log(formData[name].key );

    return (
        <FormControl fullWidth>
            <InputLabel required={required} htmlFor="enum-simple-address">
                <Translate id={`market.${name}`} />
            </InputLabel>
            <Select
                value={_.isString(formData[name]) === true ? formData[name] : formData[name] ? formData[name].key : ""}
                onChange={handleFormData(name)}
                inputProps={{
                    name: "enumId",
                    required: required,
                    id: "enum-simple-address",
                }}
                className={"MuiInput-formControl"}>
                <MenuItem value="">
                    <em>{<Translate id={"market.None"} />}</em>
                </MenuItem>
                {data.map((__) => (
                    <MenuItem key={__.key} value={__.key}>
                        {__.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const LabelsFormControl = ({source, formData, handleFormData}) => {
    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();

    const data = useSelector(({marketing}) => {
        return isObjectArray(formData.labels) ? _.unionBy(marketing.labels.list, formData.labels, "id") : marketing.labels.list;
    });

    const handleChange = (event) => {
        if (event.target.value.includes("add")) {
            dispatch(selectType(source));
            dispatch(turnOn("marketingLabel"));
        } else {
            setSelected(event.target.value);
            handleFormData("labels")(event);
        }
    };

    useEffect(() => {
        dispatch(getList({source}));
        formData.labels && setSelected(formData.labels.map((__) => (_.has(__, "id") ? __.id : __)));
    }, []);

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="label-simple-address">
                <Translate id={`market.${source} labels`} />
            </InputLabel>
            <Select
                multiple
                value={selected}
                renderValue={(value) =>
                    value.map((id) => {
                        const l = data.find((l) => l.id === id);
                        return l && <LabelChip key={id} label={l} />;
                    })
                }
                onChange={handleChange}
                inputProps={{
                    name: "labelId",
                    id: "label-simple-address",
                }}
                className={"MuiInput-formControl"}>
                <MenuItem selected value="add">
                    <Icon className={"mr-8"}>add</Icon>
                    <em>
                        <Translate id={`market.Add new label for ${source}`} />
                    </em>
                </MenuItem>
                {data.map((__) => (
                    <MenuItem selected key={__.id} data={__} value={__.id}>
                        <LabelChip label={__} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const ContactInputItem = ({item}) => (
    <span className={"flex flex-row items-center"}>
        <span className={"mr-1"}>
            <NameLabel user={item} />
        </span>
        {item.company && (
            <MyTooltip title={<Translate id={"market.company"} />}>
                <small className={"mr-1 bg-grey-dark text-white rounded px-1"}>{item.company.name}</small>
            </MyTooltip>
        )}
        {item.decisionMaker && (
            <MyTooltip title={<Translate id={"market.is decisionMaker or not"} />}>
                <Icon fontSize={"inherit"}>error</Icon>
            </MyTooltip>
        )}
    </span>
);

export const ContactSelector = ({customClassName, formData, handleFormData, required}) => {
    const dispatch = useDispatch();
    const language = localStorage.getItem("i18nextLng");
    let selectRef = useRef(null);
    const changeContacts = (value) => {
        if (value) {
            if (value.findIndex((i) => i.value === "add") > -1) {
                dispatch(turnOn("contact"));
            } else {
                handleFormData("contactIds")({target: {value: value}});
            }
        } else {
            handleFormData("contactIds")({target: {value: ""}});
        }
    };

    useEffect(() => {
        EventBus.on(UPDATE_CONTACT_SELECTOR_AFTER_CREATE_A_CONTACT_NOTIFI, (data) => {
            refreshContact(data);
        });
        return function cleanup() {
            EventBus.remove(UPDATE_CONTACT_SELECTOR_AFTER_CREATE_A_CONTACT_NOTIFI);
        };
    }, []);

    const refreshContact = (data) => {
        if (data) {
            const temp = {value: data.id, label: <ContactInputItem item={data} />};
            const customDatas = [...formData.contactIds, temp];
            handleFormData("contactIds")({target: {value: customDatas}});
            selectRef.current.focus();
        }
    };
    const searchCompanyContacts = (inputValue, callback) => {
        axios
            .get(`${crmPrefix}/companyContacts?size=6`, {
                params: {
                    keyword: inputValue,
                },
            })
            .then((res) => {
                let data = res.data.content.map((item) => ({
                    value: item.id,
                    label: <ContactInputItem item={item} />,
                    data: item,
                }));
                const addElement = {
                    value: "add",
                    label: (
                        <span className={"text-green font-bold flex items-center"}>
                            <Icon className="mr-2">add</Icon>
                            {language === "cn" ? "创建客户联系人" : "Create a contact"}
                        </span>
                    ),
                };
                data = [addElement, ...data];
                callback(data);
            });
    };
    return (
        <Translate>
            {({translate}) => (
                <Select2
                    required
                    key={_.random(2, 99999999)}
                    ref={selectRef}
                    textFieldProps={{
                        required: required,
                        label: translate("market.contacts"),
                        InputLabelProps: {
                            shrink: true,
                        },
                        inputProps: {
                            style: {height: formData.contactIds && formData.contactIds.length > 1 ? "auto" : 32},
                        },
                    }}
                    closeMenuOnSelect={false}
                    loadOptions={searchCompanyContacts}
                    defaultOptions
                    isMulti
                    noOptionsMessage={() => translate("_.no option")}
                    className={customClassName ? customClassName : "overflow-visible w-full"}
                    // styles={selectStyles}
                    components={components}
                    value={formData.contactIds}
                    onChange={changeContacts}
                    placeholder={translate("market.Search contacts")}
                    isClearable
                />
            )}
        </Translate>
    );
};

ContactSelector.propTypes = {
    customClassName: PropTypes.string,
};

export const CompanySelectorItem = ({item}) => (
    <span>
        {item.code && <small className={"mr-2 rounded bg-grey-darker px-1 text-white"}>{item.code}</small>}
        <span className={"mr-2"}>{item.name}</span>
        {item.industry && <small className={"rounded bg-primary px-1 text-white"}>{item.industry.key}</small>}
    </span>
);

export const CompanySelector = ({customClassName, formData, handleFormData, required}) => {
    const dispatch = useDispatch();
    const language = localStorage.getItem("i18nextLng");
    const [companyValue, setCompanyValue] = useState();

    useEffect(() => {
        if (formData && formData.company) {
            setCompanyValue({
                value: formData.company.id,
                label: <CompanySelectorItem item={formData.company} />,
            });
        }
        EventBus.on(UPDATE_COMPANY_SELECTOR_AFTER_CREATE_A_COMPANY_NOTIFI, (data) => {
            refreshTheList(data);
        });
        return function cleanup() {
            EventBus.remove(UPDATE_COMPANY_SELECTOR_AFTER_CREATE_A_COMPANY_NOTIFI);
        };
    }, []);

    const refreshTheList = (data) => {
        if (data) {
            setCompanyValue({
                value: data.id,
                label: <CompanySelectorItem item={data} />,
            });
            handleFormData("companyId")({target: {value: data.id}});
        }
    };

    const searchCompanies = (inputValue, callback) => {
        axios
            .get(`${crmPrefix}/companies?list=mine&size=8`, {
                params: {
                    keyword: inputValue,
                },
            })
            .then((res) => {
                let data = res.data.content.map((item) => ({
                    value: item.id,
                    label: <CompanySelectorItem item={item} />,
                }));
                const addElement = {
                    value: "add",
                    label: (
                        <span className={"text-green font-bold flex items-center"}>
                            <Icon className="mr-2">add</Icon>
                            {language === "cn" ? "创建公司" : "Create a company"}
                        </span>
                    ),
                };
                data = [addElement, ...data];
                callback(data);
            });
    };

    const changeCompany = (value) => {
        if (value) {
            if (value.value === "add") {
                dispatch(turnOn("company"));
            } else {
                handleFormData("companyId")({target: {value: value.value}});
                setCompanyValue(value);
            }
        } else {
            handleFormData("companyId")({target: {value: ""}});
            setCompanyValue(null);
        }
    };

    return (
        <Translate>
            {({translate}) => (
                <Select2
                    required={required}
                    key={_.random(2, 999999)}
                    textFieldProps={{
                        required: required,
                        fullWidth: true,
                        label: translate("market.Company"),
                        InputLabelProps: {
                            shrink: true,
                            required: required,
                        },
                    }}
                    placeholder={translate("market.Search a company")}
                    loadOptions={searchCompanies}
                    defaultOptions
                    openMenuOnFocus
                    className={customClassName ? customClassName : "overflow-visible w-full z-9999"}
                    // styles={selectStyles}
                    components={components}
                    value={companyValue}
                    onChange={changeCompany}
                    isClearable
                />
            )}
        </Translate>
    );
};

CompanySelector.propTypes = {
    customClassName: PropTypes.string,
    type: PropTypes.string,
};

export const DealSelectorItem = ({item}) => (
    <span className={"flex flex-row justify-between"}>
        <span className={"mr-1"}>{item.name}</span>
    </span>
);

export const DealSelector = ({formData, handleFormData}) => {
    const dispatch = useDispatch();
    const language = localStorage.getItem("i18nextLng");
    const [value, setValue] = useState();

    useEffect(() => {
        if (formData.deal) {
            setValue({
                value: formData.deal.id,
                label: <DealSelectorItem item={formData.deal} />,
            });
        }
        EventBus.on(UPDATE_DEAL_SELECTOR_AFTER_CREATE_A_DEAL_NOTIFI, (data) => {
            refreshTheList(data);
        });
        return function cleanup() {
            EventBus.remove(UPDATE_DEAL_SELECTOR_AFTER_CREATE_A_DEAL_NOTIFI);
        };
    }, []);

    const refreshTheList = (data) => {
        if (data) {
            setValue({
                value: formData.deal.id,
                label: <DealSelectorItem item={formData.deal} />,
            });
            handleFormData("dealId")({target: {value: data.id}});
        }
    };

    const onSearch = (inputValue, callback) => {
        axios
            .get(`${crmPrefix}/deals?size=8`, {
                params: {
                    keyword: inputValue,
                },
            })
            .then((res) => {
                let data = res.data.content.map((item) => ({
                    value: item.id,
                    label: <DealSelectorItem item={item} />,
                    data: item,
                }));
                const addElement = {
                    value: "add",
                    label: (
                        <span className={"text-green font-bold flex items-center"}>
                            <Icon className="mr-2">add</Icon>
                            {language === "cn" ? "创建交易" : "Create a deal"}
                        </span>
                    ),
                };
                data = [addElement, ...data];
                callback(data);
            });
    };

    const onChange = (value) => {
        if (value) {
            if (value.value === "add") {
                dispatch(turnOn("deal"));
            } else {
                handleFormData("dealId")({target: {value: value.value}});
                setValue(value);
            }
        } else {
            handleFormData("dealId")({target: {value: ""}});
            setValue(null);
        }
    };

    return (
        <Translate>
            {({translate}) => (
                <Select2
                    textFieldProps={{
                        margin: "normal",
                        fullWidth: true,
                        label: translate("market.deal"),
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    key={_.random(3, 99999999999)}
                    loadOptions={onSearch}
                    defaultOptions
                    margin={"normal"}
                    className={"overflow-visible"}
                    // styles={selectStyles}
                    components={components}
                    value={value}
                    onChange={onChange}
                    placeholder={translate("market.Search a deal")}
                    isClearable
                />
            )}
        </Translate>
    );
};

DealSelector.propTypes = {
    customClassName: PropTypes.string,
    type: PropTypes.string,
};
