import type {FormControlProps} from "@material-ui/core";
import {FormControl} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import Icon from "@material-ui/core/Icon";
import {MyTooltip} from "@ui";
import clsx from "clsx";
import type {ReactElement} from "react";
import type {EnumType} from "types";

type Props = {
    type: string;
    items: EnumType[];
    label?: string | ReactElement;
    required?: boolean;
    formData: Record<string, any>;
    onChange(type: string): (value?: string | number) => void;
    large?: boolean;
} & Omit<FormControlProps, "onChange">;

const SelectButtons = ({type, items, label, required, formData, onChange, large, ...rest}: Props) => {
    return (
        <>
            <FormControl {...rest}>
                {label && (
                    <FormLabel className={"mb-2"} required={required}>
                        {label}
                    </FormLabel>
                )}
                <div className={clsx(large ? "h-60" : "h-40", "bg-white border  flex w-full text-center mt-1 overflow-hidden rounded-8 font-bold")}>
                    {items.map((_) => (
                        <MyTooltip key={_.value} arrow title={_.label} disabled={!_.icon}>
                            <button
                                data-cy={"select-btn"}
                                data-selected={formData && formData[type] === _.value}
                                data-value={_.value}
                                onClick={() => onChange(type)(_.value)}
                                className={clsx(
                                    "items-center font-bold px-12 flex flex-grow-default justify-center truncate",
                                    // items.length === i && "flex-grow",
                                    formData &&
                                        formData[type] === _.value &&
                                        (_.color ? `bg-${_.color} text-white bg-${_.color}-dark` : "text-white bg-primary")
                                )}>
                                {_.icon ? <Icon fontSize={large ? "large" : "medium"}>{_.icon}</Icon> : _.label}
                            </button>
                        </MyTooltip>
                    ))}
                </div>
            </FormControl>
        </>
    );
};

export default SelectButtons;
