import axios, {commonPrefix} from "../../../../../myaxios";

export const GET_EXTERNAL_USERS = "[GET_EXTERNAL_USERS]";




export function getExternalUsers(params) {

    const request = axios.get(`${commonPrefix}/extConstUsers`,{params});

    return (dispatch) => {
        request.then(response => {
            dispatch({
                type: GET_EXTERNAL_USERS,
                payload: response.data
            });
        });
    };
}



