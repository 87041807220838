import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import {Dialog, DialogActions, DialogContent, MenuItem, Typography} from "@material-ui/core";
import {useEffect, useMemo, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {useStoreUtils} from "../categories/state";
import {useMemoizedFn, useMount, useSetState} from "ahooks";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Translate} from "react-localize-redux";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip/Chip";
import TextField from "@material-ui/core/TextField";
import UsersSelector from "../../../../../utils/UserSelector/UsersSelector";
import {map} from "lodash";
import {usePostCategory, usePutCategory} from "../categories/hooks";
import {useDispatch} from "react-redux";
import * as fuseActions from "../../../../store/actions";

const initState = {
    name: "",
    status: "",
    users: [],
};

const STATUS = [
    {
        value: "open",
        label: "processCategory open",
    },
    {
        value: "close",
        label: "processCategory close",
    },
];

function EditProcessCategoryDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.editProcessCategoryDialog,
    });
    const {categoryActions, categorySelected} = useStoreUtils();

    const {onChangeSelectedCategory} = categoryActions;

    const dispatch = useDispatch();

    const postCategoryActions = usePostCategory();

    const putCategoryActions = usePutCategory();

    const [loading, setLoading] = useState(false);

    const [state, setState] = useSetState(initState);

    useMount(() => {
        if (categorySelected) {
            const mappedUsers = map(categorySelected.users, (item) => item.user);
            setState({
                name: categorySelected.name,
                status: categorySelected.status,
                users: mappedUsers,
            });
        } else {
            setState(initState);
        }
    });

    useEffect(() => {
        return () => {
            onChangeSelectedCategory(null);
        };
    }, []);

    const handleCancel = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSure = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        let params;
        if (categorySelected) {
            const tu = map(state.users, (t) => t.id);
            params = {
                id: categorySelected.id,
                name: state.name,
                status: state.status,
                users: tu,
            };
            putCategoryActions
                .run(params)
                .then((response) => {
                    if (response.status === 200) {
                        finshParams?.onFinish();
                        dispatch(
                            fuseActions.showMessage({
                                variant: "success",
                                message: "_.Category edit successfully",
                            })
                        );
                        onTurnOffDialog();
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            const tu = map(state.users, (t) => t.id);
            params = {
                name: state.name,
                status: state.status,
                users: tu,
            };
            postCategoryActions
                .run(params)
                .then((response) => {
                    if (response.status === 200) {
                        finshParams?.onFinish();
                        dispatch(
                            fuseActions.showMessage({
                                variant: "success",
                                message: "_.Category create successfully",
                            })
                        );
                        onTurnOffDialog();
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    });

    const handleNameChange = useMemoizedFn((e) => {
        setState({
            name: e.target.value,
        });
    });

    const handleStatusChange = useMemoizedFn((e) => {
        setState({
            status: e.target.value,
        });
    });

    const deleteUser = useMemoizedFn((contact) => {
        let users = state.users;
        users = users.filter((item) => item.id !== contact.id);
        setState({
            users: users,
        });
    });
    const handleAddUsers = useMemoizedFn((users) => {
        console.log("handleAddUsers > users", users);
        setState({
            users: users,
        });
    });

    const rootStyle = useMemo(() => {
        if (loading) {
            return "flex flex-col flex-grow loading overflow-hidden";
        }
        return "flex flex-col flex-grow overflow-hidden";
    }, [loading]);

    const disabled = useMemo(() => {
        return state.name === "" || state.status === "";
    }, [state.name, state.status]);

    return (
        <Dialog {...dialogProps}>
            <div className={rootStyle}>
                <DialogTitle>
                    <div className={"flex flex-row items-center"}>
                        <Typography variant={"h6"} className={"inline-flex mr-1"}>
                            {categorySelected ? (
                                <Translate id={"_.edit process category"} />
                            ) : (
                                <Translate id={"_.new process category"} />
                            )}
                        </Typography>
                        {categorySelected && (
                            <div className={"flex flex-row items-center"}>
                                <Chip size="small" label={categorySelected?.name} color="secondary" />
                            </div>
                        )}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={"flex flex-row overflow-hidden"}>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    value={state?.name}
                                    label={<Translate id={"_.processCategory name"} />}
                                    onChange={handleNameChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    value={state?.status}
                                    label={<Translate id={"_.processCategory status"} />}
                                    onChange={handleStatusChange}
                                >
                                    {STATUS.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {<Translate id={`_.${option.label}`} />}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <UsersSelector
                                    title={<Translate id={"_.processCategory users"} />}
                                    deleteUser={deleteUser}
                                    addUsers={handleAddUsers}
                                    users={state?.users || []}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{<Translate id={"_.cancel"} />}</Button>
                    <Button disabled={disabled} onClick={handleSure} color="secondary">
                        {<Translate id={"_.sure"} />}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default EditProcessCategoryDialog;
