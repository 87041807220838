import {FuseMessage, FuseScrollbars, FuseSuspense} from "@fuse";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import {memo} from "react";
import {Toaster} from "react-hot-toast";
import {shallowEqual, useSelector} from "react-redux";
import {renderRoutes} from "react-router-config";
import AppContext from "../../RouteContext";
import FooterLayout1 from "../layout1/components/FooterLayout1";
import ProgressSnackbar from "../shared-components/ProgressSnackbar";
import FooterLayout3 from "./components/FooterLayout3";
import NavbarWrapperLayout3 from "./components/NavbarWrapperLayout3";
import RightSideLayout3 from "./components/RightSideLayout3";
import ToolbarLayout3 from "./components/ToolbarLayout3";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "&.boxed": {
            maxWidth: 1120,
            margin: "0 auto",
            boxShadow: theme.shadows[3],
        },
        "&.container": {
            "& .container": {
                maxWidth: 1120,
                width: "100%",
                margin: "0 auto",
            },
            "& .navigation": {},
        },
    },
    content: {
        display: "flex",
        overflow: "auto",
        flex: "1 1 auto",
        flexDirection: "column",
        width: "100%",
        "-webkit-overflow-scrolling": "touch",
        zIndex: 4,
    },
    toolbarWrapper: {
        display: "flex",
        position: "relative",
        zIndex: 5,
    },
    toolbar: {
        display: "flex",
        flex: "1 0 auto",
    },
    footerWrapper: {
        position: "relative",
        zIndex: 5,
    },
    footer: {
        display: "flex",
        flex: "1 0 auto",
    },
}));

function Layout3(props) {
    const config = useSelector(({fuse}) => fuse.settings.current.layout.config, shallowEqual);

    const classes = useStyles(props);

    return (
        <AppContext.Consumer>
            {({routes}) => (
                <div id="fuse-layout" className={clsx(classes.root, config.mode)}>
                    <div className="flex flex-1 flex-col overflow-hidden relative">
                        {config.toolbar.display && config.toolbar.position === "above" && <ToolbarLayout3 />}

                        {config.navbar.display && <NavbarWrapperLayout3 />}

                        {config.toolbar.display && config.toolbar.position === "below" && <ToolbarLayout3 />}

                        <FuseScrollbars className={clsx(classes.content)}>
                            <div className="flex flex-auto flex-col relative pb-56 md:pb-0">
                                <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

                                {props.children}

                                {config.footer.display && config.footer.style === "static" && <FooterLayout3 />}
                            </div>
                        </FuseScrollbars>

                        {config.footer.display && config.footer.style === "fixed" && <FooterLayout1 />}

                        {/*<SettingsPanel/>*/}
                    </div>

                    {config.rightSidePanel.display && <RightSideLayout3 />}
                    <ProgressSnackbar />
                    <FuseMessage />
                    <Toaster position="bottom-left" />
                </div>
            )}
        </AppContext.Consumer>
    );
}

export default memo(Layout3);
