const settingsConfig = {
    layout          : {
        style : "layout1", // layout1 layout2 layout3
        config: {
            scroll : "content",
            navbar : {
                display : true,
                folded  : false,
                position: "left"
            },
            toolbar: {
                display : true,
                style   : "fixed",
                position: "below"
            },
            footer : {
                display : false,
                style   : "fixed",
                position: "below"
            },
            newFooter: true,
            leftSidePanel : {
                display : true,
            },
            rightSidePanel : {
                display : true,
            },
            mode   : "fullwidth"
        } // checkout layout configs at app/configs/layout-1/Layout1Config.js
    },
    customScrollbars: true,
    theme           : {
        main   : "default",
        navbar : "mainThemeDark",
        toolbar: "mainThemeLight",
        footer : "mainThemeDark"
    }
};

export default settingsConfig;
