const createNoHandlerSlice = (set) => ({
    selectedNoHandlerIncome: [],
    onChangeSelectedNoHandlerIncome: (datas) => {
        set({
            selectedNoHandlerIncome: datas,
        });
    },
});

export default createNoHandlerSlice;
