import { turnOff, showMessage, replaceParams, minimize } from "@fuseActions";
import {Button, DialogActions, DialogContent, DialogTitle, Icon, IconButton, TextField, Typography, withMobileDialog} from "@material-ui/core";
import { stringify } from "qs";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import UsersSelector from "utils/UserSelector/UsersSelector";

import withDialog from "utils/withDialog";
import FaveButton from "../../../../../components/layout/fav/FaveButton";
import axios, {crmPrefix} from "../../../../../myaxios";
import transJson from "../../translates/translate.json";
import { getList, selectItem, getItem } from "../store/actions";

// const initState  = {
//     loading: false,
//     users: [],
//     formData: {
//         "title": ""
//     },
// };

// const TabPanel = (props) =>  {
//     const { children, value, index, ...other } = props;
//
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`vertical-tabpanel-${index}`}
//             className="bg-grey-lighter flex-grow"
//             style={{borderRadius: "0 5px 5px 0"}}
//             aria-labelledby={`vertical-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <div>
//                     {children}
//                 </div>
//             )}
//         </div>
//     );
// };

class MeetingDialog extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(transJson);
    }

    state = {
        loading: false,
        users: [],
        formData: {
            title: "",
        },
    };

    handleFormData = (name) => (event) => {
        const formData = {...this.state.formData};
        formData[name] = event.target.value;
        this.setState({
            formData,
        });
    };

    componentDidMount = () => {
        const {selectedItem} = this.props;
        if (selectedItem) {
            this.setData(selectedItem);
        }
    };

    componentDidUpdate = (prevProps) => {
        const {selectedItem} = this.props;
        if (selectedItem !== prevProps.selectedItem) {
            selectedItem ? this.setData(selectedItem) : this.clearData();
        }
    };

    componentWillUnmount = () => {
        this.setState({users: []});
        this.clearData();
    };

    setData = (selectedItem) => {
        this.setState({
            formData: {
                ...selectedItem,
            },
            users: selectedItem.participants.map((__) => __.user),
        });
    };

    clearData = () => {
        this.setState({
            loading: false,
            users: [],
            formData: {
                title: "",
            },
        });
    };

    filterData = () => {
        const search = {...this.props.search};
        this.props.getList(search);
        delete search.keyword;
        localStorage.setItem("meetingFilter", stringify({...search}));
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {search, selectedItem, showMessage, getItem, match, item, replaceParams} = this.props;
        this.setState({loading: true});
        let formData = {
            ...this.state.formData,
            userIds: this.state.users.map((__) => __.id),
        };

        if (selectedItem) {
            formData = {
                title: this.state.formData.title,
                id: selectedItem.id,
            };
        }

        let request = selectedItem ? axios.put(`${crmPrefix}/innerMeeting`, formData) : axios.post(`${crmPrefix}/innerMeeting`, formData);
        request
            .then((res) => {
                this.setState({loading: false});
                showMessage({
                    message: "message.Item Saved!",
                    variant: "success",
                });
                if (item) {
                    getItem(item.id);
                } else {
                    replaceParams({...match.params, size: search.size}).then(() => this.filterData());
                }

                this.closeDialog();
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    closeDialog = () => {
        this.clearData();
        this.props.turnOff("meeting");
        this.props.selectItem(null);
    };

    addUsers = (users) => {
        this.setState({users});
    };

    deleteUser = (contact) => {
        let users = this.state.users;
        const i = users.findIndex((_member) => _member.id === contact.id);
        if (i > -1) {
            users = users.filter((_member) => _member.id !== contact.id);
        }
        this.setState({users});
    };

    render() {
        const {selectedItem, dialogs} = this.props;
        const {loading, formData, step} = this.state;

        const disableSubmit = formData.title.trim() === "";
        // function a11yProps(index) {
        //     return {
        //         id: `vertical-tab-${index}`,
        //         'aria-controls': `vertical-tabpanel-${index}`,
        //     };
        // }

        return (
            <div className={loading ? "loading" : null}>
                <DialogTitle
                    id="alert-dialog-title"
                    disableTypography
                    classes={{root: "drag flex justify-between items-center px-24 py-8 rounded-t"}}>
                    {selectedItem ? (
                        <Translate>
                            {({translate}) => (
                                <Typography className={"my-12"} variant={"h6"}>
                                    {translate("market.Edit meeting with parameter", {name: selectedItem.title})}
                                </Typography>
                            )}
                        </Translate>
                    ) : (
                        <>
                            <Typography variant={"h6"}>
                                <span className={"font-normal"}>
                                    <Translate id="market.New meeting" />
                                </span>
                            </Typography>
                        </>
                    )}
                    <div>
                        {!selectedItem && <FaveButton colored className={"-mr-8"} sourceId={"meeting"} title={"_.meeting"} type={"dialog"} />}
                        {/*<IconButton className={'no-drag'} onClick={()=>this.props.minimize("meeting")}><Icon>remove</Icon></IconButton>*/}
                        <IconButton className={"no-drag"} onClick={this.closeDialog}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={"overflow-visible"}>
                    <Translate>
                        {({translate}) => (
                            <TextField
                                // InputLabelProps={{
                                //     required: true
                                // }}
                                required
                                fullWidth
                                label={translate("market.title")}
                                value={formData.title}
                                onChange={this.handleFormData("title")}
                            />
                        )}
                    </Translate>

                    {!selectedItem && (
                        <UsersSelector
                            title={<Translate id={"market.Participants"} />}
                            addUsers={this.addUsers}
                            deleteUser={this.deleteUser}
                            users={this.state.users}
                        />
                    )}
                </DialogContent>

                <DialogActions>
                    <Button color="default" onClick={this.closeDialog}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button color="secondary" variant={"contained"} disabled={disableSubmit} onClick={this.onSubmit}>
                        <Translate id="_.save" />
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: turnOff,
            showMessage: showMessage,
            replaceParams: replaceParams,
            getList: getList,
            selectItem: selectItem,
            minimize: minimize,
            getItem: getItem,
        },
        dispatch
    );
}

function mapStateToProps({marketing, fuse, user}) {
    return {
        selectedItem: marketing.meeting.list.selected,
        item: marketing.meeting.item,
        search: fuse.search,
    };
}

export default withDialog(
    "meeting",
    "sm",
    true
)(
    withRouter(
        withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(MeetingDialog)))
    )
);
