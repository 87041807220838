import {useEffect, useState} from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {useDispatch} from "react-redux";
import {Translate} from "react-localize-redux";
import * as fuseActions from "@fuseActions";
import axios, {crmPrefix} from "../../../../myaxios";
import Empty from "utils/Empty";
import * as LabelActions from "../store/actions";
import {getGridListAsycnNotificationName} from "../marketingTools";
import EventBus from "utils/EventBus";
import ListItem from "@material-ui/core/ListItem";

const GridList = ({link, GridItem, type, sourceItem, sourceType, disableAdd, withoutCard}) => {
    const [data, setData] = useState({content: []});
    const dispatch = useDispatch();
    // let notificationBus ;
    const getData = () => {
        axios.get(crmPrefix + "/" + link, {
            params: {
                [sourceType+"Id"]: sourceItem.id
            }
        }).then(r => {
            // console.log(r.data);
            // console.log(`get current grid list data link=> ${link}`, r.data);
            setData(r.data);
        });
    };

    const onClickAdd = () => {
        dispatch(LabelActions.selectGridlistType(`${sourceType}-${type}`));
        dispatch(fuseActions.turnOn(type));
    };

    const refresh = () => {
        getData();
        dispatch(LabelActions.selectGridlistType(null));
    };

    useEffect(()=>{

        getData();

         const notifiName = getGridListAsycnNotificationName(`${sourceType}-${type}`);

         EventBus.on(notifiName,(data)=>{ refresh(); });

        return function cleanup() {
            EventBus.remove(notifiName);
        };
    },[]);


    const inner = <List dense subheader={<ListSubheader className={"flex items-center justify-between"}><span><Translate id={`market.${type}sss`}/> ({data.totalElements})</span> {!disableAdd && <IconButton onClick={onClickAdd} size={"small"}><Icon>add</Icon></IconButton>}</ListSubheader>} >
        {data.content.length > 0 ? data.content.map(__ =>
            <ListItem
                // button
                dense
                // onClick={()=>{
                //     // getItem(__.id);
                //     dispatch(fuseActions.turnOn(`${type}Detail`));
                // }}
            >
                <GridItem small  key={__.id}  item={__}/>
            </ListItem>
            ) :
            <Empty className={"block"} noLoading noIcon/>}
    </List>;

    return (withoutCard ? inner  :
        <Card className="min-h-192">
            {inner}
        </Card>
    );
};

export default GridList;
