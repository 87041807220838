import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    redpacketSendFinshed: false,
    redPacketsList: [],
    myBlanceCanSend: null,
    myRedPacketByYearMonth: null,
    startDate: new Date(),
    endDate: new Date(),
    openBackDrop: false,
    updatedAmountsObjects: [],
    selectedRedPacketType: "general",
    selectedRedpacketReceivers:[]
};


const  sendRedPacketReducers = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_RED_PACKETS_LIST: {
            let tobj = cloneDeep(state);
            tobj.redPacketsList = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     redPacketsList: action.payload
            // };
        }
        case Actions.GET_MY_RED_PACKET_BALANCE_CAN_SEND: {
            let tobj = cloneDeep(state);
            tobj.myBlanceCanSend = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     myBlanceCanSend: action.payload
            // };
        }

        case Actions.GET_MY_RED_PACKET_BALANCE_BY_YEAR_MONTH: {

            let tobj = cloneDeep(state);
            tobj.myRedPacketByYearMonth = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     myRedPacketByYearMonth: action.payload,
            // };
        }

        case Actions.SELECT_START_DATE: {
            let tobj = cloneDeep(state);
            tobj.startDate = action.payload;
            return tobj;
            // return  {
            //     ...state,
            //     startDate: action.payload,
            // };
        }

        case Actions.OPEN_BACK_DROP: {
            let tobj = cloneDeep(state);
            tobj.openBackDrop = action.payload;
            return tobj;
            // return  {
            //     ...state,
            //     openBackDrop: action.payload,
            // };
        }

        case Actions.SELECT_END_DATE: {
            let tobj = cloneDeep(state);
            tobj.endDate = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     endDate: action.payload,
            // };
        }

        case Actions.AMOUNT_UPDATE_ON_INPUT: {
            let tobj = cloneDeep(state);
            tobj.updatedAmountsObjects = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     updatedAmountsObjects: action.payload
            // };
        }

        case Actions.SET_REDPACKET_TYPE: {
            let tobj = cloneDeep(state);
            tobj.selectedRedPacketType = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedRedPacketType: action.payload
            // };
        }

        case Actions.SELECT_REDPACKET_RECIVER: {
            let tobj = cloneDeep(state);
            tobj.selectedRedpacketReceivers = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedRedpacketReceivers: action.payload
            // };
        }

        case Actions.RED_PACKET_SEND_FINSHED: {
            let tobj = cloneDeep(state);
            tobj.redpacketSendFinshed = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     redpacketSendFinshed: action.payload
            // };
        }

        default:
            return cloneDeep(state);
    }
};

export default sendRedPacketReducers;
