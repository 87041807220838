export const createModelSlice = (set, get) => ({
    open: false,
    options: null,
    onChangeOpen: () => {
        set({
            open: true,
        });
    },
    onChangeClose: () => {
        set({
            open: false,
        });
    },
    onChangeModelOptions: (options) => {
        set({
            options: options,
        });
    },
});
