export const ADD_FIELD = "[Form Builder] ADD";
export const REMOVE_FIELD = "[Form Builder] REMOVE FIELD";
export const EDIT_FIELD = "[Form Builder] EDIT";
export const EDIT_FORM_DETAIL = "[Form Builder] EDIT FORM DETAIL";
export const CLEAR_FORM = "[Form Builder] CLEAR FORM";
export const CHANGE_GRID = "[Form Builder] CHANGE GRID";
export const EDIT_FORM = "[Form Builder] EDIT FORM";
export const CHANGE_ORDER = "[Form Builder] CHANGE ORDER";
export const CHANGE_EDITOR = "[Form Builder] CHANGE_EDITOR";

export const addField = (field) => {
    return {
        type   : ADD_FIELD,
        field: field
    };
};

export const removeField = (field) => {
    return {
        type   : REMOVE_FIELD,
        field: field
    };
};

export const editField = (field, data) => {
    return {
        type   : EDIT_FIELD,
        field: field,
        data: data
    };
};

export const editFormDetails = (data) => {
    return {
        type   : EDIT_FORM_DETAIL,
        data: data
    };
};

export const clearForm = () => {
    return {
        type   : CLEAR_FORM,
    };
};

export const changeGrid = (field,grid) => {
    return {
        type   : CHANGE_GRID,
        field: field,
        grid: grid
    };
};

export const editForm = (schema,uiSchema) => {
    return {
        type   : EDIT_FORM,
        schema,
        uiSchema,
    };
};

export const changeOrder = (start,end) => {
    return {
        type   : CHANGE_ORDER,
        start: start,
        end: end,
    };
};

export const changeEditor = (item) => {
    return {
        type   : CHANGE_EDITOR,
        item
    };
};
