import {Icon, IconButton, TablePagination, Typography} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import {toNumber} from "lodash";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import {Translate} from "react-localize-redux";

PaginationFilter.propTypes = {
    query: PropTypes.object.isRequired,
    onChangeQuery: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    showOriginalDisplayedRows: PropTypes.bool,
    colSpan: PropTypes.any.isRequired,
    onChangeSelectedRows: PropTypes.func
};

function PaginationFilter(props) {
    const {
        query,
        onChangeQuery,
        mutate,
        totalPages,
        showOriginalDisplayedRows,
        count,
        colSpan,
        onChangeSelectedRows
    } = props;

    const [manualPage, setManualPage] = useState(0);

    useEffect(() => {
        if (!!query?.page) {
            const tp = toNumber(query.page);
            setManualPage(tp);
        }
        return () => {
        };
    }, [query?.page, setManualPage]);

    const onManualPageChange = useMemoizedFn((e) => {
        const tp = e.target.value;
        setManualPage(tp);
    });

    const onGoToPage = useMemoizedFn((e) => {
        e.stopPropagation();
        const params = {
            ...query,
            page: toNumber(manualPage)
        };
        mutate(params).then((response) => {
            if (response.status === 200) {
                onChangeQuery(params);
            }
        });
    });

    const onPageChange = useMemoizedFn((e, newPage) => {
        onChangeSelectedRows && onChangeSelectedRows([]);
        console.debug("***[PaginationFilter]***[onPageChange] > query:", query);
        const tp = toNumber(newPage + 1);
        const params = {
            ...query,
            page: tp
        };
        setManualPage(tp);
        mutate(params).then((response) => {
            if (response.status === 200) {
                onChangeQuery(params);
            }
        });
    });

    const onRowsPerPageChange = useMemoizedFn((e) => {
        onChangeSelectedRows && onChangeSelectedRows([]);
        console.debug("***[PaginationFilter]***[onRowsPerPageChange] > query:", query);
        const params = {
            ...query,
            page: 1,
            size: toNumber(e.target.value)
        };
        setManualPage(1);
        mutate(params).then((response) => {
            console.debug("onRowsPerPageChange > response: ", response);
            if (response.status === 200) {
                onChangeQuery(params);
                setManualPage(1);
            }
        });
    });

    const disableManualPageButton = useMemo(() => {
        return manualPage < 1 || manualPage > totalPages;
    }, [manualPage, totalPages]);

    const page = useMemo(() => {
        return !!query?.page ? toNumber(query.page) - 1 : 0;
    }, [query?.page]);

    const rowsPerPage = useMemo(() => {
        return !!query?.size ? toNumber(query.size) : 10;
    }, [query?.size]);

    return (
        <div
            data-cy="paginationRoot"
            className="flex flex-row flex-1 w-full justify-end items-center shrink-0 border-solid border-t-1"
        >
            <div className={"flex items-center"}>
                <Typography variant="body1">{<Translate id={"_.Go to page"} />}:</Typography>
            </div>
            <div className={"flex items-center"}>
                <Input
                    data-cy="paginationRootPage"
                    type={"number"}
                    className={"w-72 mx-4"}
                    inputProps={{max: totalPages, min: 1}}
                    value={manualPage}
                    onChange={onManualPageChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <MyTooltip title={<Translate id={"_.Jump to"} />}>
                                <span>
                                    <IconButton
                                        size={"small"}
                                        data-cy="paginationRootPageAction"
                                        disabled={disableManualPageButton}
                                        aria-label="manual page jump button"
                                        onClick={onGoToPage}
                                    >
                                        <Icon>fast_forward</Icon>
                                    </IconButton>
                                </span>
                            </MyTooltip>
                        </InputAdornment>
                    }
                />
            </div>
            <div className={"flex items-center"}>
                <Typography className="text-center">
                    {<Translate id={"_.totalPages"} />}:<strong className={"text-lg"}>{` ${totalPages} `} </strong>
                </Typography>
            </div>
            <div className={"flex items-center"}>
                {showOriginalDisplayedRows ? (
                    <Translate>
                        {({translate}) => (
                            <TablePagination
                                component="div"
                                colSpan={colSpan}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                page={page}
                                labelRowsPerPage={translate("_.rowsPerPage")}
                                backIconButtonProps={{
                                    "aria-label": translate("_.previous")
                                }}
                                nextIconButtonProps={{
                                    "aria-label": translate("_.next")
                                }}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowsPerPageChange}
                            />
                        )}
                    </Translate>
                ) : (
                    <Translate>
                        {({translate}) => (
                            <TablePagination
                                data-cy="paginationRootPageAndRowsPerPage"
                                component="div"
                                colSpan={colSpan}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                labelDisplayedRows={({from, to, count, _}) => {
                                    return translate("_.display rows with params", {from: from, to: to, count: count});
                                }}
                                labelRowsPerPage={<Translate id={"_.rowsPerPage"} />}
                                backIconButtonProps={{
                                    "aria-label": translate("_.previous")
                                }}
                                nextIconButtonProps={{
                                    "aria-label": translate("_.next")
                                }}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowsPerPageChange}
                            />
                        )}
                    </Translate>
                )}
            </div>
        </div>
    );
}

export default PaginationFilter;
