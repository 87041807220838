import {lazy} from "react";

export const TodoAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/todo/stage/:id/post",
            component: lazy(() =>
                import("./Task/Stage/StagePostPage")
            )
        },
        {
            path     : "/todo/:id",
            component: lazy(() =>
                import("./Task/TaskPage")
            )
        },
        {
            path     : "/task/:id",
            component: lazy(() =>
                import("./Task/TaskPage")
            )
        },
        {
            path     : "/todo/:id/",
            component: lazy(() =>
                import("./Task/TaskPage")
            )
        },
        {
            path     : "/task/:id/",
            component: lazy(() =>
                import("./Task/TaskPage")
            )
        },
        {
            path     : "/todo",
            component: lazy(() =>
                import("./List/Page")
            )
        },
    ]
};
