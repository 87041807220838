import {forwardRef} from 'react';
import Fade from '@material-ui/core/Fade';


// @ts-ignore
const Transition  = forwardRef(function Transition(props, ref) {

    return <Fade in={true} ref={ref} {...props} />
});

export default Transition;
