import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    externalUserResponse: null,
};

const external_usersReducer = function (state=initState, action) {
    switch (action.type) {
        case Actions.GET_EXTERNAL_USERS: {
            let tobj = cloneDeep(state);
            tobj.externalUserResponse = action.payload;
            return  tobj;
            // return {
            //     ...state,
            //     externalUserResponse: action.payload,
            // }
        }
        default:
            return  state;
    }
};

export default external_usersReducer;
