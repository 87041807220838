import {lazy} from "react";

export const BusinessTripsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/businessTrips",
            component: lazy(() => import("./Page")),
        },
        {
            path: "/myleaves",
            component: lazy(() => import("./Page")),
        },
        {
            path: "/leaveRequests",
            component: lazy(() => import("./Page")),
        },
        {
            path: "/pendingRequests",
            component: lazy(() => import("./Page")),
        },
    ],
};
