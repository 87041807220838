import clsx from "clsx";
import type {ReactNode} from "react";

export type ItemContentProps = {
    content?: ReactNode;
    sidebar?: ReactNode;
    leftSidebar?: ReactNode;
};

export const SingleContent = ({content, sidebar, leftSidebar}: ItemContentProps) => {
    return (
        <div className={"w-full"}>
            <div className={clsx("grid  row-gap px-8 md:px-12 md:gap-x-12 grid-cols-1 md:grid-cols-4 print:p-0 print:block")}>
                {content && <div className={clsx(sidebar ? "col-span-3 " : "col-span-4", leftSidebar ? "" : "print:p-0")}>{content}</div>}
                {sidebar && <div className={clsx(" print:mt-20 print:p-0 print:w-full", leftSidebar ? "order-first" : "")}>{sidebar}</div>}
            </div>
        </div>
    );
};
