import {Icon} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import {DeleteWrapper, EmptyView, MyCard} from "@ui";
import axios, {firmPrefix} from "myaxios";
import {useState} from "react";
import {LeafPoll} from "react-leaf-polls";
import "react-leaf-polls/dist/index.css";
import type {Result} from "react-leaf-polls/dist/types/result";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import useSWR from "swr";
import {ErrorButton, SuccessButton} from "utils/utilComponents";
import {showMessage} from "@fuseActions";
import {useWorkPlan} from "../useWorkPlan";

const customTheme = {
    textColor: "black",
    mainColor: "#0acc8d",
    leftColor: "#a67ef5",
    alignment: "start",
};
//
// type VotePostItem = {
//     id: number;
//     text: string;
// };

type VotePost = {
    title: string;
    result: Result[];
};

type VoteType = {
    title: string;
    timestamp: number;
    id: string;
    workPlanId: string;
    result: Result[];
    isVoted?: boolean;
};

const initF: VotePost = {
    title: "",
    result: [
        {
            id: 0,
            text: "Option 1",
            votes: 0,
        },
        {
            votes: 0,
            id: 1,
            text: "Option 2",
        },
    ],
};

const VoteWP = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {wp, canEdit} = useWorkPlan();
    const [add, setAdd] = useState(false);
    const [formData, setFormData] = useState<VotePost>(initF);
    const [show, setShow] = useState<Set<string>>(new Set<string>());

    const {data, mutate} = useSWR<VoteType[]>(wp && `${firmPrefix}/workplan/${wp?.id}/votes`, {revalidateOnMount: true});

    const onVote = (id) => (item: Result) => {
        axios
            .put(`${firmPrefix}/workplan/${wp?.id}/vote?id=${id}`, {
                ...item,
            })
            .then(() => {
                mutate();
            });
    };

    const addVote = () => {
        setLoading(true);
        axios
            .post(`${firmPrefix}/workplan/${wp?.id}/vote`, {...formData, items: formData.result})
            .then(() => {
                dispatch(showMessage({message: "Vote created", variant: "success"}));
                mutate().then(() => {
                    setAdd(false);
                    setFormData(initF);
                });
            })
            .finally(() => setLoading(false));
    };

    const onChangeItem = (index) => (e) => {
        const t = [...formData.result];
        t[index].text = e.target.value;
        setFormData((s) => ({...s, result: t}));
    };

    const addItem = () => {
        const t = [...formData.result];
        t.push({
            votes: 0,
            id: t.length,
            text: "Option " + (t.length + 1),
        });
        setFormData((s) => ({...s, result: t}));
    };

    const onDeleteItem = (i) => () => {
        const t = [...formData.result];
        t.splice(i, 1);

        setFormData((s) => ({...s, result: t}));
    };

    const toggleShowResults = (id) => {
        const t = new Set(show);
        if (t.has(id)) {
            t.delete(id);
        } else {
            t.add(id);
        }
        setShow(t);
    };

    return (
        wp && (
            <MyCard
                action={
                    canEdit && (
                        <Button data-cy={"vote-create"} onClick={() => setAdd(!add)} startIcon={<Icon>{add ? "close" : "add"}</Icon>}>
                            {add ? <Translate id={"_.close"} /> : <Translate id={"_.add"} />}
                        </Button>
                    )
                }
                noPadding
                title={<Translate id={"wps.Votes"} />}>
                {add && (
                    <div className="px-16 pt-8 bg-gray-50 border-b mb-24 pb-24">
                        <TextField
                            data-cy={"vote-title"}
                            required
                            fullWidth
                            onChange={(e) => setFormData((s) => ({...s, title: e.target.value}))}
                            label={<Translate id={"_.Title"} />}
                            value={formData.title}
                        />

                        <div data-cy={"vote-post-items"}>
                            {formData.result?.map((item, i) => (
                                <TextField
                                    key={item.id}
                                    data-cy={"vote-text-" + i}
                                    fullWidth
                                    className={"mt-16"}
                                    size={"small"}
                                    variant={"outlined"}
                                    onChange={onChangeItem(i)}
                                    value={item.text}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton data-cy={"vote-delete-" + i} disabled={formData.result?.length < 3} onClick={onDeleteItem(i)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        ),
                                    }}
                                />
                            ))}
                        </div>

                        <div className="mt-20 flex justify-between">
                            <Button
                                data-cy={"add-vote-item"}
                                disabled={formData.result?.length > 14}
                                onClick={addItem}
                                variant={"contained"}
                                startIcon={<Icon>add</Icon>}>
                                {<Translate id={"_.Add item"} />}
                            </Button>

                            <SuccessButton
                                data-cy={"save-votes"}
                                disabled={loading || formData.title === ""}
                                className={loading ? "load" : ""}
                                onClick={addVote}
                                variant={"contained"}>
                                <Translate id={"_.save"} />
                            </SuccessButton>
                        </div>
                    </div>
                )}
                {data && data.length > 0 ? (
                    <div className="px-12">
                        {data.reverse()?.map?.((v) => (
                            <div key={v.id} className={"mb-24"} data-cy={"votes"}>
                                {canEdit && show.has(v.id) ? (
                                    <PollWrapperWithResult
                                        key={v.id}
                                        type="multiple"
                                        question={v.title}
                                        results={v.result}
                                        theme={customTheme}
                                        onVote={onVote(v.id)}
                                        isVoted={true}
                                    />
                                ) : (
                                    <PollWrapper
                                        key={v.id}
                                        type="multiple"
                                        question={v.title}
                                        results={v.result}
                                        theme={customTheme}
                                        onVote={onVote(v.id)}
                                        isVoted={v.isVoted}
                                    />
                                )}
                                {canEdit && (
                                    <div className="flex justify-end items-center mt-8">
                                        {!v.isVoted && (
                                            <Button className={"mr-8"} size={"small"} onClick={() => toggleShowResults(v.id)}>
                                                {show.has(v.id) ? "Hide result" : "Show result"}
                                            </Button>
                                        )}
                                        <DeleteWrapper
                                            item={v.title}
                                            deleteReq={() => axios.delete(`${firmPrefix}/workplan/${wp.id}/vote?voteId=${v.id}`)}
                                            callback={mutate}
                                            children={(onClick) => (
                                                <ErrorButton
                                                    data-cy={"delete-vote"}
                                                    size={"small"}
                                                    variant={"contained"}
                                                    startIcon={<Icon>delete</Icon>}
                                                    onClick={onClick}>
                                                    <Translate id={"delete"} />
                                                </ErrorButton>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <EmptyView noIcon dense label={<Translate id="wps.There is no vote." />} />
                )}
            </MyCard>
        )
    );
};

const PollWrapper = (props) => {
    return <LeafPoll {...props} />;
};

const PollWrapperWithResult = (props) => {
    return <LeafPoll {...props} />;
};

export default VoteWP;
