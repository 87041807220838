import {Card} from "@material-ui/core";


interface Options {
    children: any;
}
function SRXCard({children}: Options) {
    return (
        <Card className={"rounded-lg h-full w-full p-5 shadow-inner"}>
            {children}
        </Card>
    );
}

export default SRXCard;
