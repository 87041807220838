import axios, {crmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_BRANDS = "[COMMERCE|BRANDS] GET_BRANDS";
export const GET_BRAND_CATEGORIES = "[COMMERCE|BRANDS] GET_BRAND_CATEGORIES";
export const BRAND_CHANGE_VIEW = "[COMMERCE|BRANDS] BRAND_CHANGE_VIEW";
export const SELECT_BRAND_CAT = "[COMMERCE|BRANDS] SELECT_BRAND_CATT";
export const SELECT_BRAND = "[COMMERCE|BRANDS] SELECT_BRAND";
export const CLEAR_BRAND = "[COMMERCE|BRANDS] CLEAR_BRAND";
export const PASS_BRAND_ID_TO_MAP = "[COMMERCE_PASS_BRAND_ID_TO_MAP]";
export const CLEAR_TO_MAP_BRAND_ID = "[COMMERCE_CLEAR_TO_MAP_BRAND_ID]";

export function getBrands(params, callback){

    const request = axios.get(`${crmPrefix}/brands`, {
        params: params
    });

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_BRANDS,
                payload: response.data
            });
            if(typeof callback=== "function") callback();
            dispatch(finishLoading());
        });
        dispatch(getBrandCategories());
    };
}

export function passBrandIdToMap(brandId) {
    return {
        type: PASS_BRAND_ID_TO_MAP,
        brandId
    };
}

export function clearToMapBrandId() {
    return {
        type: CLEAR_TO_MAP_BRAND_ID,
    };
}

export function getBrandCategories(){
    const request = axios.get(`${crmPrefix}/brandCategories`);

    return (dispatch) =>
        request.then((response) => {
                dispatch({
                    type: GET_BRAND_CATEGORIES ,
                    payload: response.data
                });
            }
        );
}

export function brandChangeView(view){
    return {
        type      : BRAND_CHANGE_VIEW,
        view
    };
}

export function selectBrandCat(cat){
    return {
        type      : SELECT_BRAND_CAT,
        cat
    };
}

export function selectBrand(brand){
    return {
        type      : SELECT_BRAND,
        brand
    };
}

