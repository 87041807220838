import {Button, Dialog, DialogContent, IconButton, Input, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import {MyTooltip} from "@ui";
import {useLocalStorageState, useMount} from "ahooks";
import _ from "lodash";
import {chain} from "mathjs";
import numeral from "numeral";
import {useContext, useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {withRouter} from "react-router-dom";
import {useMUIDialog, useRouterSwitch} from "../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../@fuse/state/constant";
import axios, {financialPrefix} from "../../../../myaxios";
import {IncomeOrdersContext} from "../IncomeOrdersDistributions/state/constant";
import incomeJson from "../translate/income.json";
import {useIncomeStore} from "./state";
import {INCOME_LOCAL_ID} from "./state/constant";
import {LFDialogHeader} from "./SubViews/NewSelectedMatchingDistributeView";

const InitState = {
    loading: false,
    searchLoading: false,
    inputValue: "",
    overAmountDistributeTips: "",
    searchOrders: [],
    options: [],
    orderValue: "",
    avalableAmount: 0,
    anchorEl: null,
    searchBy: "keyword",
    openOrders: false,
    open: false,
    selectedOrders: [],
    isFetching: false,
};

function NewSelectedMatchingDistributeDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.selectedMatchingDistribute,
        fullScreen: true,
        fullWidth: true,
    });

    const [state, setState] = useState(InitState);

    const context = useContext(IncomeOrdersContext);

    const [incomeId, setIncomeId] = useLocalStorageState(INCOME_LOCAL_ID);

    const {pathname, params} = useRouterSwitch();

    const detailRequest = context?.detailRequest;

    const incomeDetails = context?.detailRequest?.data?.data;

    const {matchingOrdersCount, matchingOrdersSelected, onChangeMatchingOrdersSelected} = useIncomeStore();

    useMount(() => {
        props.addTranslation(incomeJson);
        syncSomeDatas();
    });

    useEffect(() => {
        matchingOrdersSelected && checkOverAMount();
        return () => {
            setState({
                ...state,
                orderValue: [],
                loading: false,
            });
        };
    }, [matchingOrdersSelected]);

    const syncSomeDatas = () => {
        const newSelected = matchingOrdersSelected.map((item) => {
            return {...item, distributeAmount: item.invoiceRequestAmount};
        });

        if (incomeDetails) {
            let preAmounts;
            preAmounts = incomeDetails.amount - incomeDetails.collectAmount;
            setState({
                ...state,
                avalableAmount: preAmounts,
            });
        }
        onChangeMatchingOrdersSelected(newSelected);
    };

    const handleDistributeAmount = (order) => (e) => {
        console.log("当前订单", order);
        console.log("输入的值", e.target.value);
        const newSelected = matchingOrdersSelected.map((item) => {
            let tObj = {...item};
            if (item.orderCode === order.orderCode) {
                tObj.distributeAmount = e.target.value;
                return tObj;
            }
            return tObj;
        });
        onChangeMatchingOrdersSelected(newSelected);
    };

    const checkOverAMount = () => {
        const totalAmount = _.isArray(matchingOrdersSelected) === true ? sumArray(matchingOrdersSelected) : 0;
        const balance = chain(_.toNumber(incomeDetails.amount))
            .subtract(_.toNumber(incomeDetails.collectAmount))
            .subtract(_.toNumber(totalAmount))
            .done();
        console.log("输入的金额是:", totalAmount);
        console.log("可分配金额是:", balance);
        setState({
            ...state,
            avalableAmount: balance,
        });
        if (totalAmount > _.toNumber(incomeDetails.availableAmount)) {
            setState({
                ...state,
                overAmountDistributeTips: "income.The amount allocated has exceeded this income",
            });
        } else {
            setState({
                ...state,
                overAmountDistributeTips: "",
            });
        }
    };

    const sumArray = (arr) => {
        let sum = 0;
        arr.forEach((obj, index, array) => {
            let tempAmount = 0;
            if (obj.distributeAmount !== "" && obj.distributeAmount) {
                tempAmount = _.toNumber(obj.distributeAmount);
            }
            sum += tempAmount;
        });
        return sum;
    };

    const handleDeleteAOderFromOrders = (row) => {
        const newSelected = matchingOrdersSelected.filter((item) => item.orderId !== row.orderId);
        onChangeMatchingOrdersSelected(newSelected);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        onTurnOffDialog();
    };

    const canSubmit = () => {
        let disabled = false;
        let tempTotalAmount = 0;
        if (state.loading === true) {
            disabled = true;
        }
        if (matchingOrdersSelected.length === 0 || matchingOrdersSelected === "") {
            disabled = true;
        }
        if (matchingOrdersSelected instanceof Array) {
            matchingOrdersSelected.map((item) => {
                if (item.distributeAmount) {
                    tempTotalAmount = chain(_.toNumber(tempTotalAmount))
                        .add(_.toNumber(item.distributeAmount !== "" ? item.distributeAmount : 0))
                        .done();
                    if (_.toNumber(item.distributeAmount) > _.toNumber(item.availableAmount)) {
                        disabled = true;
                    }
                    if (_.toNumber(item.distributeAmount) < 0) {
                        disabled = true;
                    }
                }
            });
        } else {
            disabled = true;
        }
        if (tempTotalAmount > incomeDetails?.data?.data?.availableAmount) {
            disabled = true;
        }
        return disabled;
    };

    const handleSubmit = (e) => {
        const language = localStorage.getItem("i18nextLng");
        const {path} = this.props.match;
        const attchmentId = params?.attchmentId;
        const {incomeDtails, search, confirm, selectedIncomeID, selectedIncome, selectedAutoMatchings} = this.props;
        const title = language === "cn" ? "提示" : "Prompt";
        const confirmationText = language === "cn" ? "检查" : "Check";
        const cancellationText = language === "en" ? "返回" : "Back";
        const description =
            language === "cn"
                ? "收入分配出错，请检查金额是否超额或者检查网络，然后重试"
                : "Revenue distribution error, please check whether the amount is over or check the network and try again";
        setState({
            ...state,
            loading: true,
        });
        const params = {
            incomeId: incomeDtails?.data?.data?.id,
            orders: matchingOrdersSelected.map((item) => {
                return {
                    clientId: item.clientId,
                    clientName: item.clientName,
                    orderCode: item.orderCode,
                    orderId: item.orderId,
                    projectId: item.projectId,
                    projectName: item.projectName,
                    amount: item.distributeAmount,
                };
            }),
        };
        axios
            .put(`${financialPrefix}/addIncomeOrders`, params)
            .then(
                (res) => {
                    finshParams?.onFinish();
                    onTurnOffDialog();
                },
                () => {
                    confirm({
                        title: title,
                        description: description,
                        confirmationText: confirmationText,
                        cancellationText: cancellationText,
                    })
                        .then(() => {
                            setState({
                                ...state,
                                loading: true,
                            });
                            const id = incomeId;
                            const _tp = {
                                id: id,
                            };
                            detailRequest?.run(_tp).then(() => {
                                setState({
                                    ...state,
                                    loading: false,
                                });
                            });
                        })
                        .catch(() => {
                            // this.handleClose();
                        });
                }
            )
            .finally(() => {
                setState({
                    ...state,
                    loading: false,
                });
            });
    };

    return (
        <>
            <Dialog {...dialogProps}>
                <LFDialogHeader avalableAmount={state.avalableAmount} selectedAutoMatchings={matchingOrdersSelected} />
                <DialogContent className="min-h-512 overflow-hidden">
                    <div className={state.loading === true ? "loading" : ""}>
                        <div className="flex flex-col">
                            <Table className="w-full mt-3" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={"hidden md:table-cell"}>{<Translate id={"income.orderCode"} />}</TableCell>
                                        <TableCell align="right" padding={"none"} className="text-nowrap hidden md:table-cell">
                                            {<Translate id={"income.clientName"} />}
                                        </TableCell>
                                        <TableCell align="right" padding={"none"} className="text-nowrap hidden md:table-cell">
                                            {<Translate id={"income.invoiceAmount(￥)"} />}
                                        </TableCell>
                                        <TableCell align="right" padding={"none"} className="text-nowrap">
                                            {<Translate id={"income.Max Amount(￥)"} />}
                                        </TableCell>
                                        <TableCell align="right" padding={"none"} className="text-nowrap">
                                            {<Translate id={"income.Quota(￥)"} />}
                                        </TableCell>
                                        <TableCell align="right" padding={"none"} className="text-nowrap">
                                            {<Translate id={"income.action"} />}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {matchingOrdersSelected && matchingOrdersSelected.length > 0 ? (
                                        matchingOrdersSelected.map((row) =>
                                            row ? (
                                                <TableRow key={row.orderId}>
                                                    <TableCell
                                                        component="td"
                                                        padding={"none"}
                                                        scope="row"
                                                        className="text-nowrap hidden md:table-cell">
                                                        {row.orderCode}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        padding={"none"}
                                                        component="td"
                                                        scope="row"
                                                        className="text-nowrap hidden md:table-cell">
                                                        {row.clientName}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        component="td"
                                                        padding={"none"}
                                                        scope="row"
                                                        className="text-nowrap hidden md:table-cell">
                                                        {numeral(row.invoiceAmount).format("0,0.00")}
                                                    </TableCell>
                                                    <TableCell align="right" component="td" padding={"none"} scope="row" className="text-nowrap">
                                                        {numeral(row.availableAmount).format("0,0.00")}
                                                    </TableCell>
                                                    <TableCell align="right" component="td" padding={"none"} scope="row" className="text-nowrap">
                                                        {
                                                            <Translate>
                                                                {({translate}) => (
                                                                    <Input
                                                                        placeholder={translate("income.Distribute income here")}
                                                                        className={
                                                                            state.overAmountDistributeTips === ""
                                                                                ? "flex flex-1 justify-end font-mono text-right"
                                                                                : "flex flex-1 justify-end text-right font-mono text-red"
                                                                        }
                                                                        type={"number"}
                                                                        disableUnderline
                                                                        fullWidth
                                                                        key={row.orderId}
                                                                        error={state.overAmountDistributeTips !== ""}
                                                                        helperText={
                                                                            state.overAmountDistributeTips !== ""
                                                                                ? translate(state.overAmountDistributeTips)
                                                                                : ""
                                                                        }
                                                                        value={row.distributeAmount}
                                                                        inputProps={{style: {textAlign: "right"}}}
                                                                        onChange={handleDistributeAmount(row)}
                                                                    />
                                                                )}
                                                            </Translate>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right" component="td" padding={"none"} scope="row">
                                                        <MyTooltip title={<Translate id={"income.delete order"} />}>
                                                            <IconButton
                                                                color="primary"
                                                                aria-controls="simple-menu-for-edit-Edit contract"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    handleDeleteAOderFromOrders(row);
                                                                }}>
                                                                <DeleteIcon style={{color: red[500]}} />
                                                            </IconButton>
                                                        </MyTooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    ) : (
                                        <TableRow key={"sdfgdsfgsdfghdsfgh"}>
                                            <TableCell colSpan={6} align={"center"}>
                                                <Typography variant="caption" display="block" className="text-center">
                                                    {<Translate id={"income.No data"} />}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            {state.overAmountDistributeTips !== "" && (
                                <Typography variant="body2">
                                    <small className="text-red">{<Translate id={state.overAmountDistributeTips} />}</small>
                                </Typography>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{<Translate id={"income.back"} />}</Button>
                    <Button disabled={canSubmit()} onClick={handleSubmit} color="secondary">
                        {<Translate id={"income.sure"} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withLocalize(withRouter(NewSelectedMatchingDistributeDialog));
