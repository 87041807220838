import {useEffect, useState} from "react";
import {useMemoizedFn} from "ahooks";

function useWindowSizeStore() {
    const [width, setWidth] = useState(0);

    const hanleWindowResize = useMemoizedFn((e) => {
        let h = e.target.innerWidth;
        setWidth(h);
    });

    useEffect(() => {
        const width = window.innerWidth;
        setWidth(width);

        window.addEventListener("resize", hanleWindowResize);

        return () => {
            window.removeEventListener("resize", hanleWindowResize);
        };
    }, []);

    return {
        windowWidth: width,
    };
}

export default useWindowSizeStore;
