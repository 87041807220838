import {Button} from '@material-ui/core';
import {Component} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import {connect} from 'react-redux';
import {FuseSettings} from '../../../@fuse';
import * as fuseActions from '../../../app/store/actions';
import axios, {userPrefix} from '../../../myaxios';


class Preferences extends Component {

    state = {
        loading: false
    };

    onSubmitGeneral = () => {
        this.props.startLoading();
        // console.log(this.props.settings);
        const data = {key: "current", value: this.props.settings};
        axios.put(`${userPrefix}/setting`, data)
        .then(() => {
            this.props.showMessage({
                variant: "success",
                message: "message.Information saved!",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
            this.props.finishLoading();
        }).catch(() => {
            this.props.showMessage({
                variant: "error",
                message: "message.Something went wrong!",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
            this.props.finishLoading();
        });
    };

    render() {
        return (
            <>
                <FuseSettings />
                <Button onClick={this.onSubmitGeneral} variant="contained" color={"secondary"}><Translate id="profile.Save settings" /></Button>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showMessage: (options) => dispatch(fuseActions.showMessage(options)),
        startLoading: fuseActions.startLoading,
        finishLoading: fuseActions.finishLoading
    };
};

const mapStateToProps = (state) => {
    return {
        settings: state.fuse.settings.current
    };
};

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Preferences));

