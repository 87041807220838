export const createListSlice = (set, get) => ({
    categorySelected: null,
    categoryActions: {
        onChangeSelectedCategory: (data) => {
            set({
                categorySelected: data,
            });
        },
    },
});
