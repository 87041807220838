import useSWRMutation from "swr/mutation";
import axios, {financialPrefix} from "../../../../../myaxios";

function sendRequest(url, {arg}) {
    return axios.put(url, arg);
}

function useBatchSetIncomeUser() {
    const {trigger, isMutating, data, error} = useSWRMutation(`${financialPrefix}/setIncomeUser`, sendRequest);
    return {
        run: trigger,
        isMutating,
        data,
        error,
    };
}

export default useBatchSetIncomeUser;
