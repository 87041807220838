import axios, {pmsPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_TASK_LIST = "[TASKS] GET_TASK_LIST";
export const SELECT_SUB_TASK = "[TASKS] SELECT_SUB_TASK ";
export const SELECT_TASK = "[TASKS] SELECT_TASK ";
export const SELECT_TASK_ID = "[TASKS] SELECT_TASK_ID ";
export const SELECT_STAGE_ID = "[TASK] SELECT_STAGE_ID ";
export const SELECT_PROJECT_STAGE = "[TASK] SELECT_PROJECT_STAGE ";

export function getTasksList(params) {
    // here have 翻页(从 0 开始)
    const request = axios.get(`${pmsPrefix}/myTasks`,{params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            if(response.data.content)
                dispatch({
                    type: GET_TASK_LIST,
                    payload: response.data
                });
            dispatch(finishLoading());
        });
    };
}

export function selectSubTask(subTask) {
    return {
        type: SELECT_SUB_TASK,
        subTask
    };
}

export function selectTask(task) {
    return {
        type: SELECT_TASK,
        task
    };
}

export function selectTaskId(taskId) {
    return {
        type: SELECT_TASK_ID,
        taskId
    };
}

export function selectProjectStage(projectStage) {
    return {
        type: SELECT_PROJECT_STAGE,
        projectStage
    };
}

export function selectStageId(id){

    return {
        type: SELECT_STAGE_ID,
        id
    };
}
