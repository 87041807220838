import {ListItem, ListItemText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";
import type {FC} from "react";
import {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";
import {useDg, useDGDef} from "../hooks/hooks";

const getListStyle = (isDraggingOver) => ({
    background: "white",
    border: isDraggingOver ? "2px dotted #ccc" : "2px dotted transparent",
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    boxShadow: isDragging ? "0 2px 2px rgba(0,0,0,.4)" : "none",
    // change background colour if dragging
    opacity: isDragging ? 0.7 : 1,
    backgroundColor: isDragging ? "#ccc" : "transparent",
    // styles we need to apply on draggables
    ...draggableStyle,
    top: "auto",
    left: "auto",
});

const DGCustomizeCols: FC = () => {
    const {t} = useTranslation(["datagrid", "_"]);

    const {permission, cols, onDeleteCol, hiddenCols, onChangeSetting} = useDGDef();
    const {onToggleCol} = useDg();
    const [settings, setSettings] = useState<HTMLButtonElement | null>(null);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newOrder = reorder(cols, result.source.index, result.destination.index);

        onChangeSetting("cols", newOrder);
    };

    return (
        <>
            <Button
                variant={"text"}
                size={"small"}
                className={"mr-12"}
                startIcon={<Icon children={"settings"} />}
                onClick={(e) => setSettings(e.currentTarget)}>
                {t("customizeColumns")}
            </Button>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(settings)}
                onClose={() => setSettings(null)}
                anchorEl={settings}>
                <List dense>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                    {cols.map((col, index) => (
                                        <Draggable key={col.id} draggableId={col.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(provided.draggableProps.style, snapshot.isDragging)}>
                                                    <ListItem disableGutters key={col.id} className={clsx("min-w-224 pl-12")}>
                                                        <Icon
                                                            className={" mr-8 ml-4 text-gray-400"}
                                                            style={{cursor: "grab"}}
                                                            {...provided.dragHandleProps}>
                                                            drag_indicator
                                                        </Icon>

                                                        <ListItemText
                                                            className={clsx(hiddenCols?.has(col.id) && " opacity-40")}
                                                            primary={col.label}
                                                        />
                                                        <div className={"pr-12"}>
                                                            <IconButton
                                                                size={"small"}
                                                                onClick={() => onToggleCol(col.id)}
                                                                className={"opacity-75"}
                                                                edge="end">
                                                                <Icon children={!hiddenCols?.has(col.id) ? "visibility" : "visibility_off"} />
                                                            </IconButton>
                                                            {permission === "manage" && (
                                                                <IconButton
                                                                    size={"small"}
                                                                    onClick={() => onDeleteCol(col.id)}
                                                                    className={"opacity-80 mx-12"}
                                                                    edge="end">
                                                                    <Icon children="delete_outline" />
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                    </ListItem>
                                                    {/*// @ts-ignore*/}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {/*{cols.map((col) => (*/}
                    {/*    <ListItem disableGutters key={col.id} className={clsx("min-w-224 pl-12")}>*/}
                    {/*        /!*<Icon className={" mr-8 ml-4 text-gray-400"} style={{cursor: "grab"}}>*!/*/}
                    {/*        /!*    drag_indicator*!/*/}
                    {/*        /!*</Icon>*!/*/}

                    {/*        <ListItemText className={clsx(hiddenCols?.has(col.id) && " opacity-40")} primary={col.label} />*/}
                    {/*        <div className={"pr-12"}>*/}
                    {/*            <IconButton size={"small"} onClick={() => onToggleCol(col.id)} className={"opacity-75"} edge="end">*/}
                    {/*                <Icon children={!hiddenCols?.has(col.id) ? "visibility" : "visibility_off"} />*/}
                    {/*            </IconButton>*/}
                    {/*            {permission === "manage" && (*/}
                    {/*                <IconButton size={"small"} onClick={() => onDeleteCol(col.id)} className={"opacity-80 mx-12"} edge="end">*/}
                    {/*                    <Icon children="delete_outline" />*/}
                    {/*                </IconButton>*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*    </ListItem>*/}
                    {/*))}*/}
                </List>
            </Popover>
        </>
    );
};

export default DGCustomizeCols;
