import * as fuseActions from '@fuseActions';
import {Avatar, AvatarProps} from '@material-ui/core';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
// @ts-ignore
import uniqid from 'uniqid';
import {userType} from '../../types';

function get_url_extension(url: string | undefined) {
    if (!url || url === "" || url === "null" || !(typeof url?.split === "function")) return "";
    else {
        // @ts-ignore
        return url.split(/[#?]/)[0].split(".").pop().trim();
    }
}

type Props = {
    user?: userType;
    src?: string;
    resize?: boolean;
    size?: number;
    crop?: number;
    alt?: string;
    showFull?: boolean;
    className?: string;
} & AvatarProps;


//crop: 120,
//     size: 32
export function MyAvatar({user, src, resize, size = 32, crop = 120, alt, showFull, className, ...rest}: Props) {


    const dispatch = useDispatch();

    const s = user ? user.avatar : src;
    const a = user ? user.lastName + user.firstName : alt;

    const noCrop = get_url_extension(s) === "webp";

    const newSrc =
        !s || s === "" || s === "null"
            ? ""
            : resize
                ? `${s}/resize/${resize}x${resize}`
                : crop && !noCrop
                    ? `${s}/crop/${crop}x${crop}`
                    : s;
    const letters = (!s || s === "" || s === "null") && a ? a[0] + (a[1] ? a[1] : "") : "";

    return showFull && !(!s || s === "" || s === "null") ? (
        <Avatar
            id={uniqid("avatar-FullImage-")}
            component={"span"}
            src={newSrc}
            alt={a}
            style={{cursor: "zoom-in", width: size, height: size, fontSize: size * 0.5}}
            className={clsx(className, "uppercase font-bold text-xs text-grey-darkest ")}
            onClick={(e) => {
                e.stopPropagation();
                dispatch(fuseActions.setFullImage(a, s));
                dispatch(fuseActions.turnOn("fullImage"));
            }}
            {...rest}
        >
            {letters}
        </Avatar>
    ) : (
        // @ts-ignore
        <Avatar
            id={uniqid("avatar-withoutAvatar")}
            component={"span"}
            src={newSrc}
            alt={a}
            loading={"lazy"}
            style={{width: size, height: size, fontSize: size * 0.5}}
            className={clsx(className, "uppercase font-bold text-xs text-grey-darkest ")}
            {...rest}
        >
            {letters}
        </Avatar>
    );
}

// MyAvatar.defaultProps = {
//     crop: 120,
//     size: 32
// };


