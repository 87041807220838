import {useDispatch, useSelector} from "react-redux";
import useSWR from "swr";
import type {dataType, getDataType, SearchType} from "types";
import {addParam, finishLoading, removeParam, replaceParams} from "../../app/store/actions";
import type {FilterDataType} from "./ContentWrapper";
import {isEqual} from "lodash";

type BaseReturn<T> = {
    params?: SearchType;
    isValidating: boolean;
    loading: boolean;
    error: any;
    /**
     * Replace filters or refresh
     */
    getData: getDataType;
    data?: dataType<T>;
    /**
     * Adds or removes a filter without touching other filters
     */
    filterData: FilterDataType;
};

function useList<T>(link?: string): BaseReturn<T> {
    const dispatch = useDispatch() as (action: any) => void;
    // const [params, setParams] = useState<SearchType>();
    const search = useSelector(({fuse}) => fuse.search);
    const {data, error, mutate, isLoading, isValidating} = useSWR<dataType<T> | T[]>(link && [link, search]);

    const getData = (paramsFrom?: SearchType) => {
        if (paramsFrom && !isEqual(search, paramsFrom)) {
            dispatch(replaceParams(paramsFrom));
            // setParams(paramsFrom);
        } else {
            mutate()?.then(() => {
                dispatch(finishLoading());
            });
        }
    };

    const filterData: FilterDataType = (paramsFrom) => {
        const remove = typeof paramsFrom !== "object";

        if (paramsFrom && !remove) {
            // setParams((p) => ({...p, ...paramsFrom}));
            dispatch(addParam({...paramsFrom}));
        } else if (paramsFrom && remove) {
            // const newParams = {...params};
            // delete newParams[paramsFrom];
            // setParams(newParams);
            dispatch(removeParam(paramsFrom));
        }
    };

    const isDataType = data && "totalElements" in data && "content" in data;

    return {
        filterData: filterData,
        data: isDataType
            ? {
                  ...data,
                  totalElements: data ? data.totalElements : 0,
                  totalPages: data ? data.totalPages : 1,
                  content: data ? data.content : [],
                  page: data ? data.page : 1,
                  size: data ? data.size : 0,
              }
            : isDataType !== undefined
            ? {
                  totalElements: data ? data.length : 0,
                  totalPages: 0,
                  content: data ? data : [],
                  page: 0,
                  size: data ? data.length : 0,
              }
            : undefined,
        isValidating,
        loading: isLoading,
        error,
        getData,
    };
}

export default useList;
