import {forwardRef,useEffect, useMemo, useRef, useState} from "react";
import axios from "../../myaxios";
import {Dialog, DialogContent, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Translate} from "react-localize-redux";
import {useMemoizedFn} from "ahooks";
import {isMobile} from "react-device-detect";
import Fade from '@material-ui/core/Fade';

VersionUpdateConfirm.propTypes = {};

const Transition = forwardRef(function Transition(props, ref) {
    return <Fade  ref={ref} {...props} />;
});

function VersionUpdateConfirm(props) {
    /** 当前版本号 */
    const curVersion = useRef(null);

    /** 当前版本号 */
    const newVersionRef = useRef(null);

    const currentENV = useRef(null);

    /** 定时器 */
    const timer = useRef(null);

    const [visible, setVisible] = useState(false);

    /** 轮询间隔 */
    const pollingTime = useMemo(() => {
        return 30 * 60 * 1000;
    }, []);

    /** 获取最新版本号
     * timestamp-时间戳防止请求缓存
     */
    const fetchNewVersion = useMemoizedFn(async () => {
        // 在 js 中请求首页地址不会更新页面
        const timestamp = new Date().getTime();
        const response = await axios.get(`${window.location.origin}?time=${timestamp}`);
        // 返回的是字符串，需要转换为 html
        const el = document.createElement("html");
        el.innerHTML = response.data;
        let newVersion = "0.0.0.123";
        // 拿到 版本号
        const metaList = el.querySelectorAll("meta");
        if (metaList.length) {
            metaList.forEach((item) => {
                if (item.name === "version_no") {
                    newVersion = item.content;
                }
            });
        }
        newVersionRef.current = newVersion;
        console.log("fetchNewVersion", curVersion.current, newVersion);
        if (currentENV.current === "development") return;
        if (newVersion && newVersion !== curVersion.current && !visible) {
            // 版本更新，弹出提示
            setVisible(true);
        } else if (newVersion && newVersion === curVersion.current && visible) {
            setVisible(false);
        }
    });

    /** 开启定时器 */
    const setTimer = useMemoizedFn(() => {
        timer.current = setInterval(fetchNewVersion, pollingTime);
    });

    /** 清除定时器 */
    const clearTimer = useMemoizedFn(() => {
        if (timer.current) {
            clearInterval(timer.current);
            timer.current = null;
        }
    });

    /** 获取当前版本号 */
    const getCurrentVersion = useMemoizedFn(() => {
        let version = "0.0.0";
        const metaList = document.getElementsByTagName("meta");
        if (metaList.length > 0) {
            for (let i = 0, len = metaList.length - 1; i < len; i++) {
                const item = metaList[i];
                if (item && item.name === "version_no") {
                    version = item.content;
                }
            }
        }
        curVersion.current = version;
    });

    /** 获取当前版本号 */
    const getVersion = useMemoizedFn(() => {
        getCurrentVersion();
        fetchNewVersion().then(() => {});
    });

    /** 浏览器窗口是否显示隐藏 */
    const onVisibilityChange = useMemoizedFn(() => {
        // eslint-disable-next-line prefer-destructuring
        if (!document.hidden) {
            setTimer();
            getVersion();
        } else {
            clearTimer();
        }
    });

    useEffect(() => {
        currentENV.current = process.env.NODE_ENV;
        getVersion();
        setTimer();
        return () => {
            clearTimer();
        };
    }, []);


    /** 立即刷新 */
    const handleConfirm = useMemoizedFn(() => {
        window.location.reload(true);
        if (isMobile) {
            window.postMessage("exitApp");
        }
        setVisible(false);
    });

    const handleCancel = useMemoizedFn(() => {
        setVisible(false);
    });

    return (
        <Dialog TransitionComponent={Transition}  fullWidth maxWidth={"sm"} open={visible} aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">{<Translate id={"_.tips"} />}</DialogTitle>
            <DialogContent>
                <div className={"flex flex-row justify-center items-center"}>
                    <Typography variant={"body1"} className="text-center">
                        {<Translate id={"_.New version detected, please click update!"} />}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    <Translate id={"_.cancel"} />
                </Button>
                <Button onClick={handleConfirm} color="secondary">
                    <Translate id={"_.update"} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default VersionUpdateConfirm;
