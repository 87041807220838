import {ListItemSecondaryAction} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {MyAvatar, NameLabel, UserView} from "@ui";
import axios, {firmPrefix} from "myaxios";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import type {StaffType} from "types";
import AsyncSelect from "../../../../../utils/Forms/AsyncSelect";
import {useWorkPlan} from "../useWorkPlan";

const LeaderInfo = () => {
    const [WPChangeLeader, setWPChangeLeader] = useState(false);

    const {wp, canEdit, refresh} = useWorkPlan();

    const [loading, setLoading] = useState(false);
    // const [user, setUser] = useState(
    //     wp.leader
    //         ? {
    //               value: wp.leader.user.id,
    //               label: <UserView user={wp.leader} />,
    //           }
    //         : null
    // );

    // const searchContact = (inputValue, callback) => {
    //     axios
    //         .get(`${firmPrefix}/firm/staffs?size=8`, {
    //             params: {
    //                 keyword: inputValue,
    //             },
    //         })
    //         .then((res) => {
    //             const users = res.data.content.map((item) => ({
    //                 value: item.user.id,
    //                 label: <UserView user={item.user} />,
    //             }));
    //             callback(users);
    //         });
    // };

    const saveLeader = (userId?: string) => {
        setLoading(true);
        const data = {
            workplanId: wp?.id,
            leader: userId
        };
        axios.put(`${firmPrefix}/setLeader`, data).then(() => {
            refresh().then(() => {
                setWPChangeLeader(false);
                setLoading(false);
            });
        });
    };

    return !wp ? (
        <></>
    ) : WPChangeLeader ? (
        <>
            <div className={"flex w-full items-center pl-12 pb-12 pt-12"}>
                <div className="flex-1">
                    {/*<Translate>*/}
                    {/*    {({translate}) => (*/}
                    {/*        <Select*/}
                    {/*            required*/}
                    {/*            loadOptions={searchContact}*/}
                    {/*            defaultOptions*/}
                    {/*            fullWidth*/}
                    {/*            // styles={selectStyles}*/}
                    {/*            components={components}*/}
                    {/*            value={user}*/}
                    {/*            onChange={changeAssignee}*/}
                    {/*            noOptionsMessage={() => translate("_.no option")}*/}
                    {/*            placeholder={translate("wps.Search a user")}*/}
                    {/*            isClearable*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</Translate>*/}
                    {/*// @ts-ignore*/}
                    <AsyncSelect<StaffType>
                        id={"selectLeader"}
                        data-cy={"select-leader"}
                        defaultOptions
                        defaultValue={wp.leader?.user}
                        size={"small"}
                        name={"leader"}
                        variant={"outlined"}
                        onChange={(e, newValue) => saveLeader(newValue?.user?.id)}
                        getOptionLabel={(option) => option.fullName || option.username || option.id}
                        link={`${firmPrefix}/firm/staffs?size=8`}
                        renderOption={(option) => <UserView user={option?.user} />}
                    />
                </div>

                <IconButton onClick={() => setWPChangeLeader(false)}>
                    <Icon>close</Icon>
                </IconButton>
            </div>
        </>
    ) : (
        <List dense className={loading ? "loading" : "m-0 p-0"}>
            <ListItem disableGutters className={"pl-8"}>
                {!wp.leader ? (
                    <>
                        <ListItemIcon className={"min-w-32"} data-cy={"empty-leader"}>
                            <Icon>face</Icon>
                        </ListItemIcon>
                        <ListItemText primary={<Translate id={"wps.No leader is selected"} />} />
                    </>
                ) : (
                    <>
                        <div>
                            <MyAvatar className={"mr-12"} user={wp.leader.user} />
                        </div>
                        <ListItemText primary={<NameLabel user={wp.leader.user} />} secondary={<Translate id={"wps.Leader"} />} />
                    </>
                )}
                {canEdit && (
                    <ListItemSecondaryAction>
                        <IconButton data-cy={"edit-leader"} size={"small"} onClick={() => setWPChangeLeader(true)}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        </List>
    );
};

export default LeaderInfo;
