import {useEffect, useMemo, useState} from "react";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import {Dialog, DialogActions, DialogContent, Typography} from "@material-ui/core";
import {usePutSetCategory} from "../definitions/hooks";
import {useProcessStore} from "../../state/useProcessStore";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Translate} from "react-localize-redux";
import Chip from "@material-ui/core/Chip/Chip";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useMemoizedFn} from "ahooks";
import {AsyncSelectQuery} from "../../../../../utils/RootComponents";
import axios, {flowPrefix} from "../../../../../myaxios";
import {map} from "lodash";
import {AddCategory, NEW_CATEGORY_TAG, ProcessCategoryView} from "../../utils/utils";

function DefinitionSetCategoryDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.definitionSetCategory,
    });

    const selectedDefinition = useProcessStore((state) => state.selectedDefinition);

    const onChangeSelectedDefinition = useProcessStore((state) => state.onChangeSelectedDefinition);

    const putSetCategoryActions = usePutSetCategory();

    const [loading, setLoading] = useState(false);

    const [categoryValue, setCategoryValue] = useState("");

    const disabledSubmit = useMemo(() => {
        return !categoryValue || categoryValue === "";
    }, [categoryValue]);

    useEffect(() => {
        return () => {
            onChangeSelectedDefinition(null);
        };
    }, []);

    const getCategories = useMemoizedFn((params) => {
        return axios.get(`${flowPrefix}/processCategories`, {params: params});
    });

    const handleCreateCategoryLabel = useMemoizedFn((datas) => {
        return new Promise((resolve, reject) => {
            const results = map(datas, (o) => {
                return {
                    value: o.id,
                    label: <ProcessCategoryView category={o} />,
                };
            });
            const res = [
                {
                    value: `${NEW_CATEGORY_TAG}`,
                    label: <AddCategory />,
                },
                ...results,
            ];
            resolve(res);
        });
    });

    const handleCategoryValueChange = useMemoizedFn((value) => {
        setCategoryValue(value);
    });

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSure = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        const params = {
            processDefinitionKey: selectedDefinition?.key,
            categoryId: categoryValue?.value,
        };
        putSetCategoryActions
            .run(params)
            .then((res) => {
                if (res.status === 200) {
                    finshParams?.onFinish();
                    onTurnOffDialog();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return (
        <Dialog classes={{paper: "md:overflow-visible ", root: "md:overflow-visible"}} {...dialogProps}>
            <div className={loading ? "flex flex-col  loading" : "flex flex-col"}>
                <DialogTitle>
                    <div className={"flex flex-row items-center"}>
                        <Typography variant={"h6"} className={"inline-flex mr-1"}>
                            {<Translate id={"_.set processDefinition category"} />}
                        </Typography>
                        <div className={"flex flex-row items-center"}>
                            <Chip size="small" label={selectedDefinition?.name} color="secondary" />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className={"overflow-visible"}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <AsyncSelectQuery
                                name={"name"}
                                standaloneMode
                                onChangeValue={handleCategoryValueChange}
                                onCreateSelectValue={handleCreateCategoryLabel}
                                dataSourceRequest={getCategories}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>
                        {<Translate id={"_.cancel"} />}
                    </Button>
                    <Button disabled={disabledSubmit} variant="contained" color="secondary" onClick={handleSure}>
                        {<Translate id={"_.sure"} />}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default DefinitionSetCategoryDialog;
