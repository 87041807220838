import {lazy} from "react";

export const DevelopersConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/settings/:tab",
            component: lazy(() => import("./settings/Settings")),
        },
        {
            path: "/settings",
            component: lazy(() => import("./settings/Settings")),
        },
        {
            path: "/apis",
            component: lazy(() => import("./api-manager/APIsApp")),
        },
        {
            path: "/menus",
            component: lazy(() => import("./menu-manager/Page")),
        },
        {
            path: "/errors",
            component: lazy(() => import("./errorReports/Page")),
        },
        {
            path: "/clickCount",
            component: lazy(() => import("./clickCount/Page")),
        },
    ],
};
