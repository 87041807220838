import {lazy} from "react";

export const ConfigDeals = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/deal/:id",
            component: lazy(() =>
                import("./deal/SinglePage")
            )
        },
        {
            path     : "/deal/:id/",
            component: lazy(() =>
                import("./deal/SinglePage")
            )
        },
        {
            path     : "/marketing/deals",
            component: lazy(() =>
                import("./list/Page")
            )
        }
    ]
};
