import {finishLoading, startLoading} from "@fuseActions";
import axios, {financialPrefix} from "../../../../../myaxios";

export const GET_ATTACHMENTS_HISTORY = "[GET_ATTACHMENTS_HISTORY]";

export const GET_EXCEL_TABLE_COLS = "[GET_EXCEL_TABLE_COLS]";

export const GET_EXCEL_TABLE_ROWS = "[GET_EXCEL_TABLE_ROWS]";

export const GET_TABLE_ORIGINAL_DATASOURCES = "[GET_TABLE_ORIGINAL_DATASOURCES]";

export function getTransactionAttachments(params) {
    const request = params
        ? axios.get(`${financialPrefix}/transactionAttachments`, {params})
        : axios.get(`${financialPrefix}/transactionAttachments`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_ATTACHMENTS_HISTORY,
                payload: res.data
            });
            dispatch(finishLoading());
        });
    };
}

export function getExcelTableCols(cols) {
    return {
        type: GET_EXCEL_TABLE_COLS,
        payload: cols
    };
}

export function getExcelTableRows(rows) {
    return {
        type: GET_EXCEL_TABLE_ROWS,
        payload: rows
    };
}

export function getTableOriginalDatas(array) {
    return {
        type: GET_TABLE_ORIGINAL_DATASOURCES,
        payload: array
    };
}
