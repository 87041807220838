import {AppBar, Hidden, Toolbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {lighten} from "@material-ui/core/styles";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import * as fuseActions from "@fuseActions";
import {memo} from "react";
import {Translate} from "react-localize-redux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios, {commonPrefix} from "../../../../myaxios";
import ToggleView from "../../../components/ToggleView";
import ZoomControlButtons from "../../../components/ZoomControlButtons";

const useStyles = makeStyles((theme) => ({
    minimizedButton: {
        height: "50px",
        marginRight: 5,
        borderRadius: 0,
        backgroundColor: lighten(theme.palette.secondary.light, 0.75),
        borderBottom: `4px solid ${theme.palette.secondary.dark}`,
        "&:hover": {
            background: lighten(theme.palette.secondary.light, 0.4),
        },
    },
}));

function FooterLayout1(props) {
    const dispatch = useDispatch();
    const footerTheme = useSelector(({fuse}) => fuse.settings.footerTheme, shallowEqual);
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const classes = useStyles();

    const onClick = (key) => (e) => {
        const data = {
            type: "openMinimized",
            name: key,
        };
        axios.post(`${commonPrefix}/clickCount`, data);
        dispatch(fuseActions.turnOn(key));
    };

    return (
        <ThemeProvider theme={footerTheme}>
            <Hidden smDown>
                <AppBar id="fuse-footer" style={{height: "50px", minHeight: "50px", zIndex: 20000}} className="relative p-0 " color="default">
                    <Toolbar style={{height: "50px", minHeight: "50px"}} className="p-0 m-0 z-10 flex justify-between items-center">
                        <div>
                            {Object.keys(dialogs).map(
                                (key) =>
                                    dialogs[key] === 2 && (
                                        <Button key={key} className={classes.minimizedButton} onClick={onClick(key)}>
                                            {<Translate id={`_.${key}`} />}
                                        </Button>
                                    )
                            )}
                        </div>
                        {/*<div className='flex items-center mr-2'>*/}
                        {/*        <span className={"text-gray-600 text-center"}>*/}
                        {/*            @ { <Translate id={"_.Pandda Master"}/> }  蜀ICP备18024878号*/}
                        {/*        </span>*/}
                        {/*</div>*/}
                        <div className={"flex items-center"}>
                            <small className={"text-gray-400 text-center mr-3"}>@{<Translate id={"_.Pandda Master"} />} 蜀ICP备18024878号</small>
                            <ZoomControlButtons />
                            <ToggleView />
                        </div>
                    </Toolbar>
                </AppBar>
            </Hidden>
        </ThemeProvider>
    );
}

export default memo(FooterLayout1);
