import type {ButtonProps} from "@material-ui/core/Button";
import {Translate} from "react-localize-redux";

export const confirmDefaultOptions = {
    confirmationText: <Translate id={"_.Delete"} />,
    cancellationText: <Translate id={"_.Cancel"} />,
    confirmationButtonProps: {
        autoFocus: !(!process.env.NODE_ENV || process.env.NODE_ENV === "development"),
        variant: "contained",
        "data-cy": "confirm-btn",
        className: "bg-red-light hover:bg-red-dark",
    } as ButtonProps,
};
