import {Icon, IconButton, ListItem, ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {useState} from "react";
import type {departmentType} from "../../types";
import {useTranslation} from "react-i18next";

type Props = {
    _department: departmentType;
    selected: string;
    changeDepartment(department: departmentType): void;
    disableRoot?: boolean;
};
const TreeItem = (props: Props) => {
    const {t} = useTranslation();
    const {_department, selected, changeDepartment, disableRoot} = props;
    const [openChildren, setOpenChildren] = useState(false);
    const toggleDepartment = (e) => {
        e.stopPropagation();
        setOpenChildren(!openChildren);
    };

    return _department ? (
        <>
            <ListItem
                disableGutters
                classes={{root: "pl-1"}}
                button
                component={"li"}
                selected={selected === _department.id}
                onClick={() => changeDepartment(_department)}>
                {_department.children && _department.children.length > 0 && (
                    <ListItemIcon className={"mx-0 min-w-28"}>
                        <IconButton onClick={toggleDepartment} size={"small"}>
                            <Icon fontSize={"small"}>{openChildren ? "keyboard_arrow_down" : "keyboard_arrow_right"}</Icon>
                        </IconButton>
                    </ListItemIcon>
                )}
                <ListItemText
                    classes={{root: !(_department.children && _department.children.length > 0) ? "pl-16" : ""}}
                    primary={_department.name === "root" ? `${t("userSelector.root")}` : _department.name}
                />
            </ListItem>
            {_department.children && _department.children.length > 0 && openChildren && (
                <List dense classes={{root: "ml-16 border-l-2 border-gray-darker border-dashed"}}>
                    {!disableRoot && (
                        <TreeItem
                            _department={{
                                active: true,
                                name: "root",
                                id: _department.id + "root",
                                parentDepartment: _department,
                            }}
                            selected={selected}
                            changeDepartment={changeDepartment}
                        />
                    )}
                    {_department.children.map((_child, i) => (
                        <TreeItem _department={_child} key={i} disableRoot={disableRoot} selected={selected} changeDepartment={changeDepartment} />
                    ))}
                </List>
            )}
        </>
    ) : null;
};

export default TreeItem;
