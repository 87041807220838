import {concat, filter, indexOf} from "lodash";

export const createGDialogSlice = (set, get) => ({
    dialogRegistries: [],
    /**
     * @param {Object} config - dialog 的配置
     */
    config: null,
    /**
     * @param {Object} finshParams - 当 dialog 成功执行完所有请求后，进行的刷新操作， 比如 list 或者 single 的刷新
     * @param {function} finshParams.onFinsh - 当 dialog 成功执行完所有请求后，进行的刷新操作， 比如 list 或者 single 的刷新
     */
    finshParams: null,
    onChangeFinshParams: (obj) => {
        set({
            finshParams: obj,
        });
    },
    onChangeConfig: (options) => {
        set({
            config: options,
        });
    },
    /**
     * @param {GDialogRegistries} tag -  dialog 全局注册表
     */
    onChangeDialogRegistries: (tag) => {
        const originals = get().dialogRegistries;
        const findIndex = indexOf(originals, tag);
        let results;
        if (findIndex === -1) {
            results = concat(originals, tag);
        } else {
            results = filter(originals, (o) => {
                return o !== tag;
            });
        }
        set({
            dialogRegistries: results,
        });
    },
});
