//useSetIncomeTag

import axios, {financialPrefix} from "../../../../../myaxios";
import useSWRMutation from "swr/mutation";

function sendRequest(url, {arg}) {
    return axios.put(url, arg);
}

function useSetIncomeTag() {
    const {trigger, isMutating, data, error} = useSWRMutation(`${financialPrefix}/setIncomeTag`, sendRequest);

    return {
        run: trigger,
        isMutating,
        data,
        error,
    };
}

export default useSetIncomeTag;
