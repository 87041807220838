//createInstanceListSlice

import produce from "immer";

export const createInstanceListSlice = (set) => ({
    instances: [],
    selected: [],
    selectedInstance: [],
    openMore: [],
    onChangeOpenMore: (t) => {
        set({
            openMore: t,
        });
    },
    onChangeSelectedInstance: (t) => {
        set({
            selectedInstance: t,
        });
    },
    onUpdateSelected: (t) => {
        set(
            produce((state) => {
                state.selected = t;
            })
        );
    },
    onChangeInstances: (t) => {
        set(
            produce((state) => {
                state.instances = t;
            })
        );
    },
});
