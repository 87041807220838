import {useEffect} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {DialogWrapper} from "@ui";
import {isAfter, isBefore, isToday, setHours, setMinutes} from "date-fns";

import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router";
import {useLocation} from "react-router-dom";
import {FuseSuspense} from "../../../@fuse";
import NotificationsList from "../../notifications/NotificationsList";
import { turnOn, turnOff } from '@fuseActions';
import DashboardContent from "./DashboardContent";
import transJson from "./translation/dashboard.json";

const DashboardDialog = (props) => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();

    useEffect(() => {
        props.addTranslation(transJson);
        if (pathname === "/dashboard") {
            localStorage.setItem("showDashboardDate", +new Date());
        } else if (pathname !== "/login") {
            const timestamp = localStorage.getItem("showDashboardDate");

            if (timestamp) {
                //                console.log({
                //                    //                    compareTime: setMinutes(setHours(new Date(), 13), 59),
                //                    timestamp: timestamp,
                //                    Local: new Date(+timestamp),
                //                    isToday: isToday(+timestamp),
                //                    isBefore: isBefore(new Date(+timestamp), new Date().setHours(14, 0, 0)),
                //                    isAfternoon: isAfter(new Date(), new Date().setHours(14, 0, 0)),
                //                    now: new Date(),
                //                });

                if (!isToday(+timestamp)) {
                    dispatch(turnOn("dailyDashboard"));
                } else if (
                    isBefore(new Date(+timestamp), setMinutes(setHours(new Date(), 13), 59)) &&
                    isAfter(new Date(), new Date().setHours(14, 0, 0))
                ) {
                    dispatch(turnOn("dailyDashboard"));
                }
            } else {
                dispatch(turnOn("dailyDashboard"));
            }
        }
    }, [props.match]);

    const closeDialog = () => {
        dispatch(turnOff("dailyDashboard"));
        localStorage.setItem("showDashboardDate", +new Date());
    };

    return (
        <DialogWrapper
            onCloseCallback={closeDialog}
            title={<Translate id={"dashboard.What you have been missing"} />}
            name={"dailyDashboard"}
            maxWidth={"xl"}
        >
            {() => (
                <Grid container spacing={0}>
                    <Grid item md={9} xs={12}>
                        <FuseSuspense>
                            <DashboardContent />
                        </FuseSuspense>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className={"rounded-8 mt-12 mr-12"} onClick={closeDialog}>
                            <NotificationsList />
                        </Card>
                    </Grid>
                </Grid>
            )}
        </DialogWrapper>
    );
};

export default withLocalize(withRouter(DashboardDialog));
