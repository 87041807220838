import {createContext, useContext} from "react";

export type MainContextType = {
    sidebar: boolean;
    sidebarActions: {
        toggle: () => void;
        setLeft: () => void;
        setRight: () => void;
    };
    saving: boolean;
    savingActions: {
        toggle: () => void;
        setLeft: () => void;
        setRight: () => void;
    };
    miniFilters?: boolean;
    // drawer: boolean;
    // drawerActions: {
    //     toggle:() => void;
    //     setLeft:() => void;
    //     setRight:() => void;
    // };
    link: string;
};

export const MainContext = createContext<MainContextType>({
    sidebar: false,
    sidebarActions: {
        toggle: () => {},
        setLeft: () => {},
        setRight: () => {},
    },
    saving: false,
    savingActions: {
        toggle: () => {},
        setLeft: () => {},
        setRight: () => {},
    },
    link: "",
    miniFilters: false,
});

export const useMainContext = () => useContext(MainContext);
