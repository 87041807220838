import {Component} from "react";
import {connect} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import {bindActionCreators} from "redux";
import * as fuseActions from "@fuseActions";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {withLocalize} from "react-localize-redux";

class ProgressSnackbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {progress} = this.props;
        return (
            <Snackbar
                ContentProps={{
                    classes: {message: "w-full pt-0"},
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                // onClose={this.props.finishProgress}
                open={progress.state}
                message={
                    <div className="flex items-start" id={"progressSnackbar"}>
                        <div className="flex-1">
                            {progress.files.length > 0 &&
                                progress.files.map((file,index) => (
                                    <div key={`${file.title}-${index}`}>
                                        <div className={"mt-4"}>
                                            <span data-cy={"downloading-file"}>{file.title}</span>: {file.percent}%
                                        </div>
                                        <div className={"pt-16"}>
                                            <LinearProgress variant="determinate" value={file.percent} />
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <IconButton
                            className={"ml-12 -mb-8 -mr-8"}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.finishProgress}
                        >
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                }
            />
        );
    }
}

function mapStateToProps({fuse}) {
    return {
        progress: fuse.progress,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            finishProgress: fuseActions.finishProgress,
        },
        dispatch
    );
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(ProgressSnackbar));
