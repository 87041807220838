import {FormLabel, Icon} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {MyTooltip} from "@ui";
import classNames from "classnames";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import DatePickerWrapper from "utils/DatePickerWrapper/DatePickerWrapper";
import {DealSelector} from "../../marketingTools";

const Step0 = ({formData, handleFormData}) => {
    const [complete, setComplete] = useState(false);

    const [startDateWarn, setStartDateWarn] = useState("");

    const [endDateWarn, setEndDateWarn] = useState("");

    const handleStartDate = (start) => {
        if (formData.endDate) {
            const startTime = new Date(start).getTime();
            const endTime = new Date(formData.endDate).getTime();

            if (startTime < endTime) {
                setStartDateWarn("");
                setEndDateWarn("");
                handleFormData("startDate")({target: {value: start}});
            } else {
                setStartDateWarn("market.Start date should be earlier than end date");
            }
        } else {
            setStartDateWarn("");
            setEndDateWarn("");
            handleFormData("startDate")({target: {value: start}});
        }
    };

    const handleEndDate = (end) => {
        if (formData.startDate) {
            const startTime = new Date(formData.startDate).getTime();
            const endTime = new Date(end).getTime();

            if (startTime < endTime) {
                setStartDateWarn("");
                setEndDateWarn("");
                handleFormData("endDate")({target: {value: end}});
            } else {
                setEndDateWarn("market.End date should be later than start date");
            }
        } else {
            setStartDateWarn("");
            setEndDateWarn("");
            handleFormData("endDate")({target: {value: end}});
        }
    };

    return (
        <div className={classNames("max-h-0", complete ? "overflow-visible" : " overflow-hidden")}>
            <Typography variant={"h6"}>
                <Translate id={"market.More Information"} />
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <DealSelector formData={formData} handleFormData={handleFormData} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Translate>
                        {({translate}) => (
                            <DatePickerWrapper
                                disableToolbar
                                variant="inline"
                                fullWidth
                                label={translate("market.reminder date")}
                                autoOk
                                minDate={new Date("2000-1-1")}
                                format={"yyyy-MM-dd"}
                                emptyLabel={translate("market.reminder is null,click to set")}
                                value={new Date(formData.reminder)}
                                onChange={(reminder) => handleFormData("reminder")({target: {value: reminder}})}
                            />
                        )}
                    </Translate>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Translate>
                        {({translate}) => (
                            <DatePickerWrapper
                                disableToolbar
                                variant="inline"
                                error={startDateWarn !== ""}
                                helperText={startDateWarn !== "" ? translate(startDateWarn) : " "}
                                label={translate("market.Start date")}
                                autoOk
                                emptyLabel={translate("market.click to set startDate")}
                                value={new Date(formData.startDate)}
                                minDate={new Date("2000-1-1")}
                                format={"yyyy-MM-dd"}
                                onChange={(start) => handleStartDate(start)}
                            />
                        )}
                    </Translate>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Translate>
                        {({translate}) => (
                            <DatePickerWrapper
                                disableToolbar
                                variant="inline"
                                format={"yyyy-MM-dd"}
                                error={endDateWarn !== ""}
                                helperText={endDateWarn !== "" ? translate(endDateWarn) : ""}
                                minDate={new Date("2000-1-1")}
                                label={translate("market.End date")}
                                emptyLabel={translate("market.click to set endDate")}
                                autoOk
                                value={new Date(formData.endDate)}
                                onChange={(end) => handleEndDate(end)}
                            />
                        )}
                    </Translate>
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormLabel>{<Translate id={"market.Priority"} />}</FormLabel>
                    <div className={"radius bg-grey-light border flex w-full text-center mt-1 h-40 overflow-hidden rounded"}>
                        <MyTooltip title={<Translate id={"market.high"} />}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleFormData("priority")({target: {value: "high"}});
                                }}
                                className={classNames(
                                    "flex-1 items-center flex justify-center",
                                    formData.priority === "high" && "text-white bg-red"
                                )}>
                                <Translate id={"market.high"} />
                            </button>
                        </MyTooltip>
                        <MyTooltip title={<Translate id={"market.general"} />}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleFormData("priority")({target: {value: "general"}});
                                }}
                                className={classNames(
                                    "flex-1 items-center border-r-1 border-l-1  flex justify-center",
                                    formData.priority === "general" && "text-white bg-orange"
                                )}>
                                <Translate id={"market.general"} />
                            </button>
                        </MyTooltip>
                        <MyTooltip title={<Translate id={"market.priority"} />}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleFormData("priority")({target: {value: "low"}});
                                }}
                                className={classNames(
                                    "flex-1 items-center flex justify-center",
                                    formData.priority === "low" && "text-white bg-green"
                                )}>
                                <Translate id={"market.low"} />
                            </button>
                        </MyTooltip>
                    </div>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormLabel>{<Translate id={"market.status"} />}</FormLabel>
                    <div className={"radius bg-grey-light border flex w-full text-center mt-1 h-40 overflow-hidden rounded"}>
                        <MyTooltip title={<Translate id={"market.open"} />}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    // this.setState({status: "open"})
                                    handleFormData("status")({target: {value: "open"}});
                                }}
                                className={classNames(
                                    "flex-1 items-center flex justify-center border-r-1",
                                    formData.status === "open" && "text-white bg-red"
                                )}>
                                <Icon>work_outline</Icon>
                            </button>
                        </MyTooltip>
                        <MyTooltip title={<Translate id={"market.close"} />}>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    // this.setState({status: "close"})
                                    handleFormData("status")({target: {value: "close"}});
                                }}
                                className={classNames(
                                    "flex-1 items-center flex justify-center",
                                    formData.status === "close" && "text-white bg-green"
                                )}>
                                <Icon>done_all</Icon>
                            </button>
                        </MyTooltip>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormLabel>{<Translate id={"market.result"} />}</FormLabel>

                    <div className="radius bg-grey-light border flex w-full text-center mt-1 h-40 overflow-hidden rounded">
                        <MyTooltip title={<Translate id={"market.bad"} />}>
                            <button
                                onClick={() => handleFormData("result")({target: {value: "bad"}})}
                                className={classNames(
                                    "flex-1 items-center flex justify-center",
                                    formData.result === "bad" && "text-white bg-red rounded"
                                )}>
                                <Icon>sentiment_dissatisfied</Icon>
                            </button>
                        </MyTooltip>
                        <MyTooltip title={<Translate id={"market.general"} />}>
                            <button
                                onClick={() => handleFormData("result")({target: {value: "general"}})}
                                className={classNames(
                                    "flex-1 items-center flex justify-center",
                                    formData.result === "general" && "text-white bg-orange"
                                )}>
                                <Icon>mood</Icon>
                            </button>
                        </MyTooltip>
                        <MyTooltip title={<Translate id={"market.good"} />}>
                            <button
                                onClick={() => handleFormData("result")({target: {value: "good"}})}
                                className={classNames(
                                    "flex-1 items-center flex justify-center",
                                    formData.result === "good" && "text-white bg-green rounded"
                                )}>
                                <Icon>sentiment_satisfied</Icon>
                            </button>
                        </MyTooltip>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Step0;
