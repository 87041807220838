import {Dexie, type EntityTable} from "dexie";

type UserSettings = {
    language: "cn" | "en";
    listView: "0" | "1";
    userId: string;
    rowPerPage: number;
    WPSViewColumn?: number;
    WPSView?: "NewDaily" | "Daily" | "Weekly";
    hideDoneChecklist: boolean;
};

const db = new Dexie("personalized") as Dexie & {
    // dataGridCells: EntityTable<CellType, "id">;
    settings: EntityTable<UserSettings, "userId">;
    filters: EntityTable<Record<string, unknown>, "id">;
    latest: EntityTable<Record<string, unknown>, "id">;
};

db.version(1).stores({
    settings: "&userId",
    filters: "&key, userId",
    latest: "++id, key, userId",
});

export {db};
