import {SelectUsers} from "@forms";
import {turnOff, turnOn} from "@fuseActions";
import {Icon} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import FormLabel from "@material-ui/core/FormLabel";
import {MyAvatar, NameLabel} from "@ui";
import type {ReactNode} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import type {userType} from "types";

type Props = {
    users?: userType[];
    title: ReactNode;
    addUsers: (users: userType[]) => void;
    deleteUser: (user: userType) => void;
    name: string;
    hiddenAvatar?: boolean;
    required?: boolean;
};

const UsersSelector = ({users, title, addUsers, deleteUser, name, hiddenAvatar, required}: Props) => {
    const dispatch = useDispatch();
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const openDialog = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(turnOn(name));
        dispatch(turnOn("selectUsers"));
    };

    return (
        <>
            <div className="w-full border-1 rounded-8 mt-12 bg-gray-50 overflow-y-scroll max-h-224">
                {users && users.length > 0 ? (
                    <div className={"px-20 py-16"}>
                        <FormLabel className={"block mb-16"}>{title}</FormLabel>

                        {users.map((user) => {
                            return hiddenAvatar ? (
                                <Chip
                                    data-cy={"selected-user-item"}
                                    key={user.id}
                                    color="secondary"
                                    label={<NameLabel user={user} />}
                                    onDelete={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        deleteUser(user);
                                    }}
                                    className="mr-2 mb-2"
                                />
                            ) : (
                                <Chip
                                    data-cy={"selected-user-item"}
                                    key={user.id}
                                    avatar={<MyAvatar size={24} user={user} />}
                                    color="secondary"
                                    label={<NameLabel user={user} />}
                                    onDelete={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        deleteUser(user);
                                    }}
                                    className="mr-2 mb-2"
                                />
                            );
                        })}
                        <Chip icon={<Icon>add</Icon>} label={<Translate id={"_.Add more"} />} onClick={openDialog} className="mr-2 mb-2" />
                    </div>
                ) : (
                    <button
                        data-cy={name}
                        onClick={openDialog}
                        className="w-full px-16 py-20 hover:bg-gray-100 font-bold  block text-grey-dark text-left">
                        <FormLabel>
                            <Icon className={"align-bottom"}>add</Icon> {<Translate id={"_.add"} />} {title}{" "}
                            {required && <span className="text-red">*</span>}
                        </FormLabel>
                    </button>
                )}
            </div>
            {((name && dialogs[name]) || !name) && (
                <SelectUsers
                    onClose={() => {
                        dispatch(turnOff("selectUsers"));
                        dispatch(turnOff(name));
                    }}
                    disableWithoutDepartment
                    members={users}
                    // cancelCallback={() => dispatch(fuseActions.turnOff(name))}
                    onSubmit={addUsers}
                    title={title}
                />
            )}
        </>
    );
};

export default UsersSelector;
