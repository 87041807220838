import {Button, DialogContent, Grid, TextField} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import Select from "react-select/async";
import {components} from "utils/Select2Components";
import axios, {commonPrefix} from "../../../../../myaxios";

const CitySelectorItem = ({item}) => (
    <span className={"flex items-center"}>
        <strong className={"mr-2"}>{item.city}</strong>
        <small className={"text-grey-dark"}>
            {item.country}, {item.province}
        </small>
    </span>
);

const Step2 = ({selectedItem, formData, handleFormData}) => {
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [country, setCountry] = useState("");
    const [chooseCountry, setChooseCountry] = useState(false);
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        if (selectedItem && selectedItem.address && selectedItem.address.city !== "") {
            setCity({
                value: selectedItem.address,
                label: <CitySelectorItem item={selectedItem.address} />
            });
        }
    }, []);

    const searchCity = (inputValue, callback) => {
        axios
        .get(`${commonPrefix}/addressList?size=8`, {
            params: {
                city: inputValue,
                country,
                province
            }
        })
        .then((res) => {
            const data = res.data.map((item) => ({
                value: item,
                label: <CitySelectorItem item={item} />
            }));
            callback(data);
        });
    };
    const searchCountries = (inputValue, callback) => {
        axios
        .get(`${commonPrefix}/addressList?size=8`, {
            params: {
                name: inputValue
            }
        })
        .then((res) => {
            const data = res.data.map((item) => ({
                value: item,
                label: <strong className={"mr-2"}>{item.city}</strong>
            }));
            callback(data);
        });
    };
    const searchStates = (inputValue, callback) => {
        axios
        .get(`${commonPrefix}/addressList?size=8`, {
            params: {
                city: inputValue,
                country
            }
        })
        .then((res) => {
            const data = res.data.map((item) => ({
                value: item,
                label: <strong className={"mr-2"}>{item.city}</strong>
            }));
            callback(data);
        });
    };

    const changeCity = (value) => {
        if (value) {
            handleFormData("address")({
                target: {
                    value: {
                        city: value.value.city,
                        country: value.value.country,
                        province: value.value.province
                    }
                }
            });
            setCity(value);
        } else {
            setCity("");
            handleFormData("address")({
                target: {
                    value: {
                        city: "",
                        country: "",
                        province: ""
                    }
                }
            });
        }
    };
    const changeCountry = (value) => {
        if (value) {
            handleFormData("address")({
                target: {
                    value: {
                        country: "string",
                        province: "string"
                    }
                }
            });
            setCountry(value);
        } else {
            setCountry("");
            handleFormData("address")({
                target: {
                    value: {
                        city: "",
                        country: "",
                        province: ""
                    }
                }
            });
        }
    };
    const changeState = (value) => {
        if (value) {
            handleFormData("address")({
                target: {
                    value: {
                        province: "string"
                    }
                }
            });
            setProvince(value);
        } else {
            setProvince("");
            handleFormData("address")({
                target: {
                    value: {
                        city: "",
                        province: ""
                    }
                }
            });
        }
    };

    return (
        <div className={classNames("max-h-0", complete ? "overflow-visible" : " overflow-hidden")}>
            <Divider className="mt-16" />
            <DialogContent className={"overflow-visible"}>
                <Typography variant={"h6"}>
                    <Translate id={"market.Contact Information"} />
                </Typography>
                <Grid container spacing={2}>
                    {chooseCountry && (
                        <>
                            <Grid item xs={12} md={6}>
                                <Translate>
                                    {({translate}) => (
                                        <Select
                                            textFieldProps={{
                                                margin: "normal",
                                                label: translate("market.Country"),
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            }}
                                            loadOptions={searchCountries}
                                            defaultOptions
                                            // styles={selectStyles}
                                            components={components}
                                            value={city}
                                            onChange={changeCountry}
                                            placeholder={translate("market.Search a Country")}
                                            isClearable
                                        />
                                    )}
                                </Translate>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Translate>
                                    {({translate}) => (
                                        <Select
                                            textFieldProps={{
                                                margin: "normal",
                                                label: translate("market.Province"),
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            }}
                                            loadOptions={searchStates}
                                            defaultOptions
                                            // styles={selectStyles}
                                            components={components}
                                            value={city}
                                            onChange={changeState}
                                            placeholder={translate("market.Search a State or Province")}
                                            isClearable
                                        />
                                    )}
                                </Translate>
                            </Grid>
                        </>
                    )}
                    <Grid item md={12} xs={12}>
                        <div className="flex items-end w-full">
                            <div className="flex-1">
                                <Translate>
                                    {({translate}) => (
                                        <Select
                                            textFieldProps={{
                                                margin: "normal",
                                                fullWidth: true,
                                                label: translate("market.City"),
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            }}
                                            loadOptions={searchCity}
                                            defaultOptions
                                            margin={"normal"}
                                            // styles={selectStyles}
                                            components={components}
                                            value={city}
                                            onChange={changeCity}
                                            placeholder={translate("market.Search a city")}
                                            isClearable
                                        />
                                    )}
                                </Translate>
                            </div>
                            {chooseCountry ? (
                                <Button className="mb-8 ml-12" onClick={() => setChooseCountry(false)}>
                                    {<Translate id={"market.Search Cities"} />}
                                </Button>
                            ) : (
                                <Button className="mb-8 ml-12" onClick={() => setChooseCountry(true)}>
                                    {<Translate id={"market.Choose Country"} />}
                                </Button>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    fullWidth
                                    id={"companyPhone"}
                                    key={"companyPhone"}
                                    label={translate("_.phone")}
                                    value={formData.phone}
                                    onChange={handleFormData("phone")}
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    fullWidth
                                    id={"companyFax"}
                                    key={"companyFax"}
                                    label={translate("_.fax")}
                                    value={formData.fax}
                                    onChange={handleFormData("fax")}
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    fullWidth
                                    id={"companyWebsite"}
                                    key={"companyWebsite"}
                                    label={translate("_.website")}
                                    value={formData.website}
                                    onChange={handleFormData("website")}
                                />
                            )}
                        </Translate>
                    </Grid>
                </Grid>
            </DialogContent>
        </div>
    );
};

export default Step2;
