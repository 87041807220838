import {useEffect, useMemo, useState} from "react";
import {useLocalStorageState, useMount} from "ahooks";
import json from "../translate/income.json";
import {useMUIDialog} from "../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../@fuse/state/constant";
import {Dialog} from "@material-ui/core";
import NewMatchingDialogContent from "./SubViews/AutoMatchSubview/NewMatchingDialogContent";
import {withLocalize} from "react-localize-redux";
import {useIncomeMatching} from "./hooks";
import {INCOME_LOCAL_ID, MatchingOrderContext} from "./state/constant";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/styles";
import {useDetail} from "../IncomeSureManager/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function NewMatchingOrderDialog(props) {
    const classes = useStyles();
    const [incomeId, setIncomeId] = useLocalStorageState(INCOME_LOCAL_ID);
    const [loading, setLoading] = useState(false);
    const {dialogProps, onTurnOffDialog} = useMUIDialog({
        registry: GDialogRegistries.matchingOrder,
        fullScreen: true,
        maxWidth: 'lg',
        fullWidth: true,
    });

    const matchingRequest = useIncomeMatching();

    const detailRequest = useDetail();

    useMount(() => {
        props.addTranslation(json);
        if (incomeId) {
            const params = {
                incomeId: incomeId,
            };
            matchingRequest.run(params).then(() => {});
        }
        console.log("NewMatchingOrderDialog componentDidMount");
    });

    useEffect(() => {
        if (matchingRequest?.isMutating || detailRequest?.isMutating) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [matchingRequest?.isMutating, detailRequest?.isMutating]);

    const handleClose = () => {
        onTurnOffDialog();
    };

    const _store = useMemo(
        () => ({
            onCloseDialog: handleClose,
            matchingRequest,
            detailRequest,
        }),
        [matchingRequest, detailRequest]
    );

    return (
        <>
            <Dialog {...dialogProps}>
                <>
                    <MatchingOrderContext.Provider value={_store}>
                        <NewMatchingDialogContent />
                    </MatchingOrderContext.Provider>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            </Dialog>
        </>
    );
}

export default withLocalize(NewMatchingOrderDialog);
