// function useNoHandlerState(props) {
//     return (
//         <div></div>
//     );
// }
//
// export default useNoHandlerState;

import {create} from "zustand";
import createNoHandlerSlice from "./createNoHandlerSlice";

const useNoHandlerState = create((...a) => ({
    ...createNoHandlerSlice(...a),
}));

export default useNoHandlerState;
