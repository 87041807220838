import {IconButton} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {MyTooltip, UserView} from "@ui";
import {Translate} from "react-localize-redux";
import {withRouter} from "react-router-dom";
import TableEmpty from "../../../../../utils/empty_placeholder/TableEmpty";
import {MyPriceLabel} from "../../../../../utils/RootComponents";

const OrderTable = ({datas, handleDeleteOrder}) => {
    return (
        <Table size={"small"}>
            <TableHead>
                <TableRow className="h-64 cursor-pointer justify-between">
                    <TableCell align="left" className="flex-1 text-nowrap">
                        {<Translate id={"income.handler"} />}
                    </TableCell>
                    <TableCell align="right" className="flex-1 text-nowrap">
                        {<Translate id={"income.OrderCode"} />}
                    </TableCell>
                    <TableCell align="right" className="text-nowrap flex-1">
                        <Translate id={"income.ClientName"} />
                    </TableCell>
                    <TableCell align="right" className="text-nowrap flex-1">
                        <Translate id={"income.amount(￥)"} />
                    </TableCell>
                    <TableCell align="right" className="text-nowrap flex-1">
                        <Translate id={"income.actions"} />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {datas && datas.length !== 0 ? (
                    datas.map((row, tmpIndex) => (
                        <TableRow key={`${row.id}`} className="h-80 cursor-pointer items-center">
                            <TableCell align="left" className=" text-nowrap">
                                {<UserView user={row.user} />}
                            </TableCell>
                            <TableCell align="right" className=" text-nowrap">
                                {row.orderCode}
                            </TableCell>
                            <TableCell align="right" className=" text-nowrap">
                                {row.clientName}
                            </TableCell>
                            <TableCell align="right" className=" text-nowrap">
                                <MyPriceLabel
                                    classNames={"flex flex-row justify-end items-center"}
                                    price={row?.amount}
                                    showPrefix={false}
                                />
                                {/*{numeral(row.amount).format("0,0.00")}*/}
                            </TableCell>
                            <TableCell align="right" className=" text-nowrap">
                                {
                                    <MyTooltip title={<Translate id={"income.Delete order"} />}>
                                        <IconButton
                                            color="secondary"
                                            // disabled={disabled}
                                            aria-controls="incomne-order-Delete order"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleDeleteOrder(row);
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </MyTooltip>
                                }
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    // <TableRow>
                    //     <TableCell colSpan={7}>
                    //         <Typography variant={"h6"} className={"text-center w-full"}>
                    //             {<Translate id="income.Nothing Found!" />}
                    //         </Typography>
                    //     </TableCell>
                    // </TableRow>
                    <TableEmpty />
                )}
            </TableBody>
        </Table>
    );
};

export default withRouter(OrderTable);
