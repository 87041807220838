import axios, {firmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const  GET_RED_PACKETS_LIST = "[GET_RED_PACKETS_LIST]";

export const  SELECT_START_DATE = "[SELECT_START_DATE]";

export const  SELECT_REDPACKET_RECIVER = "[SELECT_REDPACKET_RECIVER]";

export const  SELECT_END_DATE = "[SELECT_END_DATE]";

export const  OPEN_BACK_DROP = "[OPEN_BACK_DROP]";

export const  SET_REDPACKET_TYPE = "[SET_REDPACKET_TYPE]";


export const  AMOUNT_UPDATE_ON_INPUT = "AMOUNT_UPDATE_ON_INPUT";

export const  RED_PACKET_SEND_FINSHED = "RED_PACKET_SEND_FINSHED";


export const  GET_MY_RED_PACKET_BALANCE_CAN_SEND = "[GET_MY_RED_PACKET_BALANCE_CAN_SEND]";

export const  GET_MY_RED_PACKET_BALANCE_BY_YEAR_MONTH = "[GET_MY_RED_PACKET_BALANCE_BY_YEAR_MONTH]";




export function getRedPacketsList(params,callback) {

    const request = axios.get(`${firmPrefix}/redPackets`,{params: params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) =>{
            dispatch({
                type: GET_RED_PACKETS_LIST,
                payload: res.data,
            });
            dispatch(finishLoading());
            if(typeof callback=== "function") callback();
        });
    };
}


export function selectAStartDate(date) {

    return {
        type: SELECT_START_DATE,
        payload: date,
    };
}


export function openBackDrop(flag) {

    return {
        type: OPEN_BACK_DROP,
        payload: flag,
    };
}

export function setRedpacketSendFinshed(flag) {

    return {
        type: RED_PACKET_SEND_FINSHED,
        payload: flag,
    };
}

export function selectRedpacketForSend(type) {

    return {
        type: SET_REDPACKET_TYPE,
        payload: type,
    };
}

export function selectRedpacketReciver(persons) {

    return {
        type: SELECT_REDPACKET_RECIVER,
        payload: persons,
    };
}


export function selectAEndDate(date) {

    return {
        type: SELECT_END_DATE,
        payload: date
    };
}


export function getMyRedpacketBalance(callback) {

    const request = axios.get(`${firmPrefix}/myRedPacketBalance`);

    return (dispatch) => {
        dispatch(startLoading());
        request.then(res =>{
            dispatch({
                type: GET_MY_RED_PACKET_BALANCE_CAN_SEND,
                payload: res.data,
            });
            dispatch(finishLoading());
            if(typeof callback=== "function") callback();
        }, (err) =>{
            if(typeof callback=== "function") callback();
        });
    };
}




// export function getMyRedpacketBalanceStatisticsByYearAndMonth(params) {
//
//     const request = axios.get(`${firmPrefix}/myRedPacketBalance`,{params: params});
//
//     return (dispatch) => {
//         dispatch(startLoading());
//         request.then(res =>{
//             dispatch({
//                 type: GET_MY_RED_PACKET_BALANCE_BY_YEAR_MONTH,
//                 payload: res.data,
//             });
//             dispatch(finishLoading());
//         });
//     };
// }

export function amountUpdateOnSendReadpacket(objects) {
    return {
        type: AMOUNT_UPDATE_ON_INPUT,
        payload: objects
    };
}
