import PropTypes from "prop-types";
import {Card, Hidden, Icon, IconButton} from "@material-ui/core";
import {useMemoizedFn} from "ahooks";
import {useEffect} from "react";
import {useWindowSizeStore} from "./index";

FilterRoot.propTypes = {
    /**
     * @description > 打开或者关闭侧边栏的控制参数
     */
    open: PropTypes.bool.isRequired,
    /**
     * @description > 关闭侧边栏的回调函数
     */
    onTurnOff: PropTypes.func,
};

function FilterRoot(props) {
    const {open, onTurnOff, children} = props;

    const {windowWidth} = useWindowSizeStore();
    useEffect(() => {
        const rem = convertRemToPixels(48);
        if (windowWidth < rem) {
            console.log("windowWidth < rem closed", windowWidth, rem);
            onTurnOff && onTurnOff();
        }
        return () => {};
    }, [windowWidth]);

    function convertRemToPixels(rem) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    const handleCloseSidebar = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOff && onTurnOff();
    });

    return (
        <div className={open ? "flex mt-12 w-auto" : "hidden"} data-cy={"filterButton"}>
            <>
                {open && (
                    <Hidden mdUp>
                        <IconButton
                            onClick={handleCloseSidebar}
                            className={"absolute top-0 right-0 z-999 bg-white hover:bg-red-dark hover:text-white"}
                        >
                            <Icon>close</Icon>
                        </IconButton>
                        <div
                            onClick={handleCloseSidebar}
                            className={"bg-black opacity-50 absolute top-0 z-99 w-full h-full"}
                        />
                    </Hidden>
                )}
                <div
                    className={
                        open
                            ? "md:min-w-288 md:relative p-24 md:p-0 left-0 w-full md:w-auto top-0 absolute z-99 flex-shrink-0"
                            : "hidden"
                    }
                >
                    {open && (
                        <Card
                            style={{height: "100%"}}
                            className={"bg-white h-full pb-12  md:rounded-8 md:ml-12 overflow-visible"}
                        >
                            <div className="flex flex-col w-full px-12 pt-12">{children}</div>
                        </Card>
                    )}
                </div>
            </>
        </div>
    );
}

export default FilterRoot;
