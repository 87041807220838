import {DialogContent, DialogTitle, Grid, Icon, TextField, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import FaveButton from "../../../../../components/layout/fav/FaveButton";
import {uploadFiles} from "../../../../../utils/fileUploader";
import {CompanySelector} from "../../marketingTools";
import Decision from "./elements/Decision";

const Step0 = (props) => {
    const {selectedItem, closeDialog, formData, handleFormData} = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const changeFile = (e) => {
        if (e.target.files) setLoading(true);
        uploadFiles(e.target.files, (status, files) => {
            handleFormData("avatar")({target: {value: files[0].visitUrl}});
            setLoading(false);
        });
    };

    const onClickHelperText = (value) => (e) => {
        e.preventDefault();
        handleFormData("title")({target: {value: value}});
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title" disableTypography classes={{root: "drag flex justify-between items-center px-24 py-8 rounded-t"}}>
                {selectedItem ? (
                    <Translate>
                        {({translate}) => (
                            <Typography className={"my-12"} variant={"h6"}>
                                {translate("market.Edit company's contact with parameter", {
                                    name: selectedItem.company.name
                                })}
                            </Typography>
                        )}
                    </Translate>
                ) : (
                    <>
                        <Typography variant={"h6"}>
                            <span className={"font-normal"}>
                                <Translate id="market.New Contacts" />
                            </span>
                        </Typography>
                    </>
                )}
                <div>
                    {!selectedItem && (
                        <FaveButton colored className={"-mr-8"} sourceId={"companyContacts"} title={"_.companyContacts"} type={"dialog"} />
                    )}
                    {/*<IconButton className={'no-drag'} onClick={()=>dispatch(fuseActions.minimize("contact"))}><Icon>remove</Icon></IconButton>*/}
                    <IconButton className={"no-drag"} onClick={closeDialog}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent className={"overflow-visible"}>
                {/*<Grid spacing={2} container className="overflow-visible">*/}
                <div className="flex md:flex-row flex-col items-center mb-16">
                    <label
                        className={classNames(
                            "bg-grey-lighter hover:bg-grey-light border cursor-pointer items-center flex w-128 h-128 rounded-full mr-12 text-center justify-center",
                            loading && "loading"
                        )}
                    >
                        <input type="file" accept="image/png, image/jpeg" onChange={changeFile} className="hidden" />
                        {formData.avatar !== "" && !loading ? (
                            <img style={{maxWidth: "100%"}} src={formData.avatar} alt={"logo"} />
                        ) : (
                            <Icon fontSize={"large"}>camera_enhance</Icon>
                        )}
                    </label>
                    {/*<Grid item md={2} xs={12}>*/}
                    {/*    <AvatarInput {...props}/>*/}
                    {/*</Grid>*/}
                    <div className="flex-1">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                {/*<Name  {...props}/>*/}
                                <Translate>
                                    {({translate}) => (
                                        <TextField
                                            fullWidth
                                            inputProps={{maxLength: 10}}
                                            helperText={
                                                <>
                                                    <a href={"#"} onClick={onClickHelperText(`${translate("market.Mr")}`)}>
                                                        {translate("market.Mr")}
                                                    </a>
                                                    ,&nbsp;
                                                    <a href={"#"} onClick={onClickHelperText(`${translate("market.Miss")}`)}>
                                                        {translate("market.Miss")}
                                                    </a>
                                                    ,&nbsp;
                                                    <a href={"#"} onClick={onClickHelperText(`${translate("market.Manager")}`)}>
                                                        {translate("market.Manager")}
                                                    </a>
                                                    ,&nbsp;
                                                    <a href={"#"} onClick={onClickHelperText(`${translate("market.Marketer")}`)}>
                                                        {translate("market.Marketer")}
                                                    </a>
                                                    ,&nbsp;
                                                    <a href={"#"} onClick={onClickHelperText(`${translate("market.Boss")}`)}>
                                                        {translate("market.Boss")}
                                                    </a>
                                                </>
                                            }
                                            label={translate("market.Name Title")}
                                            value={formData.title}
                                            onChange={handleFormData("title")}
                                        />
                                    )}
                                </Translate>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Translate>
                                    {({translate}) => (
                                        <TextField
                                            required
                                            inputProps={{maxLength: 60}}
                                            fullWidth
                                            label={translate("_.Name")}
                                            value={formData.lastName}
                                            onChange={handleFormData("lastName")}
                                        />
                                    )}
                                </Translate>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CompanySelector required {...props} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Decision {...props} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </DialogContent>
        </>
    );
};

export default Step0;
