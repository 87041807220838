import {useSocket} from "@hooks";
import {Icon, IconButton, List, ListSubheader} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {EmptyView, MyTooltip} from "@ui";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import NotificationItem from "./NotificationItem";
import {toggleQuickPanel} from "./store/actions";

export const useStyles = makeStyles((theme) => ({
    unreadBadge: {
        position: "absolute",
        minWidth: 18,
        height: 18,
        top: -9,
        right: -11,
        borderRadius: 9,
        padding: "0 5px",
        fontSize: 11,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // @ts-ignore
        backgroundColor: theme.palette.secondary.main,
        // @ts-ignore
        color: theme.palette.secondary.contrastText,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.35)",
        zIndex: 10,
    },
}));

const NotificationsList = (props) => {
    const dispatch = useDispatch();
    const notifications = useSelector(({notification}) => notification.notifications);
    const ntfNumber = useSelector(({notification}) => notification.ntfNumber);
    const classes = useStyles(props);
    const {emitEvent} = useSocket();

    // const {emitEvent} = useSocket()

    // const unreadAllNotification = () => {
    //     // emitEvent("notificationSeen")
    // }

    return (
        <>
            <List
                subheader={
                    <ListSubheader component="div" className={"flex bg-white"}>
                        <div className={"flex-grow"}>
                            <span className="relative">
                                <Translate id="navi.Notifications" />
                                {ntfNumber > 0 && <span className={classes.unreadBadge}>{parseInt(ntfNumber)}</span>}
                            </span>
                        </div>

                        {parseInt(ntfNumber) > 0 && (
                            <MyTooltip title={<Translate id="navi.Mark as read" />}>
                                <span>
                                    <IconButton
                                        color="inherit"
                                        onClick={() => {
                                            emitEvent("notificationSeen", {});
                                        }}>
                                        <Icon>done_all</Icon>
                                    </IconButton>
                                </span>
                            </MyTooltip>
                        )}

                        <MyTooltip title={<Translate id="navi.See all" />}>
                            <IconButton component={Link} color="inherit" onClick={() => dispatch(toggleQuickPanel(false))} to="/notifications/all">
                                <Icon>open_in_new</Icon>
                            </IconButton>
                        </MyTooltip>

                        <MyTooltip title={<Translate id="_.close" />}>
                            <IconButton color="inherit" onClick={() => dispatch(toggleQuickPanel(false))}>
                                <Icon>close</Icon>
                            </IconButton>
                        </MyTooltip>
                    </ListSubheader>
                }>
                {notifications && notifications.length > 0 ? (
                    notifications.map((ntf, i) => <NotificationItem ntf={ntf} key={i} />)
                ) : (
                    <EmptyView label={<Translate id="navi.You're alright" />} />
                )}
            </List>
        </>
    );
};

export default NotificationsList;
