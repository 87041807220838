import App from "app/App";
import {createRoot} from "react-dom/client";
import "typeface-muli";
// import "./i18n";
import {unregister} from "./serviceWorker";
import "./styles/index.css";

// console.log("Indextx");

if (process.env.NODE_ENV !== "development") console.log = () => {};

// const root = createRoot(document.getElementById("root"));
// root.render(<App />);

const root = createRoot(document.getElementById("root")!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
