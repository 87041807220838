import {Button, Chip, Dialog, DialogContent, TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {useMemoizedFn} from "ahooks";
import {useMemo, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import * as fuseActions from "../../../../store/actions";
import {useDefinitionStore} from "../definitions/state/useDefinitionStore";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: "visible"
    }
}));

function NewCreateInstanceDialog(props) {
    const classes = useStyles();
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.createInstance
    });

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState("");

    const [businessKey, setBusinessKey] = useState("");

    const user = useSelector(({user}) => user.profile);

    const dispatch = useDispatch();

    const currentDefinition = useDefinitionStore((state) => state.currentDefinition);

    const handleTile = useMemoizedFn((e) => {
        setTitle(e.target.value);
    });

    const handleBusinessKey = useMemoizedFn((e) => {
        setBusinessKey(e.target.value);
    });

    const handleCancel = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        const params = {
            processDefinitionKey: currentDefinition?.key,
            owner: user?.id,
            businessKey: businessKey,
            title: title
        };
        axios
        .post(`${flowPrefix}/processInstance`, params)
        .then(() => {
            finshParams?.onFinish();
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "_.New instance successfully"
                })
            );
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    });

    const disabledSubmit = useMemo(() => {
        return title === "" || businessKey === "";
    }, [title, businessKey]);

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <DialogTitle id="NewCreateInstanceDialog-dialog-title">
                    {<Translate id="_.New instance for definition" />}
                    <Chip label={currentDefinition?.name} className={"ml-1"} color="secondary" size="small" />
                </DialogTitle>
                <DialogContent className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="instance title-required"
                                label={<Translate id={"_.instance title"} />}
                                value={title}
                                onChange={handleTile}
                                variant="filled"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="businessKey-required"
                                onChange={handleBusinessKey}
                                label={<Translate id={"_.business_Key"} />}
                                value={businessKey}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleCancel}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button variant={"contained"} disabled={disabledSubmit} onClick={handleSubmit} color="secondary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewCreateInstanceDialog;
