import {List, ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import {Translate} from "react-localize-redux";
import CompanyLabel from "../../utils/CompanyLabel";
import Owner from "../../utils/Owner";
import {CreateAndActiveDateItem} from "../../marketingTools";
import Card from "@material-ui/core/Card";

const RightSidebar = (props) => {
    const {sourceItem} = props;
    return (
        <Card  className="m-20 ml-0  ">
            <Owner user={sourceItem.user}/>
            <CompanyLabel link={"deal"} {...props}/>

            <List>
                { sourceItem.leadSource && <ListItem>
                    <ListItemText primary={ sourceItem.leadSource ? sourceItem.leadSource.value : <Translate id={"market.No lead source"}/>} secondary={<Translate id={"market.Lead source"}/>} />
                </ListItem>
                }

                {
                    sourceItem.type && <ListItem>
                        <ListItemText primary={sourceItem.type ? sourceItem.type.value : <Translate id={"market.No type"}/> } secondary={<Translate id={"market.Type"}/>}/>
                    </ListItem>
                }


                {
                    sourceItem.closeDate > 0 && sourceItem.closeDate !== null && sourceItem.closeDate !== undefined  && <ListItem>
                        <ListItemText primary={sourceItem.closeDate || <Translate id={"market.No date"}/>} secondary={<Translate id={"market.Close date"}/>} />
                    </ListItem>
                }


                {
                    (sourceItem.createDate || sourceItem.activeDate) && <CreateAndActiveDateItem sourceItem={sourceItem} />
                }

            </List>
        </Card>
    );
};

export default RightSidebar;
