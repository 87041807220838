import {Component} from 'react';
import {bindActionCreators} from 'redux';
import * as Actions from '../store/actions';
import {connect} from 'react-redux';
import {Avatar, Button, DialogContent, Icon, withStyles} from '@material-ui/core';
import axios, {crmPrefix} from '../../../../myaxios';
import * as fuseActions from '@fuseActions';
import {Translate, withLocalize} from 'react-localize-redux';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CheckerInput from 'utils/CheckerInput/CheckerInput';
import {savePicture} from 'utils/fileUploader';
import Divider from '@material-ui/core/Divider';
import transJson from '../translation/commerce';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import withDialog from '../../../../utils/withDialog';

const styles = (theme) => ({
    bigAvatar: {
        width: 50,
        height: 50,
    },
    label: {
        lineHeight: 0,
    },
});

class BrandDialog extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(transJson);
    }

    state = {
        loading: false,
        name: "",
        img: "",
        cat: "",
        new: "",
        nameError: false,
        fileLoading: false,
    };

    error = (name, e) => {
        this.setState({[name]: e});
    };

    componentDidMount = () => {
        if (this.props.selectedBrand) {
            this.setState({
                name: this.props.selectedBrand.name,
                img: this.props.selectedBrand.img,
                cat: this.props.selectedBrand.category.id,
            });
            if (this.props.categories.length === 0) this.props.getBrandCategories();
        }
    };

    handleChangeCategory = (e) => {
        if (e.target.value === "add") this.props.turnOn("editBrandCat");
        else this.setState({cat: e.target.value});
    };

    onSubmit = () => {
        const {selectedBrand} = this.props;
        this.setState({loading: true});
        // if(!this.state.user) return;
        const formData = {
            name: this.state.name,
            category: this.state.cat,
            img: this.state.img,
        };
        if (selectedBrand) {
            formData.id = selectedBrand.id;
        }
        let request = selectedBrand
            ? axios.put(`${crmPrefix}/brand`, formData)
            : axios.post(`${crmPrefix}/brand`, formData);
        request
            .then(() => {
                // console.log("新建品牌或者编辑品牌成功!");
                this.props.getBrands({sidx: "timestamp", sord: "DESC"});
                this.setState({loading: false});
                this.props.turnOff("brand");
                this.props.showMessage({
                    variant: "success",
                    message: "message.Brand Saved!",
                });
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    changeImage = (e) => {
        if (e.target.files[0])
            if (e.target.files[0].size > 10240000) {
                /** 10 M **/
                this.props.showMessage({
                    variant: "success",
                    message: "message.File is too big!",
                });
            } else {
                this.setState({new: e.target.files[0], fileLoading: true});
                savePicture(e.target.files[0], (status, url) => {
                    if (status) {
                        const img = `${url}`;
                        this.setState({img});
                        this.setState({fileLoading: false});
                    } else {
                        this.setState({fileLoading: false});
                        this.props.showMessage({
                            variant: "success",
                            message: "message.Something is wrong!",
                        });
                    }
                });
            }
    };

    render() {
        const {selectedBrand, dialogs, classes} = this.props;

        const {loading} = this.state;

        return (
            <>
                <div className={loading ? "loading" : null}>
                    <DialogTitle>
                        {selectedBrand ? (
                            <>
                                <Translate id="crm.Edit" /> {selectedBrand.name}
                            </>
                        ) : (
                            <Translate id="crm.New Brand" />
                        )}
                    </DialogTitle>

                    <DialogContent className={"p-20"}>
                        <div className="flex flex-wrap  items-start justify-start">
                            <div className={"w-full sm:w-1/2 flex"}>
                                <input
                                    id={"uid"}
                                    type="file"
                                    accept={"image/*"}
                                    value={""}
                                    onChange={this.changeImage}
                                    className="hidden"
                                />
                                <label className={"mb-0 mr-12 " + classes.label} htmlFor={"uid"}>
                                    {this.state.fileLoading ? (
                                        <CircularProgress size={50} className={""} />
                                    ) : (
                                        <Avatar
                                            className={classes.bigAvatar + " border"}
                                            alt={"Brand"}
                                            src={this.state.img && this.state.img + "/crop/120x120"}
                                        >
                                            <Icon children={"add"} />
                                        </Avatar>
                                    )}
                                </label>

                                <CheckerInput
                                    autoFocus={true}
                                    className={""}
                                    error={(e) => this.error("nameError", e)}
                                    changeHandler={(val) => this.setState({name: val})}
                                    min={0}
                                    max={99999999}
                                    name={"name"}
                                    url={`${crmPrefix}/checkBrandNameAvailability`}
                                    editValue={selectedBrand && selectedBrand.name}
                                    label={<Translate id="crm.Brand" />}
                                />
                            </div>

                            <div className={"w-full sm:w-1/2 sm:pl-12 pl-auto"}>
                                <FormControl className={"w-full"}>
                                    <InputLabel htmlFor="category">
                                        <Translate id="crm.Category" />
                                    </InputLabel>
                                    <Select
                                        value={this.state.cat}
                                        onChange={this.handleChangeCategory}
                                        inputProps={{
                                            name: "cat",
                                            id: "category",
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>
                                                <Translate id="crm.None" />
                                            </em>
                                        </MenuItem>
                                        <MenuItem value="add">
                                            <Icon className={"mr-8"}>add</Icon>
                                            <em>
                                                <Translate id="crm.Add new category" />
                                            </em>
                                        </MenuItem>
                                        <Divider />
                                        {this.props.categories &&
                                            this.props.categories.map((_cat) => (
                                                <MenuItem key={_cat.id} value={_cat.id}>
                                                    {_cat.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.props.turnOff("brand");
                            }}
                        >
                            <Translate id="_.cancel" />
                        </Button>
                        <Button
                            variant={"contained"}
                            disabled={
                                this.state.fileLoading ||
                                this.state.name === "" ||
                                this.state.nameError ||
                                this.state.cat === "" ||
                                this.props.loading
                            }
                            color="secondary"
                            onClick={this.onSubmit}
                        >
                            <Translate id="_.submit" />
                        </Button>
                    </DialogActions>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff,
            turnOn: fuseActions.turnOn,
            showMessage: fuseActions.showMessage,
            getBrands: Actions.getBrands,
            getBrandCategories: Actions.getBrandCategories,
        },
        dispatch
    );
}

function mapStateToProps({fuse, commerce}) {
    return {
        dialogs: fuse.dialogs,
        loading: fuse.loading,
        categories: commerce.brands.categories,
        selectedBrand: commerce.brands.selectedBrand,
    };
}

export default withDialog(
    "brand",
    "xs"
)(
    withMobileDialog()(
        withStyles(styles, {withTheme: true})(withLocalize(connect(mapStateToProps, mapDispatchToProps)(BrandDialog)))
    )
);
