import PropTypes from "prop-types";
import {StyledTableCell, StyledTableRow} from "../utilComponents";
import clsx from "clsx";
import {useSelector} from "react-redux";
import {useMemoizedFn} from "ahooks";
import {Hidden, Icon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useMemo} from "react";

TableRowRoot.propTypes = {
    query: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    dataSource: PropTypes.array,
    selected: PropTypes.bool,
    expandedRow: PropTypes.any,
    onChangeExpandRow: PropTypes.func,
    /**
     * @description - 当前行的索引
     */
    index: PropTypes.number.isRequired,
    /**
     * @description - 点击某行的回调
     * @param {object} row - 当前行的数据
     */
    onRowSelection: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    row: {
        "& .actions": {
            display: "none",
            backdropFilter: "blur(6px)",
            // backgroundColor: theme.palette.background.default,
            // background: `linear-gradient(90deg, transparent 0  , ${theme.palette.grey["300"]} 90%)`,
        },
        "&:hover .actions": {
            display: "flex",
        },
    },
}));

function TableRowRoot(props) {
    const {query, row, selected, expandedRow, onChangeExpandRow, onRowSelection, index, children} = props;
    const classes = useStyles();
    const listView = useSelector(({fuse}) => fuse.dialogs?.listView);

    const handleExpandClick = useMemoizedFn((e) => {
        e.stopPropagation();
        onChangeExpandRow && onChangeExpandRow(row.id);
    });

    const openExpanded = useMemo(() => {
        const texpanded = expandedRow.has(row.id) || false;
        console.log("TableRowRoot > expanded > ", texpanded);
        return texpanded;
    }, [expandedRow, row.id]);

    const handleRowClick = useMemoizedFn((e) => {
        e.stopPropagation();
        onRowSelection && onRowSelection(e);
    });

    return (
        <StyledTableRow
            selected={selected}
            role={"checkbox"}
            tabIndex={-1}
            onClick={handleRowClick}
            className={clsx(selected && "bg-blue-lightest", listView ? `h-24` : `h-64`, "border-b-1", classes.row)}
        >
            <>
                <Hidden smUp>
                    <StyledTableCell onClick={handleExpandClick} className={"text-grey-dark"}>
                        <Icon fontSize={"inherit"} className={"text-24"}>
                            {openExpanded ? "expand_less" : "expand_more"}
                        </Icon>
                    </StyledTableCell>
                </Hidden>
                <StyledTableCell padding={"checkbox"} className={"w-24 text-grey-dark"}>
                    <Typography>{query?.size ? query?.size * (query?.page - 1) + index + 1 : index + 1}</Typography>
                </StyledTableCell>
                {children}
            </>
        </StyledTableRow>
    );
}

export default TableRowRoot;
