import * as XLSX from "xlsx";

export function downloadExcelFile(datas, fileName) {
    const ws = XLSX.utils.aoa_to_sheet(datas);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export const downloadJsonExcel = (data, header, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data, {dateNF: "YYYY/MM/DD"});
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
