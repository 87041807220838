import {combineReducers} from "redux";
import list from "./list.reducer";
import single from "./single.reducer";

const reducer = combineReducers({
    list,
    single,
});

export default reducer;
