import axios, {crmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_PRODUCTS = "[COMMERCE PRODUCT] GET";

export const PRODUCTS_CHANGE_VIEW = "[COMMERCE PRODUCT] CHANGE VIEW";
export const GET_PRODUCT_CATEGORIES  = "[COMMERCE PRODUCT] GET CATEGORIES";
export const SELECT_PRODUCT = "[COMMERCE PRODUCT] SELECT_PRODUCT";
export const SELECT_PRODUCT_CAT  = "[COMMERCE PRODUCT] SELECT CAT";
export const PASS_SELECTED_PRODUCTID = "[COMMERCE_SELECTED_PRODUCTED]";
export const REMOVE_ALL_SELECTED_PRODUCTS = "[COMMERCE_REMOVE_ALL_SELECTED_PRODUCTS]";

export function getProducts(params, callback){
    const request = axios.get(`${crmPrefix}/products`, {params});

    return (dispatch) =>{
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_PRODUCTS,
                payload: response.data
            });
            if(typeof callback=== "function") callback();
            dispatch(finishLoading());
        });
        dispatch(getProductCategories(params));
    };
}

export function getProductCategories(params){
    const request = axios.get(`${crmPrefix}/productCategories`, {params});

    return (dispatch) =>
        request.then((response) => {
                dispatch({
                    type: GET_PRODUCT_CATEGORIES ,
                    payload: response.data
                });
            }
        );
}

// export function productsChangeView(view){
//     return {
//         type      : PRODUCTS_CHANGE_VIEW,
//         view
//     }
// }

export function selectProductCat(cat){
    return {
        type      : SELECT_PRODUCT_CAT,
        cat
    };
}

export function selectProduct(payload){
    return {
        type      : SELECT_PRODUCT,
        payload
    };
}

export function passSelectedProductID(productIds) {
    return {
        type        : PASS_SELECTED_PRODUCTID,
        productIds
    };
}


export function removeAllSelectedProducts() {
    return {
        type        : REMOVE_ALL_SELECTED_PRODUCTS
    };
}
