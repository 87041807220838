import myaxios, {commonPrefix} from "@api";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {Alert} from "@material-ui/lab";
import {SubmitButton} from "@ui";
import {useLiveQuery} from "dexie-react-hooks";
import type {FC} from "react";
import {useState} from "react";
// @ts-ignore
import {CopyToClipboard} from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../../../app/auth/AuthProvider";
import {db} from "../db";
import {useDGDef, useDGSelection} from "../hooks/hooks";
import useDatagrids from "../hooks/useDatagrids";
import useRows from "../hooks/useRows";
import {Popover, Switch} from "@material-ui/core";
import {TwitterPicker} from "react-color";

const DGFooter: FC = () => {
    const {t} = useTranslation(["datagrid", "_"]);
    const {selected, clearAll} = useDGSelection();
    const {onPublishSettings, permission, isOwner, id, title, cols, lastModified, setShowOnlyChanged, showOnlyChanged} = useDGDef();
    const {onDeleteRows, onUpdateRows} = useRows();
    const {updateDataGrid} = useDatagrids(id);
    const {user} = useAuth();
    const [colorEl, setColorEl] = useState(null);

    const newRows = useLiveQuery(() =>
        id
            ? db.dataGridRows
                  .where("dataGridId")
                  .equals(id)
                  .and((x) => x.activeTime > 0)
                  .toArray()
            : []
    );
    //
    // useWhyDidYouUpdate("DGfooter", {newRows, permission, isOwner, id, title, cols, lastModified});

    // const {rowsConf} = useDg();
    const [loading, setLoading] = useState(false);
    const onSaveData = () => {
        const da = newRows?.map((x) => {
            x["t"] = x.activeTime;
            x["u"] = user?.id;
            delete x.dataGridId;
            delete x.activeTime;
            return x;
        });

        // console.log({da});
        setLoading(true);
        myaxios
            .put(`${commonPrefix}/datagridData/${id}`, {rows: da})
            .then(({data}) => {
                toast.success(t("toast.saved"));
                updateDataGrid(
                    {
                        title: data.title,
                        permission: data.permission,
                        cols: data.data && data.data.cols ? data.data.cols : [],
                        isOwner: user?.id === data.user?.id,
                        activeTime: data.activeTime,
                        lastModified: undefined,
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    data.data.rows ? data.data.rows.map(({activeTime, ...x}) => ({...x, dataGridId: id})) : []
                );
            })
            .finally(() => setLoading(false));
    };

    const onPublish = () => {
        if (!id) return;

        const da = newRows?.map((x) => {
            x["t"] = x.activeTime;
            delete x.dataGridId;
            x["u"] = user?.id;
            delete x.activeTime;
            return x;
        });

        // newRows?.forEach((x) => {
        //     delete x.activeTime;
        //     delete x.dataGridId;
        // });

        // console.log({da});
        setLoading(true);
        cols.forEach((x) => {
            delete x.modifiedTime;
        });
        myaxios
            .put(`${commonPrefix}/datagridData/${id}`, {cols, rows: da})
            .then(({data}) => {
                console.log(data.activeTime);
                onPublishSettings(id, title);
                toast.success(t("toast.saved"));
                // clearConflict().then(() => {
                //     mutate(`${commonPrefix}/datagrids/${id}`);
                // });
                updateDataGrid(
                    {
                        title: data.title,
                        permission: data.permission,
                        cols: data.data && data.data.cols ? data.data.cols : [],
                        isOwner: user?.id === data.user?.id,
                        activeTime: data.activeTime,
                        lastModified: undefined,
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    data.data.rows ? data.data.rows.map(({activeTime, ...x}) => ({...x, dataGridId: id})) : []
                );

                // clearConflict().then(() => {
                //     mutate(`${commonPrefix}/datagrids/${id}`);
                // });
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className={"border-t-1 py-12 border-gray-200 flex items-center justify-between"}>
            <div className="flex items-center pl-12">
                {/*<div className={"pl-12 text-20"}>*/}
                {/*    <Trans*/}
                {/*        i18nKey="recordsSelected"*/}
                {/*        values={{*/}
                {/*            checked: checked.size,*/}
                {/*            name: t("records"),*/}
                {/*        }}*/}
                {/*        components={{*/}
                {/*            s: <strong />,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
                {t("select_counter", {ns: "_", count: selected.length, name: t("records")})}

                {selected.length > 0 && (permission === "manage" || permission === "edit" || isOwner) && (
                    <>
                        <Button size={"small"} onClick={() => clearAll()} className={"ml-4"}>
                            {t("deselect")}
                        </Button>

                        <Button
                            className={"ml-4 text-red"}
                            onClick={() => {
                                clearAll();
                                onDeleteRows(selected);
                            }}
                            size={"small"}>
                            {t("deleteSelected")}
                        </Button>

                        <Button
                            size={"small"}
                            onClick={(event) => {
                                event.stopPropagation();
                                // @ts-ignore
                                setColorEl(event.currentTarget);
                            }}
                            className={"ml-4"}>
                            {t("changeColor")}
                        </Button>
                    </>
                )}

                <Popover
                    open={Boolean(colorEl)}
                    anchorEl={colorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={() => setColorEl(null)}>
                    <TwitterPicker
                        colors={[
                            "#999999",
                            "#f3f4f6",
                            "#FFFFFF",
                            "#D9B3A9",
                            "#FFC266",
                            "#FFE566",
                            "#CCEEFF",
                            "#D9F2FF",
                            "#66C9FF",
                            "#ffa1bd",
                            "#FFD9E5",
                            "#E5C2FF",
                        ]}
                        className={"shadow-0 mr-12"}
                        triangle={"hide"}
                        width={320}
                        onChangeComplete={(color) => {
                            onUpdateRows(selected, "color", color.hex);
                            setColorEl(null);
                        }}
                    />
                </Popover>
            </div>
            {/*{paginated && (*/}
            {/*    <MyPagination*/}
            {/*        filterData={filterData}*/}
            {/*        size={pageSize}*/}
            {/*        page={currentPage}*/}
            {/*        totalElements={filteredData ? filteredData.length : 0}*/}
            {/*        totalPages={totalPages}*/}
            {/*    />*/}
            {/*)}*/}
            <div className={"mr-12 flex items-center"}>
                {lastModified && (
                    <Alert className={"mr-12 py-0"} severity={"error"}>
                        {t("notSaved")}: {newRows?.length}
                        <Switch checked={showOnlyChanged} size={"small"} onChange={(e) => setShowOnlyChanged(e.target.checked)} />
                    </Alert>
                )}

                <CopyToClipboard text={`https://srx.signabc.com/datagrids/${id}`} onCopy={() => toast.success(t("copied"))}>
                    <Button className={"mr-12"} startIcon={<Icon fontSize={"small"}>content_copy</Icon>}>
                        {t("copyLink")}
                    </Button>
                </CopyToClipboard>

                {(permission === "edit" || permission === "manage" || permission === "add" || isOwner) &&
                    (isOwner ? (
                        <SubmitButton disabled={!lastModified} loading={loading} startIcon={<Icon children={"save"} />} success onClick={onPublish}>
                            {t("savePublish")}
                        </SubmitButton>
                    ) : (
                        <SubmitButton disabled={!lastModified} loading={loading} startIcon={<Icon children={"save"} />} success onClick={onSaveData}>
                            {t("save")}
                        </SubmitButton>
                    ))}
            </div>
        </div>
    );
};

export default DGFooter;
