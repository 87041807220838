import {IconButton, Typography} from "@material-ui/core";
import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import {has, indexOf, keys} from "lodash";
import PropTypes from "prop-types";
import {useEffect} from "react";
import {Translate} from "react-localize-redux";

ButtonQuery.propTypes = {
    /**
     * @description > 是否 加入 "filterBy" 参数标记当前的筛选条件 key,用于当存在多个参数互斥时，清空相关参数
     */
    haveFilterBy: PropTypes.bool,
    /**
     * @description > 该筛选的名字
     */
    titleLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    /**
     * @description > 网络请求的参数
     */
    query: PropTypes.object.isRequired,
    /**
     * @description > 修改网络请求的参数的回调函数
     */
    onChangeQuery: PropTypes.func.isRequired,

    /**
     * @description > 各项筛选的配置
     * @param {Array} dataSource iconButtonAble = false > [{ label: string ,value : any,style: string }];
     * @param {Array} dataSource iconButtonAble = true > [{ label: string ,value : any,style: string,icon: Icon }];
     */
    dataSource: PropTypes.array.isRequired,

    /**
     * @description > 修改网络请求的参数的 key
     */
    name: PropTypes.string.isRequired,

    /**
     * @description > true 是 IconButton， false 是 button
     */
    iconButtonAble: PropTypes.bool
};

ButtonQuery.defaultProps = {
    haveFilterBy: false
};

function ButtonQuery(props) {
    const {titleLabel, query, onChangeQuery, dataSource, name, iconButtonAble, haveFilterBy} = props;

    useEffect(() => {
        return () => {
        };
    }, []);

    const handleFilter = useMemoizedFn((value) => (e) => {
        e.stopPropagation();
        let tempParams = {...query};
        if (has(tempParams, `${name}`) && `${tempParams[name]}` === `${value}`) {
            delete tempParams[name];
        } else {
            tempParams = {...tempParams, [name]: value};
        }
        if (haveFilterBy) {
            tempParams = {...tempParams, filterBy: `${name}`};
        } else {
            delete tempParams.filterBy;
        }
        // console.log("ButtonQuery > onChangeQuery > query: ", tempParams);
        onChangeQuery(tempParams);
    });

    const getBorderStyle = useMemoizedFn((index) => {
        if (index === 0) {
            return "rounded-l-lg border-r border-solid border-grey-light";
        }
        if (index > 0 && index !== dataSource?.length - 1) {
            return "border-r border-solid border-grey-light";
        }
        if (index === dataSource?.length - 1) {
            return "rounded-r-lg";
        }
        return "";
    });

    const getSelectedStyle = useMemoizedFn((item) => {
        const {value, style} = item;
        const compare = query[`${name}`];
        if (indexOf(keys(query), `${name}`) !== -1 && `${compare}` === `${value}`) {
            return style;
        } else {
            return "";
        }
    });

    return (
        <div className={"flex flex-col"}>
            {titleLabel && (
                <Typography className={"text-grey-dark"} variant={"subtitle1"}>
                    {titleLabel}
                </Typography>
            )}
            <div
                className={
                    "radius bg-white rounded-lg  border flex items-stretch w-full truncate mt-1 h-40 overflow-hidden"
                }
            >
                {(!iconButtonAble || iconButtonAble === false) &&
                    !!dataSource &&
                    dataSource.map((object, index) => (
                        <MyTooltip key={`${index}`} title={<Translate id={`${object.label}`} />}>
                            <button
                                onClick={handleFilter(`${object.value}`)}
                                style={{width: "fit-content"}}
                                className={`
                                 flex-1 items-center truncate flex text-center justify-center ${getBorderStyle(
                                    index
                                )} ${getSelectedStyle(object)}
                                  `}
                            >
                                {<Translate id={`${object.label}`} />}
                            </button>
                        </MyTooltip>
                    ))}
                {!!iconButtonAble &&
                    !!dataSource &&
                    dataSource.map((object, index) => (
                        <MyTooltip key={`${index}`} title={<Translate id={`${object.label}`} />}>
                            <IconButton
                                onClick={handleFilter(`${object.value}`)}
                                className={`
                                 flex-1 items-center truncate flex text-center justify-center w-auto ${getBorderStyle(
                                    index
                                )} ${getSelectedStyle(object)}
                                  `}
                            >
                                {object.icon}
                            </IconButton>
                        </MyTooltip>
                    ))}
            </div>
        </div>
    );
}

export default ButtonQuery;
