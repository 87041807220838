import {GET_TASK_LIST, SELECT_PROJECT_STAGE, SELECT_STAGE_ID, SELECT_SUB_TASK, SELECT_TASK, SELECT_TASK_ID} from "../actions";

const initialState = {
    taskListData: null,
    subTasksData: null,
    selectedTask: null,
    selectedTaskId: null,
    selectedSubTask: null,
    selectedStageId: null,
    selectedProjectStage: null
};

const tasksReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_TASK_LIST:
            return {
                ...state,
                taskListData: {...action.payload}
            };
        case SELECT_TASK:
            return {
                ...state,
                selectedTask: action.task
            };
        case SELECT_TASK_ID:
            return {
                ...state,
                selectedTaskId: action.taskId
            };
        case SELECT_SUB_TASK:
            return {
                ...state,
                selectedSubTask: action.subTask
            };
        case SELECT_STAGE_ID:
            return {
                ...state,
                selectedStageId: action.id
            };
        case SELECT_PROJECT_STAGE:
            return {
                ...state,
                selectedProjectStage: action.projectStage
            };
        default:
            return state;
    }
};

export default tasksReducer;
