import {lazy} from "react";

export const Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: "/users",
            component: lazy(() => import("./Page"))
        },
        // {
        //     path: "/user/:id",
        //     component: lazy(() => import("./single/ProfilePage"))
        // },
        // {
        //     path: "/user/:id/profile",
        //     component: lazy(() => import("./single/ProfilePage"))
        // },
        {
            path: "/backoffs",
            component: lazy(() => import("./BackOff/Page"))
        }
    ]
};
