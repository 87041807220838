// noinspection JSPotentiallyInvalidUsageOfThis

import {Component, forwardRef} from "react";
import {bindActionCreators} from "redux";
import {Dialog} from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {connect} from "react-redux";
import * as fuseActions from "@fuseActions";
import Grow from "@material-ui/core/Grow";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

/* Use example  todo convert dialog
 1- const WPDetailDialog = withDialog("wpDetail", "xl")(WPPage);
 2- export default withDialog('chooseCategory', "xs")(withLocalize(CategoriesContent));
 */
export const Transition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const withDialog = (name, size, shouldNotClose) => (ComposedComponent) => {
    class DialogWrapper extends Component {
        onClose = () => {
            // if(minimize)
            //     this.props.minimize(name);
            // else
            this.props.turnOff(name);
        };
        render() {
            const {fullScreen, dialogs, ...rest} = this.props;
            return (
                <Dialog
                    data-cy={"dialog-" + name}
                    scroll={"body"}
                    classes={{paper: "md:overflow-visible ", root: "md:overflow-visible"}}
                    className="md:overflow-visible z-max   rounded-8 overflow-hiddesn"
                    TransitionComponent={Transition}
                    transitionDuration={300}
                    fullScreen={fullScreen}
                    maxWidth={size || "md"}
                    // keepMounted
                    open={!!dialogs[name]}
                    onClose={() => (!shouldNotClose ? this.onClose() : console.log("can't close"))}
                    BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
                    fullWidth>
                    {/*<Backdrop className={classes.backdrop} open={open} onClick={handleClose}/>*/}
                    {!!dialogs[name] && <ComposedComponent onClose={this.onClose} isDialog {...rest} />}
                    {!shouldNotClose && (
                        <IconButton
                            className={"absolute hover:bg-red-dark hover:text-white bg-white z-9999 top-12 right-12 md:-top-16 md:-right-16 "}
                            data-cy={"closeDialog"}
                            // style={{top: -16, right: -16}}
                            onClick={this.onClose}>
                            <Icon>close</Icon>
                        </IconButton>
                    )}
                </Dialog>
            );
        }
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators(
            {
                turnOff: fuseActions.turnOff,
                // minimize    : fuseActions.minimize,
            },
            dispatch
        );
    }

    function mapStateToProps({fuse}) {
        return {
            dialogs: fuse.dialogs,
        };
    }
    return withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(DialogWrapper));
};

export default withDialog;
