import {Icon, List, Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import {EmptyView} from "@ui";
import {firmPrefix} from "myaxios";
import {memo, useEffect} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import useSWR from "swr";
import type {dataType} from "types";
import { turnOff } from "../../../../store/actions";
import type {WPListType} from "../../types";
import WPListItem from "../../WPs/Views/WPListItem";

// const WPDetailDialog = withDialog("wpDetail", "xl")(WPPage);
const UnreadWidget = () => {
    const {data, mutate} = useSWR<dataType<WPListType>>(`${firmPrefix}/workplans?unread=true&size=5`);
    // const [data, setData] = useState({totalElements: 0, content: []});

    useEffect(() => {
        mutate();
    }, []);

    const dispatch = useDispatch();
    const closeDialog = () => {
        dispatch(turnOff("dailyDashboard"));
        // @ts-ignore
        localStorage.setItem("showDashboardDate", +new Date());
    };

    return (
        data && (
            <Paper className="rounded-8 mb-16 flex-1 mr-16">
                {/*<WPDetailDialog />*/}
                <List
                    classes={{root: "pb-0 max-h-384 overflow-y-scroll"}}
                    subheader={
                        <ListSubheader
                            component="div"
                            className="flex justify-between  bg-white"
                            id="nested-list-subheader"
                        >
                            <div>
                                {<Translate id={"wps.Unread Workplans "} />}({data.totalElements})
                                <IconButton size={"small"} onClick={() => mutate()}>
                                    <Icon>refresh</Icon>
                                </IconButton>
                            </div>

                            <div>
                                <Button onClick={closeDialog} size={"small"} component={Link} to={"/wps/unread/true"}>
                                    {<Translate id={"wps.See all unread"} />}
                                </Button>
                            </div>
                        </ListSubheader>
                    }
                >
                    {data.content.length === 0 ? (
                        <EmptyView dense label={<Translate id={"wps.No workplans"} />} />
                    ) : (
                        data.content.map((wp, i) => (
                            <WPListItem className="border-b-1" wp={wp} small square elevation={0} key={i} />
                        ))
                    )}
                </List>
            </Paper>
        )
    );
};

export default memo(withLocalize(UnreadWidget));
