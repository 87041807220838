import axios, {crmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_BRAND = "[COMMERCE|BRAND] GET_BRAND";
export const SET_BRAND_SEARCH_TEXT = "[COMMERCE|BRAND] SET_BRANDS_SEARCH_TEXT";

export function getBrand(id){
    const request = axios.get(`${crmPrefix}/brand/${id}`);

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
                dispatch({
                    type: GET_BRAND,
                    payload: response.data
                });
                dispatch(finishLoading());
            }
        );
    };
}
