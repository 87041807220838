import {SelectUsers} from "@forms";
import {Chip, Icon, ListItemSecondaryAction} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {MyAvatar} from "@ui";
import {finishLoading, showMessage, startLoading, turnOff, turnOn} from "@fuseActions";
import classNames from "classnames";
import {Component} from "react";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import NameTooltipComponent from "utils/NameTooltipComponent";
import axios, {crmPrefix} from "../../../../../myaxios";

class Members extends Component {
    state = {
        edit: false,
        users: this.props.participants.map((i) => i.user),
        gData: [],
        loading: false,
        openMore: false,
    };
    //
    // getParticipants = (page) => {
    //     this.setState(state=>({users: this.props.participants.map(i=>i.user)}))
    // };

    submitUsers = (users) => {
        this.setState({loading: true});
        axios
            .put(`${crmPrefix}/updateParticipants`, {
                userIds: users.map((u) => u.id),
                meetingId: this.props.sourceItem?.id,
            })
            .then(() => {
                this.props.refresh();
                this.setState({loading: false});
                this.props.showMessage({
                    message: "message.Members Saved!",
                    variant: "success",
                });
            });
    };

    render() {
        const {dialogs, participants} = this.props;
        const {loading, users, openMore} = this.state;

        return (
            <>
                <SelectUsers members={users} disableWithoutDepartment onSubmit={(users) => this.submitUsers(users)} />
                <Divider />
                <List dense className={loading ? "loading py-0" : "py-0"}>
                    <ListItem disableGutters className={"pl-8"}>
                        <ListItemText primary={<Translate id={"_.Members"} />} />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.props.turnOn("selectUsers")} size={"small"}>
                                <Icon>{"edit"}</Icon>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {participants.length > 0 && (
                        <div className="px-8 pb-16">
                            {participants.map(({user}, i) => (
                                <Chip
                                    size={"small"}
                                    key={i}
                                    avatar={<MyAvatar user={user} />}
                                    className={classNames("m-1")}
                                    // label={user.lastName + user.firstName}
                                    label={<NameTooltipComponent user={user} strongTagFLname />}
                                />
                            ))}
                        </div>
                    )}
                </List>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: turnOn,
            turnOff: turnOff,
            showMessage: showMessage,
            startLoading: startLoading,
            finishLoading: finishLoading,
        },
        dispatch
    );
}

function mapStateToProps({fuse, marketing}) {
    return {
        dialogs: fuse.dialogs,
        item: marketing.companies.item,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
