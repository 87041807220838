import {ListItemText} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import {ItemTitle, LocalTimeStamp, MyTooltip} from "@ui";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {TodoType} from "./Page";

const TaskItem = ({item}: {item: TodoType}) => {
    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);
    const {t} = useTranslation("todo");

    // handleChange = (event, expanded) => {
    //     if(!this.props.item.taskId){
    //         if(expanded) {
    //             this.getSubTasks()
    //         }
    //         if(!expanded) {
    //             this.props.finishLoading();
    //         }
    //     }
    // };

    // getSubTasks = () => {
    //     const {item} = this.props;
    //     this.props.startLoading();
    //     axios.get(`${pmsPrefix}/subtasks?taskId=${item.id}`).then(res=>{
    //         const subTasks = res.data;
    //         this.props.finishLoading();
    //         this.setState({subTasks, loaded: true})
    //     })
    // };

    // componentDidUpdate = (prevProps, prevState, snapshot) => {
    //     if((prevProps.item.subtasksQuantity !== this.props.item.subtasksQuantity) || this.props.dialogs["shouldRefreshTasks"]) {
    //         this.getSubTasks();
    //     }
    // };

    // // 回调手机端的地图APP
    // openNavigationDialog = (e) => {
    //
    // };

    // checkboxHandler = (e) => {
    //     e.stopPropagation();
    //     const data = {
    //         "taskId": this.props.task.id,
    //         "status": e.target.checked ? "DONE" : "OPEN"
    //     };
    //     this.setState({checkbox: e.target.checked});
    //     axios.put(`${pmsPrefix}/toggleTaskStatus`, data).then(()=> {
    //         this.props.showMessage({message: "message.task status changed!"})
    //
    //     }).catch(() => {
    //
    //         this.setState({checkbox: this.props.task.status === "DONE"});
    //     });
    // };

    const title = item.description ? item.description.slice(0, 40) : item.project.customerName ? item.project.customerName : item.id.slice(0, 6);

    return (
        <>
            <ListItemText
                className={classNames(
                    // this.state.checkbox && "line-through",
                    "text-base",
                    !item.taskId && "font-bold",
                    `text-${item.status}`
                )}
                secondary={!listView && item.project.name}
                primary={
                    <>
                        <ItemTitle to={`/todo/${item.id}`}>{title}</ItemTitle>
                        {listView && (
                            <small className={"text-grey-darker ml-2"}>
                                {item.project.name}-{item.project.customerName}
                            </small>
                        )}
                    </>
                }
            />
            <ListItemSecondaryAction classes={{root: "flex flex-col itemsf-end"}}>
                <div className={"flex justify-between items-end"}>
                    <small className={"text-grey-darker "}>
                        <LocalTimeStamp datetime={item.timestamp} />
                    </small>
                    <MyTooltip title={t("Stages")}>
                        <Typography className={"border px-3 font-bold  rounded-full inline-block"}>
                            {" "}
                            {item.stagesDoneQuantity} /{item.stagesQuantity}{" "}
                        </Typography>
                    </MyTooltip>
                </div>
                <LinearProgress variant="determinate" className={"w-136 mt-3"} value={(item.stagesDoneQuantity * 100) / item.stagesQuantity} />
            </ListItemSecondaryAction>
        </>
    );
};

export default TaskItem;
