import {FINISH_LOADING, START_LOADING} from "../../actions";

const loading = function (state = false, action) {
    switch (action.type) {
        case START_LOADING:
            return true;
        case FINISH_LOADING:
            return false;
        default: {
            return state;
        }
    }
};

export default loading;
