import {showMessage, turnOff} from "@fuseActions";
import type {DialogProps} from "@material-ui/core";
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import type {ReactNode} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FuseSuspense} from "../../@fuse";

type Props = {
    header?: ReactNode;
    name: string;
    shouldNotClose?: boolean;
    onCloseCallback?: () => void;
    children: ((onClose: () => void) => ReactNode) | ReactNode;
} & Omit<DialogProps, "open" | "onClose" | "children">;

// export const Transition = forwardRef(function Transition(props, ref) {
//     return <Grow ref={ref} {...props} />;
// });

const DialogWrapper = (props: Props) => {
    const {name, maxWidth, shouldNotClose, children, header, fullScreen, onCloseCallback, ...rest} = props;
    const dispatch = useDispatch();

    const open = useSelector(({fuse}) => fuse.dialogs[name]);
    const onClose = () => {
        dispatch(turnOff(name));
        if (typeof onCloseCallback === "function") onCloseCallback();
    };

    return (
        <Dialog
            data-cy={"dialog-" + name}
            scroll={"body"}
            classes={{paper: "md:overflow-visible ", root: "md:overflow-visible"}}
            className="md:overflow-visible z-max   rounded-8 overflow-hiddesn"
            // @ts-ignore
            // closeAfterTransition
            // @ts-ignore
            // TransitionComponent={Transition}
            // transitionDuration={300}
            // fullScreen={fullScreen}
            maxWidth={maxWidth}
            // keepMounted
            open={!!open}
            fullScreen={fullScreen}
            onClose={() => (!shouldNotClose ? onClose() : dispatch(showMessage({message: "Can not close  this dialog", variant: "error"})))}
            BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
            fullWidth
            {...rest}>
            {header ? <DialogTitle>{header}</DialogTitle> : ""}
            <FuseSuspense>{!!open && (typeof children === "function" ? children(onClose) : children)}</FuseSuspense>
            {!shouldNotClose && (
                <IconButton
                    className={"absolute hover:bg-red-dark hover:text-white bg-white z-9999 top-12 right-12 md:-top-16 md:-right-16 "}
                    data-cy={"closeDialog"}
                    // style={{top: -16, right: -16}}
                    onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            )}
        </Dialog>
    );
};

export default withMobileDialog()(DialogWrapper);
