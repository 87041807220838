import {lazy} from "react";

export const ExternalConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/management/external/generated-qrcodes",
            component: lazy(() =>
                import("./management/QRCodeList/QRCodePage")
            )
        },
        {
            path     : "/management/external/project/:projectId/checkin_results",
            component: lazy(() =>
                import("./management/checkinResults/CheckinResultsPage")
            )
        },
        {
            path     : "/management/external/checkin_results",
            component: lazy(() =>
                import("./management/checkinResults/CheckinResultsPage")
            )
        },
        {
            path     : "/management/external/users",
            component: lazy(() =>
                import("./management/externalUsers/ExternalUsersPage")
            )
        }
    ]
};
