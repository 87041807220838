
//background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
import {useLinearGradientColorsHelper} from "../../../../../../../../@fuse/hooks";

interface Options  {
    children: any;
}
function RootView({children}: Options) {

    const {premiumWhiteLinearColor} = useLinearGradientColorsHelper();

    return (
        <div style={{
            backgroundImage: premiumWhiteLinearColor,
            height: "100%",
            width: "100%",
            padding: "10px",
        }}>
            {children}
        </div>
    );
}

export default RootView;

