import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";

export const LazyLoader = ({children}) => {
    const [isVisible, setIsVisible] = useState(false);
    // const ref = useRef(null);
    const {inView, ref} = useInView({
        threshold: 1, // Observe when 50% of the element is visible
        root: null, // Observe relative to the viewport
        rootMargin: "0px", // No additional margin
        triggerOnce: true,
    });

    useEffect(() => {
        setIsVisible(inView);
    }, [inView]);

    return <div ref={ref}>{isVisible && children}</div>;
};
