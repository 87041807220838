import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import {useTranslation} from "react-i18next";
import {Translate} from "react-localize-redux";
import Moment from "react-moment";
import TimeAgo from "timeago-react";

type Props = {
    /**
     *  unixTimestamp : parse timestamp to local date
     */
    unixTimestamp?: boolean;

    /**
     *  showInsideTip : show tooltip inside the component
     */
    showInsideTip?: boolean;
    /**
     *  dateStyleAgo:
     *  true: show  date ago , the tooltip  show the yyyy-mm-dd;
     *  false: show the yyyy-mm-dd, the tooltip show date ago;
     */
    dateStyleAgo?: boolean;
    /**
     * format:
     *  like: YYYY-MM-DD, YYYY-MM-DD hh:mm
     */
    format?: string;
    // relativeDate: PropTypes.any,
    datetime: any;
    className?: string;
};

// LocalTimeStamp.defaultProps = {
//     showInsideTip: true,
//     dateStyleAgo: false,
//     unixTimestamp: true,
// };

export function LocalTimeStamp(props: Props) {
    const {i18n} = useTranslation();
    const {unixTimestamp = true, datetime, className, showInsideTip = true, dateStyleAgo = false, format} = props;

    // const language = localStorage.getItem("i18nextLng");

    const getCurrentTime = useMemoizedFn((timestamp) => {
        return unixTimestamp ? timestamp / 1000 : timestamp;
    });

    const ContainTooltipComponent = useMemoizedFn(() => {
        if (dateStyleAgo) {
            return (
                <MyTooltip
                    title={
                        <Moment local unix={unixTimestamp} className={className ? className : ""} format={format ? format : "YYYY-MM-DD"}>
                            {datetime ? getCurrentTime(datetime) : ""}
                        </Moment>
                    }>
                    <TimeAgo
                        className={className ? className : ""}
                        opts={{minInterval: 60}}
                        datetime={datetime && datetime}
                        locale={i18n.language === "cn" ? "zh_CN" : "en_US"}
                        live={false}
                    />
                </MyTooltip>
            );
        }
        return (
            <MyTooltip
                title={
                    <TimeAgo
                        className={className ? className : ""}
                        opts={{minInterval: 60}}
                        datetime={datetime && datetime}
                        locale={i18n.language === "cn" ? "zh_CN" : "en_US"}
                        live={false}
                    />
                }>
                <Moment unix={unixTimestamp} className={className ? className : ""} local format={format ? format : "YYYY-MM-DD"}>
                    {datetime ? getCurrentTime(datetime) : ""}
                </Moment>
            </MyTooltip>
        );
    });

    const NoTooltipComponent = useMemoizedFn(() => {
        if (dateStyleAgo) {
            return (
                <TimeAgo
                    className={className ? className : ""}
                    opts={{minInterval: 60}}
                    datetime={datetime && datetime}
                    locale={i18n.language === "cn" ? "zh_CN" : "en_US"}
                    live={false}
                />
            );
        }
        return (
            <Moment local unix={unixTimestamp} className={className ? className : ""} format={format ? format : "YYYY-MM-DD"}>
                {datetime ? getCurrentTime(datetime) : ""}
            </Moment>
        );
    });

    return (
        <>
            {datetime > 10000000 ? (
                !showInsideTip ? (
                    NoTooltipComponent()
                ) : (
                    ContainTooltipComponent()
                )
            ) : (
                <span className={className ? className : ""}>
                    <Translate id={"_.No date"} />
                </span>
            )}
        </>
    );
}

