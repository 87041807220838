import {
    Icon,
    ListItemIcon,
    MenuItem,
    MenuList,
    Popover,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import {useSelector} from "react-redux";
import clsx from "clsx";
import HeadCellView from "./HeadCellView";
import IconButton from "@material-ui/core/IconButton";
import {Translate} from "react-localize-redux";
import Switch from "@material-ui/core/Switch";
import {useState} from "react";
import {isMobile} from "react-device-detect";
import type {columnType} from "types";

type Props<T> = {
    orderBy?: string;
    direction?: "asc" | "desc";
    onRequestSort?: (property: string) => void;
    selectable?: boolean;
    columns: columnType<T>[];
    allColumns?: columnType<T>[];
    smallHeader?: boolean;
    hides?: Set<string>;
    setHides?: (arg: Set<string>) => void;
};

const TableHeadView = <T,>({
    selectable,
    columns,
    smallHeader,
    hides,
    allColumns,
    setHides,
    onRequestSort,
    orderBy,
    direction,
}: Props<T>) => {
    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleHide = (col: columnType<T>) => () => {
        const t = new Set(hides);
        if (hides?.has(col.id)) t.delete(col.id);
        else t.add(col.id);
        if (typeof setHides === "function") setHides(t);
    };

    const toggleAll = () => {
        if (hides && hides?.size > 0 && typeof setHides === "function") {
            setHides(new Set());
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <TableHead>
            <TableRow className={clsx(listView || smallHeader ? "h-36" : "h-64", "relative")}>
                <TableCell>
                    {allColumns && allColumns.length > 0 && hides && (
                        <>
                            <IconButton onClick={handleClick} size={"small"} className={"absolute top-0 left-0"}>
                                <Icon fontSize={"small"}>settings</Icon>
                            </IconButton>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuList>
                                    {allColumns.map(
                                        (col) =>
                                            !col.noHide && (
                                                <MenuItem key={col.id} onClick={toggleHide(col)}>
                                                    <ListItemIcon>
                                                        <Switch size={"small"} checked={!hides.has(col.id)} />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">
                                                        {<Translate id={col.label} />}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                    )}
                                    {hides.size > 0 && (
                                        <MenuItem onClick={toggleAll}>
                                            <ListItemIcon></ListItemIcon>
                                            <Typography variant="inherit">
                                                <Translate id={"_.Show All"} />
                                            </Typography>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </Popover>
                        </>
                    )}
                </TableCell>
                {selectable && isMobile && <TableCell />}
                {columns.map((col) => {
                    return (
                        <HeadCellView<T>
                            toggleHide={toggleHide(col)}
                            hides={hides}
                            setHides={setHides}
                            key={col.id}
                            orderBy={orderBy}
                            direction={direction}
                            column={col}
                            onClick={() => {
                                if (typeof onRequestSort === "function") onRequestSort(col.id);
                            }}
                        />
                    );
                }, this)}
            </TableRow>
        </TableHead>
    );
};

export default TableHeadView;
