export const VIEW_PROCESS_STEPS = "VIEW_PROCESS_STEPS";
export const CLOSE_PROCESS_STEPS = "CLOSE_VIEW_PROCESS_STEPS";

/**
 * @param {Object} options ---- the params of input
 * @param {array}  options.datas ---- instance.taskStepInfo
 */
export const openStepsProcessView = (options) => {
    return {
        type   : VIEW_PROCESS_STEPS,
        payload: { ...options }
    };
};



export const closeStepsProcessView = () => {
    return {
        type   : CLOSE_PROCESS_STEPS
    };
};
