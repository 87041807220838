import {memo} from "react";
import {Icon, IconButton} from "@material-ui/core";
import * as Actions from "app/store/actions";
import {useDispatch} from "react-redux";

function NavbarMobileToggleButton(props)
{
    const dispatch = useDispatch();

    return (
        <IconButton  className={props.className + " no-drag"} onClick={ev => dispatch(Actions.navbarToggleMobile())} disableRipple>
            {props.children}
        </IconButton>
    );
}

NavbarMobileToggleButton.defaultProps = {
    children: <Icon>menu</Icon>
};

export default memo(NavbarMobileToggleButton);
