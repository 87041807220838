import {useState} from "react";
import PropTypes from "prop-types";
import {useDebounceFn, useMemoizedFn} from "ahooks";
import {Translate} from "react-localize-redux";
import TextField from "@material-ui/core/TextField";

SearchBox.propTypes = {
    searchBy: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    onChangeQuery: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
};

function SearchBox(props) {
    const {query, onChangeQuery, mutate, searchBy} = props;

    const [text, setText] = useState("");

    const {run} = useDebounceFn(
        () => {
            let params = {...query};
            if (text !== "") {
                params = {
                    ...params,
                    [searchBy]: text,
                };
            } else {
                delete params[`${searchBy}`];
            }
            mutate(params).then((response) => {
                if (response?.status === 200) {
                    onChangeQuery(params);
                }
            });
        },
        {wait: 500}
    );

    const handleSearch = useMemoizedFn((e) => {
        setText(e.target.value);
        run();
    });

    return (
        <div className={"flex flex-row items-center p-5 mr-3"}>
            <TextField
                id="Instance-table-SearchBox"
                fullWidth
                onChange={handleSearch}
                label={<Translate id={"_.search"} />}
                variant="outlined"
            />
        </div>
    );
}

export default SearchBox;
