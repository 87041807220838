import {showMessage} from "@fuseActions";
import {Dialog} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {useMemoizedFn} from "ahooks";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useProcessStore} from "../../state/useProcessStore";

function NewDefinitionEditorDialog(props) {
    const [loading, setLoading] = useState(false);

    const [category, setCategory] = useState("");

    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.definitionEditor
    });

    const selectedDefinition = useProcessStore((state) => state.selectedDefinition);

    const onChangeSelectedDefinition = useProcessStore((state) => state.onChangeSelectedDefinition);

    useEffect(() => {
        console.log("NewDefinitionEditorDialog > useEffect > dialogProps", dialogProps);

        return () => {
            onChangeSelectedDefinition(null);
        };
    }, []);

    const handleChange = useMemoizedFn((e) => {
        setCategory(e.target.value);
    });

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        const params = {
            id: selectedDefinition.id,
            category: category
        };
        axios
        .put(`${flowPrefix}/processDefinition`, params)
        .then((response) => {
            if (response.status === 200) {
                const params = {
                    variant: "success",
                    message: "message.Definition edit successfully!"
                };
                showMessage(params);
                finshParams?.onFinish();
                onTurnOffDialog();
            }
        })
        .finally(() => {
            setLoading(false);
        });
    });

    const canSubmit = useMemoizedFn(() => {
        return loading === true || category?.trim() === "";
    });

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <Typography variant="h6" className={"ml-20 mt-20"} color="inherit">
                    <Translate id={"process.Edit definition"} />

                    <Chip label={selectedDefinition?.name} className={"ml-3"} color={"secondary"} size={"small"} />
                </Typography>

                <DialogContent className="flex flex-col justify-center items-center">
                    <TextField
                        id="standard-category"
                        fullWidth
                        label={<Translate id={"process.category"} />}
                        value={category}
                        onChange={handleChange}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>
                        <Translate id="process.Cancel" />
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={canSubmit()}
                        onClick={handleSubmit}
                        color="primary"

                    >
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewDefinitionEditorDialog;
