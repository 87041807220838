import {lazy} from "react";

export const OutsourceConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/os/productBoms",
            component: lazy(() => import("./ProductBOMs")),
        },
        {
            path: "/os/dashboard",
            component: lazy(() => import("./dashboard/Page")),
        },
        {
            path: "/os/order/:id",
            component: lazy(() => import("./orders/single/Page")),
        },
        {
            path: "/os/orders",
            component: lazy(() => import("./orders/list/Page")),
        },
        {
            path: "/os/pendingDeliveryTasks",
            component: lazy(() => import("./receipts/Page")),
        },
        {
            path: "/os/myOrders",
            component: lazy(() => import("./orders/myOrders/Page")),
        },
        {
            path: "/os/deliveryOrder/:id",
            component: lazy(() => import("./deliveryOrders/single/Page")),
        },
        {
            path: "/os/deliveryOrders",
            component: lazy(() => import("./deliveryOrders/list/Page")),
        },
        {
            path: "/os/myDeliveryOrders",
            component: lazy(() => import("./deliveryOrders/myDeliveryOrders/Page")),
        },
        {
            path: "/os/invoice/:id",
            component: lazy(() => import("./invoices/single/Page")),
        },
        {
            path: "/os/invoices",
            component: lazy(() => import("./invoices/list/Page")),
        },
        {
            path: "/os/suppliers",
            component: lazy(() => import("./suppliers/Page")),
        },
        {
            path: "/os/supplier/:id",
            component: lazy(() => import("./suppliers/single/Page")),
        },
        {
            path: "/os/template/:id",
            component: lazy(() => import("./templates/single/Page")),
        },
        {
            path: "/os/templates",
            component: lazy(() => import("./templates/list/Page")),
        },
        {
            path: "/os/myTemplates",
            component: lazy(() => import("./templates/list/Page")),
        },
    ],
};
