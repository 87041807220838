import {lazy} from "react";

export const FileManagerConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/apps/file-manager",
            component: lazy(() => import("./FileManager")),
        },
    ],
};
