export const rowsPerPage = 20;
export const yuanSymbol = "¥";
export const dateFormat = "YYYY/MM/DD";
export const dayMonthFormat = "MM/DD";
export const timeFormat = "HH:mm";
export const dateFormatWithTime = "MM/DD, HH:mm";
export const PICKER_DATE_FORMAT = "yyyy-MM-dd";
export const PICKER_DATE_TIME_A_FORMAT = "yyyy-MM-dd HH:mm a";

export const mockServer = "http://192.168.60.121:8700";
