import {FusePageSimple} from "@fuse";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import {Component} from "react";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import Empty from "utils/Empty";
import jsons from "../../translates/translate.json";
import { clearItem, selectItem, getItem } from "../store/actions";
import Header from "./Header";
import SubItem from "./SubItem";

class SinglePage extends Component {
    state = {
        currentItem: 0,
    };

    constructor(props) {
        super(props);
        this.props.addTranslation(jsons);
    }

    componentDidMount = () => {
        const {id} = this.props.match.params;
        if (id) this.props.getItem(id);
    };

    componentDidUpdate = (prevProps) => {
        const {match, item, getItem} = this.props;
        if (item && prevProps.item && match.params.id) {
            if (match.params.id !== prevProps.match.params.id) {
                const {id} = match.params;
                getItem(id);
            }
        }
    };

    componentWillUnmount = () => {
        this.props.clearItem();
        this.props.selectItem(null);
    };

    gotoNextStep = (index) => {
        this.setState({
            currentItem: index,
        });
    };

    render() {
        const {item, selectItem, getItem} = this.props;
        const subProps = {
            sourceType: "archive",
            sourceItem: item,
            selectItem,
            currentItem: this.state.currentItem,
            nextStepAction: this.gotoNextStep,
            refresh: () => getItem(item.id),
        };
        return !item ? (
            <Empty />
        ) : (
            <FusePageSimple
                classes={{
                    header: "h-128",
                }}
                header={<Header {...subProps} />}
                content={
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <List dense className={"p-0"}>
                                {item.items && item.items.length > 0 ? item.items.map((t) => <SubItem item={t} key={t.id} />) : <Empty />}
                            </List>
                        </Grid>
                    </Grid>
                }
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearItem: clearItem,
            selectItem: selectItem,
            getItem: getItem,
        },
        dispatch
    );
}

function mapStateToProps({user, fuse, marketing}) {
    return {
        profile: user.profile,
        item: marketing.archives.item,
        dialogs: fuse.dialogs,
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(SinglePage)));
