import {FormControlLabel, IconButton} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import {EmptyView} from "@ui";
import clsx from "clsx";
import _ from "lodash";
import type {FC} from "react";
import {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Translate} from "react-localize-redux";
import DatePickerWrapper from "../../../../../utils/DatePickerWrapper/DatePickerWrapper";
import SelectButtons from "../../../../../utils/Forms/SelectButtons";
import {useDGFilters} from "../../hooks/hooks";
import type {DGColType, filterType} from "../../types";

type FilterHeadParams = {
    col: DGColType;
    selectedFilter?: filterType;
};

// type YearCat = {
//     year: number;
//     count: number;
//     months: {
//         month: number;
//         count: number;
//         days: number[];
//     }[];
// };

const DateFilterHead = memo(
    function DateFilterHead(props: FilterHeadParams) {
        const {t} = useTranslation("datagrid");
        const {col, selectedFilter} = props;
        // const {rows} = useDGRows();
        const {onRemoveFilter, onChangeFilter} = useDGFilters();
        // @ts-ignore
        const [dateFrom, setDateFrom] = useState<number | undefined>(selectedFilter?.value?.dateFrom);
        // @ts-ignore
        const [dateTo, setDateTo] = useState<number | undefined>(selectedFilter?.value?.dateTo);
        const [btn, setBtn] = useState<"same" | "before" | "after" | "notEmpty" | "empty" | "range">("same");
        const onChange = () => (event) => {
            setBtn(event);
        };
        const filterByDate = (date) => {
            setDateTo(date);
        };

        const onChangeEmpty = () => (event) => {
            if (btn === event) {
                setBtn("same");
                onRemoveFilter(col.id);
            } else {
                setBtn(event);
                setDateFrom(undefined);
                setDateTo(undefined);
                // if (event === "empty" || event === "notEmpty")
                onChangeFilter({
                    colId: col.id,
                    condition: event,
                });
            }
        };
        // useWhyDidYouUpdate("DateFilterHead", {...props});

        // const categorizedDate = useMemo<YearCat[]>(() => {
        //     const startTime = performance.now();
        //     const t = rows
        //         .map((item) => item[col.id])
        //         .filter((d) => d !== undefined && Date.parse(d))
        //         .reduce((categories, timestamp) => {
        //             const date = new Date(timestamp);
        //             const year = date.getFullYear(); // Year
        //             const month = date.getMonth(); // 0-indexed month number
        //
        //             const existingYear = categories.find((category) => category.year === year);
        //
        //             if (existingYear) {
        //                 const existingMonth = existingYear.months.find((monthObj) => monthObj.month === month);
        //                 existingYear.count++;
        //                 if (existingMonth) {
        //                     existingMonth.count++;
        //                     const day = date.getDate();
        //                     if (!existingMonth.days.includes(day)) {
        //                         existingMonth.days.push(day);
        //                     }
        //                     // existingMonth.days.push(date.getDate()); // Add day to existing month
        //                 } else {
        //                     existingYear.months.push({
        //                         month,
        //                         count: 1,
        //                         days: [date.getDate()], // Add day to new month
        //                     });
        //                 }
        //             } else {
        //                 categories.push({
        //                     year,
        //                     count: 1,
        //                     months: [
        //                         {
        //                             month,
        //                             count: 1,
        //                             days: [date.getDate()], // Add day to new month
        //                         },
        //                     ],
        //                 });
        //             }
        //
        //             return categories;
        //         }, [] as ReturnTypeDateCat);
        //     const endTime = performance.now();
        //     console.log("categorizedDate Execution time:", endTime - startTime, "milliseconds");
        //     return t;
        // }, [col.id, rows]);

        // const toggleYearFilter = () => () => {
        //     // onAddFilter(col.id, JSON.stringify({year}));
        // };
        //
        // const toggleMonthFilter = (
        //     year, month) => (e) => {
        //     if (e.target.checked) {
        //         const shouldRemove = filterValue.filter((v) => JSON.parse(v).month === month);
        //         onChangeFilters(col.id, shouldRemove, [JSON.stringify({month, year})]);
        //     } else {
        //         // onAddFilter(col.id, JSON.stringify({month, year}));
        //     }
        //     // if (i > -1) {
        //     //     if (e.target.checked) {
        //     //         t[i].value = t[i].value ? [...t[i].value, value] : [value];
        //     //     } else {
        //     //         t[i].value = t[i].value?.filter((v) => v !== value) || [];
        //     //     }
        //     // }
        //     // setFilters(t);
        //     // addFilter(col.id, JSON.stringify({month, year}))(e);
        // };
        //
        // const toggleDayFilter = (year, month, day) => e => {
        //     addFilter(col.id, JSON.stringify({year}))(e)
        // }

        return (
            <>
                <div data-tut={"wp date"} className={"flex p-12 items-center"}>
                    <DatePickerWrapper
                        disabled={btn === "empty" || btn === "notEmpty"}
                        className={clsx("mr-12 w-128  max-w-128")}
                        // disableToolbar
                        TextFieldComponent={(p) => <TextField data-cy={"wps-filters-from"} {...p} variant={"outlined"} size={"small"} />}
                        variant="inline"
                        label={<Translate id={"_.From"} />}
                        autoOk
                        value={dateFrom || null}
                        // placeholder={translate('_.From')}
                        // @ts-ignore
                        onChange={(date) => setDateFrom(+new Date(date))}
                    />
                    {btn === "range" && (
                        <DatePickerWrapper
                            TextFieldComponent={(p) => <TextField data-cy={"wps-filters-to"} {...p} variant={"outlined"} size={"small"} />}
                            className={clsx(!dateFrom && "opacity-40", "w-128 max-w-128")}
                            // disableToolbar
                            variant="inline"
                            label={<Translate id={"_.To"} />}
                            autoOk
                            // placeholder={translate('_.To')}
                            disabled={!dateFrom}
                            value={dateTo || null}
                            onChange={filterByDate}
                        />
                    )}
                </div>

                <div className="px-12">
                    <SelectButtons
                        formData={{[col.id]: btn}}
                        type={col.id}
                        items={[
                            {value: "same", label: "Same"},
                            // {value: "neq", label: "!="},
                            {value: "before", label: "Before"},
                            // {value: "<=", label: "<="},
                            {value: "after", label: "After"},
                            {value: "range", label: "Range"},
                            // {value: ">=", label: ">="},
                        ]}
                        onChange={onChange}
                    />
                </div>

                <div className={"flex justify-between items-center mt-20 px-12 mb-12"}>
                    <SelectButtons
                        formData={{[col.id]: btn}}
                        type={col.id}
                        items={[
                            {value: "empty", label: "Empty"},
                            {value: "notEmpty", label: "Not Empty"},
                        ]}
                        onChange={onChangeEmpty}
                    />
                    <div className="flex">
                        {selectedFilter && (
                            <IconButton
                                size={"small"}
                                onClick={() => {
                                    setDateFrom(undefined);
                                    setBtn("same");
                                    onRemoveFilter(col.id);
                                }}>
                                <Icon children="close" />
                            </IconButton>
                        )}
                        <Button
                            disabled={!dateFrom || (btn === "range" && !dateTo)}
                            className={"ml-12"}
                            startIcon={<Icon children="search" />}
                            size={"small"}
                            onClick={() => {
                                if (btn !== "empty" && btn !== "notEmpty")
                                    onChangeFilter({
                                        colId: col.id,
                                        value: {dateFrom: dateFrom || 0, dateTo: dateTo || 0},
                                        condition: btn,
                                    });
                            }}>
                            {t("filter")}
                        </Button>
                    </div>
                </div>

                {/*{categorizedDate.length > 0 ? (*/}
                {/*    categorizedDate.map((cat, index) => {*/}
                {/*        return (*/}
                {/*            <div key={cat.year}>*/}
                {/*                {index > 0 && <Divider className={"mt-2"} />}*/}
                {/*                <div className={"hover:bg-grey-light bg-grey-lighter duration-200"}>*/}
                {/*                    <FormControlLabel*/}
                {/*                        className={"pl-12"}*/}
                {/*                        // checked={filterValue.includes(JSON.stringify({year: cat.year}))}*/}
                {/*                        label={*/}
                {/*                            <strong>*/}
                {/*                                {cat.year} ({cat.count})*/}
                {/*                            </strong>*/}
                {/*                        }*/}
                {/*                        control={<Checkbox size={"small"} />}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                {cat.months.map((month, i) => (*/}
                {/*                    <>*/}
                {/*                        {i > 0 && <Divider className={"mt-2"} />}*/}
                {/*                        <div className={"pl-20"}>*/}
                {/*                            <div className={"hover:bg-grey-lighter duration-200"}>*/}
                {/*                                <FormControlLabel*/}
                {/*                                    // checked={*/}
                {/*                                    //     filterValue.includes(*/}
                {/*                                    //         JSON.stringify({*/}
                {/*                                    //             month: month.month,*/}
                {/*                                    //             year: cat.year,*/}
                {/*                                    //         })*/}
                {/*                                    //     ) || filterValue.includes(JSON.stringify({year: cat.year}))*/}
                {/*                                    // }*/}
                {/*                                    // disabled={filterValue.includes(JSON.stringify({year: cat.year}))}*/}
                {/*                                    // onChange={addFilter(col.id, JSON.stringify({month: month.month, year: cat.year}))}*/}
                {/*                                    // onChange={toggleMonthFilter(cat.year, month.month)}*/}
                {/*                                    className={"pl-12"}*/}
                {/*                                    label={*/}
                {/*                                        <>*/}
                {/*                                            <Translate id={`months.${month.month + 1}`} /> ({month.count})*/}
                {/*                                        </>*/}
                {/*                                    }*/}
                {/*                                    control={<Checkbox size={"small"} />}*/}
                {/*                                />*/}
                {/*                            </div>*/}
                {/*                            <div className={"pl-24 max-w-320 grid grid-cols-3"}>*/}
                {/*                                {month.days.map((day) => (*/}
                {/*                                    <span className={"hover:bg-grey-lighter duration-200"} key={day}>*/}
                {/*                                        <FormControlLabel*/}
                {/*                                            className={"pl-12"}*/}
                {/*                                            // disabled={*/}
                {/*                                            //     filterValue.includes(JSON.stringify({year: cat.year})) ||*/}
                {/*                                            //     filterValue.includes(*/}
                {/*                                            //         JSON.stringify({*/}
                {/*                                            //             month: month.month,*/}
                {/*                                            //             year: cat.year,*/}
                {/*                                            //         })*/}
                {/*                                            //     )*/}
                {/*                                            // }*/}
                {/*                                            // checked={*/}
                {/*                                            //     filterValue.includes(*/}
                {/*                                            //         JSON.stringify({*/}
                {/*                                            //             day,*/}
                {/*                                            //             month: month.month,*/}
                {/*                                            //             year: cat.year,*/}
                {/*                                            //         })*/}
                {/*                                            //     ) ||*/}
                {/*                                            //     filterValue.includes(*/}
                {/*                                            //         JSON.stringify({*/}
                {/*                                            //             month: month.month,*/}
                {/*                                            //             year: cat.year,*/}
                {/*                                            //         })*/}
                {/*                                            //     ) ||*/}
                {/*                                            //     filterValue.includes(JSON.stringify({year: cat.year}))*/}
                {/*                                            // }*/}
                {/*                                            label={*/}
                {/*                                                <>*/}
                {/*                                                    {day}*/}
                {/*                                                    <Translate id={"_.th"} />*/}
                {/*                                                </>*/}
                {/*                                            }*/}
                {/*                                            control={<Checkbox size={"small"} className={"p-6"} />}*/}
                {/*                                        />*/}
                {/*                                    </span>*/}
                {/*                                ))}*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </>*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        );*/}
                {/*    })*/}
                {/*) : (*/}
                {/*    <div className={"p-12"}>No date found</div>*/}
                {/*)}*/}
            </>
        );
    },
    (p, n) => p.col.id !== n.col.id
);

// export const SizeFilterHead = memo(function SizeFilterHead(props: FilterHeadParams) {
//     const {
//         // col.id,
//         filterValue,
//     } = props;
//     // const {onAddFilter} = useDGFilters();
//     return (
//         <div>
//             <div className={"hover:bg-grey-lighter duration-200"}>
//                 <FormControlLabel
//                     className={"pl-12"}
//                     label={<strong>{"< 1MB"}</strong>}
//                     checked={filterValue?.includes("0")}
//                     // onChange={() => onAddFilter(col.id, "0")}
//                     control={<Checkbox size={"small"} />}
//                 />
//             </div>
//             {getDataSizes.map(
//                 (c, index) =>
//                     index > 0 && (
//                         <div key={c} className={"hover:bg-grey-lighter duration-200"}>
//                             <FormControlLabel
//                                 className={"pl-12"}
//                                 checked={filterValue.includes(String(index))}
//                                 // onChange={() => onAddFilter(col.id, String(index))}
//                                 label={<SizeLabel index={index} />}
//                                 control={<Checkbox size={"small"} />}
//                             />
//                         </div>
//                     )
//             )}
//             <div className={"hover:bg-grey-lighter duration-200"}>
//                 <FormControlLabel
//                     className={"pl-12"}
//                     checked={filterValue?.includes("-1")}
//                     // onChange={() => onAddFilter(col.id, "-1")}
//                     label={<strong>{"> 4GB "}</strong>}
//                     control={<Checkbox size={"small"} />}
//                 />
//             </div>
//         </div>
//     );
// });

const NumberFilterHead = memo(function NumberFilterHead(props: FilterHeadParams) {
    const {col} = props;
    const {onChangeFilter, onRemoveFilter} = useDGFilters();
    const {t} = useTranslation("datagrid");
    const [value, setValue] = useState("");
    const [toValue, setToValue] = useState("");
    const [btn, setBtn] = useState<"eq" | "lt" | "gt" | "between" | "empty" | "notEmpty">("eq");

    // const categorizedNumbers = useMemo(() => {
    //     const colData = data
    //         .filter((row) => row[col.id] && !isNaN(row[col.id]) && Number(row[col.id]) > 0)
    //         .map((row) => Number(row[col.id]));
    //     console.log(colData);
    //     if (!colData || colData.length < 5) return [];
    //     return categorizeNumbers(colData);
    // }, [col.id, data]);

    const onChange = () => (event) => {
        setBtn(event);
    };

    const onChangeEmpty = () => (event) => {
        if (btn === event) {
            setBtn("eq");
            onRemoveFilter(col.id);
        } else {
            setBtn(event);
            setValue("");
            // if (event === "empty" || event === "notEmpty")
            onChangeFilter({
                colId: col.id,
                condition: event,
            });
        }
    };

    return (
        <div className={"p-12 flex flex-col w-320"}>
            <div className="flex">
                <TextField
                    disabled={btn === "empty" || btn === "notEmpty"}
                    fullWidth
                    autoFocus={true}
                    type={"number"}
                    className={"mb-12"}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    label={btn === "between" ? "From" : "Number"}
                />
                {btn === "between" && (
                    <TextField type={"number"} className={"ml-12 mb-12"} value={toValue} onChange={(e) => setToValue(e.target.value)} label={"Tp"} />
                )}
            </div>

            <SelectButtons
                formData={{[col.id]: btn}}
                type={col.id}
                items={[
                    {value: "eq", label: "="},
                    // {value: "neq", label: "!="},
                    {value: "lt", label: "<"},
                    // {value: "<=", label: "<="},
                    {value: "gt", label: ">"},
                    {value: "between", label: "Between"},
                    // {value: ">=", label: ">="},
                ]}
                onChange={onChange}
            />

            <div className={"flex justify-between items-center mt-20"}>
                <SelectButtons
                    formData={{[col.id]: btn}}
                    type={col.id}
                    items={[
                        {value: "empty", label: "Empty"},
                        {value: "notEmpty", label: "Not Empty"},
                    ]}
                    onChange={onChangeEmpty}
                />
                <div className="flex items-center">
                    {value.trim().length > 0 && (
                        <IconButton
                            size={"small"}
                            onClick={() => {
                                setValue("");
                                setToValue("");
                                setBtn("eq");
                                onRemoveFilter(col.id);
                            }}>
                            <Icon children="close" />
                        </IconButton>
                    )}
                    <Button
                        disabled={value.trim().length === 0 || (btn === "between" && toValue.trim().length === 0)}
                        className={"ml-12"}
                        startIcon={<Icon children="search" />}
                        size={"small"}
                        onClick={() => {
                            if (btn === "between")
                                onChangeFilter({
                                    colId: col.id,
                                    value: {
                                        from: value,
                                        to: toValue,
                                    },
                                    condition: btn,
                                });
                            else if (btn !== "empty" && btn !== "notEmpty")
                                onChangeFilter({
                                    colId: col.id,
                                    value,
                                    condition: btn,
                                });
                        }}>
                        {t("filter")}
                    </Button>
                </div>
            </div>

            {/*{categorizedNumbers.map((c) => (*/}
            {/*    <div key={c.min} className={"hover:bg-grey-lighter duration-200"}>*/}
            {/*        <FormControlLabel*/}
            {/*            className={"pl-12"}*/}
            {/*            checked={filterValue.includes(JSON.stringify(c))}*/}
            {/*            onChange={addFilter(col.id, JSON.stringify(c))}*/}
            {/*            label={<NumberLabel value={c} />}*/}
            {/*            control={<Checkbox size={"small"} />}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*))}*/}
        </div>
    );
});

const SelectObject = memo(function SelectFilterHead({col}: FilterHeadParams) {
    // const {selectedFilter, col} = props;
    // const {onChangeFilter, onRemoveFilter} = useDGFilters();
    // const {rows} = useIDB();
    // const [key, setKey] = useState("");

    // const selectableFilters = useMemo(() => {
    //     return _.uniqBy(
    //         rows.map((item) => item[col.id]),
    //         "id"
    //     );
    // }, [col.id, rows]);

    // const onToggle = (d) => (e) => {
    //     setKey(Math.random() + "");
    //     if (e.target.checked) {
    //         if (selectedFilter?.value && _.isArray(selectedFilter?.value) && selectedFilter.value.length > 0) {
    //             onChangeFilter({
    //                 colId: col.id,
    //                 value: [...selectedFilter.value, d],
    //                 condition: "eq",
    //             });
    //         } else {
    //             onChangeFilter({
    //                 colId: col.id,
    //                 value: [d],
    //                 condition: "eq",
    //             });
    //         }
    //     } else {
    //         if (selectedFilter?.value && _.isArray(selectedFilter?.value) && selectedFilter.value.length > 0) {
    //             if (selectedFilter.value.length === 1) {
    //                 onRemoveFilter(col.id);
    //             } else {
    //                 onChangeFilter({
    //                     colId: col.id,
    //                     value: selectedFilter.value.filter((item) => !_.isEqual(item, d)),
    //                     condition: "eq",
    //                 });
    //             }
    //         }
    //     }
    // };
    //
    // const isChecked = (d: any) => {
    //     if (!selectedFilter?.value || !d) return false;
    //     if (_.isArray(selectedFilter?.value)) {
    //         return selectedFilter?.value.includes(d);
    //     }
    //     return false;
    // };

    return (
        <>
            {/*{selectableFilters.map(*/}
            {/*    (d, index) =>*/}
            {/*        d && (*/}
            {/*            <div key={index} className={"hover:bg-grey-lighter duration-200"}>*/}
            {/*                <FormControlLabel*/}
            {/*                    className={"pl-12"}*/}
            {/*                    onChange={onToggle(d)}*/}
            {/*                    label={viewTypeToView[col.viewType].render({initValue: d})}*/}
            {/*                    control={*/}
            {/*                        <Checkbox*/}
            {/*                            checked={isChecked(d)}*/}
            {/*                            // onChange={}*/}
            {/*                            size={"small"}*/}
            {/*                        />*/}
            {/*                    }*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*)}*/}

            <EmptySelect col={col} />
        </>
    );
});

const EmptySelect = memo(function EmptySelect({col}: {col: DGColType}) {
    const {filters, onChangeFilter, onRemoveFilter} = useDGFilters();

    const [btn, setBtn] = useState<"empty" | "notEmpty">();

    const onChangeEmpty = () => (event) => {
        if (btn === event) {
            setBtn(undefined);
            onRemoveFilter(col.id);
        } else {
            setBtn(event);
            onChangeFilter({
                colId: col.id,
                condition: event,
            });
        }
    };

    useEffect(() => {
        const filterValue = filters.find((f) => f.colId === col.id);
        if (filterValue && (filterValue.condition === "notEmpty" || filterValue.condition === "empty")) {
            setBtn(filterValue.condition);
        } else {
            setBtn(undefined);
        }
    }, [filters]);

    return (
        <div className="p-12">
            <SelectButtons
                formData={{[col.id]: btn}}
                type={col.id}
                items={[
                    {value: "empty", label: col.viewType === "checkbox" ? "Unchecked" : "Empty"},
                    {value: "notEmpty", label: col.viewType === "checkbox" ? "Checked" : "Not Empty"},
                ]}
                onChange={onChangeEmpty}
            />
        </div>
    );
});

const SelectFromOptions = memo(function SelectFromOptions(props: FilterHeadParams) {
    const {
        col,
        selectedFilter,
        // filterValue,
        // col.id
    } = props;
    const {onChangeFilter, onRemoveFilter} = useDGFilters();
    const [key, setKey] = useState("");

    const onToggle = (d) => (e) => {
        setKey(Math.random() + "");
        if (e.target.checked) {
            if (selectedFilter?.value && _.isArray(selectedFilter?.value) && selectedFilter.value.length > 0) {
                onChangeFilter({
                    colId: col.id,
                    value: [...selectedFilter.value, d],
                    condition: "option",
                });
            } else {
                onChangeFilter({
                    colId: col.id,
                    value: [d],
                    condition: "option",
                });
            }
        } else {
            if (selectedFilter?.value && _.isArray(selectedFilter?.value) && selectedFilter.value.length > 0) {
                if (selectedFilter.value.length === 1) {
                    onRemoveFilter(col.id);
                } else {
                    onChangeFilter({
                        colId: col.id,
                        value: selectedFilter.value.filter((item) => !_.isEqual(item, d)),
                        condition: "option",
                    });
                }
            }
        }
    };

    // const {onAddFilter, onRemoveFilter} = useDGFilters();

    return (
        <>
            {col.options?.map((opt) => (
                <div key={opt} className={"hover:bg-grey-lighter duration-200"}>
                    <FormControlLabel
                        className={"pl-12"}
                        label={opt}
                        onChange={onToggle(opt)}
                        control={
                            <Checkbox
                                // @ts-ignore
                                checked={selectedFilter?.value?.includes(opt)}
                                // onChange={(e) => (e.target.checked ? onAddFilter(col.id, opt) : onRemoveFilter(col.id, opt))}
                                size={"small"}
                            />
                        }
                    />
                </div>
            ))}

            <EmptySelect key={key} col={col} />
            {/*<div className={"hover:bg-grey-lighter duration-200"}>*/}
            {/*    <FormControlLabel*/}
            {/*        className={"pl-12"}*/}
            {/*        label={"Empty"}*/}
            {/*        control={*/}
            {/*            <Checkbox*/}
            {/*                // checked={filterValue.includes("empty")}*/}
            {/*                // onChange={(e) => (e.target.checked ? onAddFilter(col.id, "empty") : onRemoveFilter(col.id, "empty"))}*/}
            {/*                size={"small"}*/}
            {/*            />*/}
            {/*        }*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    );
});

const SearchTextFilter = ({col}: FilterHeadParams) => {
    const {t} = useTranslation("datagrid");
    const {onChangeFilter, onRemoveFilter} = useDGFilters();
    const [value, setValue] = useState("");
    const [btn, setBtn] = useState<"eq" | "contains" | "startsWith" | "endsWith" | "notEmpty" | "empty">("contains");
    const onChange = () => (event) => {
        setBtn(event);
    };

    const onChangeEmpty = () => (event) => {
        if (btn === event) {
            setBtn("eq");
            onRemoveFilter(col.id);
        } else {
            setBtn(event);
            setValue("");
            // if (event === "empty" || event === "notEmpty")
            onChangeFilter({
                colId: col.id,
                condition: event,
            });
        }
    };
    return (
        <div className={"p-12 flex flex-col w-320"}>
            <TextField
                disabled={btn === "empty" || btn === "notEmpty"}
                autoFocus={true}
                value={value}
                className={"mb-12"}
                onChange={(e) => setValue(e.target.value)}
                label={<Translate id={"_.search"} />}
            />

            <SelectButtons
                formData={{[col.id]: btn}}
                type={col.id}
                items={[
                    {value: "contains", label: "Contains"},
                    {value: "eq", label: "Exact"},
                    // {value: "neq", label: "!="},
                    // {value: "<=", label: "<="},
                    {value: "startsWith", label: "Starts"},
                    {value: "endsWith", label: "Ends"},
                    // {value: ">=", label: ">="},
                ]}
                onChange={onChange}
            />

            <div className={"flex justify-between items-center mt-20"}>
                <SelectButtons
                    formData={{[col.id]: btn}}
                    type={col.id}
                    items={[
                        {value: "empty", label: "Empty"},
                        {value: "notEmpty", label: "Not Empty"},
                    ]}
                    onChange={onChangeEmpty}
                />
                <div className="flex">
                    {value.trim().length > 0 && (
                        <IconButton
                            size={"small"}
                            onClick={() => {
                                setValue("");
                                setBtn("eq");
                                onRemoveFilter(col.id);
                            }}>
                            <Icon children="close" />
                        </IconButton>
                    )}
                    <Button
                        disabled={value.trim().length === 0}
                        className={"ml-12"}
                        startIcon={<Icon children="search" />}
                        size={"small"}
                        onClick={() => {
                            if (btn !== "empty" && btn !== "notEmpty")
                                onChangeFilter({
                                    colId: col.id,
                                    value,
                                    condition: btn,
                                });
                        }}>
                        {t("filter")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

type Props = {
    col: DGColType;
};

const FilterBtn: FC<Props> = ({col}) => {
    // const {rows} = useIDB();
    const {filters} = useDGFilters();

    const [filterEl, setFilterEl] = useState<HTMLButtonElement | null>(null);

    // if (!rows || rows.length === 0) return null;
    // if (rows.filter((d) => d[col.id] !== undefined).length === 0) return null;

    // const type = viewToFilter[col.viewType];
    // the filter value is the value that has been selected
    const filterValue = filters.find((f) => f.colId === col.id);

    const renderViews = () => {
        switch (col.viewType) {
            case "currency":
                return <NumberFilterHead selectedFilter={filterValue} col={col} />;
            case "multiOption":
                return <SelectFromOptions selectedFilter={filterValue} col={col} />;
            case "singleOption":
                return <SelectFromOptions selectedFilter={filterValue} col={col} />;
            case "user":
                return <SelectObject selectedFilter={filterValue} col={col} />;
            case "number":
                return <NumberFilterHead selectedFilter={filterValue} col={col} />;
            case "text":
                return <SearchTextFilter col={col} />;
            case "date":
                return <DateFilterHead selectedFilter={filterValue} col={col} />;
            case "checkbox":
                return (
                    <div>
                        {/*<div className={"hover:bg-grey-lighter duration-200"}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        className={"pl-12"}*/}
                        {/*        checked={filterValue?.value === true}*/}
                        {/*        onChange={() =>*/}
                        {/*            onChangeFilter({*/}
                        {/*                colId: col.id,*/}
                        {/*                condition: "eq",*/}
                        {/*                value: [true],*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*        label={"True"}*/}
                        {/*        control={<Checkbox size={"small"} />}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className={"hover:bg-grey-lighter duration-200"}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        className={"pl-12"}*/}
                        {/*        checked={filterValue?.value === false}*/}
                        {/*        onChange={() =>*/}
                        {/*            onChangeFilter({*/}
                        {/*                colId: col.id,*/}
                        {/*                condition: "eq",*/}
                        {/*                value: false,*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*        label={"False"}*/}
                        {/*        control={<Checkbox size={"small"} />}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <EmptySelect col={col} />
                    </div>
                );
            case "file":
                return (
                    <EmptySelect col={col} />
                    // <div className={"hover:bg-grey-lighter duration-200"}>
                    //     <FormControlLabel
                    //         className={"pl-12"}
                    //         // @ts-ignore
                    //         checked={filterValue?.value.includes("hasFile")}
                    //         onChange={() =>
                    //             onChangeFilter({
                    //                 colId: col.id,
                    //                 condition: "eq",
                    //                 value: "notEmpty",
                    //             })
                    //         }
                    //         label={"Has file"}
                    //         control={<Checkbox size={"small"} />}
                    //     />
                    // </div>
                );
            default:
                return <EmptyView />;
        }
    };

    // const [checked, setChecked] = useState(false);
    //
    // const onChangeCheckbox = (e) => {
    //     if (!e.target.checked) {
    //         onChangeFilter({
    //             colId: col.id,
    //             condition: "empty",
    //         });
    //         // onRemoveFilter(col.id);
    //     } else {
    //         onChangeFilter({
    //             colId: col.id,
    //             condition: "notEmpty",
    //         });
    //     }
    // };

    // useEffect(() => {
    //     const filterValue = filters.find((f) => f.colId === col.id);
    //     if (filterValue) {
    //         setChecked(filterValue.condition === "notEmpty");
    //     } else {
    //         setChecked(false);
    //     }
    // }, [filters]);

    return (
        <>
            {/*{col.viewType === "checkbox" ? (*/}
            {/*    <>*/}
            {/*        <Checkbox size={"small"} onClick={onChangeCheckbox} checked={checked} />*/}
            {/*        {filterValue && (*/}
            {/*            <IconButton size={"small"} onClick={() => onRemoveFilter(col.id)}>*/}
            {/*                <Icon fontSize={"small"}>close</Icon>*/}
            {/*            </IconButton>*/}
            {/*        )}*/}
            {/*    </>*/}
            {/*) : (*/}
            {/*    <>*/}
            <IconButton
                size={"small"}
                onClick={(event) => {
                    setFilterEl(event.currentTarget);
                }}>
                <Icon fontSize={"small"}>filter_alt</Icon>
            </IconButton>
            <Popover onClose={() => setFilterEl(null)} open={Boolean(filterEl)} anchorEl={filterEl}>
                <Card>{renderViews()}</Card>
            </Popover>
            {/*    </>*/}
            {/*)}*/}
        </>
    );
};

export default FilterBtn;
