import useQueryStore from "./useQueryStore";
import {useShallow} from "zustand/react/shallow";

function useStoreUtils() {


    const {hasHydrated,query,actions} = useQueryStore(useShallow((state)=>({
        hasHydrated: state._hasHydrated,
        query: state.query,
        actions: state.actions
    })));

    return {
        hasHydrated,
        query,
        actions,
    };
}

export default useStoreUtils;
