//createInstanceSingleSlice

import produce from "immer";

export const createInstanceSingleSlice = (set) => ({
    currentInstance: null,
    /**
     * { "processInstanceId":"xx1","cancelActivities:["A1","A2"]",
     *         "startActivities":[{"id":"b1","variables":{}},
     *                            {"id":"b2","variables":{},"variablesLocal":{}}]}
     */
    /**
     * @param {boolean} isSettingCancelActivity - false : 没有处于设置取消活动的状态； true : 正在设置取消活动的状态
     */
    isSettingCancelActivity:false,
    /**
     * @param {boolean} isSettingStartActivity - false : 没有处于设置开始活动的状态； true : 正在设置开始活动的状态
     */
    isSettingStartActivity:false,
    /**
     * @param {array}  cancelActivities - 将要被取消的活动节点的数据源
     */
    cancelActivities: [],
    /**
     * @param {array}  startActivities - 将要开始的活动节点的数据源
     */
    startActivities:[],
    /**
     * @param {Object}  currentActivity - 将要删除的重设的 "开始" 、"取消" 节点
     */
    currentActivity:null,
    /**
     * @param {Object}  currentVariable - 正在编辑的变量，在重设的开始节点中
     */
    currentVariable:null,
    /**
     * @param {Object}  commitDisable - 所有变量都编辑完毕才能够提交变量
     */
    commitDisable:false,
    /**
     * @param {String} currentUserValue - 当变量 type 为 string 时，设置的 userId 回调(流程实例详情中，重设开始和结束的活动时，设置新开始的活动点，设置变量)
     */
    currentUserValue:null,
    onChangeCurrentUserValue: (t) => {
        set(
            produce((state) => {
                state.currentUserValue = t;
            })
        );
    },
    onChangeCommitDisable: (t) => {
        set(
            produce((state) => {
                state.commitDisable = t;
            })
        );
    },
    onChangeCurrentInstance: (t) => {
        set(
            produce((state) => {
                state.currentInstance = t;
            })
        );
    },
    onChangeIsSettingCancelActivity: (t) => {
        set(
            produce((state) => {
                state.isSettingCancelActivity = t;
            })
        );
    },
    onChangeIsSettingStartActivity: (t) => {
        set(
            produce((state) => {
                state.isSettingStartActivity = t;
            })
        );
    },
    onChangeCancelActivities: (t) => {
        set(
            produce((state) => {
                state.cancelActivities = t;
            })
        );
    },
    onChangeStartActivities: (t) => {
        set(
            produce((state) => {
                state.startActivities = t;
            })
        );
    },
    onChangeCurrentActivity: (t) => {
        set(
            produce((state) => {
                state.currentActivity = t;
            })
        );
    },
    onChangeCurrentVariable: (t) => {
        set(
            produce((state) => {
                state.currentVariable = t;
            })
        );
    },
});
