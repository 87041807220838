import {firmPrefix} from "myaxios";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import type {wpType} from "../types";

export function useWorkPlan(IdFromDialog?: string) {
    const params: {id: string} = useParams();
    const reId = useSelector(({fuse}) => fuse.dialogs?.selectedWPId);
    const wpid = reId || params.id || IdFromDialog;
    // console.log(reId, params.id, IdFromDialog);
    const {data, error, mutate, isLoading} = useSWR<wpType>(wpid ? `${firmPrefix}/workplan/${wpid}` : null);
    const userId = useSelector(({user}) => user.profile?.id);
    const canEdit = data && data.owner ? data.owner?.user.id === userId : false;

    return {
        wp: data,
        isLoading: isLoading,
        isError: error,
        refresh: mutate,
        canEdit,
    };
}
