import axios, {commonPrefix} from "@api";

export const OPEN_DIALOG = "[FUSE] TURN_ON";
export const MINIMIZE_DIALOG = "[FUSE] MINIMIZE_DIALOG";
export const CLOSE_DIALOG = "[FUSE] TURN_OFF";
export const TOGGLE_DIALOG = "[FUSE] TOGGLE_DIALOG";
export const SET_IMAGE_FULL = "[FUSE] SET_IMAGE_FULL";
export const SET_CACHE = "[FUSE] SET_CACHE";
export const SHOW_MY_DIALOG = "[FUSE] SHOW_MY_DIALOG";

export const SHOULD_REFRESH = "[FUSE] SHOULD_REFRESH";

export const turnOn = (dialog) => {
    return {
        type: OPEN_DIALOG,
        dialog,
    };
};

export const turnOff = (dialog) => {
    return {
        type: CLOSE_DIALOG,
        dialog,
    };
};

export const minimize = (dialog) => {
    const data = {
        type: "minimize",
        name: dialog,
    };
    axios.post(`${commonPrefix}/clickCount`, data);
    return {
        type: MINIMIZE_DIALOG,
        dialog,
    };
};

export const toggle = (dialog) => {
    return {
        type: TOGGLE_DIALOG,
        dialog,
    };
};

export const setFullImage = (title, src) => {
    return {
        type: SET_IMAGE_FULL,
        title,
        src,
    };
};

export const setCache = (key, value) => ({
    type: SET_CACHE,
    key,
    value,
});

export const showMyDialog = (title, request, successFunc, successMessage, sureButtonText) => {
    return {
        type: SHOW_MY_DIALOG,
        title,
        request,
        successFunc,
        successMessage,
        sureButtonText,
    };
};
