import {Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {Component} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../app/auth/store/actions';
import * as fuseActions from '../../../app/store/actions';
import axios, {userPrefix} from '../../../myaxios';

class Contact extends Component {
    state = {
        phone: "",
        email: "",
        preset: "",

        info: [
            {key: "WeChat", value: ""},
            {key: "QQ", value: ""},
            {key: "MSN", value: ""},
            {key: "Blog", value: ""}
        ]
    };

    constructor(props) {
        super(props);
    }

    handleChange = (name) => (event) => {
        const info = [...this.state.info];
        const index = info.findIndex((item) => item.key === name);
        if (index > -1) info[index].value = event.target.value;
        else info.push({key: name, value: event.target.value});
        this.setState({info});
        // this.setState({info[name]: event.target.value});
    };

    change = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    };

    getItemValue = (key) => {
        const info = [...this.state.info];
        const index = info.findIndex((item) => item.key === key);
        // console.log(key);
        if (index > -1) return info[index].value;
        else return "";
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.contactData && this.props.contactData !== prevProps.contactData) {
            this.getData();
        }
    };

    componentDidMount = () => {
        if (this.props.contactData) {
            this.getData();
        }
    };

    getData = () => {
        // console.log(this.props.contactData);
        this.setState({
            ...this.props.contactData,
            info: [...this.props.contactData.info]
            // "WeChat" : this.props.contactData.info[0].value || "",
            // "QQ" : this.props.contactData.info[1].value || "",
            // "MSN" : this.props.contactData.info[2].value || "",
            // "Blog" : this.props.contactData.info[3].value || "",
        });
    };

    handleSubmit = () => {
        this.props.startLoading();

        const newFormData = {
            ...this.state
            // info: []
        };

        // Object.keys(this.state).map(item => {
        //     if(this.state[item] && item !== "info" && item !== "email" && item !== "preset" && item !== "phone" && item !== "id" && item !== "loading") {
        //         newFormData.info.push({
        //             "key": item,
        //             "value": this.state[item]
        //         });
        //         delete newFormData[item];
        //     }
        // });
        axios.put(`${userPrefix}/contact`, newFormData).then(() => {
            this.props.showMessage({
                variant: "success",
                message: "message.Information saved!"
            });
            this.props.getBasicInfo();

            this.props.finishLoading();
        });
    };

    render() {
        const {phone, email, preset} = this.state;
        return (
            <>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            type={"number"}
                            onChange={this.change("phone")}
                            // passInputValue={(str) => this.phoneChange(str)}
                            value={phone}
                            required
                            margin="normal"
                            fullWidth
                            label={<Translate id={"profile.Phone"} />}
                            id="formatted-number-format-input-88776-yyyy"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            id="outlined-email-input"
                            fullWidth
                            label={<Translate id={"profile.Email"} />}
                            margin="normal"
                            type="email"
                            autoComplete="email"
                            value={email}
                            onChange={this.change("email")}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="standard-full-width"
                                    rows={5}
                                    multiline
                                    label={<Translate id={"profile.Full address"} />}
                                    placeholder={translate("profile.Full address")}
                                    fullWidth
                                    value={preset || ""}
                                    margin="normal"
                                    onChange={this.change("preset")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        </Translate>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="WeChat-full-width"
                                    fullWidth
                                    label={<Translate id={"profile.WeChat"} />}
                                    placeholder={translate("profile.WeChat")}
                                    value={this.getItemValue("WeChat")}
                                    onChange={this.handleChange("WeChat")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="QQ-full-width"
                                    fullWidth
                                    label={<Translate id={"profile.QQ"} />}
                                    placeholder={translate("profile.QQ")}
                                    value={this.getItemValue("QQ")}
                                    onChange={this.handleChange("QQ")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                />
                            )}
                        </Translate>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="MSN-full-width"
                                    fullWidth
                                    label={<Translate id={"profile.Telegram"} />}
                                    placeholder={translate("profile.Telegram")}
                                    value={this.getItemValue("Telegram")}
                                    onChange={this.handleChange("Telegram")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="Blog-full-width"
                                    fullWidth
                                    label={<Translate id={"profile.WhatsApp"} />}
                                    placeholder={translate("profile.WhatsApp")}
                                    value={this.getItemValue("WhatsApp")}
                                    onChange={this.handleChange("WhatsApp")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="MSN-full-width"
                                    fullWidth
                                    label={<Translate id={"profile.MSN"} />}
                                    placeholder={translate("profile.MSN")}
                                    value={this.getItemValue("MSN")}
                                    onChange={this.handleChange("MSN")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="Blog-full-width"
                                    fullWidth
                                    label={<Translate id={"profile.Blog"} />}
                                    placeholder={translate("profile.Blog")}
                                    value={this.getItemValue("Blog")}
                                    onChange={this.handleChange("Blog")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button variant="contained" color="secondary" type={"submit"} onClick={this.handleSubmit} disabled={!phone}>
                            <Translate id="profile.Submit" />
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}

function mapStateToProps({user}) {
    return {
        contactData: user.contact
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getBasicInfo: Actions.setUserData,
            showMessage: fuseActions.showMessage,
            startLoading: fuseActions.startLoading,
            finishLoading: fuseActions.finishLoading
        },
        dispatch
    );
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Contact));
