import {useState} from "react";
import {useProcessStore} from "../../state/useProcessStore";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import {Button, Dialog, DialogContent, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Translate} from "react-localize-redux";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import {useMemoizedFn} from "ahooks";
import axios, {flowPrefix} from "../../../../../myaxios";
import * as fuseActions from "../../../../store/actions";
import {useDispatch} from "react-redux";

function NewSyncTipDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.syncInstanceTipsDialog,
    });

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const currentInstance = useProcessStore((state) => state.currentInstance);

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSure = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        axios
            .put(`${flowPrefix}/sync/process/${currentInstance?.id}`)
            .then(() => {
                finshParams?.onFinish();
                dispatch(
                    fuseActions.showMessage({
                        message: "message.Process synchronization successful!",
                        variant: "success",
                    })
                );
                onTurnOffDialog();
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <DialogTitle id="SyncTipDialog-dialog-title">
                    <Translate id="_.Tips" />
                </DialogTitle>
                <DialogContent>
                    <div className="flex flex-col justify-center">
                        <Typography variant="button" className={"text-center font-black"} display="block">
                            <Translate id={"_.Synchronizing the process:"} />
                            <Chip className={"font-bold bg-green text-white ml-2"} label={currentInstance?.name} />
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button disabled={loading} onClick={handleSure} color="secondary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewSyncTipDialog;
