import Typography, {TypographyProps} from '@material-ui/core/Typography';
import clsx from 'clsx';
import {Translate} from 'react-localize-redux';
import {money} from '../../utils/utilities';
import {yuanSymbol} from '../../variables';

type Props = {
    size?: number;
    amount?: number;
    noPriceLabel?: boolean;
} & TypographyProps;

export const Money = ({className, size, noPriceLabel, amount}: Props) => {
    return (amount || amount === 0) ? (
        <span className={clsx(className, "font-bold", size ? "text-" + size : "text-18")}>
            {yuanSymbol}
            {money(amount)}
        </span>
    ) : (
        <Typography className={className} variant={"caption"}>
            {noPriceLabel || <Translate id={"_.No price"} />}
        </Typography>
    );
};


