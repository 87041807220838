//createRefreshSlice

import produce from "immer";

export const createRefreshSlice = (set) => ({
    refreshing: false,
    onChangeRefreshing: (t) => {
        set(
            produce((state) => {
                state.refreshing = t;
            })
        );
    },
});
