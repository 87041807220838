import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    userTasksResponse: null,
    variables: [],
    selectedUserTask: null,
    selectedAVariable: null,
    selectedVariables: null,
};


const userTask_list = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_USERTASKS_LIST: {
            let tobj = cloneDeep(state);
            tobj.userTasksResponse = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     userTasksResponse: action.payload
            // }
        }
        case Actions.GET_VARIABLES_LIST_FOR_A_USERTASK: {
            let tobj = cloneDeep(state);
            tobj.variables = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     variables: action.payload
            // }
        }
        case Actions.SELECT_A_USERTASK: {
            let tobj = cloneDeep(state);
            tobj.selectedUserTask = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedUserTask: action.payload
            // }
        }
        case Actions.SELECT_A_VARIABLE: {
            let tobj = cloneDeep(state);
            tobj.selectedAVariable = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedAVariable: action.payload
            // }
        }
        case Actions.SELECT_VARIABLES_LIST_FOR_A_USERTASK: {
            let tobj = cloneDeep(state);
            tobj.selectedVariables = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedVariables: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default userTask_list;
