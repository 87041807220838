import {Button, Dialog, DialogContent} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {LocalTimeStamp, MyAvatar, NameLabel} from "@ui";
import * as fuseActions from "app/store/actions";
import _ from "lodash";
import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {StatusColor} from "../../utils/utils";

/*
 * //UserActivityProcessDialog
 * */

function UserActivityProcessDialog(props) {
    const dispatch = useDispatch();

    const [data, setData] = useState(null);

    const options = useSelector(({fuse}) => fuse.process_util);

    useEffect(() => {
        if (options && options.datas.length > 0) {
            const arr = _.groupBy(options.datas, "index");
            setData(arr);
            console.log("UserActivityProcessDialog useEffect \n", arr);
        }
    }, [options]);

    function handleDelete() {
        dispatch(fuseActions.closeStepsProcessView());
    }

    return (
        <Dialog
            open={options.dialog}
            onClose={() => {
                handleDelete();
            }}
            fullWidth
        >
            <div>
                <DialogTitle>{<Translate id="_.View Progress" />}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{<Translate id={"_.user"} />}</TableCell>
                                    <TableCell>{<Translate id={"_.name"} />}</TableCell>
                                    <TableCell align="right">{<Translate id={"_.status"} />}</TableCell>
                                    <TableCell align="right">{<Translate id={"_.handle date"} />}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data ? (
                                    _.keys(data).map((tKey) => {
                                        return data[tKey].map((tobj, pIndex) =>
                                            tobj.tasks.length > 0
                                                ? tobj.tasks.map((row, cIndex) => {
                                                    console.log("name:pIndex:cIndex\n", row.name, pIndex, cIndex);
                                                    return pIndex === 0 && cIndex === 0 ? (
                                                        <TableRow key={row.taskDefKey}>
                                                            <TableCell component="th" scope="row">
                                                                {tKey}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <div className={"flex flex-row"}>
                                                                    <MyAvatar user={row.user} />
                                                                    <div className={"flex flex-col ml-2"}>
                                                                        <NameLabel user={row.user} />
                                                                        <small className={"inline-flex"}>
                                                                            {row.user.username}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.completeTime ? (
                                                                    <Chip
                                                                        className={`font-bold ${StatusColor(row)}`}
                                                                        size="small"
                                                                        label={<Translate id={`_.${row.status}`} />}
                                                                    />
                                                                ) : (
                                                                    <div />
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.completeTime ? (
                                                                    <LocalTimeStamp
                                                                        datetime={row.completeTime}
                                                                        format={"YYYY-MM-DD"}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        <TableRow key={row.taskDefKey}>
                                                            <TableCell />
                                                            <TableCell component="th" scope="row">
                                                                <div className={"flex flex-row"}>
                                                                    <MyAvatar user={row.user} />
                                                                    <div className={"flex flex-col ml-2"}>
                                                                        <NameLabel user={row.user} />
                                                                        <small className={"inline-flex"}>
                                                                            {row.user.username}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.endTime ? (
                                                                    <Translate id={"_.RESOLVED"} />
                                                                ) : (
                                                                    <div />
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.endTime ? (
                                                                    <LocalTimeStamp
                                                                        datetime={row.endTime}
                                                                        format={"YYYY-MM-DD HH:MM"}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                : ""
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align={"center"} component="td" scope="row">
                                            <Typography
                                                variant={"subtitle1"}
                                                className={"inline-flex justify-center items-center text-center w-full"}
                                            >
                                                {<Translate id="_.Nothing Found!" />}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => handleDelete()}>
                        <Translate id="_.cancel" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(UserActivityProcessDialog);
