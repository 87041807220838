import * as Actions from "../actions";
import { cloneDeep } from "lodash";

const initialState = {
    /*
    * {
"page": 0,
  "size": 0,
  "totalPages": 0,
  "totalElements": 0,
  "content": [
    {
      "id": "string",
      "description": "string",
      "name": "string",
      "bank": "string",
      "accNo": "string",
      "active": true,
      "activeTime": 0,
      "timestamp": 0
    }
  ]
    * */

    accountFilterRes: null,
    selected: null,
    accountFilterDetails: null,
};

const accountFilterReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_ACCOUNT_FILTERS:{

            let tobj = cloneDeep(state);
            tobj.accountFilterRes = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     accountFilterRes: action.payload
            // };
        }
        case Actions.SELECT_ACCOUNT_FILTERS:{
            let tobj = cloneDeep(state);
            tobj.selected = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selected: action.payload
            // };
        }
        case Actions.GET_ACCOUNT_FILTERS_DETAILS:{
            let tobj = cloneDeep(state);
            tobj.accountFilterDetails = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     accountFilterDetails: action.payload
            // };
        }
        default:{
            return state;
        }
    }
};

export default accountFilterReducer;
