import {useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "react-select/async";
import {searchContact} from "utils/utilities";
import {components} from "utils/Select2Components";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import * as fuseActions from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useLocation, withRouter} from "react-router-dom";
import * as Actions from "../../store/actions";
import {Chip} from "@material-ui/core";

function EndormentDialog(props) {
    const dispatch = useDispatch();

    const location = useLocation();

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const search = useSelector(({fuse}) => fuse.search);

    const selectedUserTask = useSelector(({process}) => process.userTask_list.selectedUserTask);

    const instanceObject = useSelector(({process}) => process.instance.instanceObject);

    const [user, setUser] = useState({value: "", label: ""});

    const [userId, setUserId] = useState("");

    const [loading, setLoading] = useState(false);

    function changeUser(value) {
        if (value) {
            setUser({
                ...user,
                value: value.value,
                label: value.label,
            });
            setUserId(value.value);
        } else {
            setUserId("");
            setUser("");
        }
    }

    function canSunmit() {
        return user.value === "" || false || !user.value || loading;
    }

    function handleClose() {
        dispatch(fuseActions.turnOff("EndormentDialog"));
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const params = {
            taskId: selectedUserTask.id,
            targetUserId: userId,
        };
        setLoading(true);
        axios
            .put(`${flowPrefix}/taskChangeUserAdmin`, params)
            .then((res) => {
                setLoading(false);
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.User assigneed!",
                    })
                );
                if (location.pathname === "/management/process/userTasks") {
                    dispatch(Actions.getUserTasks(search));
                } else {
                    const params = {
                        processInstanceId: instanceObject.id,
                    };
                    dispatch(Actions.getUserTaskSingle(selectedUserTask.id));
                    dispatch(Actions.getUserTasks(params));
                }
                handleClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"xs"}
            open={!!dialogs["EndormentDialog"]}
            onClose={handleClose}
            classes={{paper: "overflow-visible", root: "overflow-visible"}}
            className={"overflow-visible"}
            aria-labelledby="responsive-dialog-title"
        >
            {
                <Translate>
                    {({translate}) => (
                        <DialogTitle id="responsive-dialog-title">
                            {
                                <>
                                    {translate("process.endorsement for usertask")}
                                    <Chip label={selectedUserTask.name} color={"secondary"} size="small" />
                                </>
                            }
                        </DialogTitle>
                    )}
                </Translate>
            }
            <DialogContent className={"overflow-visible"}>
                <div className={loading ? "loading" : null}>
                    <Translate>
                        {({translate}) => (
                            <Select
                                required
                                textFieldProps={{
                                    margin: "normal",
                                    required: true,
                                    label: translate("process.Change agent"),
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                                margin={"normal"}
                                loadOptions={searchContact}
                                defaultOptions
                                // styles={selectStyles}
                                components={components}
                                value={user}
                                onChange={changeUser}
                                placeholder={translate("process.Search a user")}
                                isClearable
                            />
                        )}
                    </Translate>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{<Translate id={"_.cancel"} />}</Button>
                <Button disabled={canSunmit()} onClick={handleSubmit} color="secondary">
                    {<Translate id={"_.sure"} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withLocalize(withRouter(EndormentDialog));
