import {turnOff} from "@fuseActions";
import {Icon} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {MyChip, NameLabel} from "@ui";
import type {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import AvatarUploadable from "./AvatarUploadable";

const BasicInfo: FC = () => {
    const dispatch = useDispatch();

    const {profile, roles} = useSelector(({user}) => user);

    const rolesMap = roles?.map((role) => {
        return <MyChip key={role.id} label={role.name} size={"small"} color={"blue"} className={"mr-3 mb-3"} />;
    });

    return (
        <div className="flex flex-col items-center relative py-32 bg-blue-darkest">
            <IconButton
                onClick={() => {
                    dispatch(turnOff("userProfile"));
                }}
                className="absolute top-0 right-0">
                <Icon className={"text-white"} children="close" />
            </IconButton>
            <AvatarUploadable />
            <NameLabel user={profile} variant={"h4"} className={"mt-16 mb-8 text-white"} />
            <Typography className={"text-gray-300"}>@{profile.username}</Typography>
            <div className={"mt-24 px-16"}>{rolesMap}</div>
        </div>
    );
};

export default BasicInfo;
