import {lazy} from "react";

export const DeployConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/management/process/instance/:id",
            component: lazy(() => import("./management/instances/InstanceSingle/DetailPage")),
        },
        {
            path: "/management/process/decision/:id",
            component: lazy(() => import("./management/decisions/DecisionSingle/DetailPage")),
        },
        {
            path: "/management/process/definition/:id",
            component: lazy(() => import("./management/definitions/DefinitionSinglePage/DetailPage")),
        },
        {
            path: "/management/process/userTask/:id",
            component: lazy(() => import("./management/user-tasks/UserTaskSingle/DetailPage")),
        },
        {
            path: "/management/process/userTasks",
            component: lazy(() => import("./management/user-tasks/NewPage")),
        },
        {
            path: "/management/process/categories",

            component: lazy(() => import("./management/categories/NewPage")),
        },
        {
            path: "/management/process/instances",
            component: lazy(() => import("./management/instances/NewPage")),
        },
        {
            path: "/management/process/decisions",
            component: lazy(() => import("./management/decisions/NewPage")),
        },
        {
            path: "/management/process/definitions",
            component: lazy(() => import("./management/definitions/NewPage")),
        },
        {
            path: "/management/process/deploy",
            component: lazy(() => import("./management/deployment/DeploymentPage")),
        },
    ],
};
