import {turnOn} from "@fuseActions";
import {Button, Typography} from "@material-ui/core";
import {MyAvatar} from "@ui";
import ProfileDrawer from "components/layout/profile/ProfileDrawer";
import {memo} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import FuseSuspense from "../../@fuse/components/FuseSuspense/FuseSuspense";

function UserMenu() {
    const dispatch = useDispatch();
    const user = useSelector(({user}) => user);

    // const [menus, setMenus] = useState({userMenu: null, firmMenu: null});

    // const openMenu = (name) => (event) => {
    //     setMenus({...menus, [name]: event.currentTarget});
    // };

    // const closeMenu = (name) => {
    //     setMenus({...menus, [name]: null});
    // };

    // const openAppDialog = () => {
    //     dispatch(turnOn("downloadQRCodes"));
    //     closeMenu("userMenu");
    // };

    return (
        user.profile && (
            <>
                <FuseSuspense>
                    <ProfileDrawer />
                </FuseSuspense>
                <Button
                    data-cy={"user-menu"}
                    data-tut={"open-user-menu"}
                    className="no-drag rounded-0 h-44"
                    // onClick={openMenu("userMenu")}
                    onClick={() => dispatch(turnOn("userProfile"))}>
                    <MyAvatar user={user.profile} />

                    <div className="hidden md:flex flex-col ml-12 items-start">
                        <Typography component="span" className="normal-case font-600 flex">
                            {user.profile.lastName}
                        </Typography>
                        <Typography className="text-11 capitalize" color="textSecondary">
                            {user.masterFirm ? user.masterFirm.name : <Translate id="navi.No master firm" />}
                        </Typography>
                    </div>

                    {/*<Icon className="ml-12 hidden md:flex">keyboard_arrow_down</Icon>*/}
                </Button>

                {/*<Popover*/}
                {/*    open={Boolean(menus.userMenu)}*/}
                {/*    anchorEl={menus.userMenu}*/}
                {/*    onClose={() => closeMenu("userMenu")}*/}
                {/*    anchorOrigin={{*/}
                {/*        vertical: "bottom",*/}
                {/*        horizontal: "right",*/}
                {/*    }}*/}
                {/*    anchorPosition={{left: 20, top: -10}}*/}
                {/*    transformOrigin={{*/}
                {/*        vertical: "top",*/}
                {/*        horizontal: "right",*/}
                {/*    }}*/}
                {/*    elevation={2}*/}
                {/*    classes={{*/}
                {/*        paper: "py-8",*/}
                {/*    }}>*/}
                {/*    <>*/}
                {/*        <MenuItem component={Link} to="/profile" onClick={() => closeMenu("userMenu")}>*/}
                {/*            <ListItemIcon className={"min-w-40"}>*/}
                {/*                <Icon children="account_circle" />*/}
                {/*            </ListItemIcon>*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id="navi.My profile" />} />*/}
                {/*        </MenuItem>*/}
                {/*        <MenuItem component={Link} to="/profile/settings/general" onClick={() => closeMenu("userMenu")}>*/}
                {/*            <ListItemIcon className={"min-w-40"}>*/}
                {/*                <Icon children="settings" />*/}
                {/*            </ListItemIcon>*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id="navi.Edit profile" />} />*/}
                {/*        </MenuItem>*/}

                {/*        /!*{user.myFirms.length > 0 && (*!/*/}
                {/*        /!*    <MenuItem onClick={openMenu("firmMenu")}>*!/*/}
                {/*        /!*        <ListItemIcon className={"min-w-40"}>*!/*/}
                {/*        /!*            <Icon children="business" />*!/*/}
                {/*        /!*        </ListItemIcon>*!/*/}
                {/*        /!*        <ListItemText className="pl-0" primary={<Translate id="navi.Change company" />} />*!/*/}
                {/*        /!*    </MenuItem>*!/*/}
                {/*        /!*)}*!/*/}
                {/*        /!*{user.masterFirm.id && user.masterFirm.userId !== user.profile?.id && (*!/*/}
                {/*        /!*    <MenuItem*!/*/}
                {/*        /!*        component={Link}*!/*/}
                {/*        /!*        to={"/hr/employees"}*!/*/}
                {/*        /!*        onClick={() => {*!/*/}
                {/*        /!*            closeMenu("firmMenu");*!/*/}
                {/*        /!*            closeMenu("userMenu");*!/*/}
                {/*        /!*        }}*!/*/}
                {/*        /!*    >*!/*/}
                {/*        /!*        <ListItemIcon className={"min-w-40"}>*!/*/}
                {/*        /!*            <Icon children={"work"} />*!/*/}
                {/*        /!*        </ListItemIcon>*!/*/}
                {/*        /!*        <Translate>{({translate}) => <ListItemText className="pl-0" primary={translate("navi.Company info")} />}</Translate>*!/*/}
                {/*        /!*    </MenuItem>*!/*/}
                {/*        /!*)}*!/*/}

                {/*        {user.masterFirm?.user?.id === user.profile?.id && (*/}
                {/*            <MenuItem*/}
                {/*                data-test={"firm-settings"}*/}
                {/*                component={Link}*/}
                {/*                to={"/hr/employees"}*/}
                {/*                onClick={() => {*/}
                {/*                    closeMenu("firmMenu");*/}
                {/*                    closeMenu("userMenu");*/}
                {/*                }}>*/}
                {/*                <ListItemIcon className={"min-w-40"}>*/}
                {/*                    <Icon children={"work"} />*/}
                {/*                </ListItemIcon>*/}
                {/*                <Translate>*/}
                {/*                    {({translate}) => <ListItemText className="pl-0" primary={translate("navi.Company Settings")} />}*/}
                {/*                </Translate>*/}
                {/*            </MenuItem>*/}
                {/*        )}*/}

                {/*        <MenuItem onClick={openAppDialog}>*/}
                {/*            <ListItemIcon className={"min-w-40"}>*/}
                {/*                <Icon children="get_app" />*/}
                {/*            </ListItemIcon>*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id="navi.Download APP" />} />*/}
                {/*        </MenuItem>*/}
                {/*        <LanguageToggle />*/}
                {/*        <MenuItem*/}
                {/*            onClick={() => {*/}
                {/*                // @ts-ignore*/}
                {/*                dispatch(logoutUser());*/}
                {/*                closeMenu("userMenu");*/}
                {/*            }}>*/}
                {/*            <ListItemIcon className={"min-w-40"}>*/}
                {/*                <Icon children="exit_to_app" />*/}
                {/*            </ListItemIcon>*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id="navi.Logout" />} />*/}
                {/*        </MenuItem>*/}
                {/*    </>*/}
                {/*</Popover>*/}
            </>
        )
    );
}

export default withLocalize(memo(UserMenu));
