import {useMemo} from "react";

function useLocaleStore() {
    const lanTag = localStorage.getItem("i18nextLng");
    const _language = useMemo(() => {
        return lanTag;
    }, [lanTag]);
    return {
        language: _language,
    };
}

export default useLocaleStore;
