import {finishLoading, startLoading} from "@fuseActions";
import axios, {financialPrefix} from "../../../../../myaxios";

export const GET_ACCOUNT_FILTERS = "[GET_ACCOUNT_FILTERS]";
export const SELECT_ACCOUNT_FILTERS = "[SELECT_ACCOUNT_FILTERS]";
export const GET_ACCOUNT_FILTERS_DETAILS = "[GET_ACCOUNT_FILTERS_DETAILS]";

export function getAccountFilters(params, callback) {
    // console.log("getAccountFilters params",params);
    const request = axios.get(`${financialPrefix}/accountFilters`, {params});
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_ACCOUNT_FILTERS,
                payload: res.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function selectAccountFilter(accountFilter) {
    return {
        type: SELECT_ACCOUNT_FILTERS,
        payload: accountFilter
    };
}

export function getAccountFilterDetails(id) {
    const request = axios.get(`${financialPrefix}/accountFilter/${id}`);
    return (dispatch) => {
        dispatch(startLoading());
        return new Promise((resolve, reject) => {
            request
            .then((res) => {
                dispatch({
                    type: GET_ACCOUNT_FILTERS_DETAILS,
                    payload: res.data
                });
                dispatch(finishLoading());
                resolve();
            })
            .catch((err) => {
                dispatch(finishLoading());
                reject(err);
            });
        });
    };
}
