import {Icon, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import clsx from "clsx";
import {firmPrefix} from "myaxios";
import {useEffect, useRef, useState} from "react";
import {defaultStyles, FileIcon} from "react-file-icon/dist/react-file-icon.esm";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import CreatableSelect from "react-select/async-creatable";
import type {userType} from "types";
import {uploadFiles} from "utils/fileUploader";
import {components} from "utils/Select2Components";
import {extExtractor, isImage, ProjectView, wpSearchTags} from "utils/utilities";
import AsyncSelect from "../../../../utils/Forms/AsyncSelect";
import UserPreviewSearch from "../../../../components/forms/UserPreviewSearch";
import UsersSelector from "../../../../utils/UserSelector/UsersSelector";
import type {WPPost, WPProjectType, wpType} from "../types";

type Props = {
    formData: WPPost;
    onChange(name: string): (value: any) => void;
};

const Details = ({formData, onChange}: Props) => {
    const selectedWP: wpType = useSelector(({fuse}) => fuse.dialogs.selectedWP);
    // const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<userType[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [project, setProject] = useState<WPProjectType>();

    useEffect(() => {
        if (selectedWP) {
            setProject(selectedWP.project);
            setUsers(selectedWP.participants?.map((p) => p.user));
        }
    }, []);

    const commerceProject = useSelector(({commerce}) => commerce?.project?.data);

    const addUsers = (a) => {
        setUsers(a);
        onChange("participants")(a.map((_) => _.id));
    };

    const deleteUser = (contact) => {
        let t = users;
        const i = users.findIndex((_member) => _member.id === contact.id);
        if (i > -1) {
            t = users.filter((_member) => _member.id !== contact.id);
        }
        setUsers(t);

        onChange("participants")(t.map((_) => _.id));
    };

    // useEffect(() => {
    //     onChange("participants")(users.map((u) => u.id));
    // }, [users]);

    useEffect(() => {
        onChange("tag")(labels.toString());
    }, [labels]);

    const changeFile = (e) => {
        const l = e.target.files.length;
        if (l > 0) setLoading(true);
        // for (let i = 0; i < l; i++) {
        //     if (e.target.files[i].size > 10240000) {
        //         setLoading(false);
        //         dispatch(
        //             fuseActions.showMessage({
        //                 message: "message.File is too big! First create the work plan, then upload in work plan page",
        //             })
        //         );
        //     } else {
        uploadFiles(e.target.files, (status, uploadedFiles) => {
            if (status) {
                if (formData.attachments) onChange("attachments")([...formData.attachments, ...uploadedFiles]);
                else onChange("attachments")(uploadedFiles);
                setLoading(false);
            }
        });
        // }
        // }
    };

    const changeLabel = (value) => {
        const selectedLabels: string[] = [];
        if (value) {
            value.map((_i) => selectedLabels.push(_i.value));
            setLabels(selectedLabels);
        }
    };

    const changeProject = (e, value) => {
        if (value) {
            setProject(value);
            onChange("project")(value);
        } else {
            onChange("project")(undefined);
        }
    };

    const changeLeader = (value) => {
        if (value) {
            onChange("leader")(value.user.id);
        } else onChange("leader")("");
    };

    const defaultTags = selectedWP && selectedWP.tag.trim() !== "" && selectedWP.tag.split(",").map((l) => ({value: l, label: l}));

    return (
        <DialogContent className={clsx(loading && "loading", " rounded-8 bg-gray-50 mt-20 overflow-visible")}>
            {/* region participants & attachemnts */}
            {!selectedWP && (
                <>
                    <UsersSelector
                        title={<Translate id={"wps.Participants"} />}
                        addUsers={addUsers}
                        deleteUser={deleteUser}
                        users={users}
                        name={"participants"}
                    />

                    <input ref={inputRef} multiple type="file" className={"hidden"} onChange={changeFile} />
                    <Typography className="uppercase text-grey mb-8 mt-20">{<Translate id={"wps.Attachments"} />}</Typography>
                    {formData.attachments?.map((file, i) => {
                        return (
                            <div
                                data-cy={"wp-file-item"}
                                key={i}
                                className="h-56 overflow-hidden w-56 inline-block relative border bg-grey-lighter mr-8 mb-8 rounded-8 leading-none align-middle hover:shadow-1">
                                <button
                                    onClick={() => {
                                        onChange("attachments")(formData.attachments?.filter((a) => a.id !== file.id));
                                    }}
                                    className="absolute top-0 mt-1 mr-1 right-0 text-grey-light hover:text-black">
                                    <Icon fontSize={"small"}>close</Icon>
                                </button>
                                <a
                                    download
                                    target={"_blank"}
                                    href={file.visitUrl}
                                    className="w-full h-full hover:no-underline flex items-center justify-center">
                                    {isImage(file.visitUrl) ? (
                                        <img className="w-full" src={file.visitUrl} alt={file.originalName} />
                                    ) : (
                                        <span className="max-w-32 block max-h-52  ">
                                            <FileIcon extension={extExtractor(file.visitUrl)} {...defaultStyles[extExtractor(file.visitUrl)]} />
                                        </span>
                                    )}
                                </a>
                            </div>
                        );
                    })}
                    <div className="h-56 overflow-hidden w-56 inline-block relative border bg-grey-lighter mr-8 mb-8 rounded-8 leading-none align-middle hover:shadow-1">
                        <button onClick={() => inputRef.current?.click()} className="w-full h-full flex items-center justify-center">
                            <Icon className={"text-grey-dark"}>cloud_upload</Icon>
                        </button>
                    </div>
                </>
            )}
            {/* endregion */}

            <div className="flex md:flex-row flex-col md:items-center items-stretch mt-20">
                {/* region tags */}
                <FormControl className={"flex-1"}>
                    <FormLabel>
                        <Translate id={"wps.tags"} />
                    </FormLabel>
                    <Translate>
                        {({translate}) => (
                            <CreatableSelect
                                // textFieldProps={{
                                //     // required: true,
                                //     margin: "normal",
                                //     label: translate('wps.tags'),
                                //     InputLabelProps: {
                                //         shrink: true,
                                //     },
                                // }}
                                isMulti
                                loadOptions={wpSearchTags}
                                defaultOptions
                                // styles={colourStyles}
                                components={components}
                                formatCreateLabel={(inputValue) => translate("wps.create params", {value: inputValue})}
                                defaultValue={defaultTags || undefined}
                                onChange={changeLabel}
                                placeholder={translate("wps.create tag")}
                                isClearable
                            />
                        )}
                    </Translate>
                </FormControl>
                {/* endregion */}
                {/* region Project */}
                {!commerceProject && (
                    <FormControl className={"flex-1 md:my-0 my-20 md:mx-20"}>
                        <AsyncSelect
                            defaultOptions
                            data-cy={"wp-project"}
                            classes={{endAdornment: "-top-3"}}
                            defaultValue={project}
                            name={"wpProject"}
                            // value={project}
                            onChange={changeProject}
                            // classes={{endAdornment: "top-0"}}
                            link={`${firmPrefix}/myProjects?size=8`}
                            renderOption={(option) => <ProjectView item={option} />}
                            getOptionLabel={(option) => option.name}
                            textFieldProps={{
                                fullWidth: true,
                                variant: "outlined",
                                size: "small",
                                required: true,
                                label: <Translate id={"wps.Project"} />,
                            }}
                        />
                    </FormControl>
                )}
                {/* endregion */}
                {/* region leader */}

                <FormControl className={"flex-1"}>
                    <UserPreviewSearch
                        defaultValue={selectedWP?.leader?.user?.id}
                        data-cy={"select-leader"}
                        className={"mb-12"}
                        label={<Translate id={"wps.Leader"} />}
                        name={"wpUsers"}
                        onChange={changeLeader}
                    />
                </FormControl>
                {/* endregion */}
            </div>
        </DialogContent>
    );
};

export default Details;
