import axios, {pmsPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_PROJECTS = "[COMMERCE PROJECTS] GET";
export const GET_PROJECTS_BY_CUSTOMER_ID = "[COMMERCE PROJECTS] GET PROJECTS BY CUSTOMER ID";
export const PROJECTS_CHANGE_VIEW = "[COMMERCE PROJECTS] CHANGE VIEW";
export const SELECT_PROJECT = "[COMMERCE PROJECTS] SELECT_PROJECT";
export const SELECT_PROJECT_CAT = "[COMMERCE PROJECTS] SELECT CAT";
export const SELECT_PROJECT_ADDRESS = "[SELECT_PROJECT_ADDRESS]";
export const GET_SELECT_CUSTOMER_BRAND_ID = "[GET_SELECT_CUSTOMER_BRAND_ID]";
export const CLEAR_SELECT_CUSTOMER_BRAND_ID = "[CLEAR_SELECT_CUSTOMER_BRAND_ID]";
export const SELECT_MAP_GPS_ARRAY = "[SELECT_MAP_GPS_ARRAY]";
export const PASS_NEAR_PROJECTS_DATA = "[PASS_NEAR_PROJECTS_DATA]";
export const SELECT_PROJECT_LABEL = "[SELECT_PROJECT_LABEL]";

export function getProjects(params, callback) {
    let request = params.my ?
        axios.get(`${pmsPrefix}/myProjects`, {params}) : axios.get(`${pmsPrefix}/projects`, {params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_PROJECTS,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getProjectsByCustomerId(customerId, callback) {

    //  here have 翻页(从 0 开始)
    const request = axios.get(`${pmsPrefix}/projects?status=OPEN&size=4&customerId=${customerId}`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_PROJECTS_BY_CUSTOMER_ID,
                payload: response.data.content,
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };

}


// export function productsChangeView(view){
//     return {
//         type      : PROJECTS_CHANGE_VIEW,
//         view
//     }
// }

export function selectProjectCat(cat) {
    return {
        type: SELECT_PROJECT_CAT,
        cat
    };
}

export function selectProject(payload) {
    return {
        type: SELECT_PROJECT,
        payload
    };
}

export function selectProjectLabel(payload) {
    return {
        type: SELECT_PROJECT_LABEL,
        payload
    };
}

// export function selectAddress(param) {
//     return {
//         type      : SELECT_PROJECT_ADDRESS,
//         param
//     }
// }
//
//
// export function getCustomerOfBrandId(brandId) {
//     return {
//         type     : GET_SELECT_CUSTOMER_BRAND_ID,
//         brandId
//     }
// }
//
// export function clearCustomerOfBrand() {
//     return {
//         type    : CLEAR_SELECT_CUSTOMER_BRAND_ID,
//     }
// }

export function passSelectedMapGPS(gps) {
    return {
        type: SELECT_MAP_GPS_ARRAY,
        gps
    };
}

export function passNearProjectsDatas(projects) {
    return {
        type: PASS_NEAR_PROJECTS_DATA,
        projects
    };
}
