import {Icon, IconButton, Input, InputAdornment} from "@material-ui/core";
import {useDebounceEffect} from "ahooks";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFilters} from "../hooks/filtersContext";

const SearchBox = () => {
    const {t} = useTranslation();
    const {replaceFilters, removeFilter, filters, clearFilters} = useFilters();

    const [searchText, setSearchText] = useState("");

    useDebounceEffect(
        () => {
            if (searchText.trim() !== "") {
                replaceFilters({keyword: searchText});
            } else if (filters["keyword"]) {
                removeFilter("keyword");
            }
        },
        [searchText],
        {
            wait: 800,
        }
    );

    const clearSearch = () => {
        setSearchText("");
        clearFilters();
    };

    return (
        <Input
            data-cy={"keyword"}
            className="flex flex-1  w-full md:max-w-256 mt-0 bg-white text-darker rounded-8 border px-2"
            placeholder={t("search")}
            disableUnderline
            fullWidth
            value={searchText}
            endAdornment={
                searchText &&
                searchText?.trim() !== "" && (
                    <InputAdornment position="end">
                        <IconButton data-cy={"clear-search"} onClick={clearSearch}>
                            <Icon color={"primary"} fontSize={"small"}>
                                close
                            </Icon>
                        </IconButton>
                    </InputAdornment>
                )
            }
            startAdornment={
                <InputAdornment position="start">
                    <Icon fontSize={"small"}>search</Icon>
                </InputAdornment>
            }
            onChange={(e) => setSearchText(e.target.value)}
        />
    );
};

export default SearchBox;
