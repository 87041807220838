import {lazy} from "react";

export const LoginConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                newFooter: false,
                rightSidePanel: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {
            path: "/support",
            component: lazy(() => import("./SurrportCenter")),
        },
        {
            path: "/login",
            component: lazy(() => import("./LoginNew")),
        },
    ],
};

