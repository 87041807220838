import {AppBar, Toolbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {lighten} from "@material-ui/core/styles";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import * as fuseActions from "app/store/actions";
import {memo} from "react";
import {Translate} from "react-localize-redux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios, {commonPrefix} from "../../../../myaxios";
import ToggleView from "../../../components/ToggleView";
import ZoomControlButtons from "../../../components/ZoomControlButtons";

const useStyles = makeStyles((theme) => ({
    minimizedButton: {
        height: "50px",
        marginRight: 5,
        borderRadius: 0,
        backgroundColor: lighten(theme.palette.secondary.light, 0.75),
        borderBottom: `4px solid ${theme.palette.secondary.dark}`,
        "&:hover": {
            background: lighten(theme.palette.secondary.light, 0.4),
        },
    },
}));

function FooterLayout3(props) {
    const dispatch = useDispatch();
    const footerTheme = useSelector(({fuse}) => fuse.settings.footerTheme, shallowEqual);
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const classes = useStyles();

    const onClick = (key) => (e) => {
        const data = {
            type: "openMinimized",
            name: key,
        };
        axios.post(`${commonPrefix}/clickCount`, data);
        dispatch(fuseActions.turnOn(key));
    };

    return (
        <ThemeProvider theme={footerTheme}>
            <AppBar id="fuse-footer" style={{height: "50px", minHeight: "50px", zIndex: 20000}} className="relative z-10 p-0" color="default">
                <Toolbar style={{height: "50px", minHeight: "50px"}} className="p-0 m-0 flex justify-between items-center">
                    <div>
                        {Object.keys(dialogs).map(
                            (key) =>
                                dialogs[key] === 2 && (
                                    <Button className={classes.minimizedButton} onClick={onClick(key)}>
                                        {<Translate id={`_.${key}`} />}
                                    </Button>
                                )
                        )}
                    </div>
                    <div className={"flex"}>
                        <ZoomControlButtons />
                        <ToggleView />
                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default memo(FooterLayout3);
