import {Button, Dialog, DialogContent, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as fuseActions from "@fuseActions";
import QRCode from "qrcode.react";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";

class QrCodes extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        android: false
    };

    render() {
        const {dialogs} = this.props;
        const dialogContent = (
            <>
                <DialogTitle
                    disableTypography
                    className="justify-between items-center flex drag"
                    id="alert-dialog-title"
                >
                    <Typography variant={"h6"} className={"flex-1"}>
                        {<Translate id={"navi.Download APPs"} />}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <div className="flex justify-between px-52 pb-52 pt-16">
                        <div className="flex flex-col">
                            <div className={"mb-12"}>
                                <Typography variant={"h6"} className={"font-bold"}>
                                    {<Translate id={"navi.iOS"} />}
                                </Typography>
                                <a
                                    target="_blank"
                                    href="https://apps.apple.com/cn/app/%E7%86%8A%E7%8C%AB%E5%A4%A7%E5%B8%88/id1484928055"
                                >
                                    {<Translate id={"navi.Open Apple store"} />}
                                </a>
                            </div>
                            <div className={"relative p-1 "}>
                                {!this.state.android && (
                                    <div
                                        className="absolute w-full h-full rounded  flex items-center justify-center"
                                        style={{backgroundColor: "rgba(0,0,0,0.7)"}}
                                    >
                                        <Button
                                            variant={"contained"}
                                            onClick={() =>
                                                this.setState({
                                                    android: true
                                                })
                                            }
                                        >
                                            {<Translate id={"navi.Show iOS"} />}
                                        </Button>
                                    </div>
                                )}
                                <QRCode
                                    size={180}
                                    value={"https://apps.apple.com/cn/app/%E7%86%8A%E7%8C%AB%E5%A4%A7%E5%B8%88/id1484928055"}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"mb-12"}>
                                <Typography variant={"h6"} className={"font-bold"}>
                                    {<Translate id={"navi.Android"} />}
                                </Typography>
                                <a download href="https://signabc.com/apps/pandamaster.apk">
                                    {<Translate id={"navi.Download APK"} />}
                                </a>
                            </div>
                            <div className={"relative p-1"}>
                                {this.state.android && (
                                    <div
                                        className="absolute w-full h-full rounded  flex items-center justify-center"
                                        style={{backgroundColor: "rgba(0,0,0,0.7)"}}
                                    >
                                        <Button
                                            variant={"contained"}
                                            onClick={() =>
                                                this.setState({
                                                    android: false
                                                })
                                            }
                                        >
                                            {<Translate id={"navi.Show Android"} />}
                                        </Button>
                                    </div>
                                )}
                                <QRCode size={180} value={"https://signabc.com/apps/pandamaster.apk"} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </>
        );
        return (
            <Dialog
                open={!!dialogs["downloadQRCodes"]}
                onClose={() => {
                    this.props.turnOff("downloadQRCodes");
                }}
                fullWidth
            >
                {dialogContent}
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff
        },
        dispatch
    );
}

function mapStateToProps({fuse}) {
    return {
        dialogs: fuse.dialogs
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(QrCodes)));
