import {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import axios, {crmPrefix} from "../../../../../myaxios";
import * as fuseActions from "@fuseActions";
import {Translate, withLocalize} from "react-localize-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import * as Actions from "../store/actions";
import Chip from "@material-ui/core/Chip";
import {withRouter} from "react-router-dom";

class DeleteCompanyDialog extends Component {
    state = {
        loading: false,
    };

    onDelete = (id) => {
        const {search} = this.props;
        this.setState({loading: true});
        axios
            .delete(`${crmPrefix}/company/${id}`)
            .then(() => {
                this.setState({loading: false});
                this.props.showMessage({
                    variant: "success",
                    message: "message.Company deleted!",
                });
                this.props.getList(search);
                this.props.turnOff("deletecompany");
                this.props.turnOff("companyDetail");
                if (this.props.match.params.id) {
                    this.props.history.push("/marketing/companies");
                }
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    render() {
        const {dialogs, selected} = this.props;
        return (
            <Dialog
                open={!!dialogs["deletecompany"]}
                onClose={() => {
                    this.props.turnOff("deletecompany");
                }}
                fullWidth
            >
                <div className={this.state.loading ? "loading" : null}>
                    {selected && (
                        <>
                            <DialogTitle id="alert-dialog-title">
                                {" "}
                                <Translate id="crm.Delete" /> {selected.name}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Translate id="market.Are you sure you want to delete" />
                                    <strong>
                                        <Chip
                                            className={"text-light"}
                                            size={"small"}
                                            label={selected.name}
                                            color="primary"
                                        />
                                    </strong>
                                    ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.props.turnOff("deletecompany")}>
                                    <Translate id="_.disagree" />
                                </Button>
                                <Button onClick={() => this.onDelete(selected.id)} color="secondary">
                                    <Translate id="_.delete" />
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff,
            showMessage: fuseActions.showMessage,
            selectCompany: Actions.selectItem,
            getList: Actions.getList,
        },
        dispatch
    );
}

function mapStateToProps({fuse, marketing}) {
    return {
        dialogs: fuse.dialogs,
        selected: marketing.companies.list.selected,
        search: fuse.search,
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(DeleteCompanyDialog)));
