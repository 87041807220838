import {Component} from "react";
import axios from "../../myaxios";
import TextField from "@material-ui/core/TextField";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {CircularProgress} from "@material-ui/core";
import { showMessage, startLoading, finishLoading } from "@fuseActions";
import {Translate, withLocalize} from "react-localize-redux";
import PropTypes from "prop-types";
import translations from "../../translations/global";

class CheckerInput extends Component {

    constructor(props) {
        super(props);
        this.props.addTranslation(translations);
    }


    state = {
        value: "",
        error: false,
        errorMsg: "",
    };

    componentDidMount = () => {
        if ( this.props.editValue ) {
            this.setState({value: this.props.editValue});
        }
    };

    timerForSearch;
    checker = (value) => {
        clearTimeout(this.timerForSearch);
        this.props.startLoading();
        this.timerForSearch = setTimeout(()=>{
            axios.get(this.props.url,  {
                params: {
                    [this.props.name]: value
                }
            }).then(res => {
                this.props.finishLoading();
                if(res.data.result) {
                    this.setState({error: false, errorMsg: "_.available"});

                    this.props.error(false); // there is no error
                    this.props.changeHandler(this.state.value);
                }
                else
                    this.setState({error: true, errorMsg: "_.not available"});
                // this.props.error(true);
            }).catch(() => {
                this.props.error(true);
                return false;
            });
        },1000);

    };

    showErrorTips = (translate) => {

        const {min, max} = this.props;

        const {errorMsg} = this.state;

           // console.log(`CheckerInput 的 errorMsg`, errorMsg);
        if(min)
           if (errorMsg && errorMsg === "_.must be more than") {
               // return <Translate id={errorMsg} data={{parameter: this.props.min}}>{"Must be more than ${parameter} character"}</Translate>
               return translate(errorMsg, {parameter : min});
           }
        if(max)
            if (errorMsg && errorMsg === "_.must be less than") {
                // return <Translate id={errorMsg} data={{parameter: this.props.max}}>{"Must be less than ${parameter} character"}</Translate>
                return translate(errorMsg, {parameter : max});
            }
            if (errorMsg && errorMsg === "_.available") {
               return  translate(errorMsg);
            }
            if (errorMsg && errorMsg === "_.not available") {
               return  translate(errorMsg);
            }
        return  "";
    };


    Handler = event => {
        const value = event.target.value;
        this.setState({value});
        if(value.length >= this.props.min && value.length <= this.props.max ) {
            if(this.props.url) {
                this.checker(value);
            }else {
                this.setState({error: false, errorMsg: ""});
                this.props.error(false);
                this.props.changeHandler(value);
            }
            this.setState({error: false, errorMsg: ""});
        } else if (value.length < this.props.min) {
            this.setState({error: true, errorMsg:"_.must be more than"});
            this.props.error(true); // there is an error
        }
        if (value.length > this.props.max) {
            this.setState({error: true, errorMsg: "_.must be less than"});
            this.props.error(true);
        }
    };

    render() {
        const {
            value,
            error
        } = this.state;
        const  {required, inputType} = this.props;
        return (
            <Translate>
                {({ translate }) => <TextField
                    inputProps={{autoComplete: "off"}}
                    autoComplete={"off"}
                    margin={this.props.margin}
                    autoFocus={this.props.autoFocus}
                    className={this.props.className}
                    fullWidth
                    required={required ? required : false}
                    value={value}
                    error={error}
                    type={inputType ? inputType : "text"}
                    helperText={this.showErrorTips(translate)}
                    // helperText={errorMsg ? (errorMsg === "_.must be more than") ? <Translate id={errorMsg} data={{parameter: this.props.min}}> {"Must be more than ${parameter} character"} </Translate> : <Translate id={errorMsg}/> : ""}
                    onChange={this.Handler}
                    label={this.props.label}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {this.props.loading ? <CircularProgress size={20}/> : <CircularProgress size={0}/> }
                            </InputAdornment>
                        ),
                    }}
                />}
            </Translate>

        );
    }
}

CheckerInput.propTypes = {
    name            : PropTypes.string.isRequired,
    error           : PropTypes.func.isRequired,
    changeHandler   : PropTypes.func.isRequired,
    editValue       : PropTypes.string,
    label           : PropTypes.object,
    inputType       : PropTypes.string,
    url             : PropTypes.string.isRequired,
    min             : PropTypes.number,
    max             : PropTypes.number,
};

CheckerInput.defaultProps = {
    min : 0
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage             : showMessage,
        startLoading            : startLoading,
        finishLoading           : finishLoading,
    }, dispatch);
}

function mapStateToProps({fuse}) {
    return {
        dialogs         : fuse.dialogs,
        loading         : fuse.loading,
    };
}
export default withLocalize(connect(mapStateToProps,mapDispatchToProps)((CheckerInput)));
