import {FuseLoading} from "@fuse";
import {Icon, ListItemText, MenuItem, Popover, Typography} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {EmptyView} from "@ui";
import {memo, useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useSelector} from "react-redux";
import type {userType} from "types";
import axios, {firmPrefix} from "../../../myaxios";
import translate from "../../../translations/global.json";

type FirmType = {
    name: string;
    id: string;
    user: userType;
};

function Logo(props) {
    const mainTheme = useSelector(({fuse}) => fuse.settings.mainTheme);
    const user = useSelector(({user}) => user);
    const masterFirm = useSelector(({user}) => user.masterFirm);
    const [open, setOpen] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [myFirms, setMyFirms] = useState<FirmType[]>();
    useEffect(() => {
        props.addTranslation(translate);
    }, []);

    const closeMenu = () => {
        setOpen(null);
    };
    const openMenu = (event) => {
        setOpen(event.currentTarget);
        setLoading(true);
        axios
            .get<FirmType[]>(`${firmPrefix}/myFirms`)
            .then(({data}) => {
                setMyFirms(data);
            })
            .finally(() => setLoading(false));
    };
    const setMasterFirm = (firmId) => {
        setLoading(true);
        axios.put(`${firmPrefix}/masterFirm?firmId=${firmId}`).then(() => {
            document.location.reload();
        });
    };

    return (
        <>
            <button className="flex h-full justify-between rounded-0 items-center no-drag" onClick={openMenu}>
                <span className={"flex items-center"}>
                    <Icon className={"sm:flex hidden logo-arrow ml-12"}>{open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                    <img className="logo-icon align-middle w-24 h-24 mx-12 rounded-full" src="assets/images/logos/logo128.png" alt="logo" />
                    {masterFirm ? (
                        <Typography component={"span"} data-cy={"master-firm"} className={"text-16 font-light logo-text"}>
                            {masterFirm.name}
                        </Typography>
                    ) : (
                        <Typography data-cy={"no-master-firm"} className={"text-16 ml-12 font-light logo-text"} color="textPrimary">
                            <Translate id={"nav.Panda Master"} />
                        </Typography>
                    )}
                </span>
            </button>
            <ThemeProvider theme={mainTheme}>
                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={() => closeMenu()}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    classes={{paper: "min-w-192"}}>
                    {loading ? (
                        <MenuItem>
                            <FuseLoading className={"w-24 h-24"} />
                        </MenuItem>
                    ) : myFirms && myFirms.length > 1 ? (
                        myFirms.map((_firm, i) => {
                            let isMasterFirm = false;
                            if (user.masterFirm) {
                                isMasterFirm = user.masterFirm.id === _firm.id;
                            }
                            return (
                                !isMasterFirm && (
                                    <MenuItem key={i}>
                                        <ListItemText
                                            classes={{primary: "text-18"}}
                                            onClick={() => {
                                                setMasterFirm(_firm.id);
                                            }}
                                            className="pl-0"
                                            primary={_firm.name}
                                        />
                                    </MenuItem>
                                )
                            );
                        })
                    ) : (
                        <EmptyView label={"No other firms"} noIcon dense />
                    )}
                </Popover>
            </ThemeProvider>
        </>
    );
}

export default withLocalize(memo(Logo));
