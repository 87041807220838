import {Button, Dialog, DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useMemoizedFn} from "ahooks";
import * as fuseActions from "app/store/actions";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useInstanceStore} from "../instances/state/useInstanceStore";
import {useUserTaskStore} from "../user-tasks/state";

function NewDeleteVariableTipDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.deleteVariableTipsDialog
    });

    const dispatch = useDispatch();

    const instanceVariable = useInstanceStore((state) => state.instanceVariable);

    const onChangeInstanceVariable = useInstanceStore((state) => state.onChangeInstanceVariable);

    const userTaskVariable = useUserTaskStore((state) => state.userTaskVariable);

    const onChangeUserTaskVariable = useUserTaskStore((state) => state.onChangeUserTaskVariable);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            onChangeInstanceVariable(null);
            onChangeUserTaskVariable(null);
        };
    }, []);

    const handleDelete = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        instanceVariable ? deleteVariablesForInstance() : deleteVariablesForUserTask();
    });

    const deleteVariablesForInstance = () => {
        const params = {
            name: instanceVariable?.variable?.name,
            id: instanceVariable?.variable?.id
        };
        console.log("delete instance with params instanceVariable", instanceVariable);
        console.log("delete instance variable", params);
        axios
        .delete(`${flowPrefix}/process-instance/${instanceVariable?.instance?.id}/variables`, {params: params})
        .then((response) => {
            finshParams?.onFinish();
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Delete variables success!"
                })
            );
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const deleteVariablesForUserTask = () => {
        const params = {
            name: userTaskVariable?.variable?.name,
            id: userTaskVariable?.variable?.id
        };
        axios
        .post(`${flowPrefix}/task/${userTaskVariable?.userTask?.id}/variables`, {params: params})
        .then((response) => {
            finshParams?.onFinish();
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Delete variables success!"
                })
            );
            onTurnOffDialog();
        });
    };

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <DialogTitle id="DeleteInstanceTips-dialog-title">
                    <Translate id="process.Tips" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="DeleteDefinitionTips-dialog-description">
                        <Translate id="process.Are you sure you want to delete this variable: " />
                        <strong>
                            {(instanceVariable && instanceVariable?.variable && instanceVariable?.variable?.name) ||
                                (userTaskVariable && userTaskVariable?.variable && userTaskVariable?.variable?.name)}
                        </strong>
                        ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleDelete}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={loading}
                        onClick={handleSubmit}
                        color="secondary"

                    >
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewDeleteVariableTipDialog;
