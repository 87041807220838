import {Translate} from "react-localize-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Dialog} from "@material-ui/core";
import {useProcessStore} from "../../state/useProcessStore";
import {useMemoizedFn} from "ahooks";
import ActivitiesTipView from "./ActivitiesTipView";
import TaskStepInfoTipView from "./TaskStepInfoTipView";

function HelpCenterDialog(props) {
    const openHelp = useProcessStore((state) => state.openHelp);
    const onChangeHelpCenterDialog = useProcessStore((state) => state.onChangeHelpCenterDialog);
    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onChangeHelpCenterDialog(false);
    });
    return (
        <Dialog
            fullWidth
            maxWidth={"sm"}
            open={openHelp}
            onClose={handleClose}
            aria-labelledby="HelpCenterDialog-title"
        >
            {
                <Translate>
                    {({translate}) => (
                        <DialogTitle id="HelpCenterDialog-title">
                            {translate("_.Flow diagram reading help information")}
                        </DialogTitle>
                    )}
                </Translate>
            }
            <DialogContent className="-mt-10">
                <div className="flex flex-col px-5">
                    <div className="flex flex-row items-center mt-3">
                        <div className="flex items-center mr-3">
                            <ActivitiesTipView />
                        </div>
                        <div className="flex items-center">
                            <TaskStepInfoTipView />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    {<Translate id={"_.close"} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default HelpCenterDialog;
