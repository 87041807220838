import {Accordion, AccordionDetails, AccordionSummary, Avatar} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {MyTooltip} from "@ui";
import PropTypes from "prop-types";
import {Translate, withLocalize} from "react-localize-redux";
import ImagesDisplayView from "../../utils/ui/ImagesDisplayView";
import StrongLabel from "../../utils/ui/StrongLabel";

ExpandAccordion.propTypes = {
    external: PropTypes.object
};

function ExpandAccordion(props) {
    const {external} = props;
    return (
        <Accordion defaultExpanded>
            <AccordionSummary className="border-b-1 border-solid border-grey" expandIcon={<ExpandMoreIcon />}>
                <div className="flex flex-row items-center">
                    <MyTooltip title={<Translate id={"exm.check-in userCount"} />}>
                        <Avatar className={"mx-3 shadow-md"}>+{external.userCount}</Avatar>
                    </MyTooltip>
                    <MyTooltip title={<Translate id={"exm.check-in date"} />}>
                        <StrongLabel label={external.date} />
                    </MyTooltip>
                </div>
            </AccordionSummary>
            <AccordionDetails classes={{root: "min-h-800"}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ImagesDisplayView images={external.checkins} title={<Translate id={"exm.photos checked"} />} />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default withLocalize(ExpandAccordion);
