import {
    CLEAR_CHECKED_PS,
    GET_PROJECTS,
    REMOVE_ALLOW_CHECK,
    SELECT_PROJECT,
    SELECT_PROJECT_STAGES,
    SELECT_STAGE,
    SELECT_TEMPLATE,
    TEMPLATE_STAGES,
    TOGGLE_ALLOW_CHECK,
    TOGGLE_MENU_ANCHOR,
    TOGGLE_VIEW
} from './dashboard.action';


const initialState = {
    selectedProject : null,
    selectedStage : null,
    selectedPS      : null,
    templateStages  : null,
    projects        : null,
    dashboardView   : true,
    allowCheckPS    : false,
    menuAnchor      : null
};

const reducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case SELECT_PROJECT: {
            return {
                ...state,
                selectedProject: action.payload
            };
        }
        case SELECT_TEMPLATE: {
            return {
                ...state,
                selectedTemplate: action.payload
            };
        }
        case TOGGLE_ALLOW_CHECK: {
            return {
                ...state,
                allowCheckPS: !state.allowCheckPS
            };
        }
        case REMOVE_ALLOW_CHECK: {
            return {
                ...state,
                allowCheckPS: false
            };
        }
        case GET_PROJECTS: {
            return {
                ...state,
                projects: action.payload
            };
        }
        case TEMPLATE_STAGES: {
            return {
                ...state,
                templateStages: action.payload
            };
        }
        case SELECT_PROJECT_STAGES: {
            return {
                ...state,
                selectedPS: action.payload
            };
        }
        case SELECT_STAGE: {
            return {
                ...state,
                selectedStage: action.payload
            };
        }
        case TOGGLE_MENU_ANCHOR: {
            return {
                ...state,
                menuAnchor: action.payload ? true : null
            };
        }
        case CLEAR_CHECKED_PS: {
            return {
                ...state,
                selectedPS: null
            };
        }
        case TOGGLE_VIEW: {
            return {
                ...state,
                dashboardView: !state.dashboardView
            };
        }
        default:
        {
            return state;
        }
    }
};

export default reducer;
