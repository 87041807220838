import {finishLoading, startLoading} from "@fuseActions";
import {Button, Icon} from "@material-ui/core";
import * as fuseActions from "@fuseActions";
import {memo, useState} from "react";
import {useDispatch} from "react-redux";
import axios, {userPrefix} from "../../../myaxios";

const OldSystemButton = () => {
    const dispatch = useDispatch();
    const [link, setLink] = useState(null);

    const redirectToOldSystem = (param) => {
        dispatch(startLoading());
        axios
        .get(`${userPrefix}/accessOA`)
        .then((res) => {
            if (res.data.url) {
                window.open(res.data.url);
                setLink(res.data.url);
                setTimeout(() => {
                    setLink(null);
                }, 30000);
            }
            dispatch(finishLoading());
        })
        .catch((res) => {
            dispatch(finishLoading());
            dispatch(
                fuseActions.showMessage({
                    variant: "error",
                    message: "message.You don't have access to the old system"
                })
            );
        });
    };

    return link ? (
        <Button data-tut={"goto-old-system"} className="no-drag" target={"_blank"} color={"primary"} component={"a"} href={link}>
            <Icon>queue_play_next</Icon>
        </Button>
    ) : (
        <Button data-tut={"goto-old-system"} className="no-drag text-grey" onClick={redirectToOldSystem}>
            <Icon>queue_play_next</Icon>
        </Button>
    );
};

export default memo(OldSystemButton);
