// import type {CategoryNumber} from "./types";

import {differenceWith, isEqual} from "lodash";
import type {DGRowType} from "./types";

export function categorizeByYearMonthDay(timestamps: number[]): ReturnTypeDateCat {
    return timestamps.reduce((categories, timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear(); // Year
        const month = date.getMonth(); // 0-indexed month number

        const existingYear = categories.find((category) => category.year === year);

        if (existingYear) {
            const existingMonth = existingYear.months.find((monthObj) => monthObj.month === month);
            existingYear.count++;
            if (existingMonth) {
                existingMonth.count++;
                const day = date.getDate();
                if (!existingMonth.days.includes(day)) {
                    existingMonth.days.push(day);
                }
                // existingMonth.days.push(date.getDate()); // Add day to existing month
            } else {
                existingYear.months.push({
                    month,
                    count: 1,
                    days: [date.getDate()], // Add day to new month
                });
            }
        } else {
            categories.push({
                year,
                count: 1,
                months: [
                    {
                        month,
                        count: 1,
                        days: [date.getDate()], // Add day to new month
                    },
                ],
            });
        }

        return categories;
    }, [] as ReturnTypeDateCat);
}

export type ReturnTypeDateCat = {year: number; count: number; months: {month: number; count: number; days: number[]}[]}[];

export function searchInObject(obj: any, text: string): boolean {
    if (typeof obj === "string") {
        return obj.toLowerCase().includes(text.toLowerCase());
    } else if (Array.isArray(obj)) {
        // Search within array elements
        return obj.some((item) => searchInObject(item, text));
    } else if (typeof obj === "object") {
        // Search within object properties (excluding functions)
        for (const key in obj) {
            if (typeof obj[key] !== "function" && searchInObject(obj[key], text)) {
                return true;
            }
        }
    }
    return false;
}

export function addMissingElements(arr1, arr2): string[] {
    // Create a set from the first array to efficiently check for missing elements.
    const set1: Set<string> = new Set(arr1);
    const s: string[] = [...set1];

    // Iterate over the second array and add missing elements to the first array.
    for (const element of arr2) {
        if (!set1.has(element)) {
            s.push(element);
        }
    }

    return s;
}

/**
 * Finds the difference between two array of object
 * if there is no same id objects, returns arr1
 * if there is same ids, only checks the sameids between two arrays and also returns the rest of elements
 * @param {T[]} oldArr
 * @param {T[]} newArr
 * @returns {any[] | T[]}
 *
 */
export function findConflict<T extends {id: string}>(
    oldArr?: T[],
    newArr?: T[]
): {
    added: T[];
    removed: T[];
    modified: T[];
} {
    if (!oldArr && !newArr)
        return {
            added: [],
            removed: [],
            modified: [],
        };

    if ((!newArr || newArr.length === 0) && oldArr) {
        return {
            added: [],
            removed: oldArr,
            modified: [],
        };
    }
    if ((!oldArr || oldArr.length === 0) && newArr) {
        return {
            added: newArr,
            modified: [],
            removed: [],
        };
    }

    const startTime = performance.now();

    if (!oldArr)
        return {
            added: [],
            removed: [],
            modified: [],
        };
    if (!newArr)
        return {
            added: [],
            removed: [],
            modified: [],
        };

    const sameIds = new Set(oldArr.filter((a1) => newArr.some((a2) => a1.id === a2.id))?.map((x) => x.id));

    if (sameIds.size === 0) {
        return {
            added: newArr,
            removed: oldArr,
            modified: [],
        };
    }

    const modified = differenceWith(
        oldArr.filter((a1) => sameIds.has(a1.id)),
        newArr.filter((a1) => sameIds.has(a1.id)),
        (a, b) => {
            // Compare the length of the arrays
            if (Object.keys(a).length !== Object.keys(b).length) {
                return false;
            }

            // Compare the values of each key
            for (const key in a) {
                if (!isEqual(a[key], b[key])) {
                    return false;
                }
            }

            return true;
        }
    );

    // console.log({l1: arr1.length, l2: arr2.length, diffSizeDiff, sameIds});
    const res = {
        added: oldArr?.filter((a1) => !newArr?.some((a2) => a2.id === a1.id)),
        removed: newArr?.filter((a1) => !oldArr?.some((a2) => a2.id === a1.id)),
        modified,
    };

    console.log("Execution time for finding diff:", ((performance.now() - startTime) / 1000).toFixed(4), "seconds");

    return res;
}

export type CellType = {rowId: string; colId: string; value: any; id: number};

export function convertCellsToRows(cells?: CellType[]): DGRowType[] {
    const rows: DGRowType[] = [];
    if (!cells) return rows;
    for (const cell of cells) {
        const {rowId, colId, value} = cell;
        const row = rows.find((row) => row.id === rowId);
        if (!row) {
            rows.push({id: rowId, [colId]: value});
        } else {
            row[colId] = value;
        }
    }
    return rows;
}

// export function categorizeNumbers(numbers: number[]): CategoryNumber[] {
//     const startTime = performance.now();
//     // Define category ranges
//     const categories: CategoryNumber[] = [];
//     const minim = Math.min(...numbers);
//     const maxim = Math.max(...numbers);
//     const range = (maxim - minim) / 10; // Calculate range based on highest number
//     for (let i = 0; i < 6; i++) {
//         const min = formatLargeNumber(Math.floor(i * range + minim));
//         const max = formatLargeNumber(Math.floor((i + 1) * range + minim)); // Make sure max is inclusive
//         categories.push({min, max});
//     }
//     const endTime = performance.now();
//
//     console.log("categorizeNumbers, Execution time:", endTime - startTime, "milliseconds");
//     // Assign categories to numbers (assuming numbers fit within ranges)
//     return categories;
// }

// function formatLargeNumber(number: number) {
//     // Check if the number is less than 1000
//     if (number < 1000) {
//         return number;
//     }
//
//     // Calculate the number of digits in the number
//     const digits = Math.floor(Math.log10(number)) + 1;
//
//     // Determine the appropriate suffix based on the number of digits
//     // const suffixes = ["", "000", "M", "B", "T"];
//     const suffixIndex = Math.floor((digits - 1) / 3);
//
//     // Calculate the scaled number and format it with 3 digits
//     const scaledNumber = number / 1000 ** suffixIndex;
//     const formattedNumber = scaledNumber.toFixed(3);
//
//     // Add zeros to the right of the decimal point to make it 3 digits
//     const formattedNumberWithZeros = formattedNumber.padEnd(6, "0");
//
//     // Combine the formatted number with zeros and the suffix
//     return Number(formattedNumberWithZeros);
// }

export function isTimestamp(number) {
    if (typeof number !== "number") return false;
    // Check length
    if (number.toString().length !== 10 && number.toString().length !== 13 && number.toString().length !== 16) {
        return false;
    }

    // Check range
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    if (number < 0 || (number > 2147483647 && number < 946684800000) || number > 999999999999999999) {
        return false;
    }

    // Try date conversion
    const date = new Date(number);
    if (isNaN(date.getTime())) {
        return false;
    }

    // Return true if all checks pass
    return true;
}

export const getDataSizes = [1 * 1024 ** 2, 10 * 1024 ** 2, 100 * 1024 ** 2, 1024 ** 3, 4 * 1024 ** 3];
