import {lazy} from "react";

export const wpConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/engineer/:engineerId/wps",
            component: lazy(() => import("./WPs/Page")),
        },
        {
            path: "/engineers",
            component: lazy(() => import("./field-engineers/WorkersList")),
        },
        {
            path: "/wp/:id/",
            component: lazy(() => import("./WP/WPPage")),
        },
        {
            path: "/workplan/:id/",
            component: lazy(() => import("./WP/WPPage")),
        },
        {
            path: "/wps/dashboard",
            component: lazy(() => import("./Dashboard/WPDashboard")),
        },
        {
            path: "/wps/unread/:unread",
            component: lazy(() => import("./WPs/Page")),
        },
        {
            path: "/wps/:type",
            component: lazy(() => import("./WPs/Page")),
        },
        {
            path: "/wps",
            component: lazy(() => import("./WPs/Page")),
        },
    ],
};
