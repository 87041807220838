//noinspection JSDeprecatedSymbols,JSUnresolvedReference
import {addProgress, finishLoading, startLoading, startProgress} from "@fuseActions";
import {Typography} from "@material-ui/core";
import {MyAvatar, NameLabel, UserView} from "@ui";
import {stringify} from "flatted";
import store from "../app/store";
import {baseURL} from "../ENV.VARIABLE";
import axios, {firmPrefix, pmsPrefix, userPrefix} from "../myaxios";

export const languageMap = {
    cn: "zh-cn",
    "en ": "en-gb",
};

export function msToTime(ms) {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days";
}

export const dueDateClassGenerator = (date) => {
    let dateClass = "";
    const now = +new Date();
    // if (projectStage.status === "DONE") dateClass = "text-grey-darkest font-bold";
    if (date > now + 604800000) dateClass = "text-green-dark font-bold";
    else if (date > now + 172800000) dateClass = "text-orange-dark font-bold";
    else if (date > now) dateClass = "text-red font-bold";
    else if (date < now) dateClass = "text-white bg-red px-1 rounded-2 font-bold";

    return dateClass;
};

export function createSearchParams(searchQuery) {
    return Object.keys(searchQuery)
        .map((key) => `${key}=${searchQuery[key]}`)
        .join("&");
}

export const wpSearchTags = (inputValue, callback) => {
    axios
        .get(
            `${firmPrefix}/workplanTagHistory?size=8`,
            inputValue && {
                params: {
                    keyword: inputValue,
                },
            }
        )
        .then((res) => {
            const tags = res.data.map((item) => ({
                value: item,
                label: item,
            }));
            callback(tags);
        });
};

export function validURL(str) {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
}

export const createDownloadLink = (file, name, id) => {
    const language = localStorage.getItem("i18nextLng");
    store.dispatch(startProgress());
    store.dispatch(
        // eslint-disable-next-line no-undef
        addProgress({
            percent: 0,
            title: language === "en" ? "Downloading " : "下载中 " + name,
            id,
        })
    );
    axios({
        url: file,
        // data: {file},
        responseType: "blob",
        method: "get",
        onDownloadProgress: function (progressEvent) {
            store.dispatch(
                addProgress({
                    percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
                    title: language === "en" ? "Downloading " : "下载中 " + name,
                    id,
                })
            );
        },
    }).then((response) => {
        store.dispatch(
            addProgress({
                percent: 100,
                title: name,
                id,
            })
        );
        const blob = new Blob([response.data], {type: response.data.type});
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
    });
};

export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}

//export function arrayMover(arr, old_index, new_index) {
//    if (new_index >= arr.length) {
//        let k = new_index - arr.length + 1;
//        while (k--) {
//            arr.push(undefined);
//        }
//    }
//    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
//    return arr; // for testing
//}
//
//export const b64toBlob = async (b64Data, contentType = "application/octet-stream") => {
//    const url = `data:${contentType};base64,${b64Data}`;
//    const response = await fetch(url);
//    return await response.blob();
//};

export function formatSizeUnits(bytes, decimals = 2, si = true) {
    if (!bytes) return "";
    if (bytes === 0) return "0 Bytes";

    const thresh = si ? 1000 : 1024;
    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

    let u = -1;
    let convertedBytes = bytes;

    do {
        convertedBytes /= thresh;
        ++u;
    } while (Math.abs(convertedBytes) >= thresh && u < units.length - 1);

    return convertedBytes.toFixed(decimals) + " " + units[u];
}

export function expireTime(timestamp, minutes) {
    return (new Date() - new Date(timestamp)) / 60000 < minutes;
}

export const retrieveImageFromClipboardAsBlob = (pasteEvent, callback) => {
    if (pasteEvent.clipboardData === false) {
        if (typeof callback === "function") {
            callback(undefined);
        }
    }

    const items = pasteEvent.clipboardData.items;

    if (items === undefined) {
        if (typeof callback === "function") {
            callback(undefined);
        }
    }

    for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") === -1) continue;
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile();

        if (typeof callback === "function") {
            callback(blob);
        }
    }
};

export const searchContact = (inputValue, callback) => {
    // const lastSearchedContacts = localStorage.getItem("lastSearchedContacts");

    axios
        .get(`${userPrefix}/myContacts?showMyFirmUser=true&size=8`, {
            params: {
                keyword: inputValue,
            },
        })
        .then((res) => {
            // const lastItem = res.data.content.find(item => item.user.id === lastSearchedContacts);

            const users = res.data.content.map((item) => ({
                value: item.user.id,
                user: item.user,
                label: <UserView user={item.user} />,
            }));
            // const data = lastItem  ? [
            //     {
            //         value: lastItem,
            //         label: <UserView user={lastItem}/>
            //     },
            //     ...users] : users;
            callback(users);
        });
};

export const dateToColor = (date) => {
    let days = Math.floor((new Date(date) - new Date()) / 86400000) + 1;
    if (days > 0) {
        return days < 7 ? "orange" : "green";
    } else {
        return "red";
    }
};

export const searchProjects = (inputValue, callback) => {
    axios
        .get(`${pmsPrefix}/myProjects?size=8`, {
            params: {
                keyword: inputValue,
            },
        })
        .then((res) => {
            const projects = res.data.content.map((item) => ({
                value: item.id,
                item,
                label: (
                    <span className={"flex items-center nowrap"}>
                        <strong className={"mr-2"}>{item.name}</strong>
                    </span>
                ),
            }));
            callback(projects);
        });
};

export const searchAllProjects = (inputValue, callback) => {
    axios
        .get(`${pmsPrefix}/projects?size=8`, {
            params: {
                keyword: inputValue,
            },
        })
        .then((res) => {
            const projects = res.data.content.map((item) => ({
                value: item.id,
                item,
                label: (
                    <span className={"flex items-center nowrap"}>
                        <strong className={"mr-2"}>{item.name}</strong>
                    </span>
                ),
            }));
            callback(projects);
        });
};

export const userViewTitle = (user, variant) => (
    <Typography variant={variant || "body2"}>
        <strong>
            <NameLabel user={user} />
        </strong>{" "}
        <small className="text-gray">({user.username})</small>
    </Typography>
);

export const ProjectView = ({item}) => (
    <span className={"flex items-center"} data-cy={"projectItemSelector"}>
        {item.user && <MyAvatar user={item.user} className={"mr-3"} size={24} />}
        <strong className={"mr-2 truncate"}>{item.name}</strong> <small className={"text-grey-dark truncate"}>{item.brandName}</small>
    </span>
);
// export const departmentsView = (departments) => {
//     return
//         (departments && departments.length > 0) &&
//     departments.map((i, index) => <Typography key={index} variant={"caption"}>{i}{index !== departments.length - 1 && ", "} </Typography>}</>
// }

//
// export const changeTimestampToDate = (timestamp) => {
//     return new Date(timestamp).getFullYear() + "-" +
//         (new Date(timestamp).getMonth() < 9 ? "0"+(new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1)
//         + "-" + (new Date(timestamp).getDate() < 10 ? "0"+(new Date(timestamp).getDate()) : new Date(timestamp).getDate())
// };

export const createUploader = (callback) => {
    const language = localStorage.getItem("i18nextLng");

    let blockInfo = [];
    // const [blockInfo, setBlockInfo] = React.useState([]);
    const WebUploader = window.WebUploader;
    // const [uploadPercent, setUploadPercent] = React.useState(0);
    // console.log(window.remote.getCurrentWindow().webContents);
    if (!WebUploader) {
        // console.log("window.WebUploader doesn't support!");
        return;
    }
    // console.log("window.WebUploader supports!", WebUploader);

    //    const $ = window.$;
    // eslint-disable-next-line no-undef
    WebUploader.Uploader.register(
        {
            "before-send": "beforeSend",
        },
        {
            beforeSend: (block) => {
                let deferred = WebUploader.Deferred();
                if (block.file.size < 1100000) {
                    deferred.reject();
                    store.dispatch(
                        addProgress({
                            title: language === "en" ? "Error: File is small" : "错误:文件太小 ",
                            id: block.file.id,
                            percent: 100,
                        })
                    );
                }
                // console.log(block.file);
                if (block.file.type.includes("image")) {
                    deferred.reject();
                    store.dispatch(
                        addProgress({
                            title: language === "en" ? "Error: Shouldn't be image" : "正在检查 ",
                            id: block.file.id,
                            percent: 100,
                        })
                    );
                }
                let chunk = block.chunk;
                // console.log('检测分片是否上传'+chunk+" blocckInfo:"+blockInfo.indexOf(chunk));
                if (blockInfo.indexOf(chunk) + 1 > 0) {
                    deferred.reject();
                } else {
                    deferred.resolve();
                }
                return deferred.promise();
            },
        }
    );
    //配置可直接查看官网
    const uploader = WebUploader.create({
        server: `${baseURL}/srx-oss/v1/upload/file`,
        auto: false,
        chunked: true,
        chunkSize: 1024 * 1024 * 4,
        resize: false,
        headers: {
            token: localStorage.getItem("token"),
        },
        chunkRetry: false,
        threads: 1,
        fileNumLimit: 1, //附加数据
        formData: {
            guid: WebUploader.Base.guid("srx_sun_"),
            id: "100",
        }, // accept: [
        //     {
        //         title: 'Images',
        //         extensions: 'gif,jpg,jpeg,bmp,png',
        //         mimeTypes: 'image/*'
        //     }
        // ]
    });

    //这里我用的是单文件上传，所以每次在文件入列之前，重置uploader和分片信息
    uploader.on("beforeFileQueued", () => {
        uploader.reset();
        blockInfo = [];
        store.dispatch(startProgress());
        store.dispatch(startLoading());
    });

    //文件切面
    uploader.on("fileQueued", (file) => {
        uploader
            .md5File(file, 0, 4 * 1024 * 1024)
            .progress((percentage) => {
                store.dispatch(
                    addProgress({
                        title: language === "en" ? "Checking " : "正在检查 " + file.name,
                        id: file.id,
                        percent: (percentage * 100).toFixed(2),
                    })
                );
                // console.log('正在读取文件...' + percentage.toFixed(2) * 100 + '%')
            })
            .then((fileMd5) => {
                let formData = uploader.option("formData");
                formData.md5 = fileMd5;
                uploader.option("formData", formData);
                let fileInfo = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    ext: file.ext,
                    md5: formData.md5,
                };

                // console.log("formData:", formData)

                axios.post(`${baseURL}/srx-oss/v1/upload/sha2check`, {...formData, ...fileInfo}).then(({data}) => {
                    switch (data.code) {
                        // 断点
                        case "0":
                            // console.log('正在从上次断开的地方上传文件...')
                            // console.log("data  blockInfo:"+data.blockInfo)
                            for (let i in data.blockInfo) {
                                // setBlockInfo([...blockInfo,data.blockInfo[i]])
                                blockInfo.push(data.blockInfo[i]);
                            }
                            file.status = 0;
                            break;
                        // 无断点
                        case "1":
                            // console.log('正在上传文件...')
                            file.status = 1;
                            break;
                    }
                    uploader.upload();
                });
                //下面这个方法是一个验证分片是否存在的请求，如果存在的话直接续传
                /* $.request({
type: 'post',
url: 'http://localhost:8080/file/uploadMd5check',
data: {
...fileInfo,
...formData
}
}).then(({data, msg}) => {
switch (data.code) {
// 断点
case '0':
this.setState({
'正在从上次断开的地方上传文件...'
});
for (let i in data.blockInfo) {
this.blockInfo.push(data.blockInfo[i]);
}
file.status = 0;
break;
// 无断点
case '1':
this.setState({
tips: '正在上传文件...'
});
file.status = 1;
break;
}
uploader.upload();
}, msg => {
this.setState({
tips: <span style={{color: 'red'}}>{msg}</span>
});
})*/
            });
    });

    //所有分片上传完成后
    uploader.on("uploadSuccess", (file) => {
        let formData = uploader.option("formData");
        let fileInfo = {
            name: file.name,
            size: file.size,
            type: file.type,
            ext: file.ext,
        };
        // console.log('upload finished');
        //请求合并

        axios.post(`${baseURL}/srx-oss/v1/upload/merge`, {...formData, ...fileInfo}).then(({data}) => {
            store.dispatch(
                addProgress({
                    title: language === "en" ? "Uploaded " : "已上传 " + file.name,
                    id: file.id,
                    percent: 100,
                })
            );
            store.dispatch(finishLoading());
            // console.log(data);
            callback(data);
        });
    });

    //进度处理
    uploader.on("uploadProgress", (file, percentage) => {
        store.dispatch(
            addProgress({
                title: language === "en" ? "Uploading " : "正在上传 " + file.name,
                id: file.id,
                percent: (percentage * 100).toFixed(1),
            })
        );
    });

    uploader.on("error", (type) => {
        switch (type) {
            case "Q_EXCEED_NUM_LIMIT":
                alert("一次只能上传一个文件");
                break;
            case "Q_EXCEED_SIZE_LIMIT":
                alert("文件大小超过限制");
                break;
            case "Q_TYPE_DENIED":
                alert("文件格式只能是video/mp4");
                break;
        }
    });
    //创建上传按钮
    uploader.addButton({
        id: "#picker",
        multiple: false,
    });
};

export function isImage(url) {
    return url?.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

export const extExtractor = (url) => url?.split("?")[0].split("#")[0].split(".").pop() || "";

export const money = (t) => {
    return t?.toFixed?.(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export function fixedStringify(obj) {
    return stringify(obj);
    // let cache = [];
    // const json_str = JSON.stringify(obj, function (key, value) {
    //     if (typeof value === 'object' && value !== null) {
    //         if (cache.indexOf(value) !== -1) {
    //             return;
    //         }
    //         cache.push(value);
    //     }
    //     return value;
    // });
    // cache = null;	//释放cache
    // return json_str;
}
