import {DialogContent, Grid, TextField} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import _ from "lodash";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import Select from "react-select/async";
import {components} from "utils/Select2Components";
import axios, {commonPrefix} from "../../../../../myaxios";
import ContactSocial from "./elements/ContactSocial";

const Step1 = (props) => {
    const {handleFormData} = props;
    const [city, setCity] = useState("");

    useEffect(() => {
        if (_.isObject(props.formData.address)) {
            const item = props.formData.address;
            setCity({
                value: props.formData.address,
                label: (
                    <span className={"flex items-center"}>
                        <strong className={"mr-2"}>{item.city}</strong>
                        <small className={"text-grey-dark"}>
                            {item.country}, {item.province}
                        </small>
                    </span>
                ),
            });
        }
    }, []);

    const searchCity = (inputValue, callback) => {
        axios
            .get(`${commonPrefix}/addressList?size=8`, {
                params: {
                    city: inputValue,
                    // country, province
                },
            })
            .then((res) => {
                const data = res.data.map((item) => ({
                    value: item,
                    label: (
                        <span className={"flex items-center"}>
                            <strong className={"mr-2"}>{item.city}</strong>
                            <small className={"text-grey-dark"}>
                                {item.country}, {item.province}
                            </small>
                        </span>
                    ),
                }));
                callback(data);
            });
    };

    const changeCity = (value) => {
        if (value) {
            handleFormData("address")({
                target: {
                    value: {
                        city: value.value.city,
                        country: value.value.country,
                        province: value.value.province,
                    },
                },
            });
            setCity(value);
        } else {
            setCity("");
            handleFormData("address")({
                target: {
                    value: {
                        city: "",
                        country: "",
                        province: "",
                    },
                },
            });
        }
    };

    const onChangeStreet = (e) => {
        handleFormData("address")({
            target: {
                value: {
                    ...props.formData.address,
                    street: e.target.value,
                },
            },
        });
    };

    return (
        <>
            <Divider className="mt-16" />
            <DialogContent className={"overflow-visible"}>
                {/*<Typography variant={"h6"}><Translate id={"market.Contact Information"}/></Typography>*/}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Translate>
                            {({translate}) => (
                                <Select
                                    textFieldProps={{
                                        width: "100%",
                                        margin: "normal",
                                        label: translate("market.City"),
                                    }}
                                    loadOptions={searchCity}
                                    defaultOptions
                                    margin={"normal"}
                                    // styles={selectStyles}
                                    components={components}
                                    value={city}
                                    onChange={changeCity}
                                    placeholder={translate("market.Search a city")}
                                    isClearable
                                />
                            )}
                        </Translate>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            margin={"normal"}
                            label={<Translate id={"market.street"} />}
                            onChange={onChangeStreet}
                            value={props.formData.address ? props.formData.address.street : ""}
                        />
                    </Grid>

                    <ContactSocial {...props} />
                </Grid>
            </DialogContent>
        </>
    );
};

export default Step1;
