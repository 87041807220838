import {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as fuseActions from "@fuseActions";
import {Translate, withLocalize} from "react-localize-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import Select2 from "react-select/async";
import DialogActions from "@material-ui/core/DialogActions";
import {components} from "utils/Select2Components";
import {searchContact} from "utils/utilities";
import axios, {crmPrefix} from "../../../../../myaxios";
import {withRouter} from "react-router-dom";
import * as Actions from "../store/actions";

class ChangeCompanyUserDialog extends Component {
    state = {
        users: [],
        user: null,
        loading: false,
    };

    handleUserChange = (value) => {
        if (value) this.setState({user: value, userId: value.value});
        else this.setState({user: "", userId: ""});
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.state.user) return;
        const data = {
            companyIds: [this.props.selectedCompany.id],
            userId: this.state.userId,
        };
        const link =
            this.props.location.pathname === "/marketing/management/1"
                ? `${crmPrefix}//companyChangeUserAdmin`
                : `${crmPrefix}/companyChangeUser`;

        axios.put(link, data).then(() => {
            this.closeDialog();
            if (this.props.location.pathname === "/marketing/management/1") {
                this.props.turnOff("companyDetail");
                this.props.getList({size: 10}, true, this.props.location.search);
            } else {
                this.props.getList();
                if (this.props.dialogs["companyDetail"]) this.props.turnOff("companyDetail");
                else this.props.history.push("/marketing/companies");
            }
        });
    };

    closeDialog = () => this.props.turnOff("companyChangeUser");

    render() {
        return (
            <Dialog
                maxWidth={"xs"}
                className={"overflow-visible"}
                open={!!this.props.dialogs["companyChangeUser"]}
                onClose={this.closeDialog}
                classes={{paper: "overflow-visible m-28", root: "overflow-visible"}}
                fullWidth>
                <div className={this.state.loading ? "loading" : null}>
                    <DialogTitle>
                        <Translate id={"market.Change company manager"} />
                    </DialogTitle>
                    <form onSubmit={this.onSubmit}>
                        <DialogContent className={"overflow-visible px-20 pb-12"}>
                            <Translate>
                                {({translate}) => (
                                    <Select2
                                        textFieldProps={{
                                            label: translate("market.New company manager"),
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                        defaultOptions
                                        loadOptions={searchContact}
                                        className={"mb-24 -mt-4"}
                                        // styles={selectStyles}
                                        components={components}
                                        value={this.state.user}
                                        onChange={this.handleUserChange}
                                        placeholder={translate("market.Search a user")}
                                        isClearable
                                    />
                                )}
                            </Translate>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={this.closeDialog}>
                                <Translate id="_.cancel" />
                            </Button>
                            <Button color="secondary" type={"submit"}>
                                <Translate id="_.save" />
                            </Button>
                        </DialogActions>
                    </form>
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getList: Actions.getList,
            turnOff: fuseActions.turnOff,
            turnOn: fuseActions.turnOn,
            startLoading: fuseActions.startLoading,
            finishLoading: fuseActions.finishLoading,
        },
        dispatch
    );
}

function mapStateToProps({fuse, marketing}) {
    return {
        dialogs: fuse.dialogs,
        selectedCompany: marketing.companies.item,
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ChangeCompanyUserDialog)));
