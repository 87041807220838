import {createContext, useContext} from "react";
import type {userType} from "../../../types";

export type TransferContextType = {
    add: (user: userType) => void;
    addAll: (users: userType[]) => void;
    remove: (user: userType) => void;
    removeAll: () => void;
    selected?: userType[];
    isSelected: (user: userType) => boolean;
};

export const TransferContext = createContext<TransferContextType>({
    add: () => {},
    remove: () => {},
    removeAll: () => {},
    addAll: () => {},
    selected: [],
    isSelected: () => false,
});

export const useTransferList = () => {
    return useContext(TransferContext);
};
