import {Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import axios, {crmPrefix} from "../../../../../myaxios";
import ItemHeader from "../../utils/ItemHeader";

class Header extends Component {
    state = {
        checkbox: false,
        itemChangesCount: 0,
        user: "",
        userId: "",
        anchorEl: null
    };

    handleFormData = () => (event) => {
        const value = event.target.value;
        const data = {
            companyId: this.props.sourceItem.id,
            statusKey: value
        };
        axios.put(`${crmPrefix}/companySetStatus`, data).then(() => {
            this.props.refresh();
            this.setState({anchorEl: null});
        });
    };

    render() {
        const {sourceItem} = this.props;
        return (
            <>
                <ItemHeader
                    {...this.props}
                    title={
                        <Typography variant={"h5"}>
                            <span className={"font-bold"}>{sourceItem.name}</span>
                        </Typography>
                    }
                    actions={
                        <>
                            <MyTooltip title={<Translate id={"market.last four digits of id"} />}>
                                <Chip size={"small"} color={"secondary"} label={sourceItem.id.slice(-4)} className={"mr-2"} />
                            </MyTooltip>
                        </>
                    }
                />
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: fuseActions.turnOn
        },
        dispatch
    );
}

export default withLocalize(connect(null, mapDispatchToProps)(Header));
