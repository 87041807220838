import axios, {filePrefix} from '../../../../../myaxios';
import {finishLoading, startLoading} from '@fuseActions';
import {loadedPercent} from './main.actions';
//
// id(pin): "11"
// name(pin): "Steam summer sale budget"
// type(pin): "spreadsheet"
// owner(pin): "Emily Bennett"
// size(pin): "505 Kb"
// modified(pin): "July 8, 2017"
// opened(pin): "July 8, 2017"
// created(pin): "July 8, 2017"
// extention(pin): ""
// location(pin): "My Files > Documents"
// offline(pin): true
// preview(pin): "assets/images/etc/sample-file-preview.jpg"

export const GET_FILES = "[FILE MANAGER APP] GET FILES";
export const UPLOAD_FILES = "[FILE MANAGER APP] UPLOAD_FILES";

export function getFiles(params, callback) {
    const request = axios.get(`${filePrefix}/files`, {
        params: params
    });

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_FILES,
                payload: response.data
            });
            if (typeof callback === "function") callback();
            dispatch(finishLoading());
        });
    };
}

export function uploadFile(files) {
    return (dispatch) => {
        const url = `${filePrefix}/file`;
        // const formData = new FormData();
        // Initial FormData
        const formData = new FormData();
        files.map(_file => {
            formData.append("file", _file);
        });
        const config = {
            headers: {
                "content-type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
                dispatch(loadedPercent(
                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                ));
                // console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        };
        const request = axios.post(url, formData, config);

        dispatch(startLoading());
        request.then((response) => {
            const files = {};
            response.data.map(_file => {
                files[_file.id] = {
                    ..._file
                    // id: _file.id,
                    // name: _file.originalName,
                    // type: _file.type === "image/jpeg" ? "image" : _file.type,
                    // owner: _file.user.username,
                    // size: _file.size,
                    // // modified: _file.originalName,
                    // // opened: _file.originalName,
                    // created: _file.uploadDate,
                    // extention: "",
                    // location: "My Files > Documents",
                    // // offline: true,
                    // preview: "http://git.signabc.com:8686/resize/270x250/" + _file.uri,
                };
            });
            dispatch({
                type: UPLOAD_FILES,
                files
            });
            dispatch(finishLoading());
        });
    };
}
