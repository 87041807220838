import {useState} from "react";
import {LabelChip, LabelsFormControl} from "../marketingTools";
import {Translate} from "react-localize-redux";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import axios, {crmPrefix} from "../../../../myaxios";
import Button from "@material-ui/core/Button";

const Labels = ({sourceItem, sourceType, data, refresh, canEdit, getList}) => {
    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState({});

    const handleFormData = name => event => {
        const t = {...formData};
        t[name] = event.target.value;
        setFormData(t);
    };

    const onEdit = () => {
        const data = {
            "source": sourceType,
            "sourceId": sourceItem.id,
            labelIds : formData.labels
        };
        axios.put(`${crmPrefix}/setLabels`, data).then(()=>{
            setEdit(false);
            refresh();
            getList && getList();
        });
    };

    return (
        <div className={"flex items-center"}>
            {edit ? <div className="w-224"><LabelsFormControl source={sourceType} formData={{labels: data}} handleFormData={handleFormData} /></div> : <>
            { data && data.length > 0 ? data.map((__, i) => <LabelChip  key={i} label={__} />) : <span className={"flex h-40  items-center"}><Translate id={"market.No labels"}/></span>}
            </>}
            {canEdit && <IconButton size={"small"} onClick={()=>setEdit(!edit)}><Icon>{!edit ? "edit" : "close"}</Icon></IconButton>}
            {edit && <Button size={"small"} onClick={onEdit}><Translate id={"_.save"}/></Button> }
        </div>
    );
};

export default Labels;
