import {useMemo} from "react";
import useProcessCommonStore from "./useProcessCommonStore";

function useCommonUtils() {
    const store = useProcessCommonStore();

    const current = useMemo(() => {
        return store.currentCategory;
    }, [store.currentCategory]);

    return {
        currentCategory: current,
        onChangeCurrentCategory: store.onChangeCurrentCategory,
    };
}

export default useCommonUtils;
