/**
 * @description - table 自适应模式
 */
export const TableAdapterModel = {
    /**
     * @description - table 自适应模式 - 点击在 row 下展示隐藏的 cell
     */
    expand: "[table] > row > expand",
    /**
     * @description - table 自适应模式 - 不隐藏任何的 cell ，沿 X 轴滑动查看被遮挡的 cell
     */
    xScroller: "[table] > xScroller",
};
