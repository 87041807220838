import { GET_ORDERS, SET_ORDERS_SEARCH_TEXT } from "../actions";

const initialState = {
    data      : [],
    searchText: ""
};

const ordersReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_ORDERS:
        {
            return {
                ...state,
                data: action.payload
            };
        }
        case SET_ORDERS_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        default:
        {
            return state;
        }
    }
};

export default ordersReducer;
