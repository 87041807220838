import axios from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";
import {MODULE_NAME, moduleLinklist} from "./VARIABLES";

export const GET_LIST = `[${MODULE_NAME}] GET_LIST`;
export const SELECT_ITEM   = `[${MODULE_NAME}] SELECT_ITEM`;
export const REFRESH_GRID_LIST_TYPE = "[REFRESH_GRID_LIST_TYPE]";
export const SELECT_ITEM_FOR_DELETE_WORK_HISTORY   = `[${MODULE_NAME}] SELECT_ITEM_FOR_DELETE_WORK_HISTORY  `;

export const SELECT_TYPE   = `[${MODULE_NAME}] SELECT_TYPE`;
export const CLEAR_ITEM = `[${MODULE_NAME}] CLEAR_ITEM`;

// export const OPEN_COMPANYSELECTOR_TYPE = `[${MODULE_NAME}] OPEN_COMPANYSELECTOR_TYPE`;


export function getList(params) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(moduleLinklist,{params}).then((res) => {
            if(res.data)
                dispatch({
                    type: GET_LIST,
                    payload: res.data
                });
            dispatch(finishLoading());
        });
    };
}


export function selectGridlistType(type) {
    return {
        type: REFRESH_GRID_LIST_TYPE,
        payload: type
    };
}


// export function openCompanySelectorType(type) {
//     return {
//         type: OPEN_COMPANYSELECTOR_TYPE,
//         payload: type
//     }
// }


export function selectItem(data) {
    return {
        type: SELECT_ITEM,
        data
    };
}

export function selectWorkHistoryItem(data) {
    return {
        type: SELECT_ITEM_FOR_DELETE_WORK_HISTORY,
        data
    };
}





export function selectType(data) {
    return {
        type: SELECT_TYPE,
        data
    };
}

export function clearItem() {
    return {
        type: CLEAR_ITEM,
    };
}
