import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import type {FC} from "react";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import type {AttachmentType} from "../../types";
import {DataGridReadonly} from "./DataGrid/extra/DataGridReadonly";
import type {DGColType} from "./DataGrid/types";
import {MyTooltip} from "./MyTooltip";

type Props = {
    data?: AttachmentType[];
};

const cols: DGColType[] = [
    {
        id: "visitUrl",
        viewType: "thumb",
        minWidth: 100,
        label: "_.source",
        // itemView: (item) => (
        //     <div className={"flex justify-between items-center w-full hider"} data-cy={"file-item-title"}>
        //         <span className="max-w-24 block  ">
        //             {isImage(item.visitUrl) ? <Thumbnail size={"24"} src={item.visitUrl} /> : <FileThumb file={item} />}
        //             {/*<FileIcon extension={extExtractor(item.visitUrl)} {...defaultStyles[extExtractor(item.visitUrl)]} />*/}
        //         </span>
        //     </div>
        // ),
    },
    {
        sort: true,
        id: "originalName",
        label: "_.name",
        minWidth: 300,
        viewType: "text",
        // itemView: (item) => (
        //     <div className={"flex justify-between items-center w-full hider"} data-cy={"file-item-title"}>
        //         <a
        //             className={"cursor-pointer flex items-center"}
        //             onClick={(e) => {
        //                 e.preventDefault();
        //                 createDownloadLink(item.visitUrl, item.originalName, item.id);
        //             }}>
        //             <span className={"block"}>
        //                 <span className={"font-bold"}>{item.originalName} </span>
        //             </span>
        //         </a>
        //
        //         <IconButton size={"small"} className={"hid"}>
        //             <Icon>edit</Icon>
        //         </IconButton>
        //
        //         {/*)}*/}
        //     </div>
        // ),
    },
    {
        sort: true,
        id: "size",
        viewType: "number",
        label: "_.size",
        // itemView: (item) => <>{formatSizeUnits(item.size, 1)}</>,
    },
    {
        sort: true,
        id: "uploadDate",
        viewType: "date",
        label: "_.uploadDate",
        // itemView: (item) => <>{item.uploadDate}</>,
    },
    {
        id: "user",
        viewType: "user",
        className: "overflow-hidden",
        label: "_.user",
        // itemView: (item) => <UserView dense noUsername user={item.user} />,
    },
    {
        id: "type",
        viewType: "singleOption",
        label: "_.type",
        // itemView: (item) => <div style={{color: styleDefObj[mime.extension(item.type)]?.labelColor}}>{item.type}</div>,
    },
    {
        id: "timestamp",
        viewType: "date",
        // itemView: (item) => <LocalTimeStamp className={"text-gray-400"} format={dateFormatWithTime} datetime={item.uploadDate} />,
        label: "_.date",
        sort: true,
    },
];

// const columns: GridColDef[] = [
//     {
//         field: "originalName",
//         headerName: "originalName",
//         minWidth: 150,
//         type: "string",
//         filterable: true,
//         editable: true,
//         flex: 1,
//         renderCell: (params) => (
//             <div className={"flex items-center"} data-cy={"file-item-title"}>
//                 <a
//                     className={"cursor-pointer flex items-center"}
//                     onClick={(e) => {
//                         e.preventDefault();
//                         createDownloadLink(params.getValue(params.id, "visitUrl"), params.value, params.getValue(params.id, "id"));
//                     }}>
//                     <span className="max-w-32 block max-h-52  ">
//                         {isImage(params.getValue(params.id, "visitUrl")) ? (
//                             <Thumbnail size={"32"} src={params.getValue(params.id, "visitUrl") as string} />
//                         ) : (
//                             <FileIcon
//                                 extension={extExtractor(params.getValue(params.id, "visitUrl"))}
//                                 {...defaultStyles[extExtractor(params.getValue(params.id, "visitUrl"))]}
//                             />
//                         )}
//                     </span>
//
//                     <span className={"ml-8  truncate font-bold"}>{params.value as string}</span>
//                     {/*<Typography variant={"caption"} className={"ml-8 text-gray-500"}>*/}
//                     {/*    {item.description}*/}
//                     {/*</Typography>*/}
//                 </a>
//
//                 {/*)}*/}
//             </div>
//         ),
//     },
//     {
//         type: "number",
//         filterable: true,
//         field: "size",
//         headerName: "size",
//         renderCell: (item) => <>{formatSizeUnits(item.value)}</>,
//     },
//     {
//         filterable: true,
//         field: "user",
//         headerName: "user",
//         renderCell: (item) => <UserView dense noUsername user={item.getValue(item.id, "user") as userType} />,
//     },
//     {
//         filterable: true,
//         field: "uploadDate",
//         headerName: "uploadDate",
//         type: "date",
//         renderCell: (params) => <LocalTimeStamp className={"text-gray-400"} format={dateFormatWithTime} datetime={params.value} />,
//     },
// ];

// const rows = [
//     {lastName: "Snow", firstName: "Jon", age: 35},
//     {lastName: "Lannister", firstName: "Cersei", age: 42},
//     {lastName: "Lannister", firstName: "Jaime", age: 45},
//     {lastName: "Stark", firstName: "Arya", age: 16},
//     {lastName: "Targaryen", firstName: "Daenerys", age: null},
//     {lastName: "Melisandre", firstName: null, age: 150},
//     {lastName: "Clifford", firstName: "Ferrara", age: 44},
//     {lastName: "Frances", firstName: "Rossini", age: 36},
//     {lastName: "Roxie", firstName: "Harvey", age: 65},
// ];

export const FilesView: FC<Props> = ({data}: Props) => {
    // const [loading, setLoading] = useState(false);
    const [view, setView] = useState("table");

    // useEffect(() => {}, []);

    return (
        <>
            <DialogTitle>
                <div className="flex justify-between items-center">
                    <div>
                        <Translate id="wps.files" /> ({data?.length})
                    </div>
                    <ButtonGroup color="primary" size={"large"}>
                        <MyTooltip title={"Table view"}>
                            <Button
                                data-cy={"view-table-btn"}
                                variant={view === "table" ? "contained" : "outlined"}
                                onClick={() => {
                                    setView("table");
                                }}
                                className="w-20 min-w-20">
                                <Icon>view_week</Icon>
                            </Button>
                        </MyTooltip>
                        <MyTooltip title={"List view"}>
                            <Button
                                data-cy={"view-list-btn"}
                                variant={view === "list" ? "contained" : "outlined"}
                                onClick={() => {
                                    setView("list");
                                }}
                                className="w-20 min-w-20">
                                <Icon>view_list</Icon>
                            </Button>
                        </MyTooltip>
                    </ButtonGroup>
                </div>
            </DialogTitle>

            <DataGridReadonly id={"wpFiles"} defaultCols={cols} defaultRows={data} />

            {/*<TableView<AttachmentType> columns={colTitle} content={data} name={"WPAttachmentsDialog"} />*/}
        </>
    );
};
