import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_DECISION_SINGLE = "[GET_DECISION_SINGLE]";
export const RESET_DECISION_SINGLE = "[RESET_DECISION_SINGLE]";

export function getDecisionSingle(id, callback, catchError) {
    const request = axios.get(`${flowPrefix}/decisionsAdmin/${id}`);
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_DECISION_SINGLE,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}


export function resetDecisionSingle() {
    return {
        type: RESET_DECISION_SINGLE
    };
}



