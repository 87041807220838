export const CLOSE_PROGRESS = "[PROGRESS] CLOSE";
export const SHOW_PROGRESS = "[PROGRESS] SHOW";
export const ADD_PROGRESS = "[PROGRESS] ADD";

export function finishProgress() {
    return {
        type: CLOSE_PROGRESS
    };
}

export function startProgress() {
    return {
        type: SHOW_PROGRESS,
    };
}

export function addProgress(file = {
    percent: 0, title: "Start ", disableClose: false
}) {
    return {
        type: ADD_PROGRESS,
        file
    };
}

