import type {ReactNode} from "react";
import {useDispatch, useSelector} from "react-redux";
import { clearChecked } from "@fuseActions";
import {Translate} from "react-localize-redux";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

const SelectBar = ({name,  actions, noTranslate}: {
    noTranslate?: boolean;
    name: string;
    actions?: ReactNode;
}) => {
    const dispatch = useDispatch();
    const checked = useSelector(({fuse}) => fuse.checked);

    return (
        <Card square className="bg-blue-lightest md:flex justify-between items-center w-full py-8 px-12 ">
            <div className={"flex items-center"}>
                <Translate>
                    {({translate}: any) =>
                        <Typography variant={"subtitle2"} className={"mr-12"}>
                            {/*<strong>{checked[name].length}</strong> {name} selected!*/}
                            {noTranslate ? (checked[name] ? checked[name].length : 0) :
                                translate("_.selected count with params", {
                                    name: translate(`_.${name}`),
                                    count: checked[name] ? checked[name].length : 0
                                })}
                        </Typography>
                    }
                </Translate>
                {actions}
            </div>
            <div>
                <Button color={"default"} className={"mr-4"} onClick={() => dispatch(clearChecked(name))}>{<Translate
                    id={"_.Deselect all"}/>}</Button>
            </div>
        </Card>
    );

};


export default SelectBar;
