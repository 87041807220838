import {pmsPrefix} from "myaxios";
import {useSelector} from "react-redux";
import useSWR from "swr";

export function useIssue(id?: string) {
    const {data, error, mutate, isLoading, isValidating} = useSWR(id ? `${pmsPrefix}/projectIssue/${id}` : null);

    const userId = useSelector(({user}) => user.profile?.id);

    const isItPassed = data ? +new Date() > data.timestamp + 300000 : false;
    const isOwner = data ? data.projectIssueParticipants?.[0].user.id === userId : false;
    const isAssignee = data ? userId === data?.assignee?.user?.id : false;
    const canEdit = data ? (isOwner || isAssignee) && data["currentStatus"] !== "Close" : false;
    return {
        issue: data,
        isLoading,
        isError: error,
        refresh: mutate,
        isAssignee,
        isOwner,
        canEdit,
        isItPassed,
        isValidating
    };
}
