import {Chip, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {LocalTimeStamp, MyTooltip} from "@ui";
import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";

SubItem.propTypes = {
    item: PropTypes.object
};

function SubItem(props) {

    const {item} = props;

    return (
        <ListItem
            divider
        >
            <ListItemText
                className={"text-base font-bold"}
                primary={
                    <MyTooltip title={<Translate id={"market.fee"} />}>
                            <span className={"text-xl capitalize"}>
                                 {item.fee}
                            </span>
                    </MyTooltip>
                }
                secondary={
                    <MyTooltip title={<Translate id={"market.note"} />}>
                            <span className={"text-xl capitalize"}>
                                 {item.note}
                            </span>
                    </MyTooltip>
                }
            />
            <ListItemSecondaryAction>
                <Grid container spacing={1} direction="column"
                      justify="center"
                      alignItems="flex-end">
                    <Grid item xs={12}>
                        <MyTooltip title={<Translate id={"market.archive date"} />}>
                            <Chip
                                label={<LocalTimeStamp datetime={item.timestamp} />}
                                size={"small"}
                                variant={"default"} />
                        </MyTooltip>

                    </Grid>
                    <Grid item xs={12}>
                        <MyTooltip title={<Translate id={"market.innerMeetingId"} />}>
                            <Chip
                                label={item.innerMeetingId}
                                size={"small"}
                                variant={"default"} />
                        </MyTooltip>

                    </Grid>
                </Grid>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default SubItem;
