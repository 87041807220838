import {Icon, IconButton, ImageList, ImageListItem, ImageListItemBar, makeStyles, Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {LocalTimeStamp, MyAvatar, MyTooltip, NameLabel} from "@ui";
import {useMemoizedFn} from "ahooks";
import _ from "lodash";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useGlobalDialog} from "../../../../../@fuse/hooks";
import {useEXManageStore} from "../../state/useEXManageStore";
import * as Actions from "../../store/actions";
import {FULL_SCREEN_IMAGE_DIALOG} from "../constent";
import {getUniqueKey} from "../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper
    },
    imageList: {
        width: "80vw",
        height: "auto",
        // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
        transform: "translateZ(0)",
        overflowX: "hidden"
    },
    titleBar: {
        background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
    },
    icon: {
        color: "white"
    }
}));

ImagesDisplayView.propTypes = {
    images: PropTypes.array.isRequired,
    title: PropTypes.any.isRequired
};

function ImagesDisplayView(props) {
    const dispatch = useDispatch();

    const {openDialog} = useGlobalDialog();

    const [imageDatas, setImageDatas] = useState([]);

    const [rowHeight, setRowHeight] = useState(180);

    const onChangeGallery = useEXManageStore((state) => state.onChangeGallery);
    const onChangeImages = useEXManageStore((state) => state.onChangeImages);
    const onChangeCurrentIndex = useEXManageStore((state) => state.onChangeCurrentIndex);

    const hasWindow = typeof window !== "undefined";

    const windowWidth = hasWindow ? window.innerWidth : 0;

    const windowHeight = hasWindow ? window.innerHeight : 0;

    const {images, title} = props;

    const classes = useStyles();

    useEffect(() => {
        if (windowHeight !== 0) {
            const h = _.toNumber(windowHeight * 0.33);
            setRowHeight(h);
        }
        if (images && images.length > 0) {
            const tw = _.toInteger(windowWidth * 0.66);
            const th = _.toInteger(windowHeight * 0.66);
            // const tSrc = `${item.photo}/crop/${tw}x${th}`;
            setImageDatas(
                images.map((obj) => {
                    return {...obj, photo: `${obj.photo}/crop/${tw}x${th}`};
                })
            );
        }
    }, [images, windowHeight, windowWidth]);

    const showImage =
        ({checkIns, index}) =>
            (e) => {
                e.stopPropagation();
                const params = {
                    checkIns: checkIns,
                    currentIndex: index
                };
                dispatch(Actions.selectCheckInPhotos(params));
                openDialog(FULL_SCREEN_IMAGE_DIALOG);
            };

    const handleShowImage = useMemoizedFn(({checkIns, index}) => (e) => {
        e.stopPropagation();
        let im = [];
        let i = 0,
            length = checkIns.length;
        for (i; i < length; i++) {
            let a = checkIns[i];
            const b = {};
            if (!a) return;
            b.original = a.photo + "/resize/800x800";
            b.fullscreen = a.photo;
            b.thumbnail = a.photo + "/resize/100x100";
            b.description = (
                <div className={"text-left"}>
                    <Typography>{a.date}</Typography>
                    <div className="flex flex-row items-center ">
                        <MyAvatar user={a.user} className={"mr-3"} />
                        <div className="flex flex-col">
                            <div>
                                <strong>
                                    <NameLabel user={a.user} />
                                </strong>
                            </div>
                            <div>
                                <small className={"mr-2"}>@{a.user.username}</small>
                            </div>
                        </div>
                        {/*{a.user.id === userId &&*/}
                        {/*    <IconButton className={"text-white ml-20 -mr-12"} onClick={() => {*/}
                        {/*        setSelectedAttachment(a);*/}
                        {/*        dispatch(fuseActions.turnOn("deleteAttachment"));*/}
                        {/*    }}><Icon>delete</Icon></IconButton>*/}
                        {/*}*/}
                        {/*https://oss.signabc.com/test/a1.jpg?name=*/}
                        {/*<IconButton component={"a"} className={"text-white ml-20 -mr-12"} href={a.visitUrl+"?name="+a.originalName} download={a.originalName}><Icon>get_app</Icon></IconButton>*/}
                    </div>
                </div>
            );
            b.originalTitle = a.date;
            im.push(b);
        }

        onChangeImages(im).then(() => {
            console.log("onChangeImages resolve");
            onChangeCurrentIndex(index).then(() => {
                console.log("onChangeCurrentIndex resolve");
                onChangeGallery(true).then(() => {
                    console.log("onChangeGallery resolve");
                });
            });
        });
        // checkIns.forEach((a)=>{
        //     a.original = a.photo + "/resize/800x800";
        //     a.fullscreen = a.photo;
        //     a.thumbnail = a.photo + "/resize/100x100";
        //     a.description =
        //         <div className={"text-left"}>
        //             <Typography>{a.date}</Typography>
        //             <div className="flex flex-row items-center ">
        //                 <MyAvatar user={a.user} className={"mr-3"} />
        //                 <div className="flex flex-col">
        //                     <div>
        //                         <strong>
        //                             <NameLabel user={a.user}/>
        //                         </strong>
        //                     </div>
        //                     <div>
        //                         <small className={"mr-2"}>@{a.user.username}</small>
        //                     </div>
        //                 </div>
        //                 {/*{a.user.id === userId &&*/}
        //                 {/*    <IconButton className={"text-white ml-20 -mr-12"} onClick={() => {*/}
        //                 {/*        setSelectedAttachment(a);*/}
        //                 {/*        dispatch(fuseActions.turnOn("deleteAttachment"));*/}
        //                 {/*    }}><Icon>delete</Icon></IconButton>*/}
        //                 {/*}*/}
        //                 {/*https://oss.signabc.com/test/a1.jpg?name=*/}
        //                 {/*<IconButton component={"a"} className={"text-white ml-20 -mr-12"} href={a.visitUrl+"?name="+a.originalName} download={a.originalName}><Icon>get_app</Icon></IconButton>*/}
        //             </div>
        //         </div>;
        //     a.originalTitle = a.date;
        //     im.push(a)
        // })
    });

    return (
        <div className="w-full mt-0 pt-0 pb-2 px-8  overflow-y-scroll overflow-x-hidden">
            {images.length > 0 ? (
                <>
                    <Typography className="MuiTypography-root MuiTypography-body1 ml-10 pl-4 mb-3 text-grey-darkest uppercase font-black text-md">
                        <pre>
                            {title} ({imageDatas.length})
                        </pre>
                    </Typography>

                    {/*<ImageGallery items={images} />*/}

                    <div className={classes.root}>
                        <ImageList rowHeight={rowHeight} cols={3} gap={1} className={classes.imageList}>
                            {imageDatas.map((item, i) => (
                                <ImageListItem key={`${i}-${getUniqueKey()}`} cols={1} rows={1}>
                                    <MyTooltip title={<Translate id={"exm.Click to view larger image"} />}>
                                        <img
                                            onClick={handleShowImage({checkIns: images, index: i})}
                                            className="object-center"
                                            src={item.photo}
                                            alt={"P"}
                                        />
                                    </MyTooltip>
                                    <ImageListItemBar
                                        title={
                                            <div className={"flex flex-row my-1"}>
                                                <NameLabel strongTag user={item.user} />
                                                {item.phoneNumber && (
                                                    <MyTooltip
                                                        placement={"top"}
                                                        title={<Translate id={"exm.phoneNumber"} />}>
                                                        <Chip
                                                            icon={<Icon color={"secondary"}>phone</Icon>}
                                                            className={"ml-3"}
                                                            size={"small"}
                                                            label={
                                                                <Typography component={"span"} className={"text-xs"}>
                                                                    {item.phoneNumber}
                                                                </Typography>
                                                            }
                                                        />
                                                    </MyTooltip>
                                                )}

                                                <MyTooltip
                                                    placement={"top"}
                                                    title={<Translate id={"exm.uploadDate"} />}>
                                                    <Chip
                                                        icon={<Icon color={"secondary"}>date_range</Icon>}
                                                        className={"ml-3"}
                                                        size={"small"}
                                                        label={
                                                            <Typography component={"span"} className={"text-xs my-3"}>
                                                                <LocalTimeStamp
                                                                    datetime={item.createTime}
                                                                    format={"YYYY-MM-DD hh:mm"}
                                                                />
                                                            </Typography>
                                                        }
                                                    />
                                                </MyTooltip>
                                            </div>
                                        }
                                        subtitle={
                                            <div className="flex justify-start">
                                                {item.jobs.map((job) => (
                                                    <MyTooltip title={<Translate id={"exm.Type of work"} />}>
                                                        <Chip
                                                            label={job}
                                                            size={"small"}
                                                            color={"secondary"}
                                                            className={"mr-1 my-3"}
                                                        />
                                                    </MyTooltip>
                                                ))}
                                            </div>
                                        }
                                    />

                                    <ImageListItemBar
                                        position="top"
                                        actionIcon={
                                            <MyTooltip title={<Translate id={"exm.Show large image"} />}>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={handleShowImage({checkIns: images, index: i})}>
                                                    <Icon fontSize="large">{"zoom_in"}</Icon>
                                                </IconButton>
                                            </MyTooltip>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>
                </>
            ) : (
                <button className="w-full text-center font-bold mt-1 py-4 block text-grey-dark ">
                    {<Translate id={"_.No images information at this time"} />}
                </button>
            )}
        </div>
    );
}

export default ImagesDisplayView;
