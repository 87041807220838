import {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Translate, withLocalize} from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import axios, {flowPrefix} from "../../../../../myaxios";
import * as Actions from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import Chip from "@material-ui/core/Chip";
import {useCloseDialog, useShowMessage} from "@fuse/hooks";

function DefinitionDialog(props) {
    const dispatch = useDispatch();

    const {id} = useParams();

    const closeDialog = useCloseDialog();

    const {showMessage} = useShowMessage();

    const search = useSelector(({fuse}) => fuse.search);

    const selectedDefinition = useSelector(({process}) => process.definition_list.selectedDefinition);

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const [category, setCategory] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        selectedDefinition && setCategory(selectedDefinition.category);
    }, []);

    function handleCloseDialog() {
        closeDialog("DefinitionDialog");
    }

    function handleUpload() {
        setLoading(true);
        const params = {
            id: selectedDefinition.id,
            category: category,
        };
        axios
            .put(`${flowPrefix}/processDefinition`, params)
            .then(() => {
                setLoading(false);
                const params = {
                    variant: "success",
                    message: "message.Definition edit successfully!",
                };
                showMessage(params);
                if (id) {
                    dispatch(Actions.getProcessDefinitionSingle(id));
                } else {
                    dispatch(Actions.getProcessDefinitions(search));
                }
                handleCloseDialog();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    function canSubmit() {
        return category.trim() === "";
    }

    return (
        <Dialog fullWidth maxWidth={"xs"} open={!!dialogs["DefinitionDialog"]} onClose={handleCloseDialog}>
            <div className={loading ? "loading" : null}>
                <Typography variant="h6" className={"ml-20 mt-20"} color="inherit">
                    <Translate id={"process.Edit definition"} />

                    <Chip label={selectedDefinition.name} className={"ml-3"} color={"primary"} />
                </Typography>

                <DialogContent className="flex flex-col">
                    <TextField
                        id="standard-category"
                        fullWidth
                        label={<Translate id={"process.category"} />}
                        value={category}
                        onChange={handleChange}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        <Translate id="process.Cancel" />
                    </Button>
                    <Button disabled={canSubmit()} onClick={handleUpload} color="primary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(DefinitionDialog);
