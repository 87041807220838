import * as Actions from "../actions";
import {cloneDeep} from "lodash";
const initialState = {
    contractsObject: [],
    selectedContract: null,
    selectedContractsForAgent: []
};

const contractsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_CONTRACTS_LIST:{
            const tobj = cloneDeep(state);
            tobj.contractsObject = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     contractsObject: action.payload
            // };
        }
        case Actions.SELECT_A_CONTRACT:{
            const tobj = cloneDeep(state);
            tobj.selectedContract = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedContract: action.payload
            // };
        }
        case Actions.SELECT_CONTRACTS_FOR_CHANGE_AGENT:{
            // console.log('selectContractsForAgent  传值到  reducer ', action.payload);
            const tobj = cloneDeep(state);
            tobj.selectedContractsForAgent = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedContractsForAgent: [...action.payload]
            // };
        }
        default:{
            return state;
        }
    }
};

export default contractsReducer;
