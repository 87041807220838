import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_PROCESSDEFINES_LIST = "[GET_PROCESSDEFINES_LIST]";
export const SELECT_A_PROCESSDEFINITION = "[SELECT_A_PROCESSDEFINITION]";

export function getProcessDefinitions(params, callback) {

    const request = axios.get(`${flowPrefix}/processDefinitions`, {
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_PROCESSDEFINES_LIST,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function selectADefinition(_definition) {
    return {
        type: SELECT_A_PROCESSDEFINITION,
        payload: _definition
    };
}
