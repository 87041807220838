import produce from "immer";

export const createImageGallerySlice = (set) => ({
    images: [],
    onChangeImages: (t) => {
        // set({ images: t  })
        return new Promise((resolve,reject)=>{
            set(
                produce((state) => {
                    state.images = t;
                })
            );
             resolve();
        });
    },
    currentIndex: 0,
    onChangeCurrentIndex: (t) => {
        // set({ currentIndex: t })
        return new Promise((resolve,reject)=>{
            set(
                produce((state) => {
                    state.currentIndex = t;
                })
            );
            resolve();
        });
    },
    openGallery: false,
    onChangeGallery: (t) => {
        // set({ openGallery: t })
        return new Promise((resolve,reject)=>{
            set(
                produce((state) => {
                    state.openGallery = t;
                })
            );
            resolve();
        });
    },
});
