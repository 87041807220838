import {lazy} from "react";

export const RedPacketConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/redPackets/type/:type",
            component: lazy(() => import("./my-red-packets/MyRedPackets"))
        },
        {
            path     : "/redPackets/list/:list",
            component: lazy(() =>
                import("./my-red-packets/MyRedPackets")
            )
        },
        {
            path     : "/redPackets",
            component: lazy(() =>
                import("./my-red-packets/MyRedPackets")
            )
        },
        {
            path     : "/red-packet/relatives/status/:status",
            component: lazy(() =>
                import("./Relatives/RelativesPage")
            )
        },
        {
            path     : "/red-packet/relatives",
            component: lazy(() =>
                import("./Relatives/RelativesPage")
            )
        },
        {
            path     : "/red-packet/manager/rank/yearMonth",
            component: lazy(() =>
                import("./red-packet-manager/RedPacketManager")
            )
        },
        {
            path     : "/red-packet/manager/rank-stats",
            component: lazy(() =>
                import("./red-packet-manager/RedPacketManager")
            )
        },
        {
            path     : "/red-packet/manager/rank-model",
            component: lazy(() =>
                import("./red-packet-manager/RedPacketManager")
            )
        }
    ]
};
