import {Icon} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";

const ZoomControlButtons = () => {
    const [fontSize, setFontSize] = useState(9);
    useEffect(() => {
        const z = localStorage.getItem("zoom");
        if (z) {
            setFontSize(+z);
            document.getElementById("html").setAttribute("style", `font-size: ${z}px`);
        }
    }, []);

    const zoom = (type) => () => {
        const t = type ? fontSize + 1 : fontSize - 1;
        setFontSize(t);
        localStorage.setItem("zoom", `${t}`);
        document.getElementById("html").setAttribute("style", `font-size: ${t}px`);
    };

    const resetZoom = () => {
        setFontSize(9);
        localStorage.setItem("zoom", "9");
        document.getElementById("html").setAttribute("style", "font-size: 9px");
    };

    return (
        <div className={"items-center hidden md:flex border-r-1 border-l-1 h-44"} data-tut="zoom_in_and_out">
            <Button className={"h-44"} disabled={fontSize === 9} size={"small"} style={{borderRadius: 0}} onClick={resetZoom}>
                {<Translate id={"_.Reset"} />}
            </Button>
            <IconButton className={"h-44"} style={{borderRadius: 0}} onClick={zoom(0)} disabled={fontSize < 8}>
                <Icon>zoom_out</Icon>
            </IconButton>
            <IconButton className={"h-44"} style={{borderRadius: 0}} onClick={zoom(1)} disabled={fontSize > 11}>
                <Icon>zoom_in</Icon>
            </IconButton>
        </div>
    );
};

export default ZoomControlButtons;
