import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "react-select/async";
import {searchContact} from "utils/utilities";
import {components} from "utils/Select2Components";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import * as fuseActions from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";
import * as Actions from "../../store/actions";
import {Chip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {searchInstanceUsers} from "../../utils/utils";

function TransferSingleForInstance() {
    const dispatch = useDispatch();

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const search = useSelector(({fuse}) => fuse.search);

    const transferContextType = useSelector(({process}) => process.instances_list.transferContextType);

    const selectedInstance = useSelector(({process}) => process.instances_list.selectedInstance);

    const definition = useSelector(({process}) => process.definition.definitionObject);

    const decision = useSelector(({process}) => process.decision.decisionObject);

    const [originalUser, setOriginalUser] = useState({value: "", label: ""});

    const [user, setUser] = useState({value: "", label: ""});

    const [userId, setUserId] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.debug("TransferSingleForInstance > useEffect > selectedInstance", selectedInstance);
    }, []);

    function changeOriginUser(value) {
        if (value) {
            setOriginalUser({
                ...user,
                value: value.value,
                label: value.label,
            });
        } else {
            setOriginalUser("");
        }
    }

    function changeUser(value) {
        if (value) {
            setUser({
                ...user,
                value: value.value,
                label: value.label,
            });
            setUserId(value.value);
        } else {
            setUserId("");
            setUser("");
        }
    }

    function canSunmit() {
        return (
            user.value === "" ||
            false ||
            !user.value ||
            originalUser.value === "" ||
            false ||
            !originalUser.value ||
            loading
        );
    }

    function handleClose() {
        dispatch(fuseActions.turnOff("TransferSingleForInstance"));
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const params = {
            processInstanceId: selectedInstance.id,
            originalUserId: originalUser.value,
            targetUserId: user.value,
        };
        setLoading(true);
        axios
            .put(`${flowPrefix}/changeInstanceUser`, params)
            .then((res) => {
                setLoading(false);
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.instance Transferred!",
                    })
                );
                if (transferContextType === "instances_list") {
                    dispatch(Actions.getProcessInstances(search));
                } else if (transferContextType === "definition_detail") {
                    const params = {processDefinitionId: definition.id};
                    dispatch(Actions.getProcessInstances(search));
                } else if (transferContextType === "decision_detail") {
                    const params = {decisionId: decision.id};
                    dispatch(Actions.getProcessInstances(search));
                } else {
                    dispatch(Actions.getInstanceSingle(selectedInstance.id));
                }
                handleClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"xs"}
            open={!!dialogs["TransferSingleForInstance"]}
            onClose={handleClose}
            classes={{paper: "overflow-visible", root: "overflow-visible"}}
            className={"overflow-visible"}
            aria-labelledby="responsive-dialog-title"
        >
            {
                <Translate>
                    {({translate}) => (
                        <DialogTitle id="responsive-dialog-title">
                            {
                                <>
                                    {translate("process.Instance Transfer")}
                                    <Chip
                                        className={"ml-3"}
                                        label={selectedInstance.name}
                                        color={"secondary"}
                                        size="small"
                                    />
                                </>
                            }
                        </DialogTitle>
                    )}
                </Translate>
            }

            <DialogContent className={"overflow-visible"}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Translate>
                            {({translate}) => (
                                <Select
                                    required
                                    textFieldProps={{
                                        margin: "normal",
                                        required: true,
                                        label: translate("process.original user"),
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    }}
                                    margin={"normal"}
                                    loadOptions={searchInstanceUsers(selectedInstance)}
                                    defaultOptions
                                    // styles={selectStyles}
                                    components={components}
                                    value={originalUser}
                                    onChange={changeOriginUser}
                                    placeholder={translate("process.Search a user")}
                                    isClearable
                                />
                            )}
                        </Translate>
                    </Grid>

                    <Grid item xs={6}>
                        <Translate>
                            {({translate}) => (
                                <Select
                                    required
                                    textFieldProps={{
                                        margin: "normal",
                                        required: true,
                                        label: translate("process.target user"),
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    }}
                                    margin={"normal"}
                                    loadOptions={searchContact}
                                    defaultOptions
                                    // styles={selectStyles}
                                    components={components}
                                    value={user}
                                    onChange={changeUser}
                                    placeholder={translate("process.Search a user")}
                                    isClearable
                                />
                            )}
                        </Translate>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{<Translate id={"_.cancel"} />}</Button>
                <Button disabled={canSunmit()} onClick={handleSubmit} color="secondary">
                    {<Translate id={"_.sure"} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withLocalize(TransferSingleForInstance);
