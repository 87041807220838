import {Button, Dialog, DialogContent, Icon, IconButton, InputAdornment, TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import * as fuseActions from "app/store/actions";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useInstanceStore} from "../instances/state/useInstanceStore";
import {useUserTaskStore} from "../user-tasks/state";
import NewUserDialog from "./NewUserDialog";

const initState = {
    name: "",
    type: "",
    value: ""
};

function NewInstanceUserTaskVarDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.instanceUserTaskVarDialog
    });

    const userActions = useMUIDialog({
        registry: GDialogRegistries.definitionUserDialog
    });

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [state, setState] = useState(initState);

    const instanceVariable = useInstanceStore((state) => state.instanceVariable);

    const onChangeInstanceVariable = useInstanceStore((state) => state.onChangeInstanceVariable);

    const userTaskVariable = useUserTaskStore((state) => state.userTaskVariable);

    const onChangeUserTaskVariable = useUserTaskStore((state) => state.onChangeUserTaskVariable);

    useEffect(() => {
        return () => {
            onChangeInstanceVariable(null);
            onChangeUserTaskVariable(null);
        };
    }, []);

    useEffect(() => {
        initParams();
    }, [instanceVariable, userTaskVariable]);

    const initParams = useMemoizedFn(() => {
        if (instanceVariable?.variable) {
            setState({
                name: instanceVariable?.variable?.name,
                type: instanceVariable?.variable?.type,
                value: instanceVariable?.variable?.value
            });
        }

        if (userTaskVariable?.variable) {
            setState({
                name: userTaskVariable?.variable?.name,
                type: instanceVariable?.variable?.type,
                value: userTaskVariable?.variable?.value
            });
        }
    });

    const handleName = useMemoizedFn((e) => {
        setState({
            ...state,
            name: e.target.value
        });
    });

    const handleValue = useMemoizedFn((e) => {
        setState({
            ...state,
            value: e.target.value
        });
    });

    const handleDelete = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        instanceVariable ? createVariablesForInstance() : createVariablesForUserTask();
    });

    const createVariablesForInstance = () => {
        const params = {
            name: state.name,
            value:  state.type === "object" ? [state.value]  : state.value
        };
        axios
        .post(`${flowPrefix}/process-instance/${instanceVariable?.instance?.id}/variables`, params)
        .then((response) => {
            finshParams?.onFinish();
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Create variables success!"
                })
            );
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const createVariablesForUserTask = () => {
        const params = {
            name: state.name,
            value: state.value
        };
        axios
        .post(`${flowPrefix}/task/${userTaskVariable?.userTask?.id}/variables`, {params})
        .then((response) => {
            finshParams?.onFinish();
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Create variables success!"
                })
            );
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleSearchUser = useMemoizedFn((e) => {
        e.stopPropagation();
        userActions?.onTurnOnDialog();
    });

    const handleSetValueAsUser = useMemoizedFn((userString) => {
        setState({
            ...state,
            value: userString
        });
    });

    return (
        <>
            <Dialog classes={{root: "overflow-hidden", paper: "overflow-hidden"}} {...dialogProps}>
                <div className={loading ? "loading" : null}>
                    <DialogTitle id="VariableDialog-dialog-title">
                        {instanceVariable || userTaskVariable ? (
                            <Translate id="process.Edit variable" />
                        ) : (
                            <Translate id="process.add variable" />
                        )}
                    </DialogTitle>
                    <DialogContent className={"flex flex-grow overflow-hidden"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name-required"
                                    label={<Translate id={"process.variable name"} />}
                                    value={state.name}
                                    onChange={handleName}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="value-required"
                                    multiline
                                    onChange={handleValue}
                                    label={<Translate id={"process.value"} />}
                                    value={state.value}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <MyTooltip title={<Translate id={"_.select user"} />}>
                                                    <IconButton onClick={handleSearchUser} edge="end">
                                                        <Icon>person_search</Icon>
                                                    </IconButton>
                                                </MyTooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} onClick={handleDelete}>
                            <Translate id="_.cancel" />
                        </Button>
                        <Button
                            variant={"contained"}
                            disabled={state.name === "" || state.value === ""}
                            onClick={handleSubmit}
                            color="secondary"

                        >
                            <Translate id="_.sure" />
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {userActions?.open && <NewUserDialog onChange={handleSetValueAsUser} />}
        </>
    );
}

export default NewInstanceUserTaskVarDialog;
