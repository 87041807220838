import * as Actions from "../actions";

const initialState = {
    data: {
        totalElements: 0,
        content: []
    },
    selected: null,
    selectedForEditCompany: null,
    selectedWorkHistory: null,
    selectedLabel: null,
    params: {},
    relationShips: null,
    extraContactInfos:[]
};

const listReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_LIST:
            return {
                ...state,
                data: {...action.payload}
            };
        case Actions.CLEAR_LIST:
            return {
                ...state,
                data:  {
                    totalElements: 0,
                    content: []
                }
            };
        case Actions.SELECT_ITEM:
            return {
                ...state,
                selected: action.data
            };
        case Actions.SELECT_ITEM_FOR_EDIT_COMPANY:
            return {
                ...state,
                selectedForEditCompany: action.data
            };
        case Actions.SELECT_WORK_HISTORY_FOR_COMPANY_CONTACT:
            return {
                ...state,
                selectedWorkHistory: action.data
            };
        case Actions.SELECT_LABEL:
            return {
                ...state,
                selectedLabel: action.data
            };
        case Actions.GET_RELATIONSHIP:
            return {
                ...state,
                relationShips: action.payload
            };
        case Actions.GET_EXTRA_INFO:
            return {
                ...state,
                extraContactInfos: action.payload
            };
        default:
            return state;
    }
};

export default listReducer;
