import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    hooks: [],
    accounts: [],
    selectedHook: null,
    selectedAccount: null,
    /*
    * 当传创建/编辑hook 成功后，显示在dialog
    * */
    clientToken: "",
    hubToken: "",
    currentHookName: ""
};

const financialReducer = function (state=initState, action) {
    switch (action.type)
    {
        case Actions.GET_HOOKS: {
            let tobj = cloneDeep(state);
            tobj.hooks = action.payload;
            return  tobj;

            // return {
            //     ...state,
            //     hooks: action.payload
            // };
        }
        case Actions.SELECT_A_HOOK: {
            let tobj = cloneDeep(state);
            tobj.selectedHook = action.payload;
            return  tobj;
            // return {
            //     ...state,
            //     selectedHook: action.payload
            // };
        }
        case Actions.De_SELECT_HOOK: {
            let tobj = cloneDeep(state);
            tobj.selectedHook = null;
            return  tobj;
            // return {
            //     ...state,
            //     selectedHook: null
            // };
        }
        case Actions.GET_ACCOUNTS: {
            let tobj = cloneDeep(state);
            tobj.accounts = action.payload;
            return  tobj;
        }

            // return {
            //     ...state,
            //     accounts: action.payload
            // };

        case Actions.EMPTY_ACCOUNTS: {
            let tobj = cloneDeep(state);
            tobj.accounts = [];
            return  tobj;
        }

            // return {
            //     ...state,
            //     accounts: []
            // };

        case Actions.SELECT_ACCOUNT: {
            let tobj = cloneDeep(state);
            tobj.selectedAccount = action.payload;
            return  tobj;
            // return {
            //     ...state,
            //     selectedAccount: action.payload
            // };
        }
        case Actions.DE_SELECT_ACCOUNT: {
            let tobj = cloneDeep(state);
            tobj.selectedAccount = null;
            return  tobj;
            // return {
            //     ...state,
            //     selectedAccount: null
            // }
        }
        case Actions.SHOW_TOKEN: {
                let tobj = cloneDeep(state);
                tobj.clientToken = action.payload.clientToken;
                tobj.hubToken = action.payload.hubToken;
                tobj.currentHookName = action.payload.hookName;
                return  tobj;
            // return {
            //     ...state,
            //     clientToken: action.payload.clientToken,
            //     hubToken: action.payload.hubToken,
            //     currentHookName: action.payload.hookName
            // }
        }
        default: return  state;
    }
};


export default financialReducer;
