import axios, {crmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_PRODUCT = "[COMMERCE|PRODUCT] GET_PRODUCT";
// export const SET_PRODUCT_SEARCH_TEXT = '[COMMERCE|PRODUCT] SET_PRODUCT_SEARCH_TEXT';

export function getProduct(id){
    const request = axios.get(`${crmPrefix}/product/${id}`);

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
                dispatch({
                    type: GET_PRODUCT,
                    payload: response.data
                });
                dispatch(finishLoading());
            }
        );
    };
}
