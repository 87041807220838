import axios, {filePrefix} from "../myaxios";

export const savePicture = (image, callback) => {
    const url = `${filePrefix}/file`;
    const formData = new FormData();
    formData.append("file", image);
    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    axios
        .post(url, formData, config)
        .then((res) => {
            callback(true, res.data[0].visitUrl);
        })
        .catch((er) => {
            callback(false, er);
        });
};

export const saveFile = (file, callback) => {
    const url = `${filePrefix}/file`;
    const formData = new FormData();
    formData.append("file", file);
    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    axios
        .post(url, formData, config)
        .then((res) => {
            callback(true, res.data[0]);
        })
        .catch((er) => {
            callback(false, er);
        });
};

export const uploadFile = (file, callback) => {
    const url = `${filePrefix}/file`;
    const formData = new FormData();
    formData.append("file", file);
    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    axios
        .post(url, formData, config)
        .then((res) => {
            callback(true, res.data[0]);
        })
        .catch((er) => {
            callback(false, er);
        });
};

export const uploadFiles = (files, callback) => {
    // console.log(files);
    const url = `${filePrefix}/file`;
    const formData = new FormData();
    Object.keys(files).map((key, value) => {
        // console.log(files[value]);
        formData.append("file", files[value]);
    });
    // files.map(file => formData.append('file',file))

    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    axios
        .post(url, formData, config)
        .then((res) => {
            callback(true, res.data);
        })
        .catch((er) => {
            callback(false, er);
        });
};
