import {lazy} from "react";

export const ConfigManagement = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/marketing/management/:tab",
            component: lazy(() =>
                import("./ManagementPage")
            )
        },
        {
            path     : "/marketing/management",
            component: lazy(() =>
                import("./ManagementPage")
            )
        }
    ]
};
