import {Typography} from "@material-ui/core";
import {MyAvatar, NameLabel} from "@ui";
import {random} from "lodash";

export const getUniqueKey = () => {
    return random(888, 9999999);
};

export const UserView = ({item}) => (
    <span className="flex flex-row items-center">
        <MyAvatar user={item} className={"wmr-3"} />
        <span className="flex flex-col">
            <span className="flex flex-row items-center nowrap">
                <strong>
                    {" "}
                    <NameLabel user={item} />{" "}
                </strong>
                <small className={"text-grey-dark mr-2"}>@{item.username}</small>
            </span>
            <span className="flex flex-row  items-center -mt-2 nowrap">
                {item.departmentNames && item.departmentNames.length > 0 ? (
                    <>
                        {item.departmentNames.map((subitem, index) => (
                            <Typography key={index} color={"textPrimary"} variant={"caption"}>
                                {subitem}
                                {index !== item.departmentNames.length - 1 && ", "}{" "}
                            </Typography>
                        ))}
                    </>
                ) : null}
            </span>
        </span>
    </span>
);
