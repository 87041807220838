import {DialogContent, FormControl, Grid} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import {MyTooltip} from "@ui";
import classNames from "classnames";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {EnumFormControl} from "../../marketingTools";

function ValueLabelAR(props) {
    const {children, open, value} = props;

    return (
        <MyTooltip open={open} enterTouchDelay={0} placement="top" title={value + "M"}>
            {children}
        </MyTooltip>
    );
}

function ValueLabelNE(props) {
    const {children, open, value} = props;

    return (
        <MyTooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </MyTooltip>
    );
}

const marks = [
    {
        value: 100,
        label: "100"
    },
    {
        value: 5000,
        label: "5K"
    },
    {
        value: 10000,
        label: "10K"
    },
    {
        value: 20000,
        label: "20K"
    }
];

const Step1 = ({formData, handleFormData}) => {
    const [complete, setComplete] = useState(false);
    return (
        <div className={classNames("max-h-0", complete ? "overflow-visible" : " overflow-hidden")}>
            <Divider className="mt-16" />
            <DialogContent className={"overflow-visible"}>
                <Typography variant={"h6"}>
                    <Translate id={"market.More Information"} />
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <EnumFormControl type={"SalesCompanyIndustry"} name={"industry"} formData={formData} handleFormData={handleFormData} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <EnumFormControl type={"SalesCompanyOwnership"} name={"ownership"} formData={formData} handleFormData={handleFormData} />
                    </Grid>
                </Grid>

                <FormControl fullWidth>
                    <InputLabel htmlFor="Annual-Revenue">{<Translate id={"market.Annual Revenue"} />}</InputLabel>
                    <div className="mt-68 -mb-36">
                        <Slider
                            min={0}
                            max={100}
                            id={"Annual-Revenue"}
                            ValueLabelComponent={ValueLabelAR}
                            step={10}
                            value={formData.annualRevenue || 0}
                            onChange={(event, newValue) => handleFormData("annualRevenue")({target: {value: newValue}})}
                            name={"Annual-Revenue"}
                            valueLabelDisplay="on"
                        />
                    </div>
                </FormControl>

                <FormControl fullWidth className={"mt-20"}>
                    <InputLabel htmlFor="Annual-Revenue">{<Translate id={"market.Number of employees"} />}</InputLabel>
                    <div className="mt-68">
                        <Slider
                            min={100}
                            max={20000}
                            id={"Number of employees"}
                            ValueLabelComponent={ValueLabelNE}
                            step={500}
                            value={formData.employees || 0}
                            onChange={(event, newValue) => handleFormData("employees")({target: {value: newValue}})}
                            name={"Annual-Revenue"}
                            valueLabelDisplay="on"
                            marks={marks}
                        />
                    </div>
                </FormControl>
            </DialogContent>
        </div>
    );
};

export default Step1;
