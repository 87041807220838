// noinspection JSIncompatibleTypesComparison

import {Component} from 'react';
import PropTypes from 'prop-types';

class AMapReGeocoder extends Component {
    constructor(props) {
        super(props);
        this.resolveGeocoder(this.props);
    }

    componentDidMount() {
        // console.log(`AMapReGeocoder 高德地图正在解析!`);
        // this.handleSearch(this.props.latitude, this.props.longitude);
        // console.log(`解析组件传入参数 => latitude: => ${this.props.latitude} address => ${this.props.address}`);
        if (this.props.address) {
            this.handleGetGPSByAddress(this.props.address);
        }
        if (this.props.latitude) {
            this.handleSearch(this.props.latitude, this.props.longitude);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.latitude !== this.props.latitude || prevProps.longitude !== this.props.longitude) {
            // console.log(`AMapReGeocoder => 高德地图 更新`);
            this.handleSearch(this.props.latitude, this.props.longitude);
        }
        if (prevProps.address !== this.props.address) {
            // console.log(`AMapReGeocoder => 高德地图 更新,正在正向解析`);
            this.handleGetGPSByAddress(this.props.address);
        }
    }

    resolveGeocoder = (props) => {
        this.map = props.__map__;
        this.map.plugin(["AMap.Geocoder"], () => {
            const resGeocoder = new window.AMap.Geocoder({city: "全国"});
            this.map.addControl(resGeocoder);
            this.geoloc = resGeocoder;
        });
    };

    /*
     * 根据 gps 逆解析位置信息
     * */
    handleSearch = (lat, lng) => {
        // console.log(`AMapReGeocoder 高德地图正在逆向解析!`);
        const lnglat = [lng, lat];
        this.geoloc &&
            this.geoloc.getAddress(lnglat, (status, result) => {
                if (status === "complete" && result.info === "OK") {
                    // result为对应的地理位置详细信息
                    console.log("高德地图逆解析成功", result.regeocode);
                    this.props.passRegeocode(result.regeocode);
                }
            });
    };

    /*
     * 根据 address 解析 gps 信息
     * */
    handleGetGPSByAddress = (address) => {
        this.geoloc &&
            this.geoloc.getLocation(address, (status, result) => {
                if (status === "complete" && result.geocodes.length) {
                    const lnglatObject = result.geocodes;
                    this.props.callbackLngLat(lnglatObject);
                }
            });
    };

    render() {
        return <div></div>;
    }
}

AMapReGeocoder.propTypes = {
    /*
     *  返回逆向解析的结果， 即根据GPS信息，解析成具体的行政城市地址信息
     * */
    passRegeocode: PropTypes.func,
    /*
     *  返回正向解析的结果， 即根据地址信息，解析成 gps 等具体的地址信息
     * */
    callbackLngLat: PropTypes.func,
};

export default AMapReGeocoder;
