import {useEffect} from "react";
// @ts-ignore
import ListItemText from "@material-ui/core/ListItemText";
// @ts-ignore
import {CodeView, ColoredDate, ItemTitle, Money, MyChip} from "@ui";
import {deliveryOrderTypes} from "app/main/outsource/enums";

import trans from "../../../main/outsource/translation/index.json";

// @ts-ignore
function OsdelOrderItemView(props: any) {
    const {item, addTranslation} = props;
    useEffect(() => {
        if (addTranslation) {
            addTranslation(trans);
        }
    }, []);
    return (
        <>
            <ListItemText
                primary={
                    <>
                        <CodeView dense>{item.code}</CodeView>
                        <ItemTitle>{item.title}</ItemTitle>
                    </>
                }
                secondary={
                    <>
                        <Money amount={item.totalPrice} />
                        <ColoredDate
                            date={item.dueDate}
                            disabled={!(item.status === "OPEN" || item.status === "PENDING")}
                        />
                    </>
                }
            />

            <MyChip size={"small"} variant={"outlined"} label={deliveryOrderTypes[item.type]?.label} />
        </>
    );
}

export default OsdelOrderItemView;
