import {useMemo} from "react";

function useLinearGradientColorsHelper() {

    const premiumWhiteLinearColor = useMemo(()=>{
        return "linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%)";
    },[]);

    return {
        premiumWhiteLinearColor
    };
}

export default useLinearGradientColorsHelper;
