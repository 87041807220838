import {
    GET_PRODUCTS_ALL_CATEGORIES_IN_SOLUTION,
    GET_PRODUCTS_CATEGORIES_IN_SOLUTION_SEARCH_PRODUCT_DIALOG,
    GET_PRODUCTS_OF_SOLUTION,
    GET_SOLUTION_DETAILS,
    GET_SOLUTIONS,
    PASS_BRAND_ID,
    PASS_SELECTED_PRODUCTS,
    REMOVE_ALL_SELECTED,
    SEARCH_TO_ADD_PRODUCT_TO_SOLUTION,
    SELECT_PRODUCT_CATEGORY_IN_SOLUTION,
    SELECT_PRODUCT_CATEGORY_IN_SOLUTION_SEARCH_PRODUCTDIALOG,
    SELECT_SOLUTION,
    SET_SEARCH_PRODUCT_IN_SOLUTION,
    SET_SOLUTION_DIALOG_BOUNDS_RESIZE
} from '../actions';

const initState = {
    content                               : [],
    searchText                            : "",
    searchProductInSolution               : "",
    selectedSolution                      : null,
    productsOfSolution                    : [],
    selectedProducts                      : [],
    currentBrandId                        : "",
    searchInAddProductDialogText          : "",
    allCategoriesInSolution               : [],
    selectProductCategoryInSolution       : null,
    categoriesInSolutionSearchDialog      : [],
    selectedProCatInSolutionSearchDialog  : null,
    resizeHeight                          : null,
    resizeWidth                           :  null,
    totalElements                         : 0,
    solutionDetailsObject                 : null,
    solutionsResult: null,
};

const solutionsReducer = function (state=initState, action) {
    switch (action.type) {

        case GET_SOLUTIONS:
            return {
                ...state,
                ...action.payload,
                solutionsResult: action.payload
            };
        case SELECT_SOLUTION:
            return {
                ...state,
                selectedSolution: action.payload
            };
        case GET_PRODUCTS_OF_SOLUTION:
            return {
                ...state,
                productsOfSolution: action.payload,
            };
        case PASS_SELECTED_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.payload,
            };
        case REMOVE_ALL_SELECTED:
            return {
              ...state,
              selectedProducts: [],
            };
        case PASS_BRAND_ID:
            return {
                ...state,
                currentBrandId: action.payload
            };
        case SET_SEARCH_PRODUCT_IN_SOLUTION:
            return {
                ...state,
                searchProductInSolution: action.payload
            };
        case SEARCH_TO_ADD_PRODUCT_TO_SOLUTION:
            return {
                ...state,
                searchInAddProductDialogText: action.payload
            };
        case GET_PRODUCTS_ALL_CATEGORIES_IN_SOLUTION:
            return {
              ...state,
              allCategoriesInSolution: action.payload,
            };
        case SELECT_PRODUCT_CATEGORY_IN_SOLUTION:
            return {
                ...state,
                selectProductCategoryInSolution:    action.payload
            };
        case GET_PRODUCTS_CATEGORIES_IN_SOLUTION_SEARCH_PRODUCT_DIALOG:
            return {
                ...state,
                categoriesInSolutionSearchDialog: action.payload
            };
        case SELECT_PRODUCT_CATEGORY_IN_SOLUTION_SEARCH_PRODUCTDIALOG:
            return {
                ...state,
                selectedProCatInSolutionSearchDialog: action.payload
            };
        case SET_SOLUTION_DIALOG_BOUNDS_RESIZE:
            return {
                ...state,
                resizeHeight: action.height,
                resizeWidth: action.width,
            };
        case GET_SOLUTION_DETAILS:
            return {
                ...state,
                solutionDetailsObject: action.payload
            };
        default:
            return state;
    }
};

export default solutionsReducer;
