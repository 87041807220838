export const createDefinitionSlice = (set) => ({
    variables: [],
    userString: "",
    edittingVarIndex: 0,
    editVariable: null,
    selectedDefinition: null,
    onChangeSelectedDefinition: (t) => {
        set({selectedDefinition: t});
        // set(
        //     produce((state) => {
        //         state.selectedDefinition = t
        //     })
        // )
    },
    onChangeEdittingVarIndex: (t) => {
        set({edittingVarIndex: t});
        // set(
        //     produce((state) => {
        //         state.edittingVarIndex = t
        //     })
        // )
    },
    onChangeEditVariable: (t) => {
        set({editVariable: t});
        // set(
        //     produce((state) => {
        //         state.editVariable = t
        //     })
        // )
    },
    onChangeVariables: (t) => {
        set({variables: t});
        // set(
        //     produce((state) => {
        //         state.variables = t
        //     })
        // )
    },
    onChangeUserString: (t) => {
        set({userString: t});
        // set(
        //     produce((state) => {
        //         state.userString = t
        //     })
        // )
    },
});
