import {LoginConfig} from "app/auth/login/LoginConfig";
import {LogoutConfig} from "app/auth/logout/LogoutConfig";
import {Redirect} from "react-router-dom";
import FuseUtils from "../../@fuse/FuseUtils";
import {homepageURL} from "../../ENV.VARIABLE";
import {appsConfigs} from "../main/appsConfigs";

const routeConfigs = [LoginConfig, LogoutConfig, ...appsConfigs];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: "/",
        component: () => <Redirect to={homepageURL} />,
    },
];

export default routes;
