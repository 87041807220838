import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import {MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import EventBus, {UPDATE_WORK_HISTORIES_NOTIFI} from "utils/EventBus";
import axios, {crmPrefix} from "../../../../myaxios";
import {CompanySelector} from "../marketingTools";

const CompanyLabel = ({refresh, sourceItem, link, canEdit}) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setFormData({
            id: sourceItem.id,
            companyId: sourceItem.company ? sourceItem.company.id : "",
            company: sourceItem.company
        });
    }, [sourceItem]);

    const handleFormData = name => event => {
        const tformData = {...formData};
        tformData[name] = event.target.value;
        setFormData(tformData);
    };


    const submit = () => {
        if (formData === "" || formData === undefined || formData === null) {
            dispatch(fuseActions.showMessage({
                variant: "error",
                message: "message.Company can't be null!"
            }));
        } else {
            const data = {
                id: formData.id,
                companyId: formData.companyId
            };
            delete data.company;
            const request = axios.put(`${crmPrefix}/${link}`, data);
            request.then(() => {
                refresh();
                sourceItem.lastName && EventBus.dispatch(UPDATE_WORK_HISTORIES_NOTIFI, UPDATE_WORK_HISTORIES_NOTIFI);
                setEdit(false);
                dispatch(fuseActions.showMessage({
                    variant: "success",
                    message: "message.Company changed!"
                }));
            });
        }
    };

    return (
        <>
            {
                edit ?
                    <div className={"w-full px-16 pt-16"}>
                        <div className="mb-4">
                            <CompanySelector formData={formData} handleFormData={handleFormData} />
                        </div>
                        <Button size={"small"} onClick={() => setEdit(false)} className="mr-2" variant={"contained"}><Translate
                            id={"_.cancel"} /></Button>
                        <Button size={"small"} onClick={submit} color={"secondary"} variant={"contained"}><Translate id={"_.save"} /></Button>
                    </div>
                    :
                    <List>
                        <ListItem>
                            <ListItemText primary={(sourceItem && sourceItem.company) && sourceItem.company.name}
                                          secondary={<Translate id={"market.Company"} />} />
                        </ListItem>
                        {canEdit && <ListItemSecondaryAction>
                            <MyTooltip title={<Translate id="market.edit company" />}>
                                <IconButton
                                    onClick={() => setEdit(true)}>
                                    <Icon fontSize={"small"}>edit</Icon>
                                </IconButton>
                            </MyTooltip>
                        </ListItemSecondaryAction>
                        }
                    </List>
            }
        </>
    );
};

export default CompanyLabel;

CompanyLabel.propTypes = {
    type: PropTypes.string
};
