import {Button} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {EmptyView, MyCard, ParseHTML, SubmitButton} from "@ui";
import {showMessage} from "app/store/actions";
import axios, {firmPrefix} from "myaxios";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import EditorInput from "../../../../utils/EditorInput/EditorInput";
import {useWorkPlan} from "./useWorkPlan";
import Tags from "./Widgets/Tags";

const InformationWP = ({getHistory}) => {
    const {wp, canEdit, refresh} = useWorkPlan();
    const dispatch = useDispatch();
    const [editDescription, setEditDescription] = useState(false);
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    const onEditWP = () => {
        setEditDescription(true);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
            ...wp,
            workplanId: wp?.id,
            description: value,
        };

        axios
            .put(`${firmPrefix}/workplan`, formData)
            .then(() => {
                setLoading(false);
                dispatch(
                    showMessage({
                        message: "message.WPDetail Saved!",
                        variant: "success",
                    })
                );
                refresh();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        wp && (
            <MyCard
                noPadding={wp.description.trim() === ""}
                title={
                    <>
                        <Translate id={"wps.Description"} />{" "}
                        {wp.descriptionIsChanged && (
                            <Chip
                                onClick={(e) => getHistory(wp.id, e.currentTarget)}
                                size="small"
                                className="ml-2"
                                label={<Translate id={"wps.Edited"} />}
                            />
                        )}
                    </>
                }
                action={<Tags />}>
                {}
                {wp.description.trim() === "" ? (
                    editDescription ? (
                        <div className={"px-16 pb-16"}>
                            <EditorInput loading={loading} name={"wp-description"} value={value} onChange={(e) => setValue(e.target.value)} />
                            <div className="flex justify-end mt-12">
                                <Button data-cy={"cancel-add-desc"} className={"mr-4"} onClick={() => setEditDescription(false)}>
                                    <Translate id={"_.cancel"} />
                                </Button>
                                <SubmitButton data-cy={"submit-wp-description"} success onClick={onSubmit} loading={loading}>
                                    <Translate id={"_.save"} />
                                </SubmitButton>
                            </div>
                        </div>
                    ) : (
                        <EmptyView
                            noIcon
                            left
                            label={
                                <span>
                                    <Translate id={"_.No description"} />{" "}
                                    {canEdit && (
                                        <IconButton data-cy={"no-description-add"} onClick={onEditWP} color={"default"}>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    )}
                                </span>
                            }
                            className="py-0 px-16"></EmptyView>
                    )
                ) : (
                    <ParseHTML html={wp.description} />
                )}
            </MyCard>
        )
    );
};

export default InformationWP;
