import {lazy} from "react";
import {CustomerConfig} from "./customers/Config";

export const CommerceAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/stage-templates/:templateId/:content",
            component: lazy(() =>
                import("./templates/template/Page")
            )
        },
        {
            path     : "/stage-templates/:templateId/",
            component: lazy(() =>
                import("./templates/template/Page")
            )
        },
        {
            path     : "/stage-templates",
            component: lazy(() =>
                import("./templates/list/Page")
            )
        },
        {
            path     : "/template/:templateId/",
            component: lazy(() =>
                import("./templates/list/Page")
            )
        },
        {
            path     : "/stage-templates",
            component: lazy(() =>
                import("./templates/list/Page")
            )
        },
        {
            path     : "/project/:projectId/:content/:tab",
            component: lazy(() =>
                import("./projects/single/ProjectPage")
            )
        },
        {
            path     : "/project/:projectId/:content",
            component: lazy(() =>
                import("./projects/single/ProjectPage")
            )
        },
        {
            path     : "/project/:projectId",
            component: lazy(() =>
                import("./projects/single/ProjectPage")
            )
        },
        {
            path     : "/solution/:solutionId/products",
            component: lazy(() =>
                import("./products/list/Page")
            )
        },
        {
            path     : "/solution/:brandId/solutions",
            component: lazy(() =>
                import("./solutions/NewSolutionPage")
            )
        },
        {
            path     :  "/solutions",
            component:  lazy(() =>
                import("./solutions/SolutionsPage")
            )
        },
        {
            path     : "/products/:productId/:scrollInto",
            component: lazy(() =>
                import("./products/single/Product")
            )
        },
        {
            path     : "/products/:productId",
            component: lazy(() =>
                import("./products/single/Product")
            )
        },
        {
            path     : "/products",
            component: lazy(() =>
                import("./products/list/Page")
            )
        },

        ...CustomerConfig,

        {
            path     : "/brands/category/:categoryId",
            component: lazy(() =>
                import("./brands/list/Page")
            )
        },
        {
            path     : "/brand/:brandId/customers/:groupId",
            component: lazy(() =>
                import("./customers/list/Page")
            )
        },
        {
            path     : "/brand/:brandId/customers",
            component: lazy(() =>
                import("./customers/list/Page")
            )
        },
        {
            path     : "/brand/:brandId/solutions/:status",
            component: lazy(() =>
                import("./solutions/SolutionsPage")
            )
        },
        {
            path     : "/brand/:brandId/solutions",
            component: lazy(() =>
                import("./solutions/SolutionsPage")
            )
        },
        {
            path     : "/brand/:brandId/projects",
            component: lazy(() =>
                import("./projects/list/Page")
            )
        },
        {
            path     : "/brand/:brandId/products/:categoryId",
            component: lazy(() =>
                import("./products/list/Page")
            )
        },
        {
            path     : "/brand/:brandId/products",
            component: lazy(() =>
                import("./products/list/Page")
            )
        },
        {
            path     : "/brand/:brandId/:scrollInto",
            component: lazy(() =>
                import("./brands/single/Brand")
            )
        },
        {
            path     : "/brands/status/:status",
            component: lazy(() =>
                import("./brands/list/Page")
            )
        },
        {
            path     : "/brands",
            component: lazy(() =>
                import("./brands/list/Page")
            )
        },
        {
            path     : "/brand/:brandId",
            component: lazy(() =>
                import("./brands/single/Brand")
            )
        },
    ]
};
