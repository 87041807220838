import {Icon, IconButton, Typography} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import type {ReactNode} from "react";
import {Helmet} from "react-helmet/es/Helmet";
import {Link, useHistory} from "react-router-dom";

export type ItemHeaderProps = {
    actions?: ReactNode;
    title?: ReactNode;
    breads?: ReactNode[];
    /**
     * if it's a dialog, it shows close button and removes backlink
     */
    isDialog?: boolean;
    /**
     * If there is no backlink provided, component goes back to history
     */
    backLink?: string;
    stringTitle: string;
};

export const SingleHeader = ({actions, stringTitle, title, breads, isDialog, backLink}: ItemHeaderProps) => {
    const history = useHistory();

    const backButton = () => {
        if (!backLink && history.length > 1) history.goBack();
    };

    return (
        <div className={"p-12 w-full justify-between flex items-center"}>
            <Helmet>
                <title>{stringTitle}</title>
            </Helmet>
            <div className="flex items-center">
                {!isDialog && history.length > 1 && (
                    // @ts-ignore no to
                    <IconButton className={"-ml-8 mr-8 print:hidden"} to={backLink} component={backLink && Link} onClick={backButton}>
                        <Icon className="text-3xl">arrow_back</Icon>
                    </IconButton>
                )}

                <div className={"flex-1 md:mb-0 mb-8"}>
                    {breads && (
                        <Breadcrumbs aria-label="breadcrumb" separator={<Icon fontSize={"small"} children="chevron_right" />}>
                            {breads?.map((b, i) => (
                                <Typography key={i} color={i + 1 === breads.length ? "textPrimary" : "initial"}>
                                    {b}
                                </Typography>
                            ))}
                        </Breadcrumbs>
                    )}
                    <span className={"md:text-32 text-24 font-bold align-middle capitalize"}>{title}</span>
                </div>
            </div>
            <div className="flex items-center">{actions}</div>
        </div>
    );
};
