import {CardHeader} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {ParseHTML} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import {useEffect, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import Empty from "utils/Empty";
import Labels from "./Labels";

const Description = (props) => {
    const {sourceItem, sourceType, selectItem, canEdit} = props;
    const [expanded, setExpanded] = useState(false);
    const [expandable, setExpandable] = useState(false);
    const hasDescription = !!sourceItem.description && sourceItem.description.trim() !== "";
    const dispatch = useDispatch();
    const card = useRef(null);
    const onEdit = () => {
        selectItem(sourceItem);
        dispatch(fuseActions.turnOn(sourceType));
    };

    useEffect(() => {
        // console.log();
        if (card.current.clientHeight > 224) {
            setExpandable(true);
        }
    }, []);

    return (
        <Card className={"m-20 relative"}>
            <CardHeader title={<Translate id={"_.description"} />} subheader={sourceItem.labels && <Labels {...props} data={sourceItem.labels} />} />
            <CardContent ref={card} className={classNames("pt-0 ", expanded ? "pb-80" : expandable && "max-h-224 overflow-hidden")}>
                {hasDescription ?
                    <ParseHTML html={sourceItem.description} />
                    :
                    <Empty
                        className="py-2"
                        noIcon
                    >
                        {canEdit &&
                            <Button onClick={onEdit} color={"primary"}>
                                <Translate id={`market.Edit the ${sourceType}`} />
                            </Button>}
                    </Empty>
                }
            </CardContent>

            {expandable && <div className={"absolute fixed-bottom pt-24 pb-8 flex justify-center from-transparent to-white"}
                                style={{background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)"}}>
                <IconButton onClick={() => setExpanded(!expanded)}><Icon>{expanded ? "expand_less" : "expand_more"}</Icon></IconButton>
            </div>}
        </Card>
    );
};

export default Description;
