import {useMemo} from "react";
import {Dialog} from "@material-ui/core";
import {useMUIDialog} from "../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../@fuse/state/constant";
import {IncomeSureContext} from "../IncomeSureManager/state/constant";
import {ContentView} from "./SubViews/details";
import {useDetail} from "../IncomeSureManager/hooks";
import {useLocalStorageState, useMemoizedFn, useMount} from "ahooks";
import {INCOME_LOCAL_ID} from "./state/constant";
import json from "../translate/income.json";
import {withLocalize} from "react-localize-redux";
import {useIncomeMatching} from "./hooks";
import {forEach, toNumber} from "lodash";
import {useIncomeStore} from "./state";

function IncomeDetailDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.incomeDetails,
    });
    const matchingDialogActions = useMUIDialog({
        registry: GDialogRegistries.matchingOrder,
        maxWidth: 'lg',
        fullWidth: true,
        fullScreen: true,
    });

    const [incomeId, setIncomeId] = useLocalStorageState(INCOME_LOCAL_ID);

    const detailsRequest = useDetail();

    const matchingRequest = useIncomeMatching();

    const {onChangeMatchingOrdersCount} = useIncomeStore();

    useMount(() => {
        props.addTranslation(json);
        const params = {
            id: incomeId,
        };
        detailsRequest.run(params).then((response) => {
            response?.status === 200 && handleIncomeMatchingInvoice(response?.data);
        });
    });

    /**
     * @description - 获取到收入详情数据后，立马匹配发票 / 订单金额
     */
    // useEffect(() => {
    //     const tIncome = detailsRequest?.data?.data;
    //     if (tIncome) {
    //         handleIncomeMatchingInvoice();
    //     }
    // }, [detailsRequest?.data?.data]);

    /**
     * @description - 一笔回款匹配最近时间段的发票 / 订单金额
     */
    const handleIncomeMatchingInvoice = (_detailsData) => {
        const tIncome = _detailsData;
        const collectPercent = tIncome?.collectPercent;
        const tType = tIncome?.type;
        const tStatus = tIncome?.status;
        let tMatchingOrders = [];
        if (toNumber(collectPercent) === 0 && tType === "INCOME" && tStatus === "ACTIVE") {
            const params = {
                incomeId: incomeId,
            };
            matchingRequest.run(params).then((response) => {
                console.log("matchingRequest?.data?.data > response", response);
                console.log("matchingRequest?.data?.data", matchingRequest?.data?.data);
                if (response?.status === 200) {
                    const tMatching = response?.data;
                    !!tMatching &&
                        forEach(tMatching, (matchingItem) => {
                            const itemOrders = matchingItem?.orders;
                            itemOrders &&
                                forEach(itemOrders, (orderItem) => {
                                    tMatchingOrders.push(orderItem);
                                });
                        });
                    onChangeMatchingOrdersCount(tMatchingOrders.length);
                    console.log("tMatchingOrders", tMatchingOrders);
                    tMatchingOrders.length > 0 &&
                        toNumber(collectPercent) !== 100 &&
                        tStatus !== "FINISH" &&
                        matchingDialogActions.onTurnOnDialog({
                            onFinish: handleRefresh,
                        });
                }
            });
        }
    };

    const handleRefresh = useMemoizedFn(() => {
        const params = {
            id: incomeId,
        };
        finshParams?.onFinish();
        detailsRequest.run(params).then(() => {});
    });

    const store = useMemo(
        () => ({
            data: detailsRequest?.data?.data,
            mutate: detailsRequest?.run,
            onClose: onTurnOffDialog,
            onRefresh: handleRefresh,
        }),
        [detailsRequest?.data?.data, detailsRequest?.run, onTurnOffDialog, handleRefresh]
    );

    return (
        <Dialog {...dialogProps}>
            <IncomeSureContext.Provider value={store}>
                <div className={detailsRequest?.isMutating || matchingRequest?.isMutating ? "loading" : ""}>
                    <ContentView />
                </div>
            </IncomeSureContext.Provider>
        </Dialog>
    );
}

export default withLocalize(IncomeDetailDialog);
