import * as Actions from '../actions';

const uploadFile = (state, {files}) => {
    // const id = state.length + 1 ;
    //
    // const files = [];
    // payload.map(_file => {
    //     files.push({
    //         id: _file.id,
    //         name: _file.originalName,
    //         type: _file.type,
    //         owner: _file.user.username,
    //         size: _file.size,
    //         // modified: _file.originalName,
    //         // opened: _file.originalName,
    //         created: _file.uploadDate,
    //         extention: "",
    //         location: "My Files > Documents",
    //         // offline: true,
    //         preview: _file.visitUrl,
    //     })
    // });

    return {...state, ...files};
};

const filesReducer = function (state = {content: []}, action) {
    switch ( action.type ) {
        case Actions.GET_FILES:
            // const content =_.keyBy(action.payload.content, 'id');
            return action.payload;
        case Actions.UPLOAD_FILES:
            return uploadFile(state,action);
        default:
            return state;
    }
};

export default filesReducer;


// hashCode: "6ea7b5c8b486c038b7003f031e208ace3907fdfa"
// id: "5c0a130c68d18258e48bc375"
// originalName: "photo_2018-11-02_13-56-18.jpg"
// owner: "53102fb4bf1044ed8b0ba36c"
// size: 27026
// type: "image/jpeg"
// uploadDate: 1544164108056
// uri: "595/4dbbdd36-fea3-49d3-91e6-ad6024b01279.jpg"
// visitUrl: "http://git.signabc.com:8686/595/4dbbdd36-fea3-49d3-91e6-ad6024b01279.jpg"
