import { GET_APIS, SET_SELECTED_API, REMOVE_SELECTED_API, CHANGE_MODULE, CHANGE_TAG } from "../actions";

const initialState = {
    all : [],
    list : [],
    selected: [],
    selectedTag: "",
    selectedAPI: null,
};

const changeTab = (state, {tag}) => {
    const selected = state.list.filter(_api => _api.tag === tag);
    return {
        ...state,
        selected,
        selectedTag: tag
    };
};

const changeModule = (state, {module}) => {
    const list = state.all.filter(_api => _api.module === module);
    // console.log(list);
    return {
        ...state,
        list
    };
};


const firms = function (state = initialState, action) {
    switch ( action.type ) {
        case GET_APIS: {
            return {
                ...state,
                all: action.list,
            };
        }
        case SET_SELECTED_API: {
            return {
                ...state,
                selectedAPI: action.api
            };
        }
        case REMOVE_SELECTED_API: {
            return {
                ...state,
                selectedAPI: null
            };
        }
        case CHANGE_MODULE: return changeModule(state, action);
        case CHANGE_TAG: return changeTab(state, action);

        default:
        {
            return state;
        }
    }
};

export default firms;
