import PropTypes from "prop-types";
import {TableCell, TableRow} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import LFSvgIcon from "../RootComponents/LFSvgIcon";
import EmptyIcon from "../../IconFontVendor/暂无内容.svg";

TableEmpty.propTypes = {
    label: PropTypes.string,
};

function TableEmpty(props) {
    const {label} = props;
    return (
        <TableRow>
            <TableCell colSpan={"100%"} className="h-full border-b-0" align={"center"}>
                <div className="flex flex-col flex-grow justify-center items-center">
                    <div className="flex">
                        <LFSvgIcon svg={EmptyIcon} style={{height: "60px", width: "60px"}} />
                    </div>
                    <Typography
                        variant="h6"
                        className="inline-flex text-xl truncate text-grey-darker justify-center items-center text-center w-full"
                    >
                        {label || <Translate id="_.No data available" />}
                    </Typography>
                </div>
            </TableCell>
        </TableRow>
    );
}

export default TableEmpty;
