import {Chip, Icon, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import {MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import axios, {crmPrefix} from "../../../../../myaxios";
import {ActiveDateChip, calTwoDates, haveDeleteAble, LabelChip} from "../../marketingTools";
import * as Actions from "../store/actions";

const styles = () => ({});

class ItemView extends Component {
    onDelete = () => {
        const id = this.props.item.id;
        axios.delete(`${crmPrefix}/company/${id}`).then(() => {
            this.props.showMessage({message: "message.Company has been deleted!"});
            this.props.getList();
        });
    };

    onClickItem = () => {
        const {item, getItem, turnOn, width} = this.props;
        if (width === "sm" || width === "xs") {
            this.props.history.push(`/company/${item.id}`);
        } else {
            turnOn("companyDetail");
            getItem(item.id);
        }
    };

    render() {
        const {item, listView, small} = this.props;
        return (
            <>
                <ListItemText
                    classes={{root: listView ? "my-0" : ""}}
                    className={"text-base"}
                    primary={
                        <>
                            {item.industry && <Typography color={"textSecondary"}>{item.industry.value} </Typography>}

                            <MyTooltip title={haveDeleteAble(item) === true ? <Translate id={"market.deleted"} /> : ""}>
                                <button onClick={this.onClickItem} className={"text-xl nowrap max-w-full  capitalize"}>
                                    {item.name.trim() !== "" && (
                                        <>
                                            {" "}
                                            <span
                                                className={
                                                    haveDeleteAble(item) === true ? "line-through text-gray-500" : ""
                                                }
                                            >
                                                {item.name}
                                            </span>{" "}
                                        </>
                                    )}
                                </button>
                            </MyTooltip>
                            {item.labels && listView && (
                                <>
                                    {item.labels.length > 0 &&
                                        item.labels.map((__, i) => <LabelChip key={i} label={__} />)}
                                </>
                            )}
                            {/*<small*/}
                            {/*    className="text-grey ml-2">{item.phone}*/}
                            {/*</small>*/}
                        </>
                    }
                    secondary={
                        item.labels &&
                        !listView && (
                            <>
                                {item.labels.length > 0 && item.labels.map((__, i) => <LabelChip key={i} label={__} />)}
                            </>
                        )
                    }
                />
                {!small && (
                    <ListItemSecondaryAction>
                        <div className={"flex flex-row items-center"}>
                            <Typography color={"secondary"}>{item.lastContactTime}</Typography>
                            {item.members && item.members.length > 1 && (
                                <span>
                                    <Icon fontSize={"small"} className="text-grey align-text-bottom">
                                        people
                                    </Icon>{" "}
                                    {item.members.length}
                                </span>
                            )}
                            {item.status && item.status.value && (
                                <Chip
                                    className={classNames("text-white  mx-3")}
                                    label={item.status.value}
                                    size={"small"}
                                    color={"primary"}
                                    variant={"default"}
                                />
                            )}
                            {item.overDate &&
                            item.overDate !== 0 &&
                            item.overDate !== "" &&
                            calTwoDates(item.overDate) !== 0 ? (
                                <ActiveDateChip overDate={item.overDate} />
                            ) : (
                                ""
                            )}
                        </div>
                    </ListItemSecondaryAction>
                )}
            </>
        );
    }
}

ItemView.propTypes = {
    item: PropTypes.object.isRequired,
    small: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getItem: Actions.getItem,
            getList: Actions.getList,
            turnOn: fuseActions.turnOn,
            toggleCheck: fuseActions.toggleCheck,
            showMessage: fuseActions.showMessage
        },
        dispatch
    );
}

export default withWidth()(withRouter(withStyles(styles)(withLocalize(connect(null, mapDispatchToProps)(ItemView)))));
