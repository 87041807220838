import * as Actions from "../actions";

const initialState = {
    stockTransfers: null,
    userAssets: null,
    stockOrders: null,
    fundingStats: null,
    buildBonusData: null,
};

const listReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_STOCK_TRANSFERS:
            return {
                ...state,
                stockTransfers: action.payload
            };
        case Actions.GET_USER_ASSETS:
            return {
                ...state,
                userAssets: action.payload
            };
        case Actions.GET_STOCK_ORDERS:
            return {
                ...state,
                stockOrders: action.payload
            };
        case Actions.GET_FUNDING_STATS:
            return {
                ...state,
                fundingStats: action.payload
            };
        case Actions.GET_BUILD_BONUS_DATA:
            return {
                ...state,
                buildBonusData: action.payload
            };
        case Actions.CLEAR_BUILD_BONUS_DATA:
            return {
                ...state,
                buildBonusData: null
            };
        case Actions.GET_BONUSREQUESTS:
            return {
                ...state,
                bonusRequests: action.payload
            };
        default:
            return state;
    }
};

export default listReducer;
