import type {ButtonProps} from "@material-ui/core/Button";
import clsx from "clsx";
import {ErrorButton, LoadingButton, SuccessButton} from "utils/utilComponents";

type Props = {
    success?: boolean;
    error?: boolean;
    loading?: boolean;
};

export const SubmitButton = ({success, error, loading, className, disabled, ...rest}: Props & ButtonProps) => {
    return (
        <>
            {error ? (
                <ErrorButton variant={"contained"} className={clsx(className, loading ? "load" : "")} {...rest} disabled={loading || disabled} />
            ) : success ? (
                <SuccessButton variant={"contained"} className={clsx(className, loading ? "load" : "")} {...rest} disabled={loading || disabled} />
            ) : (
                <LoadingButton variant={"contained"} className={clsx(className, loading ? "load" : "")} {...rest} disabled={loading || disabled} />
            )}
        </>
    );
};
