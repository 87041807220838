import {withLocalize} from "react-localize-redux";
import NameTooltip from "./NameTooltip";

export function nameText(user) {
    let resString = "";

    const regx =
        /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/;

    if (user) {
        const firstName = user.firstName;
        const lastName = user.lastName;
        resString =
            regx.test(`${firstName}${lastName}`) === true ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
    }
    return resString;
}

function NameTooltipComponent(props) {
    const {strongTagFLname, showUsername, user, userNameColorClass} = props;

    const haveUsernameStrongContent = (
        <>
            {strongTagFLname ? (
                <>
                    <NameTooltip user={user}>
                        <strong className={"hover:text-blue hover:underline font-bold"}>{nameText(user)}</strong>
                    </NameTooltip>
                    <small className={userNameColorClass ? `ml-1 ${userNameColorClass}` : "ml-1 text-gray-500"}>
                        @{user.username}
                    </small>
                </>
            ) : (
                <>
                    <NameTooltip user={user}>
                        <p className={"hover:text-blue hover:underline"}>{nameText(user)}</p>
                    </NameTooltip>
                    <small className="ml-1 text-gray-500">@{user?.username}</small>
                </>
            )}
        </>
    );

    const noUsernameStrongContent = (
        <>
            {strongTagFLname ? (
                <NameTooltip user={user}>
                    <strong className={"hover:text-blue hover:underline font-bold"}>{nameText(user)}</strong>
                </NameTooltip>
            ) : (
                <NameTooltip user={user}>
                    <p className={"hover:text-blue hover:underline"}>{nameText(user)}</p>
                </NameTooltip>
            )}
        </>
    );

    return (
        // <NameTooltip user={user}>
        //     <span>
        //         {
        //            showUsername ?
        //                haveUsernameStrongContent :
        //                noUsernameStrongContent
        //         }
        //     </span>
        // </NameTooltip>
        showUsername ? haveUsernameStrongContent : noUsernameStrongContent
    );
}

//
//function areEqual(prevProps, nextProps) {
//    /*
//    如果把 nextProps 传入 render 方法的返回结果与
//    将 prevProps 传入 render 方法的返回结果一致则返回 true，
//    否则返回 false
//    */
//    return (
//        prevProps.user === nextProps.user &&
//        prevProps.style === nextProps.style &&
//        prevProps.nameColorClass === nextProps.nameColorClass &&
//        prevProps.userNameColorClass === nextProps.userNameColorClass &&
//        prevProps.strongTagFLname === nextProps.strongTagFLname &&
//        prevProps.showUsername === nextProps.showUsername
//    );
//}

export default withLocalize(NameTooltipComponent);
