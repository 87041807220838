import * as fuseActions from "@fuseActions";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

function useOpenDialog() {
    const dispatch = useDispatch();

    return     useCallback(
        (identifier) => {
            dispatch(fuseActions.turnOn(`${identifier}`));
        },
        [dispatch]
    );
}

export default useOpenDialog;
