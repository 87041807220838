const createMatchingSlice = (set) => ({
    matchingOrdersLength: 0,
    matchingOrdersSelected: [],
    onChangeMatchingOrdersSelected: (selected) => {
        set({matchingOrdersSelected: selected});
    },
    onChangeMatchingOrdersLength: (length) => {
        set({matchingOrdersLength: length});
    },
});

export default createMatchingSlice;
