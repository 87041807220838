import useQueryStore from "./useQueryStore";
import {useMemo} from "react";

function useStoreUtils() {
    const _hasHydrated = useQueryStore((state) => state._hasHydrated);

    const _query = useQueryStore((state) => state.query);

    const _actions = useQueryStore((state) => state.actions);

    const getHasHydrated = useMemo(() => _hasHydrated, [_hasHydrated]);

    const getQuery = useMemo(() => _query, [_query]);

    return {
        hasHydrated: getHasHydrated,
        query: getQuery,
        actions: _actions,
    };
}

export default useStoreUtils;
