import axios, {financialPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_CONTRACTS_LIST = "[FIN_GET_CONTRACTS_LIST]";

export const SELECT_A_CONTRACT = "[FIN_SELECT_A_CONTRACT]";

export const SELECT_CONTRACTS_FOR_CHANGE_AGENT = "[FIN_SELECT_CONTRACTS_FOR_CHANGE_AGENT]";


export function getContracts(params, callback) {

    const request = axios.get(`${financialPrefix}/contracts`, {params}) ;

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_CONTRACTS_LIST,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}


export function selectAContract(contract) {

    return {
        type: SELECT_A_CONTRACT,
        payload: contract
    };
}


export function selectContractsForAgent(contractS) {

    return {
        type: SELECT_CONTRACTS_FOR_CHANGE_AGENT,
        payload: contractS
    };
}


