import {Button, Icon} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import {MyCard} from "@ui";
import clsx from "clsx";
import axios, {firmPrefix} from "myaxios";
import {useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import EditorInput, {sanitizeFromEditable} from "utils/EditorInput/EditorInput";
import type {CommentItemType} from "../../../../utils/CommentItem";
import CommentItem from "../../../../utils/CommentItem";
import {setCache, turnOff} from "@fuseActions";
// import CommentItem from "./Widgets/CommentItem";
import {useWorkPlan} from "./useWorkPlan";

const formHtml = `<div>
<table class="w-full pb-16" ><colgroup><col style="width: 10%;"><col style="width: 90%;"></colgroup>
    <tbody>
        <tr >
            <td style="text-align: center;" colspan="2"><span style="font-size: 30px;" contenteditable="false"  class="font-bold">工程施工日志</span></td>
        </tr>
        <tr >
            <td contenteditable="false"   class="font-bold">施工单位：</td>
            <td contenteditable="true" >四川省桑瑞光辉标识系统股份有限公司</td>
        </tr>
        <tr >
            <td contenteditable="false"  class="font-bold">工程名称：</td>
            <td contenteditable="true">(标注清楚项目全称)</td>
        </tr>
    </tbody>
</table>
<table class="border w-full border-gray-300" ><colgroup><col style="width: 5%;"><col style="width: 5%;"><col style="width: 40%;"><col style="width: 10%;"><col style="width: 40%;"></colgroup>
    <tbody>
        <tr class="border-b  border-gray-300">
            <td colspan="2" contenteditable="false"  class="font-bold border-r border-gray-300">施工日期:</td>
            <td contenteditable="true">2024-1-1(施工当日)</td>
            <td contenteditable="false"  class="font-bold border-r border-gray-300">项目负责人:</td>
            <td contenteditable="true">&nbsp;</td>
        </tr>
        <tr class="border-b  border-gray-300">
            <td colspan="2" contenteditable="false"  class="font-bold border-r border-gray-300">天气状况:</td>
            <td contenteditable="true">晴(-8至3度)</td>
            <td contenteditable="false"  class="font-bold border-r border-gray-300">记 录 人:</td>
            <td contenteditable="true">现场负责记录人员</td>
        </tr>
        <tr class="border-b  border-gray-300">
            <td colspan="2" contenteditable="false"  class="font-bold border-r border-gray-300">施 工 队:</td>
            <td contenteditable="true"></td>
            <td contenteditable="false"  class="font-bold border-r border-gray-300">施工人数:</td>
            <td contenteditable="true">人</td>
        </tr>
        <tr class="border-b  border-gray-300">
            <td colspan="2" contenteditable="false"  class="font-bold border-r border-gray-300">总工期</td>
            <td contenteditable="true"> 天</td>
            <td contenteditable="false"  class="font-bold border-r border-gray-300">施工工期:</td>
            <td contenteditable="true">第  天</td>
        </tr>
        <tr class="border-b  border-gray-300">
            <td style="height:120px; text-align: center;" rowspan="2" contenteditable="false"  class="font-bold border-r border-gray-300">主<br>要<br>记<br>录</td>
            <td contenteditable="true" style="vertical-align:top" colspan="4">工 种:焊工、电工、普工<br>施工内容 :<br>1、拆除隔墙，垃圾装袋。早7点开始，3人垃圾装袋清运到11点，后2人人清运至12点<br>2、边棚吊顶安装（1004），上午4人固定<br>3、下午4人边棚吊顶（1004），拆除墙体砸坏地板2人修复。</td>
        </tr>
        <tr class="border-b  border-gray-300">
            <td style="height:18px;" colspan="4" contenteditable="false"  class="font-bold">备注：分项把当天每项施工内容写清楚，遇到有特种作业需要写清楚焊工及电工人员名称，施工内容需要备注清楚大概时间段。</td>
        </tr>
        <tr class="border-b  border-gray-300">
            <td style="height: 120px;text-align: center;" rowspan="2" contenteditable="false"  class="font-bold border-r border-gray-300">明<br>日<br>计<br>划</td>
        <td contenteditable="true" style="vertical-align:top" colspan="4">2024年1月2日<br>1、安装1001边棚吊顶安装</td>
        </tr>
        <tr class="border-b  border-gray-300">
            <td style="height:18px;" colspan="4" contenteditable="false"  class="font-bold border-r border-gray-300 text-red">备注：写清楚第二天施工内容及可能遇到的困难及风险。</td>
        </tr>
    </tbody>
</table>
<table class="border w-full border-gray-300" >
    <tbody>
        <tr class="border-b border-gray-300" >
            <td contenteditable="false"  style=" font-weight: bold; border-right: 1px solid #ccc; vertical-align:top" rowspan="2">高空车/吊车</td>
            <td contenteditable="false"  style=" font-weight: bold; border-right: 1px solid #ccc">租赁供应商</td>
            <td contenteditable="false"  style=" font-weight: bold; border-right: 1px solid #ccc">型号</td>
            <td contenteditable="false"  style=" font-weight: bold; border-right: 1px solid #ccc">在场数量</td>
            <td contenteditable="false"  style=" font-weight: bold; border-right: 1px solid #ccc">工作数量</td>
            <td contenteditable="false"  style=" font-weight: bold; border-right: 1px solid #ccc">停置数量</td>
            <td contenteditable="false"  style=" font-weight: bold;">设备停置原因</td>
        </tr>
        <tr class="border-b border-gray-300" style="height: 70px">
            <td  style="border-right: 1px solid #ccc"></td>
            <td  style="border-right: 1px solid #ccc"></td>
            <td  style="border-right: 1px solid #ccc"></td>
            <td  style="border-right: 1px solid #ccc"></td>
            <td  style="border-right: 1px solid #ccc"></td>
            <td  ></td>
        </tr>
        <tr>
            <td colspan="7" style="height: 70px; vertical-align:top; font-weight: bold;">
                安全质量记录并<span style="">上传照片：</span>
            </td>
        </tr>
    </tbody>
</table>
</div>`;

const Comments = ({getHistory, filesWrapperRef}) => {
    const {wp, refresh} = useWorkPlan();
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [notDeletable, setNotDeletable] = useState<boolean>();
    const commentsRef = useRef<HTMLFormElement | null>(null);

    const dispatch = useDispatch();

    const onFilterAttachments = (time) => {
        dispatch(setCache("fileFilterTime", time));
        setTimeout(() => {
            if (filesWrapperRef && filesWrapperRef.current) {
                filesWrapperRef.current.scrollIntoView({behavior: "smooth"});
                setTimeout(() => {
                    dispatch(turnOff("fileFilterTime"));
                }, 450);
            }
        }, 50);
    };

    const submitComment = (e) => {
        e.preventDefault?.();
        if (comment.trim() === "") return;
        setLoading(true);
        const content = sanitizeFromEditable(comment);
        const data = {
            content,
            workplanId: wp?.id,
            notDeletable,
        };
        axios
            .post(`${firmPrefix}/workplanComment`, data)
            .then(() => {
                refresh();
                setComment("");
                setNotDeletable(undefined);
            })
            .finally(() => setLoading(false));
    };

    const uploadFileCallback = (attachments) => {
        axios
            .put(`${firmPrefix}/addAttachments`, {
                workplanId: wp?.id,
                attachments,
            })
            .then(() => {
                refresh();
            });
    };

    const replyReq = (payload) => {
        return axios.post(`${firmPrefix}/workplanComment`, {...payload, workplanId: wp?.id});
    };

    const deleteReq = (payload) => {
        return axios.delete(`${firmPrefix}/workplanComment?commentId=${payload}`);
    };

    const editReq = (payload) => {
        return axios.put(`${firmPrefix}/workplanComment`, payload);
    };

    const fileUploadReq = (payload) => {
        return axios.put(`${firmPrefix}/addAttachments`, {
            workplanId: wp?.id,
            ...payload,
        });
    };

    const extraTitle = (item: CommentItemType) => {
        return (
            <>
                {item.isChanged && !item.deleteDate && (
                    <Chip
                        onClick={(e) => getHistory(item.id, e.currentTarget)}
                        size="small"
                        className="mr-2"
                        label={<Translate id={"wps.Edited"} />}
                    />
                )}
                {wp?.attachments?.length && wp?.attachments?.length > 0 && !item.deleteDate ? (
                    <Button
                        data-cy={"find-files"}
                        size={"small"}
                        className={"mr-8 hid"}
                        startIcon={<Icon>attachments</Icon>}
                        onClick={() => onFilterAttachments(item.timestamp)}>
                        {<Translate id={"wps.Find files"} />}
                    </Button>
                ) : (
                    ""
                )}
            </>
        );
    };

    const addForm = () => {
        setComment(formHtml);
        setNotDeletable(true);
    };

    const clearForm = () => {
        setComment("");
        setNotDeletable(false);
    };

    return (
        wp && (
            <MyCard
                noPadding
                title={
                    <>
                        <Translate id={"wps.Comments"} />({wp.commentCount}){" "}
                        <Button
                            disabled={comment.trim() !== ""}
                            data-cy={"add-workplan-form"}
                            className={"mx-4"}
                            color={"secondary"}
                            onClick={addForm}>
                            {notDeletable ? <Translate id={"wps.Reset Work Plan form"} /> : <Translate id={"wps.Insert Work Plan form"} />}
                        </Button>
                        <Button data-cy={"clear-comment"} disabled={comment.trim() === ""} onClick={clearForm}>
                            {<Translate id={"wps.Clear all"} />}
                        </Button>
                    </>
                }
                action={
                    <Translate>
                        {({translate}) =>
                            wp.unreadCommentCount > 0 && (
                                <div className="rounded-8 px-8 py-2 mt-16 mr-8 bg-yellow-light">
                                    {translate("wps.params Unreads", {count: wp.unreadCommentCount})}
                                </div>
                            )
                        }
                    </Translate>
                }>
                {/* region form */}
                <form ref={commentsRef} onSubmit={submitComment} className={clsx("mx-16 pb-16", notDeletable && "border-3  rounded-4 border-red")}>
                    <EditorInput
                        loading={loading}
                        notFocused
                        rows={"96"}
                        name={"wp-comment"}
                        placeholder={<Translate id={"_.Write a comment..."} />}
                        uploadFileCallback={uploadFileCallback}
                        onSubmit={submitComment}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </form>

                {/* endregion */}

                {wp.comments.length > 0 && (
                    <List>
                        {wp.comments.map((_c) => (
                            <CommentItem
                                commentsRef={commentsRef}
                                extraTitle={extraTitle}
                                replyReq={replyReq}
                                deleteReq={deleteReq}
                                editReq={editReq}
                                fileUploadReq={fileUploadReq}
                                refresh={refresh}
                                key={_c.id}
                                item={_c}
                            />
                        ))}
                    </List>
                )}
            </MyCard>
        )
    );
};

export default Comments;
