import {finishLoading, startLoading} from "@fuseActions";
import axios from "myaxios";
import {GET_LIST} from "../../../contacts/store/actions";
import {moduleLinklistAdmin} from "../../../contacts/store/actions/VARIABLES";
import {MODULE_NAME, moduleLinklist, moduleLinkSingle} from "./VARIABLES";

export const GET_ARCHIVES_LIST = `[${MODULE_NAME}] GET_ARCHIVES_LIST`;
export const GET_ARCHIVE_ITEM = `[${MODULE_NAME}] GET_ARCHIVE_ITEM`;
export const SELECT_ARCHIVE_ITEM = `[${MODULE_NAME}] SELECT_ARCHIVE_ITEM`;
export const CLEAR_ARCHIVE_ITEM = `[${MODULE_NAME}] CLEAR_ARCHIVE_ITEM`;

export function getItem(params, callback, fail) {
    const request = axios.get(`${moduleLinkSingle}`, {params: params});

    return (dispatch) => {
        dispatch(startLoading());
        request
            .then((response) => {
                dispatch({
                    type: GET_ARCHIVE_ITEM,
                    payload: response.data,
                });
                dispatch(finishLoading());
                if (typeof callback === "function") callback();
            })
            .catch((err) => {
                // console.log('解析 归档时出错', err);
                if (typeof fail === "function") fail();
            });
    };
}

export function clearItem() {
    return {
        type: CLEAR_ARCHIVE_ITEM,
    };
}

export function selectItem(item) {
    return {
        type: SELECT_ARCHIVE_ITEM,
        payload: item,
    };
}

export function getList(params) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(moduleLinklist, {params}).then((res) => {
            if (res.data.content)
                return new Promise((resolve) => {
                    dispatch({
                        type: GET_LIST,
                        payload: res.data,
                    });
                    dispatch(finishLoading());
                    resolve();
                });
        });
    };
}

export function getAdminData(params) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(moduleLinklistAdmin, {params}).then((res) => {
            if (res.data.content)
                return new Promise((resolve) => {
                    dispatch({
                        type: GET_LIST,
                        payload: res.data,
                    });
                    dispatch(finishLoading());
                    resolve();
                });
        });
    };
}
