import {lazy} from "react";

export const IncomeModuleConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/tools/excel",
            component: lazy(() => import("./ExcelTools/ExcelTools")),
        },
        {
            path: "/income/invoice/print",
            component: lazy(() => import("./InVoiceGP/Page")),
        },
        // {
        //     path: "/attchments/:attchmentId/incomes",
        //     component: lazy(() => import("./IncomeSureManager/IncomeManagerClass")),
        // },
        {
            path: "/attchments/:attchmentId/incomes",
            component: lazy(() => import("./IncomeSureManager/NewPage")),
        },
        {
            path: "/income/account_filters",
            component: lazy(() => import("./AccountFilter/Page")),
        },
        {
            path: "/incomeOrders",
            component: lazy(() => import("./IncomeOrdersDistributions/NewPage")),
        },
        {
            path: "/myIncomes/type/:type",
            component: lazy(() => import("./MyIncomes/MyIncomesClass")),
        },
        {
            path: "/myIncomes",
            component: lazy(() => import("./MyIncomes/NewPage")),
        },
        {
            path: "/incomes/manager/attachment/:id/incomes",
            component: lazy(() => import("./IncomeSureManager/IncomeManagerClass")),
        },
        {
            path: "/incomes/manager/incomeSure",
            component: lazy(() => import("./IncomeSureManager/NewPage")),
        },
        {
            path: "/incomes/distribution/page/:type",
            component: lazy(() => import("./IncomeDistribution/IncomeDistribution")),
        },
        {
            path: "/incomes/distribution",
            component: lazy(() => import("./IncomeDistribution/IncomeDistribution")),
        },
    ],
};
