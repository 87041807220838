import {useConfirm} from "material-ui-confirm";
import type {ReactNode} from "react";
import {confirmDefaultOptions} from "../../utils/variables";
import {useTranslation} from "react-i18next";

type Props = {
    /**
     * () => axios.delete(`${pmsPrefix}/checklist/${_.id}`)
     */
    deleteReq(): Promise<void | any>;
    callback(): void;
    /**
     *
     * @param arg
     *
     * (onClick) => (
     *         <IconButton
     *             onClick={onClick}
     *         >
     *             <Icon color={"error"} fontSize={"small"}>
     *                 delete
     *             </Icon>
     *         </IconButton>
     *     )
     */
    children: (arg: () => void) => ReactNode;
    item?: ReactNode;
};

export const DeleteWrapper = ({deleteReq, callback, children, item}: Props) => {
    const confirm = useConfirm();
    const {t} = useTranslation();

    const onClickDelete = (e?: MouseEvent) => {
        e?.stopPropagation?.();
        confirm({
            ...confirmDefaultOptions,
            title: t("Delete Confirmation"),
            description: item ? (
                <div className={"bg-gray-100 p-12 rounded-8"}>{item}</div>
            ) : (
                <div className={"text-2xl"}>{t("Are you sure you want to remove this?")}</div>
            ),
        }).then(() => {
            deleteReq().then(() => callback());
        });
    };

    return children(onClickDelete);
};
