import { SHOULD_REFRESH, OPEN_DIALOG, MINIMIZE_DIALOG, CLOSE_DIALOG, TOGGLE_DIALOG, SET_IMAGE_FULL, SHOW_MY_DIALOG, SET_CACHE } from "../../actions";

const dialogs = function (state = {}, action) {
    switch (action.type) {
        case SHOULD_REFRESH: {
            return {
                ...state,
                shouldRefresh: Math.random(),
            };
        }
        case OPEN_DIALOG: {
            return {
                ...state,
                [action.dialog]: true,
            };
        }
        case MINIMIZE_DIALOG: {
            return {
                ...state,
                [action.dialog]: 2,
            };
        }
        case CLOSE_DIALOG: {
            return {
                ...state,
                [action.dialog]: false,
            };
        }
        case TOGGLE_DIALOG: {
            return {
                ...state,
                [action.dialog]: !state[action.dialog],
            };
        }
        case SET_IMAGE_FULL: {
            return {
                ...state,
                selectedImage: {
                    title: action.title,
                    src: action.src,
                },
            };
        }
        case SHOW_MY_DIALOG: {
            return {
                ...state,
                params: {
                    iconType: action.iconType,
                    title: action.title,
                    request: action.request,
                    successFunc: action.successFunc,
                    successMessage: action.successMessage,
                    sureButtonText: action.sureButtonText,
                },
            };
        }
        case SET_CACHE: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        default: {
            return state;
        }
    }
};

export default dialogs;
