import {lazy} from 'react';

export const myFlowAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/flow/tasks/def/:def",
            component: lazy(() => import("./tasks/list/Page")),
        },
        {
            path: "/flow/admin",
            component: lazy(() => import("./admin/Page")),
        },
        {
            path: "/flow/adminTemplates",
            component: lazy(() => import("./admin/TemplatesPage")),
        },
        {
            path: "/flowTask/:id",
            component: lazy(() => import("./tasks/single/Page")),
        },
        {
            path: "/flow/tasks/:id",
            component: lazy(() => import("./tasks/single/Page")),
        },
        {
            path: "/flow/tasks",
            component: lazy(() => import("./tasks/list/Page")),
        },
        {
            path: "/instances/:id",
            component: lazy(() => import("./instances/single/Page")),
        },
        {
            path: "/flow/instances/:id",
            component: lazy(() => import("./instances/single/Page")),
        },
        {
            path: "/flow/instances",
            component: lazy(() => import("./instances/list/Page")),
        },
        {
            path: "/excel-standards",
            component: lazy(() => import("../flow/admin/EexcelStandards")),
        },
    ],
};
