import {finishLoading, startLoading} from "./loading.action";
import axios, {userPrefix} from "@api";

export const GET_PERMISSIONS = "GET_PERMISSIONS";

export function getPermissions(module, tag) {
    const request = axios.get(`${userPrefix}/myPermissions`, {params: {module, tag}});
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(startLoading());
            request.then((res) => {
                dispatch({
                    type: GET_PERMISSIONS,
                    data: res.data,
                    module,
                });
                dispatch(finishLoading());
                resolve();
            });
        });
    };
}

export function hasPermissions(permission, module) {
    // console.log(permission);
    return (dispatch, getState) => {
        const {permissions} = getState().fuse;
        if (!permissions[module]) return false;
        return permissions[module].includes(permission);
    };
}
