import {ConfigCompanies} from "./companies/ConfigCompanies";
import {ConfigActivities} from "./activities/ConfigActivities";
import {ConfigContacts} from "./contacts/ConfigContacts";
import {ConfigDeals} from "./deals/ConfigDeals";
import {ConfigManagement} from "./management/ConfigManagement";
import {ConfigMeeting} from "./meetings/Config";
import {ConfigArchives} from "./Archives/Config";

export const marketingConfig = [
    ConfigCompanies,
    ConfigActivities,
    ConfigContacts,
    ConfigDeals,
    ConfigManagement,
    ConfigMeeting,
    ConfigArchives
];
