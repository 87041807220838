import SvgIcon from "@material-ui/core/SvgIcon";
import {makeStyles} from "@material-ui/styles";
import _ from "lodash";

import Icon from "@material-ui/core/Icon";
import DingOriginalIcon from "../../../../../IconFontVendor/钉钉.svg";
import QQOriginalIcon from "../../../../../IconFontVendor/QQ.svg";
import WeChatOriginalIcon from "../../../../../IconFontVendor/wechat-logo-svgrepo-com.svg";
import LinkedInSvgIcon from "../../../../../IconFontVendor/linkedin-icon-2-logo-svgrepo-com.svg";
import CallSvgIcon from "../../../../../IconFontVendor/telephone.svg";
import EmailSvgIcon from "../../../../../IconFontVendor/email-chat-svgrepo-com.svg";
import VisitCustomerSVGIcon from "../../../../../IconFontVendor/hurry.svg";
import CuSTOMERcOMEiNsvgIcon from "../../../../../IconFontVendor/train-locomotive-svgrepo-com.svg";
import MeetingSVGIcon from "../../../../../IconFontVendor/meeting-svgrepo-com.svg";
import InternetChatSVGIcon from "../../../../../IconFontVendor/internet-chat.svg";

import PokerIcon from "../../../../../IconFontVendor/poker-cards-svgrepo-com.svg";
/**
 * 水疗
 * */
import SpaIcon from "../../../../../IconFontVendor/spa-svgrepo-com.svg";
/**
 * /**
 * 下棋
 * */
import ChessIcon from "../../../../../IconFontVendor/chess-svgrepo-com.svg";
/**
 * 打麻将
 * */
import MaJiangIcon from "../../../../../IconFontVendor/mahjong-red-dragon-svgrepo-com.svg";
/**
 * 房车露营
 * */
import RVCampingIcon from "../../../../../IconFontVendor/caravan-camping-svgrepo-com.svg";
/**
 * 野外露营
 * */
import CampingIcon from "../../../../../IconFontVendor/tent-camping-svgrepo-com.svg";
/**
 * 喝咖啡
 * */
import CofeIcon from "../../../../../IconFontVendor/coffee-machine-svgrepo-com.svg";
/**
 * 喝茶
 * */
import TeaIcon from "../../../../../IconFontVendor/tea-svgrepo-com.svg";
/**
 * 蒸桑拿
 * */
import SaunaIcon from "../../../../../IconFontVendor/sauna-svgrepo-com.svg";
/**
 *  游泳
 * */
import SwimmingIcon from "../../../../../IconFontVendor/swimming-swim-svgrepo-com.svg";
/**
 *  洗头
 * */
import ShampooIcon from "../../../../../IconFontVendor/person-getting-massage-svgrepo-com.svg";
/**
 *  按摩
 * */
import BodyMassageIcon from "../../../../../IconFontVendor/massage-session-svgrepo-com.svg";
/**
 *  洗脚
 * */
import FeetMassageIcon from "../../../../../IconFontVendor/feet-svgrepo-com .svg";
/*
 *  攀岩
 * */
import ClimbingIcon from "../../../../../IconFontVendor/person-climbing-svgrepo-com.svg";
/**
 *  摄影
 * */
import PhotographyIcon from "../../../../../IconFontVendor/photography-trophy-svgrepo-com.svg";
/**
 *  打篮球
 * */
import BasketballIcon from "../../../../../IconFontVendor/basketball-svgrepo-com.svg";
/**
 *  网球
 * */
import TennisIcon from "../../../../../IconFontVendor/tennis-svgrepo-com.svg";
/*
 *网球
 */
import BadmintonIcon from "../../../../../IconFontVendor/badminton-svgrepo-com.svg";
/*
 *  足球
 */
import FootballIcon from "../../../../../IconFontVendor/football-svgrepo-com.svg";
/*
 *  滑雪
 */
import SkiIcon from "../../../../../IconFontVendor/ski-svgrepo-com.svg";
/*
 *  保龄球
 */
import BowlingIcon from "../../../../../IconFontVendor/bowling-svgrepo-com.svg";
/*
 *  高尔夫
 */
import GolfIcon from "../../../../../IconFontVendor/golf-svgrepo-com.svg";
/*
 *  钓鱼
 */
import FishingIcon from "../../../../../IconFontVendor/fishing-pole-svgrepo-com.svg";
/*
 *  远足
 */
import HikingIcon from "../../../../../IconFontVendor/hiking-svgrepo-com.svg";
/*
 *  玩游戏
 */
import PlayGameIcon from "../../../../../IconFontVendor/game-console-svgrepo-com.svg";
/*
 *  KTV
 */
import KTVIcon from "../../../../../IconFontVendor/karaoke-sing-svgrepo-com.svg";
/*
 *  Party
 */
import PartyIcon from "../../../../../IconFontVendor/party-popper-svgrepo-com.svg";
/*
 *  Concert
 */
import ConcertIcon from "../../../../../IconFontVendor/concert-svgrepo-com.svg";
/*
 *  Piano
 */
import PianoIcon from "../../../../../IconFontVendor/piano-svgrepo-com.svg";
/*
 *  舞台剧
 */
import StageIcon from "../../../../../IconFontVendor/stage-svgrepo-com.svg";
/*
 *  看电影
 */
import CinemaIcon from "../../../../../IconFontVendor/video-camera-cinema-svgrepo-com.svg";
/*
 *  逛街购物
 */
import ShoppingIcon from "../../../../../IconFontVendor/shopping-bag-svgrepo-com.svg";
/*
 *  喝酒
 */
import WineIcon from "../../../../../IconFontVendor/wine-svgrepo-com.svg";

import ShakehnadleOriginalIcon from "../../../../../IconFontVendor/handshake-deal-svgrepo-com.svg";

const customStyless = makeStyles({
    imageIcon: {
        display: "flex",
        height: "inherit",
        width: "inherit",
    },
    iconRoot: {
        textAlign: "center",
    },
});

/*
 * 决策者  decisionMaker  svg  按钮
 * **/
export function KeyManSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M16.75 4.36C18.77 6.56 18.77 9.61 16.75 11.63L15.07 9.94C15.91 8.76 15.91 7.23 15.07 6.05L16.75 4.36M20.06 1C24 5.05 23.96 11.11 20.06 15L18.43 13.37C21.2 10.19 21.2 5.65 18.43 2.63L20.06 1M9 4C11.2 4 13 5.79 13 8S11.2 12 9 12 5 10.21 5 8 6.79 4 9 4M13 14.54C13 15.6 12.71 18.07 10.8 20.83L10 16L10.93 14.12C10.31 14.05 9.66 14 9 14S7.67 14.05 7.05 14.12L8 16L7.18 20.83C5.27 18.07 5 15.6 5 14.54C2.6 15.24 .994 16.5 .994 18V22H17V18C17 16.5 15.39 15.24 13 14.54Z"
            />
        </SvgIcon>
    );
}

/*
 * 非决策者  decisionMaker  svg  按钮
 * **/
export function NonKeyManSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M12.7 9.5L7.5 4.3C7.96 4.11 8.46 4 9 4C11.2 4 13 5.79 13 8C13 8.53 12.89 9.04 12.7 9.5M16.75 11.63C18.77 9.61 18.77 6.56 16.75 4.36L15.07 6.05C15.91 7.23 15.91 8.76 15.07 9.94L16.75 11.63M20.06 15C23.96 11.11 24 5.05 20.06 1L18.43 2.63C21.2 5.65 21.2 10.19 18.43 13.37L20.06 15M2.39 1.73L1.11 3L5.13 7C5.05 7.34 5 7.66 5 8C5 10.21 6.79 12 9 12C9.33 12 9.66 11.94 9.97 11.86L13 14.87C12.93 16.06 12.54 18.32 10.8 20.83L10 16L10.93 14.12C10.31 14.05 9.66 14 9 14C8.32 14 7.67 14.05 7.05 14.12L8 16L7.18 20.83C5.27 18.07 5 15.6 5 14.54C2.6 15.24 .994 16.5 .994 18V22H17V18.88L20.84 22.73L22.11 21.46L2.39 1.73Z"
            />
        </SvgIcon>
    );
}

/*
 * 关系  RelationSvgIcon  svg  按钮
 * **/
export function RelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M11 14H9C9 9.03 13.03 5 18 5V7C14.13 7 11 10.13 11 14M18 11V9C15.24 9 13 11.24 13 14H15C15 12.34 16.34 11 18 11M7 4C7 2.89 6.11 2 5 2S3 2.89 3 4 3.89 6 5 6 7 5.11 7 4M11.45 4.5H9.45C9.21 5.92 8 7 6.5 7H3.5C2.67 7 2 7.67 2 8.5V11H8V8.74C9.86 8.15 11.25 6.5 11.45 4.5M19 17C20.11 17 21 16.11 21 15S20.11 13 19 13 17 13.89 17 15 17.89 17 19 17M20.5 18H17.5C16 18 14.79 16.92 14.55 15.5H12.55C12.75 17.5 14.14 19.15 16 19.74V22H22V19.5C22 18.67 21.33 18 20.5 18Z"
            />
        </SvgIcon>
    );
}

/*
 * 普通关系  GeneralRelationSvgIcon  svg  按钮
 * **/
export function GeneralRelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
            />
        </SvgIcon>
    );
}

/*
 * 陌生关系  StrangeRelationSvgIcon  svg  按钮
 * **/
export function StrangeRelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M13,8A4,4 0 0,1 9,12A4,4 0 0,1 5,8A4,4 0 0,1 9,4A4,4 0 0,1 13,8M17,18V20H1V18C1,15.79 4.58,14 9,14C13.42,14 17,15.79 17,18M20.5,14.5V16H19V14.5H20.5M18.5,9.5H17V9A3,3 0 0,1 20,6A3,3 0 0,1 23,9C23,9.97 22.5,10.88 21.71,11.41L21.41,11.6C20.84,12 20.5,12.61 20.5,13.3V13.5H19V13.3C19,12.11 19.6,11 20.59,10.35L20.88,10.16C21.27,9.9 21.5,9.47 21.5,9A1.5,1.5 0 0,0 20,7.5A1.5,1.5 0 0,0 18.5,9V9.5Z"
            />
        </SvgIcon>
    );
}

/*
 * 朋友关系  FriendRelationSvgIcon  svg  按钮
 * **/
export function FriendRelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M15,14C12.3,14 7,15.3 7,18V20H23V18C23,15.3 17.7,14 15,14M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12M5,15L4.4,14.5C2.4,12.6 1,11.4 1,9.9C1,8.7 2,7.7 3.2,7.7C3.9,7.7 4.6,8 5,8.5C5.4,8 6.1,7.7 6.8,7.7C8,7.7 9,8.6 9,9.9C9,11.4 7.6,12.6 5.6,14.5L5,15Z"
            />
        </SvgIcon>
    );
}

/*
 * 合作伙伴关系  PartnerRelationSvgIcon  svg  按钮
 * **/
export function PartnerRelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
            />
        </SvgIcon>
    );
}

/*
 * 同行关系  PeerRelationSvgIcon  svg  按钮
 * **/
export function PeerRelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M6.2,2.44L18.1,14.34L20.22,12.22L21.63,13.63L19.16,16.1L22.34,19.28C22.73,19.67 22.73,20.3 22.34,20.69L21.63,21.4C21.24,21.79 20.61,21.79 20.22,21.4L17,18.23L14.56,20.7L13.15,19.29L15.27,17.17L3.37,5.27V2.44H6.2M15.89,10L20.63,5.26V2.44H17.8L13.06,7.18L15.89,10M10.94,15L8.11,12.13L5.9,14.34L3.78,12.22L2.37,13.63L4.84,16.1L1.66,19.29C1.27,19.68 1.27,20.31 1.66,20.7L2.37,21.41C2.76,21.8 3.39,21.8 3.78,21.41L7,18.23L9.44,20.7L10.85,19.29L8.73,17.17L10.94,15Z"
            />
        </SvgIcon>
    );
}

/*
 * 分歧者关系  DivergentRelationSvgIcon  svg  按钮
 * **/
export function DivergentRelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M14,4L16.29,6.29L13.41,9.17L14.83,10.59L17.71,7.71L20,10V4M10,4H4V10L6.29,7.71L11,12.41V20H13V11.59L7.71,6.29"
            />
        </SvgIcon>
    );
}

/*
 * 熟人关系  AcquaintanceRelationSvgIcon  svg  按钮
 * **/
export function AcquaintanceRelationSvgIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M22,4H14V7H10V4H2A2,2 0 0,0 0,6V20A2,2 0 0,0 2,22H22A2,2 0 0,0 24,20V6A2,2 0 0,0 22,4M8,9A2,2 0 0,1 10,11A2,2 0 0,1 8,13A2,2 0 0,1 6,11A2,2 0 0,1 8,9M12,17H4V16C4,14.67 6.67,14 8,14C9.33,14 12,14.67 12,16V17M20,18H14V16H20V18M20,14H14V12H20V14M20,10H14V8H20V10M13,6H11V2H13V6Z"
            />
        </SvgIcon>
    );
}

export function DingDingSvgIcon() {
    const classes = customStyless();
    return (
        <Icon classes={{root: "items-center"}}>
            <img className={classes.imageIcon} src={DingOriginalIcon} />
        </Icon>
    );
}

/*
 * QQ   svg  按钮
 * **/
export function QQChatIcon() {
    const classes = customStyless();
    return (
        <Icon classes={{root: "items-center"}}>
            <img className={classes.imageIcon} src={QQOriginalIcon} />
        </Icon>
    );
}

export function WeChatIcon() {
    const classes = customStyless();
    return (
        <Icon classes={{root: "items-center"}}>
            <img className={classes.imageIcon} src={WeChatOriginalIcon} />
        </Icon>
    );
}

export function LinkedInIcon() {
    const classes = customStyless();
    return (
        <Icon classes={{root: "items-center"}}>
            <img className={classes.imageIcon} src={LinkedInSvgIcon} />
        </Icon>
    );
}

/*
 * ----------------------- n 每行的元素个数 ---------------------------
 * */
export function getActivityTypeDataSources({n}) {
    const originalArray = [
        {GeneralMeeting: ShakehnadleOriginalIcon},
        {Calling: CallSvgIcon},
        {PlayMaJiang: MaJiangIcon},
        {PlayPoker: PokerIcon},
        {WineBureau: WineIcon},
        {KTV: KTVIcon},
        {Shopping: ShoppingIcon},
        {Fishing: FishingIcon},
        {PlayChess: ChessIcon},
        {Swimming: SwimmingIcon},
        {DrinkCofe: CofeIcon},
        {DrinkTea: TeaIcon},
        {PlayGame: PlayGameIcon},
        {Photography: PhotographyIcon},
        {Concert: ConcertIcon},
        {WatchMovie: CinemaIcon},
        {WatchStage: StageIcon},
        {PlayBadminton: BadmintonIcon},
        {PlayBasketball: BasketballIcon},
        {PlayFootball: FootballIcon},
        {PlayBowlingball: BowlingIcon},
        {PlayGolfball: GolfIcon},
        {PlayTennis: TennisIcon},
        {Climbing: ClimbingIcon},
        {Party: PartyIcon},
        {BodyMassage: BodyMassageIcon},
        {FeetMassage: FeetMassageIcon},
        {WashHeadMassage: ShampooIcon},
        {SaunaMassage: SaunaIcon},
        {Spa: SpaIcon},
        {WatchPioneShow: PianoIcon},
        {Hiking: HikingIcon},
        {Ski: SkiIcon},
        {RVCamping: RVCampingIcon},
        {Camping: CampingIcon},
    ];

    let len = originalArray.length;

    const lineNumber = _.ceil(len / n);

    let resArray = [];

    for (let i = 0; i < lineNumber; i++) {
        let temp = originalArray.slice(i * n, i * n + n);
        resArray.push(temp);
    }
    return resArray;
}

export function generateActivityTypeIcon(icon) {
    // const classes = customStyless();
    return <img className={"max-w-full max-h-full"} src={icon} />;
}

export function generateRoundActivityTypeIcon(iconName) {
    const iconsArray = [
        {GeneralMeeting: ShakehnadleOriginalIcon},
        {Calling: CallSvgIcon},
        {PlayMaJiang: MaJiangIcon},
        {PlayPoker: PokerIcon},
        {WineBureau: WineIcon},
        {KTV: KTVIcon},
        {Fishing: FishingIcon},
        {PlayChess: ChessIcon},
        {Swimming: SwimmingIcon},
        {DrinkCofe: CofeIcon},
        {DrinkTea: TeaIcon},
        {PlayGame: PlayGameIcon},
        {Photography: PhotographyIcon},
        {Concert: ConcertIcon},
        {WatchMovie: CinemaIcon},
        {WatchStage: StageIcon},
        {Spa: SpaIcon},
        {Shopping: ShoppingIcon},
        {BodyMassage: BodyMassageIcon},
        {FeetMassage: FeetMassageIcon},
        {WashHeadMassage: ShampooIcon},
        {SaunaMassage: SaunaIcon},
        {PlayBadminton: BadmintonIcon},
        {PlayBasketball: BasketballIcon},
        {PlayFootball: FootballIcon},
        {PlayBowlingball: BowlingIcon},
        {PlayGolfball: GolfIcon},
        {PlayTennis: TennisIcon},
        {Climbing: ClimbingIcon},
        {Party: PartyIcon},
        {WatchPioneShow: PianoIcon},
        {Hiking: HikingIcon},
        {Ski: SkiIcon},
        {RVCamping: RVCampingIcon},
        {Camping: CampingIcon},
        {call: CallSvgIcon},
        {internetChat: InternetChatSVGIcon},
        {visitingCustomers: VisitCustomerSVGIcon},
        {customerVisit: CuSTOMERcOMEiNsvgIcon},
        {meeting: MeetingSVGIcon},
        {Email: EmailSvgIcon},
    ];

    /**************************     /salesEnumProperties?type=Sales Activity Type      **********************/
    /*
     * [
     * {"value":"电话","key":"call"},
     * {"value":"网络聊天","key":"internetChat"},
     * {"value":"拜访客户","key":"visitingCustomers"},
     * {"value":"客户拜访","key":"customerVisit"},
     * {"value":"会议","key":"meeting"},
     * {"value":"电子邮件","key":"Email"}
     * ]
     * **/

    return (
        <Icon fontSize={"large"} className={"max-w-full"}>
            <img
                className={"flex rounded-full max-w-full"}
                src={Object.values(iconsArray.filter((item) => Object.keys(item)[0] === iconName)[0])[0]}
                alt={"NO"}
            />
        </Icon>
    );
}

export function getTheIconByName(name) {
    const iconsArray = [
        {GeneralMeeting: ShakehnadleOriginalIcon},
        {Calling: CallSvgIcon},
        {PlayMaJiang: MaJiangIcon},
        {PlayPoker: PokerIcon},
        {WineBureau: WineIcon},
        {KTV: KTVIcon},
        {Fishing: FishingIcon},
        {PlayChess: ChessIcon},
        {Swimming: SwimmingIcon},
        {DrinkCofe: CofeIcon},
        {DrinkTea: TeaIcon},
        {PlayGame: PlayGameIcon},
        {Photography: PhotographyIcon},
        {Concert: ConcertIcon},
        {WatchMovie: CinemaIcon},
        {WatchStage: StageIcon},
        {Spa: SpaIcon},
        {Shopping: ShoppingIcon},
        {BodyMassage: BodyMassageIcon},
        {FeetMassage: FeetMassageIcon},
        {WashHeadMassage: ShampooIcon},
        {SaunaMassage: SaunaIcon},
        {PlayBadminton: BadmintonIcon},
        {PlayBasketball: BasketballIcon},
        {PlayFootball: FootballIcon},
        {PlayBowlingball: BowlingIcon},
        {PlayGolfball: GolfIcon},
        {PlayTennis: TennisIcon},
        {Climbing: ClimbingIcon},
        {Party: PartyIcon},
        {WatchPioneShow: PianoIcon},
        {Hiking: HikingIcon},
        {Ski: SkiIcon},
        {RVCamping: RVCampingIcon},
        {Camping: CampingIcon},
        {call: CallSvgIcon},
        {internetChat: InternetChatSVGIcon},
        {visitingCustomers: VisitCustomerSVGIcon},
        {customerVisit: CuSTOMERcOMEiNsvgIcon},
        {meeting: MeetingSVGIcon},
        {Email: EmailSvgIcon},
    ];

    return Object.values(iconsArray.filter((item) => Object.keys(item)[0] === name)[0])[0];
}
