import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_PROCESSDEFINE_SINGLE = "[GET_PROCESSDEFINE_SINGLE]";
export const RESET_PROCESSDEFINE_SINGLE = "[GET_PROCESSDEFINE_SINGLE]";
export const HANDLE_FOCUS_DIAGRAM = "[HANDLE_FOCUS_DIAGRAM]";
export const GET_PROCESSDEFINE_SINGLE_USERS_AND_TASKS = "[GET_PROCESSDEFINE_SINGLE_USERS_AND_TASKS]";
export const START_PAUSE_DEFINITION_ACTION = "[START_PAUSE_DEFINITION_ACTION]";


export function getProcessDefinitionSingle(id, callback, catchError) {

    const params = {id: id};
    const request = axios.get(`${flowPrefix}/processDefinition`, {params: params});
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_PROCESSDEFINE_SINGLE,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}

export function getProcessDefinitionSingleUsers(id, callback) {

    const params = {processDefinitionId: id};
    const request = axios.get(`${flowPrefix}/processDefinition/users`, {params: params});
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_PROCESSDEFINE_SINGLE_USERS_AND_TASKS,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function resetDefinitionSingle() {
    return {
        type: RESET_PROCESSDEFINE_SINGLE
    };
}




