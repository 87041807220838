import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_PROCESS_DEPLOYMENTS = "[GET_PROCESS_DEPLOYMENTS]";
export const SELECT_A_PROCESS_DEPLOY = "[SELECT_A_PROCESS_DEPLOY]";

export function getDeploys(params) {
    const request = axios.get(`${flowPrefix}/deployments`,{params});
    return (dispatch) => {
        request.then(response => {
            dispatch({
                type: GET_PROCESS_DEPLOYMENTS,
                payload: response.data
            });
        });
    };
}

export function selectADeploy(_deploy) {
    return {
        type: SELECT_A_PROCESS_DEPLOY,
        payload: _deploy
    };
}
