import {TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import {MyTooltip} from "@ui";
import classNames from "classnames";
import {useEffect} from "react";
import {Translate} from "react-localize-redux";
import EditorInput from "utils/EditorInput/EditorInput";
import {ContactSelector} from "../../marketingTools";
import {generateActivityTypeIcon, getActivityTypeDataSources} from "../VendorIcons/VendorIcons";

const Step0 = ({formData, handleFormData}) => {
    useEffect(() => {
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Translate>
                    {({translate}) => (
                        <TextField
                            InputLabelProps={{
                                required: true
                            }}
                            inputProps={{maxLength: 60}}
                            fullWidth
                            placeholder={translate("market.enter the activity title here")}
                            label={translate("market.title")}
                            value={formData.title}
                            onChange={handleFormData("title")}
                        />
                    )}
                </Translate>
            </Grid>
            <Grid item xs={12} md={6}>
                <ContactSelector required formData={formData} handleFormData={handleFormData} />
            </Grid>
            <Grid item xs={12}>
                <EditorInput
                    rows={"88"}
                    name={"description"}
                    disableMention
                    value={formData.description}
                    onChange={(e) => handleFormData("description")({target: {value: e.target.value}})}
                />
            </Grid>

            <Grid item xs={12}>
                <div className="flex flex-col">
                    <InputLabel required>
                        <Translate id={"_.type"} />
                    </InputLabel>
                    {getActivityTypeDataSources({n: 35}).map((sunArray, index) => {
                        return (
                            <div key={index} className="justify-between ">
                                {sunArray.map((item, subIn) => {
                                    return (
                                        <MyTooltip
                                            key={subIn}
                                            arrow
                                            title={<Translate id={`market.${Object.keys(item)[0]}`} />}
                                        >
                                            <button
                                                key={`${Object.keys(item)[0]}`}
                                                onClick={() =>
                                                    handleFormData("type")({target: {value: `${Object.keys(item)[0]}`}})
                                                }
                                                className={classNames(
                                                    "flex-shrink-0 rounded p-12 m-2 w-68 h-68",
                                                    formData.type === `${Object.keys(item)[0]}` && "bg-primary"
                                                )}
                                            >
                                                {generateActivityTypeIcon(Object.values(item)[0])}
                                            </button>
                                        </MyTooltip>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </Grid>
        </Grid>
    );
};

export default Step0;
