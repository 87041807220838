import { GET_BRAND_CATEGORIES, GET_BRANDS, BRAND_CHANGE_VIEW, SELECT_BRAND_CAT, SELECT_BRAND, PASS_BRAND_ID_TO_MAP, CLEAR_TO_MAP_BRAND_ID, CLEAR_BRAND } from "../actions";

const initialState = {
    content      : [],
    view      : 0,  // 0 = list , 1 = grid , 2 = kanban
    categories: [],
    selectedCategory    : null,
    selectedBrand: null,
    totalElements: 0,
    searchText: "",
    brandIdToMap: "",
};

const brandsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_BRAND_CATEGORIES: {
            return {
                ...state,
                categories: action.payload
            };
        }
        case GET_BRANDS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case BRAND_CHANGE_VIEW: {
            return {
                ...state,
                view: action.view
            };
        }
        case SELECT_BRAND_CAT: {
            return {
                ...state,
                selectedCategory: action.cat
            };
        }
        case SELECT_BRAND: {
            return {
                ...state,
                selectedBrand: action.brand
            };
        }
        case PASS_BRAND_ID_TO_MAP:
            return {
              ...state,
                brandIdToMap: action.brandId
            };
        case CLEAR_TO_MAP_BRAND_ID:
            return {
                ...state,
                brandIdToMap: "",
            };
        case CLEAR_BRAND:
            return {
              ...state,
                selectedBrand: null,
            };
        default: {
            return state;
        }
    }
};

export default brandsReducer;
