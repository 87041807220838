

export  const  BASE_URL_FOR_QRCODE = "https://www.signabc.com/pages/index/index";



/*
*  二维码展示 model  开关 identifier
* */
export const QRCODMODELNAME = "[QRCODMODELNAME]";

/*
*  大图展示 dialog  开关 identifier
* */
export const FULL_SCREEN_IMAGE_DIALOG = "[FULL_SCREEN_IMAGE_DIALOG]";
/*
* checkin_results_filter_bar
* external-users-list
* */
/*
*  checkin_results 筛选栏 identifier
* */
export const CHECKIN_RESULTS_FILTER_BAR_NAME = "[CHECKIN_RESULTS_FILTER_BAR_NAME]";

/*
*  external-users  筛选 identifier
* */
export const EXTERNAL_USERS_FILTER_BAR_NAME = "[EXTERNAL_USERS_FILTER_BAR_NAME]";


/*
*  "generated-ercode-list";  筛选 identifier
* */
export const GENERATED_QRCODE_LIST_FILTER_BAR_NAME = "[GENERATED_QRCODE_LIST_FILTER_BAR_NAME]";




/*
*  "PROJECT_NAME_FOR_CHECKIN_RESULTS";  保存项目签到详情的项目名的key
* */
export const PROJECT_NAME_FOR_CHECKIN_RESULTS = "[PROJECT_NAME_FOR_CHECKIN_RESULTS]";
