// import {createContext, useContext} from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {useAuth} from "../app/auth/AuthProvider";
import {db} from "./db";

// export const PersonalizedContext = createContext<PersonalizedContent>(
//     {
//         settings:  {
//             language: "cn",
//         },
//         filters: {},
//     }
// );
//
// export const usePersonalized = () => useContext(PersonalizedContext);
//
// export const PersonalizedProvider = ({children}) => {
//     return <PersonalizedContext.Provider value={null}>{children}</PersonalizedContext.Provider>;
// };

export const usePersonalized = () => {
    const {user} = useAuth();
    const userId = user?.id;
    const settings = useLiveQuery(() => (userId ? db.settings.get(userId) : undefined));
    // const filters = useLiveQuery(() => (userId ? db.filters.where("userId").equals(userId).toArray() : []));
    // const latest = useLiveQuery(() => (userId ? db.latest.where("userId").equals(userId).toArray() : []));

    const init = async (userId?: string) => {
        try {
            if (!userId) return;
            const isInit = await db.settings.get(userId);
            if (isInit) return;
            await db.settings.add({
                userId,
                language: "cn",
                listView: "0",
                rowPerPage: 20,
                WPSViewColumn: 3,
                WPSView: "NewDaily",
                hideDoneChecklist: false,
            });
            return;
        } catch (error) {
            console.error("Error  init:", error);
            return false;
        }
    };

    const updateSettings = async (changes: Record<string, unknown>) => {
        try {
            await init(userId);

            if (userId) {
                await db.settings.update(userId, changes);
                console.log("updated successfully");
            }
        } catch (error) {
            console.error("Error updated data:", error);
        }
    };

    const updateFilters = async (key: string, changes: Record<string, unknown>) => {
        try {
            if (userId) {
                await db.filters.update(key, changes);
                console.log("updated successfully");
            }
        } catch (error) {
            console.error("Error updated data:", error);
        }
    };

    const addFilter = async (key: string, filter: Record<string, unknown>) => {
        try {
            if (userId) {
                await db.filters.add({userId, key, ...filter});
                console.log("added successfully");
            }
        } catch (error) {
            console.error("Error add data:", error);
        }
    };

    const addLatest = async (key: string, value: Record<string, unknown>) => {
        try {
            if (userId) {
                await db.latest.add({userId, key, value});
                console.log("added successfully");
            }
        } catch (error) {
            console.error("Error add data:", error);
        }
    };

    const getLatest = async (key: string) => {
        if (userId) {
            return await db.latest.filter((x) => x.key === key && userId === x.userId).toArray();
        } else {
            return;
        }
    };

    const deleteFilter = async (id: string) => {
        try {
            if (userId) {
                await db.filters.delete(id);
                console.log("deleted successfully");
            }
        } catch (error) {
            console.error("Error deleted data:", error);
        }
    };

    async function deleteLatest(key: string) {
        await db.transaction("rw", "latest", async () => {
            const matchingKeys = await db.latest.filter((x) => x.key === key && userId === x.userId).keys();
            console.log(matchingKeys);
            await db.latest.bulkDelete(matchingKeys);

            console.log(`Deleted ${matchingKeys.length} items`);
        });
    }

    async function addOrUpdateFilters(key: string, data: Record<string, any>) {
        return await db.transaction("rw", "filters", async () => {
            const existingData = await db.filters.get(key);
            if (existingData) {
                // Update existing data
                await db.filters.put({userId, key, ...data});
                return {message: "Data updated successfully"};
            } else {
                // Add new data
                await db.filters.add({userId, key, ...data});
                return {message: "Data added successfully"};
            }
        });
    }

    async function getFilter(key: string) {
        return db.filters.filter((x) => x.key === key && userId === x.userId).first();
    }

    async function getSetting(key?: string) {
        if (!userId) return;
        const t = await db.settings.get(userId);
        if (t) {
            if (key) return t[key];
            else return t;
        }
    }

    // const updateLatest = async (changes: Record<string, unknown>) => {
    //     try {
    //         if (userId) {
    //             await db.latest.update(userId, changes);
    //             console.log("updated successfully");
    //         }
    //     } catch (error) {
    //         console.error("Error bulkPut data:", error);
    //     }
    // };
    //
    // const addLatest = async (latest: Record<string, unknown>) => {
    //     try {
    //         if (userId) {
    //             await db.latest.add({userId, ...latest});
    //             console.log("added successfully");
    //         }
    //     } catch (error) {
    //         console.error("Error bulkPut data:", error);
    //     }
    // };
    //
    // const deleteLatest = async (id: string) => {
    //     try {
    //         if (userId) {
    //             await db.latest.delete(id);
    //             console.log("deleted successfully");
    //         }
    //     } catch (error) {
    //         console.error("Error bulkPut data:", error);
    //     }
    // };

    return {
        deleteLatest,
        getLatest,
        addLatest,
        getSetting,
        settings,
        getFilter,
        // filters,
        // latest,
        updateFilters,
        addFilter,
        deleteFilter,
        init,
        updateSettings,
        addOrUpdateFilters,
    };
};
