import { GET_PRODUCTS, GET_PRODUCT_CATEGORIES, SELECT_PRODUCT_CAT, PRODUCTS_CHANGE_VIEW, SELECT_PRODUCT, PASS_SELECTED_PRODUCTID, REMOVE_ALL_SELECTED_PRODUCTS } from "../actions";

const initialState = {
    content                : [],
    view                   : 0,  // 0 = list , 1 = grid , 2 = kanban
    categories             : [],
    totalElements          : 0,
    selectedCategory       : null,
    searchText             : "",
    selectedProductedIds   : []
};

const productsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_PRODUCTS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case GET_PRODUCT_CATEGORIES: {
            return {
                ...state,
                categories: action.payload
            };
        }
        case SELECT_PRODUCT_CAT: {
            return {
                ...state,
                selectedCategory: action.cat
            };
        }
        case PRODUCTS_CHANGE_VIEW: {
            return {
                ...state,
                view: action.view
            };
        }
        case SELECT_PRODUCT: {
            return {
                ...state,
                selectedProduct: action.payload
            };
        }
        case PASS_SELECTED_PRODUCTID: {
            return {
                ...state,
                selectedProductedIds: action.productIds
            };
        }
        case REMOVE_ALL_SELECTED_PRODUCTS: {
            return {
                ...state,
                selectedProductedIds: []
            };
        }
        default: {
            return state;
        }
    }
};

export default productsReducer;
