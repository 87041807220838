import {IconButton, Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {MyAvatar, MyTooltip, NameLabel} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import axios, {crmPrefix} from "../../../../../myaxios";
import {KeyManSvgIcon, NonKeyManSvgIcon} from "../../activities/VendorIcons/VendorIcons";
import ItemHeader from "../../utils/ItemHeader";
import * as Actions from "../store/actions";

class Header extends Component {
    state = {
        checkbox: false,
        itemChangesCount: 0,
        user: "",
        userId: ""
    };

    setDecisionMaker = () => {
        const {item, getItem, getList} = this.props;

        const parameter = {
            contactId: item.id,
            isDecisionMaker: !item.isDecisionMaker
        };

        axios.put(`${crmPrefix}/contactSetDecisionMaker`, parameter).then(() => {
            const message =
                !item.isDecisionMaker === true
                    ? "message.current ComapnyContact has been setted decisionMaker!"
                    : "message.current ComapnyContact has been setted Non-decision maker!";
            this.props.showMessage({variant: "success", message: message});
            getItem(item.id);
            getList();
        });
    };

    closeDialog = () => {
        this.props.turnOff("contactDetail");
    };

    render() {
        const {item} = this.props;

        return (
            <>
                <ItemHeader
                    {...this.props}
                    actions={
                        <>
                            <MyTooltip title={<Translate id={item.isDecisionMaker ? "market.DecisionMaker" : "market.Not decisionMaker"} />}>
                                <IconButton
                                    onClick={() => {
                                        this.setDecisionMaker();
                                    }}
                                >
                                    {item.isDecisionMaker === true ? <KeyManSvgIcon className="text-red" /> : <NonKeyManSvgIcon />}
                                </IconButton>
                            </MyTooltip>
                        </>
                    }
                    title={
                        <div className={"flex flex-col w-full"}>
                            <div className={"flex flex-row items-center"}>
                                <MyAvatar src={item.avatar} alt={item.fullName} size={48} />
                                <div className={"flex flex-col ml-12"}>
                                    <Typography variant={"h5"} className={classNames("flex")}>
                                        <span className={"flex flex-row font-bold"}>
                                            <NameLabel user={item} />

                                            {item.title && item.title !== "" ? (
                                                <small className={"flex items-center ml-1 mb-2"}>({item.title})</small>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </Typography>
                                    <div className={"flex flex-row ml-2 items-center"}>
                                        {!!item.department && (
                                            <MyTooltip title={<Translate id={"market.department"} />}>
                                                <Chip size={"small"} color={"secondary"} label={item.department} className={"mr-2"} />
                                            </MyTooltip>
                                        )}
                                        {!!item.assistant && (
                                            <MyTooltip title={<Translate id={"market.assistant"} />}>
                                                <Chip size={"small"} color={"secondary"} label={item.assistant} className={"mr-2"} />
                                            </MyTooltip>
                                        )}
                                        {!!item.relationShip && (
                                            <MyTooltip title={<Translate id={"market.relationShip"} />}>
                                                <Chip size={"small"} color={"secondary"} label={item.relationShip.value} className={"mr-2"} />
                                            </MyTooltip>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: fuseActions.turnOn,
            turnOff: fuseActions.turnOff,
            showMessage: fuseActions.showMessage,

            selectItem: Actions.selectItem,
            getItem: Actions.getItem,
            getList: Actions.getList,
            selectItemForEditCompany: Actions.selectItemForEditCompany
        },
        dispatch
    );
}

function mapStateToProps({marketing}) {
    return {
        item: marketing.companyContacts.item
    };
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Header));
