import * as Actions from "../actions";
import {cloneDeep} from "lodash";
const initialState = {
    /*
    * {
  "page": 0,
  "size": 0,
  "totalPages": 0,
  "totalElements": 0,
  "content": [
    {
      "id": "string",
      "description": "string",
      "importCount": 0,
      "incomeCount": 0,
      "user": {},
      "timestamp": 0
    }
  ]
}
    * */
    incomeOrdersRes: null,
    selectedIncomeOrder: null,
    selectedIncomeID: "",
};

const incomeOrdersDistrubuteReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_INCOME_ORDERS_DISTRIBUTIONS:{
            let tobj = cloneDeep(state);
            tobj.incomeOrdersRes = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     incomeOrdersRes: action.payload
            // };
        }
        case Actions.SELECTED_A_INCOME_ORDERS_DISTRIBUTION:{
            let tobj = cloneDeep(state);
            tobj.selectedIncomeOrder = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedIncomeOrder: action.payload
            // };
        }
        case Actions.SELECTED_A_INCOMEID_FOR_ORDERS_DISTRIBUTION:{
            let tobj = cloneDeep(state);
            tobj.selectedIncomeID = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedIncomeID: action.payload
            // };
        }
        default:{
            return state;
        }
    }
};

export default incomeOrdersDistrubuteReducer;
