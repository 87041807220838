import {Button, DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {DialogWrapper} from "@ui";
import {useCallback, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import Webcam from "react-webcam";
import { turnOn } from "@fuseActions";
import {saveFile} from "../fileUploader";
import {dataURItoBlob} from "../utilities";

const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user"
};

const CameraDialog = ({addImageToText}) => {
    const [src, setSrc] = useState("");
    const webcamRef = useRef(null);
    const dispatch = useDispatch();

    // const capture = () => {
    //     const {getScreenshot} = webcamRef;
    //     const imageSrc = getScreenshot();
    //     this.setState({src: imageSrc});
    // };

    const capture = useCallback(() => {
        // @ts-ignore
        const imageSrc = webcamRef?.current?.getScreenshot();
        setSrc(imageSrc);
    }, [webcamRef]);

    const convertToFile = () => {
        const blob = dataURItoBlob(src);
        saveFile(blob, (status, data) => {
            addImageToText(data);
            dispatch(turnOn("cameraDialog"));
        });
    };

    return (
        <DialogWrapper name={"cameraDialog"} header={<Translate id="_.Take picture" />}>
            {() => (
                <>
                    <DialogContent>
                        {src === "" ? (
                            <Webcam
                                audio={false}
                                height={500}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={500}
                                videoConstraints={videoConstraints}
                            />
                        ) : (
                            <img src={src} width={500} height={500} alt={"profile"} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        {src === "" ? (
                            <>
                                <Button onClick={capture} variant="contained" color="primary">
                                    <Translate id="_.Capture photo" />
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={() => setSrc("")} variant="contained" color="primary">
                                    <Translate id="_.Retake" />
                                </Button>
                                <Button
                                    onClick={() => convertToFile()}
                                    variant="contained"
                                    color="primary"
                                    autoFocus={true}
                                >
                                    <Translate id="_.Use this picture" />
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </>
            )}
        </DialogWrapper>
    );
};

export default CameraDialog;
