import {makeStyles, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {EmptyView} from "@ui";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import "../../utils/style/custom.css";
import ExpandAccordion from "./ExpandAccordion";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh"
    },
    paper: {
        margin: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary
    },
    originBottomCenter: {
        zIndex: 99999999
    }
}));

function ExternalList(props) {
    const classes = useStyles();
    const checkinResults = useSelector(({externalM}) => externalM.checkin_Results.checkinResults);
    let {projectId} = useParams();
    return (
        <Grid container className={classes.root} spacing={3}>
            {checkinResults && checkinResults.length > 0 ? (
                <Grid item xs={12}>
                    {checkinResults.map((_check, _index) => (
                        <Paper elevation={2} key={_index} className={classes.paper}>
                            <ExpandAccordion key={_index} external={_check} />
                        </Paper>
                    ))}
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <EmptyView
                        label={
                            projectId ? (
                                <Translate id="exm.No data available" />
                            ) : (
                                <Translate id="exm.Please select the project first" />
                            )
                        }
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default ExternalList;
