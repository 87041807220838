import {Dialog} from "@material-ui/core";
import {EmptyView} from "@ui";
import {useMemoizedFn} from "ahooks";
import {useEffect} from "react";
import ImageGallery from "react-image-gallery";
import {useEXManageStore} from "../../state/useEXManageStore";

function EXImageGallery(props) {

    const currentIndex = useEXManageStore((state) => state.currentIndex);
    const openGallery = useEXManageStore((state) => state.openGallery);
    const images = useEXManageStore((state) => state.images);

    const onChangeGallery = useEXManageStore((state) => state.onChangeGallery);

    useEffect(() => {
        console.log("EXImageGallery useEffect images", images);
    }, []);


    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onChangeGallery(false).then(() => {
        });
    });

    if (!images) {
        return (
            <Dialog
                BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
                maxWidth={"lg"} classes={{root: "min-h-224"}} open={openGallery} onClose={handleClose} fullWidth>
                <EmptyView />
            </Dialog>
        );
    }

    if (openGallery) {
        return (
            <Dialog
                BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
                maxWidth={"lg"} classes={{root: "min-h-224"}} open={openGallery} onClose={handleClose} fullWidth>
                <ImageGallery
                    lazyLoad
                    items={images}
                    thumbnailPosition={"bottom"}
                    startIndex={currentIndex}
                    showIndex
                />
            </Dialog>
        );
    }
}

export default EXImageGallery;
