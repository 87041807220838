import {forwardRef, useMemo} from "react";
import {useMemoizedFn, useMount} from "ahooks";
import {Translate, withLocalize} from "react-localize-redux";
import translate from "./translate.json";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import {FModelType, useFuseModel} from "./index";
import WarningIcon from "../../../IconFontVendor/警告.svg";
import SuccessIcon from "../../../IconFontVendor/成功.svg";
import ErrorIcon from "../../../IconFontVendor/错误.svg";
import LFSvgIcon from "../../../utils/RootComponents/LFSvgIcon";

const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

function FuseModel(props) {
    const {open, options, hideModel} = useFuseModel();

    useMount(() => {
        props.addTranslation(translate);
    });

    const getSRC = useMemo(() => {
        if (options?.type === FModelType.warn) {
            return WarningIcon;
        }
        if (options?.type === FModelType.success) {
            return SuccessIcon;
        }
        if (options?.type === FModelType.error) {
            return ErrorIcon;
        }
        return "";
    }, [options?.type]);

    const onHandleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        hideModel();
    });

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={"xs"}
                onClose={onHandleClose}
                aria-labelledby="FuseModel-fade-title"
                aria-describedby="FuseModel-fade-description"
            >
                <DialogContent>
                    <div className="flex flex-col flex-grow justify-center items-center p-10">
                        <div className="flex flex-row items-center" id="FuseModel-fade-title">
                            <LFSvgIcon svg={getSRC} style={{height: "60px", width: "60px"}} />
                        </div>
                        <div className="flex flex-row items-center mt-10" id="FuseModel-fade-description">
                            {options?.content}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {!!options && !!options.actions ? (
                        options.actions
                    ) : (
                        <Button onClick={onHandleClose} variant="contained" color="secondary">
                            {<Translate id={"fmText.cancel"} />}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withLocalize(FuseModel);
