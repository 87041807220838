import {create} from "zustand";
import {createDefinitionSlice} from "./createDefinitionSlice";
import {createDialogSlice} from "./createDialogSlice";
import {createStepsSlice} from "./createStepsSlice";
import {createInstanceListSlice} from "./createInstanceListSlice";
import {createInstanceSingleSlice} from "./createInstanceSingleSlice";
import {createRefreshSlice} from "./createRefreshSlice";

export const useProcessStore = create((...a)=>({
    ...createDialogSlice(...a),
    ...createDefinitionSlice(...a),
    ...createStepsSlice(...a),
    ...createInstanceListSlice(...a),
    ...createInstanceSingleSlice(...a),
    ...createRefreshSlice(...a),
}));
