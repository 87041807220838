import {useMemo} from "react";
import useSyncSelectStore from "./useSyncSelectStore";

/**
 * @Returns {Object} - 返回一个对象 {refreshId, onChangeRefreshId}
 */
function useAsyncSelectUtils() {
    const refreshId = useSyncSelectStore((state) => state.refreshId);

    const selectActions = useSyncSelectStore((state) => state.selectActions);

    const {onChangeRefreshId} = selectActions;

    return useMemo(
        () => ({
            refreshId,
            onChangeRefreshId,
        }),
        [onChangeRefreshId, refreshId]
    );
}

export default useAsyncSelectUtils;
