import axios, {firmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";
import {GET_STATS} from "./red-packet-manager.action";

export const  SELECT_ONE_RED_PACKET_TEMPORY_PER_MONTH = "[SELECT_ONE_RED_PACKET_9878776]";
export const  UNSELECT_ONE_RED_PACKET_TEMPORY_PER_MONTH = "[UNSELECT_ONE_RED_PACKET_TEMPORY_PER_MONTH_98dsfs78776]";





export function unSelectRank() {
    return {
        type: UNSELECT_ONE_RED_PACKET_TEMPORY_PER_MONTH
    };
}


export function getRedPacketStats(params) {
    const request = axios.get(`${firmPrefix}/redPacketStats`,{params: params});
    return (dispatch) => {
        dispatch(startLoading());
        request.then(res =>{
            dispatch({
                type: GET_STATS,
                payload: res.data,
            });
            dispatch(finishLoading());
        });
    };
}



