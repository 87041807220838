import {useState} from "react";
import {Divider, Icon, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {Translate, withLocalize} from "react-localize-redux";
import ListSubheader from "@material-ui/core/ListSubheader";
import TextField from "@material-ui/core/TextField";
import axios, {crmPrefix} from "../../../../../myaxios";

import _ from "lodash";
import {AddressItem} from "../../contacts/contact/RightSidebar";
import Owner from "../../utils/Owner";
import {CreateAndActiveDateItem} from "../../marketingTools";
import Members from "./Members";
import PageItem from "../list/ItemView";
import Card from "@material-ui/core/Card";

const ContactItem = ({type, icon ,sourceItem, onClickButton, refresh, canEdit  }) => {
    const [edit, setEdit] = useState(sourceItem[type].trim() === "");
    const isEdit = sourceItem[type].trim() !== "" && edit;
    const [value, setValue] = useState(sourceItem[type]);

    const addContactItem = () => {
        const data = {
            id: sourceItem.id,
            name: null,
            [type]: value
        };
        axios.put(`${crmPrefix}/company`, data).then(() => {
            refresh();
            setEdit(false);
        });
    };

    return <ListItem >
        {!edit ?
            <ListItemText
                primary={sourceItem[type]}
                secondary={<Translate id={`market.${type}`}/>}
            />
            :
            canEdit ? <div className={"flex items-center"}>
                { <Translate>
                    {({ translate }) =>
                        <TextField fullWidth placeholder={`${isEdit ? translate("market.Edit") : translate("market.Add") + translate(`market.${type}`)}`} value={value} onChange={(e)=>setValue(e.target.value)}/>
                    }
                 </Translate> }

                <IconButton  disabled={value.trim() === ""} onClick={addContactItem}><Icon>check</Icon></IconButton>
                {isEdit && <IconButton onClick={()=>setEdit(false)}><Icon>close</Icon></IconButton>}
            </div>
                : <ListItemText
                    primary={<>No <Translate id={`market.${type}`}/></>}
                />
        }
        <ListItemSecondaryAction>
            {!edit &&
            <>
                {canEdit && <IconButton className="mr-1" onClick={()=>setEdit(true)} size={"small"}>
                    <Icon fontSize={"small"}>edit</Icon>
                </IconButton>}
                <IconButton  color={"secondary"} disabled={!onClickButton} edge="end" aria-label="delete">
                    <Icon>{icon}</Icon>
                </IconButton>
            </>
            }
        </ListItemSecondaryAction>
    </ListItem>;
};

const RightSidebar = ({sourceItem, refresh, canEdit, activeLanguage}) => {
    const contactProps = {
        refresh,
        sourceItem,
        canEdit,
    };

    return (
        <Card  className="m-20 ml-0  ">
            <Owner user={sourceItem.user}/>
            <List dense subheader={<ListSubheader><Translate  id={"market.Contact Information"}/></ListSubheader>}>
                <ContactItem {...contactProps} type={"phone"} onClickButton  icon={"local_phone"}/>
                <ContactItem {...contactProps} type={"website"}  onClickButton  icon={"phonelink"}/>
                <ContactItem {...contactProps} type={"fax"}   icon={"scanner"}/>
                { _.isObject(sourceItem.address) && <AddressItem address={sourceItem.address} language={activeLanguage}/> }
            </List>

            {(sourceItem.categories && sourceItem.categories.length > 0) &&
                <>
                <Divider />
                <List dense subheader={<ListSubheader><Translate  id={"market.Categories"}/></ListSubheader>}>
                    {sourceItem.categories.map(_=>
                        <>
                            <ListItem><ListItemText primary={<strong>{_.name}</strong>}/></ListItem>
                            {_.companies.map(c => c.id !== sourceItem.id && <PageItem small  item={c} />)}
                        </>
                        )}
                </List>
                </>}
                {/*<ListItem >*/}
                {/*    <ListItemText*/}
                {/*        primary={sourceItem.address && sourceItem.address.city.trim() !== "" ? sourceItem.address.city + sourceItem.address.province + sourceItem.address.country  : <Translate id={"market.No address"}/>}*/}
                {/*        secondary={sourceItem.address && sourceItem.address.city.trim() !== "" && <Translate id={'market.Address'}/>}*/}
                {/*    />*/}
                {/*    <ListItemSecondaryAction>*/}
                {/*        {sourceItem.address && sourceItem.address.city.trim() !== "" &&*/}
                {/*        <IconButton disabled color={"secondary"} edge="end" aria-label="delete">*/}
                {/*            <Icon>location_on</Icon>*/}
                {/*        </IconButton>*/}
                {/*        }*/}
                {/*    </ListItemSecondaryAction>*/}
                {/*</ListItem>*/}
                <Members participants={sourceItem.members} canEdit={canEdit}/>
                {/*<MembersInfo />*/}
            <Divider />
            {
                (sourceItem.createDate || sourceItem.activeDate) && <CreateAndActiveDateItem sourceItem={sourceItem} />
            }
        </Card>
    );
};

export default withLocalize(RightSidebar);
