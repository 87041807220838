import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_DECISIONS_LIST = "[GET_DECISIONS_LIST]";
export const SELECT_A_DECISION = "[SELECT_A_DECISION]";

export function getDecisions(params, callback, catchError) {
    const request = axios.get(`${flowPrefix}/decisionsAdmin`, {
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_DECISIONS_LIST,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(err => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}
