import {Button, Icon} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {CodeView, LocalTimeStamp, MyTooltip} from "@ui";
import {isValid} from "date-fns";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {turnOn} from "@fuseActions";
import {ErrorButton} from "../../utilComponents";

type Props = {
    filterData(arg0: {[key: string]: any} | string): void;
    clearSearch(): void;
    noSaveSearch?: boolean;
    openSidebar(): void;
    name: string;
    translationKey?: string;
};

export const defaultIgnoreParams = ["keyword", "page", "size", "sord", "sidx"];

const isBool = (v) => v === "true" || typeof v === "boolean" || v === "false";
const isTimestamp = (v) => parseInt(v) > 949334400000 && parseInt(v) < 2527257600000;

export const chipSearchValue: (value: any, translationKey?: string) => any = (value, translationKey = "_") => {
    if (isBool(value)) return "";
    if ((parseInt(value) + "").length > 8 && isTimestamp(value) && isValid(parseInt(value))) return <LocalTimeStamp datetime={+value} />;
    // if (value.length > 10) return value.slice(value.length - 5, value.length) + "...";
    if (value.length > 15) return <CodeView dense>{value}</CodeView>;
    return (
        <>
            <Translate>{({translate}) => <>{translate(`${translationKey}.${value}`, null, {onMissingTranslation: () => ""})}</>}</Translate>
        </>
    );
};

const ViewSearches = ({filterData, clearSearch, noSaveSearch, openSidebar, name, translationKey}: Props) => {
    const dispatch = useDispatch();
    // @ts-ignore
    const search: {[key: string]: string | number | boolean} = useSelector(({fuse}) => fuse.search);
    // @ts-ignore
    const dialogs: {[key: string]: boolean} = useSelector(({fuse}) => fuse.dialogs);
    // const location = useLocation();

    const hasFilter =
        Object.keys(search).findIndex((key) => {
            return !defaultIgnoreParams.includes(key);
        }) > -1;

    // const chipSearchValue = (key: string) => {
    //     if (key === "plant") {
    //         // @ts-ignore
    //         return search[key];
    //     }
    //     // @ts-ignore
    //     if (moment(+search[key]).isValid()) return <LocalTimeStamp datetime={+search[key]} />;
    //
    //     const v = search[key] === "true" || search[key] === true ? "" : search[key];
    //     // @ts-ignore
    //     if (v?.length > 10) {
    //         // @ts-ignore
    //         return v.slice(0, 5) + "...";
    //     }
    //     if (v === "") return "";
    //     if (
    //         (location.pathname === "/parking/visitorCars" || location.pathname === "/parking/monthCars") &&
    //         v === "list"
    //     ) {
    //         return <Translate id={`_.park value ${v}`} />;
    //     }
    //     return <Translate id={`${translationKey || "_"}.${v}`} />;
    // };

    // const getSeachKeyLabel = (key: string) => {
    //     if (
    //         (location.pathname === "/parking/visitorCars" || location.pathname === "/parking/monthCars") &&
    //         key === "list"
    //     ) {
    //         return <Translate id={`_.park ${key}`} />;
    //     }
    //     return <Translate id={`_.${key}`} />;
    // };

    return (
        <div className={"flex-grow items-center md:pl-12 md:flex justify-between"}>
            <div className={"flex-1"}>
                {/* // @ts-ignore */}
                {Object.keys(search).map(
                    (key) =>
                        !defaultIgnoreParams.includes(key) &&
                        !!search[key] &&
                        search[key] !== "" && (
                            <Chip
                                data-cy={`view-search-chip-${key}`}
                                data-value={search[key]}
                                key={key}
                                color={"primary"}
                                className={"mr-12 mt-8 md:mt-0"}
                                label={
                                    <>
                                        <span className={search[key] === "false" || search[key] === false ? "line-through" : "text-gray-200"}>
                                            <Translate id={`_.${key}`} />
                                            {/*{getSeachKeyLabel(key)}*/}
                                        </span>{" "}
                                        {chipSearchValue(search[key], translationKey)}
                                    </>
                                }
                                size={"small"}
                                onDelete={() => filterData(key)}
                                deleteIcon={
                                    <Icon className=" mr-2 -ml-1 h-20 w-20" fontSize={"small"}>
                                        close
                                    </Icon>
                                }
                            />
                        )
                )}
            </div>
            {hasFilter && (
                <div className={" mt-8 md:mt-0"}>
                    <MyTooltip title={<Translate id={"_.Clear All"} />}>
                        <ErrorButton variant={"contained"} size={"small"} startIcon={<Icon>close</Icon>} onClick={clearSearch}>
                            {<Translate id={"_.Clear All filters"} />}
                        </ErrorButton>
                    </MyTooltip>
                    {/* // @ts-ignore */}
                    {!dialogs["SaveSearch_" + name] && !noSaveSearch && (
                        <MyTooltip title={<Translate id={"_.save filter"} />}>
                            <Button
                                size={"small"}
                                className={"ml-20"}
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => {
                                    openSidebar();
                                    dispatch(turnOn("SaveSearch_" + name));
                                }}
                                startIcon={<Icon>save</Icon>}>
                                <Translate id={"_.save filter"} />
                            </Button>
                        </MyTooltip>
                    )}
                </div>
            )}
        </div>
    );
};

export default ViewSearches;
