export const createDialogSlice = (set) => ({
    openUser: false,
    openBackDrop: false,
    openDELLastVariableTips: false,
    openNewInstance: false,
    openDiagram: false,
    openHelp: false,
    openMuiltTask: false,
    openDeleteMultiInstance: false,
    syncOpen: false,
    onUserDialog: (t) => {
        set((state) => ({openUser: !state.openUser}));
    },
    onOpenBackDrop: (t) => {
        set({openBackDrop: t});
    },
    onChangeDELLastVariableTips: (t) => {
        set({openDELLastVariableTips: t});
    },
    onChangeNewInstanceDialog: (t) => {
        set({openNewInstance: t});
    },
    onChangeViewDiagramDialog: (t) => {
        set({openDiagram: t});
    },
    onChangeHelpCenterDialog: (t) => {
        set({openHelp: t});
    },
    onChangeMuiltTaskInATaskDialog: (t) => {
        set({openMuiltTask: t});
    },
    onChangeDeleteMultiDialog: (t) => {
        set({openDeleteMultiInstance: t});
    },
    onChangeSyncProcessDialog: (t) => {
        set({syncOpen: t});
    },
});
