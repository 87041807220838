import {Icon, IconButton, Input, InputAdornment} from "@material-ui/core";
import {useDebounceEffect} from "ahooks";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import type {SearchType} from "../../types";

type Props = {
    filterData(arg0: string | SearchType): void;
    shouldClearSearch?: any;
}

const SearchBox = ({filterData, shouldClearSearch}: Props) => {
    // const dispatch = useDispatch();
    // const location = useLocation();
    // const history = useHistory();
    // const params = useParams();
    const keyword = useSelector(({fuse}) => fuse.search["keyword"]);
    const [searchText, setSearchText] = useState("");
    // const debouncedValue = useDebounce(searchText, {wait: 1000}); // todo update to useDebounceEffect

    // function getOptions(debouncedValue: string) {
    //     if (debouncedValue.trim() !== "") {
    //         const s = {
    //             ...params,
    //             keyword: debouncedValue
    //         };
    //         // @ts-ignore
    //         dispatch(fuseActions.replaceParams({size: rowsPerPage, ...s})).then(() => {
    //             getData(s);
    //         });
    //         history.replace({pathname: location.pathname, search: `?${createSearchParams(s)}`});
    //     } else clearSearch();
    // }

    useDebounceEffect(
        () => {
            if (searchText.trim() !== "") {
                filterData({keyword: searchText});
            } else if (keyword) {
                filterData("keyword");
            }
        },
        [searchText],
        {
            wait: 800
        }
    );

    useEffect(() => {
        if (shouldClearSearch)
            clearSearch();
    }, [shouldClearSearch]);

    // let timerForSearch: ReturnType<typeof setTimeout>;
    const onSearch = (e) => {
        // clearTimeout(timerForSearch);
        setSearchText(e.target.value);
        // const size = Number(localStorage.getItem("rowPerPage"));
        // timerForSearch = setTimeout(() => {
        //
        // }, 1000);
    };

    const clearSearch = () => {
        setSearchText("");
        filterData("keyword");
    };

    // useEffect(() => {
    //     const parsedQueryString = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    //     if (!!parsedQueryString.keyword && typeof parsedQueryString.keyword === "string")
    //         setSearchText(parsedQueryString?.keyword);
    // }, []);

    return (

        <Translate>
            {({translate}: any) => (
                <Input
                    data-cy={"keyword"}
                    className="flex flex-1  w-full md:max-w-256 mt-0 bg-white text-darker rounded-8 border px-2"
                    placeholder={translate("_.search")}
                    disableUnderline
                    fullWidth
                    value={searchText}
                    endAdornment={
                        searchText &&
                        searchText?.trim() !== "" && (
                            <InputAdornment position="end">
                                <IconButton data-cy={"clear-search"} onClick={clearSearch}>
                                    <Icon color={"primary"} fontSize={"small"}>
                                        close
                                    </Icon>
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                    startAdornment={
                        <InputAdornment position="start">
                            <Icon fontSize={"small"}>search</Icon>
                        </InputAdornment>
                    }
                    onChange={onSearch}
                />
            )}
        </Translate>

    );
};

export default SearchBox;
