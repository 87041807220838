import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    ranksObject: null,
    selectedRankModel: null,
    selectedYearAndMonth: null,
    rankModelType: "",
    selectedDepartment: null,
};


const  redPacketManagerReducers = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_RANKS: {
            let tobj = cloneDeep(state);
            tobj.ranksObject = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     ranksObject: action.payload
            // };
        }

        case Actions.SELECT_ONE_RED_PACKET: {
            let tobj = cloneDeep(state);
            tobj.selectedRankModel = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedRankModel: action.payload
            // };
        }

        case Actions.UNSELECT_ONE_RED_PACKET_MODEL: {
            let tobj = cloneDeep(state);
            tobj.selectedRankModel = null;
            return tobj;
            // return {
            //     ...state,
            //     selectedRankModel: null,
            // };
        }

        case Actions.SELECT_YEAR_AND_MONTH: {
            let tobj = cloneDeep(state);
            tobj.selectedYearAndMonth = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedYearAndMonth: action.payload
            // };
        }

        case Actions.EDIT_RANK_TYPE: {
            let tobj = cloneDeep(state);
            tobj.editRankType = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     editRankType: action.payload
            // };
        }

        case Actions.SELECT_RANK_MODEL_TYPE: {
            let tobj = cloneDeep(state);
            tobj.rankModelType = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     rankModelType: action.payload
            // };
        }

        case Actions.SELECT_A_DEPARTMENT: {
            let tobj = cloneDeep(state);
            tobj.selectedDepartment = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedDepartment: action.payload
            // };
        }
        default:
            return cloneDeep(state);
    }
};

export default redPacketManagerReducers;
