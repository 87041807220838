import {Tab, Tabs} from "@material-ui/core";
import type {FC} from "react";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import Contact from "./Contact";
import General from "./General";
import Password from "./Password";

const ProfileEdit: FC = () => {
    const [tabValue, setTabValue] = useState(0);
    const {profile} = useSelector(({user}) => user);

    return (
        <>
            <Tabs value={tabValue} className={"border-b"} variant={"fullWidth"} onChange={(e, value) => setTabValue(value)}>
                <Tab classes={{root: "min-w-136"}} label={<Translate id="profile.general" />} />
                <Tab classes={{root: "min-w-136"}} label={<Translate id="profile.contact" />} />
                <Tab classes={{root: "min-w-136"}} label={<Translate id="profile.security" />} />
            </Tabs>

            {tabValue === 0 && (
                <div className="px-16">
                    <General
                        // @ts-ignore
                        user={profile}
                    />
                </div>
            )}
            {tabValue === 1 && (
                <div className="px-16">
                    <Contact />
                </div>
            )}
            {tabValue === 2 && (
                <div className="px-16">
                    <Password />
                </div>
            )}
        </>
    );
};

export default ProfileEdit;
