import type {FC} from "react";
import {useState} from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import {IconButton, InputAdornment} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import TreeItem from "../../../utils/UserSelector/TreeItem";
import {EmptyView} from "@ui";
import Grid from "@material-ui/core/Grid/Grid";
import {useDebounceEffect} from "ahooks";
import {useDep} from "./useDep";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

type Props = {
    disableWithoutDepartment?: boolean;
};

const Departments: FC<Props> = ({disableWithoutDepartment}) => {
    const {t} = useTranslation();
    const [departmentSearch, setDepartmentSearch] = useState<string>();

    const {departments, loading, getDepartments, setSelectedDep, selectedDep} = useDep();

    useDebounceEffect(
        () => {
            if (departmentSearch !== undefined) {
                if (departmentSearch === "") getDepartments();
                else getDepartments({keyword: departmentSearch});
            }
        },
        [departmentSearch],
        {
            wait: 800,
        }
    );

    const clearSearchDep = () => {
        setSelectedDep(undefined);
        setDepartmentSearch(undefined);
        getDepartments();
    };

    return (
        <Grid item md={3} style={{maxHeight: "calc(100vh -  200px)"}} className={clsx("hidden md:block overflow-y-auto")}>
            <List
                dense
                subheader={
                    <ListSubheader className={" justify-between flex bg-white"}>
                        <span className={"flex-1"}>{t("userSelector.departments")}</span>
                    </ListSubheader>
                }
                // classes={{root: "overflow-y-auto"}}
            >
                <ListItem>
                    <TextField
                        placeholder={t("Search")}
                        InputProps={{
                            endAdornment: departmentSearch && (
                                <InputAdornment position="end">
                                    <IconButton onClick={clearSearchDep}>
                                        <Icon fontSize={"small"}>close</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon fontSize={"small"}>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        className={"md:mt-0 mb-12"}
                        value={departmentSearch || ""}
                        onChange={(e) => setDepartmentSearch(e.target.value)}
                    />
                </ListItem>

                <ListItem disableTouchRipple disableRipple button selected={!selectedDep} onClick={() => setSelectedDep(undefined)}>
                    <ListItemText primary={t("userSelector.allUsers")} />
                </ListItem>
                {!disableWithoutDepartment && (
                    <ListItem
                        button
                        selected={selectedDep ? selectedDep.name === "withoutDepartment" : false}
                        onClick={() => setSelectedDep({name: "withoutDepartment", id: "withoutDepartment", active: true})}>
                        <ListItemText primary={t("userSelector.noDepartment")} />
                    </ListItem>
                )}
                {departments && departments?.length > 0 ? (
                    departments.map(
                        (_department) =>
                            _department && (
                                <TreeItem
                                    key={_department.id}
                                    _department={_department}
                                    selected={selectedDep ? selectedDep.id : "all"}
                                    changeDepartment={setSelectedDep}
                                />
                            )
                    )
                ) : (
                    <EmptyView loading={loading} />
                )}
            </List>
        </Grid>
    );
};

export default Departments;
