import Typography from '@material-ui/core/Typography';
import {ReactNode} from 'react';

type Props = {
    label: ReactNode;
    value: ReactNode;
};

export const SmallWidget = ({label, value}: Props) => {
    return (
        <div
            className={
                "mr-12 flex-shrink-0 min-w-128 border bg-grey-lightest h-auto rounded-8 py-8 px-12 border-grey align-middle capitalize"
            }>
            <Typography className={"text-16 font-bold -mt-1 mb-8"} color={"secondary"}>
                {label}
            </Typography>
            <div className="h-28">{value}</div>
        </div>
    );
};

