import type {ChipProps} from "@material-ui/core/Chip";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import type {ReactNode} from "react";
import {useSelector} from "react-redux";
import type {Colors} from "../../types";

type Types = "CLOSE" | "OPEN" | "Open" | "open" | "InProcess" | "Solved" | "Close" | "close" | "minor" | "major" | "critical";

const mapTypeToColors: {[key: string]: Colors} = {
    CLOSE: "red",
    OPEN: "red",
    Open: "red",
    open: "green",
    InProcess: "blue",
    Solved: "purple",
    Close: "green",
    close: "red",
    minor: "green",
    major: "orange",
    critical: "red",
};

interface Props {
    label: string | ReactNode;
    color?: Colors;
    // size?: "small" | "medium";
    type?: Types;
    // className?: string;
    // disabled?: boolean;
}

export const MyChip = ({type, color, className, size, ...rest}: Props & Omit<ChipProps, "color" | "label">) => {
    let c = color;
    // @ts-ignore
    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);
    if (!!type && !color && mapTypeToColors[type]) c = mapTypeToColors[type];
    return (
        <Chip
            classes={{icon: `text-${c}-darker`}}
            className={clsx(
                "font-bold capitalize",
                listView && !size ? `text-${c} bg-transparent border-none` : `bg-${c}-lighter focus:bg-${c}-light text-${c}-darker`,
                className
            )}
            size={size ? size : listView ? "small" : "medium"}
            icon={listView && !size ? <Icon className={`text-${c}-dark text-12`}>circle</Icon> : undefined}
            {...rest}
        />
    );
};
