import {Icon, IconButton, ListSubheader} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/styles";
import {showMessage, turnOff} from "@fuseActions";
import axios, {commonPrefix} from "myaxios";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import useSWR from "swr";
import {FuseLoading} from "../../../@fuse";
import {usePersonalized} from "../../../hooks/usePersonalized";

export type SavedSearchType = {
    data: {[key: string]: string | number};
    id: string;
    key: string;
    name: string;
    type: string;
};

const useStyles = makeStyles(() => ({
    hideButton: {
        "& > button": {
            display: "none",
        },
        "&:hover button": {
            display: "block",
        },
    },
}));

const SaveSearch = (props: {name: string; getData(params: {[key: string]: any}): void}) => {
    // region states and hooks
    const classes = useStyles();
    const dispatch = useDispatch();
    // @ts-ignore
    const search = useSelector(({fuse}) => fuse.search);
    // @ts-ignore
    const openSave = useSelector(({fuse}) => fuse.dialogs["SaveSearch_" + props.name]);
    const [searchName, setSearchName] = useState("");
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<SavedSearchType>();
    const {addOrUpdateFilters} = usePersonalized();

    const {
        data: savedSearch,
        mutate,
        isLoading,
    } = useSWR<SavedSearchType[]>(`${commonPrefix}/myData?type=${props.name}&key=filter`, {revalidateOnMount: true});
    // endregion

    const submitSave = () => {
        setLoading(true);
        const data = {
            key: "filter",
            type: props.name,
            name: searchName,
            data: search,
        };
        axios.put(`${commonPrefix}/myData`, data).then(() => {
            setLoading(false);
            dispatch(showMessage({message: "message.Filter saved!", variant: "success"}));
            mutate();
            close();
        });
    };

    const deleteSavedSearch = (item: SavedSearchType) => (e: any) => {
        e.stopPropagation();
        axios.delete(`${commonPrefix}/myData/${item.id}`).then(() => {
            mutate();
        });
    };

    const close = () => {
        setSearchName("");
        dispatch(turnOff("SaveSearch_" + props.name));
    };

    const onSavedClick = (_: SavedSearchType) => {
        // dispatch(Actions.turnOn("SaveSearch_"+props.name));
        // dispatch(Actions.turnOn(`${props.name}Show`));
        // @ts-ignore
        // dispatch(replaceParams(_.data));

        addOrUpdateFilters(props.name, _.data);
        // console.log(_.data);
        setSelected(_);
        props.getData(_.data);
    };

    return (
        <>
            {openSave && (
                <div className={loading ? "loading bg-grey-lighter pb-20" : " bg-grey-lighter pb-20"}>
                    <List
                        subheader={
                            <ListSubheader>
                                <Translate id={"_.Save these Parameters"} />
                            </ListSubheader>
                        }
                        className={"bg-grey-lighter"}>
                        {/*// @ts-ignore*/}
                        {Object.keys(search).map(
                            (key) =>
                                key !== "size" && (
                                    <ListItem key={key}>
                                        <ListItemText primaryTypographyProps={{className: "capitalize font-bold"}} primary={key} />
                                        {/*// @ts-ignore*/}
                                        <ListItemSecondaryAction>
                                            {/*// @ts-ignore*/}
                                            {search[key].length > 10
                                                ? // @ts-ignore
                                                  search[key].slice(search[key].length - 5, search[key].length)
                                                : // @ts-ignore
                                                  search[key]}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                        )}
                        <TextField
                            autoFocus={true}
                            label={<Translate id={"_.Name of the filter"} />}
                            className={"m-12"}
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                    </List>
                    <div className={"flex justify-end pr-12 my-12"}>
                        <Button size={"small"} onClick={close} className={"mr-2"}>
                            <Translate id={"_.cancel"} />
                        </Button>
                        <Button
                            onClick={submitSave}
                            startIcon={<Icon>save</Icon>}
                            disabled={searchName.trim() === "" || loading}
                            size={"small"}
                            variant={"contained"}
                            color={"primary"}>
                            <Translate id={"_.save filter"} />
                        </Button>
                    </div>
                </div>
            )}

            <div className={"bg-grey-light "} style={{borderRadius: "8px 8px 0 0"}}>
                <ListSubheader className={"items-center space-between flex h-48 pl-16"} disableSticky>
                    <span className={"flex-1 uppercase leading-normal"}>
                        <Translate id="_.Saved searches" /> ({savedSearch?.length || 0})
                    </span>
                    {isLoading && <FuseLoading />}
                    {/*{savedSearch.length > 0 &&<div><IconButton size={"small"} onClick={()=>setEditSaved(!editSaved)}><Icon>{editSaved ? "close" : "edit"}</Icon></IconButton></div>}*/}
                </ListSubheader>

                {savedSearch && savedSearch.length > 0 && (
                    <List dense>
                        {savedSearch.map((_, i) => (
                            <ListItem selected={_ === selected} className={classes.hideButton} button key={i} onClick={() => onSavedClick(_)}>
                                <ListItemText primaryTypographyProps={{className: "text-18"}} primary={_.name} />

                                <IconButton onClick={deleteSavedSearch(_)} size={"small"}>
                                    <Icon fontSize={"small"} color={"error"}>
                                        delete
                                    </Icon>
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                )}
                {/*{savedSearch.length === 0 ? <Typography color={"textSecondary"}>{<Translate id={"_.You can save your search parameters using the save button on the top."}/>}</Typography> :*/}
                {/*savedSearch.map( (_, i) => */}
                {/*    <Chip key={i} onClick={()=>onSavedClick(_)} size={"small"} color={"secondary"} onDelete={editSaved ? deleteSavedSearch(_) : null} className={"mr-8 mb-8"} label={_.name}/>)}*/}
                {/*<Divider />*/}
            </div>
        </>
    );
};

export default SaveSearch;
