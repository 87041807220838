import {finishLoading, startLoading} from "@fuseActions";
import axios, {financialPrefix} from "../../../../../myaxios";

export const GET_INCOMES_FOR_MANAGER = "[GET_INCOMES_FOR_MANAGER]";

export const GET_NO_HANDLER_INCOMES_FOR_MANAGER = "[GET_NO_HANDLER_INCOMES_FOR_MANAGER]";

export const GET_INCOME_DETAILS = "[GET_INCOME_DETAILS]";

export const GET_INCOME_MATCHING = "[GET_INCOME_MATCHING]";

export const GET_INCOME_MATCHING_LENGTH = "[GET_INCOME_MATCHING_LENGTH]";

export const CLEAR_INCOME_MATCHING = "[CLEAR_INCOME_MATCHING]";

export const SELECT_A_INCOME_FOR_MANAGER = "[SELECT_A_INCOME_FOR_MANAGER]";

export const SELECT_NO_HANDLER_INCOMES_FOR_MANAGER = "[SELECT_NO_HANDLER_INCOMES_FOR_MANAGER]";

export const SELECT_AUTO_MATCHING_REVENUES = "[SELECT_AUTO_MATCHING_REVENUES]";

export const SELECT_A_ORDER_FOR_INCOME = "[SELECT_A_ORDER_FOR_INCOME]";

export const IS_MANAGER_INCOME = "[IS_MANAGER_INCOME]";

export const SELECT_INCOMES_LIST_FOR_MANAGER = "[SELECT_INCOMES_LIST_FOR_MANAGER]";

export const GET_ORDERS_LIST = "[GET_ORDERS_LIST]";

export function getIncomesForManager(params, callback) {
    const request =
        params && true ? axios.get(`${financialPrefix}/incomes`, {params}) : axios.get(`${financialPrefix}/incomes`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_INCOMES_FOR_MANAGER,
                payload: res.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getNoHandlerIncomesForManager(params, callback) {
    const request = params
        ? axios.get(`${financialPrefix}/incomeMatchingInvoices`, {params})
        : axios.get(`${financialPrefix}/incomeMatchingInvoices`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_NO_HANDLER_INCOMES_FOR_MANAGER,
                payload: res.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getIncomeDetails(id, callback) {
    const request = axios.get(`${financialPrefix}/income/${id}`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_INCOME_DETAILS,
                payload: res.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getIncomeMatching(params, callback) {
    const request = axios.get(`${financialPrefix}/incomeMatching`, {params});
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_INCOME_MATCHING,
                payload: res.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getIncomeMatchingCount(length) {
    return {
        type: GET_INCOME_MATCHING_LENGTH,
        payload: length
    };
}

export function clearIncomeMatching() {
    return {
        type: CLEAR_INCOME_MATCHING
    };
}

export function selectIncomesForManager(incomes) {
    return {
        type: SELECT_INCOMES_LIST_FOR_MANAGER,
        payload: incomes
    };
}

export function selectNoHandlerIncomeForManager(incomes) {
    return {
        type: SELECT_NO_HANDLER_INCOMES_FOR_MANAGER,
        payload: incomes
    };
}

export function selectAutoMatchingRevenues(incomes) {
    return {
        type: SELECT_AUTO_MATCHING_REVENUES,
        payload: incomes
    };
}

export function selectAIncomeForManager(income) {
    return {
        type: SELECT_A_INCOME_FOR_MANAGER,
        payload: income
    };
}

export function selectAOrderForIncome(order) {
    return {
        type: SELECT_A_ORDER_FOR_INCOME,
        payload: order
    };
}

export function passIsOrNotManagerIncome(boolean) {
    return {
        type: IS_MANAGER_INCOME,
        payload: boolean
    };
}
