import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import numeral from "numeral";

MyPriceLabel.propTypes = {
    price: PropTypes.any.isRequired,
    /**
     * @description - 是否显示人民币的前缀(￥)符号,默认显示
     */
    showPrefix: PropTypes.bool,
    /**
     * @description - 数据为空时的占位符，默认为空字符串
     */
    emptyPlaceholder: PropTypes.string,
    classNames: PropTypes.string,
};

MyPriceLabel.defaultProps = {
    showPrefix: true,
    emptyPlaceholder: "",
};

function MyPriceLabel(props) {
    const {price, showPrefix, emptyPlaceholder, classNames} = props;
    return (
        <>
            {price ? (
                <div className={classNames ? classNames : "flex flex-row items-center"}>
                    {showPrefix && <small className={"text-red mx-2"}>￥</small>}
                    <Typography className={"text-red text-lg"} variant={"button"} display="block">
                        {numeral(price).format("0,0.00")}
                    </Typography>
                </div>
            ) : (
                emptyPlaceholder || ""
            )}
        </>
    );
}

export default MyPriceLabel;
