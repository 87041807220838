export const createListSlice = (set, get) => ({
    myIncomeSingleSelected: null,
    isMyIncome: false,
    onChangeIsMyIncome: (flag) => {
        set({
            isMyIncome: flag,
        });
    },
    onChangeMyIncomeSingleSelected: (data) => {
        set({
            myIncomeSingleSelected: data,
        });
    },
});
