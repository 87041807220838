import * as Actions from "../actions";
import { cloneDeep } from "lodash";
const initState = {
    decisionObject: null,
};


const decision = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_DECISION_SINGLE: {
            const obj = cloneDeep(state);
            obj.decisionObject = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     decisionObject: action.payload
            // }
        }
        case Actions.RESET_DECISION_SINGLE: {
            const obj = cloneDeep(state);
            obj.decisionObject = null;
            return  obj;
        }
        default:
            return  state;
    }
};

export default decision;
