import PropTypes from "prop-types";
import {Translate} from "react-localize-redux";
import Select from "react-select/async";
import {components} from "../../Select2Components";
import {useEffect, useState} from "react";
import {useDebounceFn, useMemoizedFn} from "ahooks";
import useAsyncSelectUtils from "./utils/useAsyncSelectUtils";

AsyncSelectQuery.propTypes = {
    /**
     * @description > 是否是单机模式；该模式下只使用该组件选择数据，不在该组件内进行 网络请求的参数组装
     */
    standaloneMode: PropTypes.bool,
    /**
     * @description > 网络请求的参数名 like query["page"]
     */
    name: PropTypes.string,
    /**
     * @description > 已经有值，通过 defaultValue 传入组件作为初始化值 { value: *,label: * } || null
     */
    defaultValue: PropTypes.any,
    /**
     * @description > select 是否是多选的 select
     */
    isMulti: PropTypes.bool,

    /**
     * @description > 获取数据源网络请求的函数
     */
    dataSourceRequest: PropTypes.func.isRequired,

    /**
     * @description > 网络请求的参数
     */
    query: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
    /**
     * @description > 更新网络请求的参数
     */
    onChangeQuery: PropTypes.func,
    /**
     * @description > AsyncSelect 的 onChange 事件的回调
     */
    onChangeValue: PropTypes.func,

    /**
     * @description >  回调到使用该组件的地方，用于创建 select 的 value = {id:"*",value:"*"}
     * @returns {Promise<{value: string, label: node||string}>}
     */
    onCreateSelectValue: PropTypes.func.isRequired,
};

AsyncSelectQuery.defaultProps = {
    standaloneMode: false,
};

function AsyncSelectQuery(props) {
    const {
        standaloneMode,
        name,
        defaultValue,
        isMulti,
        dataSourceRequest,
        query,
        onChangeQuery,
        onChangeValue,
        onCreateSelectValue,
        ...rest
    } = props;

    const [value, setValue] = useState("");

    const {refreshId, onChangeRefreshId} = useAsyncSelectUtils();

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const debounceLoadOptions = useDebounceFn(
        (input, callback) => {
            debounceActions(input, callback);
        },
        {wait: 250}
    );

    const debounceActions = useMemoizedFn((input, callback) => {
        let data = {};
        if (input) {
            data = {
                ...data,
                [name]: input,
            };
        }
        console.log("debounceActions > data", data);
        dataSourceRequest(data).then((response) => {
            const res = !!response?.data?.content ? response?.data?.content : response?.data;
            onCreateSelectValue(res).then((item) => {
                callback(item);
            });
        });
    });

    const loadOptions = useMemoizedFn((input, callback) => {
        debounceLoadOptions.run(input, callback);
    });

    const handleSelectChange = useMemoizedFn((results) => {
        console.log("handleSelectChange > results", results);
        if (results) {
            setValue(results);
            if (!standaloneMode) {
                const data = {
                    ...query,
                    [name]: results.value,
                };
                onChangeQuery(data);
            } else {
                onChangeValue(results);
            }
        } else {
            setValue("");
            if (!standaloneMode) {
                const data = {
                    ...query,
                };
                if (data[name]) {
                    delete data[name];
                }
                onChangeQuery(data);
            } else {
                onChangeValue("");
            }
        }
    });

    return (
        <>
            <Translate>
                {({translate}) => (
                    <Select
                        key={refreshId ? refreshId : "refreshId"}
                        placeholder={translate(`_.By ${name}`)}
                        loadOptions={loadOptions}
                        defaultOptions
                        components={components}
                        value={value}
                        noOptionsMessage={({inputValue}) => translate("_.No options")}
                        loadingMessage={({inputValue}) => translate("_.loading...")}
                        onChange={handleSelectChange}
                        className={""}
                        isOptionDisabled={(option) => option?.disabled === true || false}
                        isClearable={!isMulti}
                        {...rest}
                    />
                )}
            </Translate>
        </>
    );
}

export default AsyncSelectQuery;
