import {FusePageSimple} from "@fuse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import {Component} from "react";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import Empty from "utils/Empty";
import EventBus, {UPDATE_WORK_HISTORIES_NOTIFI} from "utils/EventBus";
import axios, {crmPrefix} from "../../../../../myaxios";
import ActivityItem from "../../activities/list/ItemView";
import DealItem from "../../deals/list/ItemView";
import jsons from "../../translates/translate.json";
import GridList from "../../utils/GridList";
import Labels from "../../utils/Labels";
import Notes from "../../utils/Notes";
import { clearItem, selectItem, getItem, getList } from "../store/actions";
import Header from "./Header";
import RightSidebar from "./RightSidebar";
import WorkHistory from "./WorkHistory";

class SinglePage extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(jsons);
        this.state = {
            workHistories: [],
        };
    }

    componentDidMount = () => {
        // console.log("remounting");
        const {id} = this.props.match.params;
        this.getWorkHistories();
        if (id) {
            this.props.getItem(id);
        }
        EventBus.on(UPDATE_WORK_HISTORIES_NOTIFI, () => this.getWorkHistories());
    };

    componentDidUpdate = (prevProps) => {
        // console.log("updating");
        const {match, item, getItem} = this.props;
        if (item && prevProps.item && match.params.id) {
            if (match.params.id !== prevProps.match.params.id) {
                const {id} = match.params;
                getItem(id);
                this.getWorkHistories();
            }
        }
    };

    componentWillUnmount = () => {
        this.props.clearItem();
        EventBus.remove(UPDATE_WORK_HISTORIES_NOTIFI);
    };

    getWorkHistories = () => {
        const id = this.props.item.id;
        const params = {contactId: id};
        axios.get(`${crmPrefix}/contactWorkHistories`, {params}).then((res) => {
            this.setState({
                workHistories: _.isArray(res.data) === true ? [...res.data] : [],
            });
        });
    };

    render() {
        const {item, selectItem, getItem, profile, getList, search} = this.props;
        const {workHistories} = this.state;
        const subProps = {
            canEdit: item && profile.id === item.user.id,
            sourceType: "contact",
            sourceItem: this.props.item,
            selectItem,
            refresh: () => {
                getItem(item.id);
            },
            getList: () => getList(search),
        };
        return !item ? (
            <Empty />
        ) : (
            <FusePageSimple
                classes={{
                    header: "h-128",
                }}
                header={<Header {...subProps} />}
                content={
                    <div className={"w-full"}>
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <div className="px-16 mt-12">
                                    <Labels {...subProps} data={item.labels} />
                                </div>
                                {/*<Description {...subProps}/>*/}
                                <Notes {...subProps} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RightSidebar {...subProps} />
                            </Grid>
                        </Grid>
                        <Divider />
                        <div className={"p-20"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <WorkHistory datas={workHistories} reFresh={this.getWorkHistories} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <GridList {...subProps} link={"activities"} type={"activity"} GridItem={ActivityItem} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <GridList {...subProps} link={"deals"} type={"deal"} GridItem={DealItem} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearItem: clearItem,
            selectItem: selectItem,
            getItem: getItem,
            getList: getList,
        },
        dispatch
    );
}

function mapStateToProps({user, fuse, marketing}) {
    return {
        profile: user.profile,
        item: marketing.companyContacts.item,
        search: fuse.search,
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(SinglePage)));
