export const USER_TASKS_FILTER_KEY = "USER_TASKS_FILTER_KEY";

export const INSTANCES_FILTER_KEY = "INSTANCES_FILTER_KEY";

export const DECISIONS_FILTER_KEY = "DECISIONS_FILTER_KEY";

export const DEFINITIONS_FILTER_KEY = "DEFINITIONS_FILTER_KEY";

export const UPDATE_WORK_HISTORIES_NOTIFI = "[UPDATE_WORK_HISTORIES_NOTIFI]";

export const UPDATE_COMPANY_SELECTOR_AFTER_CREATE_A_COMPANY_NOTIFI = "[UPDATE_COMPANY_SELECTOR_AFTER_CREATE_A_COMPANY_NOTIFI]";

export const UPDATE_CONTACT_SELECTOR_AFTER_CREATE_A_CONTACT_NOTIFI = "[UPDATE_CONTACT_SELECTOR_AFTER_CREATE_A_CONTACT_NOTIFI]";

export const UPDATE_DEAL_SELECTOR_AFTER_CREATE_A_DEAL_NOTIFI = "[UPDATE_DEAL_SELECTOR_AFTER_CREATE_A_DEAL_NOTIFI]";

// fixme replace this with swr
const EventBus = {
    on(event, callback) {
        // console.log('EventBus===> listen on',event);
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        // console.log('EventBus===> listen dispatch',event);
        document.dispatchEvent(new CustomEvent(event, {detail: data}));
    },
    remove(event, callback) {
        // console.log('EventBus===> listen remove',event);
        document.removeEventListener(event, callback);
    },
};

export default EventBus;
