import {DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid/Grid";
import {DialogWrapper, SubmitButton} from "@ui";
import {useEffect, useState} from "react";
import axios, {firmPrefix} from "@api";
import type {departmentType, SearchType, userType} from "../../../types";
import type {UsersSelectorProps} from "./types";
import {useTranslation} from "react-i18next";
import {DepContext} from "./useDep";
import {TransferContext} from "./useTransferList";
import Departments from "./Departments";
import SelectedUsers from "./SelectedUsers";
import NotSelectedUsers from "./NotSelectedUsers";

const UsersSelectorDialog = ({onSubmit, disableWithoutDepartment, members, onClose, title}: UsersSelectorProps) => {
    const {t} = useTranslation();

    // region states
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<userType[]>();
    const [selectedDepartment, setSelectedDepartment] = useState<departmentType>();
    const [departments, setDepartments] = useState<departmentType[]>();
    // endregion

    const getDepartments = (params?: SearchType) => {
        setLoading(true);
        axios
            .get(`${firmPrefix}/departments`, {params})
            .then((res) => {
                setDepartments(res.data);
            })
            .finally(() => setLoading(false));
    };

    const isSelected = (user) => (selectedUsers ? selectedUsers.findIndex((__) => __.id === user.id) !== -1 : false);

    const selectUser = (user: userType) => {
        // e.stopPropagation();
        // e.preventDefault();
        const t = selectedUsers ? [...selectedUsers] : [];

        // const isSelected = selectedUsers.findIndex((__) => __.id === user.id) !== -1;
        if (!isSelected(user)) {
            t.push(user);
            // const n = unSelectedUsers.filter(u => u.id !== user.id);
            setSelectedUsers(t);
        }
    };

    const removeUser = (user: userType) => {
        const oldSelectedUsers = selectedUsers ? [...selectedUsers] : [];
        const t = oldSelectedUsers.filter((_u) => _u.id !== user.id);
        setSelectedUsers(t);
    };

    const removeAll = () => {
        // e.preventDefault();
        // e.stopPropagation();
        setSelectedUsers(undefined);

        // setSelectedUsersSearch(undefined);
    };

    const addAll = (users: userType[]) => {
        const t = selectedUsers ? [...selectedUsers] : [];
        if (users) {
            // const dataUsers = staff.map((u) => u.user);
            users.forEach((u) => !isSelected(u) && t.push(u));
            setSelectedUsers(t);
        }
    };

    useEffect(() => {
        if (members) setSelectedUsers(members);
        getDepartments();
    }, []);

    const transferValue = {
        add: selectUser,
        remove: removeUser,
        removeAll,
        addAll,
        selected: selectedUsers,
        isSelected,
    };

    const depValue = {
        departments,
        getDepartments,
        selectedDep: selectedDepartment,
        setSelectedDep: setSelectedDepartment,
        loading,
    };

    return (
        <DepContext.Provider value={depValue}>
            <DialogTitle id="alert-dialog-title">
                <div className={" md:flex justify-between"}>
                    <span>
                        {t("userSelector.title")} {title && <span>- {title}</span>}
                    </span>
                    <SubmitButton
                        success
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onClose();
                            onSubmit(selectedUsers);
                        }}
                        color="secondary">
                        {t("done")} ({selectedUsers?.length || "0"})
                    </SubmitButton>
                </div>
            </DialogTitle>
            <DialogContent className={"p-0 border-t-1 border-b-1"}>
                <Grid container>
                    <Departments disableWithoutDepartment={disableWithoutDepartment} />

                    <TransferContext.Provider value={transferValue}>
                        <NotSelectedUsers />
                        <SelectedUsers />
                    </TransferContext.Provider>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SubmitButton
                    data-cy={"done-users"}
                    success
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onSubmit(selectedUsers);
                        onClose();
                    }}
                    color="secondary">
                    {t("done")} ({selectedUsers?.length || "0"})
                </SubmitButton>
            </DialogActions>
        </DepContext.Provider>
    );
};

const Wrapped = (props) => (
    <DialogWrapper name={"selectUsers"} maxWidth={"xl"}>
        {(onClose) => <UsersSelectorDialog onClose={onClose} {...props} />}
    </DialogWrapper>
);

export default Wrapped;
