//useQueryStore
import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {CATEGORIES_MANAGER_REQUEST_PERSIST_KEY} from "./constant";

let store = (set) => ({
    /**
     * @description - 是否已经从本地存储中恢复了数据,
     */
    _hasHydrated: false,
    /**
     * @param {Object} query -  网络请求参数
     */
    query: {
        status: "open",
        page: 1,
        size: 10,
    },
    actions: {
        setHasHydrated: (flag) => {
            set({
                _hasHydrated: flag,
            });
        },
        onChangeQuery: (query) => {
            return new Promise((resolve, reject) => {
                set({
                    query: query,
                });
                resolve(null);
            });
        },
    },
});

store = devtools(store);

store = persist(store, {
    name: CATEGORIES_MANAGER_REQUEST_PERSIST_KEY,
    partialize: ({actions, ...rest}) => rest,
    // ...
    onRehydrateStorage: () => (state) => {
        state.actions.setHasHydrated(true);
    },
});

const useQueryStore = create(store);

export default useQueryStore;
