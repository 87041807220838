import axios, {crmPrefix} from "../../../../../myaxios";

export const GET_SOLUTIONS = "[COMMERCE BRANDS SOLUTION]";
export const GET_SOLUTION_DETAILS = "[GET_SOLUTION_details]";
export const SELECT_SOLUTION = "[COMMERCE_SELECT_SOLUTION]";
export const GET_PRODUCTS_OF_SOLUTION = "[COMMERCE_GET_PRODUCTS_OF_SOLUTION]";
export const PASS_SELECTED_PRODUCTS = "[COMMERCE_PASS_SELECTED_PRODUCTS]";
export const REMOVE_ALL_SELECTED = "[COMMERCE_REMOVE_ALL_SELECTED]";
export const PASS_BRAND_ID = "[COMMERCE_PASS_BRAND_ID]";
export const SET_SEARCH_PRODUCT_IN_SOLUTION = "[COMMERCE_SEARCH_PRODUCT_IN_SOLUTION]";
export const SEARCH_TO_ADD_PRODUCT_TO_SOLUTION = "[COMMERCE_SEARCH_ADD_PRODUCT_TO_SOLUTION]";
export const GET_PRODUCTS_ALL_CATEGORIES_IN_SOLUTION = "[COMMERCE_GET_PRODUCTS_ALL_CATEGORIES_IN_SOLUTION]";
export const SELECT_PRODUCT_CATEGORY_IN_SOLUTION = "[COMMERCE_SELECT_PRODUCT_CATEGORY_IN_SOLUTION]";
export const GET_PRODUCTS_CATEGORIES_IN_SOLUTION_SEARCH_PRODUCT_DIALOG = "[COMMERCE_GET_PRODUCTS_CATEGORIES_IN_SOLUTION_SEARCH_PRODUCT_DIALOG]";
export const SELECT_PRODUCT_CATEGORY_IN_SOLUTION_SEARCH_PRODUCTDIALOG = "[COMMERCE_SELECT_PRODUCT_CATEGORY_IN_SOLUTION_SEARCH_PRODUCTDIALOG]";
export const SET_SOLUTION_DIALOG_BOUNDS_RESIZE = "[COMMERCE_SET_SOLUTION_DIALOG_BOUNDS_RESIZE]";


export function getSolutions(params) {

    //  here have 翻页 从0 开始
    const request = axios.get(`${crmPrefix}/solutions`, {params});
    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_SOLUTIONS,
                payload: response.data
            });
        });
    };
}

export function getSolutionDetails(id) {
    const request = axios.get(`${crmPrefix}/solution/${id}`);
    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_SOLUTION_DETAILS,
                payload: response.data
            });
        });
    };
}

export function selectSolution(solution) {
    return {
        type: SELECT_SOLUTION,
        payload: solution
    };
}

export function getProductsOfSolution(search, solutionId) {
    const searchT = search ? search : "";
    //  here have 翻页(从 0 开始)
    const request = axios.get(`${crmPrefix}/solution/${solutionId}/products${searchT}`);
    return dispatch => {
        request.then(response => {
            dispatch({
                type: GET_PRODUCTS_OF_SOLUTION,
                payload: response.data
            });
        });
    };
}


export function selectedProducts(productsIds) {
    return {
        type: PASS_SELECTED_PRODUCTS,
        payload: productsIds
    };
}

export function removeAllSelectedProductids() {
    return {
        type: REMOVE_ALL_SELECTED
    };
}


export function passBrandId(brandId) {
    return {
        type: PASS_BRAND_ID,
        payload: brandId
    };
}

export function searchProductInSolutionDialog(e) {
    return {
        type: SET_SEARCH_PRODUCT_IN_SOLUTION,
        payload: e.target.value
    };
}

export function searchProductInAddProductToDialog(e) {
    return {
        type: SEARCH_TO_ADD_PRODUCT_TO_SOLUTION,
        payload: e.target.value
    };
}

export function getProductsAllCategoriesInSolution(params) {
    const request = axios.get(`${crmPrefix}/productCategories`, {params});
    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_PRODUCTS_ALL_CATEGORIES_IN_SOLUTION,
                payload: response.data
            });
        });
    };
}

export function getProductsCaategoriesInSolutionSearchProductDialog(params) {
    const request = axios.get(`${crmPrefix}/productCategories`, {params});
    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_PRODUCTS_CATEGORIES_IN_SOLUTION_SEARCH_PRODUCT_DIALOG,
                payload: response.data
            });
        });
    };
}

//export function selectProductCategoryInSolution(cat) {
//    return {
//        type: SELECT_PRODUCT_CATEGORY_IN_SOLUTION,
//        payload: cat
//    }
//}

export function setResizeDialogBounds(height, width) {
    return {
        type: SET_SOLUTION_DIALOG_BOUNDS_RESIZE,
        height: height,
        width: width
    };
}



