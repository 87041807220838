import axios, {crmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";
import {getCustomerGroups} from "./customers.actions";

export const GET_CUSTOMER = "[COMMERCE|CUSTOMER] GET_CUSTOMER";
export const CLEAR_CUSTOMER = "[COMMERCE|CUSTOMER] CLEAR_CUSTOMER";
export const GET_CONTACTS_BY_CUSTOMER    =  "[COMMERCE|CUSTOMER] GET_CONTACTS_BY_CUSTOMER";
export const GET_CUSTOMER_MEMEBERS_BY_CUSTOMER = "[COMMERCE|CUSTOMER] GET_CUSTOMER_MEMEBERS_BY_CUSTOMER";

export function getCustomer(id, errorCallBack){
    const request = axios.get(`${crmPrefix}/customer/${id}`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_CUSTOMER,
                payload: response.data,
            });
            dispatch(getCustomerGroups({brandId:response.data.brand.id}));
            dispatch(finishLoading());
        }).catch(er=>{
            errorCallBack(er);
        });
    };
}

export function clearCustomer() {
    return {
        type: CLEAR_CUSTOMER,
    };
}


export function getCustomerMembersByCustomer(customerId) {

    // here have 翻页
    const request = axios.get(`${crmPrefix}/customerMembersByCustomer?customerId=${customerId}`);

    return (dispatch) => {

        dispatch(startLoading());
        request.then((response) =>{
            dispatch({
                type: GET_CUSTOMER_MEMEBERS_BY_CUSTOMER,
                payload: response.data.content,
            });
        });
    };
}

