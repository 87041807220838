import {finishLoading, startLoading} from "@fuseActions";
import axios from "myaxios";
import {MODULE_NAME, moduleLinklist, moduleLinklistAdmin, moduleLinkSingle} from "./VARIABLES";

export const GET_LIST = `[${MODULE_NAME}] GET_LIST`;
export const SELECT_ITEM = `[${MODULE_NAME}] SELECT_ITEM`;
export const GET_ITEM = `[${MODULE_NAME}] GET_ITEM`;
export const CLEAR_ITEM = `[${MODULE_NAME}] CLEAR_ITEM`;

export function getItem(id) {
    const request = axios.get(`${moduleLinkSingle}/${id}`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_ITEM,
                payload: response.data,
            });
            dispatch(finishLoading());
        });
    };
}

export function clearItem() {
    return {
        type: CLEAR_ITEM,
    };
}

export function getList(params) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(moduleLinklist, {params}).then((res) => {
            if (res.data.content)
                return new Promise((resolve) => {
                    dispatch({
                        type: GET_LIST,
                        payload: res.data,
                    });
                    dispatch(finishLoading());
                    resolve();
                });
        });
    };
}

export function getAdminData(params) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(moduleLinklistAdmin, {params}).then((res) => {
            if (res.data.content)
                return new Promise((resolve) => {
                    dispatch({
                        type: GET_LIST,
                        payload: res.data,
                    });
                    dispatch(finishLoading());
                    resolve();
                });
        });
    };
}

export function selectItem(data) {
    return {
        type: SELECT_ITEM,
        data,
    };
}


