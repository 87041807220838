import {
    Checkbox,
    Hidden,
    Icon,
    ListItemIcon,
    MenuItem,
    MenuList,
    Popover,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import {useMemo, useState} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import {TABLE_ROW_HEIGHT_36, TABLE_ROW_HEIGHT_64} from "../utilComponents";

LFTableHeaderView.propTypes = {
    query: PropTypes.any,
    onChangeQuery: PropTypes.func,
    mutate: PropTypes.func,
    /**
     * @param {array} 表头
     */
    columns: PropTypes.array.isRequired,
    /**
     * @param {array} rows 数据源
     */
    dataSource: PropTypes.array.isRequired,
    /**
     * @description - 表头显示/隐藏 某些 cell 列
     */
    headSet: PropTypes.any.isRequired,
    onHeadSet: PropTypes.func.isRequired,
    numSelected: PropTypes.number,
    rowCount: PropTypes.number,
    onAllSelection: PropTypes.func
};

function LFTableHeaderView(props) {
    const {
        query,
        onChangeQuery,
        mutate,
        columns,
        dataSource,
        headSet,
        onHeadSet,
        numSelected,
        rowCount,
        onAllSelection
    } = props;

    const listView = useSelector(({fuse}) => fuse.dialogs?.listView);

    const [anchorEl, setAnchorEl] = useState(null);

    const [order, setOrder] = useState("asc");

    const [orderBy, setOrderBy] = useState("");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleHide = (row) => () => {
        const t = new Set(headSet);
        if (headSet.has(row.id)) t.delete(row.id);
        else t.add(row.id);
        onHeadSet(t);
    };

    const toggleAll = () => {
        if (headSet.size > 0) {
            onHeadSet(new Set());
        }
    };

    const createSortHandler = (property) => (e) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const params = {
            ...query,
            sidx: property,
            sord: isAsc ? "asc" : "desc"
        };
        mutate(params).then((response) => {
            console.log("header sort response", response);
            if (response?.status === 200) {
                onChangeQuery(params);
            }
        });
    };

    const handleSelectAllClick = useMemoizedFn((e) => {
        if (e.target.checked) {
            const newSelecteds = !!dataSource ? dataSource : [];
            console.debug("[handleSelectAllClick] > newSelecteds", newSelecteds);
            onAllSelection(newSelecteds);
        } else {
            onAllSelection([]);
        }
    });

    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);

    const id = useMemo(() => {
        return open ? "simple-popover" : undefined;
    }, [open]);


    const checkboxIndeterminate = useMemo(() => {
        return numSelected > 0 && numSelected < rowCount;
    }, [rowCount, numSelected]);


    const checkboxSelected = useMemo(() => {
        return numSelected > 0 && numSelected === rowCount;
    }, [rowCount, numSelected]);


    return (
        <TableHead>
            <TableRow className={listView ? `h-${TABLE_ROW_HEIGHT_36}` : `H-${TABLE_ROW_HEIGHT_64}`}>
                <TableCell padding={"checkbox"}>
                    <IconButton onClick={handleClick} size={"small"} className={"absolute top-0 left-0"}>
                        <Icon fontSize={"small"}>settings</Icon>
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                    >
                        <MenuList>
                            {columns.map(
                                (col) =>
                                    !col.noHide && (
                                        <MenuItem key={col.id} onClick={toggleHide(col)}>
                                            <ListItemIcon>
                                                <Switch size={"small"} checked={!headSet.has(col.id)} />
                                            </ListItemIcon>
                                            <Typography variant="inherit">{<Translate id={col.label} />}</Typography>
                                        </MenuItem>
                                    )
                            )}
                            {headSet.size > 0 && (
                                <MenuItem onClick={toggleAll}>
                                    <ListItemIcon></ListItemIcon>
                                    <Typography variant="inherit">
                                        <Translate id={"_.Show All"} />
                                    </Typography>
                                </MenuItem>
                            )}
                        </MenuList>
                    </Popover>
                </TableCell>
                <Hidden smUp>
                    <TableCell padding={"checkbox"}></TableCell>
                </Hidden>
                {columns.map((column, i) => {
                    if (column.id === "checkbox") {
                        return (
                            <TableCell padding="checkbox" key={`${column.id}-${i}`}>
                                <Checkbox
                                    indeterminate={checkboxIndeterminate}
                                    checked={checkboxSelected}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell
                            key={`${column.id}-${i}`}
                            sortDirection={orderBy === column.id ? order : false}
                            className={clsx(
                                column.className,
                                "relative hider nowrap",
                                column.hideInMobile && "hidden md:table-cell"
                            )}
                            padding={column.padding}
                            align={column.align}
                        >
                            <>
                                {!column.noHide && (
                                    <IconButton
                                        onClick={toggleHide}
                                        size={"small"}
                                        className={"hid absolute top-1/5 z-99 right-0"}
                                    >
                                        <Icon fontSize={"small"}>
                                            {!headSet.has(column.id) ? "visibility_off" : "double_arrow"}
                                        </Icon>
                                    </IconButton>
                                )}
                                {column.sort ? (
                                    <MyTooltip
                                        title={<Translate id="crm.Sort" />}
                                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={order}
                                            onClick={createSortHandler(column.id)}
                                        >
                                            {column.label && <Translate id={column.label} />}
                                            {/*{typeof row.extraLabel === "function" && row.extraLabel()}*/}
                                        </TableSortLabel>
                                    </MyTooltip>
                                ) : (
                                    <Translate id={column.label} />
                                )}
                                {column.extraView && column.extraView()}
                            </>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export default LFTableHeaderView;
