import axios, {financialPrefix} from "../../../../../myaxios";

import {finishLoading, startLoading} from "@fuseActions";

export const GET_CONTRACT_DETAIL = "[FIN_GET_CONTRACT_DETAIL]";

export const SELECT_A_BILL  = "[FIN_SELECT_A_BILL]";

export const SELECT_A_ATTACHMENT = "[SELECT_A_ATTACHMENT]";

export const GET_CONTRACT_LOGS = "[GET_CONTRACT_LOGS]";

export function getContract(id, callback) {

    const request = axios.get(`${financialPrefix}/contract/${id}`);

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_CONTRACT_DETAIL,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getOprerationLogs(params, callback) {

    const request = axios.get(`${financialPrefix}/contract/logs`,{params: params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_CONTRACT_LOGS,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function selectABill(bill) {
    return  {
        type: SELECT_A_BILL,
        payload: bill,
    };
}

export function selectAAttachment(attachment) {
    return  {
        type: SELECT_A_ATTACHMENT,
        payload: attachment,
    };
}
