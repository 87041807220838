import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    InputAdornment,
    TextField,
    Typography,
    withMobileDialog,
    withStyles
} from "@material-ui/core";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {components} from "utils/Select2Components";
import FaveButton from "../../../../../components/layout/fav/FaveButton";
import axios, {crmPrefix} from "../../../../../myaxios";
import MapComponent from "../../projects/Dialogs/ProjectDialog/MapComponent";
import * as Actions from "../../store/actions";
import sources from "../../translation/commerce";

const styles = (theme) => ({
    mapContent: {
        height: 250,
        width: "100%",
        visibility: "visible",
        zIndex: 98888
    }
});

class CustomerDialog extends Component {
    state = {
        loading: false,

        license: "",
        brandId: "",
        brand: [{value: "", label: ""}],
        name: "",
        tag: "",
        city: "",

        error: false,

        openBrand: false,

        brands: [],

        anchorEl: null,
        anchorEl2: null,

        value: "",
        errorMsg: "",
        checkerLoading: false,

        showRedBorder: false,

        geoInfo: null,

        language: "cn",

        address: ""
    };

    constructor(props) {
        super(props);
        this.props.addTranslation(sources);
    }

    componentDidMount = () => {
        // console.log("获取到 customers dialog 的 路径", this.props.);

        if (this.props.selectedCustomer) {
            this.setState({
                id: this.props.selectedCustomer.id,
                name: this.props.selectedCustomer.name,
                value: this.props.selectedCustomer.license,
                city: this.props.selectedCustomer.city,
                brand: {value: this.props.selectedCustomer.brand.id, label: this.props.selectedCustomer.brand.name},
                brandId: this.props.selectedCustomer.brand.id,
                tag: this.props.selectedCustomer.tag,
                address: this.props.selectedCustomer.address,
                license: this.props.selectedCustomer.license
            });
            this.props.passBrandIdToMap(this.props.selectedCustomer.brand.id);
        } else if (this.props.selectedBrand) {
            this.setState({
                brandId: this.props.selectedBrand.id,
                brand: {label: this.props.selectedBrand.name, value: this.props.selectedBrand.id}
            });
            this.props.passBrandIdToMap(this.props.selectedBrand.id);
        }

        this.setState({
            loading: true
        });
        axios.get(`${crmPrefix}/brands?size=8`).then((res) => {
            const brands = res.data.content.map((item) => ({
                value: item.id,
                label: item.name
            }));
            this.setState({brands: brands, loading: false});
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            openLicense: false,
            anchorEl2: null,
            openBrand: false,
            anchorEl3: null,
            openCity: false
        });
    };

    onChangeBrand = (e) => {
        this.setState({
            loading: true
        });
        axios
        .get(`${crmPrefix}/brands?size=8`, {
            params: {
                name: e
            }
        })
        .then((res) => {
            const brands = res.data.content.map((item) => ({
                value: item.id,
                label: item.name
            }));
            this.setState({brands: brands, loading: false});
        });
    };

    error = (name, e) => {
        this.setState({[name]: e});
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    };

    onSubmit = () => {
        const {selectedCustomer} = this.props;
        this.setState({loading: true});
        if (!this.state.name) return;

        let formData;

        if (selectedCustomer) {
            formData = {
                name: this.state.name,
                license: this.state.license,
                brand: this.state.brandId,
                tag: this.state.tag,
                country: selectedCustomer.country,
                state: selectedCustomer.state,
                city: selectedCustomer.city,
                address: selectedCustomer.address
            };
        } else {
            formData = {
                name: this.state.name,
                license: this.state.license,
                brand: this.state.brandId,
                tag: this.state.tag,
                country: this.state.language === "cn" ? "中国" : this.state.geoInfo.levelOne,
                state: this.state.language === "cn" ? this.state.geoInfo.levelOne : this.state.geoInfo.levelTwo,
                city: this.state.language === "cn" ? this.state.geoInfo.levelTwo : this.state.geoInfo.levelThree,
                address: this.state.geoInfo.fullAddress
            };
        }

        if (this.props.selectedCustomer) formData.id = this.props.selectedCustomer.id;
        let request = this.props.selectedCustomer ? axios.put(`${crmPrefix}/customer`, formData) : axios.post(`${crmPrefix}/customer`, formData);

        request
        .then(() => {
            this.setState({loading: false});
            this.props.turnOff("customer");
            this.props.turnOn("customersShouldUpdate");
            this.props.showMessage({
                message: "message.Customer Saved!"
            });
        })
        .catch(() => {
            this.setState({loading: false});
        });
    };

    checker = (value) => {
        this.setState({checkerLoading: true});
        axios
        .get(`${crmPrefix}/checkCustomerLicenseAvailability`, {
            params: {
                license: value,
                brandId: this.state.brandId
            }
        })
        .then((res) => {
            if (res.data.result) {
                this.setState({
                    error: false,
                    license: value,
                    errorMsg: "crm.Available",
                    checkerLoading: false
                });
                // this.props.changeHandler(this.state.value)
            } else this.setState({error: true, errorMsg: "crm.Not available", checkerLoading: false});
        })
        .catch(() => {
            this.setState({checkerLoading: false});
            return false;
        });
    };

    Handler = (event) => {
        const value = event.target.value;
        this.setState({value});
        if (value.length > 3) {
            this.checker(value);
            this.setState({error: false, errorMsg: ""});
        } else {
            this.setState({error: true, errorMsg: "Must be more than 3"});
        }
    };

    handleGeoLocation = (e) => {
        this.setState({
            geoInfo: e,
            city: this.state.language === "cn" ? e.levelTwo : e.levelThree
        });
    };

    hadleGeoHaveError = (err) => {
    };

    notGetBrandId = (err) => {
        this.setState({
            showRedBorder: true
        });
    };

    handleChipChange = (value) => {
        if (value) {
            this.setState({brand: value, brandId: value.value, showRedBorder: false});
            this.props.passBrandIdToMap(value.value);
        } else {
            this.setState({brand: "", brandId: ""});
            this.props.clearBrandId();
        }
    };

    render() {
        const {selectedCustomer, dialogs, classes, selectedBrand} = this.props;
        const {loading, address, showRedBorder} = this.state;
        const {value, error, errorMsg} = this.state;

        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                open={!!dialogs.customer}
                scroll={"body"}
                onClose={() => {
                    this.props.turnOff("customer");
                    this.props.clearBrandId();
                }}
                fullWidth
            >
                <div className={loading ? "loading" : null}>
                    <DialogTitle
                        id="alert-dialog-title"
                        disableTypography
                        classes={{root: "flex justify-between items-center px-24 py-8 bg-customer-darker"}}
                    >
                        {selectedCustomer ? (
                            <>
                                <Typography className={"text-white"} variant={"h6"}>
                                    {" "}
                                    <Translate id="crm.Edit" /> {selectedCustomer.name}
                                </Typography>{" "}
                            </>
                        ) : (
                            <>
                                <Typography className={"text-white"} variant={"h6"}>
                                    {" "}
                                    <Translate id="crm.New Customer" />
                                </Typography>
                                <FaveButton colored className={"-mr-8"} sourceId={"customer"} title={"customer"} type={"dialog"} />
                            </>
                        )}
                    </DialogTitle>
                    <DialogContent className={"p-20"}>
                        <Grid container>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>person</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    autoFocus={true}
                                    className="mb-24 md:pr-20"
                                    fullWidth
                                    required
                                    onChange={this.handleChange("name")}
                                    label={<Translate id={"_.name"} />}
                                    value={this.state.name}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>bookmark</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    className="mb-24"
                                    fullWidth
                                    onChange={this.handleChange("tag")}
                                    label={<Translate id={"crm.Tags"} />}
                                    value={this.state.tag}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <Translate>
                                    {({translate}) => (
                                        <Select
                                            textFieldProps={{
                                                label: translate("crm.Brand"),
                                                InputLabelProps: {
                                                    shrink: true
                                                }
                                            }}
                                            isDisabled={selectedBrand !== null}
                                            onInputChange={(e) => this.onChangeBrand(e)}
                                            classes={classes}
                                            className={showRedBorder ? "md:pr-20 mb-24 -mt-4" : "border-red md:pr-20 mb-24 -mt-4"}
                                            // styles={selectStyles}
                                            options={this.state.brands}
                                            components={components}
                                            value={this.state.brand}
                                            onChange={this.handleChipChange}
                                            placeholder={translate("crm.Search a brand")}
                                            isClearable
                                        />
                                    )}
                                </Translate>
                            </Grid>

                            {this.state.brandId && (
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon>credit_card</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {this.state.checkerLoading ? <CircularProgress size={20} /> : <CircularProgress size={0} />}
                                                </InputAdornment>
                                            )
                                        }}
                                        className={"mb-24"}
                                        fullWidth
                                        required
                                        value={value}
                                        error={error}
                                        helperText={
                                            errorMsg ? (
                                                errorMsg === "Must be more than 3" ? (
                                                    <Translate id="crm.Must be more than 3 character" data={{parameter: 3}}>
                                                        {" "}
                                                        {"Must be more than ${parameter} character"}{" "}
                                                    </Translate>
                                                ) : (
                                                    <Translate id={errorMsg} />
                                                )
                                            ) : (
                                                ""
                                            )
                                        }
                                        onChange={this.Handler}
                                        label={<Translate id="crm.Business license No" />}
                                    />
                                </Grid>
                            )}

                            <Grid item sm={12} xs={12}>
                                <TextField
                                    id={"customer-new"}
                                    // disabled={this.state.name === "" }
                                    fullWidth
                                    required
                                    label={
                                        this.state.geoInfo ? (
                                            `${this.state.geoInfo.fullAddress}`
                                        ) : this.state.address ? (
                                            this.state.address
                                        ) : (
                                            <Translate id={"crm.Select address on the map"} />
                                        )
                                    }
                                />
                            </Grid>

                            <Grid item sm={12} xs={12}>
                                <div className={classNames(classes.mapContent, "mt-24")}>
                                    <MapComponent
                                        haveGetLocation={this.handleGeoLocation}
                                        haveError={this.hadleGeoHaveError}
                                        useType={"customer"}
                                        showErrorTips={this.notGetBrandId}
                                        showLoading={() => this.setState({loading: true})}
                                        finishedLoading={() => this.setState({loading: false})}
                                        inputId={"customer-new"}
                                        address={selectedCustomer && address}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.props.turnOff("customer");
                            }}
                        >
                            <Translate id="_.cancel" />
                        </Button>
                        <Button
                            disabled={this.state.name === "" || this.state.error || this.state.city === ""}
                            color="secondary"
                            onClick={this.onSubmit}
                        >
                            <Translate id="_.submit" />
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff,
            turnOn: fuseActions.turnOn,
            showMessage: fuseActions.showMessage,
            passBrandIdToMap: Actions.passBrandIdToMap,
            clearBrandId: Actions.clearToMapBrandId,
            getProjectsByCustomerId: Actions.getProjectsByCustomerId,
            getAllCustomers: Actions.getCustomers
        },
        dispatch
    );
}

function mapStateToProps({fuse, commerce}) {
    return {
        dialogs: fuse.dialogs,
        selectedCustomer: commerce.customers.selectedCustomer,
        selectedBrand: commerce.brands.selectedBrand,
        brandIdToMap: commerce.brands.brandIdToMap
    };
}

export default withMobileDialog()(
    withRouter(withStyles(styles, {withTheme: true})(withLocalize(connect(mapStateToProps, mapDispatchToProps)(CustomerDialog))))
);
