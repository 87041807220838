import {FuseScrollbars} from "@fuse";
import {AppBar, Hidden, Icon} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import NavbarMobileToggleButton from "app/layouts/shared-components/NavbarMobileToggleButton";
import Navigation from "app/layouts/shared-components/Navigation";
import clsx from "clsx";
import {Translate} from "react-localize-redux";
import {version} from "../../../../ENV.VARIABLE";
import Logo from "../../shared-components/Logo";
import NavbarFoldedToggleButton from "../../shared-components/NavbarFoldedToggleButton";

const useStyles = makeStyles({
    content: {
        overflowX: "hidden",
        overflowY: "auto",
        "-webkit-overflow-scrolling": "touch",
        background: "linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 40px, 100% 10px",
        backgroundAttachment: "local, scroll",
    },
});

function NavbarLayout1(props) {
    const classes = useStyles();

    return (
        <div className={clsx("flex flex-col overflow-hidden h-full", props.className)}>
            <AppBar color="primary" position="static" elevation={0} className="flex flex-row items-center shrink h-44 min-h-44 pr-12">
                <div className="flex-1 h-full">
                    <Logo />
                </div>

                <Hidden mdDown>
                    <NavbarFoldedToggleButton className="w-44 h-44  min-w-44 p-0">
                        <Icon>menu_open</Icon>
                    </NavbarFoldedToggleButton>
                </Hidden>

                <Hidden lgUp>
                    <NavbarMobileToggleButton className="w-40 h-40 p-0">
                        <Icon>arrow_back</Icon>
                    </NavbarMobileToggleButton>
                </Hidden>
            </AppBar>

            <FuseScrollbars className={clsx(classes.content)}>
                {/*<UserNavbarHeader/>*/}

                <Navigation layout="vertical" />
                <span className="opacity/50 text-white p-16 mb-12 font-italic">
                    {<Translate id={"_.Version"} />}: {version}
                </span>
            </FuseScrollbars>
        </div>
    );
}

export default NavbarLayout1;
