import DateFnsUtils from "@date-io/date-fns";
import type {DatePickerProps} from "@material-ui/pickers";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import enLocal from "date-fns/locale/en-US";
import cnLocal from "date-fns/locale/zh-CN";
import {useTranslation} from "react-i18next";

const localeMap = {
    en: enLocal,
    cn: cnLocal,
};

function DatePickerWrapper(props: DatePickerProps) {
    const {format = "yy/MM/dd", ...rest} = props;

    const {i18n} = useTranslation();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
            <DatePicker
                autoOk
                format={format}
                cancelLabel={i18n.language === "cn" ? "取消" : "Cancel"}
                okLabel={i18n.language === "cn" ? "确定" : "Sure"}
                disableToolbar={true}
                {...rest}
            />
        </MuiPickersUtilsProvider>
    );
}

export default DatePickerWrapper;
