import {useCallback, useMemo} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";

function useRouterSwitch() {
    const history = useHistory();

    const location = useLocation();

    const params = useParams();

    const goto = useCallback(
        (router) => {
            history.push(`${router}`);
        },
        [history]
    );

    const backTo = useCallback(() => {
        history.goBack();
    }, [history]);

    const pathname = useMemo(() => {
        return location?.pathname;
    }, [location?.pathname]);

    const query = useMemo(() => {
        return params;
    }, [params]);

    return {goto, backTo, pathname, params: query};
}

export default useRouterSwitch;
