import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Translate} from "react-localize-redux";

const Decision = ({formData, handleFormData}) => {
    return (
        <div>
            <FormControlLabel
                className="mb-0 mt-12 w-full"
                control={<Checkbox checked={formData.isDecisionMaker} onChange={ (e) => {
                    e.stopPropagation();
                    handleFormData("isDecisionMaker")({target: {value: !formData.isDecisionMaker}});} }/>}
                label={<Translate id={"market.Set as decision maker"}/>}
            />
        </div>
    );
};

export default Decision;
