import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

type Props = {
    className?: string;
    children: any;
    to?: string;
    dense?: boolean;
    short?: boolean;
    truncate?: boolean;
};

const ItemTitle = ({className, children, to, dense, short, truncate}: Props) => {
    const listView = useSelector(({fuse}) => fuse.dialogs.listView);
    const clickable = !!to;
    return (
        <Typography
            onClick={(e) => {
                if (clickable) e.stopPropagation();
            }}
            component={clickable ? Link : "p"}
            className={clsx("inline-block  font-bold", truncate ? "truncate" : "balance", listView || dense ? " text-14" : "text-18", className)}
            to={to}>
            {short ? children.slice(0, 20) : children}
        </Typography>
    );
};

export default ItemTitle;
