import {FormControl, FormLabel, List, ListItem, ListItemText} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import {makeStyles} from "@material-ui/core/styles";
import {MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import DatePickerWrapper from "utils/DatePickerWrapper/DatePickerWrapper";
import axios, {crmPrefix} from "../../../../../myaxios";
import {yearMonthDayTimestamp} from "../../../incomes/utils/DateUtils";
import ContactItem from "../../contacts/list/ItemView";
import DealItem from "../../deals/list/ItemView";
import {CreateAndActiveDateItem} from "../../marketingTools";
import CompanyLabel from "../../utils/CompanyLabel";
import GridList from "../../utils/GridList";
import Owner from "../../utils/Owner";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const RightSidebar = (props) => {
    const {sourceItem, refresh, canEdit} = props;
    const [loading, setLoading] = useState(false);
    const [startError, setStartError] = useState("");
    const [endError, setEndError] = useState("");
    const dispatch = useDispatch();

    const priorityHandle = (priority) => {
        setLoading(true);
        // setPriority(priority);
        const data = {
            activityId: sourceItem.id,
            priority: priority
        };
        axios
        .put(`${crmPrefix}/activitySetPriority`, data)
        .then(() => {
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Activity priority changed!"
                })
            );
            refresh();
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        });
    };

    const reminderHandle = (reminder) => {
        setLoading(true);

        let t = new Date(reminder);
        const reminderTime = yearMonthDayTimestamp(t);

        const data = {
            activityId: sourceItem.id,
            reminder: reminderTime
        };
        axios
        .put(`${crmPrefix}/activitySetReminder`, data)
        .then(() => {
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Activity reminder changed!"
                })
            );
            refresh();
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        });
    };

    const startDateHandle = (start) => {
        setLoading(true);
        let t = new Date(start);
        const endDaTE = new Date(props.sourceItem.endDate);
        const startTime = yearMonthDayTimestamp(t);
        const endTime = yearMonthDayTimestamp(endDaTE);
        if (
            props.sourceItem.endDate === 0 ||
            props.sourceItem.endDate === undefined ||
            props.sourceItem.endDate === null
        ) {
            const data = {
                id: sourceItem.id,
                startDate: startTime
            };
            changeDateAction(data, "start");
        } else {
            if (startTime < endTime) {
                const data = {
                    id: sourceItem.id,
                    startDate: startTime
                };
                changeDateAction(data, "start");
            } else {
                setStartError("market.Start date should be earlier than end date");
            }
        }
    };

    const endDateHandle = (end) => {
        setLoading(true);
        let t = new Date(end);
        const startDaTE = new Date(props.sourceItem.startDate);
        const endTime = yearMonthDayTimestamp(t);
        const startTime = yearMonthDayTimestamp(startDaTE);
        if (
            props.sourceItem.startDate === 0 ||
            props.sourceItem.startDate === undefined ||
            props.sourceItem.startDate === null
        ) {
            const data = {
                id: sourceItem.id,
                endDate: endTime
            };
            changeDateAction(data, "end");
        } else {
            if (startTime < endTime) {
                const data = {
                    id: sourceItem.id,
                    endDate: endTime
                };
                changeDateAction(data, "end");
            } else {
                setEndError("market.End date should be later than start date");
            }
        }
    };

    const changeDateAction = (data, type) => {
        setEndError("");
        setStartError("");
        axios
        .put(`${crmPrefix}/activity`, data)
        .then(() => {
            type === "start"
                ? dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.Activity start date changed!"
                    })
                )
                : dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.Activity end date changed!"
                    })
                );
            refresh();
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        });
    };

    const changeResult = (res) => {
        setLoading(true);
        const params = {
            id: sourceItem.id,
            result: res
        };

        axios
        .put(`${crmPrefix}/activity`, params)
        .then(() => {
            setLoading(false);
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Activity result changed!"
                })
            );
            refresh();
        })
        .catch(() => {
            setLoading(false);
        });
    };

    const subProps = {
        sourceType: "activity",
        sourceItem: sourceItem,
        refresh: () => refresh()
    };

    return (
        <Card className={" mt-20 mr-20 mb-20 h- "}>
            <Owner user={sourceItem.user} />
            <CompanyLabel link={"activity"} {...props} />
            <List dense className={"pb-2"}>
                <ListItem>
                    <ListItemText
                        primary={
                            <FormControl className={"w-full mr-2"}>
                                <Translate>
                                    {({translate}) => (
                                        <DatePickerWrapper
                                            disableToolbar
                                            disabled={!canEdit}
                                            variant="inline"
                                            error={startError !== ""}
                                            label={translate("market.start date")}
                                            emptyLabel={translate("market.click to set startDate")}
                                            autoOk
                                            helperText={startError !== "" ? translate(startError) : ""}
                                            minDate={new Date("2000-1-1")}
                                            // maxDate={new Date()}
                                            format={"yyyy-MM-dd"}
                                            value={new Date(sourceItem.startDate)}
                                            onChange={(date) => startDateHandle(date)}
                                        />
                                    )}
                                </Translate>
                            </FormControl>
                        }
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={
                            <FormControl className={"w-full mr-2"}>
                                <Translate>
                                    {({translate}) => (
                                        <DatePickerWrapper
                                            disableToolbar
                                            disabled={!canEdit}
                                            variant="inline"
                                            error={endError !== ""}
                                            label={translate("market.end date")}
                                            helperText={endError !== "" ? translate(endError) : ""}
                                            emptyLabel={translate("market.click to set endDate")}
                                            autoOk
                                            value={new Date(sourceItem.endDate)}
                                            minDate={new Date("2000-1-1")}
                                            format={"yyyy-MM-dd"}
                                            onChange={(date) => endDateHandle(date)}
                                        />
                                    )}
                                </Translate>
                            </FormControl>
                        }
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={
                            <FormControl className={"w-full mr-2"}>
                                <Translate>
                                    {({translate}) => (
                                        <DatePickerWrapper
                                            disableToolbar
                                            variant="inline"
                                            disabled={!canEdit}
                                            label={translate("market.reminder date")}
                                            emptyLabel={translate("market.reminder is null,click to set")}
                                            autoOk
                                            value={new Date(sourceItem.reminder)}
                                            minDate={new Date("2000-1-1")}
                                            format={"yyyy-MM-dd"}
                                            onChange={reminderHandle}
                                        />
                                    )}
                                </Translate>
                            </FormControl>
                        }
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={
                            <FormControl className={"mt-1 w-full mr-2"}>
                                <FormLabel>{<Translate id={"market.priority"} />}</FormLabel>
                                <div
                                    className={
                                        "radius bg-grey-light border flex w-full text-center mt-1 h-40 overflow-hidden rounded"
                                    }>
                                    <MyTooltip title={<Translate id={"market.high"} />}>
                                        <span className="flex-1 items-center flex justify-center h-full">
                                            <button
                                                disabled={!canEdit}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    priorityHandle("high");
                                                }}
                                                className={classNames(
                                                    "flex-1 items-center flex justify-center h-full",
                                                    sourceItem.priority === "high" && "text-white bg-red"
                                                )}>
                                                <Translate id={"market.high"} />
                                            </button>
                                        </span>
                                    </MyTooltip>
                                    <MyTooltip title={<Translate id={"market.general"} />}>
                                        <span className="flex-1 items-center flex justify-center h-full">
                                            <button
                                                disabled={!canEdit}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    priorityHandle("general");
                                                }}
                                                className={classNames(
                                                    "flex-1 items-center border-r-1 border-l-1  flex justify-center h-full",
                                                    sourceItem.priority === "general" && "text-white bg-orange"
                                                )}>
                                                <Translate id={"market.general"} />
                                            </button>
                                        </span>
                                    </MyTooltip>
                                    <MyTooltip title={<Translate id={"market.low"} />}>
                                        <span className="flex-1 items-center flex justify-center h-full">
                                            <button
                                                disabled={!canEdit}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // this.setState({severity: "minor"})
                                                    priorityHandle("low");
                                                }}
                                                className={classNames(
                                                    "flex-1 items-center flex justify-center h-full",
                                                    sourceItem.priority === "low" && "text-white bg-green"
                                                )}>
                                                <Translate id={"market.low"} />
                                            </button>
                                        </span>
                                    </MyTooltip>
                                </div>
                            </FormControl>
                        }
                    />
                </ListItem>
                <Divider />

                <ListItem>
                    <ListItemText
                        primary={
                            <FormControl className={"mt-1 w-full mr-2"}>
                                <FormLabel>{<Translate id={"market.result"} />}</FormLabel>
                                <div
                                    className={
                                        "radius bg-grey-light border flex w-full text-center mt-1 h-40 overflow-hidden rounded"
                                    }>
                                    <MyTooltip title={<Translate id={"market.bad"} />}>
                                        <span className="flex-1 items-center flex justify-center h-full">
                                            <button
                                                disabled={!canEdit}
                                                onClick={() => changeResult("bad")}
                                                className={classNames(
                                                    "flex-1 items-center flex justify-center h-full",
                                                    sourceItem.result === "bad" && "text-white bg-red rounded"
                                                )}>
                                                <Icon>sentiment_dissatisfied</Icon>
                                            </button>
                                        </span>
                                    </MyTooltip>
                                    <MyTooltip title={<Translate id={"market.general"} />}>
                                        <span className="flex-1 items-center flex justify-center h-full">
                                            <button
                                                disabled={!canEdit}
                                                onClick={() => changeResult("general")}
                                                className={classNames(
                                                    "flex-1 items-center flex justify-center h-full",
                                                    sourceItem.result === "general" && "text-white bg-orange"
                                                )}>
                                                <Icon>mood</Icon>
                                            </button>
                                        </span>
                                    </MyTooltip>
                                    <MyTooltip title={<Translate id={"market.good"} />}>
                                        <span className="flex-1 items-center flex justify-center h-full">
                                            <button
                                                disabled={!canEdit}
                                                onClick={() => changeResult("good")}
                                                className={classNames(
                                                    "flex-1 items-center flex justify-center h-full",
                                                    sourceItem.result === "good" && "text-white bg-green rounded"
                                                )}>
                                                <Icon>sentiment_satisfied</Icon>
                                            </button>
                                        </span>
                                    </MyTooltip>
                                </div>
                            </FormControl>
                        }
                    />
                </ListItem>

                {(sourceItem.createDate || sourceItem.activeDate) && (
                    <CreateAndActiveDateItem sourceItem={sourceItem} />
                )}

                <Divider />

                {sourceItem.deal && (
                    <>
                        <FormLabel className="ml-16 mt-16">{<Translate id={"market.deal"} />}</FormLabel>
                        <DealItem small item={sourceItem.deal} />
                    </>
                )}
                <Divider />
                <GridList {...subProps} withoutCard link={"companyContacts"} type={"contact"} GridItem={ContactItem} />
            </List>
        </Card>
    );
};

export default RightSidebar;
