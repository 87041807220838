import * as Actions from "../actions/myIcome.actions";

const initialState = {
    /*
    * {
  "page": 0,
  "size": 0,
  "totalPages": 0,
  "totalElements": 0,
  "content": [
    {
      "id": "string",
      "description": "string",
      "importCount": 0,
      "incomeCount": 0,
      "user": {},
      "timestamp": 0
    }
  ]
}
    * */
    // myIncomesResObject: null,
    content      : [],
    totalElements: 0,
};

const myincomeReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_MY_INCOMES:{
            // let tobj = cloneDeep(state);
            // tobj.isManagerIncome = action.payload
            // return tobj;
            return {
                ...state,
                ...action.payload
            };
        }
        default:{
            return state;
        }
    }
};

export default myincomeReducer;
