import {Dialog, DialogContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {UserView} from "@ui";

import {useMemoizedFn, useMount} from "ahooks";
import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useMUIDialog} from "../../../../@fuse/hooks";

import {GDialogRegistries} from "../../../../@fuse/state/constant";
import axios, {userPrefix} from "../../../../myaxios";
import {AsyncSelectQuery} from "../../../../utils/RootComponents";
import * as fuseActions from "../../../store/actions";
import json from "../translate/income.json";
import {useIncomeCommonUtils} from "../utils/state";
import {usePutIncomeUser} from "./hooks";

function MyIncomeChangeUserDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.changeUserMyIncome
    });

    const dispatch = useDispatch();

    const {run, isMutating} = usePutIncomeUser();
    const {currentIncome, onChangeCurrentIncome} = useIncomeCommonUtils();
    const [handler, setHandler] = useState("");

    useMount(() => {
        props.addTranslation(json);
    });

    useEffect(() => {
        return () => {
            onChangeCurrentIncome(null);
        };
    }, []);

    const getHandlers = useMemoizedFn((params) => {
        return axios.get(`${userPrefix}/myContacts?showMyFirmUser=true&size=8`, {
            params
        });
    });

    const handleCreateHandlerId = useMemoizedFn((datas) => {
        return new Promise((resolve, reject) => {
            const users = datas.map((item) => ({
                value: item.user.id,
                user: item.user,
                label: <UserView user={item?.user} />
            }));
            resolve(users);
        });
    });

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSelectHandler = useMemoizedFn((user) => {
        setHandler(user);
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        const params = {
            id: currentIncome?.id,
            user: handler?.value || ""
        };
        run(params).then((response) => {
            if (response.status === 200) {
                finshParams?.onFinish();
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "income.Handler setup successful"
                    })
                );
                onTurnOffDialog();
            }
        });
    });
    return (
        <Dialog classes={{paper: "overflow-visible", root: "overflow-visible"}} {...dialogProps}>
            <div className={isMutating ? "flex flex-col flex-grow loading" : "flex flex-col flex-grow"}>
                <DialogTitle id="NewUserDialog-dialog-title">
                    {<>{<Translate id={"income.SetIncomeUser"} />}</>}
                </DialogTitle>
                <DialogContent className={"overflow-visible"}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <AsyncSelectQuery
                                defaultValue={handler}
                                dataSourceRequest={getHandlers}
                                standaloneMode
                                onCreateSelectValue={handleCreateHandlerId}
                                onChangeValue={handleSelectHandler}
                                name={"keyword"}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>
                        {<Translate id={"_.cancel"} />}
                    </Button>
                    <Button
                        variant={"contained"}
                        // disabled={handler === "" || !handler}
                        onClick={handleSubmit}
                        color="secondary"
                        autoFocus={true}
                    >
                        {<Translate id={"_.sure"} />}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(MyIncomeChangeUserDialog);
