import {lazy} from "react";

export const  FinancialConfig = {
    settings: {
        layout: {
            config: {

                 }
            }
        },
    routes: [
        {
            path: "/financial/:firmId/hooks",
            component: lazy(() =>
                import("./AccountPage")
            )
        },
        {
            path: "/financial/hook/:hookId/accounts",
            component: lazy(() =>
                import("./AccountPage")
            )
        },
        {
            path: "/financial/:firmId/hook/:hookId/accounts",
            component: lazy(() =>
                import("./AccountPage")
            )
        },
        {
            path: "/financial/accounts",
            component: lazy(() =>
                import("./AccountPage")
            )
        }
    ],
};
