import {lazy} from 'react';

export const HrConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: "/hr/departments",
            component: lazy(() => import("./departments/Page"))
        },
        {
            path: "/hr/edit-departments",
            component: lazy(() => import("./departments/DepStructure"))
        },
        {
            path: "/hr/employees",
            component: lazy(() => import("./employees/Page"))
        },
        {
            path: "/wx/users",
            component: lazy(() => import("./wx/Page"))
        }
//        {
//            path: "/hr/requests",
//            component: lazy(() => import("./MyFirm/Requests"))
//        }
    ]
};
