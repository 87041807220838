import {lazy} from "react";

export const ConfigCompanies = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/company/:id",
            component: lazy(() =>
                import("./company/SinglePage")
            )
        },
        {
            path     : "/company/:id/",
            component: lazy(() =>
                import("./company/SinglePage")
            )
        },
        {
            path     : "/marketing/companies",
            component: lazy(() =>
                import("./list/Page")
            )
        }
    ]
};
