import * as Actions from "../actions";

const initialState = {
    data: {
        totalElements: 0,
        content: 0
    },
    selected: null,
    params: {}
};

const listReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_LIST:
            return {
                ...state,
                data: {...action.payload}
            };

        case Actions.SELECT_ITEM:
            return {
                ...state,
                selected: action.data
            };
        default:
            return state;
    }
};

export default listReducer;
