import * as Actions from '../actions';

const selectedItemReducer = function (state = "1", action) {
    if (action.type === Actions.SET_SELECTED_ITEM) {
        return action.payload;
    } else {
        return state;
    }
};

export default selectedItemReducer;
