import {CHANGE_STEP, CHOOSE_FORM, GET_STAGES, GET_TEMPLATE, SELECT_CATEGORY, SELECT_STAGE} from '../actions';
import _ from 'lodash';

const initialState = {
    data: null,
    searchText: "",
    stages: [],
    activeStep: 0,
    selectedStage: null,
};


function chooseForm(state, {form}) {
    let stages = _.cloneDeep(state.stages);
    stages[state.activeStep] = {
        ...stages[state.activeStep],
        ...form
    };
    return {
        ...state,
        stages
    };
}

const templateReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_TEMPLATE: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_STAGES: {
            return {
                ...state,
                stages: action.payload
            };
        }
        case CHANGE_STEP: {
            return {
                ...state,
                activeStep: action.step
            };
        }
        case SELECT_STAGE: {
            return {
                ...state,
                selectedStage: action.stage
            };
        }
        case SELECT_CATEGORY: {
            return {
                ...state,
                selectedCategory: action.payload
            };
        }
        case CHOOSE_FORM: {
            return chooseForm(state, action);
        }
        default: {
            return state;
        }
    }
};

export default templateReducer;
