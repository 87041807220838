import {useMemo} from "react";
import useQueryStore from "./useQueryStore";
import {useIncomeSure} from "./useIncomeSure";
import {useShallow} from "zustand/react/shallow";

function useIncomeSureUtils() {
    const _hasHydrated = useQueryStore((state) => state._hasHydrated);

    const _query = useQueryStore((state) => state.query);

    const _actions = useQueryStore((state) => state.actions);

    const {
        isIncomeSure,
        isSelectedAll,
        incomeSureListSelected,
        incomeSureSingleSelected,
        onChangeIncomeSureSingleSelected,
        onChangeIncomeSureListSelected,
        onChangeIsIncomeSure,
        onChangeIsSelectedAll
    } = useIncomeSure(useShallow((state)=>({
        isIncomeSure: state.isIncomeSure,
        isSelectedAll: state.isSelectedAll,
        incomeSureListSelected: state.incomeSureListSelected,
        incomeSureSingleSelected: state.incomeSureSingleSelected,
        onChangeIncomeSureSingleSelected: state.onChangeIncomeSureSingleSelected,
        onChangeIncomeSureListSelected: state.onChangeIncomeSureListSelected,
        onChangeIsIncomeSure: state.onChangeIsIncomeSure,
        onChangeIsSelectedAll: state.onChangeIsSelectedAll,
    })));

    return {
        hasHydrated: _hasHydrated,
        query: _query,
        actions: _actions,
        isIncomeSure,
        isSelectedAll,
        onChangeIsIncomeSure,
        incomeSureListSelected,
        incomeSureSingleSelected,
        onChangeIncomeSureSingleSelected,
        onChangeIncomeSureListSelected,
        onChangeIsSelectedAll,
    };
}

export default useIncomeSureUtils;
