import {Icon} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {LocalTimeStamp, MyAvatar, ParseHTML} from "@ui";
import * as fuseActions from "@fuseActions";
import clsx from "clsx";
import {useState} from "react";

import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import EditorInput from "utils/EditorInput/EditorInput";
import NameTooltipComponent from "utils/NameTooltipComponent";
import axios, {crmPrefix} from "../../../../myaxios";

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    note: {
        zIndex: "9999999999999999999999999999"
    }
}));

const Notes = ({sourceItem, sourceType, refresh, activeLanguage}) => {
    const [expanded, setExpanded] = useState(true);
    const [description, setDescription] = useState("");
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const sendNote = () => {
        const data = {
            source: sourceType,
            sourceId: sourceItem.id,
            title: "",
            description
        };
        axios.put(`${crmPrefix}/salesNote`, data).then(() => {
            setDescription("");
            refresh();
        });
    };

    const deleteNote = (id) => () => {
        const language = activeLanguage.code;
        const data = {
            source: sourceType,
            sourceId: sourceItem.id,
            noteId: id
        };
        // const tRequest = axios.delete(`${crmPrefix}/salesNote`, {params: data});
        const tTtile = language === "en" ? "Do you want to delete this note?" : "确定要删除该备注吗?";
        const tConfirmButtonText = language === "en" ? "Delete" : "删除";
        const tSuccessM = language === "en" ? "Delete success!" : "删除成功!";
        const successFunc = (success) => {
            refresh();
            if (success instanceof Function) {
                success();
            }
        };
        const request = (success, failed) => {
            axios
            .delete(`${crmPrefix}/salesNote`, {params: data})
            .then((res) => {
                if (success instanceof Function) {
                    success();
                }
            })
            .catch(() => {
                if (failed instanceof Function) {
                    failed();
                }
            });
        };
        dispatch(fuseActions.showMyDialog(tTtile, request, successFunc, tSuccessM, tConfirmButtonText));
        dispatch(fuseActions.turnOn("MyDialog"));
    };

    return (
        <Card className={"m-20"} id={"#notes"}>
            {
                <Translate>
                    {({translate}) => (
                        <CardHeader
                            subheader={translate("market.parameter count notes", {count: sourceItem.notes.length})}
                            title={<Translate id={"market.Notes"} />}
                            action={
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded
                                    })}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <Icon>keyboard_arrow_down</Icon>
                                </IconButton>
                            }
                        />
                    )}
                </Translate>
            }

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className="pt-0">
                    <EditorInput
                        disableMention
                        onSubmit={sendNote}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={<Translate id={"market.Add note"} />}
                    />
                    {/*<TextField value={description} onChange={(e)=>setDescription(e.target.value)} fullWidth label={<Translate id={"market.Add note"}/>} multiline rows={3} />*/}
                    {/*<Button onClick={sendNote} disabled={description.trim() === ""} variant={"contained"} color={"primary"} className={"mt-12"}>{<Translate id={"market.Send"}/>}</Button>*/}
                    {sourceItem.notes.length > 0 && (
                        <>
                            <Divider className={"mt-16"} />
                            <List dense>
                                {sourceItem.notes.map((__, i) => (
                                    <ListItem key={i} classes={{root: "pb-0"}} alignItems="flex-start">
                                        <ListItemAvatar>
                                            <MyAvatar user={__.user} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <div className="flex flex-row items-center">
                                                        <span className={"text-bold capitalize " + classes.title}>
                                                            <NameTooltipComponent user={__.user} strongTagFLname />
                                                            {/*{<NameLabel firstName={__.user.firstName} lastName={__.user.lastName}/>}*/}
                                                        </span>
                                                    <small className={"text-grey ml-2"}>
                                                        {<LocalTimeStamp datetime={__.timestamp} />}
                                                    </small>
                                                </div>
                                            }
                                            secondary={<ParseHTML html={__.description} />}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton size={"small"} onClick={deleteNote(__.id)}>
                                                <Icon fontSize={"small"}>delete</Icon>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default withLocalize(Notes);
