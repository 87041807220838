import {lazy} from "react";

export const ParkingConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/parking/visitorCars",
            component: lazy(() => import("./visitor/Page"))
        },
        {
            path     : "/parking/monthCars",
            component: lazy(() => import("./manager/Page"))
        }
    ]
};
