import { turnOff, minimize, turnOn, startLoading, finishLoading, showMessage, replaceParams } from "@fuseActions";
import {Button, DialogActions, DialogTitle, Icon, IconButton, Typography, withMobileDialog} from "@material-ui/core";
import { stringify } from "qs";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import EventBus, {UPDATE_DEAL_SELECTOR_AFTER_CREATE_A_DEAL_NOTIFI} from "utils/EventBus";

import withDialog from "utils/withDialog";
import FaveButton from "../../../../../components/layout/fav/FaveButton";
import axios, {crmPrefix} from "../../../../../myaxios";
import {ContactInputItem, getGridListAsycnNotificationName} from "../../marketingTools";
import transJson from "../../translates/translate.json";
import { getList, selectItem, getItem } from "../store/actions";
import Step0 from "./Step0";
import Step1 from "./Step1";

const initState = {
    loading: false,
    step: 0,

    formData: {
        name: "",
        companyId: "",
        id: "",
        contactIds: [],
        amount: "",
        closingDate: "",
        stage: "",
        type: "",
        probability: 50,
        leadSource: "",
        description: "",
    },
};

// const TabPanel = (props) =>  {
//     const { children, value, index, ...other } = props;
//
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`vertical-tabpanel-${index}`}
//             className="bg-grey-lighter flex-grow"
//             style={{borderRadius: "0 5px 5px 0"}}
//             aria-labelledby={`vertical-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <div>
//                     {children}
//                 </div>
//             )}
//         </div>
//     );
// };

class DealDialog extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(transJson);
    }

    state = initState;

    handleFormData = (name) => (event) => {
        const formData = {...this.state.formData};
        formData[name] = event.target.value;
        this.setState({
            formData,
        });
    };

    componentDidMount = () => {
        const {selectedItem, selectedCompany, selectedContact} = this.props;
        if (selectedItem) {
            this.setData(selectedItem);
        }
        if (selectedCompany) {
            this.setData({
                contacts: [],
                company: selectedCompany,
            });
        }
        if (selectedContact) {
            this.setData({
                contacts: [selectedContact],
            });
        }
    };

    componentDidUpdate = (prevProps) => {
        const {selectedItem} = this.props;
        if (selectedItem !== prevProps.selectedItem) {
            selectedItem ? this.setData(selectedItem) : this.clearData();
        }
    };

    componentWillUnmount = () => {
        this.clearData();
    };

    setData = (selectedItem) => {
        this.setState({
            formData: {
                ...initState.formData,
                ...selectedItem,
                stage: selectedItem.stage ? selectedItem.stage.key : "",
                companyId: selectedItem.company ? selectedItem.company.id : "",
                type: selectedItem.type ? selectedItem.type.key : "",
                leadSource: selectedItem.leadSource ? selectedItem.leadSource.key : "",
                contactIds: selectedItem.contacts.map((__) => ({
                    value: __.id,
                    label: <ContactInputItem item={__} />,
                })),
            },
        });
    };

    clearData = () => {
        this.setState(initState);
    };

    filterData = () => {
        const search = {...this.props.search};
        this.props.getList(search);
        delete search.keyword;
        delete search.content;
        localStorage.setItem("dealsFilter", stringify({...search}));
    };

    onSubmit = (e) => {
        e.preventDefault();
        const pathName = this.props.location.pathname;
        const {search, selectedItem, dialogs, turnOn, showMessage, getItem, match, item, replaceParams} = this.props;
        this.setState({loading: true});
        let formData = this.state.formData;
        formData = {
            ...formData,
            contactIds: formData.contactIds.map((t) => t.value),
        };

        if (selectedItem) {
            formData.id = selectedItem.id;
        }

        let request =
            selectedItem && !dialogs["duplicateItem"] ? axios.put(`${crmPrefix}/deal`, formData) : axios.post(`${crmPrefix}/deal`, formData);
        request
            .then((res) => {
                this.setState({loading: false});
                showMessage({
                    message: "message.Item Saved!",
                    variant: "success",
                });
                if (item || selectedItem) {
                    getItem(item.id);
                } else {
                    turnOn("companiesSideFilter");
                    replaceParams({...match.params, size: search.size}).then(() => this.filterData());
                }

                if (this.props.selectedGridListType) {
                    const notifiName = getGridListAsycnNotificationName(this.props.selectedGridListType);
                    EventBus.dispatch(notifiName, notifiName);
                } else if (pathName === "/marketing/activities") {
                    EventBus.dispatch(UPDATE_DEAL_SELECTOR_AFTER_CREATE_A_DEAL_NOTIFI, res.data);
                }
                this.clearData();
                this.closeDialog();
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    closeDialog = () => {
        this.props.turnOff("deal");
        this.props.selectItem(null);
    };

    goBack = () => {
        if (this.state.step !== -1) this.setState((state) => ({step: state.step - 1}));
    };

    render() {
        const {selectedItem, dialogs} = this.props;
        const {loading, formData, step} = this.state;

        const disableSubmit = formData.name.trim() === "" || formData.stage === "" || formData.contactIds.length === 0;

        const stepsProps = {
            handleFormData: this.handleFormData,
            onSubmit: this.onSubmit,
            step,
            selectedItem,
            formData,
            goBack: this.goBack,
            closeDialog: this.closeDialog,
        };

        return (
            <div className={loading ? "loading" : null}>
                <DialogTitle
                    id="alert-dialog-title"
                    disableTypography
                    classes={{root: "drag flex justify-between items-center px-24 py-8 rounded-t"}}>
                    {selectedItem ? (
                        <Translate>
                            {({translate}) => (
                                <Typography variant={"h6"}>{translate("market.Edit deal with parameter", {name: selectedItem.name})}</Typography>
                            )}
                        </Translate>
                    ) : (
                        <>
                            <Typography variant={"h6"}>
                                <span className={"font-normal"}>
                                    <Translate id="market.New deal" />
                                </span>
                            </Typography>
                        </>
                    )}
                    <div>
                        {!selectedItem && <FaveButton colored className={"-mr-8"} sourceId={"deal"} title={"deal"} type={"dialog"} />}
                        {/*<IconButton className={'no-drag'} onClick={()=>this.props.minimize("deal")}><Icon>remove</Icon></IconButton>*/}
                        <IconButton className={"no-drag"} onClick={this.closeDialog}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </DialogTitle>

                <Step0 {...stepsProps} />
                {step === 1 && <Step1 {...stepsProps} />}

                <DialogActions className="mt-20">
                    <Button color={"default"} onClick={this.closeDialog}>
                        {<Translate id={"market.cancel"} />}
                    </Button>
                    {step === 1 ? (
                        <>
                            <Button variant={"contained"} disabled={disableSubmit} color="secondary" onClick={this.onSubmit}>
                                <Translate id={"_.save"} />
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button disabled={disableSubmit} onClick={this.onSubmit} color="secondary">
                                <Translate id={"_.quick save"} />
                            </Button>
                            <Button variant={"contained"} disabled={disableSubmit} color="secondary" onClick={() => this.setState({step: 1})}>
                                <Translate id={"_.next"} />
                            </Button>
                        </>
                    )}
                </DialogActions>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: turnOff,
            minimize: minimize,
            turnOn: turnOn,
            startLoading: startLoading,
            finishLoading: finishLoading,
            showMessage: showMessage,
            replaceParams: replaceParams,
            getList: getList,
            selectItem: selectItem,
            getItem: getItem,
        },
        dispatch
    );
}

function mapStateToProps({marketing, fuse, user}) {
    return {
        selectedItem: marketing.deals.list.selected,
        selectedGridListType: marketing.labels.selectedGridListType,
        item: marketing.deals.item,
        search: fuse.search,
        selectedCompany: marketing.companies.item,
        selectedContact: marketing.companyContacts.item,
        user: user.profile,
        dialogs: fuse.dialogs,
    };
}

export default withDialog(
    "deal",
    "sm",
    true
)(withRouter(withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(DealDialog)))));
