import {Translate} from "react-localize-redux";
import type {EnumType} from "types";

// export const flowStates = {
//     REJECT: {label: <Translate id={"process.REJECT"} />, color: "red", value: "REJECT"},
//     PENDING: {label: <Translate id={"process.PENDING"} />, color: "orange", value: "PENDING"},
//     STOP: {label: <Translate id={"process.STOP"} />, color: "black", value: "STOP"},
//     FINISHED: {label: <Translate id={"process.FINISHED"} />, color: "blue", value: "FINISHED"},
//     HIGH: {label: <Translate id={"process.HIGH"} />, color: "red", value: "HIGH"},
//     GENERAL: {label: <Translate id={"process.GENERAL"} />, color: "orange", value: "GENERAL"},
//     LOW: {label: <Translate id={"process.LOW"} />, color: "green", value: "LOW"},
//     APPROVED: {label: <Translate id={"process.APPROVED"} />, color: "green", value: "APPROVED"},
//     RESOLVED: {label: <Translate id={"process.RESOLVED"} />, color: "green", value: "RESOLVED"},
// };

export const taskStates: Record<string, EnumType> = {
    PENDING: {label: <Translate id={"_.PENDING"} />, color: "orange", value: "false"},
    RESOLVED: {label: <Translate id={"_.RESOLVED"} />, color: "green", value: "true"},
};

export const taskPriorities: Record<string, EnumType> = {
    HIGH: {label: <Translate id={"_.process.HIGH"} />, color: "red", value: "HIGH"},
    GENERAL: {label: <Translate id={"_.process.GENERAL"} />, color: "orange", value: "GENERAL"},
    LOW: {label: <Translate id={"_.process.LOW"} />, color: "green", value: "LOW"},
    DAEMON: {label: <Translate id={"_.process.LOWEST"} />, color: "grey", value: "DAEMON"},
};

export const instancesStatuses: Record<string, EnumType> = {
    INPROCESS: {label: <Translate id={"_.INPROCESS"} />, color: "blue", value: "INPROCESS"},
    PAUSE: {label: <Translate id={"_.PAUSE"} />, color: "orange", value: "PAUSE"},
    FAIL: {label: <Translate id={"_.FAIL"} />, color: "red", value: "FAIL"},
    COMPLETED: {label: <Translate id={"_.COMPLETED"} />, color: "green", value: "COMPLETED"},
};

export const QuotationStatuses: Record<string, EnumType> = {
    ready: {label: <Translate id={"_.ready"} />, color: "blue", value: "ready"},
    confirmed: {label: <Translate id={"_.confirmed"} />, color: "green", value: "confirmed"},
    deleted: {label: <Translate id={"_.deleted"} />, color: "red", value: "deleted"},
};

export const BudgetsStatuses: Record<string, EnumType> = {
    available: {label: <Translate id={"_.available"} />, color: "blue", value: "available"},
    inProcess: {label: <Translate id={"_.inProcessbudget"} />, color: "yellow", value: "inProcess"},
    done: {label: <Translate id={"_.done"} />, color: "green", value: "done"},
};
