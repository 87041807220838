import {Icon, IconButton, InputAdornment} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {MyTooltip} from "@ui";
import {useDebounceFn, useMemoizedFn, useMount} from "ahooks";
import {filter, isFunction, keys} from "lodash";
import PropTypes from "prop-types";
import {useEffect, useMemo, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import useStringFunc from "../../app/main/incomes/utils/hooks/useStringFunc";
import {ErrorButton} from "../utilComponents";

ContentToolBarRoot.propTypes = {
    /**
     * @description > 打开或者关闭侧边栏的控制参数
     */
    open: PropTypes.bool.isRequired,
    /**
     * @description > 关闭侧边栏的回调函数
     */
    handleTurnOff: PropTypes.func.isRequired,
    /**
     * @description > 打开侧边栏的回调函数
     */
    handleTurnOn: PropTypes.func.isRequired,
    /**
     * @description > 是否禁用筛选栏，禁用后，不会显示筛选栏
     */
    disableFilter: PropTypes.bool,
    /**
     * @description > 是否有搜索框
     */
    searchable: PropTypes.bool.isRequired,
    /**
     * @description > 搜索的 key
     */
    searchKey: PropTypes.string,
    /**
     * @description > 搜索框的回调函数,仅当 searchable 为 true 时有效
     */
    onSearch: PropTypes.func,

    /**
     * @description > 筛选参数
     */
    query: PropTypes.object.isRequired,
    /**
     * @description > 更新筛选参数的函数
     */
    onChangeQuery: PropTypes.func,
    /**
     * @description > 清空所有筛选参数
     */
    onClearQuery: PropTypes.func,
};

/**
 * @description > 控制展示/关闭侧边筛选栏的 tool bar
 */
function ContentToolBarRoot(props) {
    const {
        open,
        handleTurnOff,
        handleTurnOn,
        disableFilter,
        searchable,
        searchKey,
        onSearch,
        children,
        query,
        onClearQuery,
        onChangeQuery,
    } = props;

    const {trimAll} = useStringFunc();

    const [text, setText] = useState("");


   const compositionRef = useRef(null);

    useMount(() => {
        console.log("ContentToolBarRoot >>> handleTurnOn", handleTurnOn);
        isFunction(handleTurnOn) && handleTurnOn();
    });

    useEffect(() => {
        if (!!disableFilter) {
            open && handleTurnOff();
        }
    }, [open, disableFilter]);

    useEffect(() => {
        if (query && query[`${searchKey}`]) {
            setText(query[`${searchKey}`]);
        }
    }, [query,  query[`${searchKey}`]]);




    const searchDebounce = useDebounceFn(
        () => {
            let params;
            if (text === "") {
                params = null;
            } else {
                const t = trimAll(text);
                params = {
                    text: t
                };
            }
            onSearch(params);
        },
        {
            wait: 500
        }
    );

    const handleClickOpenOrClose = useMemoizedFn((e) => {
        e.stopPropagation();
        if (open) {
            handleTurnOff();
        } else {
            handleTurnOn();
        }
    });

    const handleSearchChange = (e) => {
        console.debug('[handleSearchChange]', e.target.value)
        let userInputValue = e.target.value;
        setText(userInputValue);
        if (!compositionRef.current) {
            searchDebounce.run();
        }
    };

    const handleClearSearch = useMemoizedFn((e) => {
        let tempQ = query;
        delete tempQ[`${searchKey}`];
        tempQ = {...tempQ,page:1};
        setText("");
        onSearch(tempQ);
        onChangeQuery(tempQ).finally(() => {});
    });

    const handleClearQuery = useMemoizedFn((e) => {
        e.stopPropagation();
        onClearQuery();
    });

    const showClearQueryButton = useMemo(() => {
        const queryKeys = keys(query);
        const filted = filter(queryKeys, (key) => {
            return key !== "page" && key !== "size" && key !== "keyword" && key !== "name" && key !== "lastest";
        });
        return filted.length > 0;
    }, [query]);

    const handleComposition = (event) => {
        // console.debug("handleComposition", event.type)
        if (event.type === "compositionstart") {
            console.debug('compositionstart');
            compositionRef.current = true;
        } else if (event.type === 'compositionend') {
            console.debug('compositionend');
            compositionRef.current = false;
            if (navigator.userAgent.indexOf('Chrome') > -1) {
               handleSearchChange(event);
            }
        }
    }

    return (
        <div
            className={
                "my-12 md:my-20 px-20 sm:flex sm:flex-col  md:flex md:flex-row md:justify-between w-full md:items-center"
            }
        >
            <div className={"flex md:flex-row  md:items-center sm:flex-col "}>
                <div className={"flex flex-row items-center"}>
                    <Button
                        id={"filter-button"}
                        disableRipple
                        variant={"contained"}
                        className={"bg-white mr-12"}
                        disabled={!!disableFilter ? disableFilter : false}
                        onClick={handleClickOpenOrClose}
                        startIcon={<Icon>{open ? "arrow_back" : "filter_alt"}</Icon>}
                    >
                        {open ? <Translate id={"_.Close Filters"} /> : <Translate id={"_.open Filters"} />}
                    </Button>
                    {searchable && (
                        <>
                            <Translate>
                                {({translate}) => (
                                    <TextField
                                        id={"searchBar"}
                                        className="flex flex-1  w-full md:max-w-256 mt-0 bg-white text-darker rounded-8 border px-2 "
                                        placeholder={translate("_.search")}
                                        fullWidth
                                        value={text}
                                        size={"small"}
                                        onChange={handleSearchChange}
                                        onCompositionStart={handleComposition}
                                        onCompositionUpdate={handleComposition}
                                        onCompositionEnd={handleComposition}
                                        InputProps={{
                                            endAdornment:
                                                text &&
                                                trimAll(text) !== "" && (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClearSearch}>
                                                            <Icon color={"primary"} fontSize={"small"}>
                                                                close
                                                            </Icon>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <Icon fontSize={"small"}>search</Icon>
                                                </InputAdornment>

                                        }}
                                        variant="outlined"
                                    />
                                )}
                            </Translate>
                        </>
                    )}
                </div>
                <div className={"flex  md:flex-row md:items-center md:my-0 sm:my-10"}>
                    {showClearQueryButton && children}
                </div>
            </div>

            <div className={"flex items-center md:my-0 sm:my-10"}>
                {showClearQueryButton && (
                    <MyTooltip title={<Translate id={"_.Clear All"} />}>
                        <ErrorButton
                            variant={"contained"}
                            size={"small"}
                            disabled={!!disableFilter ? disableFilter : false}
                            startIcon={<Icon>close</Icon>}
                            onClick={handleClearQuery}
                        >
                            {<Translate id={"_.Clear All filters"} />}
                        </ErrorButton>
                    </MyTooltip>
                )}
            </div>
        </div>
    );
}

export default ContentToolBarRoot;
