import {IconButton} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import {SubmitButton} from "@ui";
import type {FC} from "react";
import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import DGColForm from "../../header/DGColForm";
import {useDGDef} from "../../hooks/hooks";
import type {DGColType} from "../../types";
import TH from "./TH";

const THList: FC = () => {
    const {t} = useTranslation(["datagrid", "_"]);
    const {permission, isOwner, cols, onAddCol, hiddenCols} = useDGDef();
    const [labelError, setLabelError] = useState<string>();

    // const {checked, toggleAll} = useDGSelection();
    const [newColEl, setNewColEl] = useState<HTMLButtonElement | null>(null);

    const [tempCol, setTempCol] = useState<Omit<DGColType, "id">>({
        label: "",
        viewType: "text",
    });

    const uniqueLabel = (label: string | undefined) => {
        return cols.some((x) => !x.deleted && x.label === label);
    };

    function onSaveCol() {
        if (uniqueLabel(tempCol.label)) {
            setLabelError(t("uniqueLabel"));
            return;
        }
        setLabelError(undefined);
        if (tempCol.viewType === "singleOption") {
            const t = {...tempCol};
            t.options = tempCol.options?.filter((c) => c !== "");
            onAddCol(t);
        } else {
            onAddCol(tempCol);
        }

        setTempCol({
            label: "",
            viewType: "text",
        });

        setNewColEl(null);
    }

    return (
        <div className={"flex w-fit sticky top-0 bg-white  border-b-2 border-gray-300 z-9999"}>
            <div className="sticky z-9999 left-0 flex bg-white">
                {((permission !== "readonly" && permission !== "disabled") || isOwner) && <div className="w-28 border-r-1 border-gray-200" />}
                {/* region checkbox */}

                <div className="min-w-32 w-32 content-center items-center flex z-9999  bg-white border-r-1 border-gray-200">
                    {/*<Checkbox*/}
                    {/*    className={"px-2  py-1 "}*/}
                    {/*    size={"small"}*/}
                    {/*    indeterminate={rows && checked.size > 0 && checked.size < rows?.length}*/}
                    {/*    onChange={(e) => toggleAll(e.target.checked)}*/}
                    {/*/>*/}
                </div>

                {/* endregion */}
            </div>

            {/* region cols */}
            {cols.map((col) => {
                return (
                    !hiddenCols?.has(col.id) &&
                    !col.deleted && (
                        <Fragment key={col.id}>
                            <TH col={col} />
                        </Fragment>
                    )
                );
            })}

            {/* endregion */}
            {/* region add new */}
            {(permission === "manage" || isOwner) && (
                <>
                    <div className={"border-r border-gray-200 border-solid"}>
                        <IconButton
                            size={"small"}
                            onClick={(event) => {
                                setNewColEl(event.currentTarget);
                            }}>
                            <Icon fontSize={"small"}>add</Icon>
                        </IconButton>
                    </div>

                    <Popover onClose={() => setNewColEl(null)} open={Boolean(newColEl)} anchorEl={newColEl}>
                        <DGColForm errors={{label: labelError}} tempCol={tempCol} setTempCol={setTempCol} />

                        <div className={"px-12 py-12 mb-4 flex justify-end"}>
                            <SubmitButton disabled={tempCol.label?.trim() === ""} success size={"small"} className={"ml-8"} onClick={onSaveCol}>
                                {t("save")}
                            </SubmitButton>
                        </div>
                    </Popover>
                </>
            )}
            {/* endregion */}
        </div>
    );
};

export default THList;
