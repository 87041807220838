import {setCache, turnOff, turnOn} from "@fuseActions";
// @ts-ignore
import type Socket from "@types/socket.io-client";
import Notify from "notifyjs";
import {createContext, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import io from "socket.io-client";
import {markRead, receiveNotification, seeNotification} from "../app/notifications/store/actions";
import {baseChatURL, version} from "../ENV.VARIABLE"; // Import the types

// Define interface for events received from the server
interface ServerToClientEvents {
    notificationSeen: (id: string) => void;
    // Add specific event names and types here (e.g., 'my-event': (data: string) => void)
}

// Define interface for events emitted to the server
interface ClientToServerEvents {
    notificationSeen: (id: string) => void;
    // Add specific event names and types here (e.g., 'my-event': (data: string) => void)
}

// create socket context
export const SocketContext = createContext<{
    socket: Socket.Socket<ServerToClientEvents, ClientToServerEvents> | null;
    isConnected: boolean;
    emitEvent: (eventName: keyof ClientToServerEvents, data: any) => void;
    on: (eventName: keyof ServerToClientEvents, callback: (data: any) => void) => void;
}>({
    socket: null,
    isConnected: false,
    emitEvent: () => {},
    on: () => {},
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({children}: {children: ReactNode}) => {
    const dispatch = useDispatch() as (action: any) => void;
    const {i18n} = useTranslation();
    const [socket, setSocket] = useState<Socket.Socket<ServerToClientEvents, ClientToServerEvents> | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const eventListeners = useRef<Array<(...args: any[]) => void>>([]); // Store event listeners for cleanup

    useEffect(() => {
        if (socket) {
            socket.disconnect();
            eventListeners.current.forEach((listener) => socket.off(listener));
        }

        const newSocket = io(baseChatURL, {
            transports: ["websocket"],
            query: `device=desktop&language=${i18n.language}&version=${version}`,
        });
        setSocket(newSocket);

        const parameter = {
            token: localStorage.getItem("token"),
            language: localStorage.getItem("i18nextLng"),
        };

        newSocket.emit("checkToken", parameter);

        const handleConnect = () => setIsConnected(true);
        const handleDisconnect = () => setIsConnected(false);
        const handleNotification = (notification) => {
            dispatch(receiveNotification(notification));
            if (window.Notification) {
                const myNotification = new Notify(notification.title, {
                    body: notification.detail,
                });
                myNotification.show();
            }
        };

        newSocket.on("connect", handleConnect);
        newSocket.on("disconnect", handleDisconnect);
        newSocket.on("notification", handleNotification);
        newSocket.on("notificationSeen", (data) => {
            if (data.id) {
                dispatch(seeNotification(data.id));
            } else if (data.isSeen) {
                dispatch(markRead());
            }
        });
        newSocket.on("signature", (data) => {
            dispatch(turnOff("twoFactor"));
            dispatch(turnOn("shouldRetry"));
            dispatch(setCache("headerSignature", data));
        });

        eventListeners.current.push(handleConnect, handleDisconnect);

        // return () => {
        //     newSocket.disconnect();
        //     eventListeners.current.forEach((listener) => newSocket.off(listener));
        // };
    }, [i18n.language]);

    const emitEvent = (eventName: keyof ClientToServerEvents, data: any) => {
        if (socket) {
            socket.emit(eventName, data);
        }
    };

    const on = (eventName: keyof ServerToClientEvents, callback: (data: any) => void) => {
        if (socket) {
            socket.on(eventName, callback);
            eventListeners.current.push(() => socket.off(eventName, callback)); // Add listener to cleanup
        }
    };

    return <SocketContext.Provider value={{socket, isConnected, emitEvent, on}}>{children}</SocketContext.Provider>;
};
