import {Icon, ListItemText, Menu, MenuItem, Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import {MyAvatar, MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import _ from "lodash";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import BadmintonIcon from "../../../../../IconFontVendor/badminton-svgrepo-com.svg";
import BasketballIcon from "../../../../../IconFontVendor/basketball-svgrepo-com.svg";
import BowlingIcon from "../../../../../IconFontVendor/bowling-svgrepo-com.svg";
import RVCampingIcon from "../../../../../IconFontVendor/caravan-camping-svgrepo-com.svg";
import ChessIcon from "../../../../../IconFontVendor/chess-svgrepo-com.svg";
import CofeIcon from "../../../../../IconFontVendor/coffee-machine-svgrepo-com.svg";
import ConcertIcon from "../../../../../IconFontVendor/concert-svgrepo-com.svg";
import EmailSvgIcon from "../../../../../IconFontVendor/email-chat-svgrepo-com.svg";
import FeetMassageIcon from "../../../../../IconFontVendor/feet-svgrepo-com .svg";
import FishingIcon from "../../../../../IconFontVendor/fishing-pole-svgrepo-com.svg";
import FootballIcon from "../../../../../IconFontVendor/football-svgrepo-com.svg";
import PlayGameIcon from "../../../../../IconFontVendor/game-console-svgrepo-com.svg";
import GolfIcon from "../../../../../IconFontVendor/golf-svgrepo-com.svg";
import ShakehnadleOriginalIcon from "../../../../../IconFontVendor/handshake-deal-svgrepo-com.svg";
import HikingIcon from "../../../../../IconFontVendor/hiking-svgrepo-com.svg";
import VisitCustomerSVGIcon from "../../../../../IconFontVendor/hurry.svg";
import InternetChatSVGIcon from "../../../../../IconFontVendor/internet-chat.svg";
import KTVIcon from "../../../../../IconFontVendor/karaoke-sing-svgrepo-com.svg";
import MaJiangIcon from "../../../../../IconFontVendor/mahjong-red-dragon-svgrepo-com.svg";
import BodyMassageIcon from "../../../../../IconFontVendor/massage-session-svgrepo-com.svg";
import MeetingSVGIcon from "../../../../../IconFontVendor/meeting-svgrepo-com.svg";
import PartyIcon from "../../../../../IconFontVendor/party-popper-svgrepo-com.svg";
import ClimbingIcon from "../../../../../IconFontVendor/person-climbing-svgrepo-com.svg";
import ShampooIcon from "../../../../../IconFontVendor/person-getting-massage-svgrepo-com.svg";
import PhotographyIcon from "../../../../../IconFontVendor/photography-trophy-svgrepo-com.svg";
import PianoIcon from "../../../../../IconFontVendor/piano-svgrepo-com.svg";
import PokerIcon from "../../../../../IconFontVendor/poker-cards-svgrepo-com.svg";
import SaunaIcon from "../../../../../IconFontVendor/sauna-svgrepo-com.svg";
import ShoppingIcon from "../../../../../IconFontVendor/shopping-bag-svgrepo-com.svg";
import SkiIcon from "../../../../../IconFontVendor/ski-svgrepo-com.svg";
import SpaIcon from "../../../../../IconFontVendor/spa-svgrepo-com.svg";
import StageIcon from "../../../../../IconFontVendor/stage-svgrepo-com.svg";
import SwimmingIcon from "../../../../../IconFontVendor/swimming-swim-svgrepo-com.svg";
import TeaIcon from "../../../../../IconFontVendor/tea-svgrepo-com.svg";
import CallSvgIcon from "../../../../../IconFontVendor/telephone.svg";
import TennisIcon from "../../../../../IconFontVendor/tennis-svgrepo-com.svg";
import CampingIcon from "../../../../../IconFontVendor/tent-camping-svgrepo-com.svg";
import CuSTOMERcOMEiNsvgIcon from "../../../../../IconFontVendor/train-locomotive-svgrepo-com.svg";
import CinemaIcon from "../../../../../IconFontVendor/video-camera-cinema-svgrepo-com.svg";
import WineIcon from "../../../../../IconFontVendor/wine-svgrepo-com.svg";
import axios, {crmPrefix} from "../../../../../myaxios";
import * as companyActions from "../../companies/store/actions";
import ItemHeader from "../../utils/ItemHeader";
import * as Actions from "../store/actions";
import {getTheIconByName} from "../VendorIcons/VendorIcons";

const styles = (theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    imageIcon: {
        display: "flex",
        height: "inherit",
        width: "inherit"
    },
    iconRoot: {
        textAlign: "center"
    }
});

const types = [
    {GeneralMeeting: ShakehnadleOriginalIcon},
    {Calling: CallSvgIcon},
    {PlayMaJiang: MaJiangIcon},
    {PlayPoker: PokerIcon},
    {WineBureau: WineIcon},
    {KTV: KTVIcon},
    {Fishing: FishingIcon},
    {PlayChess: ChessIcon},
    {Swimming: SwimmingIcon},
    {DrinkCofe: CofeIcon},
    {DrinkTea: TeaIcon},
    {PlayGame: PlayGameIcon},
    {Photography: PhotographyIcon},
    {Concert: ConcertIcon},
    {WatchMovie: CinemaIcon},
    {WatchStage: StageIcon},
    {Spa: SpaIcon},
    {Shopping: ShoppingIcon},
    {BodyMassage: BodyMassageIcon},
    {FeetMassage: FeetMassageIcon},
    {WashHeadMassage: ShampooIcon},
    {SaunaMassage: SaunaIcon},
    {PlayBadminton: BadmintonIcon},
    {PlayBasketball: BasketballIcon},
    {PlayFootball: FootballIcon},
    {PlayBowlingball: BowlingIcon},
    {PlayGolfball: GolfIcon},
    {PlayTennis: TennisIcon},
    {Climbing: ClimbingIcon},
    {Party: PartyIcon},
    {WatchPioneShow: PianoIcon},
    {Hiking: HikingIcon},
    {Ski: SkiIcon},
    {RVCamping: RVCampingIcon},
    {Camping: CampingIcon},
    {call: CallSvgIcon},
    {internetChat: InternetChatSVGIcon},
    {visitingCustomers: VisitCustomerSVGIcon},
    {customerVisit: CuSTOMERcOMEiNsvgIcon},
    {meeting: MeetingSVGIcon},
    {Email: EmailSvgIcon}
];

const RedColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700]
        }
    }
}))(Button);

const GreenColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    }
}))(Button);

class Header extends Component {
    state = {
        typeAnchorEl: null,
        checkbox: false,
        itemChangesCount: 0,
        user: "",
        userId: "",
        checkAnchorEl: null,
        openReminderDate: false,
        editCompany: false
    };

    checkboxHandler = (e) => {
        e.stopPropagation();
        const {sourceItem} = this.props;
        const search = this.props.search;
        const data = {
            activityId: sourceItem.id,
            status: sourceItem.status === "open" ? "close" : "open"
        };
        axios.put(`${crmPrefix}/activitySetStatus`, data).then(() => {
            this.props.refresh();
            this.props.getList(search);
            this.props.showMessage({
                variant: "success",
                message: "message.Activity status changed!"
            });
        });
    };

    closeDialog = () => {
        this.props.turnOff("activityDetail");
    };

    handleTypeClick = (event) => {
        // setAnchorEl(event.currentTarget);
        this.setState({
            typeAnchorEl: event.currentTarget
        });
    };

    handleTypeClose = () => {
        // setAnchorEl(null);
        this.setState({
            typeAnchorEl: null
        });
    };

    changeType = (type) => {
        const {sourceItem, search} = this.props;
        const params = {
            id: sourceItem.id,
            type: type
        };

        axios
        .put(`${crmPrefix}/activity`, {...params})
        .then(() => {
            // this.props.getActivity(this.props.sourceItem.id);
            this.props.showMessage({
                message: "message.Activity type changed!",
                variant: "success"
            });
            this.props.refresh();
            this.props.getList(search);
            this.handleTypeClose();
        })
        .catch(() => {
            this.handleTypeClose();
        });
    };

    render() {
        const {sourceItem, classes, canEdit} = this.props;

        const {typeAnchorEl} = this.state;

        return (
            <>
                <ItemHeader
                    {...this.props}
                    title={
                        <>
                            <MyTooltip
                                title={
                                    _.isObject(sourceItem.type) ? (
                                        <Translate id={`market.${sourceItem.type.key}`} />
                                    ) : (
                                        <Translate id={"market.click to set type"} />
                                    )
                                }
                            >
                                <span>
                                    <button disabled={!canEdit} aria-controls="long-menu-type" aria-haspopup="true" onClick={this.handleTypeClick}>
                                        <div className={"flex flex-row items-center"}>
                                            <MyAvatar src={getTheIconByName(sourceItem.type.key)} size={48} alt={sourceItem.lastName} />
                                            {canEdit && <Icon fontSize={"small"}>edit</Icon>}
                                        </div>
                                    </button>
                                </span>
                            </MyTooltip>
                            {canEdit && (
                                <Menu
                                    id="long-menu-type"
                                    anchorEl={typeAnchorEl}
                                    open={Boolean(typeAnchorEl)}
                                    onClose={this.handleTypeClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: "20ch"
                                        }
                                    }}
                                >
                                    {types.map((temp) => {
                                        const option = Object.keys(temp)[0];
                                        const TargetIcon = Object.values(temp)[0];
                                        return (
                                            <MenuItem
                                                key={option}
                                                selected={option === sourceItem.type.key}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.changeType(option);
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <div className={"flex flex-row items-center justify-center"}>
                                                            <Icon classes={{root: "items-center"}}>
                                                                <img className={classes.imageIcon} src={TargetIcon} />
                                                            </Icon>
                                                            <Typography className={"ml-3"} variant={"caption"}>
                                                                <Translate id={`market.${option}`} />
                                                            </Typography>
                                                        </div>
                                                    }
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            )}
                            <div className={"flex flex-col ml-12"}>
                                <Typography variant={"h5"} className={classNames("flex mb-2 items-center")}>
                                    <span className={"font-bold"}>
                                        <strong>{sourceItem.title}</strong>
                                    </span>
                                </Typography>
                            </div>
                        </>
                    }
                    actions={
                        <Translate>
                            {({translate}) => (
                                <MyTooltip title={translate(`market.Status ${sourceItem.status}`)}>
                                    {sourceItem.status === "open" ? (
                                        <GreenColorButton
                                            disabled={!canEdit}
                                            variant={"contained"}
                                            className={"text-white"}
                                            onClick={this.checkboxHandler}
                                        >
                                            {translate("market.CLOSE")}
                                        </GreenColorButton>
                                    ) : (
                                        <RedColorButton
                                            variant={"contained"}
                                            disabled={!canEdit}
                                            className={"mr-4 text-white"}
                                            onClick={this.checkboxHandler}
                                        >
                                            {translate("market.OPEN")}
                                        </RedColorButton>
                                    )}
                                </MyTooltip>
                            )}
                        </Translate>
                    }
                />
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: fuseActions.turnOn,
            turnOff: fuseActions.turnOff,
            showMessage: fuseActions.showMessage,
            selectCompany: companyActions.selectItem,
            getList: Actions.getList,
            selectActivity: Actions.selectItem
        },
        dispatch
    );
}

function mapStateToProps({fuse, marketing}) {
    return {
        search: fuse.search,
        item: marketing.activities.item
    };
}

export default withRouter(withStyles(styles, {withTheme: true})(withLocalize(connect(mapStateToProps, mapDispatchToProps)(Header))));
