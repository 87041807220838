import type {EntityTable} from "dexie";
import {Dexie} from "dexie";
import type {DGSettingsType} from "./hooks/hooks";
import type {DGRowType} from "./types";

const db = new Dexie("srx") as Dexie & {
    // dataGridCells: EntityTable<CellType, "id">;
    dataGrids: EntityTable<DGSettingsType, "id">;
    dataGridRows: EntityTable<DGRowType, "id">;
};

db.version(1).stores({
    dataGrids: "&id",
    // dataGridCells: "++id, rowId,  colId",
    dataGridRows:
        "&id, dataGridId, activeTime, c0, c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13, c14, c15, c16, c17, c18, c19, c20, c21, c22, c23, c24, c25, c26, c27,c28,c29,c30, c31, c32,c33,c34,c35,c36,c37,c38,c39,c40,c41,c42,c43,c44,c45,c46,c47,c48,c49,c50,c51,c52,c53,c54,c55,c56,c57,c58,c59,c60,c61,c62,c63,c64,c65,c66,c67,c68,c69,c70,c71,c72,c73,c74,c75,c76,c77,c78,c79,c80,c81,c82,c83,c84,c85,c86,c87,c88,c89,c90,c91,c92,c93,c94,c95,c96,c97,c98,c99",
});

export {db};
