import type {ReactNode} from "react";
import {Typography} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";

import {useSelector} from "react-redux";

type Props = {
    children: ReactNode;
    title: ReactNode;
    remove(from: string, type: string): void;
    type: string;
    removeFrom: string;
};

const WidgetWrapper = ({children, title, remove, removeFrom, type}: Props) => {
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    return (
        <div className="flex-grow mb-16">
            {dialogs["editDashboard"] && (
                <div className={classNames("flex h-48 justify-between")}>
                    <Typography variant={"subtitle1"} className="font-bold pt-12 pl-12">
                        {title}
                    </Typography>
                    <div>
                        <IconButton onClick={() => remove(removeFrom, type)}>
                            <Icon color={"error"}>delete</Icon>
                        </IconButton>
                    </div>
                </div>
            )}
            <div className="flex justify-center">
                <div className={"w-full"}>{children}</div>
            </div>
        </div>
    );
};

export default WidgetWrapper;
