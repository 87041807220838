/**
 *  ----------------------------------------------------------------
 *  @classdesc MUI Dialog 的全局注册表
 *  ----------------------------------------------------------------
 */

/**
 * @description - 回款模块的 dialog  注册表
 */
const IncomeModuleRegistries = {
    noHandlerRevenuesDialog: "[NewNoHandlerRevenuesDialog] NewNoHandlerRevenuesDialog",
    setIncomeUser: "[IncomeSureSetIncomeUserDialog] IncomeSureSetIncomeUserDialog",
    setIncomeTag: "[IncomeSureSetTagDialog] IncomeSureSetTagDialog",
    setIncomeType: "[IncomeSureTypeDialog] IncomeSureTypeDialog",
    incomeDetails: "[IncomeDetailDialog] IncomeDetailDialog",
    orderDistribute: "[NewOrderDistributeDialog] NewOrderDistributeDialog",
    refundAmount: "[NewRefundDialog] NewRefundDialog",
    changeUserMyIncome: "[MyIncomeChangeUserDialog] > MyIncomeChangeUserDialog",
    definitionsInCategory: "[NewDefinitionsInCategoryDialog] > NewDefinitionsInCategoryDialog",
    downloadXLS: "[NewDownloadXLSDialog] > NewDownloadXLSDialog",
    matchingOrder: "[NewMatchingOrderDialog] > NewMatchingOrderDialog",
    selectedMatchingDistribute: "[NewSelectedMatchingDistributeDialog] > NewSelectedMatchingDistributeDialog",
};

const LiXiangExcelModelRegistries = {
    lxExcelStartup: "[Dialog] > LXExcelStartupDialog",
};

const CommercialModuleRegistries = {
    sheetPreViewDialog: "[Commercial-User payments] > SheetPreViewDialog",
};

/**
 * @typedef {(string)} GDialogRegistries  MUI Dialog 全局注册表类
 */

/**
 * @classdesc -  GDialogRegistries - MUI Dialog 全局注册表类
 */
export const GDialogRegistries = {
    ...IncomeModuleRegistries,
    ...LiXiangExcelModelRegistries,
    ...CommercialModuleRegistries,
    /**
     * @description - ClickWrongElememtTips , 重新设置开始取消的节点时，在流程图上点击了非 userTask 的节点的提示 dialog 的 tag
     */
    clickWrongElementTip: "ClickWrongElememtTips",
    /**
     * @description - DeleteCancelActivityTips , 从已经设置的取消节点中移除节点的提示 dialog 的 tag
     */
    deleteCancelActivityTips: "DeleteCancelActivityTips",
    /**
     * @description - SettingVariablesDialog , 流程实例详情中关于设置的取消或者开始活动的变量设置的dialog 的tag
     */
    setVariableForActivity: "[start-cancel-activity-settings]-SettingVariablesDialog",
    /**
     * @description - UserSelectorForVariableDialog , 流程实例重设的开始节点的设置 用户变量
     */
    userSelectorForVariableInInstance: "[variable in start activity]UserSelectorForVariableDialog",
    /**
     * @description - InstanceMigration , 流程实例中，流程实例迁移的 Form 表单的 dialog
     */
    instanceMigration: "[Instance-Details]InstanceMigration",

    /**
     * @description - DefinitionSuspendDialog , 流程定义中，暂停或者激活流程定义的 dialog
     */
    definitionSuspend: "[Definitions]DefinitionSuspendDialog",

    /**
     * @description - DefinitionEditorDialog , 流程定义中，编辑流程定义的 dialog
     */
    definitionEditor: "[Definitions]DefinitionEditorDialog",

    /**
     * @description - NewTransferForInstanceDialog , 流程实例中，改签的 dialog
     */
    transferForInstance: "[NewTransferForInstanceDialog] NewTransferForInstanceDialog",

    /**
     * @description - NewBatchDeleteInstanceDialog , 删除流程实例时，提示的 dialog
     */
    batchDeleteInstance: "[NewBatchDeleteInstanceDialog] > NewBatchDeleteInstanceDialog",
    /**
     * @description - NewDeleteInstanceTip , 删除流程实例时，提示的 dialog
     */
    singleDeleteInstance: "[NewDeleteInstanceTip] > NewDeleteInstanceTip",
    /**
     * @description - NewChangeUserForUserTaskDialog , 用户任务模块中的改签 dialog
     */
    changeUserForUserTask: "[NewChangeUserForUserTaskDialog] > NewChangeUserForUserTaskDialog",
    /**
     * @description - NewCreateInstanceDialog , 流程详情中新建 instance 的 dialog
     */
    createInstance: "[NewCreateInstanceDialog] > NewCreateInstanceDialog",
    /**
     * @description - NewPauseDefinitionDialog , 流程详情中暂停 definition 的 dialog
     */
    pauseDefinitionTips: "[NewPauseDefinitionDialog] > NewPauseDefinitionDialog",
    /**
     * @description - NewEditDefinitionDialog , 流程详情中编辑 definition 的 dialog
     */
    editDefinitionDialog: "[NewEditDefinitionDialog] > NewEditDefinitionDialog",
    /**
     * @description - NewUserDialog , 流程详情中 新建/编辑 user 变量 的 dialog
     */
    definitionUserDialog: "[NewDefinitionUserDialog] > NewUserDialog",
    /**
     * @description - NewDeleteLastVarTipsDialog , 流程详情中，当删除最后一个 user 变量提示 的 dialog
     */
    deleteLastVarTipsDialog: "[NewDeleteLastVarTipsDialog] > NewDeleteLastVarTipsDialog",
    /**
     * @description - NewDeleteLastVarTipsDialog , 流程实例详情中，同步流程实例的 tips dialog
     */
    syncInstanceTipsDialog: "[NewSyncTipDialog] > NewSyncTipDialog",
    /**
     * @description - NewEditInstanceVarialbleDialog , 流程实例详情中/用户任务详情中，编辑或者新建变量的 dialog
     */
    instanceUserTaskVarDialog: "[NewInstanceUserTaskVarDialog] > NewInstanceUserTaskVarDialog",
    /**
     * @description - NewDeleteVariableTipDialog , 流程实例详情中/用户任务详情中，删除变量的提示 dialog
     */
    deleteVariableTipsDialog: "[NewDeleteVariableTipDialog] > NewDeleteVariableTipDialog",
    /**
     * @description - EditProcessCategoryDialog , 流程分类列表中，编辑分类的 dialog
     */
    editProcessCategoryDialog: "[EditProcessCategoryDialog] > EditProcessCategoryDialog",
    /**
     * @description - DefinitionSetCategoryDialog , 流程分类列表中，编辑分类的 dialog
     */
    definitionSetCategory: "[DefinitionSetCategoryDialog] > DefinitionSetCategoryDialog",
    /**
     * @description - tourLedger , 成品台账的名字解释 dialog
     */
    tourLedger: "[Ledger] > tourLedger",
};

/**
 * @description - 回款模块的筛选栏  注册表
 */
const IncomeModuleFilters = {
    income_sure_filter: "[IncomeSure] > income_sure_filter",
    my_income_filter: "[MyIncomes] > my_income_filter",
};

/**
 * @typedef {(string)} GFilterRegistries  所有列表的侧边筛选栏 全局注册表类
 */
export const GFilterRegistries = {
    ...IncomeModuleFilters,
    /**
     * @description - def_list_manager , 流程管理 > 流程定义列表筛选栏的 tag
     */
    def_list_filter_manager: "def_list_manager",
    /**
     * @description - ins_list_filter_manager , 流程管理 > 流程实例列表筛选栏的 tag
     */
    ins_list_filter_manager: "ins_list_filter_manager",
    /**
     * @description - deci_list_filter_manager , 流程管理 > 决策列表筛选栏的 tag
     */
    deci_list_filter_manager: "deci_list_filter_manager",
    /**
     * @description - task_list_filter_manager , 流程管理 > 用户任务列表筛选栏的 tag
     */
    task_list_filter_manager: "task_list_filter_manager",
    /**
     * @description - categories_list_filter_manager , 流程管理 > 流程分类列表筛选栏的 tag
     */
    categories_list_filter_manager: "categories_list_filter_manager",
    /**
     * @description - incomeOrders , 流程管理 > 回款分配记录
     */
    incomeOrders: "incomeOrders_filter_sidebar",
};
