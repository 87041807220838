import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_USERTASK_SINGLE = "[GET_USERTASK_SINGLE]";
export const RESET_USERTASK_SINGLE = "[RESET_USERTASK_SINGLE]";
export const GET_ACTIVITIES = "[GET_ACTIVITIES]";
export const GET_VARIABLES_FOR_USERTASK_SINGLE = "[GET_VARIABLES_FOR_USERTASK_SINGLE]";
export const SELECT_A_VARIABLE_FOR_USERTASK = "[SELECT_A_VARIABLE_FOR_USERTASK]";

export function getUserTaskSingle(id, callback, catchError) {
    const request = axios.get(`${flowPrefix}/task/${id}`);
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_USERTASK_SINGLE,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}

export function getVariablesForUserTask(id, callback, catchError) {

    const request = axios.get(`${flowPrefix}/task/${id}/variables`);
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_VARIABLES_FOR_USERTASK_SINGLE,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}


export function resetUserTaskSingle() {
    return {
        type: RESET_USERTASK_SINGLE
    };
}



