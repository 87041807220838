import {useMemo, useState} from "react";


function usePageSimpleClasses() {

    const [ defaultClasses,setClasses] = useState({
        contentWrapper: "p-0 sm:p-24 pb-80 sm:pb-80 h-full",
        content       : "flex flex-row",
        toolbar       : "flex flex-1 px-16 sm:px-24",
        leftSidebar   : "w-256 border-0",
        header        : "min-h-72 h-72 sm:h-136 sm:min-h-136"
    });

    const actions = useMemo(()=>{
        const reSetClasses = ({classes}) => {
           setClasses({
               ...defaultClasses,
               ...classes
           });
        };
        return { reSetClasses };
    },[defaultClasses]);

    return [defaultClasses,actions];
}

export default usePageSimpleClasses;
