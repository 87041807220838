import {Icon, IconButton, TablePagination, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {MyTooltip} from "@ui";
import _ from "lodash";
import type {ChangeEvent} from "react";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import type {SearchType} from "types";
import {rowsPerPage} from "variables";
import {usePersonalized} from "../../hooks/usePersonalized";

type Props = {
    dense?: boolean;
    size: number;
    page?: number;
    totalPages: number;
    totalElements: number;
    filterData: (arg0: SearchType) => void;
};

export const InsidePagination = (props: Props) => {
    const {filterData, totalElements, totalPages, size, page, dense} = props;

    const {updateSettings} = usePersonalized();

    const [tempPage, setTempPage] = useState<string>("1");

    useEffect(() => {
        if (page && page !== _.toNumber(tempPage)) {
            setTempPage(String(page));
        }
    }, [page]);

    const onPageChange = (event: any, page: number) => {
        filterData({size: props.size, page: _.toNumber(page) + 1});
    };

    const onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateSettings({rowPerPage: event.target.value});
        // localStorage.setItem("rowPerPage", event.target.value);
        filterData({size: event.target.value});
    };

    const submitAble = _.toNumber(page) > totalPages || _.toNumber(page) === 0 || totalPages < 2;

    if (totalElements === 0) return <></>;
    return (
        <div className={dense ? "" : "md:flex items-center justify-end"}>
            <div className="justify-end items-center md:pt-0 pt-12 pr-16 flex ">
                <Typography>{<Translate id={"_.Page"} />}:</Typography>

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        filterData({page: _.toNumber(tempPage)});
                    }}>
                    <TextField
                        size={"small"}
                        disabled={totalPages < 2}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment classes={{root: "ml-0"}} position="end">
                                    <MyTooltip title={<Translate id={"_.Jump to"} />}>
                                        <span>
                                            <IconButton
                                                size={"small"}
                                                disabled={submitAble}
                                                aria-label="toggle password visibility"
                                                onClick={() => filterData({page: _.toNumber(tempPage)})}>
                                                <Icon>fast_forward</Icon>
                                            </IconButton>
                                        </span>
                                    </MyTooltip>
                                </InputAdornment>
                            ),
                        }}
                        type={"number"}
                        className={"w-72 mx-2 pl-2 rounded-4 border-1 border-gray-200 border-solid "}
                        value={tempPage}
                        onChange={(e) => setTempPage(e.target.value)}
                    />
                </form>

                <Typography>
                    <Translate id={"_.totalPages"} />: &nbsp;
                    <span className={"text-20 align-middle"}>{totalPages}</span>
                </Typography>
            </div>
            {/*{!!hiddenPageElement ? (*/}
            {/*    <Translate>*/}
            {/*        {({translate}: any) => (*/}
            {/*            <TablePagination*/}
            {/*                rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 1000, 10000]}*/}
            {/*                classes={{toolbar: "pl-2"}}*/}
            {/*                component="div"*/}
            {/*                data-cy={"pagination"}*/}
            {/*                count={totalElements}*/}
            {/*                rowsPerPage={_.toNumber(size)}*/}
            {/*                page={_.toNumber(page) - 1}*/}
            {/*                labelDisplayedRows={({from, to, count}) => {*/}
            {/*                    return translate("_.display rows with short params", {*/}
            {/*                        from,*/}
            {/*                        to,*/}
            {/*                        count*/}
            {/*                    });*/}
            {/*                }}*/}
            {/*                size={"small"}*/}
            {/*                labelRowsPerPage={<></>}*/}
            {/*                backIconButtonProps={{*/}
            {/*                    "aria-label": translate("_.previous")*/}
            {/*                }}*/}
            {/*                nextIconButtonProps={{*/}
            {/*                    "aria-label": translate("_.next")*/}
            {/*                }}*/}
            {/*                onPageChange={onPageChange}*/}
            {/*                onRowsPerPageChange={onRowsPerPageChange}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    </Translate>*/}
            {/*) : (*/}
            <Translate>
                {({translate}: any) => (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 1000, 10000]}
                        classes={{toolbar: "pl-2"}}
                        component="div"
                        data-cy={"pagination"}
                        count={totalElements}
                        rowsPerPage={_.toNumber(size)}
                        page={_.toNumber(page) - 1}
                        labelDisplayedRows={({from, to, count}) => {
                            return translate("_.display rows with params", {from, to, count});
                        }}
                        size={"medium"}
                        labelRowsPerPage={<Translate id={"_.rowsPerPage"} />}
                        backIconButtonProps={{
                            "aria-label": translate("_.previous"),
                            className: "prev-page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": translate("_.next"),
                            className: "next-page",
                        }}
                        SelectProps={{className: "select-rpp"}}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                )}
            </Translate>
            {/*)}*/}
        </div>
    );
};

function MyPagination(props: Omit<Props, "size"> & {size?: number}) {
    const {settings} = usePersonalized();
    const search = useSelector(({fuse}) => fuse.search);
    const [size, setSize] = useState(rowsPerPage);

    useEffect(() => {
        if (settings) {
            setSize(props.size || search.size || settings.rowPerPage || rowsPerPage);
        } else {
            setSize(props.size || search.size || rowsPerPage);
        }
    }, [settings, search, props]);

    const page = props.page || search.page || 1;

    return <InsidePagination {...props} page={page} size={size} />;
}

export default MyPagination;
