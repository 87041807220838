import {showMessage, turnOff, turnOn} from '@fuseActions';
import {Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Fab from '@material-ui/core/Fab/Fab';
import Icon from '@material-ui/core/Icon/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';
import {DialogWrapper, EmptyView, MyAvatar} from '@ui';
import {setUserData} from 'app/auth/store/actions';
import classNames from 'classnames';
import getCroppedImg from 'components/layout/profile/cropedImage.js';
import axios, {userPrefix} from 'myaxios';
import {useCallback, useRef, useState} from 'react';
import Cropper from 'react-easy-crop';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import Webcam from 'react-webcam';
import {savePicture} from 'utils/fileUploader.js';
import {dataURItoBlob} from 'utils/utilities.js';

const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user"
};
const AvatarUploadable = () => {
    const dispatch = useDispatch();
    const webcamRef = useRef(null);
    const [src, setSrc] = useState("");
    const [fileLoading, setFileLoading] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [error, setError] = useState(false);

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const {profile} = useSelector(({user}) => user);

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(src, croppedAreaPixels);
            saveAvatar(dataURItoBlob(croppedImage));
            // setState({croppedImage, isCrop: false})
        } catch (e) {
            console.error(e);
        }
    };

    const fromFileInput = (e) => {
        /** 10 M **/
        closeMenu();
        dispatch(turnOn("profileCrop"));
        setFileLoading(true);
        savePicture(e.target.files[0], (status, url) => {
            setFileLoading(false);
            setSrc(url);
        });
        // if(e.target.files[0].size > 10480000) {
        //     props.showMessage({
        //         variant: 'error',
        //         message: 'message.File is too big!'
        //     });
        // } else {
        //     setState({image: e.target.files[0], fileLoading: true, isCrop: true});
        //     // setState({src: e.target.files[0].visitUrl, cameraDialog: true});
        //
        //     saveAvatar(e.target.files[0]);
        // }
    };

    const saveAvatar = (image) => {
        setFileLoading(true);
        savePicture(image, (status, url) => {
            axios.put(`${userPrefix}/avatar`, {avatar: url}).then(() => {
                closeMenu();
                dispatch(turnOff("profileCrop"));
                dispatch(showMessage({variant: "success", message: "message.Avatar saved!"}));
                // @ts-ignore
                dispatch(setUserData());
            }).finally(() => {
                setFileLoading(false);
            });
        });
    };

    const removePicture = () => {
        setFileLoading(true);
        axios
            .put(`${userPrefix}/avatar`, {
                avatar: ""
            })
            .then(() => {
                dispatch(showMessage({
                    variant: "success",
                    message: "message.Avatar removed!"
                }));
                closeMenu();

                // @ts-ignore
                dispatch(setUserData());
            }).finally(() => {
            setFileLoading(false);
        });
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const capture = useCallback(
        () => {
            // @ts-ignore
            const src = webcamRef.current?.getScreenshot();
            setSrc(src);
            dispatch(turnOff("profileCamera"));
            dispatch(turnOn("profileCrop"));
        },
        [webcamRef]
    );

    return <>
        <div className="relative">
            {fileLoading ? <CircularProgress size={94} /> : <MyAvatar user={profile} crop={200} size={96} className={"text-light"} />}
            {/*// @ts-ignore*/}
            <Fab
                aria-owns={anchorEl ? "simple-menu" : null}
                aria-haspopup="true"
                color="secondary"
                size={"small"}
                className="no-drag bottom-0 right-0 absolute"
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <Icon>{!!profile.avatar ? "edit" : "add_photo_alternate"}</Icon>
            </Fab>

            <Menu className={"no-drag "} id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                <MenuItem
                    onClick={() => {
                        closeMenu();
                        dispatch(turnOn("profileCamera"));
                    }}
                >
                    <ListItemIcon>
                        <Icon>photo_camera</Icon>
                    </ListItemIcon>
                    <Translate id="profile.Open camera" />
                </MenuItem>
                <label style={{display: "block"}} className="mb-0">
                    <MenuItem>
                        <ListItemIcon>
                            <Icon>image_search</Icon>
                        </ListItemIcon>
                        <input type="file" value={""} accept="image/jpeg" onChange={fromFileInput} className="hidden" />
                        <Translate id="profile.From file" /> <small> (2MB)</small>
                    </MenuItem>
                </label>
                <MenuItem className={"no-drag "} onClick={removePicture}>
                    <ListItemIcon>
                        <Icon>delete_outline</Icon>
                    </ListItemIcon>
                    <Translate id="profile.Remove picture" />
                </MenuItem>
            </Menu>
        </div>


        <DialogWrapper name={"profileCrop"}>
            <DialogContent className={classNames(fileLoading && "loading")}>
                <div className={"overflow-hidden relative"}>
                    <div className="crop-container w-512 h-512">
                        <Cropper
                            image={src}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            cropShape="round"
                            showGrid={false}
                            onZoomChange={(val) => setZoom(val)}
                            onCropChange={(val) => setCrop(val)}
                            // @ts-ignore
                            onCropComplete={(area, val) => setCroppedAreaPixels(val)}
                        />
                    </div>

                </div>
                <div className="controls">
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        // @ts-ignore
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={showCroppedImage} variant="contained" color="primary">
                    <Translate id="_.crop & save" />
                </Button>
            </DialogActions>
        </DialogWrapper>

        <DialogWrapper name={"profileCamera"}>
            <DialogContent>
                {error ? (
                    <div className="w-216">
                        <EmptyView
                            label={<Translate id={"_.No media"}/>} />
                    </div>
                ) : (
                    <Webcam
                        onUserMediaError={() => setError(true)}
                        audio={false}
                        height={500}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={500}
                        videoConstraints={videoConstraints}
                    />
                )}
            </DialogContent>
            <DialogActions>
                {!error && (
                    <Button onClick={capture} variant="contained" color="primary">
                        <Translate id="profile.Capture photo" />
                    </Button>
                )}
            </DialogActions>
        </DialogWrapper>

    </>;
};

export default AvatarUploadable;
