import {Dialog} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {useMemoizedFn} from "ahooks";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import Select from "react-select/async";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import {components} from "../../../../../utils/Select2Components";
import {searchContact} from "../../../../../utils/utilities";
import {useProcessStore} from "../../state/useProcessStore";

function NewUserDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.definitionUserDialog
    });

    const onChangeUserString = useProcessStore((state) => state.onChangeUserString);

    const [user, setUser] = useState(null);

    const changeUser = useMemoizedFn((value) => {
        if (value) {
            setUser({
                ...user,
                value: value.value,
                label: value.label
            });
        } else {
            setUser(null);
        }
    });

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        onChangeUserString(user.value);
        props.onChange(user.value);
        onTurnOffDialog();
    });

    return (
        <Dialog classes={{paper: "overflow-visible", root: "overflow-visible"}} {...dialogProps}>
            {
                <Translate>
                    {({translate}) => (
                        <DialogTitle id="NewUserDialog-dialog-title">{<>{translate("_.select a user")}</>}</DialogTitle>
                    )}
                </Translate>
            }
            <DialogContent className={"overflow-visible"}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Translate>
                            {({translate}) => (
                                <Select
                                    required
                                    textFieldProps={{
                                        margin: "normal",
                                        required: true,
                                        label: translate("_.stuff"),
                                        InputLabelProps: {
                                            shrink: true
                                        }
                                    }}
                                    margin={"normal"}
                                    loadOptions={searchContact}
                                    defaultOptions
                                    // styles={selectStyles}
                                    components={components}
                                    value={user}
                                    onChange={changeUser}
                                    placeholder={translate("process.Search a user")}
                                    isClearable
                                />
                            )}
                        </Translate>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={handleClose}>
                    {<Translate id={"_.cancel"} />}
                </Button>
                <Button
                    variant={"contained"}
                    disabled={user === ""}
                    onClick={handleSubmit}
                    color="secondary"

                >
                    {<Translate id={"_.sure"} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewUserDialog;
