import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch, useSelector} from "react-redux";
import * as fuseActions from "@fuseActions";
import * as Actions from "../../store/actions";
import axios, {flowPrefix} from "../../../../../myaxios";

function DeleteVariableTips(props) {
    const dispatch = useDispatch();

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const selectedAVariable = useSelector(({process}) => process.userTask_list.selectedAVariable);

    const selectedAVariableForInstance = useSelector(({process}) => process.instance.selectedVariable);

    const selectedUserTask = useSelector(({process}) => process.userTask_list.selectedUserTask);

    const selectedInstance = useSelector(({process}) => process.instances_list.selectedInstance);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return function cleanup() {
            setLoading(false);
            dispatch(Actions.selectAInstance(null));
            dispatch(Actions.selectAUsertask(null));
            dispatch(Actions.selectAVariable(null));
            dispatch(Actions.selectAVariableForInstance(null));
            dispatch(Actions.selectVariablesForAUserTask([]));
        };
    }, []);

    function handleDelete() {
        dispatch(fuseActions.turnOff("DeleteVariableTips"));
    }

    function canSubmit() {
        return loading === true;
    }

    function handleSubmit() {
        setLoading(true);
        selectedUserTask ? deleteVariablesForUserTask() : deleteVariablesForInstance();
    }

    const deleteVariablesForInstance = () => {
        const params = {
            name: selectedAVariableForInstance.name,
            id: selectedAVariableForInstance.id,
        };
        axios
            .delete(`${flowPrefix}/process-instance/${selectedInstance.id}/variables`, {params})
            .then((response) => {
                dispatch(Actions.getVariablesForInstance(selectedInstance.id));
                dispatch(Actions.getInstanceSingle(selectedInstance.id));
                setLoading(false);
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.Delete variables success!",
                    })
                );
                handleDelete();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteVariablesForUserTask = () => {
        const params = {
            name: selectedAVariable.name,
            id: selectedAVariable.id,
        };
        axios.post(`${flowPrefix}/task/${selectedUserTask.id}/variables`, {params}).then((response) => {
            setLoading(false);
            dispatch(Actions.getVariablesForUserTask(selectedUserTask.id));
            dispatch(Actions.getUserTaskSingle(selectedUserTask.id));
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.Delete variables success!",
                })
            );
            handleDelete();
        });
    };

    return (
        <Dialog
            open={!!dialogs["DeleteVariableTips"]}
            onClose={() => {
                handleDelete();
            }}
            fullWidth
        >
            <div className={loading ? "loading" : null}>
                <DialogTitle id="DeleteInstanceTips-dialog-title">
                    <Translate id="process.Tips" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="DeleteDefinitionTips-dialog-description">
                        <Translate id="process.Are you sure you want to delete this variable: " />
                        <strong>
                            {(selectedAVariable && selectedAVariable.name) ||
                                (selectedAVariableForInstance && selectedAVariableForInstance.name)}
                        </strong>
                        ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete()}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button disabled={canSubmit()} onClick={() => handleSubmit()} color="secondary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(DeleteVariableTips);
