import { GET_NAVIGATION, SET_NAVIGATION, RESET_NAVIGATION } from "@fuseActions";


const navigation = function(state = null, action) {
    switch (action.type) {
        case GET_NAVIGATION: {
            return [
                ...state
            ];
        }
        case SET_NAVIGATION: {
            return [
                ...action.navigation
            ];
        }
        case RESET_NAVIGATION: {
            return null;
        }
        default: {
            return state;
        }
    }
};

export default navigation;
