import {ListItemAvatar, ListItemText} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
// import {format} from 'timeago.js';
import Typography from "@material-ui/core/Typography";
import {CodeView, ColoredDate, ItemTitle, LocalTimeStamp, MyAvatar, MyChip} from "@ui";
import classNames from "classnames";
import clsx from "clsx";
import type {ReactNode} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import {issueStatuses} from "../enums";
import type {IssueType} from "../types";

const severityClasses = {
    minor: "bg-green-light",
    major: "bg-orange-light",
    critical: "bg-red-light",
};

type Props = {
    item: IssueType;
    noAssignee?: boolean;
    noStatus?: boolean;
    dense?: boolean;
    actions?: ReactNode;
};

const IssueItem = (props: Props) => {
    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);
    const {item, noAssignee, noStatus, dense, actions} = props;

    return (
        <>
            <div
                className={clsx(
                    severityClasses[item.severity],
                    dense ? "-ml-12 h-32 w-12 mr-8" : "mr-12 w-20 h-32",
                    " flex-shrink-0 rounded-4 -ml-12"
                )}
            />

            {!noAssignee && (
                <ListItemAvatar classes={{root: listView ? "min-w-40" : "min-w-52 relative"}}>
                    <MyAvatar user={item.assignee.user} className={"mt-2"} size={(listView || dense) && 24} />
                </ListItemAvatar>
            )}
            <ListItemText
                disableTypography
                className={classNames(listView && "my-0", "text-base")}
                primary={
                    <div className={"flex items-center"}>
                        {dense ? (
                            <ItemTitle className="capitalize font-normal text-left">{item.title}</ItemTitle>
                        ) : (
                            <>
                                <CodeView dense>{item.code}</CodeView>
                                <ItemTitle className="capitalize font-normal text-left" to={`/issue/${item.id}`}>
                                    {item.title}
                                </ItemTitle>
                            </>
                        )}
                        {listView && (
                            <div className={"ml-12 flex items-center"}>
                                {!dense && (
                                    <>
                                        <small className={"text-grey-dark"}>
                                            {item.project.name} - <LocalTimeStamp datetime={item.timestamp} />
                                        </small>
                                    </>
                                )}
                                <Typography component={"span"} className={"block ml-12"}>
                                    {item.labels.length > 0 &&
                                        item.labels.map((i, j) => (
                                            <Chip
                                                component={"span"}
                                                key={j}
                                                label={i.title}
                                                size={"small"}
                                                className={"text-white font-bold mr-2 text-xs h-16"}
                                                style={{backgroundColor: i.color}}
                                            />
                                        ))}
                                </Typography>
                            </div>
                        )}
                    </div>
                }
                secondary={
                    !listView && (
                        <span className={clsx(dense ? "" : "flex items-center")}>
                            <small className={"text-grey-dark"}>
                                {item.project.name} <LocalTimeStamp datetime={item.timestamp} />
                            </small>
                            <span className={clsx("block", dense ? "" : "ml-12 -mt-2")}>
                                {item.labels.length > 0 &&
                                    item.labels.map((i, j) => (
                                        <Chip
                                            component={"span"}
                                            key={j}
                                            label={i.title}
                                            size={"small"}
                                            className={"text-white font-bold mr-2 text-xs h-16"}
                                            style={{backgroundColor: i.color}}
                                        />
                                    ))}
                            </span>
                            {item.dueDate && item["currentStatus"] !== "Close" ? (
                                <div className={"mt-3"}>
                                    <ColoredDate size={16} date={item.dueDate} />
                                </div>
                            ) : null}
                        </span>
                    )
                }
            />

            {/*{!dense && item.dueDate && item["currentStatus"] !== "Close" ? (*/}
            {/*    <div>*/}
            {/*        <ColoredDate size={16} className={"mr-3"} date={item.dueDate} />*/}
            {/*    </div>*/}
            {/*) : null}*/}
            {!noStatus && <MyChip color={issueStatuses[item.currentStatus].color} label={<Translate id={`issues.${item["currentStatus"]}`} />} />}
            {actions}
        </>
    );
};

export default IssueItem;
