import {Backdrop, makeStyles} from "@material-ui/core";
// import Lottie from "react-lottie-player";
// import loadingjson from "../../../../../IconFontVendor/loading2.json";
import useBackdropLoading from "../../utils/hooks/useBackdropLoading";
import {FuseLoading} from "../../../../../@fuse";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function ProgressBackdrop(props) {
    const classes = useStyles();
    const {openBackDrop} = useBackdropLoading();
    return (
        <Backdrop className={classes.backdrop} open={openBackDrop}>
            <div className={"flex flex-col my-24 justify-center items-center"}>
                <FuseLoading />
                {/*<Lottie loop animationData={loadingjson} play style={{width: 150, height: 150}} />*/}
            </div>
        </Backdrop>
    );
}

export default ProgressBackdrop;
