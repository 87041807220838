import {Translate} from "react-localize-redux";
import type {EnumType} from "../../../types";

export const taskStatuses: EnumType[] = [
    {color: "green", icon: "check", value: "check", label: <Translate id={"SCMs.check"} />},
    {color: "red", icon: "not_started", value: "open", label: <Translate id={"SCMs.open"} />},
    {color: "orange", icon: "play_arrow", value: "start", label: <Translate id={"SCMs.start"} />},
    {color: "orange", icon: "pause", value: "pause", label: <Translate id={"SCMs.pause"} />},
    {
        color: "blue",
        icon: "assignment_turned_in",
        value: "settlement",
        label: <Translate id={"SCMs.settlement"} />
    },
    {color: "purple", icon: "alarm_on", value: "pending", label: <Translate id={"SCMs.pending"} />},
    {color: "black", icon: "thumb_up_alt", value: "close", label: <Translate id={"SCMs.close"} />},
    {color: "red", icon: "thumb_down_alt", value: "reject", label: <Translate id={"SCMs.reject"} />}
];

export const statusMapColor = {
    check: "green",
    open: "danger",
    check_pass: "danger",
    reject: "red-dark",
    ENABLE: "success",
    OPEN: "success",
    CLOSE: "danger",
    DISABLE: "danger",
    start: "warning",
    pause: "orange-dark",
    report: "primary",
    settlement: "info",
    pending: "purple",
    close: "black"
};

