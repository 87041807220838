import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    userTaskObject: null,
    activities:[]
};


const userTask = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_USERTASK_SINGLE: {
            let tobj = cloneDeep(state);
            tobj.userTaskObject = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     userTaskObject: action.payload
            // }
        }
        case Actions.RESET_USERTASK_SINGLE: {
            let tobj = cloneDeep(state);
            tobj.userTaskObject = null;
            return tobj;
            // return {
            //     ...state,
            //     userTaskObject: action.payload
            // }
        }
        case Actions.GET_ACTIVITIES: {
            let tobj = cloneDeep(state);
            tobj.activities = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     activities: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default userTask;
