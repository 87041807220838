export const createListSlice = (set, get) => ({
    incomeSureListSelected: [],
    incomeSureSingleSelected: null,
    isIncomeSure: false,
    isSelectedAll: false,
    onChangeIsSelectedAll: (flag) => {
        set({
            isSelectedAll: flag,
        });
    },
    onChangeIsIncomeSure: (flag) => {
        set({
            isIncomeSure: flag,
        });
    },
    onChangeIncomeSureSingleSelected: (data) => {
        set({
            incomeSureSingleSelected: data,
        });
    },
    onChangeIncomeSureListSelected: (datas) => {
        set({
            incomeSureListSelected: datas,
        });
    },
});
