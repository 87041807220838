import axios, {crmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_CUSTOMERS = "[COMMERCE|CUSTOMERS] GET CUSTOMERS";
export const GET_CUSTOMER_GROUP = "[COMMERCE|CUSTOMERS] GET_CUSTOMER_GROUP";
export const CUSTOMERS_CHANGE_VIEW = "[COMMERCE|CUSTOMERS] CUSTOMERS_CHANGE_VIEW";
export const SELECT_CUSTOMER_GROUP = "[COMMERCE|CUSTOMERS] SELECT_CUSTOMER_GROUP";
export const SELECT_CUSTOMER = "[COMMERCE|CUSTOMERS] SELECT_CUSTOMER";
export const CANCEL_SELECTED_CUSTOMER   = "[COMMERCE_CANCEL_SELECTED_CUSTOMER]";
export const SELECT_CUSTOMERS = "[COMMERCE|CUSTOMERS] SELECT_CUSTOMERS";
export const SELECT_USERS = "[COMMERCE|CUSTOMERS] SELECT_USERS";

export function getCustomers(params, callback) {
    let request = params.my ?
        axios.get(`${crmPrefix}/myCustomers`, {params}) :  axios.get(`${crmPrefix}/customers`,{params});
    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_CUSTOMERS,
                payload: response.data
            });
            if(typeof callback=== "function") callback();
            dispatch(finishLoading());
        });
    };
}

export function getCustomerGroups(params) {
    const request = axios.get(`${crmPrefix}/customerGroups`, {
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_CUSTOMER_GROUP,
                payload: response.data
            });
            dispatch(finishLoading());
        });
    };
}

export function selectUsers(users) {
    return {
        type      : SELECT_USERS,
        users
    };
}

export function selectCustomerGroup(group){
    return {
        type      : SELECT_CUSTOMER_GROUP,
        group
    };
}

// select one customer for edit dialog
export function selectCustomer(payload){
    return {
        type      : SELECT_CUSTOMER,
        payload
    };
}

export function cancelSelectedCustomer() {
    return {
        type      : CANCEL_SELECTED_CUSTOMER
    };
}

// select many customers to add in group
export function selectCustomers(customers){
    return {
        type      : SELECT_CUSTOMERS,
        customers
    };
}
