import {showMessage} from "@fuseActions";
import {CardHeader, Dialog, FormControlLabel, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import {DeleteWrapper, DialogWrapper, EmptyView, FilesView, MyAvatar, MyTooltip, NameLabel} from "@ui";
import classNames from "classnames";
import {isSameDay} from "date-fns";
import {fileSeverURL} from "ENV.VARIABLE";
import axios, {firmPrefix} from "myaxios";
import {forwardRef, useEffect, useState} from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import type {AttachmentType} from "types";
import {isImage} from "utils/utilities";
import type {imGalleryType} from "../../../types";
import {useWorkPlan} from "../../useWorkPlan";
import FileListItem from "./FileListItem";
import UploadButton from "./UploadButton";

const FilesWrapper = forwardRef<HTMLDivElement>((props, ref) => {
    const dispatch = useDispatch();
    const userId = useSelector(({user}) => user.profile?.id);
    const {wp, refresh} = useWorkPlan();
    const attachments = wp?.attachments || [];
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const [loading, setLoading] = useState(false);
    const [openCheckboxes, setOpenCheckboxes] = useState(false);
    const [checked, setChecked] = useState<AttachmentType[]>([]);
    const [progress, setProgress] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState<imGalleryType[]>([]);

    const imageGalleryDescriptionItem = (item: AttachmentType) => (
        <div className={"text-left"}>
            <Typography>{item.originalName}</Typography>
            <div className="flex flex-row items-center ">
                <MyAvatar user={item.user} className={"mr-3"} />
                <div className="flex flex-col">
                    <div>
                        <strong>
                            <NameLabel user={item.user} />
                        </strong>
                    </div>
                    <div>
                        <small className={"mr-2"}>@{item.user.username}</small>
                    </div>
                </div>
                {item.user.id === userId && (
                    <DeleteWrapper
                        deleteReq={() => axios.delete(`${firmPrefix}/deleteAttachment?attachmentId=${item.id}`)}
                        callback={refresh}
                        children={(onClick) => (
                            <IconButton className={"text-white ml-20 -mr-12"} onClick={onClick}>
                                <Icon>delete</Icon>
                            </IconButton>
                        )}
                    />
                )}
                <IconButton
                    component={"a"}
                    className={"text-white ml-20 -mr-12"}
                    href={item.visitUrl + "?name=" + item.originalName}
                    download={item.originalName}>
                    <Icon>download</Icon>
                </IconButton>
            </div>
        </div>
    );

    const setPhotos = () => {
        const im: imGalleryType[] = [];
        wp?.attachments.map((a) => {
            if (isImage(a.visitUrl)) {
                im.push({
                    ...a,
                    original: a.visitUrl + "/resize/800x800",
                    fullscreen: a.visitUrl,
                    thumbnail: a.visitUrl + "/resize/100x100",
                    description: imageGalleryDescriptionItem(a),
                    originalTitle: a.originalName,
                });
            }
        });
        setImages(im);
    };

    useEffect(() => setPhotos(), [wp, attachments]);

    const startCarousel = (id) => {
        setCurrentIndex(images.findIndex((img) => img.id === id));
        setModalIsOpen(true);
    };

    const toggleFileToZip = (file) => () => {
        if (checked.includes(file)) setChecked(checked.filter((f) => f.id !== file.id));
        else setChecked([...checked, file]);
    };

    const toggleAll = () => {
        if (checked.length === attachments.length) {
            setChecked([]);
        } else {
            setChecked(attachments);
        }
    };

    const downloadZip = () => {
        setLoading(true);
        const data = checked.map((i) => ({
            uri: i.uri,
            size: i.size,
            hashCode: i.hashCode,
            originalName: i.originalName,
        }));

        axios({
            url: `${fileSeverURL}/download?name=${wp?.title}.zip`,
            responseType: "blob",
            method: "POST",
            onDownloadProgress: function (progressEvent) {
                setProgress(() => {
                    if (!progressEvent.total) return 0;
                    return Math.min((progressEvent.loaded * 100) / progressEvent.total, 100);
                });
            },
            data,
        })
            .then((response) => {
                setChecked([]);
                setOpenCheckboxes(false);
                dispatch(showMessage({message: "message.Download complete!", variant: "success"}));
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${wp?.title}.zip`;
                a.click();
            })
            .finally(() => setLoading(false));
    };

    // const [params, setParams] = useState<SearchType>();
    // const [filteredAttachments, setFilteredAttachments] = useState<AttachmentType[] | undefined>(attachments);
    // const searchAttachments = (p?: SearchType) => {
    //     const d = {...params, ...p};
    //     setParams(d);
    //     if (p) {
    //         const t = attachments.filter((a) => {
    //             const s = "user" in d && d.user ? a.user.id === d.user?.id : true;
    //             const w = "date" in d && d.date ? isSameDay(a.uploadDate, d.date) : true;
    //             console.log(w, s, s && w);
    //             return s && w;
    //         });
    //         setFilteredAttachments(t);
    //     }
    // };

    return !wp ? (
        <></>
    ) : (
        <div data-cy={"files-wrapper"}>
            <Dialog
                BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
                maxWidth={"lg"}
                classes={{root: "min-h-224"}}
                open={modalIsOpen}
                onClose={() => {
                    setModalIsOpen(false);
                }}
                fullWidth>
                <ImageGallery lazyLoad items={images} thumbnailPosition={"bottom"} startIndex={currentIndex} showIndex />
            </Dialog>

            <DialogWrapper name={"WPAttachmentsDialog"} maxWidth={"xl"}>
                {() => (
                    <>
                        <FilesView data={wp.attachments} />
                        {/*<div className="flex px-16 mb-16">*/}
                        {/*    <div className="min-w-288 mr-12">*/}
                        {/*        <UserPreviewSearch*/}
                        {/*            margin={"none"}*/}
                        {/*            label={"Seach by user"}*/}
                        {/*            fullWidth*/}
                        {/*            name={"wpAttachmentFile"}*/}
                        {/*            onChange={(val) => searchAttachments({user: val?.user})}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    <DatePickerWrapper*/}
                        {/*        clearable*/}
                        {/*        label={"Seach by date"}*/}
                        {/*        // @ts-ignore*/}
                        {/*        value={params?.date || null}*/}
                        {/*        onChange={(val) => searchAttachments({date: val})}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<Divider />*/}
                        {/*<TableView<AttachmentType> columns={colTitle} content={filteredAttachments} name={"WPAttachmentsDialog"} />*/}
                    </>
                )}
            </DialogWrapper>

            {/* region header */}
            <CardHeader
                title={
                    <>
                        <Translate id="wps.files" /> ({attachments.length})
                    </>
                }></CardHeader>

            {/* endregion */}

            {/* region Buttons */}
            <div className={"px-12 "}>
                <UploadButton refresh={refresh} wp={wp} />

                {/*{attachments.length > 0 && (*/}
                {/*    <CustomButton*/}
                {/*        title={"Download Zip"}*/}
                {/*        icon={"download"}*/}
                {/*        onClick={() => {*/}
                {/*            setOpenCheckboxes(!openCheckboxes);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}

                {attachments.length > 0 && (
                    <Button
                        className={"my-12"}
                        variant={"contained"}
                        data-cy={"toggle-checkboxes"}
                        onClick={() => {
                            setOpenCheckboxes(!openCheckboxes);
                        }}
                        color={!openCheckboxes ? "secondary" : "default"}
                        size={"small"}>
                        {!openCheckboxes ? <Translate id={"wps.Download as ZIP"} /> : <Translate id={"_.cancel"} />}
                    </Button>
                )}

                {loading && <EmptyView dense loading={loading} />}
            </div>
            {/* endregion */}

            {/* region download */}
            {openCheckboxes && (
                <div className="px-8 pt-8">
                    {progress > 0 && progress < 100 ? (
                        <div className={"w-full p-16"}>
                            <LinearProgress variant="determinate" value={progress} />
                        </div>
                    ) : (
                        <div className={"flex items-center justify-between"}>
                            <FormControlLabel
                                className={"ml-1"}
                                control={
                                    <Checkbox
                                        data-cy={"toggle-all"}
                                        checked={checked.length === attachments?.length}
                                        onChange={toggleAll}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                }
                                label={<Translate id={"wps.Select all"} />}
                            />
                            <div className="flex items-center">
                                <Button
                                    size={"small"}
                                    variant={"contained"}
                                    data-cy={"download-zip"}
                                    disabled={checked.length === 0}
                                    color={"secondary"}
                                    onClick={downloadZip}>
                                    {<Translate id={"wps.Download"} />} (<span data-cy={"file-counter"}>{checked?.length}</span>)
                                </Button>
                                <IconButton
                                    onClick={() => {
                                        setOpenCheckboxes(false);
                                    }}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/* endregion */}

            {/* region attachemnt list */}
            <List dense disablePadding style={{maxHeight: "calc(100vh - 110px)"}} className={classNames("overflow-y-scroll pl-12 pt-0")}>
                {attachments.length > 0 &&
                    attachments.map((file, i) => (
                        <FileListItem
                            Checkbox={
                                openCheckboxes && (
                                    <Checkbox
                                        data-cy={"select-item"}
                                        className={"mr-8"}
                                        checked={checked.includes(file)}
                                        onChange={toggleFileToZip(file)}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                )
                            }
                            ref={attachments.findIndex((s) => isSameDay(s.uploadDate, dialogs["fileFilterTime"])) === i ? ref : null}
                            key={file.id}
                            startCarousel={startCarousel}
                            file={file}
                        />
                    ))}
            </List>
            {/* endregion */}
        </div>
    );
});

export const CustomButton = ({onClick, icon, title, ...rest}: {onClick(): void; icon: string; title: string}) => {
    return (
        <MyTooltip title={title}>
            <button
                onClick={onClick}
                className="flex hover:shadow-2 mr-8 flex-col items-center p-16 bg-grey-lighter duration-200 rounded-12 "
                {...rest}>
                <Icon className={"text-4xl text-gray-500"}>{icon}</Icon>
            </button>
        </MyTooltip>
    );
};

// const colTitle: columnType<AttachmentType>[] = [
//     {
//         width: 400,
//         id: "source",
//         className: "truncate",
//         label: "_.media source",
//         itemView: (item) => (
//             <div className={"flex items-center"} data-cy={"file-item-title"}>
//                 <a
//                     className={"cursor-pointer flex items-center"}
//                     onClick={(e) => {
//                         e.preventDefault();
//                         createDownloadLink(item.visitUrl, item.originalName, item.id);
//                     }}>
//                     <span className="max-w-32 block max-h-52  ">
//                         <FileIcon extension={extExtractor(item.visitUrl)} {...defaultStyles[extExtractor(item.visitUrl)]} />
//                     </span>
//                     <span className={"block"}>
//                         <span className={"ml-8 whitespace-pre-line font-bold"}>{item.originalName} </span>
//                         {/*<Typography variant={"caption"} className={"ml-8 text-gray-500"}>*/}
//                         {/*    {item.description}*/}
//                         {/*</Typography>*/}
//                     </span>
//                 </a>
//
//                 {/*)}*/}
//             </div>
//         ),
//     },
//     {
//         hideInMobile: true,
//         id: "size",
//         padding: "none",
//         label: "_.size",
//         itemView: (item) => <>{formatSizeUnits(item.size)}</>,
//     },
//     {
//         id: "user",
//         padding: "none",
//         label: "_.user",
//         itemView: (item) => (
//             <>
//                 <UserView dense noUsername user={item.user} />
//             </>
//         ),
//     },
//     {
//         id: "timestamp",
//         itemView: (item) => <LocalTimeStamp className={"text-gray-400"} format={dateFormatWithTime} datetime={item.uploadDate} />,
//         label: "_.date",
//         sort: true,
//     },
//     {
//         width: 100,
//         id: "download",
//         itemView: (item) => (
//             <IconButton
//                 onClick={() => {
//                     createDownloadLink(item.visitUrl, item.originalName, item.id);
//                 }}>
//                 <Icon>download</Icon>
//             </IconButton>
//         ),
//         label: "",
//         sort: true,
//     },
// ];

FilesWrapper.displayName = "FilesWrapper";

export default FilesWrapper;
