import {GET_TEMPLATES, SELECT_TEMPLATE, SET_TEMPLATES_SEARCH_TEXT} from '../actions';

const initialState = {
    content               : [],
    view                  : 0,  // 0 = list , 1 = grid , 2 = kanban
    groups                : [],
    selectedTemplate      : null,
    selectedCustomerGroup : null,
    searchText            : "",
    totalElements         : 0,
};

const templateReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_TEMPLATES:{
            return {
                ...state,
                ...action.payload
            };
        }
        case SELECT_TEMPLATE:{
            return {
                ...state,
                selectedTemplate: action.payload
            };
        }
        case SET_TEMPLATES_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        default:{
            return state;
        }
    }
};

export default templateReducer;
