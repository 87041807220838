import axios, {chatPrefix} from "../../../../myaxios";

export const TOGGLE_QUICK_PANEL = "[QUICK PANEL] TOGGLE QUICK PANEL";
export const GET_QUICK_PANEL_DATA = "[QUICK PANEL] GET DATA";
export const GET_NOTIFICATION = "[QUICK PANEL] GET NOTIFICATION";
export const SEEN_NOTIFICATION = "[QUICK PANEL] SEEN_NOTIFICATION";

export const CLEAR_SELECTED = "[QUICK PANEL] CLEAR_SELECTED";
export const MARK_READ_ALL = "[QUICK PANEL] MARK_READ_ALL";
export const MARK_READ_GROUP = "[QUICK PANEL] MARK_READ_GROUP";

export function getNotifications() {
    const request = axios.get(`${chatPrefix}/notifications?status=unread`);
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_QUICK_PANEL_DATA,
                payload: response.data,
            })
        );
}
// export function onSeenNotification() {
//     return {
//         type   : ON_SEEN_NOTIFICATION,
//         payload: value
//     }
// }

export function toggleQuickPanel(value) {
    return {
        type: TOGGLE_QUICK_PANEL,
        payload: value,
    };
}

export function markRead() {
    return {
        type: MARK_READ_ALL,
    };
}

export function receiveNotification(ntf) {
    return {
        type: GET_NOTIFICATION,
        ntf,
    };
}

export function seeNotification(id) {
    return {
        type: SEEN_NOTIFICATION,
        id,
    };
}

export function clearSelected() {
    return {
        type: CLEAR_SELECTED,
    };
}
