// @ts-nocheck
import {ListItemSecondaryAction} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { showMessage, setCache, turnOn ,finishLoading, startLoading} from "@fuseActions";

import axios, {firmPrefix, userPrefix} from "myaxios";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import AsyncSelect from "../../../../../utils/Forms/AsyncSelect";
import {useWorkPlan} from "../useWorkPlan";

type ERMProjectType = {
    id: string;
    name: string;
    remark?: string;
    type: "ERM";
};
const ProjectInfo = () => {
    const {canEdit, wp, refresh} = useWorkPlan();
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState(null);

    const redirectToOldSystem = (e, projectId) => {
        e.preventDefault();
        dispatch(startLoading());
        axios
            .get(`${userPrefix}/accessOA`)
            .then((res) => {
                if (res.data.url) {
                    window.open(
                        res.data.url +
                        `&CONTROLLER=erm.project.cmd.ProjectDetailCmd&NEXT_PAGE=PJ_PROJECT_DETAIL&projectId=${projectId}`
                    );
                    setLink(
                        res.data.url +
                        `&CONTROLLER=erm.project.cmd.ProjectDetailCmd&NEXT_PAGE=PJ_PROJECT_DETAIL&projectId=${projectId}`
                    );
                    setTimeout(() => {
                        setLink(null);
                    }, 30000);
                }
                dispatch(finishLoading());
            })
            .catch(() => {
                dispatch(finishLoading());
                dispatch(
                    showMessage({
                        variant: "error",
                        message: "message.You don't have access to the old system"
                    })
                );
            });
    };
    // const [remark, setRemark] = useState("");

    // const searchProjects = (inputValue, callback) => {
    //     axios
    //         .get(`${firmPrefix}/myProjects?size=8`, {
    //             params: {
    //                 keyword: inputValue,
    //             },
    //         })
    //         .then((res) => {
    //             const projects = res.data.content.map((item) => ({
    //                 value: item.id,
    //                 item,
    //                 label: (
    //                     <span>
    //                         <Typography variant={"body1"}>{item.name}</Typography>
    //                         {/*<small className={"text-grey-dark"}>{item.remark}</small>*/}
    //                     </span>
    //                 ),
    //             }));
    //             callback(projects);
    //         });
    // };

    // const changeProject = (value) => {
    //     if (value) {
    //         // setProjectId(value.item);
    //         setProject(value);
    //         saveProject(value.item);
    //     } else {
    //         // setProjectId("");
    //         setProject("");
    //         saveProject("");
    //     }
    // };

    const saveProject = (project?: any) => {
        setLoading(true);
        const data = {
            workplanId: wp.id
        };
        if (project) {
            data.project = project;
        }
        axios.put(`${firmPrefix}/setProject`, data).then(() => {
            refresh().then(() => {
                setLoading(false);
                setEdit(false);
            });
        });
    };

    const onClickCheckinResult = () => {
        dispatch(setCache("cacheProjectId", wp.project.id));
        dispatch(turnOn("checkinResults"));
    };

    return edit ? (
        <>
            <div className={"flex items-center w-full pl-12  pb-12 pt-12"}>
                <div className="flex-1">
                    {/*<Translate>*/}
                    {/*    {({translate}) => (*/}
                    {/*        <Select*/}
                    {/*            required*/}
                    {/*            textFieldProps={{*/}
                    {/*                required: true,*/}
                    {/*                size: "small",*/}
                    {/*            }}*/}
                    {/*            loadOptions={searchProjects}*/}
                    {/*            defaultOptions*/}
                    {/*            fullWidth*/}
                    {/*            // styles={selectStyles}*/}
                    {/*            components={components}*/}
                    {/*            value={project}*/}
                    {/*            onChange={changeProject}*/}
                    {/*            noOptionsMessage={() => translate("_.no option")}*/}
                    {/*            placeholder={translate("wps.Search a proejct")}*/}
                    {/*            isClearable*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</Translate>*/}

                    <AsyncSelect<ERMProjectType>
                        id={"selectProject"}
                        data-cy={"select-project"}
                        defaultOptions
                        defaultValue={wp.project}
                        size={"small"}
                        name={"project"}
                        variant={"outlined"}
                        onChange={(e, newValue) => saveProject(newValue)}
                        getOptionLabel={(option) => option.name}
                        link={`${firmPrefix}/myProjects?size=8`}
                    />
                </div>

                <div>
                    <IconButton onClick={() => setEdit(false)}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </div>
        </>
    ) : (
        <List dense className={loading ? "loading py-0" : "py-0"}>
            <ListItem disableGutters className={"pl-8"}>
                <ListItemIcon className={"min-w-32"}>
                    <Icon>business_center</Icon>
                </ListItemIcon>

                <ListItemText
                    primary={
                        wp.project ? (
                            link ? (
                                <a target={"_blank"} className="cursor-pointer" href={link}>
                                    {wp.project.name}
                                </a>
                            ) : (
                                <a
                                    target={"_blank"}
                                    className="text-primary cursor-pointer"
                                    onClick={(e) => redirectToOldSystem(e, wp.project.id)}
                                >
                                    {wp.project.name}
                                </a>
                            )
                        ) : (
                            <span data-cy={"empty-project"}>
                                <Translate id={"wps.No project is selected"} />
                            </span>
                        )
                    }
                />

                {wp.project && (
                    <IconButton data-cy={"checkin-button"} onClick={onClickCheckinResult}>
                        <Icon>image</Icon>
                    </IconButton>
                )}
                {canEdit && (
                    <ListItemSecondaryAction>
                        <IconButton data-cy={"edit-project"} size={"small"} onClick={() => setEdit(true)}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        </List>
    );
};

export default ProjectInfo;
