import {Button, Dialog, DialogContent, Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useMemoizedFn} from "ahooks";
import * as fuseActions from "app/store/actions";
import classNames from "classnames";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useDefinitionStore} from "../definitions/state/useDefinitionStore";

function NewPauseDefinitionDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.pauseDefinitionTips
    });

    const dispatch = useDispatch();

    const currentDefinition = useDefinitionStore((state) => state.currentDefinition);

    const [loading, setLoading] = useState(false);

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        const params = {
            id: currentDefinition?.id,
            suspended: !currentDefinition?.suspended
        };
        axios
        .put(`${flowPrefix}/processDefinitionSetSuspended`, params)
        .then((res) => {
            finshParams?.onFinish();
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: currentDefinition?.suspended
                        ? "message.Definition started!"
                        : "message.Definition suspended!"
                })
            );
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    });

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <DialogTitle id="PauseDefinitionTipsDialog-dialog-title">
                    <Translate id="process.Start or pause this definition" />
                    <Chip
                        className={classNames(
                            `font-bold`,
                            currentDefinition?.suspended === false
                                ? "bg-green text-white ml-2"
                                : "bg-red text-white ml-3"
                        )}
                        label={<Translate id={`process.${currentDefinition?.suspended ? "suspended" : "running"}`} />}
                    />
                </DialogTitle>
                <DialogContent>
                    <div className="flex flex-col justify-center">
                        <Typography variant="button" className={"text-center font-black"} display="block">
                            {currentDefinition?.suspended ? (
                                <Translate id={"process.Sure you want to start the current process definition?"} />
                            ) : (
                                <Translate id={"process.Sure you want to suspend the current process definition?"} />
                            )}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={loading}
                        onClick={handleSubmit}
                        color="secondary"

                    >
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewPauseDefinitionDialog;
