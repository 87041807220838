import clsx from 'clsx';
import {LazyLoadImage} from 'react-lazy-load-image-component';

export type ThumbSizes = "20" | "24" | "32" | "40" | "52" | "56" | "64" | "72" | "96" | "106" | "128" | "160" | "192" | "200" | "224" | "256";

type Props = {
    src: string;
    size?: ThumbSizes;
    className?: string;
};
export const Thumbnail = ({src, size = "106", className, ...rest}: Props) => {
    return (
        <>
            <LazyLoadImage
                loading="lazy"
                effect="blur"
                className={clsx(`min-w-${size} min-h-${size}`, "rounded-6 object-cover", className)}
                src={`${src}/resize/${parseInt(size) * 2}x${parseInt(size) * 2}`}
                {...rest}
            />
        </>
    );
};
