import {Button, DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogWrapper, LocalTimeStamp, ParseHTML} from "@ui";
import { turnOff } from "app/store/actions";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import type {wpHistoryType} from "../../types";

const HistoryDetail = ({selectedHistory}: {selectedHistory: wpHistoryType}) => {
    const dispatch = useDispatch();

    return (
        selectedHistory && (
            <DialogWrapper name={"historyDetail"} maxWidth={"xl"}>
                {() => (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            {" "}
                            <Translate id="wps.History at " />{" "}
                            <LocalTimeStamp datetime={selectedHistory.updateTimestamp} />
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <ParseHTML html={selectedHistory.content} />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => dispatch(turnOff("historyDetail"))} color="primary">
                                <Translate id="_.close" />
                            </Button>
                        </DialogActions>
                    </>
                )}
            </DialogWrapper>
        )
    );
};

export default HistoryDetail;

// export default withDialog("historyDetail", "xl")(withLocalize(HistoryDetail));
