import axios, {flowPrefix} from "myaxios";
import useSWRMutation from "swr/mutation";

function sendRequest(url, {arg}) {
    return axios.post(url, arg);
}

/**
 * @description - 获取流程定义列表
 * @returns {Object} results - {run, isMutating, data}
 * @returns {Function} results.run - 触发请求函数
 * @returns {Boolean} results.isMutating - 是否正在请求中
 * @returns {Object} results.data - 请求返回的数据
 */
function usePostCategory() {
    const {trigger, isMutating, data, error} = useSWRMutation(`${flowPrefix}/processCategory`, sendRequest);

    return {
        run: trigger,
        isMutating,
        data,
        error,
    };
}

export default usePostCategory;
