import {Input, ListItemAvatar, ListItemText} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";
import {DeleteWrapper, LocalTimeStamp, MyChip, MyTooltip, Thumbnail, UserView} from "@ui";
import {setFullImage, showMessage, turnOn} from "app/store/actions";
import clsx from "clsx";
import {isSameDay, isToday} from "date-fns";
import axios, {firmPrefix} from "myaxios";
import type {ReactNode} from "react";
import {forwardRef, useRef, useState} from "react";
import {defaultStyles, FileIcon} from "react-file-icon/dist/react-file-icon.esm";
import {Translate} from "react-localize-redux";
import ReactPlayer from "react-player";
import {useDispatch, useSelector} from "react-redux";
import type {AttachmentType} from "types";
import {uploadFiles} from "utils/fileUploader";
import {createDownloadLink, extExtractor, formatSizeUnits, isImage} from "utils/utilities";
import {FuseLoading} from "../../../../../../@fuse";
import FileThumb from "../../../../../../components/ui/FileThumb";
import {useWorkPlan} from "../../useWorkPlan";

type Props = {
    Checkbox?: ReactNode;
    startCarousel: (index: string) => void;
    file: AttachmentType;
};

const FileListItem = forwardRef<HTMLDivElement, Props>(({file, startCarousel, Checkbox}, ref) => {
    const dispatch = useDispatch();
    const fileType = file.type && file.type.split("/")[0];
    const userId = useSelector(({user}) => user.profile?.id);
    const {refresh} = useWorkPlan();
    const canEdit = file.user.id === userId && !Checkbox;
    const inputRef = useRef(null);
    const [editName, setEditName] = useState(false);
    const [name, setName] = useState(file.originalName.replace(/\.[^/.]+$/, ""));
    const [loading, setLoading] = useState(false);
    const [historyData, setHistoryData] = useState<Record<string, any>>([]);
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const [anchorEl, setAnchorEl] = useState(null);
    const getHistory = (attachmentId: string, e) => {
        setAnchorEl(e.currentTarget);

        axios.get(`${firmPrefix}/attachmentHistory`, {params: {attachmentId}}).then(({data}) => {
            setHistoryData(data);
        });
    };

    const editAttachmentName = () => {
        setLoading(true);
        const data = {
            attachmentId: file.id,
            originalName: name,
        };
        axios.put(`${firmPrefix}/updateAttachmentName`, data).then(() => {
            dispatch(
                showMessage({
                    variant: "success",
                    message: "message.Attachment name update",
                })
            );
            refresh().then(() => {
                setLoading(false);
            });
            setEditName(false);
        });
    };

    const changeFile = (e) => {
        if (e.target.files) {
            setLoading(true);
            uploadFiles(e.target.files, (status, files) => {
                setLoading(false);
                axios
                    .put(`${firmPrefix}/updateAttachment`, {
                        attachmentId: file.id,
                        attachment: files[0],
                    })
                    .then(() => {
                        refresh().then(() => setLoading(false));
                        dispatch(showMessage({message: "message.File Uploaded", variant: "success"}));
                    });
            });
        }
    };

    const editNameHandler = (e) => {
        e.preventDefault();
        setEditName(true);
    };

    const onBlur = (e) => {
        e.preventDefault();
        if (editName) {
            if (file.originalName.replace(/\.[^/.]+$/, "") !== name) editAttachmentName();
            // setName(file.originalName);
            setEditName(false);
        }
    };

    return (
        <>
            {file.isChanged && (
                <Popover
                    data-cy={"edited-popup"}
                    elevation={8}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    className="max-w-512 min-w-256"
                    classes={{paper: "max-w-512 min-w-288"}}>
                    {loading ? (
                        <div className={"m-12"}>
                            <FuseLoading />
                        </div>
                    ) : (
                        <List dense classes={{root: "ml-8"}}>
                            {historyData.length > 0 &&
                                historyData.map((h, i) => {
                                    // const fileType = h.attachment.type && h.attachment.type.split("/")[0];
                                    return (
                                        <ListItem key={i} classes={{root: "items-center pl-0 pt-0"}} data-cy={"history-item"}>
                                            <ListItemAvatar>
                                                <div className="w-64 mr-8 relative ">
                                                    {isImage(h.attachment.visitUrl) ? (
                                                        <button
                                                            data-cy={"open-image-modal"}
                                                            className="w-full h-full block"
                                                            onClick={() => {
                                                                dispatch(setFullImage("", h.attachment.visitUrl));
                                                                dispatch(turnOn("fullImage"));
                                                            }}>
                                                            <Thumbnail size={"56"} src={h.attachment.visitUrl} />
                                                        </button>
                                                    ) : (
                                                        <MyTooltip title={<Translate id={"_.download"} />}>
                                                            <a
                                                                data-cy={"download-file"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    createDownloadLink(
                                                                        h.attachment.visitUrl,
                                                                        h.attachment.originalName,
                                                                        h.attachment.id
                                                                    );
                                                                }}
                                                                role="button"
                                                                className="w-full hover:no-underline flex items-center justify-center"
                                                                target={"_blank"}>
                                                                <span className="max-w-56 block  ">
                                                                    <FileIcon
                                                                        extension={extExtractor(h.attachment.visitUrl)}
                                                                        {...defaultStyles[extExtractor(h.attachment.visitUrl)]}
                                                                    />
                                                                </span>
                                                            </a>
                                                        </MyTooltip>
                                                    )}
                                                </div>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<span>{h.attachment.originalName}</span>}
                                                secondary={
                                                    <span>
                                                        <LocalTimeStamp datetime={h.attachment.uploadDate} />{" "}
                                                        <small className="text-grey">({formatSizeUnits(h.attachment.size)})</small>
                                                    </span>
                                                }
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                    )}
                </Popover>
            )}
            <ListItem
                ref={ref}
                data-cy={"file-item"}
                ContainerProps={{
                    // @ts-ignore
                    "data-cy": "file-item",
                }}
                classes={{
                    root: clsx(
                        loading && "loading",
                        "items-start pl-0 transi pt-0 duration-200",
                        isSameDay(file.uploadDate, dialogs["fileFilterTime"]) && "bg-red-lighter rounded-4"
                    ),
                }}>
                {Checkbox}
                <ListItemAvatar>
                    {/*<div className="h-64 overflow-hidden w-64 mr-8 mt-8 relative border bg-grey-lighter rounded-8 hover:shadow-1">*/}
                    <div className=" mr-8 mt-8 relative w-64">
                        {canEdit && isToday(file.uploadDate) && (
                            <DeleteWrapper
                                item={file.hashCode}
                                deleteReq={() => axios.delete(`${firmPrefix}/deleteAttachment?attachmentId=${file.id}`)}
                                callback={refresh}
                                children={(onClick) => (
                                    <button
                                        data-cy={"delete-file"}
                                        onClick={onClick}
                                        className="absolute -top-8 rounded-full w-20 h-20 mt-1 mr-1 -left-4 bg-red-light text-white hover:bg-red-dark z-999 duration-200">
                                        <Icon fontSize={"small"}>close</Icon>
                                    </button>
                                )}
                            />
                        )}
                        {/*{isImage(file.visitUrl) ? (*/}
                        {/*    <button data-cy={"start-carousel"} className="w-full h-full block" onClick={() => startCarousel(file.id)}>*/}
                        {/*        <Thumbnail size={"56"} src={file.visitUrl} />*/}
                        {/*    </button>*/}
                        {/*) : (*/}
                        <FileThumb download file={file} size={"56"} onClickImage={() => startCarousel(file.id)} />
                    </div>
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <div className="mb-2 break-words">
                            {editName ? (
                                <form onSubmit={onBlur}>
                                    <Input
                                        inputProps={{
                                            "data-cy": "file-name-input",
                                        }}
                                        onBlur={onBlur}
                                        className={"pt-0"}
                                        autoFocus={true}
                                        onChange={(e) => setName(e.currentTarget.value)}
                                        value={name}
                                    />
                                </form>
                            ) : canEdit ? (
                                <a role={"button"} href={"#"} onClick={editNameHandler} data-cy={"edit-name"}>
                                    {file.originalName}{" "}
                                    <MyTooltip title={<Translate id={"Edit name"} />}>
                                        <Icon className={"align-middle"} fontSize={"small"}>
                                            edit
                                        </Icon>
                                    </MyTooltip>
                                </a>
                            ) : (
                                <span>{file.originalName}</span>
                            )}
                            {file.isChanged && !editName && (
                                <MyChip
                                    data-cy={"open-history"}
                                    color={"orange"}
                                    onClick={(e) => getHistory(file.id, e)}
                                    size="small"
                                    className="ml-2 font-bold relative"
                                    label={<Translate id={"wps.Edited"} />}
                                />
                            )}
                        </div>
                    }
                    secondary={
                        <div className={"flex items-center justify-between bg-gray-50 pl-1"}>
                            <div>
                                <UserView user={file.user} dense />
                                <LocalTimeStamp datetime={file.uploadDate} /> <small> ({formatSizeUnits(file.size)})</small>
                            </div>

                            {canEdit && (
                                <div>
                                    <>
                                        <input
                                            data-cy={"file-item-input"}
                                            ref={inputRef}
                                            type="file"
                                            multiple
                                            className={"hidden"}
                                            onChange={changeFile}
                                        />
                                        <MyTooltip
                                            onClick={() => {
                                                // setUpdateId(file.id);
                                                // @ts-ignore
                                                inputRef.current?.click();
                                            }}
                                            title={<Translate id={"wps.Update file"} />}>
                                            <IconButton data-cy={"update-attachment"}>
                                                <Icon>cloud_upload</Icon>
                                            </IconButton>
                                        </MyTooltip>
                                    </>
                                </div>
                            )}
                        </div>
                    }
                />
            </ListItem>

            {fileType === "audio" && (
                <li className={"-mt-12 mb-12"}>
                    <ReactPlayer width={250} url={file.visitUrl} height={50} controls playing={false} />
                </li>
            )}
        </>
    );
});

FileListItem.displayName = "FileListItem";
export default FileListItem;
