import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import {memo, useContext} from "react";
import {Toaster} from "react-hot-toast";
import {shallowEqual, useSelector} from "react-redux";
import {renderRoutes} from "react-router-config";
import {FuseMessage, FuseScrollbars, FuseSuspense} from "../../../@fuse";
import AppContext from "../../RouteContext";
import ProgressSnackbar from "../shared-components/ProgressSnackbar";
import LoggedInComponents from "./components/LoggedInComponents";
import NavbarWrapperLayout1 from "./components/NavbarWrapperLayout1";
import ToolbarLayout1 from "./components/ToolbarLayout1";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        "&.boxed": {
            maxWidth: 1280,
            margin: "0 auto",
            boxShadow: theme.shadows[3],
        },
        "&.scroll-body": {
            "& $wrapper": {
                height: "auto",
                flex: "0 0 auto",
                overflow: "auto",
            },
            "& $contentWrapper": {},
            "& $content": {},
        },
        "&.scroll-content": {
            "& $wrapper": {},
            "& $contentWrapper": {},
            "& $content": {},
        },
        "& .navigation": {
            "& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon": {
                transition: theme.transitions.create("opacity", {
                    duration: theme.transitions.duration.shortest,
                    easing: theme.transitions.easing.easeInOut,
                }),
            },
        },
    },
    wrapper: {
        display: "flex",
        position: "relative",
        width: "100%",
        height: "100%",
        flex: "1 1 auto",
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        zIndex: 3,
        overflow: "hidden",
        flex: "1 1 auto",
    },
    content: {
        position: "relative",
        display: "flex",
        overflow: "auto",
        flex: "1 1 auto",
        flexDirection: "column",
        width: "100%",
        "-webkit-overflow-scrolling": "touch",
        zIndex: 2,
    },
}));

function Layout1(props) {
    const config = useSelector(({fuse}) => fuse.settings.current.layout.config, shallowEqual);

    const appContext = useContext(AppContext);

    const classes = useStyles(props);

    const isLoggedIn = useSelector(({user}) => user.loggedIn);

    const {routes} = appContext;

    //    return (
    //        <div id="fuse-layout" className={clsx(classes.root, config.mode, "scroll-" + config.scroll)}>
    //            <div className="flex flex-1 flex-col overflow-hidden relative">
    //                {config.toolbar.display && config.toolbar.style === "fixed" && config.toolbar.position === "above" && isLoggedIn && (
    //                    <ToolbarLayout1 />
    //                )}
    //
    //                <FuseScrollbars className="overflow-auto" scrollToTopOnChildChange>
    //                    {config.toolbar.display && config.toolbar.style !== "fixed" && config.toolbar.position === "above" && isLoggedIn && (
    //                        <ToolbarLayout1 />
    //                    )}
    //
    //                    <div className={classes.wrapper}>
    //                        {config.navbar.display && config.navbar.position === "left" && isLoggedIn && <NavbarWrapperLayout1 />}
    //
    //                        <div className={classes.contentWrapper}>
    //                            {config.toolbar.display && config.toolbar.position === "below" && isLoggedIn && <ToolbarLayout1 />}
    //
    //                            <div className={classes.content + "  pb-5f6 md:pb-0"}>
    //                                <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>
    //
    //                                {props.children}
    //                            </div>
    //
    //                            {/* {config.footer.display && config.footer.position === "below" && isLoggedIn && <FooterLayout1 />} */}
    //
    //                            <FooterLayout1 />
    //                            {/*<SettingsPanel/>*/}
    //                        </div>
    //
    //                        {config.navbar.display && config.navbar.position === "right" && isLoggedIn && <NavbarWrapperLayout1 />}
    //                    </div>
    //
    //                    {/* {config.footer.display && config.footer.style !== "fixed" && config.footer.position === "above" && isLoggedIn && ( */}
    //                    {/*     <FooterLayout1 /> */}
    //                    {/* )} */}
    //                </FuseScrollbars>
    //                {config.footer.display && isLoggedIn && <FooterLayout1 />}
    //            </div>
    //
    //            {isLoggedIn && <LoggedInComponents />}
    //
    //            <ProgressSnackbar />
    //            <FuseMessage />
    //        </div>
    //    );

    return (
        <div id="fuse-layout" className={clsx(classes.root, config.mode, "scroll-" + config.scroll)}>
            <div className="flex flex-1 flex-col overflow-hidden relative">
                {config.toolbar.display && config.toolbar.position === "above" && isLoggedIn && <ToolbarLayout1 />}

                <div className={classes.wrapper}>
                    {config.navbar.display && config.navbar.position === "left" && isLoggedIn && <NavbarWrapperLayout1 />}

                    <div className={classes.contentWrapper}>
                        {config.toolbar.display && config.toolbar.position === "below" && config.toolbar.style === "fixed" && isLoggedIn && (
                            <ToolbarLayout1 />
                        )}

                        <FuseScrollbars
                            className={
                                // classes.content+ "  pb-56 md:pb-0"
                                clsx(classes.content, " pb-5f6 md:pb-0")
                            }
                            scrollToTopOnChildChange>
                            {config.toolbar.display && config.toolbar.position === "below" && config.toolbar.style !== "fixed" && isLoggedIn && (
                                <ToolbarLayout1 />
                            )}

                            <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

                            {props.children}

                            {/* {config.footer.display && config.footer.position === "below" && config.footer.style !== "fixed" && isLoggedIn && ( */}
                            {/*     <FooterLayout1 /> */}
                            {/* )} */}
                        </FuseScrollbars>

                        {/* {config.footer.display && config.footer.position === "below" && config.footer.style === "fixed" && isLoggedIn && ( */}
                        {/*     <FooterLayout1 /> */}
                        {/* )} */}
                        {/* {config.newFooter && isLoggedIn && <FooterLayout1 />} */}
                        {/*<SettingsPanel/>*/}
                    </div>

                    {config.navbar.display && config.navbar.position === "right" && isLoggedIn && <NavbarWrapperLayout1 />}
                </div>
            </div>
            {isLoggedIn && <LoggedInComponents />}
            <ProgressSnackbar />
            <Toaster
                position="bottom-left"
                toastOptions={{
                    duration: 3000,
                }}
            />
            <FuseMessage />
        </div>
    );
}

export default memo(Layout1);
