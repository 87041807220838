import {ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import {MyTooltip} from "@ui";
import {memo} from "react";

import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import Empty from "utils/Empty";
import EventBus, {UPDATE_WORK_HISTORIES_NOTIFI} from "utils/EventBus";
import axios, {crmPrefix} from "../../../../../myaxios";
import * as Actions from "../store/actions";

function SubItem({item}) {

    const dispatch = useDispatch();

    const deleteWorkHistory = () => {
        const language = this.props.activeLanguage.code;
        const tTtile = language === "en" ? "Do you want to delete this workHistory?" : "确定要删除该工作记录吗?";
        const tConfirmButtonText = language === "en" ? "Delete" : "删除";
        const tSuccessM = language === "en" ? "Delete success!" : "删除成功!";
        const successFunc = (success) => {
            dispatch(Actions.selectItemForEditCompany(null));
            EventBus.dispatch(UPDATE_WORK_HISTORIES_NOTIFI, UPDATE_WORK_HISTORIES_NOTIFI);
            if (success instanceof Function) {
                success();
            }
        };
        const request = (success, failed) => {
            axios.delete(`${crmPrefix}/contactWorkHistories/${item.id}`).then(res => {
                if (success instanceof Function) {
                    success();
                }
            }).catch(() => {
                if (failed instanceof Function) {
                    failed();
                }
            });
        };
        this.props.showMyDialog(tTtile, request, successFunc, tSuccessM, tConfirmButtonText);
        this.props.turnOn("MyDialog");
    };

    return <ListItem>

        <ListItemText
            primary={
                <>
                    <MyTooltip title={<Translate id={"market.company"} />}>
                        <Typography variant="body2">
                            {item.company ? item.company.name : <Translate id={"market.no company data"} />}
                        </Typography>
                    </MyTooltip>
                    <MyTooltip title={<Translate id={"market.department"} />}>
                        <Typography variant="caption" display="block">
                            ({item.deparment ? item.deparment : <Translate id={"market.no department data"} />})
                        </Typography>
                    </MyTooltip>
                </>
            }
        />
        <ListItemSecondaryAction>
            <MyTooltip title={<Translate id={"_.delete"} />}>
                <IconButton onClick={e => {
                    e.stopPropagation();
                    deleteWorkHistory();
                }}><Icon>delete</Icon></IconButton>
            </MyTooltip>
        </ListItemSecondaryAction>
    </ListItem>;
}


function WorkHistory({datas}) {

    return (
        <>
            <Card className={"mb-0"}>
                {<Translate>
                    {({translate}) =>
                        <List
                            dense
                            subheader={
                                <ListSubheader className={"flex items-center justify-between"}>
                                    <span>{translate("market.workHistory")} ({datas ? datas.length : 0})</span>
                                </ListSubheader>
                            }>
                            {datas && datas.length > 0 ? datas.map(__ => <SubItem key={__.id} item={__} />) : <Empty noIcon />}
                        </List>
                    }
                </Translate>}

            </Card>
        </>

    );
}

export default memo(WorkHistory);
