import { GET_PROJECT, GET_PROJECT_STAGES, SET_ACTIVE_STAGE, GET_STAGE_HISTORY, HANDLE_NEXT_STAGE, HANDLE_FIRST_OPEN_STAGE } from "../actions/index";

const initialState = {
    data: null,
    searchText: "",
    activeStage: null,
    stageHistory: [],
    stages: []
};

const reducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_PROJECT: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_PROJECT_STAGES: {
            return {
                ...state,
                stages: action.payload
            };
        }
        case SET_ACTIVE_STAGE: {
            return {
                ...state,
                activeStage: action.stage
            };
        }
        case GET_STAGE_HISTORY: {
            return {
                ...state,
                stageHistory: action.payload
            };
        }
        case HANDLE_NEXT_STAGE: {
            let activeStep;

            if ((state.stages.length - 1) === state.activeStage && state.activeStage !== -1) {
                const steps = state.stages;
                activeStep = steps.findIndex((step) => step.status === "OPEN");
            } else {
                activeStep = state.activeStage + 1;
            }
            return {
                ...state,
                activeStage: activeStep
            };
        }
        case HANDLE_FIRST_OPEN_STAGE: {
            let activeStep;
            const steps = state.stages;
            activeStep = steps.findIndex((step) => step.status === "OPEN");
            return {
                ...state,
                activeStage: activeStep,
                stageHistory: [],
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
