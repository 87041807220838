import {Icon, IconButton, Input, Typography} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import {useContext, useEffect, useState} from "react";
import {INCOME_LOCAL_ID, MatchingOrderContext} from "../../state/constant";
import {useIncomeStore} from "../../state";
import {useSelector} from "react-redux";
import {ThemeProvider} from "@material-ui/styles";
import {useDebounceFn, useLocalStorageState} from "ahooks";
import {forEach} from "lodash";
import Card from "@material-ui/core/Card";

function NewMatchingHeader(props) {
    const context = useContext(MatchingOrderContext);
    const matchingRequest = context?.matchingRequest;
    const [incomeId, setIncomeId] = useLocalStorageState(INCOME_LOCAL_ID);
    const [keyword, setKeyword] = useState("");

    const {matchingOrdersCount, onChangeMatchingOrdersCount} = useIncomeStore();
    const mainTheme = useSelector(({fuse}) => fuse.settings.mainTheme);

    const debounceActions = useDebounceFn(
        () => {
            const params =
                keyword === ""
                    ? {
                          incomeId: incomeId,
                      }
                    : {
                          incomeId: incomeId,
                          keyword: keyword,
                      };
            matchingRequest?.run(params).then((res) => {});
        },
        {wait: 500}
    );

    useEffect(() => {
        matchingRequest?.data?.data && onUpdateMatchingOrdersCount();
    }, [matchingRequest?.data?.data]);

    const onUpdateMatchingOrdersCount = () => {
        const matchingOrders = matchingRequest?.data?.data;
        let _resOrders = [];
        forEach(matchingOrders, (item) => {
            item.orders.length > 0 && _resOrders.push(item.orders);
        });
        onChangeMatchingOrdersCount(_resOrders.length);
    };

    const onSearch = (e) => {
        setKeyword(e.target.value);
        debounceActions.run();
    };

    const handleCloseDialog = (e) => {
        e.stopPropagation();
        context?.onCloseDialog();
    };

    return (
        <div className="flex flex-1 flex-row justify-between items-center">
            <div className="flex items-center justify-start pl-12">
                <Typography className={"inline-flex"} variant="h5">
                    {<Translate id={"income.auto matching orders"} />}
                    <small className={"ml-1"}>({matchingOrdersCount})</small>
                </Typography>
                <div className="flex flex-1 items-center ml-5 sm:px-12">
                    <ThemeProvider theme={mainTheme}>
                        <Card className="flex flex-1 items-center max-w-512 px-8 py-4 rounded-8">
                            <Icon className="mr-8" color="action">
                                search
                            </Icon>
                            {
                                <Translate>
                                    {({translate}) => (
                                        <Input
                                            placeholder={translate("income.Search based on keyword")}
                                            className="flex flex-1"
                                            disableUnderline
                                            fullWidth
                                            value={keyword}
                                            inputProps={{
                                                "aria-label": "Search",
                                            }}
                                            onChange={onSearch}
                                        />
                                    )}
                                </Translate>
                            }
                        </Card>
                    </ThemeProvider>
                </div>
            </div>

            <div className="flex items-center justify-end pr-12">
                <IconButton onClick={handleCloseDialog}>
                    <Icon>close</Icon>
                </IconButton>
            </div>
        </div>
    );
}

export default NewMatchingHeader;
