/*
*  useFetchUserTasks
* */

import {useSelector} from "react-redux";
import {useMemoizedFn} from "ahooks";

/**
 *  @param {Object} options
 *  @param {string} options.identifier
 */
function useLocalParam(options) {

    const search = useSelector(({fuse}) => fuse.search);

    const readLocalParams = useMemoizedFn(()=>{
        return localStorage.getItem(`${options.identifier}`);
    });

    /**
     * @param {Object} pameter
     * @param {number} pameter.page
     * @param {number} pameter.size
     * @param {string} pameter.keyword
     * .....
     */
    const saveFilterToLocal = useMemoizedFn((pameter) => {
        return new Promise((resolve)=>{
            const jsonString = JSON.stringify(pameter);
            localStorage.setItem(options.identifier,jsonString);
            resolve();
        });
    });

    /**
     * @param {string} key
     */
    const removeFilterFromLocal = useMemoizedFn((key) => {
        return new Promise((resolve)=>{
            const param =  search;
            delete param[key];
            const jsonString = JSON.stringify(param);
            localStorage.setItem(options.identifier,jsonString);
            resolve();
        });
    });


    return {
        readLocalParams,
        saveFilterToLocal,
        removeFilterFromLocal
    };
}

export default useLocalParam;
