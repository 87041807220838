export const createSingleSlice = (set, get) => ({
    currentInstance: null,
    /**
     * @param {Object} selectedInstanceItem
     * @description - 在实例详情中删除当前流程实例时，保存的当前的流程实例
     */
    selectedInstanceItem: null,
    /**
     * @param {Object} instanceVariable
     * @param {Object} instanceVariable.variable
     * @param {Object} instanceVariable.instance
     */
    instanceVariable: null,
    onChangeSingleSelectedInstance: (data) => {
        set({
            selectedInstanceItem: data,
        });
    },
    onChangeInstanceVariable: (data) => {
        set({
            instanceVariable: data,
        });
    },
    onChangeCurrentInstance: (data) => {
        set({
            currentInstance: data,
        });
    },
});
