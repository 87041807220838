import {finishLoading, startLoading} from "@fuseActions";
import axios, {financialPrefix} from "../../../../../myaxios";

export const GET_INCOME_ORDERS_DISTRIBUTIONS = "[GET_INCOME_ORDERS_DISTRIBUTIONS]";

export const SELECTED_A_INCOME_ORDERS_DISTRIBUTION = "[SELECTED_A_INCOME_ORDERS_DISTRIBUTION]";

export const SELECTED_A_INCOMEID_FOR_ORDERS_DISTRIBUTION = "[SELECTED_A_INCOMEID_FOR_ORDERS_DISTRIBUTION]";

export function getIncomeOrdersDistributions(params, callback) {
    const request = params
        ? axios.get(`${financialPrefix}/incomeOrders`, {params})
        : axios.get(`${financialPrefix}/incomeOrders`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_INCOME_ORDERS_DISTRIBUTIONS,
                payload: res.data
            });
            dispatch(finishLoading());
            if (callback instanceof Function) {
                callback(res.data.content ? res.data.content : []);
            }
        });
    };
}

export function selectAIncomeID(incomeID) {
    return {
        type: SELECTED_A_INCOMEID_FOR_ORDERS_DISTRIBUTION,
        payload: incomeID
    };
}
