import {Component} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import {withRouter} from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button, DialogContent, Icon} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid/Grid';
import axios, {pmsPrefix, scmPrefix} from '../../../../../myaxios';
import {bindActionCreators} from 'redux';
import {showMessage, turnOff, turnOn} from 'app/store/actions';
import {getProjects, getTemplates} from '../../store/actions';
import {connect} from 'react-redux';
import AsyncSelect from '../../../../../utils/Forms/AsyncSelect';

import withDialog from 'utils/withDialog';

export const ProjectItemView = ({item}) => {
    return (
        <div>
            <div className={"nowrap font-bold"}>
                {item.name} {item.clientName}
            </div>
            {item.address && <div className={"text-10"}>{item.address}</div>}
        </div>
    );
};

//export const searchOAProject = (inputValue, callback) => {
//    axios
//        .get(`${scmPrefix}/ermProjects?size=8`, {
//            params: {
//                keyword: inputValue,
//            },
//        })
//        .then((res) => {
//            const data = res.data?.content?.map((item) => ({
//                value: item.id,
//                oaProject: item,
//                label: <ProjectItemView item={item} />,
//            }));
//            callback(data);
//        });
//};

class ImportProjectDialog extends Component {
    state = {
        templateId: null,
        project: {value: "", label: ""},
        loading: false,
        oaProject: null,
    };

    componentDidMount = () => {
        this.props.getTemplates({status: "ENABLE"});
    };

    onSubmit = () => {
        const {oaProject, templateId} = this.state;
        const data = {
            oaProject,
            templateId,
        };
        axios.post(`${pmsPrefix}/importProjects`, data).then(() => {
            this.props.turnOff("importProject");
            this.props.getProjects(this.props.search);
            this.props.showMessage({message: "message.Imported", variant: "success"});
        });
    };

    handleChange = (name) => (event) => {
        if (event.target.value === "add") this.props.turnOn("template");
        else this.setState({[name]: event.target.value});
    };

    changeProject = (e, value) => {
        if (value)
            this.setState({
                oaProject: value,
            });
        else
            this.setState({
                oaProject: null,
            });
    };

    onClose = () => {
        this.props.turnOff("importProject");
    };

    render() {
        const {templateId} = this.state;
        const {templates} = this.props;

        return (
            <>
                <DialogTitle>{<Translate id={"crm.Import Project"} />}</DialogTitle>

                <DialogContent className={"overflow-visible"}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <AsyncSelect
                                link={`${scmPrefix}/ermProjects?size=8`}
                                textFieldProps={{label: <Translate id="crm.oa project" />}}
                                getOptionLabel={(item) => item.name}
                                onChange={this.changeProject}
                                renderOption={(item) => <ProjectItemView item={item} />}
                            />

                            {/*<Translate>*/}
                            {/*    {({translate}) => <Select2*/}
                            {/*        required*/}
                            {/*        textFieldProps={{*/}
                            {/*            label: translate('crm.oa project'),*/}
                            {/*            InputLabelProps: {*/}
                            {/*                shrink: true,*/}
                            {/*                required: true*/}
                            {/*            },*/}
                            {/*            required: true*/}
                            {/*        }}*/}
                            {/*        loadOptions={searchOAProject}*/}
                            {/*        defaultOptions*/}
                            {/*        value={project}*/}
                            {/*        classes={classes}*/}
                            {/*        // styles={selectStyles}*/}
                            {/*        components={components}*/}
                            {/*        onChange={this.changeProject}*/}
                            {/*        placeholder={translate("crm.Search old project")}*/}
                            {/*        isClearable*/}
                            {/*    />}*/}
                            {/*</Translate>*/}
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <FormControl className={"w-full"} fullWidth>
                                <InputLabel htmlFor="template-simple-address">
                                    <Translate id="crm.Template" />
                                </InputLabel>
                                <Select
                                    value={templateId}
                                    onChange={this.handleChange("templateId")}
                                    inputProps={{
                                        name: "templateId",
                                        id: "template-simple-address",
                                    }}
                                    className={"MuiInput-formControl w-full"}
                                >
                                    <MenuItem value="">
                                        <em>{<Translate id={"_.None"} />}</em>
                                    </MenuItem>
                                    <MenuItem value="add">
                                        <Icon className={"mr-8"}>add</Icon>
                                        <em>
                                            <Translate id="crm.Add new template" />
                                        </em>
                                    </MenuItem>
                                    {templates.map((_) => (
                                        <MenuItem key={_.id} value={_.id}>
                                            {_.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.onClose}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button variant={"contained"} color="primary" onClick={this.onSubmit}>
                        <Translate id="_.Import" />
                    </Button>
                </DialogActions>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: turnOff,
            turnOn: turnOn,
            showMessage: showMessage,
            getTemplates: getTemplates,
            getProjects: getProjects,
        },
        dispatch
    );
}

function mapStateToProps({fuse, commerce}) {
    return {
        search: fuse.search,
        templates: commerce.templates.content,
    };
}
export default withDialog(
    "importProject",
    "sm"
)(withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ImportProjectDialog))));
