import {Button, Dialog, DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import * as fuseActions from "@fuseActions";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class FullImage extends Component {
    render() {
        const {turnOff, dialogs} = this.props;
        const selectedImage = dialogs.selectedImage;
        return (
            selectedImage && (
                <Dialog
                    maxWidth={"xl"}
                    open={!!dialogs["fullImage"]}
                    onClose={() => {
                        turnOff("fullImage");
                    }}
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className={" md:flex justify-between"}>{selectedImage.title}</div>
                    </DialogTitle>
                    <DialogContent className={"p-0 text-center"}>
                        <a target={"_blank"} href={selectedImage.src}>
                            <img alt={selectedImage.title} src={selectedImage.src} />
                        </a>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                turnOff("fullImage");
                            }}
                        >
                            <Translate id="_.close" />
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff
        },
        dispatch
    );
}

function mapStateToProps({fuse, user}) {
    return {
        user: user,
        dialogs: fuse.dialogs
    };
}

export default withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(FullImage)));
