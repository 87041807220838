import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../db";
import type {DGRowType} from "../types";
import type {DGSettingsType} from "./hooks";

const useDatagrids = (id: string) => {
    const dataGrid = useLiveQuery(() => db.dataGrids.where("id").equals(id).first());

    const addDataGrid = async (datagrid: DGSettingsType, rows?: DGRowType[]) => {
        try {
            await db.dataGrids.add(datagrid);
            console.log("added successfully");
        } catch (error) {
            console.error("Error  add:", error);
        }
        if (rows) {
            // const temp: {colId: string; value: any; rowId: string}[] = [];
            // for (const row of rows) {
            //     for (const [colId, value] of Object.entries(row)) {
            //         temp.push({colId, value, rowId: row.id});
            //     }
            // }
            // await db.dataGridCells.bulkAdd(temp);
            try {
                await db.dataGridRows.bulkPut(rows.map((x) => ({...x, dataGridId: id})));
                console.log("addDataGrid, bulkAdd saved successfully!");
            } catch (error) {
                console.error("Error bulkAdd data:", error);
            }
        }
    };

    const updateDataGrid = async (changes: Partial<DGSettingsType>, rows?: DGRowType[]) => {
        try {
            if (id) {
                await db.dataGrids.update(id, changes);
                // console.log(changes, "changes");

                if (rows) {
                    await db.dataGridRows.bulkPut(rows);
                    console.log("updateDataGrid bulkPut Data saved successfully!");
                }
            }
        } catch (error) {
            console.error("Error bulkPut data:", error);
        }
    };

    const clearConflict = async () => {
        try {
            if (id) {
                await db.dataGrids.update(id, {lastModified: undefined});
                console.log("Cleared conflict");
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const clearRowConflict = async ({activeTime, ...row}: DGRowType) => {
        console.log(activeTime, row);
        try {
            if (id) {
                await db.dataGridRows.put(row);
                console.log("Cleared conflict");
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const deleteRows = async (ids: string[]) => {
        try {
            await db.dataGridRows.bulkDelete(ids);

            console.error("deleted rows :", ids);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    return {
        addDataGrid,
        dataGrid,
        updateDataGrid,
        deleteRows,
        clearConflict,
        clearRowConflict,
    };
};

export default useDatagrids;
