import {useState} from "react";
import Grid from "@material-ui/core/Grid/Grid";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import {Button, IconButton, InputAdornment} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {EmptyView, MyAvatar} from "@ui";
import type {userType} from "../../../types";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {useTransferList} from "./useTransferList";
import {useTranslation} from "react-i18next";

const UserListItem = ({user, removeUser}: {user: userType; removeUser: (user: userType) => void}) => {
    return (
        <ListItem
            button
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                removeUser(user);
            }}
            key={user.id}>
            <ListItemAvatar>
                <MyAvatar user={user} />
            </ListItemAvatar>
            <ListItemText primary={user.lastName + user.firstName} secondary={user.username} />
            <Icon className={"text-red"}>delete</Icon>
        </ListItem>
    );
};

const SelectedUsers = () => {
    const {t} = useTranslation();
    const {removeAll, selected, remove} = useTransferList();
    const [searchedUsers, setSearchedUsers] = useState<userType[]>();

    const [selectedUsersSearch, setSelectedUsersSearch] = useState<string>();

    const clearSearchSelected = () => {
        setSelectedUsersSearch("");
    };

    const removeUser = (user: userType) => {
        remove(user);
        if (selectedUsersSearch) {
            const oldSearchedUsers = searchedUsers ? [...searchedUsers] : [];
            const searched = oldSearchedUsers.filter((_u) => _u.id !== user.id);
            setSearchedUsers(searched);
        }
    };

    const onChangeSelectedUserSearch = (e) => {
        const text = e.target.value;
        if (text.trim !== "") {
            setSelectedUsersSearch(e.target.value);
            const res = selected?.filter((user) => Object.values(user).some((val) => val.includes(e.target.value)));
            setSearchedUsers(res);
        } else {
            setSelectedUsersSearch(undefined);
            setSearchedUsers(undefined);
        }
    };

    return (
        <Grid item md={5} className="overflow-y-auto" style={{maxHeight: "calc(100vh -  200px)"}} xs={12}>
            <List
                subheader={
                    <ListSubheader className={"bg-white justify-between flex"}>
                        {t("userSelector.selectedUsers")} ({selected ? selected.length : 0})
                        <div>
                            <Button
                                disabled={!selected || selected.length === 0}
                                variant={"contained"}
                                color={"primary"}
                                size={"small"}
                                onClick={() => {
                                    removeAll();
                                    setSearchedUsers(undefined);
                                    setSelectedUsersSearch(undefined);
                                }}>
                                {t("userSelector.removeAll")}
                            </Button>
                        </div>
                    </ListSubheader>
                }
                dense>
                {selected && selected.length > 0 && (
                    <ListItem>
                        <TextField
                            placeholder={t("Search")}
                            InputProps={{
                                endAdornment: selectedUsersSearch && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={clearSearchSelected}>
                                            <Icon fontSize={"small"}>close</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize={"small"}>search</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            className={"md:mt-0 mb-12"}
                            value={selectedUsersSearch || ""}
                            onChange={onChangeSelectedUserSearch}
                        />
                    </ListItem>
                )}

                {selectedUsersSearch ? (
                    searchedUsers && searchedUsers.length > 0 ? (
                        searchedUsers.map((_user) => <UserListItem key={_user.id} user={_user} removeUser={removeUser} />)
                    ) : (
                        <EmptyView label={t("userSelector.nothingFound")} />
                    )
                ) : selected && selected.length > 0 ? (
                    selected.map((_user) => <UserListItem key={_user.id} user={_user} removeUser={removeUser} />)
                ) : (
                    <EmptyView label={t("userSelector.noSelected")} />
                )}
            </List>
        </Grid>
    );
};

export default SelectedUsers;
