import {lazy} from "react";

export const SCMsAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: "/ledger/:id",
            component: lazy(() => import("./ledger/single/Page"))
        },
        {
            path: "/ledger",
            component: lazy(() => import("./ledger/list/Page"))
        },
        {
            path: "/checkpoints",
            component: lazy(() => import("./checkpoints/Page"))
        },
        {
            path: "/SCM/:id/",
            component: lazy(() => import("./tasks/single/Page"))
        },
        {
            path: "/SCMOld/:oaRecordId/",
            component: lazy(() => import("./tasks/single/Page"))
        },
        {
            path: "/productionTask/:id/",
            component: lazy(() => import("./tasks/single/Page"))
        },
        {
            path: "/SCMs",
            component: lazy(() => import("./tasks/list/Page"))
        },
        {
            path: "/productionTask",
            component: lazy(() => import("./tasks/list/Page"))
        },
        {
            path: "/productionList",
            component: lazy(() => import("./lists/Page"))
        },
        {
            path: "/productionReports",
            component: lazy(() => import("./reports/Page"))
        },
        {
            path: "/productionCount",
            component: lazy(() => import("./counts/Page"))
        }
    ]
};
