/**
 - fixme Costomize col order
 */
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import {SubmitButton} from "@ui";
import {useLiveQuery} from "dexie-react-hooks";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {db} from "../db";
import {useDGDef} from "../hooks/hooks";
import type {DGColType} from "../types";
import DGColForm from "./DGColForm";
import DGCustomizeCols from "./DGCustomizeCols";
import DGShare from "./DGShare";
import {downloadJsonExcel} from "../../../../app/main/incomes/utils/ExcelUtils";
import toast from "react-hot-toast";

const DGSettings = ({onClickAdd}: {onClickAdd(): void}) => {
    // region hooks
    const {t} = useTranslation(["datagrid", "_", "errors"]);
    const {cols, onAddCol, permission, title, fullscreen, onChangeSetting, isOwner, id} = useDGDef();

    // endregion

    // region states
    const [labelError, setLabelError] = useState<string>();
    const [newColEl, setNewColEl] = useState<HTMLButtonElement | null>(null);
    const [tempCol, setTempCol] = useState<Omit<DGColType, "id">>({
        label: "",
        viewType: "text",
    });
    // endregion

    const rowsCount = useLiveQuery(() => (id ? db.dataGridRows.where("dataGridId").equals(id).count() : 0));

    const uniqueLabel = (label: string | undefined) => {
        return cols.some((x) => !x.deleted && x.label === label);
    };

    function saveCol() {
        if (uniqueLabel(tempCol.label)) {
            setLabelError(t("uniqueLabel"));
            return;
        }
        setLabelError(undefined);

        if (tempCol.viewType === "singleOption") {
            const t = {...tempCol};
            t.options = tempCol.options?.filter((c) => c !== "");
            onAddCol(t);
        } else {
            onAddCol(tempCol);
        }

        setTempCol({
            label: "",
            viewType: "text",
        });

        setNewColEl(null);
    }

    const onExportExcel = async () => {
        const rows = await db.dataGridRows
            .where("dataGridId")
            .equals(id)
            .toArray((rowss) => rowss.map(({id, dataGridId, ...rest}) => rest));

        if (rows)
            downloadJsonExcel(
                rows,
                cols.map((x) => !x.deleted && x.label),
                title
            );
        else {
            toast.error(t("errors:noData"));
        }
    };

    return (
        <div className={"bg-white flex-wrap flex justify-between border-t-1 border-l-1 border-r-1 border-gray-200 items-center px-12 py-8"}>
            {/* region left header title settings add */}
            <div className="flex items-center">
                {/* region title */}
                <Typography variant={"h6"} className={"mr-20"}>
                    {title}
                </Typography>
                {/* endregion */}
                {/* region add row */}
                {((permission !== "readonly" && permission !== "disabled") || isOwner) && (
                    <Button
                        disabled={cols.length === 0}
                        variant={"text"}
                        size={"small"}
                        className={"mr-12"}
                        startIcon={<Icon children="playlist_add" />}
                        onClick={onClickAdd}>
                        {t("addRow")} ({rowsCount})
                    </Button>
                )}
                {/* endregion */}
                {/* region add col */}
                {(permission === "manage" || isOwner) && (
                    <>
                        <Button
                            style={{cursor: "cell"}}
                            variant={"text"}
                            size={"small"}
                            className={"mr-12"}
                            startIcon={<Icon children="add_box" />}
                            onClick={(e) => setNewColEl(e.currentTarget)}>
                            {t("addColumn")} ({cols?.length})
                        </Button>
                        <Popover onClose={() => setNewColEl(null)} open={Boolean(newColEl)} anchorEl={newColEl}>
                            <DGColForm errors={{label: labelError}} tempCol={tempCol} setTempCol={setTempCol} />

                            <div className={"px-12 py-12 mb-4 flex justify-end"}>
                                <SubmitButton disabled={tempCol.label?.trim() === ""} success size={"small"} className={"ml-8"} onClick={saveCol}>
                                    {t("save")}
                                </SubmitButton>
                            </div>
                        </Popover>
                    </>
                )}
                {/* endregion */}
                {cols.length > 0 && <DGCustomizeCols />}
            </div>
            {/* endregion */}
            <div>
                {/*{isOwner &&  <SubmitButton onClick={toggleStatus} size={"small"} startIcon={<Icon children={"toggle_off"} />} success={permission==="disabled"} error={permission!=="disabled"}>*/}
                {/*    {t("disableDatagrid")}*/}
                {/*</SubmitButton>*/}
                {/*}*/}
                {cols.length > 0 && (
                    <Button onClick={onExportExcel} startIcon={<Icon>download</Icon>}>
                        {t("excelBtn")}
                    </Button>
                )}
                {isOwner && <DGShare />}
                <IconButton onClick={() => onChangeSetting("fullscreen", !fullscreen)}>
                    <Icon children={fullscreen ? "fullscreen_exit" : "fullscreen"} />
                </IconButton>{" "}
            </div>
        </div>
    );
};

export default DGSettings;
