import {lazy} from "react";

// noinspection JSCheckFunctionSignatures,JSCheckFunctionSignatures,JSCheckFunctionSignatures,JSCheckFunctionSignatures,JSCheckFunctionSignatures
export const StockAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/stockTransfers",
            component: lazy(() =>
                import("./stockTransfers/Page")
            )
        },
        {
            path     : "/bonusRequests",
            component: lazy(() =>
                import("./bonusRequests/Page")
            )
        },
        {
            path     : "/userAssets",
            component: lazy(() =>
                import("./userAssets/Page")
            )
        },
        {
            path     : "/stockOrders",
            component: lazy(() =>
                import("./stockOrders/Page")
            )
        },
        {
            path     : "/buildBonusData",
            component: lazy(() =>
                import("./buildBonusData/Page")
            )
        },
    ]
};
