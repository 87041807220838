import axios, {commonPrefix} from "../../../../../myaxios";

export const GET_GENERATED_QRCODE = "[GET_GENERATED_QRCODE]";

export const SELECT_A_QRCODE = "[SELECT_A_QRCODE]";


export function getGeneratedQRCode(params) {

    const request = axios.get(`${commonPrefix}/extConstQRCodes`,{params});

    return (dispatch) => {
        request.then(response => {
            dispatch({
                type: GET_GENERATED_QRCODE,
                payload: response.data
            });
        });
    };
}


export function selectACRCode(_code) {
    return {
        type: SELECT_A_QRCODE,
        payload: _code
    };
}


