import { GET_QUICK_PANEL_DATA, TOGGLE_QUICK_PANEL, CLEAR_SELECTED, SEEN_NOTIFICATION, MARK_READ_ALL, MARK_READ_GROUP, GET_NOTIFICATION } from "../actions";

const initialState = {
    state: false,
    data: null,
    notifications: [
        // {
        //     'priority': 2,
        //     'count': 1,
        //     content: "This is notification detail",
        //     title: "This is notification title",
        //     time: "string($date-time)",
        //     user: {
        //         id: "fsdfs",
        //         firstName: "Mike",
        //         avatar: "fsdf",
        //         lastName: "Last Name",
        //         username: "sfd",
        //     },
        //     action: "joined",
        //     type: "ACTION_BY_USER",     // ['requestJoinFirm','joinFirmAccepted',
        //     // 'requestJoinWhiteboard' ,'joinFirmAccepted',
        //     // 'requestSendLocation' ,'sendLocationAccepted',
        //     // 'requestFillForm' ,'fillFormAccepted',]
        //
        //     sourceType  : "firm",    // ['project','firm', 'whiteboard', 'form']
        //     sourceType    : "56654",     // ['56654','65454']
        // }
    ],
    selected: null,
    priority: 0,
    ntfNumber: 0,
};

export const groupingNotifications = (unGrouped) => {
    let grouped = [];
    // eslint-disable-next-line array-callback-return
    unGrouped.map((item, i) => {
        if (i === 0) {
            if (item.sourceType) {
                grouped.push({
                    priority: 0,
                    seen: true,
                    isType: true,
                    sourceType: item.sourceType,
                    title: item.sourceType,
                    subNotif: [item],
                });
            } else {
                grouped.push(item);
            }
        } else {
            if (item.sourceType) {
                const index = grouped.findIndex((n) => n.sourceType === item.sourceType);
                if (index > -1) {
                    const sub = grouped[index].subNotif || [];
                    const index2 = sub.findIndex((n) => n.sourceId === item.sourceId);
                    if (index2 > -1) {
                        const sub2 = grouped[index].subNotif[index2].subNotif || [];
                        grouped[index].subNotif[index2] = {
                            ...grouped[index].subNotif[index2],
                            subNotif: [...sub2, item],
                        };
                    } else {
                        grouped[index] = {
                            ...grouped[index],
                            subNotif: [...sub, item],
                        };
                    }
                } else {
                    grouped.push({
                        priority: 0,
                        seen: true,
                        isType: true,
                        title: item.sourceType,
                        sourceType: item.sourceType,
                        subNotif: [item],
                    });
                }
            } else {
                grouped.push(item);
            }
        }
    });
    // console.log(grouped);
    return grouped;
};

export const unGroupingNotifications = (grouped) => {
    let unGrouped = [];
    // eslint-disable-next-line array-callback-return
    grouped.map((item) => {
        const k = {...item};
        k.subNotif = [];
        if (!k.isType) unGrouped.push(k);
        if (!!item.subNotif && item.subNotif.length > 0) {
            // eslint-disable-next-line array-callback-return
            item.subNotif.map((o) => {
                if (!!o.subNotif && o.subNotif.length > 0) {
                    o.subNotif.map((e) => {
                        if (!e.isType) unGrouped.push(e);
                    });
                } else {
                    if (!o.isType) unGrouped.push(o);
                }
            });
        }
    });
    return unGrouped;
};

const receiveNotification = (state, action) => {
    const ntfNumber = parseInt(state.ntfNumber) + parseInt(1);
    // const sourceIndex = state.notifications.findIndex(i=> i.sourceType === action.ntf.sourceType);
    // let notifications;
    // if(sourceIndex > -1) {
    //     notifications = [...state.notifications];
    //     const sub = notifications[sourceIndex].subNotif || [];
    //     const k = {...notifications[sourceIndex]};
    //     delete k.subNotif;
    //     notifications.splice(sourceIndex, 1);
    //     notifications = [
    //         {
    //             ...action.ntf,
    //             subNotif : [
    //                 k,
    //                 ...sub
    //             ]
    //         },
    //         ...notifications
    //     ]
    // } else {
    //     notifications = [
    //         action.ntf,
    //         ...state.notifications
    //     ];
    // }
    let t = unGroupingNotifications(state.notifications);
    // console.log(action.ntf);
    // console.log(t);
    t.push(action.ntf);
    // console.log(t);
    const notifications = groupingNotifications(t);
    // console.log(notifications);

    return {
        ...state,
        ntfNumber,
        notifications,
        selected: action.ntf,
        priority: action.ntf.priority,
    };
};
const quickPanel = function (state = initialState, action) {
    switch (action.type) {
        case GET_QUICK_PANEL_DATA: {
            return {
                ...state,
                ntfNumber: action.payload.totalElements,
                notifications: groupingNotifications(action.payload.content),
            };
        }
        case TOGGLE_QUICK_PANEL: {
            return {
                ...state,
                state: action.payload,
            };
        }
        case CLEAR_SELECTED: {
            return {
                ...state,
                selected: null,
            };
        }
        case SEEN_NOTIFICATION: {
            const unGrouped = unGroupingNotifications(state.notifications);
            let n = unGrouped.findIndex((_n) => _n.id === action.id);
            let ntfNumber = parseInt(state.ntfNumber);
            if (n !== -1) {
                unGrouped[n].seen = true;
                ntfNumber = parseInt(state.ntfNumber) - 1;
            }
            return {
                ...state,
                ntfNumber,
                notifications: groupingNotifications(unGrouped),
            };
        }
        case MARK_READ_ALL: {
            const unGrouped = unGroupingNotifications(state.notifications);
            //noinspection AssignmentResultUsedJS
            unGrouped.map((item) => (item.seen = true));
            return {
                ...state,
                ntfNumber: 0,
                notifications: groupingNotifications(unGrouped),
            };
        }
        case MARK_READ_GROUP: {
            let n = 0;
            const unGrouped = unGroupingNotifications(state.notifications);
            unGrouped.map((item) => {
                if (item.sourceType === action.sourceType) {
                    n++;
                    item.seen = true;
                }
            });
            // const notifications = [...state.notifications];
            // const index = notifications.findIndex(i=> i.sourceType === action.sourceType)
            // if(index > -1) {
            //     notifications[index].seen = true;
            //     notifications[index].subNotif.map(j => j.seen = true);
            // }
            return {
                ...state,
                ntfNumber: state.ntfNumber - n,
                notifications: groupingNotifications(unGrouped),
            };
        }
        case GET_NOTIFICATION:
            return receiveNotification(state, action);
        default: {
            return state;
        }
    }
};

export default quickPanel;
