import Chip from "@material-ui/core/Chip/Chip";
import Typography from "@material-ui/core/Typography";
import {NameLabel} from "@ui";
import classNames from "classnames";
import clsx from "clsx";
import type {HTMLAttributes} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import type {userType} from "../../types";
import {MyAvatar} from "./MyAvatar";

type Props = {
    user?: userType;
    dense?: boolean;
    departments?: string[];
    noAvatar?: boolean;
    noUsername?: boolean;
};

export function UserView({user, dense, departments, noAvatar, className, noUsername, ...rest}: Props & HTMLAttributes<HTMLDivElement>) {
    const listView = useSelector(({fuse}) => fuse.dialogs.listView) || dense;
    if (!user || (user && (Object.keys(user).length === 0 || user.firstName === undefined)))
        return (
            <Typography variant={"caption"}>
                <Translate id={"_.No user"} />
            </Typography>
        );

    return (
        <div className={clsx("flex w-full justify-between flex-row items-center", className)} {...rest}>
            {!noAvatar && <MyAvatar user={user} size={listView ? 18 : 32} />}
            <div className={clsx("flex flex-1", !listView ? " flex-col" : " items-center", !noAvatar && "pl-2")}>
                {user.firstName && <NameLabel className={"text-base"} strongTag user={user} />}
                {user.username && !noUsername && <small className={classNames(listView && "pl-2", "text-xs text-grey-dark")}>@{user.username}</small>}
            </div>
            {departments && departments.length > 0 && (
                <div>
                    {departments?.map((d) => (
                        <Chip key={d} size={"small"} className={"ml-2 h-20 text-10"} label={d} />
                    ))}
                </div>
            )}
        </div>
    );
}
