import {makeStyles} from "@material-ui/styles";
import {FusePageSimple} from "../../@fuse";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {Card} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

DetailRoot.propTypes = {
    header: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    contentToolbar: PropTypes.node,
    leftSidebarContent: PropTypes.node,
    rightSidebarContent: PropTypes.node,
};

function DetailRoot(props) {
    const {header, content, contentToolbar, leftSidebarContent, rightSidebarContent} = props;

    const classes = useStyles();

    return (
        <FusePageSimple
            header={
                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {header}
                        </Grid>
                    </Grid>
                </div>
            }
            content={
                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card style={{minHeight: "70vh"}} className={"flex flex-grow mx-5 h-full relative"}>
                                {content}
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
            contentToolbar={
                contentToolbar && (
                    <div className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card className={"mx-5 mb-3"}>{contentToolbar}</Card>
                            </Grid>
                        </Grid>
                    </div>
                )
            }
            leftSidebarContent={
                leftSidebarContent && (
                    <div className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card className={"w-full h-full"}>{leftSidebarContent}</Card>
                            </Grid>
                        </Grid>
                    </div>
                )
            }
            rightSidebarContent={
                rightSidebarContent && (
                    <div className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {content}
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        />
    );
}

export default DetailRoot;
