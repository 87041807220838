export const SET_DMNVIEW_TYPE = "[SET_DMNVIEW_TYPE]";

export function  setDMNViewType(_type) {
    return {
        type: SET_DMNVIEW_TYPE,
        payload: _type
    };
}



