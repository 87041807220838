import axios, {flowPrefix} from "myaxios";
import useSWRMutation from "swr/mutation";

function sendRequest(url, {arg}) {
    return axios.get(url, {params: arg});
}

function useInstances() {
    const {trigger, isMutating, data, error} = useSWRMutation(`${flowPrefix}/processInstances`, sendRequest);

    return {
        run: trigger,
        isMutating,
        data,
        error,
    };
}

export default useInstances;
