import {useSocket} from "@hooks";
import {Icon, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {LocalTimeStamp, MyTooltip} from "@ui";
import {useStyles} from "app/notifications/NotificationsList";
import classNames from "classnames";
import {useEffect, useState} from "react";
import type {LocalizeContextProps} from "react-localize-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {dateFormatWithTime} from "variables.js";
import {toggleQuickPanel} from "./store/actions";
import navitrans from "./translattions/notificate.json";
import type {NotifType} from "./types";

type Props = {
    ntf: NotifType;
};

const NotificationItem = (props: Props & LocalizeContextProps) => {
    const {ntf} = props;
    const [subNotifOpen, setSubNotifOpen] = useState(false);
    const {emitEvent} = useSocket();

    const classes = useStyles(props);
    const dispatch = useDispatch();

    useEffect(() => {
        props.addTranslation(navitrans);
    }, []);

    const onClickItem = () => {
        const subNotifLength = ntf.subNotif ? ntf.subNotif.length : 0;
        if (subNotifLength > 0) {
            setSubNotifOpen(!subNotifOpen);
        } else {
            dispatch(toggleQuickPanel(false));
            // unreadGroupNotification(ntf.sourceId);
            // setTimeout(() => this.props.getNotifications(), 3000);
        }
    };

    // const sTypeToLink = {
    //     "integral" : "",
    //     requestFirm: "/my-firms/settings",
    //
    // }

    const subNotifLength = ntf.subNotif ? ntf.subNotif.length : 0;
    const link = ntf.sourceType === "requestFirm" ? "/my-firms/settings" : ntf.sourceId ? `/${ntf.sourceType}/${ntf.sourceId}` : `/${ntf.sourceType}`;
    const isSeen = ntf.seen;
    let isHasUnseen = false;
    if (!!ntf.subNotif && ntf.subNotif.length > 0) {
        if (ntf.subNotif.findIndex((i) => !i.seen) > -1) {
            isHasUnseen = true;
        }
    }
    // https://srx.signabc.com/integral/1711959624084
    const ItemIcon = () => (
        <ListItemIcon className={"m-0 min-w-40"}>
            {ntf.priority === 2 ? <Icon color={"error"}>warning</Icon> : <Icon>notifications</Icon>}
        </ListItemIcon>
    );

    return (
        <>
            <ListItem
                button
                onClick={onClickItem}
                component={ntf.sourceType !== "userLogin" && subNotifLength === 0 ? Link : "a"}
                to={link}
                className={classNames(isHasUnseen && isSeen && "bg-yellow-lightest", !isSeen && "bg-yellow-lighter")}>
                {ntf.priority !== 1 && <ItemIcon />}
                <ListItemText
                    onMouseEnter={() => {
                        if (!ntf.seen) {
                            emitEvent("notificationSeen", {id: ntf.id});
                            // dispatch(seeNotification(ntf.id));
                        }
                    }}
                    primary={
                        <>
                            <span className="relative">
                                <strong className="capitalize">
                                    {ntf.isType && ntf.title ? (
                                        <Translate id={`notifi.${ntf.title}`} />
                                    ) : (
                                        ntf.title || <Translate id={`notifi.${ntf.sourceType}`} />
                                    )}
                                </strong>

                                {subNotifLength > 0 && <span className={classes.unreadBadge}>{subNotifLength}</span>}
                            </span>
                        </>
                    }
                    secondary={
                        <>
                            {ntf.detail}{" "}
                            <small className={"text-grey-dark"}>
                                {!!ntf.time && <LocalTimeStamp datetime={ntf.time} format={dateFormatWithTime} />}
                            </small>
                        </>
                    }
                />
                <ListItemSecondaryAction>
                    {subNotifLength > 0 && (
                        <>
                            <MyTooltip title={<Translate id="_.open" />}>
                                <IconButton onClick={() => setSubNotifOpen(!subNotifOpen)}>
                                    <Icon>{!subNotifOpen ? "keyboard_arrow_down" : "keyboard_arrow_up"}</Icon>
                                </IconButton>
                            </MyTooltip>
                        </>
                    )}
                </ListItemSecondaryAction>
            </ListItem>
            {!!ntf.subNotif && ntf.subNotif.length > 0 && subNotifOpen && (
                <div className={"border-b-1 pl-40 bg-grey-lighter"}>
                    {ntf.subNotif.map((item) => (
                        <NotificationItem {...props} key={item.id} ntf={item} />
                    ))}
                </div>
            )}
        </>
    );
};

export default withLocalize(NotificationItem);
