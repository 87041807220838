import {FuseModel, useFuseModel} from "../../@fuse/components/FuseModel";
import {useMUIDialog} from "../../@fuse/hooks";
import {GDialogRegistries} from "../../@fuse/state/constant";
import DefinitionSetCategoryDialog from "../main/camunda-process/management/dialogs/DefinitionSetCategoryDialog";
import EditProcessCategoryDialog from "../main/camunda-process/management/dialogs/EditProcessCategoryDialog";
import NewBatchDeleteInstanceDialog from "../main/camunda-process/management/dialogs/NewBatchDeleteInstanceDialog";
import NewChangeUserForUserTaskDialog from "../main/camunda-process/management/dialogs/NewChangeUserForUserTaskDialog";
import NewCreateInstanceDialog from "../main/camunda-process/management/dialogs/NewCreateInstanceDialog";
import NewDefinitionEditorDialog from "../main/camunda-process/management/dialogs/NewDefinitionEditorDialog";
import NewDefinitionsInCategoryDialog from "../main/camunda-process/management/dialogs/NewDefinitionsInCategoryDialog";
import NewDefinitionSuspendDialog from "../main/camunda-process/management/dialogs/NewDefinitionSuspendDialog";
import NewDeleteVariableTipDialog from "../main/camunda-process/management/dialogs/NewDeleteVariableTipDialog";
import NewEditDefinitionDialog from "../main/camunda-process/management/dialogs/NewEditDefinitionDialog";
import NewInstanceUserTaskVarDialog from "../main/camunda-process/management/dialogs/NewInstanceUserTaskVarDialog";
import NewPauseDefinitionDialog from "../main/camunda-process/management/dialogs/NewPauseDefinitionDialog";
import NewSyncTipDialog from "../main/camunda-process/management/dialogs/NewSyncTipDialog";
import NewTransferForInstanceDialog from "../main/camunda-process/management/dialogs/NewTransferForInstanceDialog";
import ProgressBackdrop from "../main/camunda-process/management/dialogs/ProgressBackdrop";
import useBackdropLoading from "../main/camunda-process/utils/hooks/useBackdropLoading";
import IncomeDetailDialog from "../main/incomes/dialogs/IncomeDetailDialog";
import MyIncomeChangeUserDialog from "../main/incomes/dialogs/MyIncomeChangeUserDialog";
import NewOrderDistributeDialog from "../main/incomes/dialogs/NewOrderDistributeDialog";
import NewRefundDialog from "../main/incomes/dialogs/NewRefundDialog";
import LXExcelStartupDialog from "../main/incomes/utils/components/Excel/lx-e-car/LXExcelStartupDialog";
import IncomeModuleCom from "./IncomeModuleCom";

function CommonMixinsView() {
    const {open} = useFuseModel();

    const {openBackDrop} = useBackdropLoading();

    const definitionEditorActions = useMUIDialog({
        registry: GDialogRegistries.definitionEditor,
    });

    const definitionSuspendActions = useMUIDialog({
        registry: GDialogRegistries.definitionSuspend,
    });

    const transferForInstanceActions = useMUIDialog({
        registry: GDialogRegistries.transferForInstance,
    });

    const batchDeleteInstanceActions = useMUIDialog({
        registry: GDialogRegistries.batchDeleteInstance,
    });

    const changeUserForUserTaskActions = useMUIDialog({
        registry: GDialogRegistries.changeUserForUserTask,
    });

    const createInstanceActions = useMUIDialog({
        registry: GDialogRegistries.createInstance,
    });

    const pauseDefinitionActions = useMUIDialog({
        registry: GDialogRegistries.pauseDefinitionTips,
    });

    const editDefinitionActions = useMUIDialog({
        registry: GDialogRegistries.editDefinitionDialog,
    });

    const deleteVariableActions = useMUIDialog({
        registry: GDialogRegistries.deleteVariableTipsDialog,
    });

    const instanceUserTaskActions = useMUIDialog({
        registry: GDialogRegistries.instanceUserTaskVarDialog,
    });

    const instanceSyncActions = useMUIDialog({
        registry: GDialogRegistries.syncInstanceTipsDialog,
    });

    const postCategoryActions = useMUIDialog({
        registry: GDialogRegistries.editProcessCategoryDialog,
    });

    const setCategoryActions = useMUIDialog({
        registry: GDialogRegistries.definitionSetCategory,
    });

    const incomeDetailsActions = useMUIDialog({
        registry: GDialogRegistries.incomeDetails,
    });

    const incomeOrderDistributeActions = useMUIDialog({
        registry: GDialogRegistries.orderDistribute,
    });

    const refundActions = useMUIDialog({
        registry: GDialogRegistries.refundAmount,
    });

    const changeUserMyIncomeActions = useMUIDialog({
        registry: GDialogRegistries.changeUserMyIncome,
    });

    const definitionsCategoryActions = useMUIDialog({
        registry: GDialogRegistries.definitionsInCategory,
    });
    //    const sheetPreViewDialogActions = useMUIDialog({
    //        registry: GDialogRegistries.sheetPreViewDialog,
    //    });

    const lxStartupActions = useMUIDialog({
        registry: GDialogRegistries.lxExcelStartup,
    });

    return (
        <>
            {open && <FuseModel />}
            {instanceSyncActions?.open && <NewSyncTipDialog />}
            {definitionEditorActions?.open && <NewDefinitionEditorDialog />}
            {definitionSuspendActions?.open && <NewDefinitionSuspendDialog />}
            {transferForInstanceActions?.open && <NewTransferForInstanceDialog />}
            {batchDeleteInstanceActions?.open && <NewBatchDeleteInstanceDialog />}
            {changeUserForUserTaskActions?.open && <NewChangeUserForUserTaskDialog />}
            {createInstanceActions?.open && <NewCreateInstanceDialog />}
            {pauseDefinitionActions?.open && <NewPauseDefinitionDialog />}
            {editDefinitionActions?.open && <NewEditDefinitionDialog />}
            {openBackDrop && <ProgressBackdrop />}
            {deleteVariableActions?.open && <NewDeleteVariableTipDialog />}
            {instanceUserTaskActions?.open && <NewInstanceUserTaskVarDialog />}
            {postCategoryActions?.open && <EditProcessCategoryDialog />}
            {setCategoryActions?.open && <DefinitionSetCategoryDialog />}
            {incomeDetailsActions?.open && <IncomeDetailDialog />}
            {incomeOrderDistributeActions?.open && <NewOrderDistributeDialog />}
            {refundActions?.open && <NewRefundDialog />}
            {changeUserMyIncomeActions?.open && <MyIncomeChangeUserDialog />}
            {definitionsCategoryActions?.open && <NewDefinitionsInCategoryDialog />}
            {changeUserMyIncomeActions?.open && <MyIncomeChangeUserDialog />}
            <IncomeModuleCom />
            {/* {sheetPreViewDialogActions.open && <SheetPreViewDialog />} */}
            {lxStartupActions.open && <LXExcelStartupDialog />}
        </>
    );
}

export default CommonMixinsView;
