import {lazy} from "react";

export const IssuesAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/issue/:id/",
            component: lazy(() => import("./Issue/IssuePage")),
            title: {cn: "问题列表", en: "Issue Details"},
        },
        {
            path: "/projectIssue/:id/",
            component: lazy(() => import("./Issue/IssuePage")),
            title: {cn: "问题列表", en: "Issue Details"},
        },
        {
            path: "/myIssues/:tab",
            component: lazy(() => import("./Issues/Page")),
            title: {cn: "我的问题", en: "My Issues"},
        },
        {
            path: "/myIssues",
            component: lazy(() => import("./Issues/Page")),
            title: {cn: "我的问题", en: "My Issues"},
        },
        {
            path: "/projectIssue",
            component: lazy(() => import("./Issues/Page")),
            title: {cn: "问题列表", en: "My Issues"},
        },
    ],
};
