import {ListSubheader} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {Fragment, memo} from "react";
import {withLocalize} from "react-localize-redux";
import {withRouter} from "react-router-dom";
import FuseNavVerticalCollapse from "./FuseNavVerticalCollapse";
import FuseNavVerticalItem from "./FuseNavVerticalItem";

const useStyles = makeStyles((theme) => ({
    item: {
        // backgroundColor: theme.palette.primary.light,
        height: "3rem"
        // height      : "40px",
        // width       : 'calc(100% - 16px)',
        // borderRadius: '0 20px 20px 0',
        // paddingRight: 8,
    }
}));

/**
 * @return {null}
 */
function FuseNavVerticalGroup(props) {
    //    const userRole = useSelector(({user}) => user.role);

    const classes = useStyles(props);
    const {item, nestedLevel, active, index} = props;
    let paddingValue = 40 + nestedLevel * 16;
    const listItemPadding = nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-16";

    //    if (!FuseUtils.hasPermission(item.auth, userRole)) {
    //        return null;
    //    }

    return (
        <>
            {/*<Divider className={" mt-32 mx-16"}/>*/}
            <ListSubheader disableSticky={true} className={clsx(classes.item, listItemPadding, "list-subheader flex items-center mb-8 mt-32")}>
                <span className="list-subheader-text flex items-center  uppercase  divide-solid w-full">
                    {props.activeLanguage?.code === "cn" ? item.titleCn : item.title}
                    <Divider className={"flex-1 ml-3"} />
                </span>
            </ListSubheader>

            {/*<Divider />*/}

            {/*{ index > 0  && <Divider /> }*/}

            {item.children && (
                <>
                    {item.children.map((j) => (
                        <Fragment key={j.id}>
                            {/* {item.type === "group" && <NavVerticalGroup item={item} nestedLevel={nestedLevel} active={active} />} */}

                            {(j.children && j.children.length > 0) ?
                                <FuseNavVerticalCollapse item={j} nestedLevel={nestedLevel} active={active} />
                                : j.url && <FuseNavVerticalItem item={j} nestedLevel={nestedLevel} active={active} />
                            }

                            {/* {item.type === "item" && <FuseNavVerticalItem item={item} nestedLevel={nestedLevel} active={active} />} */}

                            {/* {item.type === "link" && <FuseNavVerticalLink item={item} nestedLevel={nestedLevel} active={active} />} */}
                        </Fragment>
                    ))}
                </>
            )}
        </>
    );
}

FuseNavVerticalGroup.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        children: PropTypes.array
    })
};



const NavVerticalGroup = withRouter(memo(FuseNavVerticalGroup));

export default withLocalize(NavVerticalGroup);
