import {Icon, IconButton, Snackbar, SnackbarContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {amber, blue, green, grey} from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import {useEffect} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import { hideMessage } from "../../../app/store/actions";
import translations from "./message";

const useStyles = makeStyles((theme) => ({
    root: {},
    success: {
        backgroundColor: green[600],
        color: "#FFFFFF",
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.getContrastText(theme.palette.error.dark),
    },
    info: {
        backgroundColor: blue[600],
        color: "#FFFFFF",
    },
    warning: {
        backgroundColor: amber[500],
        color: "#333333",
    },
    default: {
        backgroundColor: grey[700],
        color: "#FFFFFF",
    },
}));

const variantIcon = {
    success: "check_circle",
    warning: "warning",
    error: "error_outline",
    info: "info",
};

function FuseMessage(props) {
    useEffect(() => {
        props.addTranslation(translations);
    }, []);
    const dispatch = useDispatch();
    const state = useSelector(({fuse}) => fuse.message.state);
    const options = useSelector(({fuse}) => fuse.message.options);

    const classes = useStyles();

    const {gotoLink, gotoTitle, ...rest} = options;

    return (
        <Snackbar
            {...rest}
            open={state}
            onClose={() => dispatch(hideMessage())}
            classes={{
                root: classes.root,
            }}
            ContentProps={{
                variant: "body2",
                headlineMapping: {
                    body1: "div",
                    body2: "div",
                },
            }}>
            <SnackbarContent
                className={clsx(classes[options.variant])}
                message={
                    <Translate>
                        {({translate}) => (
                            <div className="flex items-center">
                                {variantIcon[options.variant] && (
                                    <Icon className="mr-8" color="inherit">
                                        {variantIcon[options.variant]}
                                    </Icon>
                                )}
                                {options.params ? translate(options.message, {params: options.params}) : translate(options.message)}
                            </div>
                        )}
                    </Translate>
                }
                action={[
                    gotoLink && (
                        <Button
                            key={"goto"}
                            color={"primary"}
                            variant={"contained"}
                            onClick={() => dispatch(hideMessage())}
                            component={Link}
                            to={gotoLink}>
                            <Translate id={gotoTitle} />
                        </Button>
                    ),
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={() => dispatch(hideMessage())}>
                        <Icon>close</Icon>
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
}

export default withLocalize(FuseMessage);
