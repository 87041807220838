import axios, {firmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const  GET_RELATIVES_LIST =  "[GET_RELATIVES_sdkkfss]";
export const  GET_RELATIVES_BY_ID = "[GET_RELATIVES_BY_ID_SDJSDJSJDF]";
export const  SELECT_A_RELATIVE  = "[SELECT_A_RELATIVE_ISDIFISF]";
export const  UNSELECT_RELATIVE  = "[UNSELECT_RELATIVE_SDFVSF]";
export const  PASS_THE_SELECTED_MEMBERS_TO_ADD = "[PASS_THE_SELECTED_MEMBERS_TO_ADD]";
export const  SELECT_A_USER_FOR_RELATIVES = "[SELECT_A_USER_FOR_RELATIVES]";
export const  SEARCH_RELATIVES_BY_TITLE = "[SEARCH_RELATIVES_BY_TITLE]";



export function getRelativesList(params, callback) {

    const request = axios.get(`${firmPrefix}/relativesList`,{params: params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then( (res) => {
            dispatch(finishLoading());
            dispatch({
                type: GET_RELATIVES_LIST,
                payload: res.data,
            });
            if(typeof callback=== "function") callback();
        });
    };
}

// export function filterRelativesByUserId(userId) {
//     return {
//         type: SELECT_A_USER_FOR_RELATIVES,
//         payload: userId,
//     }
// }

// export function setSearchRelativesByTitle(title) {
//     return {
//         type: SEARCH_RELATIVES_BY_TITLE,
//         payload: title,
//     }
// }
//
//
// export function getRelativesById(id) {
//
//     const request = axios.get(`${firmPrefix}/relatives/${id}`);
//     return (dispatch) => {
//         dispatch(startLoading());
//         request.then( res => {
//             dispatch(finishLoading());
//             return {
//                 type: GET_RELATIVES_BY_ID,
//                 payload: res.data,
//             }
//         });
//     }
// }

export function selectRelative(relative) {
    return {
        type: SELECT_A_RELATIVE,
        payload: relative
    };
}

// export function unSelectRelative() {
//     return {
//         type: UNSELECT_RELATIVE
//     }
// }

export function passSelectedMembers(members,callback) {
    // console.log('PASS_THE_SELECTED_MEMBERS_TO_ADD =>', members);
    if(typeof callback=== "function") callback();
    return {
        type: PASS_THE_SELECTED_MEMBERS_TO_ADD,
        payload: members
    };
}
