import {lazy} from "react";

export const NotificationsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: "/notifications",
            component: lazy(() => import("./Page"))
        },
        {
            path: "/integral/:id",
            component: lazy(() => import("./GotoOld"))
        }
    ]
};
