import {Icon, IconButton} from "@material-ui/core";
import clsx from "clsx";
import type {FC, ReactNode} from "react";
import FaveButton from "../../layout/fav/FaveButton";
import {type FiltersContextType, useFilters} from "./hooks/filtersContext";

export type HeaderProps = {
    title: string;
    total?: number;
    subheader?: ReactNode;
    selector?: ReactNode;
    dense?: boolean;
    actions?: ((props: {filterContext: FiltersContextType}) => ReactNode) | ReactNode;
};

const Header: FC<HeaderProps> = ({title, actions, dense, selector, subheader}) => {
    const filterContext = useFilters();
    const {totalElements, swr} = filterContext;
    // const {mutate, isValidating} = useSWR([link, filters]);
    return (
        <div className={"p-12 flex items-center w-full justify-between"}>
            <div className="flex  items-center">
                <div className={"md:flex items-center"}>
                    <div>
                        <div className={clsx(" font-bold mr-20", dense ? "text-24" : "md:text-32 text-24")}>
                            {title}
                            {!!totalElements && <small className={"mx-3 text-grey"}>({totalElements})</small>}

                            <IconButton
                                size={dense ? "small" : "medium"}
                                onClick={() => swr.mutate()}
                                className={swr.isValidating ? "animate-spin" : ""}>
                                <Icon>refresh</Icon>
                            </IconButton>

                            <FaveButton dense={dense} sourceId={location.pathname} title={title} type={"link"} />
                        </div>
                        {subheader && subheader}
                    </div>
                    {selector && selector}
                </div>
            </div>

            <div className="flex flex-1 items-center mt-12 md:mt-0 justify-end pr-0 sm:pl-12 ">
                <>{typeof actions === "function" ? actions({filterContext}) : actions}</>
            </div>
        </div>
    );
};

export default Header;
