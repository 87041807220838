import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_USERTASKS_LIST = "[GET_USERTASKS_LIST]";
export const SELECT_USERTASKS_LIST = "[SELECT_USERTASKS_LIST]";
export const SELECT_A_USERTASK = "[SELECT_A_USERTASK]";
export const SELECT_A_VARIABLE = "[SELECT_A_VARIABLE]";
export const GET_VARIABLES_LIST_FOR_A_USERTASK = "[GET_VARIABLES_LIST_FOR_A_USERTASK]";
export const SELECT_VARIABLES_LIST_FOR_A_USERTASK = "[SELECT_VARIABLES_LIST_FOR_A_USERTASK]";

export function getUserTasks(params, callback, catchError) {

    const request = axios.get(`${flowPrefix}/tasks`, {
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_USERTASKS_LIST,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}

export function selectUserTasksList(_tasks) {
    return {
        type: SELECT_USERTASKS_LIST,
        payload: _tasks
    };
}

export function selectAUsertask(_task) {
    return {
        type: SELECT_A_USERTASK,
        payload: _task
    };
}

export function selectAVariable(_variable) {
    return {
        type: SELECT_A_VARIABLE,
        payload: _variable
    };
}

export function selectVariablesForAUserTask(_variables) {
    return {
        type: SELECT_VARIABLES_LIST_FOR_A_USERTASK,
        payload: _variables
    };
}
