import { GET_PROJECTS, GET_PROJECTS_BY_CUSTOMER_ID, PROJECTS_CHANGE_VIEW, SELECT_PROJECT, SELECT_PROJECT_LABEL, SELECT_PROJECT_ADDRESS, GET_SELECT_CUSTOMER_BRAND_ID, CLEAR_SELECT_CUSTOMER_BRAND_ID, SELECT_MAP_GPS_ARRAY, PASS_NEAR_PROJECTS_DATA } from "../actions";

const initialState = {
    content             : [],
    view                : 0,  // 0 = list , 1 = grid , 2 = kanban
    groups              : [],
    selectedProject     : null,
    searchText          : "",
    selectAddressParam  : null,
    customerOfBrandId   : "",
    totalElements       : 0,
    selecttedGPS        : [],
    nearProjects        : [],
    projectsForACustomer: [],
};

const projectsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        // case Actions.GET_CUSTOMER_GROUP:{
        //     return {
        //         ...state,
        //         groups: action.payload
        //     };
        // }
        case GET_PROJECTS:{
            return {
                ...state,
                ...action.payload
            };
        }
        case GET_PROJECTS_BY_CUSTOMER_ID:
            return {
                ...state,
                projectsForACustomer: action.payload,
            };
        case PROJECTS_CHANGE_VIEW:{
            return {
                ...state,
                view: action.view
            };
        }
        case SELECT_PROJECT: {
            return {
                ...state,
                selectedProject: action.payload
            };
        }
        case SELECT_PROJECT_LABEL: {
            return {
                ...state,
                selectedProjectLabel: action.payload
            };
        }
        case SELECT_PROJECT_ADDRESS: {
            return {
              ...state,
                selectAddressParam: action.param
            };
        }
        case GET_SELECT_CUSTOMER_BRAND_ID: {
            return {
                ...state,
                customerOfBrandId: action.brandId
            };
        }
        case CLEAR_SELECT_CUSTOMER_BRAND_ID: {
            return {
                ...state,
                customerOfBrandId: ""
            };
        }
        case SELECT_MAP_GPS_ARRAY:
            return {
                ...state,
                selecttedGPS: action.gps
            };

        case PASS_NEAR_PROJECTS_DATA: {
            return {
                ...state,
                nearProjects: action.projects
            };
        }
        default:{
            return state;
        }
    }
};

export default projectsReducer;
