import {Component} from 'react';
import {bindActionCreators} from 'redux';
import * as Actions from '../../store/actions';
import {connect} from 'react-redux';
import {Avatar, Button, Dialog, DialogContent, Icon, withStyles} from '@material-ui/core';
import axios, {crmPrefix} from '../../../../../myaxios';
import * as fuseActions from '@fuseActions';
import {Translate, withLocalize} from 'react-localize-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Select2 from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import {savePicture} from 'utils/fileUploader';
import Typography from '@material-ui/core/Typography';
import {withRouter} from 'react-router-dom';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {components} from 'utils/Select2Components';
import NumberLimitInput from 'utils/NumberLimitInput/NumberLimitInput';
import sources from '../../translation/commerce';

const styles = (theme) => ({
    bigAvatar: {
        width: 50,
        height: 50,
    },
    label: {
        lineHeight: 0,
    },
});

const types = ["COMMON", "COMBINATION", "PART"];

class ProductDialog extends Component {
    state = {
        loading: false,
        step: 1,
        name: "",
        user: null,
        manager: "",

        brands: [],
        openBrand: false,
        anchorEl: null,

        newAvatar: "",
        fileLoading: false,
        src: null,

        brandId: null,
        categories: [],
        brand: "",
        category: "",
        specs: "",
        description: "",
        image: "",
        type: "",
        dimension: "",
        width: "",
        height: "",
        depth: "",
        cost: "",
        unit: "",
        rate: "",
        tag: "",
    };

    constructor(props) {
        super(props);
        this.props.addTranslation(sources);
    }

    componentDidMount = () => {
        const {selectedProduct} = this.props;
        if (selectedProduct) {
            this.setState({
                name: selectedProduct.name,
                brandId: selectedProduct.brand.id,
                brand: {value: selectedProduct.brand.id, label: selectedProduct.brand.name},
                category: selectedProduct.category && selectedProduct.category.id,
                specs: selectedProduct.specs,
                description: selectedProduct.description,
                image: selectedProduct.image,
                type: selectedProduct.type,
                dimension: selectedProduct.dimension,
                width: selectedProduct.width,
                height: selectedProduct.height,
                depth: selectedProduct.depth,
                cost: selectedProduct.cost,
                unit: selectedProduct.unit,
                rate: selectedProduct.rate,
                tag: selectedProduct.tag,
            });
            axios.get(`${crmPrefix}/productCategories?brandId=${selectedProduct.brand.id}`).then((res) => {
                this.setState({categories: res.data});
            });
        } else if (this.props.selectedBrand) {
            this.setState({
                brand: {
                    value: this.props.selectedBrand.id,
                    label: this.props.selectedBrand.name,
                },
            });
            axios.get(`${crmPrefix}/productCategories?brandId=${this.props.selectedBrand.id}`).then((res) => {
                this.setState({categories: res.data});
            });
        }
    };

    handleClose = () => {
        this.setState({openName: false, anchorEl2: null, openTag: false});
    };

    handleSelectBrand = (brand) => {
        this.setState({brandId: brand.id, brand: brand.name});
        // console.log("log");
        axios.get(`${crmPrefix}/productCategories?brandId=${brand.id}`).then((res) => {
            this.setState({categories: res.data.content});
        });
        this.handleClose();
    };

    handleChipChange = (value) => {
        if (value) {
            axios.get(`${crmPrefix}/productCategories?brandId=${value.value}`).then((res) => {
                this.setState({categories: res.data});
            });
            this.setState({brand: value, brandId: value.value});
        } else this.setState({brand: "", brandId: ""});
    };

    timerForSearch;
    onChangeBrand = (e) => {
        clearTimeout(this.timerForSearch);
        this.timerForSearch = setTimeout(() => {
            axios
                .get(`${crmPrefix}/brands?size=8`, {
                    params: {
                        name: e,
                    },
                })
                .then((res) => {
                    const brands = res.data.content.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }));
                    this.setState({brands});
                });
        }, 100);
    };

    onSubmit = () => {
        if (this.state.step === 1) {
            this.setState({step: 2});
            return;
        }
        this.setState({loading: true});
        const formData = {
            name: this.state.name,
            brand: this.state.brand.value,
            category: this.state.category,
            specs: this.state.specs,
            description: this.state.description,
            image: this.state.image,
            type: this.state.type,
            dimension: this.state.dimension,
            width: this.state.width,
            height: this.state.height,
            depth: this.state.depth,
            cost: this.state.cost,
            unit: this.state.unit,
            rate: this.state.rate,
            tag: this.state.tag,
        };
        if (this.props.selectedProduct) formData.id = this.props.selectedProduct.id;
        let request = this.props.selectedProduct
            ? axios.put(`${crmPrefix}/product`, formData)
            : axios.post(`${crmPrefix}/product`, formData);

        request
            .then(() => {
                this.setState({loading: false});
                this.props.turnOff("product");
                this.props.showMessage({
                    variant: "success",
                    message: "message.Product Saved!",
                });
                this.props.turnOn("productsShouldUpdate");
                // if (this.props.location.pathname.includes("/products/") )
                //     this.props.getProduct(this.props.selectedProduct.id);
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    changeImage = (e) => {
        if (e.target.files[0]) {
            /** 10 M **/
            if (e.target.files[0].size > 10240000) {
                this.props.showMessage({
                    variant: "error",
                    message: "message.File is too big!",
                });
            } else {
                this.setState({newAvatar: e.target.files[0], fileLoading: true});
                savePicture(e.target.files[0], (status, url) => {
                    if (status) {
                        const img = `${url}`;
                        this.setState({image: img});
                        this.setState({fileLoading: false});
                    }
                });
            }
        }
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const {selectedProduct, dialogs, classes} = this.props;
        const {step, anchorEl2, openBrand, loading} = this.state;
        const id2 = openBrand ? "brand-menu" : null;
        return (
            <Dialog
                scroll={"body"}
                className={"overflow-visible"}
                fullScreen={this.props.fullScreen}
                open={!!dialogs.product}
                onClose={() => {
                    this.props.turnOff("product");
                }}
                fullWidth
            >
                <div className={loading ? "loading" : null}>
                    <DialogTitle id="alert-dialog-title">
                        {selectedProduct ? (
                            <>
                                <Translate id="crm.Edit" /> {selectedProduct.name}{" "}
                            </>
                        ) : (
                            <Translate id="crm.New Product" />
                        )}
                    </DialogTitle>

                    <DialogContent className={"overflow-visible"}>
                        {step === 1 && (
                            <>
                                <Typography variant={"subtitle2"} className={"mb-12  uppercase"}>
                                    <Translate id="crm.Basic Information" />
                                </Typography>
                                <Popper
                                    transition
                                    placement={"bottom-start"}
                                    id={id2}
                                    open={openBrand}
                                    anchorEl={anchorEl2}
                                    keepMounted
                                    onClose={this.handleClose}
                                    style={{zIndex: 1500}}
                                >
                                    <Paper>
                                        {this.state.brands.map((_brand, i) => (
                                            <MenuItem
                                                key={i}
                                                onClick={() => {
                                                    this.handleClose();
                                                    this.handleSelectBrand(_brand);
                                                }}
                                            >
                                                {_brand.name}
                                            </MenuItem>
                                        ))}
                                    </Paper>
                                </Popper>

                                <div className="flex flex-row">
                                    <input
                                        id={"uid"}
                                        type="file"
                                        accept={"image/*"}
                                        value={""}
                                        onChange={this.changeImage}
                                        className="hidden"
                                    />
                                    <label className={"mb-0 mr-12 " + classes.label} htmlFor={"uid"}>
                                        {this.state.fileLoading ? (
                                            <CircularProgress size={50} className={""} />
                                        ) : (
                                            <Avatar
                                                className={classes.bigAvatar}
                                                alt={"Product"}
                                                src={this.state.image && this.state.image + "/crop/120x120"}
                                            >
                                                <Icon children={"add"} />
                                            </Avatar>
                                        )}
                                    </label>

                                    <TextField
                                        className="mb-24 flex-1 mr-20"
                                        fullWidth
                                        autoFocus={true}
                                        onChange={this.handleChange("name")}
                                        label={<Translate id={"_.name"} />}
                                        value={this.state.name}
                                    />

                                    <div className={"mb-24 flex-1"}>
                                        <Translate>
                                            {({translate}) => (
                                                <Select2
                                                    textFieldProps={{
                                                        label: translate("crm.Brand"),
                                                        InputLabelProps: {
                                                            shrink: true,
                                                        },
                                                    }}
                                                    noOptionsMessage={() => translate("_.no option")}
                                                    onInputChange={(e) => this.onChangeBrand(e)}
                                                    classes={classes}
                                                    // styles={selectStyles}
                                                    options={this.state.brands}
                                                    components={components}
                                                    value={this.state.brand}
                                                    onChange={this.handleChipChange}
                                                    placeholder={translate("crm.Search a brand")}
                                                    isClearable
                                                />
                                            )}
                                        </Translate>
                                    </div>
                                </div>

                                <div className="flex flex-row mb-24 ">
                                    <FormControl className={"flex-1 mr-20"}>
                                        <InputLabel htmlFor="category">{<Translate id="_.category" />}</InputLabel>
                                        <Select
                                            value={this.state.category}
                                            onChange={this.handleChange("category")}
                                            inputProps={{
                                                name: "category",
                                                id: "category",
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>{<Translate id="_.none" />}</em>
                                            </MenuItem>
                                            {this.state.categories &&
                                                this.state.categories.map((_cat) => (
                                                    <MenuItem key={_cat.id} value={_cat.id}>
                                                        {_cat.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl className={"flex-1"}>
                                        <InputLabel htmlFor="category">{<Translate id="_.type" />}</InputLabel>
                                        <Select
                                            value={this.state.type}
                                            onChange={this.handleChange("type")}
                                            inputProps={{
                                                name: "type",
                                                id: "type",
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>{<Translate id="crm.none" />}</em>
                                            </MenuItem>
                                            {types &&
                                                types.map((_type) => (
                                                    <MenuItem key={_type} value={_type}>
                                                        {<Translate id={"crm." + _type} />}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <TextField
                                    id={"tewsgbfhsadbfhjsadf-009089787+dsjsadgfh=dgvfnjsdfjnh"}
                                    className="mb-24 flex-1 mr-20"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    onChange={this.handleChange("description")}
                                    label={<Translate id={"crm.Description"} />}
                                    value={this.state.description}
                                />
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <Typography variant={"subtitle2"} className={"my-12 uppercase"}>
                                    <Translate id="crm.Product Information" />
                                </Typography>

                                <div className="flex flex-row">
                                    <NumberLimitInput
                                        classNames={"mb-24 flex-1 mr-20"}
                                        id={"etsgdhshfghfghdfg546546d"}
                                        onChange={this.handleChange("rate")}
                                        label={"crm.Rate"}
                                        unit={"crm.￥"}
                                    />

                                    <TextField
                                        id={"testtsgf-98788778-dvfkjsdnvs-sdnfvsnavdg"}
                                        className="mb-24 flex-1 mr-20"
                                        fullWidth
                                        onChange={this.handleChange("tag")}
                                        label={<Translate id={"crm.Tag"} />}
                                        value={this.state.tag ? this.state.tag : ""}
                                    />
                                </div>

                                <div className="flex flex-row">
                                    <TextField
                                        className="mb-24 flex-1 mr-20"
                                        fullWidth
                                        id={"nvjksdvnjsnv-iu78787878hjbbj-sunrain12314"}
                                        onChange={this.handleChange("specs")}
                                        label={<Translate id={"crm.Spec"} />}
                                        value={this.state.specs ? this.state.specs : ""}
                                    />

                                    {/*<TextField*/}
                                    {/*    className="mb-24 flex-1 mr-20"*/}
                                    {/*    fullWidth*/}
                                    {/*    onChange={this.handleChange('cost')}*/}
                                    {/*    label={<Translate id={"crm.Cost"}/>}*/}
                                    {/*    value={this.state.cost}/>*/}
                                    <NumberLimitInput
                                        classNames={"mb-24 flex-1 mr-20"}
                                        id={"usdjhdsbb5dghfhgdf464645fgdhfgxnbvc"}
                                        onChange={this.handleChange("cost")}
                                        label={"crm.Cost"}
                                        unit={"crm.￥"}
                                    />
                                    <TextField
                                        className="mb-24 flex-1"
                                        fullWidth
                                        id={"ndsgvfjdsafg54645nadsfj897897866767dvfs"}
                                        onChange={this.handleChange("unit")}
                                        label={<Translate id={"crm.Unit"} />}
                                        value={this.state.unit ? this.state.unit : ""}
                                    />
                                </div>

                                <div className="flex flex-row">
                                    {/*<TextField*/}
                                    {/*    className="mb-24 flex-1 mr-12"*/}
                                    {/*    fullWidth*/}
                                    {/*    onChange={this.handleChange('dimension')}*/}
                                    {/*    label={<Translate id={"crm.Dimension"}/>}*/}
                                    {/*    value={this.state.dimension}/>*/}
                                    <NumberLimitInput
                                        classNames={"mb-24 flex-1 mr-12"}
                                        id={"jhjhcjxvbrwetrytw66665435xnvsd"}
                                        onChange={this.handleChange("dimension")}
                                        label={"crm.Dimension"}
                                        unit={"crm.L"}
                                    />
                                    {/*<TextField*/}
                                    {/*    className="mb-24 flex-1 mr-12"*/}
                                    {/*    fullWidth*/}
                                    {/*    onChange={this.handleChange('width')}*/}
                                    {/*    label={<Translate id={"crm.Width"}/>}*/}
                                    {/*    value={this.state.width}/>*/}
                                    <NumberLimitInput
                                        classNames={"mb-24 flex-1 mr-12"}
                                        id={"m-udisjhfg546546565hghgfvsgnhvsjfd"}
                                        onChange={this.handleChange("width")}
                                        label={"crm.Width"}
                                        unit={"crm.m"}
                                    />
                                    {/*<TextField*/}
                                    {/*    className="mb-24 flex-1 mr-12"*/}
                                    {/*    fullWidth*/}
                                    {/*    onChange={this.handleChange('height')}*/}
                                    {/*    label={<Translate id={"crm.Height"}/>}*/}
                                    {/*    value={this.state.height}/>*/}
                                    <NumberLimitInput
                                        classNames={"mb-24 flex-1 mr-12"}
                                        id={"heig-sdjhdsvfsd5464364fsd"}
                                        onChange={this.handleChange("height")}
                                        label={"crm.Height"}
                                        unit={"crm.m"}
                                    />
                                    {/*<TextField*/}
                                    {/*    className="mb-24 flex-1"*/}
                                    {/*    fullWidth*/}
                                    {/*    onChange={this.handleChange('depth')}*/}
                                    {/*    label={<Translate id={"crm.Depth"}/>}*/}
                                    {/*    value={this.state.depth}/>*/}
                                    <NumberLimitInput
                                        classNames={"mb-24 flex-1 mr-12"}
                                        id={"depth-dsjfkghsajkf"}
                                        onChange={this.handleChange("depth")}
                                        label={"crm.Depth"}
                                        unit={"crm.m"}
                                    />
                                </div>
                            </>
                        )}
                    </DialogContent>

                    <DialogActions>
                        {step === 1 ? (
                            <Button
                                onClick={() => {
                                    this.props.turnOff("product");
                                }}
                            >
                                <Translate id="_.cancel" />
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    this.setState({step: 1});
                                }}
                            >
                                <Translate id="_.back" />
                            </Button>
                        )}
                        <Button
                            disabled={this.state.fileLoading || this.state.name === ""}
                            color="secondary"
                            onClick={this.onSubmit}
                        >
                            {step === 1 ? <Translate id="_.next" /> : <Translate id="_.submit" />}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff,
            turnOn: fuseActions.turnOn,
            showMessage: fuseActions.showMessage,
            getProducts: Actions.getProducts,
            getProduct: Actions.getProduct,
        },
        dispatch
    );
}

function mapStateToProps({fuse, commerce}) {
    return {
        dialogs: fuse.dialogs,
        selectedProduct: commerce.products.selectedProduct,
        categories: commerce.products.categories,
        selectedBrand: commerce.brand.data,
    };
}

export default withMobileDialog()(
    withRouter(
        withStyles(styles, {withTheme: true})(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ProductDialog)))
    )
);
