import { turnOn, startLoading, finishLoading, toggleCheck, showMessage } from "@fuseActions";
import {ListItemSecondaryAction, ListItemText, Menu, MenuItem} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core/styles";
import {MyAvatar, MyTooltip, NameLabel} from "@ui";
import _ from "lodash";
import PropTypes from "prop-types";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import axios, {crmPrefix} from "../../../../../myaxios";
import {
    AcquaintanceRelationSvgIcon,
    DivergentRelationSvgIcon,
    FriendRelationSvgIcon,
    GeneralRelationSvgIcon,
    KeyManSvgIcon,
    NonKeyManSvgIcon,
    PartnerRelationSvgIcon,
    PeerRelationSvgIcon,
    RelationSvgIcon,
    StrangeRelationSvgIcon,
} from "../../activities/VendorIcons/VendorIcons";
import {ActiveDateChip, calTwoDates, haveDeleteAble, LabelChip} from "../../marketingTools";
import { getItem, selectItem, selectItemForEditCompany, getList } from "../store/actions";

const styles = () => ({
    item: {
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        "&.minor": {
            borderLeftColor: "#00D084",
        },
        "&.major": {
            borderLeftColor: "#FCB900",
        },
        "&.critical": {
            borderLeftColor: "#EB144C",
        },
    },
    triangle: {
        width: 12,
        right: 15,
        height: 12,
        background: "#DC353E",
        position: "absolute",
        borderRadius: 50,
        zIndex: 10,
    },
    menuIcon: {
        marginRight: 1,
    },
});

class ItemView extends Component {
    state = {
        anchorEl: null,
        relationsShips: [],
    };

    componentDidMount() {
        this.setState({
            relationsShips: this.props.relationsShips,
        });
    }

    componentDidUpdate = (prevProps) => {
        if (!_.isEqual(prevProps.relationsShips, this.props.relationsShips)) {
            this.setState({
                relationsShips: this.props.relationsShips,
            });
        }
    };

    componentWillUnmount() {
        localStorage.removeItem("getAllRelations");
    }

    handleClickRelationship = (event) => {
        event.stopPropagation();
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleCloseRelationship = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleChangeRelationship = (value) => {
        const params = {
            id: this.props.item.id,
            relationShip: value.key,
        };
        // delete params.company;
        this.props.startLoading();
        axios
            .put(`${crmPrefix}/companyContact`, {...params})
            .then(() => {
                this.props.finishLoading();
                this.props.showMessage({
                    message: "message.RelationShip changed!",
                    variant: "success",
                });
                this.props.getList(this.props.search);
            })
            .catch(() => {
                this.props.finishLoading();
            });
        this.handleCloseRelationship();
    };

    isValidRelationship = () => {
        const {item} = this.props;
        return item.relationShip !== "" && item.relationShip !== undefined && item.relationShip !== null;
    };

    getRelationIcon = (relation) => {
        switch (relation) {
            case "general":
                return <GeneralRelationSvgIcon />;
            case "strange":
                return <StrangeRelationSvgIcon />;
            case "friend":
                return <FriendRelationSvgIcon />;
            case "partner":
                return <PartnerRelationSvgIcon />;
            case "peer":
                return <PeerRelationSvgIcon />;
            case "divergent":
                return <DivergentRelationSvgIcon />;
            case "acquaintance":
                return <AcquaintanceRelationSvgIcon />;
            default:
                return <RelationSvgIcon />;
        }
    };

    getAllRelations = () => {
        const params = {type: "Sales Contact RelationShip"};
        axios.get(`${crmPrefix}/salesEnumProperties`, {params}).then((res) => {
            this.setState({
                relationsShips: res.data,
            });
        });
    };

    onDelete = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const {item} = this.props;
        this.props.selectItem(item);
        this.props.selectItemForEditCompany(null);
        this.props.turnOn("deletecontact");
    };

    setDecisionMaker = () => {
        const {item, search} = this.props;

        const parameter = {
            contactId: item.id,
            isDecisionMaker: !item.isDecisionMaker,
        };

        axios.put(`${crmPrefix}/contactSetDecisionMaker`, parameter).then(() => {
            const message =
                !item.decisionMaker === true
                    ? "message.current ComapnyContact has been setted decisionMaker!"
                    : "message.current ComapnyContact has been setted Non-decision maker!";
            this.props.showMessage({message: message, variant: "success"});
            this.props.getList(search);
        });
    };

    render() {
        const {item, name, getItem, turnOn, admin, checked, toggleCheck, listView} = this.props;

        const language = localStorage.getItem("i18nextLng");

        const isChecked = checked[name] ? checked[name].includes(item.id) : false;

        return (
            <>
                {/*<ListItem*/}
                {/*    button*/}
                {/*    divider={!small}*/}
                {/*    dense={small}*/}
                {/*    onClick={()=>{*/}
                {/*        getItem(item.id);*/}
                {/*        if(isElectron()) {*/}
                {/*            window.ipcRenderer.send( 'openNewWindow', {url: `/electron/company/${item.id}`, width: 900, height: 600})*/}
                {/*        } else turnOn('contactDetail');*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {admin && <Checkbox className={"-ml-2 mr-2"} onClick={(e)=>e.stopPropagation()} checked={isChecked} onChange={()=>toggleCheck(item.id, name)}/>}*/}

                <MyAvatar src={item.avatar} alt={item.fullName} className={" mr-12"} size={listView ? 24 : 40} />

                <ListItemText
                    classes={{root: listView ? "my-0" : ""}}
                    className={"text-base"}
                    primary={
                        <>
                            <MyTooltip title={haveDeleteAble(item) === true ? <Translate id={"market.deleted"} /> : <Translate id={"market.name"} />}>
                                {/*<a href={"#"} onClick={(e)=>{*/}
                                {/*    e.preventDefault();*/}
                                {/*    getItem(item.id);*/}
                                {/*    turnOn('contactDetail');*/}
                                {/*}}>*/}
                                <NameLabel className={haveDeleteAble(item) === true ? "line-through text-gray-500" : " "} user={item} />
                                {/*</a>*/}
                            </MyTooltip>
                            <MyTooltip title={<Translate id={"market.company"} />}>
                                <small className={"text-grey ml-2"}>
                                    <span>{item.company?.name}</span>
                                </small>
                            </MyTooltip>
                            <span className={"ml-8"}>
                                {" "}
                                {listView && item.labels && item.labels.length > 0 && item.labels.map((__, i) => <LabelChip key={i} label={__} />)}
                            </span>
                        </>
                    }
                    secondary={
                        !listView && <>{item.labels && item.labels.length > 0 && item.labels.map((__, i) => <LabelChip key={i} label={__} />)}</>
                    }
                />
                {
                    <ListItemSecondaryAction>
                        {item.overDate && item.overDate !== 0 && item.overDate !== "" && calTwoDates(item.overDate) !== 0 ? (
                            <ActiveDateChip overDate={item.overDate} />
                        ) : (
                            ""
                        )}

                        {!admin && (
                            <>
                                <MyTooltip
                                    title={
                                        this.isValidRelationship() === true ? (
                                            <Translate id={`market.${item.relationShip.key}ss`} />
                                        ) : (
                                            <Translate id={"market.no relationShip data, click to setting"} />
                                        )
                                    }>
                                    <Chip
                                        icon={this.isValidRelationship() === true ? this.getRelationIcon(item.relationShip.key) : <RelationSvgIcon />}
                                        aria-controls="relationShip-menu"
                                        aria-haspopup="true"
                                        className={"ml-3"}
                                        size={"small"}
                                        label={
                                            this.isValidRelationship() === true
                                                ? language === "cn"
                                                    ? item.relationShip.value
                                                    : item.relationShip.key
                                                : ""
                                        }
                                        clickable
                                        color={this.isValidRelationship() === true ? "primary" : "default"}
                                        onClick={this.handleClickRelationship}
                                    />
                                </MyTooltip>
                                <Menu
                                    id="relationShip-menu"
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleCloseRelationship}>
                                    {this.state.relationsShips ? (
                                        this.state.relationsShips.length > 0 ? (
                                            this.state.relationsShips.map((value) => (
                                                <MenuItem
                                                    key={value.key}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.handleChangeRelationship(value);
                                                    }}>
                                                    <ListItemText
                                                        primary={
                                                            <>
                                                                {this.getRelationIcon(value.key)}
                                                                <small className={"ml-1"}>{language === "cn" ? value.value : value.key}</small>
                                                            </>
                                                        }
                                                    />
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem onClick={this.handleCloseRelationship}>{<Translate id={"market.no relation data"} />}</MenuItem>
                                        )
                                    ) : (
                                        <MenuItem onClick={this.handleCloseRelationship}>{<Translate id={"market.no relation data"} />}</MenuItem>
                                    )}
                                </Menu>
                            </>
                        )}

                        {!admin && (
                            <MyTooltip title={<Translate id={item.isDecisionMaker ? "market.DecisionMaker" : "market.Not decisionMaker"} />}>
                                <IconButton
                                    onClick={() => {
                                        this.setDecisionMaker();
                                    }}>
                                    {item.isDecisionMaker === true ? <KeyManSvgIcon style={{color: "#d32f2f"}} /> : <NonKeyManSvgIcon />}
                                </IconButton>
                            </MyTooltip>
                        )}
                    </ListItemSecondaryAction>
                }
            </>
        );
    }
}

ItemView.propTypes = {
    item: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getItem: getItem,
            selectItem: selectItem,
            selectItemForEditCompany: selectItemForEditCompany,
            getList: getList,
            turnOn: turnOn,
            startLoading: startLoading,
            finishLoading: finishLoading,
            toggleCheck: toggleCheck,
            showMessage: showMessage,
        },
        dispatch
    );
}

function mapStateToProps({marketing, fuse}) {
    return {
        search: fuse.search,
        checked: fuse.checked,
        relationsShips: marketing.companyContacts.list.relationShips
    };
}

export default withRouter(withStyles(styles)(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ItemView))));
