import useCommonStore from "./useCommonStore";
import {useMemo} from "react";

function useIncomeCommonUtils() {
    // const currentIncome = useCommonStore((state) => state.currentIncome);

    // const onChangeCurrentIncome = useCommonStore((state) => state.onChangeCurrentIncome);

    const store = useCommonStore();

    const getIncome = useMemo(() => {
        return store.currentIncome;
    }, [store.currentIncome]);

    return {
        currentIncome: getIncome,
        onChangeCurrentIncome: store.onChangeCurrentIncome,
    };
}

export default useIncomeCommonUtils;
