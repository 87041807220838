import {Icon, List, ListItemSecondaryAction, ListItemText, Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import {DialogWrapper, EmptyView, LocalTimeStamp} from "@ui";
import { turnOn } from "@fuseActions";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import {firmPrefix} from "myaxios";
import {memo} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import useSWR from "swr";
import type {dataType} from "types";
import TRDialog from "../../travel-receipts/TRDialog";

const TrWidget = () => {
    const dispatch = useDispatch();
    // const [data, setData] = useState({totalElements: 0, content: []});
    // useEffect(() => getData(), []);
    const {data, isLoading, mutate} = useSWR<dataType<any>>(`${firmPrefix}/travelReceipts?sord=desc`, {
        revalidateOnMount: true
    });

    //
    // const getData = () => {
    //     dispatch(fuseActions.startLoading());
    //     axios.get(`${firmPrefix}/travelReceipts?sord=desc`).then(({data}) => {
    //         setData(data);
    //         dispatch(fuseActions.finishLoading());
    //     });
    // };

    return (
        <Paper className="rounded-8 mb-16  flex-1 mr-16">
            <DialogWrapper name={"tr"}>{() => <TRDialog getData={mutate} />}</DialogWrapper>
            <List
                classes={{root: "pb-0  max-h-384 overflow-y-scroll"}}
                subheader={
                    <ListSubheader
                        component="div"
                        className="flex  justify-between bg-white"
                        id="nested-list-subheader"
                    >
                        {<Translate id={"wps.Travel Receipts"} />} ({data?.totalElements})
                        <div>
                            <Button
                                color={"primary"}
                                startIcon={<Icon>add</Icon>}
                                variant={"contained"}
                                size={"small"}
                                onClick={() => dispatch(turnOn("tr"))}
                            >
                                {<Translate id={"_.add"} />}
                            </Button>
                        </div>
                    </ListSubheader>
                }
            >
                {!data || isLoading || data.content.length === 0 ? (
                    <EmptyView label={<Translate id={"wps.No Receipts"} />} />
                ) : (
                    data.content.map((tr) => (
                        <ListItem key={tr.id}>
                            <ListItemText
                                primary={tr.note}
                                secondary={
                                    <Typography variant={"caption"}>
                                        <LocalTimeStamp datetime={tr.startDate} />
                                        -
                                        <LocalTimeStamp datetime={tr.endDate} format="LL" />
                                    </Typography>
                                }
                            />
                            {
                                <Translate>
                                    {({translate}) => (
                                        <ListItemSecondaryAction className="flex flex-col text-right">
                                            <Typography variant={"caption"}>
                                                {tr.fieldDays} {translate("wps.days")}
                                            </Typography>
                                            <Typography variant={"caption"}>
                                                {translate("wps.amount")} :{tr.amount}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    )}
                                </Translate>
                            }
                        </ListItem>
                    ))
                )}
            </List>
        </Paper>
    );
};

export default memo(withLocalize(TrWidget));
