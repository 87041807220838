import {DialogContent, DialogTitle, Grid, Icon, IconButton, TextField, Typography} from "@material-ui/core";
import classNames from "classnames";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import EditorInput from "utils/EditorInput/EditorInput";
import FaveButton from "../../../../../components/layout/fav/FaveButton";
import {uploadFiles} from "../../../../../utils/fileUploader";
import {EnumFormControl, LabelsFormControl} from "../../marketingTools";

const Step0 = ({selectedItem, closeDialog, formData, handleFormData}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // const changeLabel = (value) => {
    //     const selectedLabels = [];
    //     if (value) {
    //         value.map(_i => selectedLabels.push(_i.value));
    //         handleFormData("labels")({target: {value: selectedLabels}})
    //     }
    // };

    const changeFile = (e) => {
        if (e.target.files) setLoading(true);
        uploadFiles(e.target.files, (status, files) => {
            handleFormData("avatar")({target: {value: files[0].visitUrl}});
            setLoading(false);
        });
    };

    return (
        <div>
            <DialogTitle id="alert-dialog-title" disableTypography classes={{root: "drag flex justify-between items-center px-24 py-8 rounded-t"}}>
                {selectedItem ? (
                    <Translate>
                        {({translate}) => (
                            <Typography className={"my-12"} variant={"h6"}>
                                {translate("market.Edit company with parameter", {name: selectedItem.name})}
                            </Typography>
                        )}
                    </Translate>
                ) : (
                    <>
                        <Typography variant={"h6"}>
                            <span className={"font-normal"}>
                                <Translate id="market.New Company" />
                            </span>
                        </Typography>
                    </>
                )}
                <div>
                    {!selectedItem && <FaveButton colored className={"-mr-8"} sourceId={"company"} title={"company"} type={"dialog"} />}
                    {/*<IconButton className={'no-drag'} onClick={()=>dispatch(fuseActions.minimize("company"))}><Icon>remove</Icon></IconButton>*/}
                    <IconButton className={"no-drag"} onClick={closeDialog}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className={"overflow-visible"}>
                <div className="flex md:flex-row flex-col items-center mb-16">
                    <label
                        className={classNames(
                            "bg-grey-lighter hover:bg-grey-light border cursor-pointer items-center flex w-128 h-128 rounded-full mr-12 text-center justify-center",
                            loading && "loading"
                        )}
                    >
                        <input type="file" accept="image/png, image/jpeg" onChange={changeFile} className="hidden" />
                        {formData.avatar !== "" && !loading ? (
                            <img style={{maxWidth: "100%"}} src={formData.avatar} alt={"logo"} />
                        ) : (
                            <Icon fontSize={"large"}>camera_enhance</Icon>
                        )}
                    </label>
                    <div className="flex-1">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Translate>
                                    {({translate}) => (
                                        <TextField
                                            InputLabelProps={{
                                                required: true
                                            }}
                                            required
                                            inputProps={{maxLength: 20}}
                                            fullWidth
                                            placeholder={translate("market.company's short name")}
                                            label={translate("market.shortName")}
                                            value={formData.name}
                                            onChange={handleFormData("name")}
                                        />
                                    )}
                                </Translate>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Translate>
                                    {({translate}) => (
                                        <TextField
                                            inputProps={{maxLength: 60}}
                                            fullWidth
                                            placeholder={translate("market.No more than 60 character")}
                                            label={translate("_.Full name")}
                                            value={formData.fullName}
                                            onChange={handleFormData("fullName")}
                                        />
                                    )}
                                </Translate>
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <LabelsFormControl source={"company"} formData={formData} handleFormData={handleFormData} />
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <EnumFormControl
                                    type={"SalesCompanyStatus"}
                                    deleteParam={"active"}
                                    name={"status"}
                                    formData={formData}
                                    handleFormData={handleFormData}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <EditorInput name={"description"} disableMention value={formData.description} onChange={handleFormData("description")} />
            </DialogContent>
        </div>
    );
};

export default Step0;
