import axios, {commonPrefix} from "@api";
import { turnOn } from "@fuseActions";
import {Button, Icon} from "@material-ui/core";
import {memo} from "react";
import {useDispatch} from "react-redux";

const FavoriteButton = () => {
    const dispatch = useDispatch();

    return (
        <Button
            data-tut={"favorates-list"}
            className="no-drag text-yellow-dark"
            onClick={() => {
                axios.post(`${commonPrefix}/clickCount`, {
                    type: "OPEN_MENU",
                    name: "favorites",
                });
                dispatch(turnOn("favorites"));
            }}>
            <Icon>star</Icon>
        </Button>
    );
};

export default memo(FavoriteButton);
