import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Money, UserView} from "@ui";
import {useClickAway} from "ahooks";
import {isDate} from "date-fns";
import {isArray, isObject} from "lodash";
import type {FC, ReactNode} from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import Moment from "react-moment";
import type {AttachmentType, ContactType} from "../../../../../types";
import DatePickerWrapper from "../../../../../utils/DatePickerWrapper/DatePickerWrapper";
import {uploadFiles} from "../../../../../utils/fileUploader";
import UserPreviewSearch from "../../../../forms/UserPreviewSearch";
import FileThumb from "../../../FileThumb";
import {useDGDef} from "../../hooks/hooks";
import useRows from "../../hooks/useRows";
import type {DGCellProps, RenderEditProps, ViewType} from "../../types";
import {addMissingElements, isTimestamp} from "../../utils";

const useTabKey = (callback) => {
    const keyPressHandle = (e) => {
        if (e.key === "Tab") {
            e.preventDefault();
            callback();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", keyPressHandle);
        return () => {
            window.removeEventListener("keydown", keyPressHandle);
        };
    }, [keyPressHandle]);
};

const EditText: FC<{type: "text" | "number"} & RenderEditProps> = ({initValue, onDone, type, fullView}) => {
    const divRef = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLInputElement>(null);
    // const {cols} = useDGDef()
    const [value, setValue] = useState(initValue || "");

    // const colIndex = cols.findIndex(x => x.id === col.id)

    // region handle keypress
    const keyPressHandle = useCallback(
        (e) => {
            // if (e.keyCode === 13) {
            //     // insert 2 br tags (if only one br tag is inserted the cursor won't go to the next line)
            //     document.execCommand('insertHTML', false, '\n <br /><br />');
            //     // prevent the default behaviour of return key pressed
            //     // return false;
            //     e.preventDefault()
            // }
            // console.log("value form editor", value);
            if (document.activeElement === ref.current) {
                if (e.key === "Enter") {
                    onDone(value);
                }
                if (e.key === "Tab") {
                    e.preventDefault();
                    onDone(value, true);
                }
                // if (e.key === "Tab") {
                //     e.preventDefault()
                //     if (colIndex !== cols.length - 1)
                //         setFocusedEdit(row.id + "-" + cols[colIndex === cols.length - 1 ? 0 : colIndex + 1].id)
                // }
                // if(e.key === "ArrowRight") {
                //
                //     e.preventDefault()
                //     if(colIndex !== cols.length - 1)
                //     setFocusedEdit(row.id + "-" + cols[colIndex + 1].id)
                // }
                // if(e.key === "ArrowLeft") {
                //
                //     e.preventDefault()
                //     if(colIndex > 0)
                //     setFocusedEdit(row.id + "-" + cols[colIndex - 1].id)
                // }
                // if(e.key === "ArrowDown") {
                //
                //     e.preventDefault()
                //     if(nextRowId)
                //     setFocusedEdit(nextRowId+ "-" + col.id)
                // }
                // if(e.key === "ArrowUp") {
                //
                //     e.preventDefault()
                //     if(prevRowId)
                //     setFocusedEdit(prevRowId + "-" + col.id)
                // }
            }
        },
        [value]
    );

    useEffect(() => {
        window.addEventListener("keydown", keyPressHandle);
        return () => {
            window.removeEventListener("keydown", keyPressHandle);
        };
    }, [keyPressHandle]);

    // useClickAway(() => {
    //     onDone(value);
    // }, divRef);

    return fullView ? (
        <TextField
            InputProps={{
                endAdornment: type === "number" ? <Icon children={"pin"} /> : null,
            }}
            onBlur={() => onDone(value)}
            type={type}
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    ) : (
        <div
            ref={divRef}
            className={"min-w-full h-full absolute top-0 left-0 bg-white flex items-stretch shadow-2 z-9999"}
            style={{marginLeft: -5, paddingLeft: 5}}>
            <input
                onBlur={() => onDone(value)}
                className={"pr-2"}
                ref={ref}
                type={type}
                autoFocus
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
        </div>
    );
};

const FileUploader: FC<RenderEditProps> = (props) => {
    const {onDone, fullView, initValue, onCancel} = props;
    useTabKey(onCancel);

    const fileUploader = useRef<HTMLInputElement>(null);
    const [fileLoading, setFileLoading] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState(initValue);
    useClickAway(() => {
        onCancel();
    }, divRef);
    const changeFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFileLoading(true);
            uploadFiles(e.target.files, (status, data) => {
                setFileLoading(false);
                if (status) {
                    setValue(data);
                    onDone(data);
                }
            });
        }
    };

    return (
        <div className={"flex items-center"} ref={divRef}>
            {isArray(value) && value.length > 0
                ? value?.map?.((file) => (
                      <span className={"mr-2"}>
                          <FileThumb download size={fullView ? "40" : "20"} file={file} />
                      </span>
                  ))
                : renderValue(value)}

            <input ref={fileUploader} multiple type="file" className={"hidden"} onChange={changeFile} />

            <button
                className={
                    fullView
                        ? "px-12 py-12 border-1 flex duration-200 items-center justify-center border-solid bg-gray-100 hover:shadow-1 border-gray-200 rounded-8 text-gray-500"
                        : "absolute  bg-white right-0  top-0 text-center h-full w-24 text-gray-400  hover:text-gray-800 duration-200"
                }
                disabled={fileLoading}
                onClick={() => {
                    fileUploader.current?.click();
                }}>
                <Icon className={fileLoading ? "animate-spin " : " "}>{fileLoading ? "rotate_right" : "file_upload"}</Icon>
            </button>
        </div>
    );
};

const SelectOption: FC<RenderEditProps> = (props) => {
    const {initValue, onDone, col, fullView} = props;
    // const divRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState(initValue || "");
    useTabKey(() => onDone(value, true));
    const {onChangeCol} = useDGDef();

    // useClickAway(() => {
    //     if (col.options?.findIndex((c) => c === value) === -1) {
    //         onChangeCol({...col, options: [...col.options, value]})
    //     }
    //     onDone(value);
    // }, divRef);

    const renderInside = (
        <Autocomplete
            freeSolo
            onBlur={() => {
                if (value && col.options?.findIndex((c) => c === value) === -1) {
                    onChangeCol({...col, options: [...col.options, value]});
                }
                onDone(value);
            }}
            onInputChange={(e, v) => setValue(v)}
            value={value}
            // open={!fullView}
            openOnFocus
            PaperComponent={(p) => (
                <Paper {...p} square classes={{root: "my-0"}} style={{marginLeft: -5}}>
                    {p.children}
                </Paper>
            )}
            ListboxProps={{className: "p-0"}}
            options={col.options || []}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus={!fullView}
                    InputProps={{
                        ...params.InputProps,
                        classes: !fullView ? {root: "p-0 h-full"} : {},
                        disableUnderline: !fullView,
                    }}
                    inputProps={{...params.inputProps, className: fullView ? "" : "p-0 h-full"}}
                    fullWidth
                    classes={{root: fullView ? "" : "p-0 h-full"}}
                />
            )}
            onChange={(e, v) => {
                setTimeout(() => {
                    onDone(v);
                }, 10);
            }}
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    onDone(value);
                }
            }}
            classes={{option: "py-6 px-8", root: "w-full h-full "}}
        />
    );

    return fullView ? (
        renderInside
    ) : (
        <div style={{marginLeft: -5, paddingLeft: 5}} className={"w-full h-full  absolute top-0 min-w-192 z-99 bg-white shadow-2 "}>
            {renderInside}
        </div>
    );
};

const MultipleOption: FC<RenderEditProps> = ({initValue, onDone, col, fullView}) => {
    const [value, setValue] = useState<string[]>(initValue && isArray(initValue) ? initValue : []);
    const {onChangeCol} = useDGDef();

    useTabKey(() => onDone(value, true));
    const renderInside = (
        <Autocomplete
            id="free-solo-demo"
            freeSolo
            onChange={(e, newValue) => {
                setValue(newValue);
            }}
            disableCloseOnSelect
            value={value}
            multiple
            openOnFocus
            PaperComponent={(p) => (
                <Paper {...p} square classes={{root: "my-0"}} style={{marginLeft: -5}}>
                    {p.children}
                </Paper>
            )}
            ChipProps={{size: "small"}}
            onBlur={() => {
                if (value) {
                    // if there is a different and it is not in the options, add it
                    if (col.options && col.options.length > 0) {
                        const t = addMissingElements(col.options, value);
                        console.log({t, opt: col.options});
                        if (t.length > col.options.length) onChangeCol({...col, options: t});
                    } else {
                        onChangeCol({...col, options: value});
                    }
                }

                onDone(value);
            }}
            ListboxProps={{className: "p-0 "}}
            options={col.options || []}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus={!fullView}
                    InputProps={{
                        ...params.InputProps,
                        classes: !fullView ? {input: "px-2 pt-2", root: "p-0 h-full"} : {},
                        disableUnderline: !fullView,
                    }}
                    inputProps={{...params.inputProps, className: fullView ? "" : "p-0 h-full"}}
                    fullWidth
                    classes={{root: fullView ? "" : "p-0 h-full"}}
                />
            )}
            classes={{option: "py-6 px-8", root: "w-full h-full "}}
        />
    );

    return fullView ? (
        renderInside
    ) : (
        <div style={{marginLeft: -5, paddingLeft: 5}} className={"w-full h-full  absolute top-0 min-w-192 z-99 bg-white shadow-2"}>
            {renderInside}
        </div>
    );
};

const UserEditView: FC<RenderEditProps> = ({initValue, onDone, onCancel, fullView}) => {
    useTabKey(() => onDone(initValue, true));
    const divRef = useRef<HTMLDivElement>(null);
    useClickAway(() => {
        onCancel();
    }, divRef);
    const onChange = (value?: ContactType) => {
        const t = {
            id: value?.user?.id,
            lastName: value?.user?.lastName,
            firstName: value?.user?.firstName,
            username: value?.user?.username,
        };
        onDone(value ? t : null);
    };

    const renderInside = (
        <UserPreviewSearch
            defaultValue={initValue?.id}
            InputProps={{disableUnderline: !fullView}}
            label={"Select User"}
            margin={"none"}
            className={!fullView ? "flex-grow shadow-2 pl-1 bg-white min-w-full" : ""}
            onChange={onChange}
        />
    );
    return fullView ? (
        renderInside
    ) : (
        <div
            ref={divRef}
            style={{marginLeft: -5}}
            className={"absolute z-9999 min-w-full bg-white top-0  h-full"}
            onClick={(event) => event.stopPropagation()}>
            {renderInside}
        </div>
    );
};

const CheckboxView = ({initValue, onDone, fullView, col}: RenderEditProps) => {
    const [value, setValue] = useState(initValue || false);

    const {permission, isOwner} = useDGDef();

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    return (!col?.disableEdit && permission !== "readonly" && permission !== "disabled") || isOwner ? (
        <>
            <Checkbox
                onChange={(e) => {
                    onDone(e.target.checked);
                    setValue(e.target.checked);
                }}
                checked={value || false}
                className={!fullView ? "p-0" : ""}
            />
            {value && typeof value !== "boolean" && renderValue(initValue)}
        </>
    ) : !value || typeof value === "boolean" ? (
        value ? (
            <Icon children="check" />
        ) : (
            <Icon children="close" />
        )
    ) : (
        renderValue(initValue)
    );
};

const renderValue = (value) => {
    if (value === undefined) return "";
    let t;
    switch (typeof value) {
        case "string":
            t = value;
            break;
        case "number":
            t = value.toFixed(2);
            break;
        case "boolean":
            t = value ? <Icon children="check" /> : <Icon children="close" />;
            break;
        case "object":
            if (isArray(value)) t = value.join(",");
            else if (isDate(value)) t = value.toLocaleDateString();
            else if (isObject(value)) t = JSON.stringify(value);
            else return value.toString();
            break;
        default:
            t = "Value is wrong";
    }
    return <span className={"text-red-lighter"}>{t}</span>;
};

const DateEditView: FC<RenderEditProps> = ({initValue, onDone, onCancel, fullView}) => {
    useTabKey(() => onDone(initValue, true));
    const divRef = useRef<HTMLDivElement>(null);
    useClickAway(() => {
        onCancel();
    }, divRef);

    const renderInside = (
        <div className={"relative"}>
            <div className={!fullView ? "fixed z-9999 bg-white shadow-2" : ""}>
                <DatePickerWrapper
                    // disableToolbar
                    // TextFieldComponent={(p) => <TextField
                    //     {...p}
                    //     // inputProps={{
                    //     //     ...p.inputProps,
                    //     //     className: !fullView ? "flex-grow top-0 absolute rounded-0 z-9999 shadow-2 px-2 w-160 bg-white" : "",
                    //     //     autoFocus: true
                    //     // }}
                    //     size={"small"}/>}
                    label={<Translate id={"_.Date"} />}
                    autoOk
                    variant={"static"}
                    value={initValue || null}
                    // placeholder={translate('_.From')}
                    // @ts-ignore
                    onChange={(date) => onDone(+new Date(date))}
                />
            </div>
        </div>
    );
    return fullView ? (
        renderInside
    ) : (
        <div ref={divRef} className={"relative -ml-4 w-full h-full"} onClick={(event) => event.stopPropagation()}>
            {renderInside}
        </div>
    );
};

export const viewTypeToView: Record<
    ViewType,
    {
        renderEdit: (params: RenderEditProps) => ReactNode;
        render: (params: Partial<RenderEditProps>) => ReactNode;
    }
> = {
    user: {
        renderEdit: (params) => <UserEditView {...params} />,
        render: (params) =>
            typeof params.initValue === "object" && !isArray(params.initValue) ? (
                <UserView dense noAvatar noUsername user={params.initValue} />
            ) : (
                renderValue(params.initValue)
            ),
    },
    currency: {
        renderEdit: (params) => <EditText {...params} type={"number"} />,
        render: (params) => (!isNaN(Number(params.initValue)) ? <Money amount={Number(params.initValue)} /> : renderValue(params.initValue)),
    },
    date: {
        renderEdit: (params) => <DateEditView {...params} />,
        render: (params) =>
            isTimestamp(params.initValue) ? (
                <>
                    <Moment local format={"YYYY-MM-DD"}>
                        {params.initValue}
                    </Moment>
                </>
            ) : (
                renderValue(params.initValue)
            ),
    },
    // title: {
    //     renderEdit: (params) => <EditText {...params} type={"text"} />,
    //     render: (params) => (typeof params.initValue === "string" ? <ItemTitle>{params.initValue}</ItemTitle> : renderValue(params.initValue)),
    // },
    file: {
        renderEdit: (params) => <FileUploader {...params} />,
        render: (params) =>
            isArray(params.initValue) && params.initValue.length > 0
                ? params.initValue?.map?.((file) => (
                      <span key={file.id} className={"mr-2"}>
                          <FileThumb download size={"20"} file={file} />
                      </span>
                  ))
                : renderValue(params.initValue),
    },
    // size: {
    //     renderEdit: (params) => <EditText {...params} type={"number"} />,
    //     render: (params) => (!isNaN(Number(params.initValue)) ? formatSizeUnits(params.initValue) : renderValue(params.initValue)),
    // },
    checkbox: {
        renderEdit: (params) => <CheckboxView {...params} />,
        // @ts-ignore
        render: (params) => <CheckboxView {...params} />,
    },
    text: {
        renderEdit: (params) => <EditText {...params} type={"text"} />,
        render: (params) => (typeof params.initValue === "string" ? params.initValue : renderValue(params.initValue)),
    },
    number: {
        renderEdit: (params) => <EditText {...params} type={"number"} />,
        render: (params) => (!isNaN(Number(params.initValue)) ? params.initValue : renderValue(params.initValue)),
    },
    singleOption: {
        renderEdit: (params) => <SelectOption {...params} />,
        render: (params) => (typeof params.initValue === "string" ? <>{params.initValue}</> : renderValue(params.initValue)),
    },
    multiOption: {
        renderEdit: (params) => <MultipleOption {...params} />,
        render: (params) => (isArray(params.initValue) && params.initValue.length > 0 ? params.initValue.join(", ") : renderValue(params.initValue)),
    },
    thumb: {
        renderEdit: () => null,
        render: ({row, initValue}) => (row?.visitUrl ? <FileThumb download size={"40"} file={row as AttachmentType} /> : renderValue(initValue)),
    },
};

const DGCell: FC<DGCellProps> = ({row, col, focusedEdit, setFocusedEdit, nextFocus}) => {
    const {onChangeCell} = useRows();
    const initVal = row[col.id];
    const onDone = (value: any, shouldGoNext?: boolean) => {
        setFocusedEdit(shouldGoNext && nextFocus ? nextFocus : "");
        // console.log({value, initVal, changes: !(!initVal && (value === "" || !value)) && initVal !== value});
        if (!(!initVal && (value === "" || !value)) && initVal !== value) onChangeCell(row.id, col.id, value);
    };

    const onCancel = () => {
        setFocusedEdit("");
    };
    const colType = viewTypeToView[col.viewType];
    return focusedEdit ? (
        <>
            {colType.renderEdit({
                initValue: row[col.id],
                onDone,
                onCancel,
                col,
                row,
            })}
        </>
    ) : (
        <span className={"flex"}>{colType.render({initValue: row[col.id], onDone, col, row})}</span>
    );
};

DGCell.displayName = "DGColView";

export default DGCell;
