import {useUpdateEffect} from "ahooks";
import _ from "lodash";
import {parse} from "qs";
import type {ReactNode} from "react";
import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import useSWR from "swr";
import {usePersonalized} from "../../../hooks/usePersonalized";
import type {dataType, SearchType} from "../../../types";
import {createSearchParams} from "../../../utils/utilities";
import {rowsPerPage} from "../../../variables";
import {FiltersContext} from "./hooks/filtersContext";
import {useMainContext} from "./hooks/mainContext";
import type {FilterType} from "./types";

export const defaultIgnoreParams = ["keyword", "page", "size", "sord", "sidx"];
// const nonFilter = ["keyword", "size", "page", "sord", "sidx"];

const FiltersProvider = <TItem extends {id: string}>({children}: {children: ReactNode}) => {
    const {link, sidebarActions} = useMainContext();
    const {getFilter, getSetting, addOrUpdateFilters, deleteFilter} = usePersonalized();
    const history = useHistory();

    const [filters, setFilters] = useState<FilterType>({});
    const swr = useSWR<dataType<TItem> | TItem[]>([link, filters]);
    const {data} = swr;

    const totalElements = data && "totalElements" in data ? data.totalElements : 0;
    const totalPages = data && "totalPages" in data ? data.totalPages : 0;

    const addFilter = (filter: SearchType) => {
        setFilters({...filters, page: 1, ...filter});
    };

    const removeFilter = (key: string | string[]) => {
        const newFilters = {...filters};
        if (typeof key === "string") delete newFilters[key];
        else if (Array.isArray(key)) key.forEach((k) => delete newFilters[k]);
        newFilters.page = 1;
        setFilters(newFilters);
    };

    const clearFilters = async () => {
        const sizeFromStorage = await getSetting("rowPerPage");
        await deleteFilter(link);
        setFilters({page: 1, size: sizeFromStorage});
    };

    const replaceFilters = async (newFilters: SearchType) => {
        const sizeFromStorage = await getSetting("rowPerPage");

        setFilters({page: 1, size: sizeFromStorage, ...newFilters});
    };

    useUpdateEffect(() => {
        history.replace({
            pathname: location.pathname,
            search: `?${createSearchParams(filters)}`,
        });
        const s = {...filters};
        delete s.page;
        delete s.size;
        delete s.keyword;
        delete s.sord;
        delete s.sidx;
        if (Object.keys(s).length > 0) addOrUpdateFilters(link, s);
        else {
            deleteFilter(link);
        }
    }, [filters]);

    const hasFilters =
        Object.keys(filters).findIndex((key) => {
            return !defaultIgnoreParams.includes(key);
        }) > -1;

    useEffect(() => {
        if (hasFilters) sidebarActions.setRight();
    }, [hasFilters]);

    function replaceAndGet(t: SearchType) {
        const x = {...t};
        setFilters(x);
    }

    const init = async () => {
        const parsedQueryString = parse(location.search, {ignoreQueryPrefix: true});
        const x = await getFilter(link);
        const sizeFromStorage = await getSetting("rowPerPage");
        const sizeT: number =
            data && "size" in data && data.size > 0
                ? _.toNumber(data.size)
                : filters.size
                ? _.toNumber(filters.size)
                : sizeFromStorage || rowsPerPage;

        // setFilters((x) => ({...x, size: sizeT}));

        if (x) {
            const t = {...x};
            delete t.key;
            delete t.userId;
            t.size = sizeT;
            // sidebarActions.(
            //     Object.keys({...t, ...parsedQueryString}).findIndex((key) => {
            //         return !nonFilter.includes(key);
            //     }) > -1
            // );
            replaceAndGet({...t, page: 1, ...parsedQueryString});
        } else {
            // setSidebar(
            //     Object.keys(parsedQueryString).findIndex((key) => {
            //         return !nonFilter.includes(key);
            //     }) > -1
            // );
            replaceAndGet({size: sizeT, page: 1, ...parsedQueryString});
        }
    };

    useEffect(() => {
        init();
    }, []);

    const value = {
        swr,
        filters,
        addFilter,
        removeFilter,
        clearFilters,
        replaceFilters,
        hasFilters,
        totalPages: totalPages,
        totalElements: totalElements,
    };
    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export default FiltersProvider;
