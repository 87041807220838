import * as fuseActions from "@fuseActions";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

function useCloseDialog() {
    const dispatch = useDispatch();
    return useCallback(
        (identifier) => {
            dispatch(fuseActions.turnOff(`${identifier}`));
        },
        [dispatch]
    );
}

export default useCloseDialog;
