export const createSingleSlice = (set, get) => ({
    currentUserTask: null,
    /**
     * @param {Object} userTaskVariable
     * @param {Object} userTaskVariable.variable
     * @param {Object} userTaskVariable.userTask
     */
    userTaskVariable: null,
    onChangeUserTaskVariable: (data) => {
        set({
            userTaskVariable: data,
        });
    },
    onChangeCurrentUserTask: (data) => {
        set({
            currentInstance: data,
        });
    },
});
