import {StateCreator} from "zustand";
import {LXExcelSlice} from "./constant";


export const createLXExcelSlice: StateCreator<LXExcelSlice> =  (set) => ({
    lxExcelDatasource: [],
    originalExcelFile: null,
    // 设置Excel数据源
    onChangeOriginalExcelFile: (originalExcelFile) => {
        return new Promise((resolve, _reject) => {
            set({ originalExcelFile: originalExcelFile });
            resolve();
        });
    },
    // 更新Excel数据源
    onChangeLXExcelDatasource: (excelDatasource) => {
        set({ lxExcelDatasource: excelDatasource });
    }
});
