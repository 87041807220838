import { toggleCheck, turnOn, showMessage } from "@fuseActions";
import {Chip, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import {MyTooltip} from "@ui";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {haveDeleteAble} from "../../marketingTools";
import { getItem, selectItem, getList } from "../store/actions";

class ItemView extends Component {
    onClickItem = () => {
        const {item, getItem, turnOn, width} = this.props;
        if (width === "sm" || width === "xs") {
            this.props.history.push(`/deal/${item.id}`);
        } else {
            turnOn("dealDetail");
            getItem(item.id);
        }
    };

    render() {
        const {item, small, admin, toggleCheck, checked, name} = this.props;
        const isChecked = checked[name] ? checked[name].includes(item.id) : false;

        const language = localStorage.getItem("i18nextLng");

        return (
            <>
                <div className={"flex items-center"}>
                    <ListItemText
                        className={"text-base mt-0 font-bold"}
                        primary={
                            <>
                                <strong className={"capitalize "}>
                                    <MyTooltip title={haveDeleteAble(item) === true ? <Translate id={"market.deleted"} /> : ""}>
                                        <button
                                            onClick={this.onClickItem}
                                            className={classNames(
                                                "text-xl nowrap max-w-full",
                                                haveDeleteAble(item) === true ? "line-through text-gray-500" : ""
                                            )}>
                                            {item.name}
                                        </button>
                                    </MyTooltip>
                                    {item.company && (
                                        <MyTooltip title={<Translate id={"market.Company"} />}>
                                            <span className={"text-grey ml-2"}>{item.company.name}</span>
                                        </MyTooltip>
                                    )}
                                </strong>
                            </>
                        }
                        secondary={<>{item.leadSource && <small>{item.leadSource.value}</small>}</>}
                    />
                </div>
                <ListItemSecondaryAction>
                    {item.amount > 0 && (
                        <Chip
                            className={classNames("text-white px-2 mx-3")}
                            label={item.amount + "万"}
                            size={"small"}
                            color={"primary"}
                            variant={"default"}
                        />
                    )}
                    <span className="font-bold text-grey-dark">{item.stage && language === "cn" ? item.stage.value : item.stage.key}</span>
                    <small
                        className={classNames(
                            "rounded-4 ml-2 font-bold px-2 text-white ",
                            item.probability < 100 && item.probability > 0 ? "bg-orange" : item.probability === 100 ? "bg-green" : "bg-red"
                        )}>
                        {item.probability}%
                    </small>
                </ListItemSecondaryAction>
            </>
        );
    }
}

ItemView.propTypes = {
    item: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getItem: getItem,
            selectItem: selectItem,
            toggleCheck: toggleCheck,
            getList: getList,
            turnOn: turnOn,
            showMessage: showMessage,
        },
        dispatch
    );
}

function mapStateToProps({fuse}) {
    return {
        checked: fuse.checked,
    };
}

export default withWidth()(withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ItemView))));
