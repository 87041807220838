import {Icon, IconButton} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {useToggle} from "ahooks";
import clsx from "clsx";
import type {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import ExcelBtn from "./components/ExcelBtn";
import SaveSearch from "./components/SaveSearch";
import SearchBox from "./components/SearchBox";
import FiltersProvider from "./FiltersProvider";
import Header from "./Header";
import type {FiltersContextType} from "./hooks/filtersContext";
import {useFilters} from "./hooks/filtersContext";
import {MainContext} from "./hooks/mainContext";
import ShowFilters from "./ShowFilters";
import type {MainProps} from "./types";
import Divider from "@material-ui/core/Divider";

function FilterWrapper(props: {renderFilters: (filter: FiltersContextType) => ReactNode}) {
    const filter = useFilters();
    return props.renderFilters(filter);
}

function Inner(props: {children: ReactNode | ((props: {filterContext: FiltersContextType}) => ReactNode)}) {
    const filterContext = useFilters();
    return <>{typeof props.children === "function" ? props.children({filterContext}) : props.children}</>;
}

export const FilterableData = <TItem extends {id: string}>(props: MainProps<TItem>) => {
    const {link, headerOptions, disableTextSearch, disableSavingFilters, renderFilters, excel, children, miniFilters} = props;
    const {t} = useTranslation();
    const [sidebar, sidebarActions] = useToggle();
    const [saving, savingActions] = useToggle();

    return (
        <MainContext.Provider value={{saving, savingActions, sidebar, sidebarActions, link, miniFilters}}>
            <FiltersProvider<TItem>>
                <Header {...headerOptions} />

                <div className={"w-full mb-16"} data-cy={`${link}Wrapper`}>
                    {/* region show filters and open sidebar btn */}

                    <div className={"px-12 pb-12  md:flex w-full items-center"}>
                        <div className="flex">
                            {typeof renderFilters === "function" && !miniFilters && (
                                <Button
                                    data-cy={"toggle-filter"}
                                    disableRipple
                                    variant={"contained"}
                                    className={"bg-white mr-12"}
                                    onClick={() => sidebarActions.toggle()}
                                    startIcon={<Icon>{sidebar ? "arrow_back" : "filter_alt"}</Icon>}>
                                    {sidebar ? t("Close Filters") : t("open Filters")}
                                </Button>
                            )}
                            {miniFilters && <div data-cy={"toggle-filter"}></div>}
                            {!disableTextSearch && <SearchBox />}
                        </div>
                        <ShowFilters noSaveSearch={disableSavingFilters || miniFilters} />
                    </div>

                    {/* endregion */}

                    <div className={clsx(miniFilters ? "" : "flex w-full")}>
                        {/* region sidebar */}
                        {typeof renderFilters === "function" && (
                            <>
                                {/* region mobile sidebar btn */}
                                {sidebar && !miniFilters && (
                                    <div className={"md:hidden"}>
                                        <IconButton
                                            onClick={() => sidebarActions.setLeft()}
                                            className={"absolute top-0 right-0 z-999 bg-white hover:bg-red-dark hover:text-white"}>
                                            <Icon>close</Icon>
                                        </IconButton>
                                        <div
                                            onClick={() => sidebarActions.setLeft()}
                                            className={"bg-black opacity-50 absolute top-0 z-99 w-full h-full"}
                                        />
                                    </div>
                                )}
                                {/* endregion */}

                                <div
                                    className={
                                        sidebar && !miniFilters
                                            ? "md:min-w-288 md:relative p-24 md:p-0 left-0 w-full md:w-auto top-0 absolute z-99 flex-shrink-0"
                                            : ""
                                    }>
                                    {sidebar && !miniFilters && (
                                        <Card className="bg-white h-full pb-12  md:rounded-8 md:ml-12 overflow-visible">
                                            {!disableSavingFilters && <SaveSearch name={link} />}

                                            {!saving && <FilterWrapper renderFilters={renderFilters} />}

                                            {excel && (
                                                <div className={"mb-24"}>
                                                    <Divider className={"my-12"} />
                                                    <ExcelBtn<TItem> {...excel} />
                                                </div>
                                            )}
                                        </Card>
                                    )}
                                </div>

                                {miniFilters && (
                                    <div className="pl-12 flex  flex-wrap">
                                        <FilterWrapper renderFilters={renderFilters} />
                                        {excel && <ExcelBtn<TItem> {...excel} />}
                                    </div>
                                )}
                            </>
                        )}

                        {/* endregion */}

                        {/* region content */}
                        <Card className="mx-12 flex-1 relative">
                            {/*{selectOptions && <SelectBar name={link} actions={selectOptions.selectActions} />}*/}
                            <Inner children={children} />
                        </Card>
                        {/* endregion */}
                    </div>
                </div>
            </FiltersProvider>
        </MainContext.Provider>
    );
};
