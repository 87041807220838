import {combineReducers} from "redux";
import products from "./products.reducer";
import product from "./product.reducer";
import orders from "./orders.reducer";
import order from "./order.reducer";
import brands from "./brands.reducer";
import brand from "./brand.reducer";
import customer from "./customer.reducer";
import customers from "./customers.reducer";
import projects from "./projects.reducer";
import project from "./project.reducer";
import solutions from "./solutions.reducer";
import templates from "./templates.reducer";
import template from "./template.reducer";


const reducer = combineReducers({

    products,
    product,

    orders,
    order,

    brand,
    brands,

    customer,
    customers,

    projects,
    project,

    templates,
    template,

    solutions,
});

export default reducer;
