import {Chip, Icon, IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import {MyAvatar, MyTooltip, NameLabel} from "@ui";
import {useMemoizedFn} from "ahooks";
import classNames from "classnames";
import random from "lodash/random";
import {Translate} from "react-localize-redux";
import Select from "react-select/async";
import {useMUIDialog} from "../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../myaxios";
import useAsyncSelectUtils from "../../../../utils/RootComponents/FilterViews/utils/useAsyncSelectUtils";
import {components} from "../../../../utils/Select2Components";
import {searchContact} from "../../../../utils/utilities";
import {CategoriesStatusStyle, CategoryStatusStylePseudo, useStoreUtils} from "../management/categories/state";
import StatusView from "../management/instances/StatusView";

export const getRandomId = () => {
    return random(2222, 99999999999999);
};

export const NEW_CATEGORY_TAG = "new_category_tag";

export const DiagramType = {
    definition: "[Definition_details]",
    instance: "[Instance_details]",
    decision: "[Decision_details]",
    userTask: "[UserTask_details]"
};

export const getComponent = ({item, onChange, handleOpenUser, onChangeMultiUser}) => {
    let users = "";

    const handleMultiUsers = (tus) => {
        users = tus;
        const res = tus.map((t) => t.value);
        onChangeMultiUser(res);
    };

    switch (item.type) {
        case "String":
        default:
            return (
                <TextField
                    required
                    fullWidth
                    id={`${item.id}-value`}
                    disabled={item.name === "" || item.type === ""}
                    label={<Translate id={"_.variable value"} />}
                    value={item.value}
                    onChange={handleMultiUsers}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {item.type === "String" ? (
                                    <MyTooltip title={<Translate id={"_.Click Setup Staff"} />}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleOpenUser}
                                            disabled={item.name === "" || item.type === ""}
                                            edge="end"
                                        >
                                            <Icon>group_add</Icon>
                                        </IconButton>
                                    </MyTooltip>
                                ) : (
                                    <></>
                                )}
                            </InputAdornment>
                        )
                    }}
                />
            );
        case "Boolean":
            return (
                <TextField
                    id={`${item.id}-value`}
                    select
                    fullWidth
                    disabled={item.name === "" || item.type === ""}
                    label={<Translate id={"_.variable value"} />}
                    value={item.value}
                    onChange={onChange}
                    SelectProps={{
                        native: true
                    }}
                    variant="outlined"
                >
                    <option value={""}>{""}</option>
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                </TextField>
            );
        case "Short":
        case "Integer":
        case "Long":
        case "Double":
            return (
                <TextField
                    required
                    fullWidth
                    disabled={item.name === "" || item.type === ""}
                    id={`${item.id}-value`}
                    label={<Translate id={"_.variable value"} />}
                    value={item.value}
                    onChange={onChange}
                    variant="outlined"
                    type="number"
                />
            );
        case "List":
            return (
                <Translate>
                    {({translate}) => (
                        <Select
                            required
                            className="w-full"
                            textFieldProps={{
                                margin: "normal",
                                required: true,
                                label: translate("_.stuff"),
                                InputLabelProps: {
                                    shrink: true
                                }
                            }}
                            isMulti
                            // margin={"normal"}
                            loadOptions={searchContact}
                            defaultOptions
                            // styles={selectStyles}
                            components={components}
                            value={item.value}
                            onChange={onChangeMultiUser}
                            placeholder={translate("process.Search a user")}
                            isClearable
                        />
                    )}
                </Translate>
            );
    }
};

export const StatusColor = (item) => {
    switch (item.status) {
        case "FAIL": {
            return "bg-red-lighter text-red-darker";
        }
        case "PAUSE": {
            return "bg-purple-lighter text-purple-darker";
        }
        case "INCIDENT": {
            return "bg-yellow-lighter text-yellow-darker";
        }
        case "REJECT": {
            return "bg-red-lighter text-red-darker";
        }
        case "STOP": {
            return "bg-orange-lighter text-orange-darker";
        }
        case "PENDING": {
            return "bg-yellow-lighter text-yellow-darker";
        }
        case "APPROVED": {
            return "text-purple-darker bg-purple-lighter";
        }
        case "PASS": {
            return "text-purple-darker bg-purple-lighter";
        }
        case "PROCESS": {
            return "text-blue-dark bg-blue-lighter";
        }
        case "INPROCESS": {
            return "text-blue-dark bg-blue-lighter";
        }
        case "FINISH": {
            return "text-green-darker bg-green-lighter";
        }
        case "COMPLETED": {
            return "text-green-darker bg-green-lighter";
        }
        default:
            return "";
    }
};

export const UserView = ({user}) => {

    console.debug("改签的参数",user);

    const item = user;

    return <span className="flex flex-row items-center">
        <MyAvatar user={item} className={'mr-3'}/>
        <span className="flex flex-col">
            <span className="flex flex-row items-center nowrap">
                <strong>
                    {' '}
                    <NameLabel user={item}/>{' '}
                </strong>
                <small className={'text-grey-dark mr-2'}>@{item?.username}</small>
            </span>
            <span className="flex flex-row  items-center -mt-2 nowrap">
                {item?.departmentNames && item?.departmentNames?.length > 0 ? (
                    <>
                        {item.departmentNames.map((subitem, index) => (
                            <Typography key={index} color={'textPrimary'} variant={'caption'}>
                                {subitem}
                                {index !== item.departmentNames.length - 1 && ', '}{' '}
                            </Typography>
                        ))}
                    </>
                ) : null}
            </span>
        </span>
    </span>
};

export const searchInstanceUsers = (selectedInstance) => (inputValue, callback) => {
    console.debug('select > searchInstanceUsers > selectedInstance: ', selectedInstance);
    axios
        .get(`${flowPrefix}/instanceUsers/${selectedInstance?.id}`, {
            params: {
                keyword: inputValue
            }
        })
        .then((res) => {
            console.debug("实例处理人员：",res.data);
            const users = res.data.map((item) => ({
                value: item?.user?.id,
                label: <UserView user={item?.user}/>
            }));
            callback(users);
        });
};

export const DeploymentView = ({deploy}) => (
    <div className="flex flex-row flex-1 justify-between">
        <MyTooltip title={<Translate id={'process.deploy name'}/>}>
            <Typography variant="button" className={'truncate'}>
                {deploy ? deploy.name : ''}
            </Typography>
        </MyTooltip>
    </div>
);

export const searchDeployments = (inputValue, callback) => {
    axios
        .get(`${flowPrefix}/deployments?size=8`, {
            params: {
                name: inputValue
            }
        })
        .then((res) => {
            const users = res.data.content.map((item) => ({
            value: item.id,
            label: <DeploymentView deploy={item} />
        }));
        callback(users);
    });
};

export const DefinitionView = ({definition}) => (
    <div className="flex flex-1 flex-row justify-between items-center w-full p-1 m-1 truncate">
        <MyTooltip
            title={
                <div className={"flex flex-col"}>
                    <div>
                        <Translate id={"process.definition name:"} />
                    </div>
                    <div>{definition ? definition.name : <Translate id={"process.no data"} />}</div>
                </div>
            }
        >
            <small className={"truncate"}>{definition ? definition.name : ""}</small>
        </MyTooltip>
        <MyTooltip title={<Translate id={"process.version"} />}>
            <Typography variant="button" display="block" className={"mx-2"}>
                {definition ? definition.version : ""}
            </Typography>
        </MyTooltip>

        {definition && (
            <Chip
                label={
                    definition.suspended === false ? (
                        <Translate id={"process.running"} />
                    ) : (
                        <Translate id={"process.suspended"} />
                    )
                }
                className={classNames(
                    "font-bold",
                    definition.suspended === false ? "bg-green text-white ml-2" : "bg-red text-white ml-2"
                )}
            />
        )}
    </div>
);

export const searchDefinitions = (inputValue, callback) => {
    axios
    .get(`${flowPrefix}/processDefinitions?size=8`, {
        params: {
            name: inputValue
        }
    })
    .then((res) => {
        const users = res.data.content.map((item) => ({
            value: item.id,
            label: <DefinitionView definition={item} />
        }));
        callback(users);
    });
};

export const ProcessCategoryView = ({category}) => (
    <div className="flex flex-1 flex-row justify-between items-center w-full p-1 m-1 truncate">
        <MyTooltip
            title={
                <div className={"flex flex-col"}>
                    <div>
                        <Translate id={"process.processCategory name:"} />
                    </div>
                    <div>{category ? category?.name : <Translate id={"process.no data"} />}</div>
                </div>
            }
        >
            <small className={"truncate"}>{category ? category.name : ""}</small>
        </MyTooltip>

        {category && (
            <Chip
                label={
                    category.status === "open" ? <Translate id={"process.open"} /> : <Translate id={"process.close"} />
                }
                className={`${CategoriesStatusStyle[category?.status]} ${CategoryStatusStylePseudo(
                    "hover",
                    category?.status
                )} ${CategoryStatusStylePseudo("focus", category?.status)} ${CategoryStatusStylePseudo(
                    "active",
                    category?.status
                )}`}
            />
        )}
    </div>
);

export const AddCategory = () => {
    const {onTurnOnDialog} = useMUIDialog({
        registry: GDialogRegistries.editProcessCategoryDialog,
        maxWidth: "md"
    });

    const {onChangeRefreshId} = useAsyncSelectUtils();
    const {categoryActions} = useStoreUtils();
    const {onChangeSelectedCategory} = categoryActions;

    const handleAdd = useMemoizedFn((e) => {
        e.stopPropagation();
        onChangeSelectedCategory(null);
        onTurnOnDialog({
            onFinish: handleRefresh
        });
    });

    const handleRefresh = useMemoizedFn(() => {
        const randomId = getRandomId();
        onChangeRefreshId(randomId);
    });

    return (
        <div className={"flex flex-row flex-1 w-full p-1 m-1 justify-center items-center"}>
            <div className={"flex flex-row items-center mr-3"}>
                <Icon>add</Icon>
            </div>
            <div className={"flex flex-row items-center"}>
                <Button onClick={handleAdd} color="secondary">
                    {<Translate id={"_.add processCategory"} />}
                </Button>
            </div>
        </div>
    );
};

export const searchProcessCategory = (inputValue, callback) => {
    axios
    .get(`${flowPrefix}/processCategories?size=8`, {
        params: {
            name: inputValue
        }
    })
    .then((res) => {
        const users = res.data.content.map((item) => ({
            value: item.id,
            label: <ProcessCategoryView catogory={item} />
        }));
        let tu = [
            {
                value: `${NEW_CATEGORY_TAG}`,
                label: <AddCategory />
            },
            ...users
        ];
        callback(tu);
    });
};

export const InstanceView = ({instance}) => (
    <div className="flex flex-1 flex-row justify-between items-center">
        <MyTooltip
            title={
                <div className={"flex flex-col"}>
                    <div className={"truncate"}>
                        <Translate id={"process.businessKey"} />
                    </div>
                    <div>{instance ? instance.businessKey : <Translate id={"process.no data"} />}</div>
                </div>
            }
        >
            <small className={"truncate"}>{instance ? instance.businessKey : <Skeleton variant="text" />}</small>
        </MyTooltip>
        {instance && <StatusView item={instance} />}
    </div>
);

export const searchInstance = (inputValue, callback) => {
    axios
    .get(`${flowPrefix}/processInstances?size=8`, {
        params: {
            name: inputValue
        }
    })
    .then((res) => {
        const users = res.data.content.map((item) => ({
            value: item.id,
            label: <InstanceView instance={item} />
        }));
        callback(users);
    });
};

export const DecisionView = ({decision}) => (
    <div className="flex flex-col">
        <div className={"flex flex-row items-center mb-3"}>
            <MyTooltip title={<Translate id={"process.decision name"} />}>
                <Typography variant="h6" className={"truncate  font-black mr-3"}>
                    {decision.name}
                </Typography>
            </MyTooltip>

            <div>
                <MyTooltip title={<Translate id={"process.version"} />}>
                    <span className={"bg-red-lighter text-red-darker shadow-md px-3 text-xs rounded-full inline-block"}>
                        {decision.version}
                    </span>
                </MyTooltip>
            </div>
        </div>
        {/*<div className={"flex flex-row items-center"}>*/}
        {/*        <MyTooltip title={<Translate  id={"process.tenant"}/>}>*/}
        {/*            <span className={"text-black"}>*/}
        {/*                {decision.tenantId}*/}
        {/*            </span>*/}
        {/*        </MyTooltip>*/}

        {/*        <span className={"mx-3"}>*/}
        {/*            -*/}
        {/*        </span>*/}

        {/*        <MyTooltip title={<Translate id={"process.deployment"}/>}>*/}
        {/*                    <span className={"text-black"}>*/}
        {/*                        {*/}
        {/*                            decision.deploymentId*/}
        {/*                        }*/}
        {/*                    </span>*/}
        {/*        </MyTooltip>*/}
        {/*</div>*/}
    </div>
);

export const searchDecision = (inputValue, callback) => {
    axios
    .get(`${flowPrefix}/decisionsAdmin?size=8`, {
        params: {
            name: inputValue
        }
    })
    .then((res) => {
        const users = res.data.content.map((item) => ({
            value: item.id,
            label: <DecisionView decision={item} />
        }));
        callback(users);
    });
};



