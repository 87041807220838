import axios, {financialPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_ACCOUNTS = "[FINANCIAL_GET_ACCOUNTS]";
export const EMPTY_ACCOUNTS = "[FINANCIAL_EMPTY_ACCOUNTS]";
export const SELECT_ACCOUNT = "[FINANCIAL_SELECT_ACCOUNT]";
export const DE_SELECT_ACCOUNT = "[[FINANCIAL_DE_SELECT_ACCOUNT]]";

export function getAccounts(params,callback) {
        const request = axios.get(`${financialPrefix}/accounts`,{
            params: params
        });
        return (dispatch) => {
            dispatch(startLoading());
        request.then(response => {
            dispatch(finishLoading());
            dispatch({
                type: GET_ACCOUNTS,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
        });
    };
}

export function emptyAccounts() {
    return {
        type: EMPTY_ACCOUNTS
    };
}

export function selectAccount(item) {
    return {
        type: SELECT_ACCOUNT,
        payload: item
    };
}

export function de_select_account() {
    return {
        type: DE_SELECT_ACCOUNT
    };
}

