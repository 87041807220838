
export const GET_PROJECT                = "[COMMERCE|PROJECT] GET_PROJECT";
export const GET_PROJECT_STAGES         = "[COMMERCE|PROJECT] GET_PROJECT_STAGES";
export const SET_ACTIVE_STAGE           = "[COMMERCE|PROJECT] SET_ACTIVE_STAGE";
export const HANDLE_NEXT_STAGE          = "[COMMERCE|PROJECT] HANDLE_NEXT_STAGE";
export const HANDLE_FIRST_OPEN_STAGE    = "[COMMERCE|PROJECT] HANDLE_FIRST_OPEN_STAGE";
export const GET_STAGE_HISTORY          = "[COMMERCE|PROJECT] GET_STAGE_HISTORY";

//export function getProject(id, errorCallback){
//    const request = axios.get(`${pmsPrefix}/project/${id}`);
//    return (dispatch) => {
//        dispatch(startLoading());
//        request.then((response) => {
//                dispatch(getProjectStages(id));
//                dispatch(finishLoading());
//                dispatch({
//                    type: GET_PROJECT,
//                    payload: response.data
//                });
//            }
//        ).catch(() => {
//            dispatch(finishLoading);
//            errorCallback()
//        })
//    }
//}

//export function clearProject(){
//    return (dispatch) => {
//        dispatch({
//            type: GET_PROJECT,
//            payload: null
//        });
//    }
//}
//
//export function getProjectStages(id){
//    const request = axios.get(`${pmsPrefix}/projectStages?projectId=${id}`);
//    return (dispatch) => {
//        request.then((response) => {
//                dispatch({
//                    type: GET_PROJECT_STAGES,
//                    payload: response.data
//                });
//                dispatch(handleFirstOpenStage());
//            }
//        );
//    }
//}
//
//export function setActiveStage(stage){
//    return (dispatch, getState) => {
//        dispatch({
//            type      : SET_ACTIVE_STAGE,
//            stage
//        });
//        if (stage > -1 ) {
//            if(getState().commerce.project.stages[stage]){
//                const id = getState().commerce.project.stages[stage].id;
//                dispatch(getStageHistory(id));
//            }
//        }
//    }
//}

//export function getStageHistory(id) {
//    const request = axios.get(`${pmsPrefix}/stageHistories?projectStageId=${id}`);
//    return (dispatch) => {
//        request.then((response) => {
//                dispatch({
//                    type: GET_STAGE_HISTORY,
//                    payload: response.data
//                });
//            }
//        );
//    }
//}
//
//
//export function handleFirstOpenStage(){
//    return {
//        type: HANDLE_FIRST_OPEN_STAGE,
//    }
//}
