import Skeleton from "@material-ui/lab/Skeleton";
import {MyTooltip} from "@ui";
import PropTypes from "prop-types";

TextPlaceHolderView.propTypes = {
    emptyTextTip: PropTypes.any
};

function TextPlaceHolderView(props) {
    const {emptyTextTip} = props;
    return (
        <MyTooltip title={emptyTextTip}>
            <Skeleton className={"mx-2"} variant="text" />
        </MyTooltip>
    );
}

export default TextPlaceHolderView;
