import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch, useSelector} from "react-redux";
import * as fuseActions from "@fuseActions";
import * as Actions from "../../store/actions";
import Grid from "@material-ui/core/Grid";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useMemoizedFn} from "ahooks";

const initState = {
    name: "",
    type: "",
    value: "",
};

function VariableDialog(props) {
    const dispatch = useDispatch();

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const selectedAVariable = useSelector(({process}) => process.userTask_list.selectedAVariable);

    const selectedAVariableForInstance = useSelector(({process}) => process.instance.selectedVariable);

    const selectedTask = useSelector(({process}) => process.userTask_list.selectedTask);

    const selectedInstance = useSelector(({process}) => process.instances_list.selectedInstance);

    const [loading, setLoading] = useState(false);

    const [state, setState] = useState(initState);

    useEffect(() => {
        if (selectedAVariable) {
            setState({
                ...state,
                name: selectedAVariable.name,
                value: selectedAVariable.value,
            });
        } else if (selectedAVariableForInstance) {
            setState({
                ...state,
                name: selectedAVariableForInstance.name,
                value: selectedAVariableForInstance.value,
            });
        }
        return function cleanup() {
            setLoading(false);
            dispatch(Actions.selectAVariable(null));
            dispatch(Actions.selectAVariableForInstance(null));
        };
    }, []);

    const handleName = useMemoizedFn((e) => {
        setState({
            ...state,
            name: e.target.value,
        });
    });

    const handleValue = useMemoizedFn((e) => {
        setState({
            ...state,
            value: e.target.value,
        });
    });

    function handleDelete() {
        dispatch(fuseActions.turnOff("VariableDialog"));
    }

    function canSubmit() {
        return loading === true || state.name === "" || state.value === "";
    }

    function handleSubmit() {
        setLoading(true);
        selectedInstance ? createVariablesForInstance() : createVariablesForUserTask();
    }

    const createVariablesForInstance = () => {
        const params = {
            name: state.name,
            value: state.value,
        };
        axios
            .post(`${flowPrefix}/process-instance/${selectedInstance.id}/variables`, params)
            .then((response) => {
                setLoading(false);
                dispatch(Actions.getVariablesForInstance(selectedInstance.id));
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.Create variables success!",
                    })
                );
                handleDelete();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createVariablesForUserTask = () => {
        const params = {
            name: state.name,
            value: state.value,
        };
        axios
            .post(`${flowPrefix}/task/${selectedTask.id}/variables`, {params})
            .then((response) => {
                setLoading(false);
                dispatch(Actions.getVariablesForInstance(selectedTask.id));
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.Create variables success!",
                    })
                );
                handleDelete();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={!!dialogs["VariableDialog"]}
            onClose={() => {
                handleDelete();
            }}
            fullWidth
        >
            <div className={loading ? "loading" : null}>
                <DialogTitle id="VariableDialog-dialog-title">
                    {selectedAVariable || selectedAVariableForInstance ? (
                        <Translate id="process.Edit variable" />
                    ) : (
                        <Translate id="process.add variable" />
                    )}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="name-required"
                                label={<Translate id={"process.variable name"} />}
                                value={state.name}
                                onChange={handleName}
                                variant="filled"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="value-required"
                                multiline
                                onChange={handleValue}
                                label={<Translate id={"process.value"} />}
                                value={state.value}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete()}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button disabled={canSubmit()} onClick={() => handleSubmit()} color="secondary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(VariableDialog);
