import {logoutUser} from "app/auth/store/actions";
import store from "../../store";

export const LogoutConfig = {
    routes: [
        {
            path: "/logout",
            component: () => {
                store.dispatch(logoutUser());
                return "Logging out..";
            },
        },
    ],
};

