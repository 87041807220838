import axios, {financialPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_STOCK_TRANSFERS = "[STOCK] GET_STOCK_TRANSFERS";
export const GET_USER_ASSETS = "[STOCK] GET_USER_ASSETS";
export const GET_STOCK_ORDERS = "[STOCK] GET_STOCK_ORDERS";
export const GET_FUNDING_STATS = "[STOCK] GET_FUNDING_STATS";
export const GET_BUILD_BONUS_DATA = "[STOCK] GET_BUILD_BONUS_DATA";
export const GET_BONUSREQUESTS = "[STOCK] GET_BONUSREQUESTS";
export const CLEAR_BUILD_BONUS_DATA = "[STOCK] CLEAR_BUILD_BONUS_DATA";

export function getStockTransfers(params, callback) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(`${financialPrefix}/stockTransfers`, {params}).then((res) => {
            if (res.data.content)
                dispatch({
                    type: GET_STOCK_TRANSFERS,
                    payload: res.data,
                });
            if (typeof callback === "function") {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getUserAssets(params, callback) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(`${financialPrefix}/userAssets`, {params}).then((res) => {
            if (res.data.content)
                dispatch({
                    type: GET_USER_ASSETS,
                    payload: res.data,
                });
            if (typeof callback === "function") {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function getStockOrders(params, callback) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(`${financialPrefix}/stockOrders`, {params}).then((res) => {
            if (res.data.content)
                dispatch({
                    type: GET_STOCK_ORDERS,
                    payload: res.data,
                });
            if (typeof callback === "function") {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

// export function getFundingStats(params, callback) {
//     return (dispatch, getState) => {
//         dispatch(startLoading());
//         axios.get(`${financialPrefix}/fundingStats`,{params}).then((res) => {
//             if(res.data.content)
//                 dispatch({
//                     type: GET_FUNDING_STATS,
//                     payload: res.data
//                 });
//             if (typeof callback === "function") {
//                 callback();
//             }
//             dispatch(finishLoading());
//         });
//     }
// }

export function getBuildBonusData(params, callback) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(`${financialPrefix}/buildBonusData`, {params}).then((res) => {
            if (res.data)
                dispatch({
                    type: GET_BUILD_BONUS_DATA,
                    payload: res.data,
                });
            if (typeof callback === "function") {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

export function clearBuildBonusData() {
    return {
        type: CLEAR_BUILD_BONUS_DATA,
    };
}

export function getBonusRequests(params, callback) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(`${financialPrefix}/bonusRequests`, {params}).then((res) => {
            if (res.data.content)
                dispatch({
                    type: GET_BONUSREQUESTS,
                    payload: res.data,
                });
            if (typeof callback === "function") {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}
