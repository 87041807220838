import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_INSTANCE_SINGLE = "[GET_INSTANCE_SINGLE]";

export const RESET_INSTANCE_SINGLE = "[RESET_INSTANCE_SINGLE]";
export const INSTANCE_SOURCE_TYPE = "[INSTANCE_SOURCE_TYPE]";
export const VARIABLES_SOURCE_TYPE = "[INSTANCE_SOURCE_TYPE]";
export const GET_VARIABLES_FOR_INSTANCE_SINGLE = "[GET_VARIABLES_FOR_INSTANCE_SINGLE]";
export const SELECT_A_VARIABLE_FOR_INSTANCE = "[SELECT_A_VARIABLE_FOR_INSTANCE]";


export function getInstanceSingle(id, callback, catchError) {

    const request = axios.get(`${flowPrefix}/processInstanceAdmin/${id}`);
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_INSTANCE_SINGLE,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}

export function getVariablesForInstance(id, callback, catchError) {
    const request = axios.get(`${flowPrefix}/process-instance/${id}/variables`);
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_VARIABLES_FOR_INSTANCE_SINGLE,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}

export function selectAVariableForInstance(_variable) {
    return {
        type: SELECT_A_VARIABLE_FOR_INSTANCE,
        payload: _variable
    };
}

export function resetInstanceSingle() {
    return {
        type: RESET_INSTANCE_SINGLE
    };
}
