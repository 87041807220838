import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {MyTooltip} from "@ui";
import { turnOn, turnOff } from "@fuseActions";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";


const ToggleView = () => {
    const dispatch = useDispatch();
    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);

    useEffect(() => {
        const lv = localStorage.getItem("listView");
        // console.log(lv);
        if (!!lv && lv === "1") dispatch(turnOn("listView"));
    }, []);

    const toggleView = () => {
        // console.log(listView);
        if (listView) {
            dispatch(turnOff("listView"));
            localStorage.setItem("listView", "0");
        } else {
            dispatch(turnOn("listView"));
            localStorage.setItem("listView", "1");
        }
    };

    return (<MyTooltip title={"View Mode"}>
            <IconButton className={"p-0 m-0 rounded-none w-48 h-44"} disableRipple onClick={toggleView}>
                <Icon fontSize={"inherit"}>{!listView ? "view_list" : "view_compact"}</Icon>
            </IconButton>
        </MyTooltip>
    );
};

export default ToggleView;
