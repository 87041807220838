import myaxios, {commonPrefix} from "@api";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import {ItemTitle, SubmitButton, UserView} from "@ui";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import type {userType} from "../../../../types";
import AsyncSelect from "../../../../utils/Forms/AsyncSelect";
import DataGrid from "../DataGrid";
import {db} from "../db";
import type {DGSettingsType} from "../hooks/hooks";
import type {DGColType, DGRowType} from "../types";
import uniqid from "uniqid";

type HistoryType = {
    id: string;
    title: string;
    timestamp: number;
    user: userType;
    data?: {
        cols: DGColType[];
        rows: DGRowType[];
    };
};


export const CreateDG = ({setTempId, onSave}: {setTempId: (id: string) => void; onSave: (id: string, title: string) => void}) => {
    const {t} = useTranslation(["datagrid", "_"]);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [selected, setSelected] = useState<HistoryType>();

    async function addDatagrid(ds: DGSettingsType) {
        try {
            const d = await db.dataGrids.add(ds);
            console.log("successfully added", d);
            try {
                const initRows: DGRowType[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((x) => ({id: uniqid(), activeTime: +new Date(), dataGridId: ds.id}));
                await db.dataGridRows.bulkPut(initRows);
                console.log("addDataGrid, bulkAdd saved successfully!");
            } catch (error) {
                console.error("Error bulkAdd data:", error);
            }
        } catch (error) {
            console.log(`Failed to add: ${error}`);
        }
    }

    const onCreateDG = () => {
        setLoading(true);
        myaxios
            .post(`${commonPrefix}/datagrid`, {
                title,
            })
            .then(({data}) => {
                setTempId(data.id);
                // onChangeSetting("id", data.id);
                // onChangeSetting("title", title);
                // if (selected?.data?.cols) setCols(selected?.data?.cols);
                const initCols: DGColType[] = [
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "1",
                        id: "c0",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "2",
                        id: "c1",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "3",
                        id: "c2",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "4",
                        id: "c3",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "5",
                        id: "c4",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "6",
                        id: "c5",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "7",
                        id: "c6",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "8",
                        id: "c7",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "9",
                        id: "c8",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "10",
                        id: "c9",
                    },
                    {
                        viewType: "text",
                        modifiedTime: +new Date(),
                        minWidth: 80,
                        label: "11",
                        id: "c10",
                    },
                ];

                addDatagrid({
                    isOwner: true,
                    id: data.id,
                    title,
                    lastModified: +new Date(),
                    colsWidth:
                        selected && selected.data
                            ? selected.data?.cols.reduce(
                                  (acc, value) => ({
                                      ...acc,
                                      [value.id]: value.minWidth,
                                  }),
                                  {}
                              )
                            : {},

                    cols: selected?.data?.cols || initCols,
                    permission: "manage",
                });

                onSave(data.id, title);
            })
            .finally(() => setLoading(false));
    };

    const onChangeProject = (e, option: HistoryType) => {
        setSelected(option);
    };

    return (
        <div className={"flex my-20 px-20"}>
            <TextField required className={"min-w-256 "} label={t("title")} value={title} onChange={(e) => setTitle(e.target.value)} />

            <AsyncSelect<HistoryType>
                defaultOptions
                name={"issueProject"}
                onChange={onChangeProject}
                link={`${commonPrefix}/datagrids/all`}
                renderOption={(option) => (
                    <div className={"flex flex-1 items-center justify-between"}>
                        <div className={"flex-1"}>
                            <ItemTitle>{option.title}</ItemTitle> ({t("columns")}: {option.data?.cols?.length || 0}){" "}
                        </div>

                        <div>
                            <UserView dense user={option.user} noUsername />
                        </div>
                    </div>
                )}
                getOptionLabel={(option) => option.title}
                textFieldProps={{
                    className: "w-320 ml-20",
                    required: true,
                    label: t("datagrid:templatePlaceholder"),
                }}
            />
            {/*<ContentWrapper<HistoryType>*/}
            {/*    data={data}*/}
            {/*    key={"historyData"}*/}
            {/*    getData={getData}*/}
            {/*    name={"history"}*/}
            {/*    type={"list"}*/}
            {/*    itemProps={(item) => ({*/}
            {/*        disabled: !item.data?.cols?.length,*/}
            {/*        onClick: () => setSelected(item),*/}
            {/*        className: clsx(selected && selected.id === item.id ? " border-green bg-green-lightest" : ""),*/}
            {/*    })}*/}
            {/*    ViewItem={({item}) => (*/}
            {/*        <>*/}
            {/*            <ListItemText*/}
            {/*                primary={*/}
            {/*                    <>*/}
            {/*                        <ItemTitle>{item.title}</ItemTitle> ({t("columns")}: {item.data?.cols?.length || 0}){" "}*/}
            {/*                        <small className="text-gray-400">{item.data?.cols?.map?.((col) => col.label).join(", ")}</small>*/}
            {/*                    </>*/}
            {/*                }></ListItemText>*/}
            {/*            <ListItemSecondaryAction>*/}
            {/*                <UserView dense user={item.user} />*/}
            {/*            </ListItemSecondaryAction>*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*/>*/}
            {/*<GridView<HistoryType>*/}
            {/*    grid={4}*/}
            {/*    content={history}*/}
            {/*    ViewItem={({item}) => (*/}
            {/*        <MyCard*/}
            {/*            className={clsx(selected === item._id && "shadow-4 border-green bg-green-lightest", "border-2 border-solid")}*/}
            {/*            key={item._id}*/}
            {/*            onClick={() => setSelected(item._id)}*/}
            {/*            noMargin*/}
            {/*            elevation={selected === item._id ? 4 : 1}*/}
            {/*            action={<LocalTimeStamp datetime={item.timestamp} />}*/}
            {/*            title={item.title}>*/}
            {/*            {t("owner")} <UserView dense user={item.user} />*/}
            {/*            <p>{item.data?.cols?.length} columns</p>*/}
            {/*            <p>{item.data?.rows?.length} rows</p>*/}
            {/*        </MyCard>*/}
            {/*    )}*/}
            {/*/>*/}

            <SubmitButton
                startIcon={<Icon children={"edit_note"} />}
                className={"ml-20 self-end"}
                loading={loading}
                disabled={title.trim().length === 0}
                success
                onClick={onCreateDG}>
                {t("create")}
            </SubmitButton>
        </div>
    );
};

export const CreatableDataGrid = ({onSave}: {onSave: (id: string, title: string) => void}) => {
    const [tempId, setTempId] = useState<string>();
    return <>{!tempId ? <CreateDG setTempId={setTempId} onSave={onSave} /> : <DataGrid id={tempId} />}</>;
};

