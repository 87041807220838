import {combineReducers} from "redux";
import checked from "./checked.reducer";
import dialogs from "./dialogs.reducer";
import favorites from "./favorites.reducer";
import loading from "./loading.reducer";
import message from "./message.reducer";
import navbar from "./navbar.reducer";
import navigation from "./navigation.reducer";
import permissions from "./permissions.reducer";
import process_util from "./process_util.reducer";
import progress from "./progress.reducer";
import search from "./search.reducer";
import settings from "./settings.reducer";

const fuseReducers = combineReducers({
    navigation,
    settings,
    navbar,
    message,
    loading,
    dialogs,
    favorites,
    search,
    permissions,
    progress,
    checked,
    process_util,
});

export default fuseReducers;
