import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    instancesResponse: null,
    selectedInstance: null,
    transferContextType: "instances_list",
    selectedInstanceList: []
};


const instances_list = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_PROCESSINSTANCES_LIST: {
            let tobj = cloneDeep(state);
            tobj.instancesResponse = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     instancesResponse: action.payload
            // }
        }
        case Actions.SELECT_A_PROCESSINSTANCE: {
            let tobj = cloneDeep(state);
            tobj.selectedInstance = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedInstance: action.payload
            // }
        }
        case Actions.SELECT_PROCESSINSTANCE_LIST: {
            let tobj = cloneDeep(state);
            tobj.selectedInstanceList = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedInstanceList: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default instances_list;
