export const TOGGLE_CHECK = "[FUSE] TOGGLE_CHECK";
export const ADD_CHECKED = "[FUSE] ADD_CHECKED";
export const REMOVE_CHECKED = "[FUSE] REMOVE_CHECKED";
export const CLEAR_CHECKED = "[FUSE] CLEAR_CHECKED";

export const CHECK_ARRAY = "[FUSE] CHECK_ARRAY";
export const CLEAR_ALL = "[FUSE] CLEAR_ALL";

export const toggleCheck = (id, name) => {
    return {
        type: TOGGLE_CHECK,
        id,
        name,
    };
};

export const checkArray = (array, name) => {
    return {
        type: CHECK_ARRAY,
        array,
        name,
    };
};

export const clearAll = () => {
    return {
        type: CLEAR_ALL,
    };
};

export const clearChecked = (name) => ({
    type: CLEAR_CHECKED,
    name,
});
