import {lazy} from "react";


export const DashboardAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : "/dashboard",
            component: lazy(() =>
                import("./DashboardApp")
            )
        }
    ]
};
