import {FusePageSimple} from "@fuse";
import Grid from "@material-ui/core/Grid";
import {Component} from "react";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import Empty from "utils/Empty";
import jsons from "../../translates/translate.json";
import Description from "../../utils/Description";
import Notes from "../../utils/Notes";
import { clearItem, selectItem, getItem } from "../store/actions";
import Header from "./Header";
import RightSidebar from "./RightSidebar";

class SinglePage extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(jsons);
    }

    componentDidMount = () => {
        // console.log("remounting");
        const {id} = this.props.match.params;
        if (id) this.props.getItem(id);
    };

    componentDidUpdate = (prevProps) => {
        // console.log("updating");
        const {match, item, getItem} = this.props;
        if (item && prevProps.item && match.params.id) {
            if (match.params.id !== prevProps.match.params.id) {
                const {id} = match.params;
                getItem(id);
            }
        }
    };

    componentWillUnmount = () => {
        // console.log("unmounting");
        this.props.clearItem();
        // this.props.selectItem(null);
    };

    render() {
        const {item, selectItem, getItem, profile} = this.props;
        const subProps = {
            canEdit: item && profile.id === item.user.id,
            sourceType: "activity",
            sourceItem: this.props.item,
            selectItem,
            refresh: () => getItem(item.id),
        };
        return !item ? (
            <Empty />
        ) : (
            <FusePageSimple
                classes={{
                    header: "h-128",
                }}
                header={<Header {...subProps} />}
                content={
                    <div className={"w-full"}>
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <Description {...subProps} />
                                <Notes {...subProps} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RightSidebar {...subProps} />
                            </Grid>
                        </Grid>
                    </div>
                }
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearItem: clearItem,
            selectItem: selectItem,
            getItem: getItem,
        },
        dispatch
    );
}

function mapStateToProps({user, marketing}) {
    return {
        profile: user.profile,
        item: marketing.activities.item,
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(SinglePage)));
