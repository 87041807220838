import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    generatedQRCodeResponse: null,
    selectedQRCode: null,
};

const qrcodeReducer = function (state=initState, action) {
    switch (action.type) {
        case Actions.GET_GENERATED_QRCODE: {
            let tobj = cloneDeep(state);
            tobj.generatedQRCodeResponse = action.payload;
            return  tobj;
            // return {
            //     ...state,
            //     generatedQRCodeResponse: action.payload,
            // }
        }
        case Actions.SELECT_A_QRCODE: {
            let tobj = cloneDeep(state);
            tobj.selectedQRCode = action.payload;
            return  tobj;
            // return {
            //     ...state,
            //     selectedQRCode: action.payload,
            // }
        }
        default:
            return  state;
    }
};

export default qrcodeReducer;
