import {
    Divider,
    Drawer,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Snackbar,
    Switch
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import navitrans from "app/layouts/shared-components/navitrans.json";
import NotificationsList from "app/notifications/NotificationsList";
import { turnOff } from "@fuseActions";
import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import { getNotifications, clearSelected, toggleQuickPanel } from "./store/actions";

const QuickPanel = (props) => {
    const [checked, setChecked] = useState<string[]>(["notifications"]);
    const dispatch = useDispatch();
    const notification = useSelector(({notification}) => notification);
    const {priority, selected, state} = notification;

    useEffect(() => {
        // @ts-ignore
        dispatch(getNotifications());
        props.addTranslation(navitrans);
    }, []);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <>
            {Boolean(selected) && priority === 1 && (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={Boolean(selected)}
                    autoHideDuration={6000}
                    onClose={() => dispatch(clearSelected())}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        className: "bg-white text-grey-darkest"
                    }}
                    message={
                        <strong id="message-id">
                            {selected.title} <small className={"font-normal text-grey-dark"}>({selected.detail})</small>
                        </strong>
                    }
                    action={[
                        <Button key="undo" color="secondary" size="small" component={Link} to={`/${selected.sourceType}/${selected.sourceId}`}>
                            {<Translate id={"navi.Go"} />}
                        </Button>,
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={() => dispatch(clearSelected())}>
                            <Icon>close</Icon>
                        </IconButton>
                    ]}
                />
            )}

            <Drawer
                BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
                classes={{paper: "max-w-full w-512"}}
                open={state}
                anchor="right"
                onClose={() => {
                    dispatch(toggleQuickPanel(false));
                    dispatch(turnOff("notificationPopper"));
                }}
            >
                <NotificationsList />

                <Divider />
                <List>
                    <ListSubheader component="div">
                        <Translate id="navi.Quick Settings" />
                    </ListSubheader>
                    <ListItem>
                        <ListItemIcon className={"m-0"}>
                            <Icon>notifications</Icon>
                        </ListItemIcon>
                        <Translate>{({translate}) => <ListItemText primary={translate("navi.Notifications")} />}</Translate>
                        <ListItemSecondaryAction>
                            <Switch color="primary" onChange={handleToggle("notifications")} checked={checked.indexOf("notifications") !== -1} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
};

export default withLocalize(QuickPanel);
