import axios, {userPrefix} from "../../../../../myaxios";

export const GET_APIS = "[FIRMS] GET_APIS";
export const SET_SELECTED_API = "[FIRMS] SET_SELECTED_API";
export const REMOVE_SELECTED_API = "[FIRMS] REMOVE_SELECTED_API";

export const CHANGE_TAG = "[FIRMS] CHANGE_TAG";
export const CHANGE_MODULE = "[FIRMS] CHANGE_MODULE";

export const getAPIs = (callback) => {
    const request = axios.get(`${userPrefix}/permissions`);

    return (dispatch) => {
        request.then((response) =>{
            dispatch({
                type   : GET_APIS,
                list  : response.data,
            });
            if(typeof callback === "function") {callback();}
        });
    };
};
//
// export const getStaff = (id) => {
//     const request = axios.get(`${firmPrefix}/firm/${id}/staffs`);
//
//     return (dispatch) => {
//         request.then((response) => {
//             if(response.data.length > 0) {
//                 const userIds = [];
//                 response.data.map(_s => userIds.push(_s.userId));
//                 axios.post(`${userPrefix}/usersByIds`, {
//                     data: { userIds}
//                 }).then(res=>{
//                     dispatch({
//                         type   : GET_STAFF,
//                         staff  : res.data,
//                     })
//                 })
//             }
//         })
//     }
// };
// //
// // export const getRoles = (id) => {
// //     const request = axios.get(`${firmPrefix}/firm/${id}/roles`);
// //
// //     return (dispatch) => {
// //         request.then((response) =>
// //             dispatch({
// //                 type   : GET_ROLES,
// //                 roles  : response.data,
// //             })
// //         )
// //     }
// // };
//
export const setSelectedAPI = (api) => {
    return {
        type: SET_SELECTED_API,
        api
    };
};
export const removeSelectedAPI = () => {
    return {
        type: REMOVE_SELECTED_API,
    };
};
export const changeTab = (tag) => {
    return {
        type: CHANGE_TAG,
        tag
    };
};
export const changeModule = (module) => {
    return {
        type: CHANGE_MODULE,
        module
    };
};
//
//
