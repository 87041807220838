import axios from 'axios';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {Map} from 'react-amap';
import AMapReGeocoder from './AMapReGeocoder';

class ReGeocoderTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listen: undefined,
            //en- country(国家) / cn - province(省)
            levelOne: undefined,
            //en- region（州） / cn - city（市）
            levelTwo: undefined,
            //en - place （地方）/ cn - district （区）
            levelThree: undefined,
            //详细地址
            fullAddress: undefined
        };
    }

    componentDidMount() {
        this.handleRegeoCoder(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.handleRegeoCoder(this.props);
        }
    }

    handleRegeoCoder = (props) => {
        const {language} = this.props;

        if (language === "en") {
            // axios({
            //     method: 'get',
            //     url: `"https://api.mapbox.com/geocoding/v5/mapbox.places/${props.longitude},${props.latitude}.json?access_token=pk.eyJ1IjoibGlmYW4iLCJhIjoiY2pwd2EzMHltMHBlajQycG95cmZhd2g2NCJ9.r4DuLTmTkpcMWrlnkCAE8A"`,
            //     contentType: 'application/json',
            //     headers: {
            //         'Access-Control-Allow-Origin': '*',
            //     },
            //     proxy: {
            //         host: 'localhost',
            //         port: 3000
            //     }
            // }).then(response => {
            //     console.log(' ReGeocoderTool=> Mapbox 逆解析的结果是', response);
            //     for (const subRes in response) {
            //         console.log(`ReGeocoderTool => 循环逆解析结果的 key = ${subRes},对应的值是 ${response[subRes]}`);
            //     }
            // });

            // Mapbox  逆解析
            axios
            .get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${props.longitude},${props.latitude}.json?access_token=pk.eyJ1IjoibGlmYW4iLCJhIjoiY2pwd2EzMHltMHBlajQycG95cmZhd2g2NCJ9.r4DuLTmTkpcMWrlnkCAE8A`,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    },
                    proxy: {
                        host: "192.168.0.115",
                        port: 3000
                    }
                }
            )
            .then((response) => {
                // console.log(' ReGeocoderTool=> Mapbox 逆解析的结果是', response.data);
//                    for (const subRes in response.data["features"]) {
//                        // console.log(`ReGeocoderTool => 循环逆解析结果的 key = ${subRes},对应的值是 ${response.data["features"][subRes]}`);
//
//                        for (const subsub in response.data["features"][subRes]) {
//                            // console.log(`ReGeocoderTool => 第二层逆解析的 key = ${subsub}; 第二层的值是 ${response.data["features"][subRes][subsub]}`);
//                        }
//                    }
                const originalDaTa = response.data["features"];
                if (originalDaTa.length === 1) {
                    this.setState({
                        levelOne: response.data["features"][0].text,
                        fullAddress: response.data["features"][0].place_name
                    });
                } else if (originalDaTa.length === 2) {
                    this.setState({
                        levelOne: response.data["features"][1].text,
                        levelTwo: response.data["features"][0].text,
                        fullAddress: response.data["features"][0].place_name
                    });
                } else if (originalDaTa.length === 3) {
                    this.setState({
                        levelOne: response.data["features"][2].text,
                        levelTwo: response.data["features"][1].text,
                        levelThree: response.data["features"][0].text,
                        fullAddress: response.data["features"][0].place_name
                    });
                } else if (originalDaTa.length >= 4) {
                    const count = originalDaTa.length;
                    this.setState({
                        levelOne: response.data["features"][count - 1].text,
                        levelTwo: response.data["features"][count - 2].text,
                        levelThree: response.data["features"][count - 3].text,
                        fullAddress: response.data["features"][0].place_name
                    });
                }
            });
        }
    };

    /*
     * 处理逆向解析的结果，即根据经纬度解析出地址信息等具体详细的细心
     * */
    handleAMapRegeocoder = (result) => {
        if (result.formattedAddress) {
            this.setState({
                fullAddress: result.formattedAddress
            });
            if (this.props.inputId) {
                document.getElementById(`${this.props.inputId}`).value = `${result.formattedAddress}`;

                document.getElementById(`${this.props.inputId}`).focus();
            }
        }
        if (result.addressComponent) {
            if (result.addressComponent.province) {
                this.setState({
                    levelOne: result.addressComponent.province
                });
            }
            if (result.addressComponent.city) {
                this.setState({
                    levelTwo: result.addressComponent.city
                });
            }
            if (result.addressComponent.district) {
                this.setState({
                    levelThree: result.addressComponent.district
                });
            }
        }

        const param = {
            levelOne: result.addressComponent.province,
            levelTwo: result.addressComponent.city,
            levelThree: result.addressComponent.district,
            fullAddress: result.formattedAddress
        };
        this.props.passRegeoAllInfomation(param);
    };

    /*
     * 处理正向解析的结果，即根据地址信息解析出GPS信息和更加详细的地址信息
     * */
    handleAmapGeocodeInfomation = (result) => {
        const lnglat = result[0].location;
        if (result.formattedAddress) {
            this.setState({
                fullAddress: result.formattedAddress
            });
        }
        if (result.addressComponent) {
            if (result.addressComponent.province) {
                this.setState({
                    levelOne: result.addressComponent.province
                });
            }
            if (result.addressComponent.city) {
                this.setState({
                    levelTwo: result.addressComponent.city
                });
            }
            if (result.addressComponent.district) {
                this.setState({
                    levelThree: result.addressComponent.district
                });
            }
        }
        const param = {
            levelOne: this.state.levelOne,
            levelTwo: this.state.levelTwo,
            levelThree: this.state.levelThree,
            fullAddress: this.state.fullAddress,
            lnglat: lnglat
        };
        this.props.callbackGeocodeAllInfomation(param);
    };

    render() {
        const {latitude, longitude, address} = this.props;

        return (
            <div className="hidden">
                <Map amapkey="db41b38ab7028e90c33f2baa9e1330ab">
                    <AMapReGeocoder
                        latitude={latitude && latitude}
                        longitude={longitude && longitude}
                        address={address && address}
                        passRegeocode={this.handleAMapRegeocoder}
                        callbackLngLat={this.handleAmapGeocodeInfomation}
                    />
                </Map>
            </div>
        );
    }
}

ReGeocoderTool.propTypes = {
    inputId: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    address: PropTypes.string,
    /*
     * 返回逆解析的信息
     * */
    passRegeoAllInfomation: PropTypes.func,
    /*
     * 正向解析的信息回调
     * */
    callbackGeocodeAllInfomation: PropTypes.func
};

export default ReGeocoderTool;
