import {firmPrefix} from "myaxios";
import {useLocation, useParams} from "react-router-dom";
import {useList} from "../../../utils/Page";
import type {wpType} from "./types";

const useWPS = () => {
    const params = useParams<{type?: "list" | "myWorkplans"; projectId?: string; engineerId?: string}>();
    const location = useLocation();
    const {projectId, engineerId, type} = params;

    const link = engineerId
        ? `/workplans?engineerId=${engineerId}`
        : projectId
        ? `/workplans?projectId=${projectId}`
        : location.pathname === "/wps/myWorkplans" || type === "myWorkplans"
        ? "/myWorkplans"
        : "/workplans";

    // const link =
    //     location.pathname === "/wps/myWorkplans"
    //         ? `${firmPrefix}/myWorkplans`
    //         : projectId
    //             ? `${firmPrefix}/workplans?projectId=${projectId}`
    //             : `${firmPrefix}/workplans`;

    const {data, loading, getData, filterData, isValidating} = useList<wpType>(`${firmPrefix}${link}`);

    return {
        filterData,
        content: data ? data.content : [],
        data,
        loading,
        getData,
        isValidating,
    };
};

export default useWPS;
