import produce from "immer";

export const createStepsSlice = (set) => ({
    muiltSteps: [],
    onChangeMuiltSteps: (t) => {
        set(
            produce((state) => {
                state.muiltSteps = t;
            })
        );
    },
});
