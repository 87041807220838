import {lazy} from "react";

export const ConfigArchives = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/marketing/archive/:id/",
            component: lazy(() =>
                import("./single/SinglePage")
            )
        },
        {
            path     : "/marketing/archives",
            component: lazy(() =>
                import("./list/Page")
            )
        }
    ]
};
