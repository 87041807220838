import {ClickAwayListener, Icon, IconButton, Paper} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import type {TextFieldProps} from "@material-ui/core/TextField";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import type {AutocompleteProps} from "@material-ui/lab/Autocomplete/Autocomplete";
import {useUpdateEffect} from "ahooks";
import axios from "myaxios";
import type {ChangeEvent} from "react";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {usePersonalized} from "../../hooks/usePersonalized";

type SingleType<T> = {
    multiple?: false;
    defaultValue?: T | null;
};

type MultipleType<T> = {
    multiple: true;
    defaultValue?: T[];
};

type Props<T> = {
    link: string;
    shouldUpdate?: string;
    name?: string;
    defaultOptions?: boolean;
    textFieldProps?: any;
    variant?: TextFieldProps["variant"];
    // onChange(event: ChangeEvent<any>, newValue: T): void;
    required?: boolean;
    getOptionLabel(opt): string;
} & (SingleType<T> | MultipleType<T>);

type Es = Omit<AutocompleteProps<any, any, any, any>, "options" | "renderInput">;

function AsyncSelect<T extends {id: string}>({
    link,
    shouldUpdate,
    name,
    multiple,
    defaultOptions,
    defaultValue,
    textFieldProps,
    getOptionLabel,
    onChange,
    renderOption,
    className,
    variant,
    required,
    ...rest
}: Props<T> & Es) {
    // region hooks and states
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<T[]>([]);
    const [loading, setLoading] = useState(false);
    const [hasRec, setHasRec] = useState(0);
    const [value, setValue] = useState<T | null>(null);
    const [inputValue, setInputValue] = useState("");
    const {addLatest, getLatest, deleteLatest} = usePersonalized();
    // endregion

    const getOptions = (keyword?: string) => {
        setLoading(true);
        const params = keyword && {keyword};
        axios
            .get(link, {params})
            .then(async (res) => {
                const content = res.data ? (res.data.content ? res.data.content : res.data) : [];
                if (keyword) {
                    setOptions(content);
                } else if (name) {
                    const w = await getLatest(name);
                    // const l = localStorage.getItem(name);
                    // const es = l ? JSON.parse(l) : [];
                    if (w) {
                        const es = countSameElements(w);
                        if (es.length > 0) {
                            setHasRec(es.length);
                            const s = [
                                ...es
                                    .sort((a: any, b: any) => a.echo - b.echo)
                                    .reverse()
                                    .map((item: any) => item.item),
                                ...content,
                            ];
                            const uniqueArr: T[] = [];
                            for (const i of s) {
                                if (uniqueArr.findIndex((item) => item.id === i.id) === -1) {
                                    uniqueArr.push(i);
                                }
                            }
                            setOptions(uniqueArr);
                        } else {
                            setOptions(content);
                        }
                    } else {
                        setOptions(content);
                    }
                } else {
                    setOptions(content);
                }
            })
            .finally(() => setLoading(false));
    };

    const setRecommended = (item: any) => {
        if (!name) return;
        addLatest(name, item);
        // setHasRec(true);
    };

    const countSameElements = (data: any[]) => {
        const counts: {echo: number; item: any}[] = [];

        for (const item of data) {
            const key = item.value.id; // Create a unique key based on the object value
            const index = counts.findIndex((u) => key === u.item.id);
            if (index > -1) {
                counts[index].echo += 1;
            } else {
                counts.push({item: item.value, echo: 1});
            }
        }

        return counts;
    };

    const getRecommends = async () => {
        if (!name) return;
        const w = await getLatest(name);

        if (w) {
            const es = countSameElements(w);
            if (es.length > 0) {
                setOptions(
                    es
                        .sort((a, b) => a.echo - b.echo)
                        .reverse()
                        .map((item) => item.item)
                );
                setHasRec(es.length);
            } else getOptions();
        } else getOptions();
    };

    const clearRec = (e) => {
        e.stopPropagation();
        if (name) {
            deleteLatest(name);
            setHasRec(0);
            getOptions();
        }
    };

    const onChangeValue = (event, newValue) => {
        setValue(newValue);

        if (onChange) {
            onChange(event, newValue, "select-option");
        }
        if (newValue) setRecommended(newValue);
        else {
            getOptions();
        }
    };

    const onInputChange = (event: ChangeEvent<any>, newInputValue: string) => {
        if (event?.type === "change") getOptions(newInputValue);
        setInputValue(newInputValue);
    };

    useEffect(() => {
        if (defaultOptions) {
            getOptions();
        } else if (name) getRecommends();

        if (defaultValue) {
            if (options.length === 0) getOptions();
            setValue(defaultValue);
        }
    }, []);

    useUpdateEffect(() => {
        if (defaultValue !== undefined) {
            if (defaultValue && options.length === 0) {
                getOptions();
            }
            setValue(defaultValue);
        }
    }, [shouldUpdate, defaultValue]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(false);
            }}>
            <div className={className}>
                <Translate>
                    {({translate}: {translate: any}) => (
                        <Autocomplete<T, any, any, any>
                            // classes={{endAdornment: variant === "outlined" ? "top-1" : "-top-2"}}
                            inputValue={inputValue}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={(event, reason) => {
                                if (reason !== "blur") {
                                    setOpen(false);
                                }
                            }}
                            classes={{endAdornment: variant === "outlined" ? "top-1" : "-top-2"}}
                            onInputChange={onInputChange}
                            multiple={multiple}
                            {...rest}
                            onChange={onChangeValue}
                            // @ts-ignore there is a problem using this
                            value={multiple ? (value ? value : []) : value}
                            includeInputInList
                            filterOptions={(options) => options}
                            renderOption={renderOption}
                            // defaultValue={defaultValue}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={getOptionLabel}
                            options={options}
                            loading={loading}
                            PaperComponent={(props) => (
                                <Paper>
                                    {hasRec > 0 ? (
                                        <div
                                            className={"flex items-center bg-gray-100 justify-between opacity-60 py-4 px-12"}
                                            onClick={(e) => e.stopPropagation()}>
                                            <p className={"text-lg font-bold"}>
                                                {hasRec} <Translate id={"_.recent"} />
                                            </p>
                                            <IconButton className={"hover:text-red-light duration-300"} onClick={clearRec} size={"small"}>
                                                <Icon>delete_forever</Icon>
                                            </IconButton>
                                        </div>
                                    ) : null}
                                    {props.children}
                                </Paper>
                            )}
                            popupIcon={<Icon fontSize={"large"}>expand_more</Icon>}
                            noOptionsText={<Translate id={"_.No options"} />}
                            loadingText={translate("_.Loading...")}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...textFieldProps}
                                    required={required}
                                    variant={variant}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                </Translate>
            </div>
        </ClickAwayListener>
    );
}

export default AsyncSelect;
