import {finishLoading, startLoading} from "@fuseActions";
import axios, {pmsPrefix} from "myaxios";

export const SELECT_PROJECT = "[DASHBOARD] SELECT_PROJECT";
export const GET_PROJECTS = "[DASHBOARD] GET_PROJECTS";
export const SELECT_STAGE = "[DASHBOARD] SELECT_STAGE";
export const TOGGLE_ALLOW_CHECK = "[DASHBOARD] TOGGLE_ALLOW_CHECK";
export const REMOVE_ALLOW_CHECK = "[DASHBOARD] REMOVE_ALLOW_CHECK";
export const TOGGLE_VIEW = "[DASHBOARD] TOGGLE_VIEW";
export const SELECT_PROJECT_STAGES = "[DASHBOARD] SELECT_PROJECT_STAGES";
export const CLEAR_CHECKED_PS = "[DASHBOARD] CLEAR_CHECKED_PS";
export const TEMPLATE_STAGES = "[DASHBOARD] TEMPLATE_STAGES";
export const TOGGLE_MENU_ANCHOR = "[DASHBOARD] TOGGLE_MENU_ANCHOR";
export const SELECT_TEMPLATE = "[DASHBOARD] SELECT_TEMPLATE";

export const selectProject = (payload) => {
    return {
        type: SELECT_PROJECT,
        payload,
    };
};

export const selectTemplate = (payload) => {
    return {
        type: SELECT_TEMPLATE,
        payload,
    };
};

export const getProjectStages = (params) => {
    const templateId = params && params.id ? params.id : localStorage.getItem("lastSelectedTemplateId");
    return (dispatch) => {
        dispatch(startLoading());
        return new Promise((resolve) => {
            axios.get(`${pmsPrefix}/projectStagesObject`, {params: {...params, templateId}}).then((res) => {
                clearCheckedPS();
                dispatch(finishLoading());
                dispatch({
                    type: GET_PROJECTS,
                    payload: res.data,
                });
                resolve();
            });
        });
    };
};

export const getTableData = (params) => {
    const templateId = params && params.id ? params.id : localStorage.getItem("lastSelectedTemplateId");
    // console.log("template", templateId);
    return (dispatch) => {
        dispatch(getProjectStages(params));
        axios.get(`${pmsPrefix}/stages?templateId=${templateId}`).then((res) => {
            dispatch({
                type: TEMPLATE_STAGES,
                payload: res.data,
            });
        });
    };
};

export const toggleAllowCheck = () => {
    return (dispatch) => {
        dispatch(clearCheckedPS());
        dispatch(selectProject(null));
        dispatch({type: TOGGLE_ALLOW_CHECK});
    };
};

export const removeAllowCheck = () => {
    return (dispatch) => {
        dispatch(clearCheckedPS());
        dispatch(selectProject(null));
        dispatch({type: REMOVE_ALLOW_CHECK});
    };
};

export const clearCheckedPS = () => {
    return {
        type: CLEAR_CHECKED_PS,
    };
};

export const selectProjectStage = (payload) => {
    return {
        type: SELECT_STAGE,
        payload,
    };
};

export const selectProjectStages = (payload) => {
    return {
        type: SELECT_PROJECT_STAGES,
        payload,
    };
};

export const toggleView = () => {
    return {
        type: TOGGLE_VIEW
    };
};
