import {showMessage} from "@fuseActions";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {useMemoizedFn, useMount, useSetState} from "ahooks";
import _ from "lodash";
import {useEffect, useMemo} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useMUIDialog} from "../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../@fuse/state/constant";
import json from "../translate/income.json";
import {useIncomeCommonUtils} from "../utils/state";
import usePutIncomeRefund from "./hooks/usePutIncomeRefund";

const initState = {
    amount: "",
    note: ""
};

function NewRefundDialog(props) {
    const {currentIncome, onChangeCurrentIncome} = useIncomeCommonUtils();

    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.refundAmount
    });

    const {run, isMutating} = usePutIncomeRefund();

    const [state, setState] = useSetState(initState);

    useMount(() => {
        props.addTranslation(json);
    });

    useEffect(() => {
        return () => {
            onChangeCurrentIncome(null);
        };
    }, []);

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleRevenueAmount = (e) => {
        setState({
            amount: e.target.value
        });
    };

    const handleRevenueNote = (e) => {
        setState({
            note: e.target.value
        });
    };

    const disabled = useMemo(() => {
        const {amount, note} = state;
        return amount === "" || _.toNumber(amount) < 0 || isMutating;
    }, [state, isMutating]);

    const handleSure = useMemoizedFn((e) => {
        e.stopPropagation();
        const {amount, note} = state;
        const params = {
            id: currentIncome?.id,
            amount: _.toNumber(amount),
            note: note
        };
        run(params).then((response) => {
            if (response.status === 200) {
                finshParams?.onFinish();
                showMessage({
                    variant: "success",
                    message: "message.Refund success"
                });
                onTurnOffDialog();
            }
        });
    });

    return (
        <Dialog {...dialogProps}>
            <div>
                <DialogTitle>
                    <Translate id={"income.Revenue refund"} />
                </DialogTitle>
                <DialogContent className={isMutating ? "loading overflow-hidden" : "overflow-hidden"}>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                type={"number"}
                                label={<Translate id={"income.refund amount"} />}
                                onChange={handleRevenueAmount}
                                value={state.amount}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                multiline
                                fullWidth
                                label={<Translate id={"income.note"} />}
                                onChange={handleRevenueNote}
                                value={state.note}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button
                        disabled={disabled}
                        variant={"contained"}
                        onClick={handleSure}
                        color="secondary"
                    >
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(NewRefundDialog);
