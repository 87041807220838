import {
    CANCEL_SELECTED_CUSTOMER,
    CUSTOMERS_CHANGE_VIEW,
    GET_CUSTOMER_GROUP,
    GET_CUSTOMERS,
    SELECT_CUSTOMER,
    SELECT_CUSTOMER_GROUP,
    SELECT_CUSTOMERS
} from '../actions';

const initialState = {
    content                 : [],
    groups                  : [],
    selectedCustomers       : [],
    view                    : 0,  // 0 = list , 1 = grid , 2 = kanban
    totalElements           : 0,
    selectedCustomer        : null,
    selectedCustomerGroup   : null,
    searchText              : ""
};

const customersReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_CUSTOMER_GROUP:{
            return {
                ...state,
                groups: action.payload
            };
        }
        case GET_CUSTOMERS:{
            return {
                ...state,
                ...action.payload
            };
        }
        case CUSTOMERS_CHANGE_VIEW:{
            return {
                ...state,
                view: action.view
            };
        }
        case SELECT_CUSTOMER_GROUP:{
            return {
                ...state,
                selectedCustomerGroup: action.group
            };
        }
        case SELECT_CUSTOMER: {
            return {
                ...state,
                selectedCustomer: action.payload
            };
        }
        case CANCEL_SELECTED_CUSTOMER: {
            return {
                ...state,
                selectedCustomer: null,
            };
        }
        case SELECT_CUSTOMERS: {
            return {
                ...state,
                selectedCustomers: action.customers
            };
        }
        default:{
            return state;
        }
    }
};

export default customersReducer;
