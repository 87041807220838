import {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Dialog, DialogContent} from '@material-ui/core';
import axios, {crmPrefix} from '../../../../../myaxios';
import * as fuseActions from '@fuseActions';
import {Translate, withLocalize} from 'react-localize-redux';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import * as Actions from '../../store/actions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class BrandCategoryDialog extends Component {
    state = {
        loading: false,
        name: "",
        id: null,
    };

    componentDidMount = () => {
        if (this.props.selectedCategory) {
            this.setState({
                name: this.props.selectedCategory.name,
                id: this.props.selectedCategory.id,
            });
        }
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        const formData = {
            name: this.state.name,
        };
        if (this.props.selectedCategory) formData.id = this.props.selectedCategory.id;
        let request = this.props.selectedCategory
            ? axios.put(`${crmPrefix}/brandCategory`, formData)
            : axios.post(`${crmPrefix}/brandCategory`, formData);

        request
            .then(() => {
                this.setState({loading: false});
                this.props.turnOff("editBrandCat");
                this.props.showMessage({
                    variant: "success",
                    message: "message.Brand category Saved!",
                });
                this.props.getBrandCategories();
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    render() {
        const {selectedCategory, dialogs} = this.props;
        return (
            <Dialog
                open={!!dialogs["editBrandCat"]}
                maxWidth={"xs"}
                onClose={() => {
                    this.props.turnOff("editBrandCat");
                }}
                fullWidth
            >
                <div className={this.state.loading ? "loading" : null}>
                    <DialogTitle id="alert-dialog-title">
                        {selectedCategory ? (
                            <>
                                <Translate id="crm.Edit" /> {selectedCategory.name}{" "}
                            </>
                        ) : (
                            <Translate id="crm.New Brand category" />
                        )}
                    </DialogTitle>
                    <form onSubmit={this.onSubmit}>
                        <DialogContent className={"py-0"}>
                            <TextField
                                autoFocus={true}
                                fullWidth
                                required
                                onChange={this.handleChange("name")}
                                label={<Translate id={"crm.Name"} />}
                                value={this.state.name}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.props.turnOff("editBrandCat");
                                }}
                            >
                                <Translate id="_.cancel" />
                            </Button>
                            <Button disabled={this.state.name === ""} color="primary" type={"submit"}>
                                <Translate id="_.submit" />
                            </Button>
                        </DialogActions>
                    </form>
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff,
            showMessage: fuseActions.showMessage,
            getBrandCategories: Actions.getBrandCategories,
        },
        dispatch
    );
}

function mapStateToProps({fuse, commerce}) {
    return {
        dialogs: fuse.dialogs,
        selectedCategory: commerce.brands.selectedCategory,
    };
}

export default withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(BrandCategoryDialog)));
