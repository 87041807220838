import {lazy} from "react";

export const CustomerConfig =  [
    {
        path     : "/customers/my/:my",
        component: lazy(() =>
            import("./list/Page")
        )
    },
    {
        path     : "/customers/:customerId/:scrollInto",
        component: lazy(() =>
            import("./single/Customer")
        )
    },
    {
        path     : "/customers/:customerId/",
        component: lazy(() =>
            import("./single/Customer")
        )
    },
    {
        path     : "/customer/:customerId/",
        component: lazy(() =>
            import("./single/Customer")
        )
    },
    {
        path     : "/customers",
        component: lazy(() =>
            import("./list/Page")
        )
    },
];
