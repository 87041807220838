import {deleteFavorite, getFavorites, turnOff, turnOn} from "@fuseActions";
import {Button, Card, Grid, Icon, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";
import {EmptyView} from "@ui";
import {commonPrefix} from "myaxios";
import {useEffect} from "react";

import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import useSWR from "swr";
import sources from "./translate/favorite.json";

const FavoritePanel = (props: {
    addTranslation: (arg0: {
        favorite: {
            "No shortcut": string[];
            "No favorite": string[];
            "No actions": string[];
            Shortcuts: string[];
            Favorites: string[];
            Actions: string[];
        };
    }) => void;
}) => {
    const {data, isLoading, error, mutate} = useSWR(`${commonPrefix}/favorites?size=100`, {revalidateOnMount: true});
    const dispatch = useDispatch();
    useEffect(() => {
        props.addTranslation(sources);
        // @ts-ignore
        dispatch(getFavorites("dialog"));
        // @ts-ignore
        dispatch(getFavorites("link"));
    }, []);

    if (isLoading || error) return <EmptyView loading={isLoading} error={error} />;

    return (
        <Grid container>
            <Grid item sm={2} xs={12}>
                <div className="justify-between w-full flex">
                    <Typography variant={"h6"} className={"mt-16 ml-16"}>
                        <Translate id={"favorite.Shortcuts"} />
                    </Typography>

                    <IconButton className={"block sm:hidden"} onClick={() => dispatch(turnOff("favorites"))} color="inherit">
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <List dense className={"w-full"}>
                    {data?.content && data.content.length > 0 && data.content.some((s) => s.type === "link") ? (
                        data.content
                            .filter((s) => s.type === "link")
                            .map((_item, i) => (
                                <ListItem key={i} button component={Link} onClick={() => dispatch(turnOff("favorites"))} to={_item.sourceId}>
                                    <ListItemText
                                        classes={{primary: "whitespace-no-wrap overflow-hidden"}}
                                        primary={<Translate id={_item.title} />}
                                        secondary={_item.sourceId}
                                    />

                                    <ListItemSecondaryAction>
                                        <IconButton
                                            size={"small"}
                                            className={"text-yellow-dark"}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                // @ts-ignore
                                                dispatch(deleteFavorite(_item.id, _item.type, mutate));
                                            }}>
                                            <Icon fontSize={"small"}>star</Icon>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                    ) : (
                        <ListItem>
                            <ListItemText primary={<Translate id={"favorite.No shortcut"} />} />
                        </ListItem>
                    )}
                </List>
            </Grid>
            <Grid
                item
                sm={7}
                xs={12}
                className="pb-20 px-8 sm:border-b-0 sm:border-t-0 sm:border-rigth border-grey-lighter sm:border-l-1 border-t-1 border-b-1">
                <Typography variant={"h6"} className={"mt-16 ml-16"}>
                    <Translate id={"favorite.Favorites"} />
                </Typography>
                <Grid container>
                    {data?.content && data.content.length > 0 ? (
                        data.content.map(
                            (_item, i) =>
                                _item.type !== "link" &&
                                _item.type !== "dialog" && (
                                    <Grid key={i} item sm={3} xs={6} className={" block capitalize p-8"}>
                                        <Card className={`bg-${_item.type}-lighter`}>
                                            <List dense className={"p-0"}>
                                                <ListItem
                                                    button
                                                    dense
                                                    component={Link}
                                                    to={`/${_item.type}/${_item.sourceId}`}
                                                    onClick={() => dispatch(turnOff("favorites"))}>
                                                    <ListItemText classes={{primary: "whitespace-no-wrap overflow-hidden"}} primary={_item.title} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            size={"small"}
                                                            className={"text-yellow-dark -mr-8"}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                // @ts-ignore
                                                                dispatch(deleteFavorite(_item.id, _item.type, mutate));
                                                            }}>
                                                            <Icon fontSize={"small"}>star</Icon>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                )
                        )
                    ) : (
                        <ListItem>
                            <ListItemText primary={<Translate id={"favorite.No favorite"} />} />
                        </ListItem>
                    )}
                </Grid>
            </Grid>

            <Grid item sm={3} xs={12} className="pl-12 pb-24">
                <Typography variant={"h6"} className={"mt-16 ml-16"}>
                    <Translate id={"favorite.Actions"} />
                </Typography>
                <Grid container>
                    {data?.content && data.content.length > 0 && data.content.some((s) => s.type === "dialog") ? (
                        data.content
                            .filter((s) => s.type === "dialog")
                            .map((_item, i) => (
                                <Grid key={i} item className={"w-1/2 pr-12 py-8"}>
                                    <Button
                                        size={"large"}
                                        onClick={() => {
                                            dispatch(turnOff("favorites"));
                                            dispatch(turnOn(_item.sourceId));
                                        }}
                                        variant={"contained"}
                                        fullWidth
                                        color={"primary"}>
                                        <Translate id={_item.title} />
                                    </Button>
                                </Grid>
                            ))
                    ) : (
                        <ListItem>
                            <ListItemText primary={<Translate id={"favorite.No actions"} />} />
                        </ListItem>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withLocalize(FavoritePanel);
