import {adminConfigs} from "../admin/adminConfig";
import {DevelopersConfig} from "../admin/developersConfig";
import {FlowFormConfig} from "../components/FlowForm/FlowFormConfig";
import {DashboardAppConfig} from "../dashboards/main/DashboardAppConfig";
import {NotificationsConfig} from "../notifications/NotificationConfig";
import {ArchiveConfig} from "./archive/Config";
import {ConfigBCMProject} from "./BCMProjects/Config";
import {ConfigBCMProjectNewTab} from "./BCMProjects/NewWindowsConfig";
import {DeployConfig} from "./camunda-process/DeployConfig";
import {cmbTransactionDataConfig} from "./CMBTransactionData/cmbTransactionDataConfig";
import {CommerceAppConfig} from "./commerce/CommerceAppConfig";
import {ProjectsConfig} from "./commerce/projects/ConfigProjects";
import {contractConfig} from "./contracts/contractConfig";
import {DatagridConfig} from "./datagrids/route";
import {ExternalConfig} from "./external_management/ExternalConfig";
import {FinancialConfig} from "./financials/FinancialConfig";
import {myFlowAppConfig} from "./flow/Config";
import {HrConfig} from "./hr/Config";
import {IncomeModuleConfig} from "./incomes/IncomeModuleConfig";
import {IssuesAppConfig} from "./issues/ConfigIssues";
import {BusinessTripsConfig} from "./leaveRequests/Config";
import {MembersLocConfig} from "./locations/MembersLocConfig";
import {marketingConfig} from "./marketing/marketingConfig";
import {OutsourceConfig} from "./outsource/outsourceConfig";
import {ParkingConfig} from "./parking/parkingConfig";
import {SCMsAppConfig} from "./production/Config";
import {RedPacketConfig} from "./redPackets/RedPacketConfig";
import {StockAppConfig} from "./stock/Config";
import {TodoAppConfig} from "./tasks/ConfigTasks";
import {wpConfig} from "./workplans/ConfigWPs";
import {projectWPsConfig} from "./workplans/projectWPsConfig";

export const appsConfigs = [
    ParkingConfig,
    FlowFormConfig,
    cmbTransactionDataConfig,
    ExternalConfig,
    IncomeModuleConfig,
    TodoAppConfig,
    CommerceAppConfig,
    DashboardAppConfig,
    ProjectsConfig,
    RedPacketConfig,
    IssuesAppConfig,
    MembersLocConfig,
    projectWPsConfig,
    contractConfig,
    wpConfig,
    DeployConfig,
    SCMsAppConfig,
    StockAppConfig,
    myFlowAppConfig,
    OutsourceConfig,
    HrConfig,
    ConfigBCMProjectNewTab,
    ConfigBCMProject,
    ArchiveConfig,
    BusinessTripsConfig,
    NotificationsConfig,
    FinancialConfig,
    DevelopersConfig,
    DatagridConfig,
    ...marketingConfig,
    ...adminConfigs,
];
