import {List} from "@material-ui/core";
import clsx from "clsx";
import {Fragment} from "react";
import FuseNavHorizontalCollapse from "./horizontal/FuseNavHorizontalCollapse";
import FuseNavHorizontalGroup from "./horizontal/FuseNavHorizontalGroup";
import FuseNavHorizontalItem from "./horizontal/FuseNavHorizontalItem";
import FuseNavHorizontalLink from "./horizontal/FuseNavHorizontalLink";
import FuseNavVerticalGroup from "./vertical/FuseNavVerticalGroup";

/**
 * @return {null}
 */
function FuseNavigation(props) {
    const {navigation, layout, active, dense, className} = props;

    const verticalNav = (
        <List className={clsx("navigation whitespace-no-wrap", className)} data-cy={"navigation"}>
            {navigation?.map((item, index) => (
                <Fragment key={item.id}>
                    {item.type === "group" && <FuseNavVerticalGroup index={index} item={item} nestedLevel={0} active={active} dense={dense} />}

                    {/* {item.url === "collapse" && <FuseNavVerticalCollapse item={item} nestedLevel={0} active={active} dense={dense} />} */}

                    {/* {item.type === "item" && <FuseNavVerticalItem item={item} nestedLevel={0} active={active} dense={dense} />} */}

                    {/* {item.type === "link" && <FuseNavVerticalLink item={item} nestedLevel={0} active={active} dense={dense} />} */}

                    {/* {item.type === "divider" && <Divider className="my-16" />} */}
                </Fragment>
            ))}
        </List>
    );

    const horizontalNav = (
        <List className={clsx("navigation whitespace-no-wrap flex p-0", className)} data-cy={"navigation"}>
            {navigation?.map((item) => (
                <Fragment key={item.id}>
                    {item.type === "group" && <FuseNavHorizontalGroup item={item} nestedLevel={0} dense={dense} />}

                    {item.type === "collapse" && <FuseNavHorizontalCollapse item={item} nestedLevel={0} dense={dense} />}

                    {item.type === "item" && <FuseNavHorizontalItem item={item} nestedLevel={0} dense={dense} />}

                    {item.type === "link" && <FuseNavHorizontalLink item={item} nestedLevel={0} dense={dense} />}
                </Fragment>
            ))}
        </List>
    );

    if (navigation?.length > 0) {
        switch (layout) {
            case "horizontal": {
                return horizontalNav;
            }
            case "vertical":
            default: {
                return verticalNav;
            }
        }
    } else {
        return null;
    }
}


export default FuseNavigation;
