import * as Actions from "../actions";
import { cloneDeep } from "lodash";

const initState = {
    checkinResults: [],
    selectedCheckinParams: null,
};


const checkin_Results = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_CHECKIN_RESULTS: {
            let tobj = cloneDeep(state);
            tobj.checkinResults = action.payload;
            return  tobj;
        }
        case Actions.SELECT_CHECKIN_PHOTOS: {
            let tobj = cloneDeep(state);
            tobj.selectedCheckinParams = action.payload;
            return  tobj;
            // return {
            //     ...state,
            //     selectedCheckinParams: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default checkin_Results;
