import {DialogContent, FormControl, Grid, TextField} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import {MyTooltip} from "@ui";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import DatePickerWrapper from "utils/DatePickerWrapper/DatePickerWrapper";
import {EnumFormControl} from "../../marketingTools";

function ValueLabelAR(props) {
    const {children, open, value} = props;

    return (
        <MyTooltip open={open} enterTouchDelay={0} placement="top" title={value + "万"}>
            {children}
        </MyTooltip>
    );
}

// function ValueLabelNE(props) {
//     const { children, open, value } = props;
//
//     return (
//         <Tooltip open={open} enterTouchDelay={0} placement="top" title={value+"%"}>
//             {children}
//         </Tooltip>
//     );
// }
// const marks = [
//     {
//         value: 0,
//         label:  localStorage.getItem("i18nextLng") === "cn" ?    "无机会"   :  'No chance',
//     },
//     {
//         value: 50,
//         label: '50%',
//     },
//     {
//         value: 100,
//         label: localStorage.getItem("i18nextLng") === "cn" ?    "已完成"   :  'Almost done',
//     }
// ];
// const AmountMarks = [
//     // {
//     //     value: 10,
//     //     label: '10万',
//     // },
//     {
//         value: 100,
//         label: '100万',
//     },
//     {
//         value: 1000,
//         label: '1000万',
//     },
//     {
//         value: 250,
//         label: '250万',
//     },
//     {
//         value: 500,
//         label: '500万',
//     },
//     {
//         value: 750,
//         label: '750万',
//     },
//     // {
//     //     value: 5000,
//     //     label: '5000万',
//     // },
// ];

const Step1 = ({formData, handleFormData, activeLanguage}) => {
    const [complete, setComplete] = useState(false);

    // const [marks, setMarks] = React.useState([
    //     {
    //         value: 0,
    //         label:  localStorage.getItem("i18nextLng") === "cn" ?    "无机会"   :  'No chance',
    //     },
    //     {
    //         value: 50,
    //         label: '50%',
    //     },
    //     {
    //         value: 100,
    //         label: localStorage.getItem("i18nextLng") === "cn" ?    "已完成"   :  'Almost done',
    //     }
    // ]);

    const [AmountMarks, setAmountMarks] = useState([
        {
            value: 100,
            label: "100万",
        },
        {
            value: 1000,
            label: "1000万",
        },
        {
            value: 250,
            label: "250万",
        },
        {
            value: 500,
            label: "500万",
        },
        {
            value: 750,
            label: "750万",
        },
    ]);

    useEffect(() => {
        localizeData();
    }, [activeLanguage]);

    const localizeData = () => {
        activeLanguage.code === "cn"
            ? setAmountMarks([
                  {
                      value: 100,
                      label: "100万",
                  },
                  {
                      value: 1000,
                      label: "1000万",
                  },
                  {
                      value: 250,
                      label: "250万",
                  },
                  {
                      value: 500,
                      label: "500万",
                  },
                  {
                      value: 750,
                      label: "750万",
                  },
              ])
            : setAmountMarks([
                  {
                      value: 100,
                      label: "One million",
                  },
                  {
                      value: 1000,
                      label: "Ten million",
                  },
                  {
                      value: 250,
                      label: "2.5 million",
                  },
                  {
                      value: 500,
                      label: "Five million",
                  },
                  {
                      value: 750,
                      label: "7.5 million",
                  },
              ]);
        //
        // activeLanguage.code === "cn" ? setMarks([
        //     {
        //         value: 0,
        //         label: "无机会"
        //     },
        //     {
        //         value: 50,
        //         label: '50%',
        //     },
        //     {
        //         value: 100,
        //         label: "已完成"
        //     }
        // ]) : setMarks([
        //     {
        //         value: 0,
        //         label: 'No chance',
        //     },
        //     {
        //         value: 50,
        //         label: '50%',
        //     },
        //     {
        //         value: 100,
        //         label:  'Almost done',
        //     }
        // ])
    };

    return (
        <DialogContent className={classNames("max-h-0", complete ? "overflow-visible" : " overflow-hidden")}>
            <Divider className="my-20" />
            <Typography variant={"h6"} className="mb-16">
                {<Translate id={"market.More Information"} />}
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Translate>
                        {({translate}) => (
                            <DatePickerWrapper
                                disableToolbar
                                variant="inline"
                                label={translate("market.closing date")}
                                autoOk
                                minDate={new Date("2000-1-1")}
                                // maxDate={new Date()}
                                format={"yyyy-mm-dd"}
                                TextFieldComponent={(_prop) => (
                                    <TextField
                                        {..._prop}
                                        fullWidth
                                        label={translate("market.closing date")}
                                        value={formData.closingDate ? new Date(formData.closingDate).toLocaleDateString() : ""}
                                    />
                                )}
                                onChange={(reminder) => handleFormData("closingDate")({target: {value: reminder}})}
                            />
                        )}
                    </Translate>
                </Grid>
                <Grid item md={6} xs={12}>
                    <EnumFormControl type={"SalesContactLeadSource"} name={"leadSource"} formData={formData} handleFormData={handleFormData} />
                </Grid>
            </Grid>

            <div className="px-20">
                <FormControl fullWidth>
                    <InputLabel htmlFor="Amount">{<Translate id={"market.Amount"} />}</InputLabel>
                    <div className="mt-72 -mb-32">
                        <Slider
                            id={"Amount"}
                            ValueLabelComponent={ValueLabelAR}
                            step={50}
                            min={100}
                            max={1000}
                            value={formData.amount}
                            onChange={(event, newValue) => handleFormData("amount")({target: {value: newValue}})}
                            name={"amount"}
                            valueLabelDisplay="on"
                            marks={AmountMarks}
                        />
                    </div>
                </FormControl>

                {/*<FormControl fullWidth className="mt-16">*/}
                {/*    <InputLabel htmlFor="Amount">{<Translate id={"market.Probability"}/>}</InputLabel>*/}
                {/*    <div className="mt-72">*/}
                {/*        <Slider*/}
                {/*            min={0}*/}
                {/*            max={100}*/}
                {/*            defaultValue={50}*/}
                {/*            id={"Probability"}*/}
                {/*            ValueLabelComponent={ValueLabelNE}*/}
                {/*            step={10}*/}
                {/*            value={formData.probability}*/}
                {/*            onChange={(event, newValue)=>handleFormData("probability")({target: {value: newValue}})}*/}
                {/*            name={"probability"}*/}
                {/*            valueLabelDisplay="on"*/}
                {/*            marks={marks}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</FormControl>*/}
            </div>
        </DialogContent>
    );
};

export default Step1;
