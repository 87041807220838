import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    stats: [],
    selectedStats: null
};

const  myRedPacketReducers = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_STATS: {
            let tobj = cloneDeep(state);
            tobj.stats = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     stats: action.payload
            // };
        }
        case Actions.SELECT_ONE_RED_PACKET_TEMPORY_PER_MONTH: {
            let tobj = cloneDeep(state);
            tobj.selectedStats = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedStats: action.payload
            // };
        }

        case Actions.UNSELECT_ONE_RED_PACKET_TEMPORY_PER_MONTH: {
            let tobj = cloneDeep(state);
            tobj.selectedStats = null;
            return tobj;
            // return {
            //     ...state,
            //     selectedStats: null
            // };
        }

        default:
            return cloneDeep(state);
    }
};

export default myRedPacketReducers;
