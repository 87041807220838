import PropTypes from "prop-types";
import TextPlaceHolderView from "../../utils/ui/TextPlaceHolderView";
import {Translate} from "react-localize-redux";
import {StatusColor} from "../../utils/utils";

StatusView.propTypes = {
    item: PropTypes.object
};

function StatusView(props) {
    const {item} = props;
    return (
        <div className={"flex justify-center"}>
            {   item.status ?
                <span className={`${StatusColor(item)} px-3 py-2 text-xs font-black rounded-full inline-block`}>
                            <Translate id={`process.${item.status}`}/>
                    </span> :
                <TextPlaceHolderView emptyTextTip={<Translate id={"process.no status"}/>} />
            }
        </div>
    );
}

export default StatusView;
