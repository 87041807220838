import {lazy} from "react";

export const ConfigActivities = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/activity/:id",
            component: lazy(() =>
                import("./activity/SinglePage")
            )
        },
        {
            path     : "/activity/:id/",
            component: lazy(() =>
                import("./activity/SinglePage")
            )
        },
        {
            path     : "/marketing/activities",
            component: lazy(() =>
                import("./list/Page")
            )
        }
    ]
};
