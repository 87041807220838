import {useEffect, useState} from "react";
import {addParam} from "@fuseActions";
import {useMemoizedFn} from "ahooks";

import {useDispatch, useSelector} from "react-redux";
import {useLocalParam} from "../../app/main/incomes/AccountFilter/hooks";

/*
 * inputProps: {
 *      name: "",  filter  save key
 *      fetchData: func,
 * }
 * */
/**
 * @param {Object}  inputProps
 * @param {bool}  inputProps.disableRequest - 取消网络请求,页面已经监听 search 的更新
 * @param {string}  inputProps.name   the identifier to save search params to local
 * @param {function}  inputProps.fetchDatas
 */
function usePagination(inputProps) {
    const {name, fetchDatas, disableRequest} = inputProps;

    const dispatch = useDispatch();

    const search = useSelector(({fuse}) => fuse.search);

    const {saveFilterToLocal} = useLocalParam({identifier: name});

    const [options, setOptions] = useState({
        page: 1,
        rowsPerPage: 10,
        jumpPage: 1,
    });

    useEffect(() => {
        if (search.page === 1 && (options.page !== 1 || options.jumpPage !== 1)) {
            setOptions({
                ...options,
                page: 1,
                jumpPage: 1,
            });
        }
        if (search.size === 10 && options.rowsPerPage !== 10) {
            setOptions({
                ...options,
                rowsPerPage: 10,
            });
        }
    }, [search.page, search.size]);

    const handleChangePage = useMemoizedFn((event, page) => {
        setOptions({
            ...options,
            page: page + 1,
            jumpPage: page + 1,
        });
        dispatch(addParam({page: page + 1})).then(() => {
            const t = {...search, page: page + 1};
            saveFilterToLocal(t);
            if (disableRequest && disableRequest === true) return;
            fetchDatas();
        });
    });

    const handleGoToPage = useMemoizedFn((e, page) => {
        setOptions({
            ...options,
            page,
        });
        dispatch(addParam({page})).then(() => {
            const t = {...search, page};
            saveFilterToLocal(t);
            if (disableRequest && disableRequest === true) return;
            fetchDatas();
        });
    });

    const handleChangeRowsPerPage = useMemoizedFn((event) => {
        setOptions({
            ...options,
            rowsPerPage: event.target.value,
            page: 1,
            jumpPage: 1,
        });
        dispatch(addParam({size: event.target.value, page: 1})).then(() => {
            const t = {...search, size: event.target.value, page: 1};
            saveFilterToLocal(t);
            console.log("usePagination handleChangeRowsPerPage", search);
            if (disableRequest && disableRequest === true) return;
            fetchDatas();
        });
    });

    /** @function */
    /**
     *  @param {Object}  pameter
     *  @param {number}  pameter.page
     *  @param {number}  pameter.rowsPerPage
     *  ................
     */
    const handleUpdateOptions = useMemoizedFn((pameter) => {
        if (pameter.rowsPerPage) {
            setOptions({
                ...options,
                page: 1,
                jumpPage: 1,
                rowsPerPage: pameter.rowsPerPage,
            });
        } else {
            setOptions({
                ...options,
                ...pameter,
            });
        }
    });

    return {
        page: options.page,
        rowsPerPage: options.rowsPerPage,
        jumpPage: options.jumpPage,
        updateOptions: handleUpdateOptions,
        onPageChange: handleChangePage,
        onRowsPerPageChange: handleChangeRowsPerPage,
        onGoToPage: handleGoToPage,
    };
}

export default usePagination;
