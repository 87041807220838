import * as Actions from "../actions/incomeRecognition.actions";
import {cloneDeep} from "lodash";

const initialState = {
    /*
    * {
  "page": 0,
  "size": 0,
  "totalPages": 0,
  "totalElements": 0,
  "content": [
    {
      "id": "string",
      "description": "string",
      "importCount": 0,
      "incomeCount": 0,
      "user": {},
      "timestamp": 0
    }
  ]
}
    * */
    attchmentHistoryRes: null,
    tableCols: [],
    tableRows: [],
    tableOriginalDatas: []
};

const incomeRecognitionReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_ATTACHMENTS_HISTORY:{
            let tobj = cloneDeep(state);
            tobj.attchmentHistoryRes = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     attchmentHistoryRes: action.payload
            // };
        }
        case Actions.GET_EXCEL_TABLE_COLS:{
            let tobj = cloneDeep(state);
            tobj.tableCols = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     tableCols: action.payload
            // };
        }
        case Actions.GET_EXCEL_TABLE_ROWS:{
            let tobj = cloneDeep(state);
            tobj.tableRows = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     tableRows : action.payload
            // };
        }
        case Actions.GET_TABLE_ORIGINAL_DATASOURCES:{
            let tobj = cloneDeep(state);
            tobj.tableOriginalDatas = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     tableOriginalDatas : action.payload
            // };
        }
        default:{
            return state;
        }
    }
};

export default incomeRecognitionReducer;
