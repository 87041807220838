import axios, {commonPrefix} from "@api";
import _ from "@lodash";
import {Icon} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {setDefaultSettings} from "app/store/actions";
import {memo} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

function NavbarFoldedToggleButton(props) {
    const {children = <Icon>menu</Icon>} = props;
    const dispatch = useDispatch();
    const settings = useSelector(({fuse}) => fuse.settings.current, shallowEqual);

    return (
        <Button
            className={props.className}
            onClick={() => {
                axios.post(`${commonPrefix}/clickCount`, {
                    type: "OPEN_MENU",
                    name: "toggleMenu",
                });
                dispatch(setDefaultSettings(_.set({}, "layout.config.navbar.folded", !settings.layout.config.navbar.folded)));
            }}
            color="inherit">
            {children}
        </Button>
    );
}

export default memo(NavbarFoldedToggleButton);
