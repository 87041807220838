import {createContext} from "react";
import {makeStyles} from "@material-ui/core";

/**
 *  ----------------------------------------------------------------
 *  @description - DEF_MANAGER_REQUEST_PERSIST_KEY  > 流程定义列表 <网络请求参数> 的 持久化 key
 *  ----------------------------------------------------------------
 */
export const DEF_MANAGER_REQUEST_PERSIST_KEY = "[DEF_MANAGER_REQUEST_PERSIST_KEY] > request_query";


/**
 * @description - DefinitionContext 流程定义列表的上下文
 */
export const DefinitionContext = createContext(null);


/**
 * @description - useStyles 流程定义列表的侧边筛选栏的样式
 */
export const useStyles = makeStyles(theme => ({
    root: {},
    listWrapper: {},
    listItem: {
        color: "inherit!important",
        textDecoration: "none!important",
        height: 40,
        width: "calc(100% - 16px)",
        borderRadius: "0 20px 20px 0",
        paddingLeft: 24,
        paddingRight: 12,
        "&.active": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + "!important",
            pointerEvents: "none",
            "& .list-item-icon": {
                color: "inherit"
            }
        },
        "& .list-item-icon": {
            fontSize: 16,
            width: 16,
            height: 16
        }
    },
    listSubheader: {
        paddingRight: 24,
        paddingLeft: 24
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        padding: theme.spacing(1)
    },
}));
