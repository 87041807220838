import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch, useSelector} from "react-redux";
import * as fuseActions from "@fuseActions";
import * as Actions from "../../store/actions";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useParams} from "react-router";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";

function ReStartPauseInstanceDialog(props) {
    const dispatch = useDispatch();

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const {id} = useParams();

    const search = useSelector(({fuse}) => fuse.search);

    const selectedInstance = useSelector(({process}) => process.instances_list.selectedInstance);

    const suspended = useSelector(({process}) => process.definition.suspended);

    const [loading, setLoading] = useState(false);

    const [pause, setPause] = useState(false);

    useEffect(() => {
        setPause(suspended);
        return function cleanup() {
            setLoading(false);
        };
    }, []);

    function handleDelete() {
        dispatch(fuseActions.turnOff("ReStartPauseInstanceDialog"));
    }

    function canSubmit() {
        return loading === true;
    }

    function handleSubmit() {
        setLoading(true);
        const params = {
            id: selectedInstance.id,
            suspended: pause,
        };
        axios
            .put(`${flowPrefix}/processInstanceSetSuspended`, params)
            .then((res) => {
                setLoading(false);
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: pause ? "message.Definition suspended!" : "message.Definition started!",
                    })
                );
                if (id) {
                    dispatch(Actions.getProcessDefinitionSingle(id));
                } else {
                    dispatch(Actions.getProcessInstances(search));
                }
                handleDelete();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Dialog
            open={!!dialogs["ReStartPauseInstanceDialog"]}
            onClose={() => {
                handleDelete();
            }}
            fullWidth
        >
            <div className={loading ? "loading" : null}>
                <DialogTitle id="PauseDefinitionTipsDialog-dialog-title">
                    <Translate id="process.Start or pause this instance" />
                    <Chip
                        className={classNames(
                            "font-bold",
                            selectedInstance.suspended ? "bg-red text-white ml-3" : "bg-green text-white ml-2"
                        )}
                        label={<Translate id={`process.${selectedInstance.suspended ? "suspended" : "running"}`} />}
                    />
                </DialogTitle>
                <DialogContent>
                    <div className="flex flex-col justify-center">
                        <Typography variant="button" className={"text-center"} display="block">
                            {selectedInstance.suspended ? (
                                <Translate id={"process.Sure you want to start the current process instance?"} />
                            ) : (
                                <Translate id={"process.Sure you want to suspend the current process instance?"} />
                            )}
                        </Typography>

                        {/*<div className={"flex justify-center mt-5"}>*/}
                        {/*    <Typography component="div" variant="h6">*/}
                        {/*        <Grid component="label" container alignItems="center" spacing={1}>*/}
                        {/*            <Grid item>{*/}
                        {/*                <Translate id={"process.suspended"}/>*/}
                        {/*            }</Grid>*/}
                        {/*            <Grid item>*/}
                        {/*                <AntSwitch checked={!pause} onChange={e=>setPause(!e.target.checked)} name="checkedC" />*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item>{*/}
                        {/*                <Translate id={"process.running"}/>*/}
                        {/*            }</Grid>*/}
                        {/*        </Grid>*/}
                        {/*    </Typography>*/}
                        {/*</div>*/}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete()}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button disabled={canSubmit()} onClick={() => handleSubmit()} color="secondary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(ReStartPauseInstanceDialog);
