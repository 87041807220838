import {lazy} from "react";

export const FlowFormConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : "/flowForm",
            component:  lazy(() => import("./Page"))
        },
        {
            path     : "/createFlowForm",
            component:  lazy(() => import("./create-form/CreateForm"))
        },
    ]
};
