import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import type {ReactNode} from "react";

type Props = {
    secondaryActions?: ReactNode;
    widgets?: ReactNode;
    extraInfo?: ReactNode;
    extraActions?: ReactNode;
    tabs?: ReactNode;
};
export const InfoBar = ({widgets, extraInfo, extraActions, secondaryActions, tabs}: Props) => {
    const onlyTabs = tabs && !(!!widgets || !!extraInfo || !!extraActions || !!secondaryActions);
    return (
        <>
            <Card className={"mb-12  mx-12 overflow-visible"}>
                {!onlyTabs && (
                    <>
                        <div className="md:flex pb-8 px-8 justify-between items-end">
                            <div>
                                {widgets && <div className={clsx(!!extraInfo && "mb-12", "flex mt-8 overflow-x-auto items-center")}>{widgets}</div>}
                                {extraInfo}
                            </div>

                            <div className={"flex flex-col justify-end items-end"}>
                                <div className="mr-12">{extraActions}</div>
                                <div className={"mt-3 -mb-4"}>{secondaryActions}</div>
                            </div>
                        </div>
                        <Divider />
                    </>
                )}
                {tabs}
            </Card>
        </>
    );
};
