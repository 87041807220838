import {forwardRef, useState} from "react";
import * as fuseActions from "@fuseActions";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, Zoom} from "@material-ui/core";
import {Translate, withLocalize} from "react-localize-redux";
import Typography from "@material-ui/core/Typography";

import {ErrorButton} from "../utilComponents";

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});

// const sourcesMap = {
//     success: animationJson,
//     fail: errorJson,
// };

function MyDialog(props) {
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState("");

    const [animationSource, setAnimationSource] = useState("success");

    const [loading, setLoading] = useState(false);

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const params = useSelector(({fuse}) => fuse.dialogs.params);

    const {request, successFunc} = params;

    const handleClose = () => {
        dispatch(fuseActions.turnOff("MyDialog"));
    };

    const handleSure = () => {
        setLoading(true);
        setAnimationSource("success");
        setErrorMessage("");
        request(
            () => {
                successFunc(() => {
                    setLoading(false);
                    handleClose();
                    dispatch(
                        fuseActions.showMessage({
                            variant: "success",
                            message: "message.Successfully done",
                        })
                    );
                    // dispatch(fuseActions.showMessage("message.SuccessFully deleted!"))
                });
            },
            () => {
                setLoading(false);
                setAnimationSource("fail");
                setErrorMessage("_.Network error, please check the network or try again!");
            }
        );
    };

    return (
        <>
            <Dialog
                open={dialogs["MyDialog"]}
                TransitionComponent={Transition}
                transitionDuration={300}
                keepMounted
                fullWidth
                maxWidth={"xs"}
                onClose={handleClose}
                BackdropProps={{style: {backdropFilter: "saturate(180%) blur(10px)"}}}
                className="min-w-68"
                aria-labelledby="alert-dialog-zoom-1"
                aria-describedby="alert-dialog-zoom-2"
            >
                <DialogContent className={loading ? "loading" : ""}>
                    <Typography className="mt-5 text-center" variant="subtitle2">
                        {animationSource === "success" ? params ? params.title : "" : <Translate id={errorMessage} />}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {<Translate id={"_.cancel"} />}
                    </Button>
                    <ErrorButton
                        data-cy={"deleteConfirm"}
                        className={"ml-3"}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleSure();
                        }}
                        variant={"contained"}
                    >
                        {animationSource === "success" ? (
                            params ? (
                                params.sureButtonText
                            ) : (
                                ""
                            )
                        ) : (
                            <Translate id={"_.reTry"} />
                        )}
                    </ErrorButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withLocalize(MyDialog);
