import {turnOff} from "@fuseActions";
import {Icon, IconButton} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {CodeView, EmptyView, ItemTitle, LocalTimeStamp, MyChip} from "@ui";
import WidgetWrapper from "app/dashboards/main/widgets/WidgetWrapper";
import {commonPrefix} from "myaxios";
import qs from "qs";
import type {ReactNode} from "react";

import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import useSWR from "swr";
import type {SearchType} from "types";
import {dateFormatWithTime} from "variables";
import axios from "../../../../myaxios";
import {WidgetContent} from "../../../../utils/Page";
import type {SavedSearchType} from "../../../../utils/Page/Filters/SaveSearch";
import {taskStates} from "../../../main/flow/enums";
import IssuesListItem from "../../../main/issues/components/ItemView";
import TaskItem from "../../../main/tasks/List/ItemView";
import OSDELOrderItemView from "./OSDELOrderItemView";
import OSInvoicesItemView from "./OSInvoicesItemView";
import OSOrderItemView from "./OSOrderItemView";
import PRItemView from "./PRItemView";
import PTItemView from "./PTItemView";

type Props = {
    remove(from: string, type: string): void;
    removeFrom: string;
    type: string;
};

type Saved = {
    data: SearchType;
    id: string;
    key: string;
    name: string;
    type: "filter";
};

type KTL<T> = {
    path: string;
    link: string;
    itemView(item: T): ReactNode;
    to?(item: T): string;
};

const keyToLink: Record<string, KTL<any>> = {
    productionTasks: {
        path: "SCMs",
        link: "srx-scm/v1/productionTasks",
        to: (item) => `/SCM/${item.id}`,
        itemView: (item) => <PTItemView item={item} />,
    },
    productionList: {
        path: "productionList",
        link: "srx-scm/v1/productionList",
        itemView: (item) => (
            <>
                <ListItemText
                    disableTypography
                    primary={
                        <>
                            <CodeView dense>{item.productId}</CodeView>
                            <ItemTitle short>{item.productName}</ItemTitle>
                        </>
                    }
                    secondary={
                        <>
                            <Typography>{item.plantName}</Typography>
                        </>
                    }
                />
                <LocalTimeStamp format={dateFormatWithTime} datetime={item.startTime} />
            </>
        ),
    },
    productionReports: {
        path: "productionReports",
        link: "srx-scm/v1/productionTaskReports",
        itemView: (item) => <PRItemView item={item} />,
    },
    todo: {
        path: "todo",
        link: "srx-pms/v1/myTasks",
        to: (item) => `/todo/${item.id}`,
        // @ts-ignore taskItem is old
        itemView: (item) => <TaskItem item={item} />,
    },
    issues: {
        path: "myIssues/all",
        link: "srx-pms/v1/myIssues",
        to: (item) => `/issue/${item.id}`,
        itemView: (item) => <IssuesListItem item={item} noAssignee dense />,
    },
    projectIssue: {
        path: "myIssues/all",
        link: "srx-pms/v1/myIssues",
        to: (item) => `/issue/${item.id}`,
        itemView: (item) => <IssuesListItem item={item} noAssignee dense />,
    },
    osInvoices: {
        path: "os/invoices",
        link: "srx-scm/v1/os/invoices",
        to: (item) => `/os/invoice/${item.id}`,
        itemView: (item) => <OSInvoicesItemView item={item} />,
    },
    osOrders: {
        path: "os/orders",
        link: "srx-scm/v1/os/orders",
        to: (item) => `/os/order/${item.id}`,
        itemView: (item) => <OSOrderItemView item={item} />,
    },
    osDelOrders: {
        path: "os/deliveryOrders",
        link: "srx-scm/v1/os/deliveryOrders",
        to: (item) => `/os/deliveryOrder/${item.id}`,
        itemView: (item) => <OSDELOrderItemView item={item} />,
    },
    flowTasks: {
        path: "flow/tasks",
        link: "srx-flow/v1/myTasks",
        to: (item) => `/flow/tasks/${item.id}`,
        itemView: (item) => (
            <>
                <ListItemText primary={item.name} secondary={item.processDefinition?.name} />
                <MyChip color={taskStates[item.delegationState].color} label={taskStates[item.delegationState].label} />
            </>
        ),
    },
};

const SavedSearch = (props: Props) => {
    const {data, mutate} = useSWR<Saved[]>(`${commonPrefix}/myData?key=filter`, {revalidateOnMount: true});

    const history = useHistory();

    const dispatch = useDispatch();
    const closeDialog = () => {
        dispatch(turnOff("dailyDashboard"));

        localStorage.setItem("showDashboardDate", +new Date() + "");
    };

    const deleteSavedSearch = (item: SavedSearchType) => () => {
        axios.delete(`${commonPrefix}/myData/${item.id}`).then(() => {
            mutate();
            // refresh()
        });
    };

    return (
        <WidgetWrapper title={<Translate id={"dashboard.Saved search"} />} {...props}>
            <div className={"flex flex-row flex-grow flex-wrap"}>
                {data && data.length > 0 ? (
                    data.map(
                        (_) =>
                            _.type in keyToLink && (
                                <div key={_.type} className={"mr-20 mb-32 "}>
                                    <p className={"text-gray-300 text-2xl font-bold"}>{<Translate id={`dashboard.${_.type}`} />}</p>
                                    <WidgetContent
                                        name={_.id}
                                        link={keyToLink[_.type].link}
                                        onClick={(item) => {
                                            closeDialog();
                                            if (keyToLink[_.type].to) {
                                                history.push(keyToLink[_.type].to?.(item) || "/dashboard");
                                            }
                                        }}
                                        actions={
                                            <div>
                                                <IconButton
                                                    component={Link}
                                                    to={keyToLink[_.type].path + "?" + qs.stringify(_.data)}
                                                    target={"_blank"}>
                                                    <Icon>launch</Icon>
                                                </IconButton>
                                                <IconButton onClick={deleteSavedSearch(_)}>
                                                    <Icon color={"error"}>delete</Icon>
                                                </IconButton>
                                            </div>
                                        }
                                        // @ts-ignore itemview is not static
                                        itemView={keyToLink[_.type].itemView}
                                        params={{..._.data, size: 8}}
                                        title={_.name}
                                    />
                                </div>
                            )
                    )
                ) : (
                    <EmptyView
                        icon={<Icon className={"text-48 text-gray-400"}>manage_search</Icon>}
                        label={<Translate id={"_.Start Saving your filters to see them here"} />}
                    />
                )}
            </div>
        </WidgetWrapper>
    );
};

export default SavedSearch;
