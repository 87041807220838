import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";


export const GET_PROCESSINSTANCES_LIST = "[GET_PROCESSINSTANCES_LIST]";
export const SELECT_A_PROCESSINSTANCE = "[SELECT_A_PROCESSINSTANCE]";
export const SELECT_PROCESSINSTANCE_LIST = "[SELECT_PROCESSINSTANCE_LIST]";
export const INSTANCE_TRANSFORM_USER_SOURCE_TYPE = "[INSTANCE_TRANSFORM_USER_SOURCE_TYPE]";

export function getProcessInstances(params, callback, catchError) {
    const request = axios.get(`${flowPrefix}/processInstances`, {
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_PROCESSINSTANCES_LIST,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}


export function selectAInstance(_instance) {
    return {
        type: SELECT_A_PROCESSINSTANCE,
        payload: _instance
    };
}


export function setTheContextOfTransfer(_type) {
    return {
        type: INSTANCE_TRANSFORM_USER_SOURCE_TYPE,
        payload: _type
    };
}


export function selectInstancesList(_instances) {
    return {
        type: SELECT_PROCESSINSTANCE_LIST,
        payload: _instances
    };
}
