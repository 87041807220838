import type {CardContentProps, CardHeaderProps, CardProps} from '@material-ui/core';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import clsx from 'clsx';
import type {ReactElement, ReactNode} from 'react';
import {EmptyView} from './EmptyView';

type Props = {
    loading?: boolean;
    headerProps?: CardHeaderProps;
    action?: ReactNode;
    noPadding?: boolean;
    contentProps?: CardContentProps;
    title?: ReactElement | string;
    noMargin?: boolean;
    error?: any;
};

export const MyCard = ({
    loading,
    error,
    headerProps,
    action,
    className,
    title,
    children,
    noPadding,
    contentProps,
    onClick,
    noMargin,
    ...rest
}: Props & Omit<CardProps, "title">) => {
    return (
        <Card
            className={clsx(
                "print:border-solid print:border-1 print:border-gray-300 flex-grow overflow-visible",
                onClick && "cursor-pointer hover:shadow-4",
                className,
                !noMargin && "mb-16"
            )}
            onClick={onClick}
            {...rest}>
            {title && <CardHeader title={title} action={action} {...headerProps} />}
            {error || loading ? (
                <EmptyView error={error} loading={loading} />
            ) : (
                <>
                    <CardContent className={noPadding ? "p-0" : ""} classes={{root: noPadding ? "last-pb-0" : ""}} {...contentProps}>
                        {children}
                    </CardContent>
                </>
            )}
        </Card>
    );
};
