import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {CodeView, ColoredDate, LocalTimeStamp} from "@ui";
import {forwardRef} from "react";
import {Translate} from "react-localize-redux";
import PinWP from "./PinWP";
import {useWorkPlan} from "./useWorkPlan";
import Checklists from "./Widgets/Checklists";
import FilesWrapper from "./Widgets/Files/FilesWrapper";
import LeaderInfo from "./Widgets/LeaderInfo";
import ParticipantsInfo from "./Widgets/ParticipantsInfo";
import ProjectInfo from "./Widgets/ProjectInfo";
import VoteWP from "./Widgets/VoteWP";

const Sidebar = forwardRef<HTMLDivElement>((props, ref) => {
    const {wp, canEdit} = useWorkPlan();
    return (
        wp && (
            <>
                {canEdit && <PinWP />}
                {/*<Tags />*/}
                <Card className={"mb-20 overflow-visible"}>
                    <List>
                        <ListItem>
                            <ListItemText primary={<Translate id={"_.workplan id"} />} />
                            <CodeView className={"p-0 -mr-2"}>{wp.id.slice(-6)}</CodeView>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<Translate id={"wps.Last active time:"} />} />
                            <strong>
                                <LocalTimeStamp datetime={wp.activeTime} />
                            </strong>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<Translate id={"wps.Create time:"} />} />
                            <strong>
                                <LocalTimeStamp datetime={wp.timestamp} />
                            </strong>
                        </ListItem>
                        {wp.pinTimestamp ? (
                            <ListItem>
                                <ListItemText primary={<Translate id={"wps.Pin end time:"} />} />
                                <strong>
                                    <ColoredDate date={wp.pinTimestamp} />
                                    {/*<LocalTimeStamp datetime={wp.pinTimestamp} />*/}
                                </strong>
                            </ListItem>
                        ) : (
                            ""
                        )}
                    </List>

                    <LeaderInfo />

                    <ProjectInfo />

                    <ParticipantsInfo />
                </Card>
                <Checklists />
                <VoteWP />
                <Card className={"sticky top-0"}>
                    <FilesWrapper ref={ref} />
                </Card>
            </>
        )
    );
});

Sidebar.displayName = "Sidebar";
export default Sidebar;
