import Icon from "@material-ui/core/Icon";
import {showMessage} from "app/store/actions";
import axios, {firmPrefix} from "myaxios";
import {useEffect, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {uploadFiles} from "utils/fileUploader";
import {createUploader} from "utils/utilities";
import type {wpType} from "../../../types";
import {CustomButton} from "./FilesWrapper";
import {useTranslation} from "react-i18next";

const UploadButton = ({refresh, wp}: {wp: wpType; refresh(): Promise<any>}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    // const attachments = wp?.attachments || [];
    //
    const attachFile = (data) => {
        setLoading(true);
        axios
            .put(`${firmPrefix}/addAttachments`, {
                workplanId: wp.id,
                attachments: [data],
            })
            .then(() => {
                refresh().then(() => setLoading(false));
                dispatch(showMessage({message: "message.File Uploaded", variant: "success"}));
            });
        // console.log(data);
    };

    useEffect(() => {
        createUploader(attachFile);
    }, []);

    const changeFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            /** 10 M **/
            if (e.target.files[0].size > 10240000 && e.target.files[0].type.split("/")[0] !== "image") {
                dispatch(showMessage({message: "message.File is too big!", variant: "error"}));
            } else {
                setLoading(true);
                uploadFiles(e.target.files, (status, files) => {
                    setLoading(false);
                    // console.log(files);

                    axios
                        .put(`${firmPrefix}/addAttachments`, {
                            workplanId: wp.id,
                            attachments: files,
                        })
                        .then(() => {
                            refresh().then(() => setLoading(false));
                            dispatch(showMessage({message: "message.File Uploaded", variant: "success"}));
                        });
                    // if(status) {
                    //     if(!!updateId)
                    //         axios.put(`${firmPrefix}/updateAttachment`, {
                    //             "attachmentId": updateId,
                    //             "attachment": file
                    //
                    //         }).then(res=> {
                    //             dispatch(Actions.getWP(wp.id, ()=> setLoading(false)))
                    //             dispatch(fuseActions.showMessage({message: "message.File updated", variant: "success"}))
                    //             setUpdateId(null)
                    //         })
                    //     else
                    //         attachFile(file)
                    // axios.put(`${firmPrefix}/addAttachments`, {
                    //     "workplanId": wp.id,
                    //     "attachments": [
                    //         file
                    //     ]
                    // }).then(res=> {
                    //     dispatch(Actions.getWP(wp.id, ()=> setLoading(false)))
                    //     dispatch(fuseActions.showMessage({message: "message.File Uploaded", variant: "success"}))
                    // })
                    // }
                });
            }
        }
    };

    return (
        <>
            {/*<CustomButton title={"Upload"} data-cy={"click-upload"} icon={"upload"}  />*/}
            <div>
                {/*{chooseUpload && (*/}
                {/*    <Alert className={"mb-12"} severity={"error"}>*/}
                {/*        {<Translate id={"wps.Big file should be bigger than 1mg"} />}*/}
                {/*    </Alert>*/}
                {/*)}*/}

                <div className={loading ? " loading" : " flex mb-12"}>
                    {/*{attachments.length > 0 && (*/}
                    {/*    <CustomButton title={t("see all")} icon={"pageview"} onClick={() => dispatch(turnOn("WPAttachmentsDialog"))} />*/}
                    {/*)}*/}
                    <CustomButton
                        title={t("upload")}
                        data-cy={"small-upload"}
                        icon={"add_photo_alternate"}
                        onClick={() => inputRef.current?.click()}
                    />
                    {/*<div className="webuploader-container mr-3">*/}
                    {/*    <div*/}
                    {/*        // @ts-ignore*/}
                    {/*        onClick={() => inputRef.current.click()}*/}
                    {/*        data-cy={"small-upload"}*/}
                    {/*        className={"webuploader-pick"}>*/}
                    {/*        <small>{<Translate id={"wps.Small file & Picture"} />}</small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <button id="picker" className="hover:shadow-2 font-bold p-16 bg-grey-lighter duration-200 rounded-12 ">
                        <span className="flex items-center">
                            <Icon className={"text-3xl text-gray-500 mr-4"}>upload_file</Icon>
                            <Translate id={"wps.Big file (No Image)"} />
                        </span>
                    </button>
                    {/*<div id="picker" data-cy={"big-upload"}>*/}
                    {/*    <CustomButton title={"Upload"} data-cy={"click-upload"} icon={"upload_file"} onClick={() => {}} />*/}
                    {/*    /!*<span>{<Translate id={"wps.Big file (No Image)"} />}</span>*!/*/}
                    {/*</div>*/}
                </div>

                <input data-cy={"file-input"} ref={inputRef} type="file" multiple className={"hidden"} onChange={changeFile} />
            </div>
        </>
    );
};

export default UploadButton;
