import {Checkbox, Icon, IconButton, TableContainer} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import type {TableViewProps} from "@ui";
import {EmptyView, TableView} from "@ui";
import MiniError500Page from "app/MiniError500Page";
import {addParam, clearParams, removeParam, replaceParams, toggleCheck, turnOff, turnOn} from "@fuseActions";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import {parse} from "qs";
import type {JSXElementConstructor, ReactNode} from "react";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import type {columnType, dataType, SearchType} from "types";
import {rowsPerPage} from "variables";
import {utils, writeFile} from "xlsx";
import {ErrorButton} from "../utilComponents";
import {createSearchParams} from "../utilities";
import SaveSearch from "./Filters/SaveSearch";
import ViewSearches from "./Filters/ViewSearches";
import MyPagination from "./MyPagination";
import SearchBox from "./SearchBox";
import SelectBar from "./SelectBar";
import {usePersonalized} from "../../hooks/usePersonalized";

export type BaseProps<TItem> = {
    data?: dataType<TItem>;
    /**
     * Should be unique
     * It uses to save the filters, if it's not unique there will be a bug in filtersv
     */
    name: string;

    getData(params?: SearchType): void;

    itemProps?: (arg0: TItem) => {[key: string]: any};

    filters?(params: ForwardProps<TItem>): ReactNode;

    extra?(arg0: {[key: string]: any}): ReactNode;

    loading?: boolean;
    translationKey?: string;
    error?: any;

    noPagination?: boolean;
};

export type MainProps<TItem> = {
    noSaveSearch?: boolean;
    noSearch?: boolean;
    noSidebar?: boolean;

    selectable?: boolean;
    disableSelect?: (arg0: TItem) => boolean;
    selectActinos?: ReactNode;

    to?(item: TItem): string | undefined;

    ItemDrawer?: JSXElementConstructor<any>;
    exportExcel?: (item: TItem[]) => string[][];
    exportTitle?: string[];
} & BaseProps<TItem>;

// export type MainProps<TItem> = {
//     noSaveSearch?: boolean;
//     noSearch?: boolean;
//     noSidebar?: boolean;
//     disableSelect?: (arg0: TItem) => boolean;
//     ItemDrawer?: JSXElementConstructor<any>;
//     to?(item: TItem): string | undefined;
//     selectable?: boolean;
//     exportExcel?: (item: TItem[]) => string[][];
//     exportTitle?: string[];
// } & (TableProps<TItem> | ListProps<TItem>);

type ListItemProps<T> = {
    item: T;
    index: number;
} & ForwardProps<T>;

export type TableProps<T> = {
    type: "table";
    columns: columnType<T>[];
} & Omit<TableViewProps<T>, "name" | "content" | "refresh">;

export type ListProps<T> = {
    type: "list";
    ViewItem: JSXElementConstructor<ListItemProps<T>>;
};

type ForwardProps<TItem> = {
    name: string;
    handleFormData: (type: string) => (event) => void;
    onChangeFilter: (type: string, value: any) => () => void;
    filterData: FilterDataType;
    clearSearch: () => void;
    getData: (params?: SearchType) => void;
    search: {[p: string]: string | number};
    data?: dataType<TItem>;
    filterSearch: (filter: string, value: any) => (e: any) => void;
    listView: any;
};

export type FilterDataType = (params?: SearchType | string) => void;

const nonFilter = ["keyword", "size", "page", "sord", "sidx"];

export type ContentWrapperProps<T> = MainProps<T> & (TableProps<T> | ListProps<T>);

const ContentWrapper = <TItem extends {id: string}>(props: MainProps<TItem> & (TableProps<TItem> | ListProps<TItem>)) => {
    const {
        loading,
        error,
        type,
        name,
        itemProps,
        ItemDrawer,
        data,
        exportExcel,
        selectActinos,
        extra,
        translationKey = "_",
        disableSelect,
        exportTitle,
        filters,
        noPagination,
        noSaveSearch,
        noSearch,
        noSidebar,
        selectable,
        to,
        getData,
    } = props;

    // region hooks and states
    const dispatch = useDispatch() as (action: any) => Promise<unknown>;
    const history = useHistory();
    const location = useLocation();
    const {addOrUpdateFilters, getFilter, getSetting, deleteFilter} = usePersonalized();

    // @ts-ignore
    const search: {[key: string]: string | number} = useSelector(({fuse}) => fuse.search);
    const orderBy = search?.sidx as string | undefined;
    const direction = search?.sord as "asc" | "desc" | undefined;
    // @ts-ignore
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    // @ts-ignore
    const checked = useSelector(({fuse}) => fuse.checked);

    const [selectedId, setSelectedId] = useState<string>();
    const [sidebar, setSidebar] = useState(false);

    const [size, setSize] = useState<number>();
    // endregion
    // const width = window.innerWidth;

    // const sizeFromStorage: number = localStorage.getItem("rowPerPage") ? _.toNumber(localStorage.getItem("rowPerPage")) : rowsPerPage;
    // const sizeFromStorage: number = settings?.rowPerPage || rowsPerPage;
    // @ts-ignore
    const isClickable = (!!itemProps && typeof itemProps({id: ""})?.onClick === "function") || !!ItemDrawer || !!to;
    const page: number = data && "page" in data && data.page > 0 ? _.toNumber(data.page) : search.page ? _.toNumber(search.page) : 0;
    const content = data?.content;
    // @ts-ignore
    const openSave = dialogs["SaveSearch_" + name];
    const drawer = name + "Drawer";
    const hasFilter =
        Object.keys(search).findIndex((key) => {
            return !nonFilter.includes(key);
        }) > -1;

    function replaceAndGet(t: {[p: string]: unknown}) {
        const x = {...t};
        if (noPagination) {
            x.size = 10000;
            delete t.size;
        }
        dispatch(replaceParams(t));
        getData(x);
        history.replace({
            pathname: location.pathname,
            search: `?${createSearchParams(t)}`,
        });
    }

    const init = async () => {
        const parsedQueryString = parse(location.search, {ignoreQueryPrefix: true});
        const x = await getFilter(name);
        const sizeFromStorage = await getSetting("rowPerPage");
        const sizeT: number =
            data && "size" in data && data.size > 0 ? _.toNumber(data.size) : search.size ? _.toNumber(search.size) : sizeFromStorage || rowsPerPage;

        setSize(sizeT);

        if (x) {
            const t = {...x};
            delete t.key;
            delete t.userId;
            t.size = sizeT;
            setSidebar(
                Object.keys({...t, ...parsedQueryString}).findIndex((key) => {
                    return !nonFilter.includes(key);
                }) > -1
            );
            replaceAndGet({...t, ...parsedQueryString});
        } else {
            setSidebar(
                Object.keys(parsedQueryString).findIndex((key) => {
                    return !nonFilter.includes(key);
                }) > -1
            );
            replaceAndGet({size: sizeT, ...parsedQueryString});
        }
    };

    useEffect(() => {
        init();
        return () => {
            dispatch(clearParams());
        };
    }, []);

    const getSetLocal = (search: SearchType): void => {
        const cleanedForSearch = {...search};
        Object.keys(search).forEach((k) => search[k]?.toString().trim() === "" && delete search[k]);
        Object.keys(cleanedForSearch).forEach((k) => cleanedForSearch[k]?.toString().trim() === "" && delete cleanedForSearch[k]);

        history.replace({pathname: location.pathname, search: `?${createSearchParams(cleanedForSearch)}`});
        getData(cleanedForSearch);

        const t = {...search};
        delete t.content;
        delete t.size;
        delete t.page;
        delete t.keyword;
        addOrUpdateFilters(name, t);
    };

    const filterData: FilterDataType = (params) => {
        const remove = typeof params !== "object";

        if (params && !remove) {
            dispatch(addParam({page: 1, ...params})).then(() => getSetLocal({...search, page: 1, ...params}));
        } else if (params && remove) {
            dispatch(removeParam(params)).then(() =>
                dispatch(addParam({page: 1})).then(() => {
                    const newParams = {...search};
                    delete newParams[params];
                    getSetLocal(newParams);
                })
            );
        }
    };

    const clearSearch = () => {
        deleteFilter(name);
        dispatch(replaceParams({size, page: 1})).then(() => getSetLocal({size, page: 1}));
    };

    const filterSearch = (filter: string, value: any) => (e: any) => {
        if (e) e.preventDefault();
        if (value !== search[filter]) {
            filterData({[filter]: value});
        } else {
            filterData(filter);
        }
    };

    const handleRequestSort = (property: string) => {
        let o = "asc";

        if (orderBy === property) {
            if (direction === "asc") {
                o = "desc";
            }
        }

        filterData({page: 1, sidx: property, sord: o});
    };

    const onClick = (item: TItem) => {
        const isClickable = (!!itemProps && typeof itemProps(item).onClick === "function") || !!ItemDrawer || to;

        // console.log(isClickable);
        // @ts-ignore
        if (ItemDrawer) {
            if (selectedId === item.id) {
                dispatch(turnOff(name + "Drawer"));
                setSelectedId(undefined);
            } else {
                setSelectedId(item.id);
                dispatch(turnOn(name + "Drawer"));
            }
        } else if (isClickable) {
            if (to) {
                history.push(to(item) || "");
            } else itemProps?.(item)?.onClick?.();
        }
    };

    const onChangeFilter = (type: string, value: any) => () => {
        if (search[type] === value) filterData(type);
        else filterData({[type]: value});
    };

    const handleFormData = (type: string) => (event) => {
        const value = event.target.value;
        if (value === search[type]) {
            filterData(type);
        } else {
            filterData({[type]: value});
        }
    };

    const exportDataToExcel = () => {
        if (!exportExcel || !exportTitle || !data || data.length === 0) return;
        const d = exportExcel(data.content);
        // console.log(data);
        // d.unshift(exportTitle);
        // const data = [[1,2,3],[4,5,6]];
        /* convert state to workbook */
        // @ts-ignore

        const ws = utils.aoa_to_sheet(d, {origin: 1});
        const wb = utils.book_new();

        ws["!cols"] = [{wch: 4}, ...exportTitle.map(() => ({wch: 15}))];
        utils.sheet_add_aoa(ws, [exportTitle], {
            origin: 0,
        });
        utils.book_append_sheet(wb, ws, "SheetJS");

        /* generate XLSX file and send to client */
        writeFile(wb, `${name}-${moment(new Date()).format("L")}.xlsx`);
    };

    const itemP = (i: TItem) => {
        if (itemProps) return itemProps(i);
        else {
            return {};
        }
    };

    const forwardProps: ForwardProps<TItem> = {
        name,
        handleFormData,
        onChangeFilter,
        filterData,
        clearSearch,
        getData,
        search,
        data,
        filterSearch,
        listView: dialogs["listView"],
    };

    const insideContent = () => {
        switch (type) {
            case "list": {
                const ViewItem = props.ViewItem;
                return (
                    <List dense classes={{root: "py-0"}} data-cy={"list-content"}>
                        {!!content && content.length > 0 ? (
                            content.map((_, i) => {
                                // @ts-ignore
                                const isChecked = checked[name] ? checked[name].includes(_.id) : false;

                                return (
                                    <ListItem
                                        component={"li"}
                                        divider
                                        classes={{root: "flex-wrap md:flex-nowrap "}}
                                        key={i}
                                        selected={selectedId ? selectedId === _.id : false}
                                        // @ts-ignore
                                        button={isClickable}
                                        // divider={!listView}
                                        {...itemP(_)}
                                        className={clsx(isClickable && "cursor-pointer", itemP(_)?.className, "hover:bg-grey-lightest")}
                                        data-cy={`item-${name}`}
                                        onClick={() => onClick(_)}>
                                        <Typography className={"-ml-16 pl-4 w-40 text-grey-dark"}>
                                            {size && page ? size * (page - 1) + i + 1 : i + 1}
                                        </Typography>
                                        {selectable && (
                                            <Checkbox
                                                disabled={!!(typeof disableSelect === "function" && disableSelect(_))}
                                                // @ts-ignore
                                                classes={{root: dialogs["listView"] ? "p-2 mr-8" : "mr-8"}}
                                                checked={isChecked}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={() => dispatch(toggleCheck(_.id, name))}
                                            />
                                        )}
                                        <ViewItem {...forwardProps} name={name} index={i} key={i} item={_} />
                                    </ListItem>
                                );
                            })
                        ) : (
                            <EmptyView
                                loading={loading || (!data && !error)}
                                label={
                                    hasFilter ? (
                                        <Translate>
                                            {({translate}: any) => (
                                                <div>
                                                    {translate("_.No result for your search")}{" "}
                                                    <ErrorButton
                                                        startIcon={<Icon>close</Icon>}
                                                        onClick={clearSearch}
                                                        className={"ml-12 "}
                                                        variant={"contained"}>
                                                        {translate("_.Clear search")}
                                                    </ErrorButton>
                                                </div>
                                            )}
                                        </Translate>
                                    ) : undefined
                                }
                            />
                        )}
                    </List>
                );
            }
            case "table":
                return (
                    <TableContainer>
                        <TableView<TItem>
                            columns={props.columns}
                            handleRequestSort={handleRequestSort}
                            content={content}
                            direction={direction}
                            orderBy={orderBy}
                            disableSelect={disableSelect}
                            selectable={selectable}
                            selectedItemId={selectedId}
                            itemProps={itemProps}
                            size={size}
                            emptyProps={{
                                loading: loading || (!data && !error),
                                label: hasFilter ? (
                                    <Translate>
                                        {({translate}: any) => (
                                            <div>
                                                {translate("_.No result for your search")}{" "}
                                                <ErrorButton
                                                    startIcon={<Icon>close</Icon>}
                                                    onClick={clearSearch}
                                                    className={"ml-12 "}
                                                    variant={"contained"}>
                                                    {translate("_.Clear search")}
                                                </ErrorButton>
                                            </div>
                                        )}
                                    </Translate>
                                ) : undefined,
                            }}
                            to={to}
                            refresh={() => getData()}
                            {...forwardProps}
                        />
                    </TableContainer>
                );
            default:
                return null;
        }
    };

    return (
        <div className={"w-full mb-16"} data-cy={`${name}Wrapper`}>
            {/* region Sidebar button & searchbox & view searches */}
            {!noSidebar && (
                <div className={"px-12 pb-12  md:flex w-full items-center"}>
                    <div className="flex">
                        {!!filters && (
                            <Button
                                data-cy={"toggle-filter"}
                                disableRipple
                                variant={"contained"}
                                className={"bg-white mr-12"}
                                onClick={() => setSidebar(!sidebar)}
                                startIcon={<Icon>{sidebar ? "arrow_back" : "filter_alt"}</Icon>}>
                                {sidebar ? <Translate id={"_.Close Filters"} /> : <Translate id={"_.open Filters"} />}
                            </Button>
                        )}
                        {!noSearch && <SearchBox filterData={filterData} />}
                    </div>
                    <ViewSearches
                        openSidebar={() => setSidebar(true)}
                        translationKey={translationKey}
                        noSaveSearch={noSaveSearch}
                        filterData={filterData}
                        clearSearch={clearSearch}
                        name={name}
                    />
                </div>
            )}
            {/* endregion */}
            <div className="flex w-full">
                {/* region Sidebar */}

                {!noSidebar && (
                    <>
                        {/* region mobile sidebar btn */}

                        {sidebar && (
                            <div className={"md:hidden"}>
                                <IconButton
                                    onClick={() => setSidebar(false)}
                                    className={"absolute top-0 right-0 z-999 bg-white hover:bg-red-dark hover:text-white"}>
                                    <Icon>close</Icon>
                                </IconButton>
                                <div onClick={() => setSidebar(false)} className={"bg-black opacity-50 absolute top-0 z-99 w-full h-full"} />
                            </div>
                        )}
                        {/* endregion */}

                        <div
                            className={
                                sidebar ? "md:min-w-288 md:relative p-24 md:p-0 left-0 w-full md:w-auto top-0 absolute z-99 flex-shrink-0" : ""
                            }>
                            {sidebar && (
                                <Card className="bg-white h-full pb-12  md:rounded-8 md:ml-12 overflow-visible">
                                    {!noSaveSearch && <SaveSearch {...forwardProps} />}

                                    {!openSave && <div className={"pt-12"}>{typeof filters === "function" && filters(forwardProps)}</div>}

                                    {exportExcel && (
                                        <div className={"mb-24"}>
                                            <Divider className={"my-12"} />
                                            <div className="px-12">
                                                <Button
                                                    disabled={!(content && content.length > 0)}
                                                    variant={"outlined"}
                                                    startIcon={<Icon>download</Icon>}
                                                    onClick={exportDataToExcel}>
                                                    <Translate id={"_.Export shown data"} />
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </Card>
                            )}
                        </div>
                    </>
                )}
                {/* endregion */}
                <Card className="relative mx-12 flex flex-1 flex-col">
                    {selectable && <SelectBar name={name} actions={selectActinos} />}
                    <div className="flex-grow relative">
                        {extra && extra(forwardProps)}

                        {/* region content */}
                        <div className="flex h-full relative">
                            <div
                                className={clsx(
                                    Boolean(ItemDrawer)
                                        ? // @ts-ignore
                                          !!dialogs[drawer] && selectedId && content?.find((_) => _.id === selectedId)
                                            ? "md:w-1/2 w-full"
                                            : "w-full"
                                        : "w-full",
                                    "flex flex-col  h-fulsl"
                                )}>
                                {error ? (
                                    <MiniError500Page
                                        noButtons={noSearch}
                                        error={error}
                                        refresh={getData}
                                        clearSearch={hasFilter ? clearSearch : undefined}
                                    />
                                ) : (
                                    insideContent()
                                )}

                                {data && !!content && !noPagination && data.totalPages > 0 && (
                                    <div className="flex items-center justify-end" data-cy={"pagination-wrapper"}>
                                        <MyPagination
                                            page={page}
                                            totalPages={data.totalPages}
                                            totalElements={data.totalElements}
                                            filterData={filterData}
                                        />
                                    </div>
                                )}
                            </div>

                            {!!ItemDrawer &&
                                // @ts-ignore
                                !!dialogs[drawer] &&
                                selectedId &&
                                content?.find((_) => _.id === selectedId) && (
                                    <div
                                        style={{maxHeight: "calc(100vh - 260px)"}}
                                        className={"w-full  absolute overflow-y-scroll md:relative bg-white top-0 right-0 md:w-1/2 border-l-1"}>
                                        <ItemDrawer
                                            {...forwardProps}
                                            key={selectedId}
                                            refreshSelected={onClick}
                                            drawer={drawer}
                                            item={content?.find((_) => _.id === selectedId)}
                                            closeButton={
                                                <IconButton
                                                    aria-label="Settings"
                                                    onClick={() => {
                                                        setSelectedId(undefined);
                                                        dispatch(turnOff(drawer));
                                                    }}>
                                                    <Icon>close</Icon>
                                                </IconButton>
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                        {/* endregion */}
                    </div>
                </Card>
            </div>
        </div>
    );
};
export default ContentWrapper;
