import {DialogContent, Grid, TextField} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import EditorInput from "utils/EditorInput/EditorInput";
import {ContactSelector, EnumFormControl} from "../../marketingTools";

const Step0 = ({formData, handleFormData}) => {
    return (
        <div>
            <DialogContent className={"overflow-visible py-0"}>
                <div className="flex mb-16">
                    <div className="flex-1">
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Translate>
                                    {({translate}) => (
                                        <TextField
                                            required
                                            inputProps={{maxLength: 60}}
                                            fullWidth
                                            placeholder={translate("market.No more than 60 character")}
                                            label={translate("market.deal name")}
                                            value={formData.name}
                                            onChange={handleFormData("name")}
                                        />
                                    )}
                                </Translate>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <EnumFormControl
                                    required
                                    type={"SalesDealStage"}
                                    name={"stage"}
                                    formData={formData}
                                    handleFormData={handleFormData}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <ContactSelector required formData={formData} handleFormData={handleFormData} />
                            </Grid>
                            {/*<Grid item md={6}xs=12>*/}
                            {/*    <EnumFormControl type={"SalesDealType"} name={"type"} formData={formData} handleFormData={handleFormData}/>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </div>
                </div>

                <EditorInput
                    name={"description"}
                    disableMention
                    rows={"88"}
                    value={formData.description}
                    onChange={handleFormData("description")}
                />

                {/*<Grid container spacing={3} className="mt-8">*/}
                {/*    <Grid item sm={6}><CompanySelector formData={formData} handleFormData={handleFormData} /></Grid>*/}
                {/*</Grid>*/}
            </DialogContent>
        </div>
    );
};

export default Step0;
