import {useMemo} from "react";
import useQueryStore from "./useQueryStore";
import {useCategoryStore} from "./useCategoryStore";

function useStoreUtils() {
    const _hasHydrated = useQueryStore((state) => state._hasHydrated);

    const _query = useQueryStore((state) => state.query);

    const _actions = useQueryStore((state) => state.actions);

    const _categorySelected = useCategoryStore((state) => state.categorySelected);

    const _categoryActions = useCategoryStore((state) => state.categoryActions);

    const getHasHydrated = useMemo(() => _hasHydrated, [_hasHydrated]);

    const getQuery = useMemo(() => _query, [_query]);

    return useMemo(
        () => ({
            hasHydrated: getHasHydrated,
            query: getQuery,
            actions: _actions,
            categorySelected: _categorySelected,
            categoryActions: _categoryActions,
        }),
        [getHasHydrated, getQuery, _actions, _categorySelected, _categoryActions]
    );
}

export default useStoreUtils;
