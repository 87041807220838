import {clearAll} from "./checked.actions";

export const REMOVE_PARAM   = "[SEARCH] REMOVE_PARAM";
export const REPLACE_PARAMS = "[SEARCH] REPLACE_PARAMS";
export const CLEAR_PARAMS   = "[SEARCH] CLEAR_PARAMS";
export const ADD_PARAM      = "[SEARCH] ADD_PARAM";

export const removeParam = (filter) => {
    return (dispatch) => {
        dispatch(clearAll());
        dispatch({
            type: REMOVE_PARAM,
            filter
        });
        return Promise.resolve(true);
    };
};

export const addParam =  (params) => {
    return (dispatch) => {
        dispatch(clearAll());
        dispatch({
            type: ADD_PARAM,
            params
        });
        return Promise.resolve(true);
    };
};


export const replaceParams = (params) => {
    return (dispatch) => {
        dispatch(clearAll());
        dispatch({
            type: REPLACE_PARAMS,
            params
        });
        return Promise.resolve(true);
    };
};

export const clearParams = () => {
    return (dispatch) => {
        dispatch(clearAll());
        dispatch({
            type: CLEAR_PARAMS
        });
        return Promise.resolve(true);
    };
};
