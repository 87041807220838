import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import {useMemoizedFn} from "ahooks";
import * as fuseActions from "app/store/actions";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useProcessStore} from "../../state/useProcessStore";

function NewDefinitionSuspendDialog(props) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.definitionSuspend
    });

    const selectedDefinition = useProcessStore((state) => state.selectedDefinition);

    const onChangeSelectedDefinition = useProcessStore((state) => state.onChangeSelectedDefinition);

    useEffect(() => {
        return () => {
            onChangeSelectedDefinition(null);
        };
    }, []);

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSubmit = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        const params = {
            id: selectedDefinition.id,
            suspended: !selectedDefinition.suspended
        };
        axios
        .put(`${flowPrefix}/processDefinitionSetSuspended`, params)
        .then((res) => {
            if (res.status === 200) {
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: selectedDefinition.suspended
                            ? "message.Definition started!"
                            : "message.Definition suspended!"
                    })
                );
                finshParams?.onFinish();
                onTurnOffDialog();
            }
        })
        .finally(() => {
            setLoading(false);
        });
    });

    const canSubmit = useMemoizedFn(() => {
        return loading === true;
    });

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <DialogTitle id="PauseDefinitionTipsDialog-dialog-title">
                    <Translate id="process.Start or pause this definition" />
                    <Chip
                        size="small"
                        className={classNames(
                            `font-bold`,
                            selectedDefinition?.suspended === false
                                ? "bg-green text-white ml-3"
                                : "bg-red text-white ml-3"
                        )}
                        label={<Translate id={`process.${selectedDefinition?.suspended ? "suspended" : "running"}`} />}
                    />
                </DialogTitle>
                <DialogContent>
                    <div className="flex flex-col justify-center items-center my-5">
                        <Typography variant="button" className={"text-center font-black"} display="block">
                            {selectedDefinition?.suspended ? (
                                <Translate id={"process.Sure you want to start the current process definition?"} />
                            ) : (
                                <Translate id={"process.Sure you want to suspend the current process definition?"} />
                            )}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={canSubmit()}
                        onClick={handleSubmit}
                        color="secondary"

                    >
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewDefinitionSuspendDialog;
