import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    selectedIndex: 0,
};


const tab = function (state=initState,action) {
    switch (action.type) {
        case Actions.SET_TAB_INDEX: {
            let tobj = cloneDeep(state);
            tobj.selectedIndex = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedIndex: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default tab;
