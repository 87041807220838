import {setCache, showMessage, turnOff, turnOn} from "@fuseActions";
import {Chip, Icon, IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {SingleHeader} from "@page";
import {DeleteWrapper, MyChip, MyTooltip, UserView} from "@ui";
import FaveButton from "components/layout/fav/FaveButton";
import {useConfirm} from "material-ui-confirm";
import axios, {firmPrefix} from "myaxios";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {confirmDefaultOptions} from "utils/variables";
import useWPS from "../useWPS";
import {useWorkPlan} from "./useWorkPlan";

const WPHeader = ({isDialog}: {isDialog: boolean}) => {
    const {canEdit, wp, refresh} = useWorkPlan();
    const {getData} = useWPS();
    const history = useHistory();
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const wpDetailDialog = useSelector(({fuse}) => fuse.dialogs["wpDetail"]);

    const quitWP = () => {
        const data = {workplanIds: [wp?.id]};
        confirm({
            ...confirmDefaultOptions,
            title: <Translate id={"wps.Quit"} />,
            description: (
                <Typography variant={"h6"}>
                    <Translate id={"wps.Are you sure you want to quit this workplan?"} />
                </Typography>
            ),
        }).then(() => {
            axios.put(`${firmPrefix}/exitWorkplan`, data).then(() => {
                dispatch(showMessage({message: "message.Quit successfully", variant: "success"}));
                if (wpDetailDialog) {
                    dispatch(turnOff("wpDetail"));
                    getData();
                } else {
                    history.push("/wps/list");
                }
            });
        });
    };

    const onEditWP = () => {
        dispatch(setCache("selectedWP", wp));
        dispatch(turnOff("wpDetail"));
        dispatch(turnOn("wp"));
    };

    return (
        wp && (
            <>
                <SingleHeader
                    stringTitle={wp.title}
                    isDialog={isDialog}
                    breads={[<Translate id={"wps.workplans"} />, <Translate id={"wps.workplan detail"} />]}
                    title={
                        <>
                            <div className="flex">
                                {wp.pin ? <img className="w-56 h-56 mt-2 mr-2" alt="pin" data-cy={"pinned-icon"} src="assets/images/pin.png" /> : ""}
                                <div>
                                    {wp.title} <UserView dense user={wp.owner?.user} />
                                </div>
                            </div>
                        </>
                    }
                    actions={
                        <>
                            {wp.isField && <Chip size={"small"} label={<Translate id={"wps.In Field"} />} />}
                            <MyChip
                                size={"small"}
                                color={wp.status === "ACTIVE" ? "green" : "red"}
                                label={<Translate id={"wps." + wp.status} />}
                                className={`ml-2 bg-${wp.status}`}
                            />
                            <FaveButton colored sourceId={wp.id} title={wp.title} type={"workplan"} />
                            {canEdit ? (
                                <>
                                    {wp.status === "ACTIVE" && (
                                        <DeleteWrapper
                                            item={wp.title}
                                            deleteReq={() => axios.delete(`${firmPrefix}/workplan/${wp.id}`)}
                                            callback={() => {
                                                dispatch(turnOff("wpDetail"));
                                                refresh();
                                                getData();
                                            }}
                                            children={(onClick) => (
                                                <IconButton data-cy={"delete-wp"} onClick={onClick}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            )}
                                        />
                                    )}
                                    <IconButton data-cy={"edit-wp"} onClick={onEditWP}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                </>
                            ) : (
                                <MyTooltip title={<Translate id="wps.Quit" />}>
                                    <IconButton data-cy={"quit-wp"} className="no-drag" onClick={quitWP}>
                                        <Icon>directions_run</Icon>
                                    </IconButton>
                                </MyTooltip>
                            )}

                            {/*{canEdit && (*/}
                            {/*    <IconButton data-cy={"pin-wp"} onClick={(e) => setPopover(e.currentTarget)}>*/}
                            {/*        <Icon>flash_on</Icon>*/}
                            {/*    </IconButton>*/}
                            {/*)}*/}

                            {/*<MyTooltip title={<Translate id="_.Send it to a friend" />}>*/}
                            {/*    <IconButton*/}
                            {/*        data-cy={"share-wp"}*/}
                            {/*        className="no-drag"*/}
                            {/*        onClick={() => {*/}
                            {/*            if (wpDetailDialog) dispatch(turnOff("wpDetail"));*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <Icon>share</Icon>*/}
                            {/*    </IconButton>*/}
                            {/*</MyTooltip>*/}

                            {wpDetailDialog && (
                                <MyTooltip title={<Translate id="_.open in new tab" />}>
                                    <IconButton
                                        data-cy={"open-wp"}
                                        className="no-drag"
                                        component={Link}
                                        to={`/workplan/${wp.id}`}
                                        onClick={() => {
                                            dispatch(turnOff("wpDetail"));
                                        }}>
                                        <Icon>open_in_new</Icon>
                                    </IconButton>
                                </MyTooltip>
                            )}
                        </>
                    }
                />

                {/*<div className={"flex-1"}>*/}
                {/*    <div className="flex items-center">*/}
                {/*        {(!wpDetailDialog && !isElectron()) && <div className={"hidden md:block"}><IconButton component={Link}  to={`/wps`} className={"mr-8"} size={"small"}><Icon className="text-4xl">arrow_back</Icon></IconButton></div>}*/}
                {/*        <div>*/}
                {/*            <strong className={"text-32"}>{wp.title}</strong>*/}
                {/*            {!!wp.addressJson && <Typography className={"nowrap w-full opacity/75"}>{*/}
                {/*                wp.addressJson.district + wp.addressJson.city+ wp.addressJson.province*/}
                {/*            }</Typography>}*/}
                {/*            <span className="text-gray-lighter text-xs flex items-center">*/}
                {/*                <MyAvatar user={wp.owner?.user} className="mr-2" size={16}/><strong className="mr-1"> {nameText(wp.owner.user)}</strong>@{wp.owner.user.username}*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="flex items-end flex-col">*/}
                {/*    <div className={"flex items-center"}>*/}
                {/*        /!*{wp.isField && <Chip size={"small"} label={<Translate id={"wps.In Field"}/>}/>}*!/*/}
                {/*        /!*<Chip size={"small"} label={<Translate id={"wps." + wp.status}/>} className={`ml-2 bg-${wp.status}`}/>*!/*/}
                {/*        /!*<FaveButton*!/*/}
                {/*        /!*    sourceId={wp.id}*!/*/}
                {/*        /!*    title={wp.title}*!/*/}
                {/*        /!*    className={""}*!/*/}
                {/*        /!*    type={"wp"}/>*!/*/}

                {/*        {canEdit ?*/}
                {/*            <MyTooltip title={<Translate id="_.more"/>}>*/}
                {/*                <IconButton  className="no-drag" onClick={(e)=>setMorePopover(e.currentTarget)}>*/}
                {/*                    <Icon>more_vert</Icon>*/}
                {/*                </IconButton>*/}
                {/*            </MyTooltip>*/}
                {/*            :*/}
                {/*            <MyTooltip title={<Translate id="wps.Quit"/>}>*/}
                {/*                <IconButton  className="no-drag" onClick={quitWP}>*/}
                {/*                    <Icon>directions_run</Icon>*/}
                {/*                </IconButton>*/}
                {/*            </MyTooltip>*/}
                {/*        }*/}

                {/*        {isElectron() &&*/}
                {/*        <IconButton  className="no-drag" onClick={()=>window.remote.getCurrentWindow().close()} >*/}
                {/*            <Icon>close</Icon>*/}
                {/*        </IconButton>}*/}

                {/*    <Popover*/}
                {/*        open={Boolean(popover)}*/}
                {/*        anchorEl={popover}*/}
                {/*        onClose={() => setPopover(null)}*/}
                {/*        anchorOrigin={{*/}
                {/*            vertical: "center",*/}
                {/*            horizontal: "right",*/}
                {/*        }}*/}
                {/*        transformOrigin={{*/}
                {/*            vertical: "center",*/}
                {/*            horizontal: "right",*/}
                {/*        }}*/}
                {/*        classes={{paper: "min-w-128"}}*/}
                {/*    >*/}
                {/*        <MenuItem*/}
                {/*            data-cy={"pin3"}*/}
                {/*            className={"no-drag"}*/}
                {/*            onClick={(e) => {*/}
                {/*                pinWP(3);*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id={"wps.3 days"} />} />*/}
                {/*        </MenuItem>*/}
                {/*        <MenuItem*/}
                {/*            className={"no-drag"}*/}
                {/*            onClick={(e) => {*/}
                {/*                pinWP(7);*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id={"wps.7 days"} />} />*/}
                {/*        </MenuItem>*/}
                {/*        <MenuItem*/}
                {/*            className={"no-drag"}*/}
                {/*            onClick={(e) => {*/}
                {/*                pinWP(15);*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id={"wps.15 days"} />} />*/}
                {/*        </MenuItem>*/}
                {/*        <MenuItem*/}
                {/*            className={"no-drag"}*/}
                {/*            onClick={(e) => {*/}
                {/*                pinWP(30);*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText className="pl-0" primary={<Translate id={"wps.30 days"} />} />*/}
                {/*        </MenuItem>*/}
                {/*    </Popover>*/}
            </>
        )
    );
};

export default WPHeader;
