import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    decisionsResponse: null,
    selectedDecision: null,
};

const decision_list = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_DECISIONS_LIST: {
            const obj = cloneDeep(state);
            obj.decisionsResponse = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     decisionsResponse: action.payload
            // }
        }
        case Actions.SELECT_A_DECISION: {
            const obj = cloneDeep(state);
            obj.selectedDecision = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     selectedDecision: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default decision_list;
