import {Icon, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {memo} from "react";
import * as quickPanelActions from "app/notifications/store/actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    unreadBadge: {
        position: "absolute",
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: "0 5px",
        fontSize: 11,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.35)",
        zIndex: 10,
    },
}));

function QuickPanelToggleButton(props) {
    const { children =  <Icon>notifications</Icon>} = props;
    const dispatch = useDispatch();
    const ntfNumber = useSelector(({notification}) => notification.ntfNumber, shallowEqual);
    const classes = useStyles(props);

    return (
        <IconButton
            data-tut={"quikly-open-notification"}
            className={"no-drag w-52 h-44"}
            onClick={(ev) => dispatch(quickPanelActions.toggleQuickPanel(true))}>
            {ntfNumber > 0 && <div className={classes.unreadBadge}>{parseInt(ntfNumber)}</div>}
            {children}
        </IconButton>
    );
}

// QuickPanelToggleButton.defaultProps = {
//     children: <Icon>notifications</Icon>,
// };

export default memo(QuickPanelToggleButton);
