import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {Translate} from "react-localize-redux";
import {ErrorButton} from "utils/utilComponents.js";

type Props = {
    error?: {message: string};
    code?: string;
    refresh(): void;
    noButtons?: boolean;
    clearSearch?: () => void;
    loading?: boolean;
};
const MiniError500Page = ({error, code, refresh, clearSearch, noButtons, loading}: Props) => {
    // constructor(props) {
    //     super(props);
    //     addTranslation(transJson);
    // }

    return (
        <div className={"flex flex-col flex-1 items-center justify-center p-16 errorPage"}>
            <div className="max-w-512 text-center">
                <Typography variant="h1" color="inherit" className="font-medium mb-16 mt-32">
                    {code || <Icon style={{fontSize: 64}}>error</Icon>}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" className="mb-32">
                    {error?.message}
                </Typography>
                {!noButtons && (
                    <>
                        <Button
                            startIcon={<Icon className={loading ? "animate-spin" : ""}>refresh</Icon>}
                            variant={"outlined"}
                            onClick={() => refresh()}
                            color="secondary"
                        >
                            <Translate id={"_.refresh"} />
                        </Button>
                        {clearSearch && (
                            <ErrorButton startIcon={<Icon>close</Icon>} onClick={clearSearch} className={"ml-12 "} variant={"contained"}>
                                <Translate id={"_.Clear search"} />
                            </ErrorButton>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default MiniError500Page;
