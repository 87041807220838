import {IconButton} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import {SubmitButton} from "@ui";
import _ from "lodash";
import type {FC} from "react";
import {memo, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {ErrorButton} from "../../../../../utils/utilComponents";
import DGColForm from "../../header/DGColForm";
import {useDGDef} from "../../hooks/hooks";
import type {DGColType} from "../../types";

type Props = {
    col: DGColType;
};

const EditTH: FC<Props> = (props) => {
    const {t} = useTranslation(["datagrid", "_"]);
    const {col} = props;
    const {onChangeCol, onDeleteCol, cols} = useDGDef();
    const [el, setEl] = useState<HTMLButtonElement | null>(null);
    const [tempCol, setTempCol] = useState<DGColType>(col);
    const [labelError, setLabelError] = useState<string>();

    useEffect(() => {
        setTempCol(col);
    }, [col]);

    const uniqueLabel = (label: string | undefined) => {
        return cols.some((x) => !x.deleted && x.id !== tempCol.id && x.label === label);
    };

    function onSave() {
        if (uniqueLabel(tempCol.label)) {
            setLabelError(t("uniqueLabel"));
            return;
        }
        setLabelError(undefined);

        if (tempCol.viewType === "singleOption") {
            const t = {...tempCol};
            t.options = tempCol.options?.filter((c) => c !== "");
            onChangeCol(t);
        } else {
            onChangeCol(tempCol);
        }

        setEl(null);
    }

    return (
        <>
            <Popover open={Boolean(el)} anchorEl={el} onClose={() => setEl(null)}>
                {/* region width */}

                <div className="p-12 text-gray-500">
                    <Trans
                        ns={"datagrid"}
                        i18nKey="columnMinWidthPx"
                        values={{
                            width: col.minWidth,
                        }}
                        components={{
                            s: <span className="px-4 py-1 bg-gray-100 text-gray-400 ml-4 rounded-4" />,
                        }}
                    />
                </div>

                {/* endregion */}
                <DGColForm errors={{label: labelError}} tempCol={tempCol} setTempCol={setTempCol} />
                {/* region actions delete save */}

                <div className={"px-12 py-12 mb-4 flex justify-end"}>
                    <ErrorButton startIcon={<Icon children={"delete"} />} size={"small"} variant={"contained"} onClick={() => onDeleteCol(col.id)}>
                        {t("delete")}
                    </ErrorButton>

                    <SubmitButton disabled={tempCol.label?.trim() === ""} success size={"small"} className={"ml-8"} onClick={onSave}>
                        {t("save")}
                    </SubmitButton>
                </div>
                {/* endregion */}
            </Popover>

            <IconButton
                size={"small"}
                className={"align-middle"}
                onClick={(event) => {
                    event.stopPropagation();
                    setEl(event.currentTarget);
                }}>
                <Icon fontSize={"small"} children={"tune"} />
            </IconButton>
        </>
    );
};

export default memo(EditTH, (p, n) => _.isEqual(p.col, n.col));
