import {useEffect, useMemo} from "react";
import {Translate} from "react-localize-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import DatePickerWrapper from "../../DatePickerWrapper/DatePickerWrapper";
import {useMemoizedFn, useMount, useSetState} from "ahooks";
import PropTypes from "prop-types";
import moment from "moment";
import {Typography} from "@material-ui/core";

DateFromToQuery.propTypes = {
    /**
     * @description > 是否是单机模式；该模式下只使用该组件选择数据，不在该组件内进行 网络请求的参数组装
     */
    standaloneMode: PropTypes.bool,
    /**
     * @description > 网络请求的参数
     */
    query: PropTypes.object,
    /**
     * @description > 修改网络请求的参数的回调函数
     */
    onChangeQuery: PropTypes.func,

    mutate: PropTypes.func,

    dateStyles: PropTypes.string,

    title: PropTypes.any,
};

DateFromToQuery.defaultProps = {
    standaloneMode: false,
};

const initState = {
    dateFrom: null,
    dateTo: null,
};

function DateFromToQuery(props) {
    const {query, onChangeQuery, mutate, dateStyles, standaloneMode, title} = props;
    const [state, setState] = useSetState(initState);

    useMount(() => {
        if (!!query.from) {
            setState({dateFrom: new Date(query["dateFrom"])});
            setState({dateTo: new Date(query["dateTo"])});
        } else {
            setState(initState);
        }
    });

    useEffect(() => {
        if (!query.dateFrom) {
            setState({dateFrom: null});
        } else {
            setState({dateFrom: new Date(query["dateFrom"])});
        }
        if (!query.dateTo) {
            setState({dateTo: null});
        } else {
            setState({dateTo: new Date(query["dateTo"])});
        }
        return () => {};
    }, [query?.dateFrom, query?.dateTo]);

    const onChangeFrom = useMemoizedFn((date) => {
        const formattedDate = new Date(moment(date).startOf("day"));
        const timeStampF = formattedDate.getTime();
        const timeStampT = state.dateTo?.getTime();
        setState({dateFrom: formattedDate});
        if (!standaloneMode && query?.dateTo) {
            let params = {
                ...query,
                dateFrom: timeStampF,
                dateTo: timeStampT,
            };
            onChangeQuery(params);
        }
    });

    const onChangeDateTo = useMemoizedFn((date) => {
        const formattedDate = new Date(moment(date).endOf("day"));
        const timeStamp = formattedDate.getTime();
        const timeStampF = state.dateFrom?.getTime();
        setState({dateTo: formattedDate});
        if (!standaloneMode) {
            let params = {
                ...query,
                dateFrom: timeStampF,
                dateTo: timeStamp,
            };
            onChangeQuery(params);
        }
    });

    const clearSearch = useMemoizedFn((e) => {
        e.stopPropagation();
        setState(initState);
        if (!standaloneMode) {
            let params = {
                ...query,
            };
            delete params.dateFrom;
            delete params.dateTo;
            onChangeQuery(params);
        }
    });

    const dateFrom = useMemo(() => {
        return state.dateFrom;
    }, [state.dateFrom]);

    const dateTo = useMemo(() => {
        return state.dateTo;
    }, [state.dateTo]);

    return (
        <div className={"flex flex-col w-full"}>
            {title && (
                <div className={"flex flex-row items-center"}>
                    <Typography>{title}</Typography>
                </div>
            )}
            <div className={"flex w-full mb-32 items-end"}>
                <DatePickerWrapper
                    className={!!dateStyles ? dateStyles : "mr-8 max-w-96"}
                    disableToolbar
                    variant="inline"
                    label={<Translate id={"_.From"} />}
                    autoOk
                    TextFieldComponent={(p) => <TextField {...p} variant={"outlined"} size={"small"} />}
                    value={dateFrom}
                    invalidDateMessage={<Translate id={"SCMs.Invalid Date Format"} />}
                    onChange={onChangeFrom}
                />
                <DatePickerWrapper
                    className={!!dateStyles ? dateStyles : "mr-12  max-w-96"}
                    disableToolbar
                    variant="inline"
                    label={<Translate id={"_.To"} />}
                    autoOk
                    TextFieldComponent={(p) => <TextField {...p} variant={"outlined"} size={"small"} />}
                    disabled={!dateFrom}
                    value={dateTo}
                    invalidDateMessage={<Translate id={"SCMs.Invalid Date Format"} />}
                    onChange={onChangeDateTo}
                />
                {!!dateFrom && (
                    <div>
                        <IconButton size={"small"} onClick={clearSearch}>
                            <Icon fontSize={"small"}>close</Icon>
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DateFromToQuery;
