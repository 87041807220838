import {Icon, LinearProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {EmptyView} from "@ui";
import type {ReactNode} from "react";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import useSWR from "swr";
import type {SearchType} from "types";
import {FuseLoading, FuseSuspense} from "../../@fuse";
import {chipSearchValue, defaultIgnoreParams} from "./Filters/ViewSearches";
import {useTranslation} from "react-i18next";
import {usePersonalized} from "../../hooks/usePersonalized";

type Props<T> = {
    link?: string;
    params: SearchType;
    itemView(item: T): ReactNode;
    onClick: (item: T) => void;
    title: ReactNode;
    ignoreParams?: string[];
    actions?: ReactNode;
    className?: string;
    name: string;
};

const Paging = <T,>({link, params, itemView, onClick, page}: Props<T> & {page: number}) => {
    const {data, isLoading} = useSWR(link && [link, {...params, page}], {revalidateOnMount: true});
    // if (error) return <EmptyView error={error} />;
    if (isLoading || !data) return <LinearProgress color={"secondary"} className={"h-16"} />;
    // ... handle loading and error states
    const content = data.content || data;
    return content.map((item: T, i: number) => (
        <ListItem divider onClick={() => onClick(item)} button key={i}>
            {itemView(item)}
        </ListItem>
    ));
};

const WidgetContent = <T,>(props: Props<T>) => {
    const {t} = useTranslation();
    const {addOrUpdateFilters, getFilter} = usePersonalized();
    const {link, params, title, name, ignoreParams, actions, className} = props;
    const [expand, setExpand] = useState(true);

    const [cnt, setCnt] = useState(1);

    const {data, mutate, isValidating} = useSWR(link && [link, {...params, page: 1}], {revalidateOnMount: true});

    const igParams = ignoreParams ? defaultIgnoreParams.concat(ignoreParams) : defaultIgnoreParams;

    useEffect(() => {
        getFilter(name).then((x) => {
            if (x) {
                setExpand(Boolean(x.open));
            }
        });
    }, []);

    if (!data) return <FuseLoading />;

    const pages: ReactNode[] = [];
    for (let i = 1; i <= cnt; i++) {
        pages.push(<Paging {...props} page={i} key={i} />);
    }

    const insideRender = () => {
        const content = data.content || data;

        return content.length > 0 ? (
            <List dense disablePadding>
                {pages}
                {data.totalPages > cnt && (
                    <Button fullWidth className={"rounded-none"} onClick={() => setCnt(cnt + 1)}>
                        {t("loadMore")}
                    </Button>
                )}
            </List>
        ) : (
            <EmptyView />
        );
    };

    function onFold() {
        setExpand(false);
        addOrUpdateFilters(name, {open: false});
    }

    function onExpand() {
        setExpand(true);
        addOrUpdateFilters(name, {open: true});
    }

    return (
        <FuseSuspense>
            <div className={expand ? className : ""}>
                <div className="mb-12 w-full flex justify-between">
                    {!expand ? (
                        <Card className={"flex flex-col mr-20 pb-20 items-center font-bold"}>
                            <IconButton onClick={onExpand} style={{transform: !expand ? "rotate(180deg)" : ""}}>
                                <Icon>menu_open</Icon>
                            </IconButton>
                            <Typography variant={"h6"} style={{writingMode: "vertical-lr"}}>
                                {title}
                            </Typography>
                            <span className={"text-primary mt-12"}>({data.totalElements})</span>
                        </Card>
                    ) : (
                        <>
                            <div className={"flex-1"}>
                                <Typography variant={"h6"} className={"flex-1"}>
                                    <span style={{writingMode: expand ? "horizontal-tb" : "vertical-lr"}}>{title}</span>
                                    <span className={"text-primary"}>({data.totalElements})</span>{" "}
                                    <IconButton size={"small"} onClick={() => mutate()}>
                                        <Icon className={isValidating ? "animate-spin" : ""}>refresh</Icon>
                                    </IconButton>
                                </Typography>

                                <div className={"flex items-center my-2"}>
                                    {Object.keys(params).map(
                                        (key) =>
                                            !igParams.includes(key) && (
                                                <Chip
                                                    variant={"outlined"}
                                                    key={key}
                                                    className={"mr-2 opacity/75"}
                                                    label={
                                                        <strong>
                                                            <span className={params[key] === "false" || params[key] === false ? "line-through" : ""}>
                                                                <Translate id={`_.${key}`} />
                                                            </span>
                                                            {chipSearchValue(params[key])}
                                                        </strong>
                                                    }
                                                    size={"small"}
                                                />
                                            )
                                    )}
                                </div>

                                {actions}
                            </div>

                            <div>
                                <IconButton onClick={onFold} style={{transform: !expand ? "rotate(180deg)" : ""}}>
                                    <Icon>menu_open</Icon>
                                </IconButton>
                            </div>
                        </>
                    )}
                </div>
                <Card
                    className={"overflow-y-auto"}
                    style={{maxHeight: "calc(100vh - 270px)"}}
                    // when scroll reaches end event

                    // contentProps={{className: "overflow-y-scroll  p-0"}}
                    // noPadding
                    // action={<div className={"flex items-center mt-3 mr-3 "}>
                    //     {Object.keys(params).map(key =>
                    //         (key !== "page" && key !== "size" && key !== "keyword") &&
                    //         <Chip
                    //             key={key}
                    //             className={"mx-2"}
                    //             label={<strong>
                    //                 {/*<span className={params[key] === "true" || params[key] === true ? "" : "font-normal"}><Translate id={key}/></span> */}
                    //                 {searchLabel(key)}</strong>}
                    //             size={"small"}/>
                    //     )}
                    // </div>}
                    // title={<Typography className={"mb-12"} variant={"h6"}>{title}</Typography>}
                >
                    {expand && insideRender()}
                </Card>
            </div>
        </FuseSuspense>
    );
};

export default WidgetContent;
