import {Card, TableBody} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import {useMemoizedFn} from "ahooks";
import PropTypes from "prop-types";
import {Fragment, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {ViewportList} from "react-viewport-list";
import {FuseScrollbars} from "../../@fuse";
import TableEmpty from "../empty_placeholder/TableEmpty";
import {TABLE_ROW_HEIGHT_30} from "../utilComponents";
import LFTableHeaderView from "./LFTableHeaderView";
import PaginationFilter from "./PaginationFilter";
import SearchBox from "./SearchBox";
import {TableAdapterModel} from "./utils";

TableRoot.propTypes = {
    query: PropTypes.object.isRequired,
    onChangeQuery: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    colSpan: PropTypes.number.isRequired,
    /**
     * @description - head 表头
     */
    columns: PropTypes.array,
    /**
     * @description - rows 数据源
     */
    dataSources: PropTypes.array,
    /**
     * @description - 被选中的 row 数据源
     */
    selectedRows: PropTypes.array,
    /**
     * @description - 选中某行的回调函数
     * @param {array} rows || [] || null - 选中的行数据
     */
    onChangeSelectedRows: PropTypes.func,

    /**
     * @description - 适配 手机端的 特殊定制化 row
     */
    mobileRowView: PropTypes.any,
    /**
     * @description - 适配 手机端的 特殊定制化 model,默认 xScroller: 沿 X 轴滑动
     */
    adapterModel: PropTypes.oneOf([TableAdapterModel.expand, TableAdapterModel.xScroller]),

    /**
     *  @description - 向下扩展展示被遮挡的 cell 数据 view
     */
    expandRowView: PropTypes.any,

    numSelected: PropTypes.number,

    /**
     * @description - 在一些详情页面中(比如实例、定义详情)，在 table 上需要一个搜索框
     */
    searchable: PropTypes.bool,
    /**
     * @description - table 搜索框的搜索字段
     */
    searchBy: PropTypes.string,
    /**
     *
     */
    topToolbarView: PropTypes.any,
    /**
     *  @description - 正常状态下的 row
     */
    MainRowView: PropTypes.any,
    /**
     * @description - 手机上展示被隐藏的 row
     */
    ExtraRowView: PropTypes.any
};

TableRoot.defaultProps = {
    adapterModel: TableAdapterModel.xScroller
};

const initState = {
    numSelected: 0,
    rowCount: 0
};

function TableRoot(props) {
    const {
        children,
        query,
        onChangeQuery,
        mutate,
        columns,
        dataSources,
        selectedRows,
        onChangeSelectedRows,
        totalPages,
        count,
        colSpan,
        searchBy,
        searchable,
        topToolbarView,
        MainRowView,
        ExtraRowView
    } = props;

    const listView = useSelector(({fuse}) => fuse.dialogs?.listView);

    const ref = useRef(null);

    const [headerHides, setHeaderHides] = useState(new Set());

    const [expandedRow, setExpandedRow] = useState(new Set());

    // const [state, setState] = useSetState(initState);


    const handleSelectAllClick = useMemoizedFn((datas) => {
        onChangeSelectedRows && onChangeSelectedRows(datas);
    });

    const handleExpandRow = useMemoizedFn((id) => {
        const t = new Set(expandedRow);
        if (t.has(id)) {
            t.delete(id);
        } else {
            t.add(id);
        }
        setExpandedRow(t);
    });

    return (
        <Card data-cy={"table-root"} className={"w-full flex flex-col h-full mt-12"}>
            {searchable && (
                <SearchBox searchBy={searchBy} query={query} onChangeQuery={onChangeQuery} mutate={mutate} />
            )}
            {topToolbarView && topToolbarView}
            <TableContainer>
                {/*<FuseScrollbars className="grow overflow-x-auto">*/}
                    <Table key={dataSources?.length} size={listView ? "small" : "medium"}>
                        {children ? (
                            children
                        ) : (
                            <>
                                <LFTableHeaderView
                                    columns={columns}
                                    dataSource={dataSources}
                                    query={query}
                                    onChangeQuery={onChangeQuery}
                                    mutate={mutate}
                                    headSet={headerHides}
                                    onHeadSet={setHeaderHides}
                                    numSelected={selectedRows?.length || 0}
                                    rowCount={dataSources?.length || 0}
                                    onAllSelection={handleSelectAllClick}
                                />
                                <TableBody ref={ref}>
                                    {!!dataSources && dataSources?.length > 0 ? (
                                        <ViewportList
                                            data-cy={"table-viewport"}
                                            viewportRef={ref}
                                            items={dataSources}
                                            itemSize={TABLE_ROW_HEIGHT_30}
                                        >
                                            {(item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {MainRowView && (
                                                            <MainRowView
                                                                mutate={mutate}
                                                                expandedRow={expandedRow}
                                                                onChangeExpandRow={handleExpandRow}
                                                                query={query}
                                                                row={item}
                                                                index={index}
                                                            />
                                                        )}
                                                        {ExtraRowView && (
                                                            <ExtraRowView
                                                                open={expandedRow.has(item?.id)}
                                                                row={item}
                                                                index={index}
                                                            />
                                                        )}
                                                    </Fragment>
                                                );
                                            }}
                                        </ViewportList>
                                    ) : (
                                        <TableEmpty />
                                    )}
                                </TableBody>
                            </>
                        )}
                    </Table>
                {/*</FuseScrollbars>*/}
            </TableContainer>
            <div className={"flex flex-row  items-end"}>
                <PaginationFilter
                    colSpan={colSpan}
                    query={query}
                    onChangeQuery={onChangeQuery}
                    onChangeSelectedRows={onChangeSelectedRows}
                    mutate={mutate}
                    totalPages={totalPages}
                    count={count}
                />
            </div>
        </Card>
    );
}

export default TableRoot;
