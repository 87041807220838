import {toggleCheck} from '@fuseActions';
import {Checkbox, Icon, Table, TableBody, TableCell} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/styles';
import type {EmptyPropTypes} from '@ui';
import {EmptyView} from '@ui';
import classNames from 'classnames';
import clsx from 'clsx';
import type {JSXElementConstructor, ReactNode} from 'react';
import {Fragment, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import type {columnType} from '../../types';
import TableHeadView from '../../utils/Page/Views/TableHeadView';
import {StyledTableCell, StyledTableRow} from '../../utils/utilComponents';

const useStyles = makeStyles(() => ({
    row: {
        "& .actions": {
            display: "none",
            backdropFilter: "blur(6px)",
            // backgroundColor: theme.palette.background.default,
            // background: `linear-gradient(90deg, transparent 0  , ${theme.palette.grey["300"]} 90%)`,
        },
        "&:hover .actions": {
            display: "flex",
        },
    },
}));

type itemType = {
    id: string;
};

export type TableViewProps<T> = {
    dense?: boolean;
    name: string;
    content?: T[];
    columns: columnType<T>[];
    orderBy?: string; // orderBy
    direction?: "asc" | "desc"; // direction
    selectable?: boolean;
    selectedItemId?: string;
    itemProps?: (arg0: T) => {[key: string]: any | undefined};
    // ViewItem?: JSXElementConstructor<any>;
    itemActions?: (arg0: T) => ReactNode;
    disableSelect?: boolean | ((arg0: T) => void);
    size?: number;
    handleRequestSort?: (property: string) => void;
    ExtraRow?: JSXElementConstructor<any>;
    hideColumnIds?: string[];
    refresh?(): void;
    to?(item: T): string | undefined;
    stickyHeader?: boolean;
    emptyProps?: EmptyPropTypes;
};

export function TableView<T extends itemType>({
    itemActions,
    handleRequestSort,
    dense,
    content,
    name,
    direction,
    orderBy,
    disableSelect,
    selectable,
    columns,
    selectedItemId,
    itemProps,
    ExtraRow,
    hideColumnIds,
    size,
    refresh,
    emptyProps,
    to,
    stickyHeader,
    ...rest
}: TableViewProps<T>) {
    // @ts-ignore
    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);
    // @ts-ignore
    const checked = useSelector(({fuse}) => fuse.checked);
    // @ts-ignore
    const search = useSelector(({fuse}) => fuse.search);
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const [openRow, setOpenRow] = useState<string | null>(null);

    // @ts-ignore
    const isClickable = (!!itemProps && typeof itemProps({id: ""}).onClick === "function") || !!to;

    const [hides, setHides] = useState(new Set<string>());

    const onClick = (_: T) => {
        if (isClickable) {
            if (!!to && typeof to(_) === "string") {
                // @ts-ignore
                history.push(to(_));
            } else itemProps?.(_)?.onClick?.();
        }
    };

    const itemP = (i: T) => {
        if (itemProps) return itemProps(i);
        else {
            return {};
        }
    };

    const forwardProps = {
        ...rest,
        refresh,
        name,
        dense,
        setOpenRow,
        openRow,
        listView,
    };

    // @ts-ignore
    const page = search.page ? search.page : 1;

    const [headerColumns, setHeaderColumns] = useState(columns?.filter((c) => !hides.has(c.id)));
    // if (selectable) {
    //     headerColumns = [
    //         {
    //             id: "check",
    //             sort: false,
    //         },
    //         ...columns,
    //     ];
    // }

    useEffect(() => {
        setHeaderColumns(columns?.filter((c) => !hides.has(c.id)));
    }, [hides, columns]);

    useEffect(() => {
        if (hideColumnIds && hideColumnIds.length > 0) setHides(new Set(hideColumnIds));
    }, [hideColumnIds]);

    return (
        <Table stickyHeader={stickyHeader} size={dense || listView ? "small" : "medium"}>
            <TableHeadView<T>
                hides={hides}
                setHides={setHides}
                columns={headerColumns}
                direction={direction}
                allColumns={columns}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                smallHeader={dense || listView}
                selectable={selectable}
            />

            <TableBody>
                {content && content?.length > 0 ? (
                    content.map((_, i) => {
                        // @ts-ignore
                        const isChecked = checked[name] ? checked[name].includes(_.id) : false;
                        return (
                            <Fragment key={i}>
                                <StyledTableRow
                                    selected={selectedItemId ? selectedItemId === _.id : false}
                                    role="checkbox"
                                    tabIndex={-1}
                                    {...itemP(_)}
                                    className={clsx(
                                        isChecked && "bg-blue-lightest",
                                        dense || listView ? "h-24" : "h-64",
                                        "truncate",
                                        isClickable && "cursor-pointer",
                                        i < content.length - 1 && "border-b-1",
                                        " relative",
                                        itemProps?.(_).className,
                                        classes.row
                                    )}
                                    onClick={() => onClick(_)}>
                                    {/*<StyledTableCell padding={"none"} className={"text-right md:hidden table-cell"} component="td" scope="row">*/}
                                    {/*    <IconButton*/}
                                    {/*        onClick={(e)=>{*/}
                                    {/*            e.stopPropagation();*/}
                                    {/*            this.setState(state => ({openRow: state.openRow === i ? null  : i}));*/}
                                    {/*        }}*/}
                                    {/*    ><Icon>{openRow === i ? "expand_less" : "expand_more"}</Icon></IconButton>*/}
                                    {/*</StyledTableCell>*/}

                                    {selectable && isMobile && (
                                        <StyledTableCell
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenRow(openRow === _.id ? null : _.id);
                                            }}
                                            scope="row"
                                            className={classNames("text-grey-dark")}
                                            padding={"none"}>
                                            <Icon
                                                fontSize={"inherit"}
                                                style={{
                                                    transform: openRow === _.id ? "rotate(0)" : "rotate(180deg)",
                                                }}
                                                className={"transition-all duration-300 text-24"}>
                                                {"expand_less"}
                                            </Icon>
                                        </StyledTableCell>
                                    )}

                                    <StyledTableCell
                                        onClick={(e) => {
                                            if (!isMobile || selectable) return;
                                            e.stopPropagation();
                                            setOpenRow(openRow === _.id ? null : _.id);
                                        }}
                                        scope="row"
                                        className={classNames("w-24 md:w-40 text-grey-dark")}
                                        padding={"none"}>
                                        <div className={clsx(selectable ? "justify-start" : "justify-center", "flex items-center ")}>
                                            {!selectable && (
                                                <Icon
                                                    fontSize={"inherit"}
                                                    style={{
                                                        transform: openRow === _.id ? "rotate(0)" : "rotate(180deg)",
                                                    }}
                                                    className={"transition-all duration-300 text-24  md:hidden"}>
                                                    {"expand_less"}
                                                </Icon>
                                            )}
                                            {selectable && (
                                                <Checkbox
                                                    disabled={!!(typeof disableSelect === "function" && disableSelect(_))}
                                                    data-cy={"select-item"}
                                                    className={"-ml-3"}
                                                    checked={isChecked}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={() => dispatch(toggleCheck(_.id, name))}
                                                />
                                            )}
                                            <span>{size ? size * (page - 1) + i + 1 : i + 1}</span>
                                        </div>
                                    </StyledTableCell>

                                    {
                                        //     ViewItem ? (
                                        //     <ViewItem {...forwardProps} index={i} key={i} item={_} />
                                        // ) : (
                                        columns?.map((cell, j) => {
                                            const {padding, itemView, id, className, hideInMobile, customClassName, ...rest} = cell;
                                            return (
                                                !hides.has(id) && (
                                                    <StyledTableCell
                                                        key={j}
                                                        padding={padding}
                                                        className={clsx(className, customClassName?.(_), hideInMobile && "hidden md:table-cell")}
                                                        {...rest}>
                                                        {itemView?.(_, forwardProps)}
                                                    </StyledTableCell>
                                                )
                                            );
                                        })
                                        // )
                                    }
                                    {!!itemActions && !isMobile && (
                                        <td
                                            onClick={(e) => e.stopPropagation()}
                                            className={"actions absolute flex items-center justify-center top-0 right-0 h-full"}>
                                            {itemActions(_)}
                                        </td>
                                    )}
                                </StyledTableRow>

                                {openRow === _.id && (
                                    <TableRow className={"print:hidden "}>
                                        <StyledTableCell
                                            // @ts-ignore
                                            colSpan={"100%"}
                                            padding={"none"}>
                                            <Table size={"small"}>
                                                <TableBody>
                                                    <>
                                                        {columns?.map((cell, j) => {
                                                            const {itemView, id, className, label, customClassName, hideInMobile, ...rest} = cell;
                                                            return (
                                                                !hides.has(id) &&
                                                                hideInMobile && (
                                                                    <StyledTableRow>
                                                                        <StyledTableCell>
                                                                            <Translate id={label} />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            key={j}
                                                                            className={clsx(className, customClassName?.(_))}
                                                                            {...rest}>
                                                                            {itemView?.(_, forwardProps)}
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            );
                                                        })}
                                                        {!!itemActions && (
                                                            <StyledTableRow>
                                                                <StyledTableCell>{itemActions(_)}</StyledTableCell>
                                                            </StyledTableRow>
                                                        )}
                                                        {!!ExtraRow && (
                                                            <StyledTableRow>
                                                                <StyledTableCell
                                                                    // @ts-ignore
                                                                    colSpan={"100%"}>
                                                                    <ExtraRow {...forwardProps} index={i} key={i} item={_} />
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )}
                                                    </>
                                                </TableBody>
                                            </Table>
                                        </StyledTableCell>
                                    </TableRow>
                                )}

                                {!!ExtraRow && !isMobile && (
                                    <TableRow>
                                        <StyledTableCell
                                            // @ts-ignore
                                            colSpan={"100%"}>
                                            <ExtraRow {...forwardProps} index={i} key={i} item={_} />
                                        </StyledTableCell>
                                    </TableRow>
                                )}

                                {/*{!!ViewItemMobile && openRow === i &&*/}
                                {/*<Hidden mdUp>*/}
                                {/*    <TableRow*/}
                                {/*        className={classNames(`h-64 cursor-pointer md:hidden bg-grey-lighter`)}*/}
                                {/*        hover*/}
                                {/*        tabIndex={-1}*/}
                                {/*    >*/}
                                {/*        <TableCell colSpan={"100%"} padding={"none"} component="td" scope="row">*/}
                                {/*            <ViewItemMobile*/}
                                {/*                {...forwardProps}*/}
                                {/*                open={openRow === i}*/}
                                {/*                index={i}*/}
                                {/*                key={i} item={_}/>*/}
                                {/*        </TableCell>*/}
                                {/*    </TableRow>*/}
                                {/*</Hidden>*/}
                                {/*}*/}
                            </Fragment>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell
                            align={"center"}
                            // @ts-ignore
                            colSpan={"100%"}>
                            <EmptyView {...emptyProps} />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}
