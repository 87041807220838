import {memo} from "react";
import {FuseScrollbars} from "../../../../@fuse";
import Navigation from "app/layouts/shared-components/Navigation";
import {version} from "../../../../ENV.VARIABLE";
import {Translate} from "react-localize-redux";

function NavbarLayout3() {
    return (
        <div className="flex flex-auto items-center w-full h-full containerd px-16 lg:px-24  justify-between">
            <FuseScrollbars className="flex h-full items-center">
                <Navigation className="w-full -ml-12" layout="horizontal" dense />
            </FuseScrollbars>
            <span className="opacity/50 text-white  font-italic">
                {<Translate id={"_.Version"} />}: {version}
            </span>
        </div>
    );
}

export default memo(NavbarLayout3);
