import {Icon, Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import {MyAvatar, MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import axios, {crmPrefix} from "../../../../../myaxios";
import {EnumFormControl} from "../../marketingTools";
import ItemHeader from "../../utils/ItemHeader";

class Header extends Component {
    state = {
        anchorEl: null
    };

    handleFormData = () => (event) => {
        const value = event.target.value;
        const data = {
            companyId: this.props.sourceItem.id,
            statusKey: value
        };
        axios.put(`${crmPrefix}/companySetStatus`, data).then(() => {
            this.props.refresh();
            this.setState({anchorEl: null});
        });
    };

    render() {
        const {sourceItem, canEdit, turnOn} = this.props;
        const {anchorEl} = this.state;
        return (
            <>
                <ItemHeader
                    {...this.props}
                    actions={
                        <>
                            <Popover
                                id="simple-menu"
                                classes={{paper: "w-160 p-12"}}
                                anchorEl={anchorEl}
                                keepMounted
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({anchorEl: null})}
                            >
                                <EnumFormControl
                                    deleteParam={"active"}
                                    type={"SalesCompanyStatus"}
                                    name={"status"}
                                    formData={{status: sourceItem.status ? sourceItem.status.key : ""}}
                                    handleFormData={this.handleFormData}
                                />
                            </Popover>
                            <MyTooltip title={<Translate id={"market.Change manager"} />}>
                                <IconButton onClick={() => turnOn("companyChangeUser")}>
                                    <Icon>person</Icon>
                                </IconButton>
                            </MyTooltip>
                            <MyTooltip title={<Translate id={"market.status"} />}>
                                <Chip
                                    color={"secondary"}
                                    label={sourceItem.status ? sourceItem.status.value : <Translate id={"market.No status"} />}
                                    onClick={(e) => canEdit && this.setState({anchorEl: e.currentTarget})}
                                />
                            </MyTooltip>
                        </>
                    }
                    title={
                        <div className={"flex items-center"}>
                            <MyAvatar src={sourceItem.avatar} size={48} />
                            <div className={"flex flex-col md:pt-0 md:w-auto pl-16 w-full"}>
                                <Typography variant={"h5"} className={classNames("flex mb-2 items-center")}>
                                    <span className={"font-bold"}>
                                        {sourceItem.code}
                                        {sourceItem.code ? <small> {sourceItem.name}</small> : sourceItem.name}
                                    </span>
                                    <Icon className="ml-2">{sourceItem.members.length > 1 ? "lock_open" : "lock"}</Icon>
                                </Typography>
                                <div>
                                    {!!sourceItem.industry && (
                                        <MyTooltip title={<Translate id={"market.Industry"} />}>
                                            <Chip size={"small"} color={"secondary"} label={sourceItem.industry.value} className={"mr-2"} />
                                        </MyTooltip>
                                    )}
                                    {!!sourceItem.ownership && (
                                        <MyTooltip title={<Translate id={"market.Ownership"} />}>
                                            <Chip size={"small"} color={"secondary"} label={sourceItem.ownership.value} />
                                        </MyTooltip>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                />
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: fuseActions.turnOn
        },
        dispatch
    );
}

export default withLocalize(connect(null, mapDispatchToProps)(Header));
