import {finishLoading, startLoading} from "@fuseActions";
import axios, {financialPrefix} from "../../../../../myaxios";

export const GET_MY_INCOMES = "[GET_MY_INCOMES]";

export function getMyIncomess(params, callback) {
    const request = params
        ? axios.get(`${financialPrefix}/myIncomes`, {params})
        : axios.get(`${financialPrefix}/myIncomes`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((res) => {
            dispatch({
                type: GET_MY_INCOMES,
                payload: res.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}
