import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Translate} from "react-localize-redux";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {useMemoizedFn} from "ahooks";
import axios, {flowPrefix} from "../../../../../myaxios";
import * as fuseActions from "app/store/actions";
import {useInstanceStore} from "../instances/state/useInstanceStore";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {map} from "lodash";

function NewBatchDeleteInstanceDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.batchDeleteInstance,
    });

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const selected = useInstanceStore((state) => state.instanceSelected);

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSure = useMemoizedFn((e) => {
        e.stopPropagation();
        setLoading(true);
        const ids = map(selected, (t) => t.id);
        let params = {
            ids: ids,
        };
        console.log("delete processInstances > params: ", params);
        axios
            .delete(`${flowPrefix}/processInstances`, {data: params})
            .then((response) => {
                if (response.status === 200) {
                    finshParams?.onFinish();
                    dispatch(
                        fuseActions.showMessage({
                            variant: "success",
                            message: "message.processInstances deleted!",
                        })
                    );
                    onTurnOffDialog();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return (
        <Dialog {...dialogProps}>
            <div className={loading ? "loading" : null}>
                <DialogTitle id="NewBatchDeleteInstanceDialog-dialog-title">
                    <Translate id="process.Tips" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="NewBatchDeleteInstanceDialog-dialog-description">
                        <Translate id="process.Are you sure you want to delete the selected instances?" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button onClick={handleSure} variant={"contained"} color="secondary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewBatchDeleteInstanceDialog;
