import {Grow, Icon, IconButton, ListItem, ListItemText, Paper} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {Fragment, memo, useState} from "react";
import * as ReactDOM from "react-dom";
import {withLocalize} from "react-localize-redux";
import {Manager, Popper, Reference} from "react-popper";
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {useDebounce} from "../../../hooks";
import FuseNavBadge from "./../FuseNavBadge";
import FuseNavHorizontalItem from "./FuseNavHorizontalItem";
import FuseNavHorizontalLink from "./FuseNavHorizontalLink";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .list-item-text": {
            padding: "0 0 0 16px"
        }
    },
    button: {
        display: "flex !important",
        color: theme.palette.text.primary,
        minHeight: 48,
        "&.open": {
            backgroundColor: "rgba(0,0,0,.08)"
        },
        "&.dense": {
            padding: "8px 12px 8px 12px",
            minHeight: 40,
            "& .list-item-text": {
                padding: "0 0 0 8px"
            }
        }
    },
    popper: {
        zIndex: 999
    },
    popperClose: {
        pointerEvents: "none"
    }
}));

/**
 * @return {null}
 */
function FuseNavHorizontalCollapse(props) {
    //    const userRole = useSelector(({user}) => user.role);
    const unread = useSelector(({user}) => user.unread);

    // console.log(unread[item.title]);

    const classes = useStyles(props);
    const [opened, setOpened] = useState(false);
    const {item, nestedLevel, dense} = props;

    const handleToggle = useDebounce((open) => {
        setOpened(open);
    }, 150);

    //    if (!FuseUtils.hasPermission(item.auth, userRole)) {
    //        return;
    //    }

    return (
        <ul className={clsx(classes.root, "relative pl-0")}>
            <Manager>
                <Reference>
                    {({ref}) => (
                        <div ref={ref}>
                            <ListItem
                                button
                                className={clsx("list-item", classes.button, opened && "open", dense && "dense")}
                                onMouseEnter={() => handleToggle(true)}
                                onMouseLeave={() => handleToggle(false)}
                                aria-owns={opened ? "menu-list-grow" : null}
                                aria-haspopup="true"
                            >
                                {item.icon && (
                                    <Icon color="action" className="text-20 flex-shrink-0">
                                        {item.icon}
                                    </Icon>
                                )}
                                <ListItemText
                                    className="list-item-text"
                                    primary={props.activeLanguage.code === "cn" ? item.titleCn : item.title}
                                    classes={{primary: "text-14"}}
                                />
                                {item.badge && <FuseNavBadge className="ml-8 mr-4" badge={item.badge} />}
                                {unread && unread[item.title] > 0 && <Chip color={"secondary"} className="ml-8 mr-4" label={unread[item.title]} />}
                                <IconButton disableRipple className="w-16 h-16 ml-4 p-0">
                                    <Icon className="text-20 arrow-icon">keyboard_arrow_right</Icon>
                                </IconButton>
                            </ListItem>
                        </div>
                    )}
                </Reference>
                {ReactDOM.createPortal(
                    <Popper placement="right" eventsEnabled={opened} positionFixed>
                        {({ref, style, placement, arrowProps}) =>
                            opened && (
                                <div
                                    ref={ref}
                                    style={{
                                        ...style,
                                        zIndex: 999 + nestedLevel + 1
                                    }}
                                    data-placement={placement}
                                    className={clsx(classes.popper, {[classes.popperClose]: !opened})}
                                >
                                    <Grow in={opened} id="menu-list-grow" style={{transformOrigin: "0 0 0"}}>
                                        <Paper onMouseEnter={() => handleToggle(true)} onMouseLeave={() => handleToggle(false)}>
                                            {item.children && (
                                                <ul className={clsx(classes.children, "pl-0")}>
                                                    {item.children.map((j) => (
                                                        <Fragment key={j.id}>
                                                            {(j.children && j.children.length > 0) ?
                                                                <NavHorizontalCollapse item={j} nestedLevel={nestedLevel + 1} dense={dense} />
                                                                : (j.url && j.type !== "link") ?
                                                                    <FuseNavHorizontalItem item={j} nestedLevel={nestedLevel + 1} dense={dense} /> :
                                                                    <FuseNavHorizontalLink item={j} nestedLevel={nestedLevel + 1} dense={dense} />
                                                            }
                                                            {/* {item.type === "group" && ( */}
                                                            {/*     <FuseNavHorizontalGroup item={item} nestedLevel={nestedLevel + 1} dense={dense} /> */}
                                                            {/* )} */}

                                                            {/* {item.type === "collapse" && ( */}
                                                            {/*     <NavHorizontalCollapse item={item} nestedLevel={nestedLevel + 1} dense={dense} /> */}
                                                            {/* )} */}

                                                            {/* {item.type === "item" && ( */}
                                                            {/*     <FuseNavHorizontalItem item={item} nestedLevel={nestedLevel + 1} dense={dense} /> */}
                                                            {/* )} */}

                                                            {/* {item.type === "link" && ( */}
                                                            {/*     <FuseNavHorizontalLink item={item} nestedLevel={nestedLevel + 1} dense={dense} /> */}
                                                            {/* )} */}
                                                        </Fragment>
                                                    ))}
                                                </ul>
                                            )}
                                        </Paper>
                                    </Grow>
                                </div>
                            )
                        }
                    </Popper>,
                    document.querySelector("#root")
                )}
            </Manager>
        </ul>
    );
}

FuseNavHorizontalCollapse.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        icon: PropTypes.string,
        children: PropTypes.array
    })
};

FuseNavHorizontalCollapse.defaultProps = {};

const NavHorizontalCollapse = withRouter(memo(FuseNavHorizontalCollapse));

export default withLocalize(NavHorizontalCollapse);
