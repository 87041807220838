import { turnOff, minimize, turnOn, showMessage, replaceParams } from "@fuseActions";
import {Button, DialogActions, withMobileDialog} from "@material-ui/core";
import _ from "lodash";
import { stringify } from "qs";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import EventBus, {UPDATE_CONTACT_SELECTOR_AFTER_CREATE_A_CONTACT_NOTIFI} from "utils/EventBus";

import withDialog from "utils/withDialog";
import axios, {crmPrefix} from "../../../../../myaxios";
import {getGridListAsycnNotificationName} from "../../marketingTools";
import transJson from "../../translates/translate.json";
import { getList, selectItem, selectItemForEditCompany, getItem } from "../store/actions";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";

const initState = {
    loading: false,
    step: 0,

    formData: {
        lastName: "",
        firstName: "",
        title: "",
        email: "",
        phone: "",
        contactInfos: [],
        department: "",
        companyId: "",
        assistant: "",
        leadSource: "",
        birthday: "",
        address: null,
        description: "",
        user: null,
        labels: [],
        tag: "",
        isDecisionMaker: false,
        relationShip: "",
        avatar: "",
    },
};

class CompanyContactDialog extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(transJson);
    }

    state = initState;

    handleFormData = (name) => (event) => {
        const formData = {...this.state.formData};
        formData[name] = event.target.value;
        this.setState({
            formData,
        });
    };

    componentDidMount = () => {
        EventBus.on("watchQuiklyCreateCompany", this.listenToUpdateCompany);
        const {selectedItem, selectedItemForEditCompany, selectedCompany, selectedActivity, item} = this.props;
        if (selectedItemForEditCompany) {
            this.setData(selectedItemForEditCompany);
            this.setState({step: 1});
        } else if (selectedItem) {
            this.setData(selectedItem);
        } else if (selectedActivity) {
            this.setData(selectedActivity);
        } else if (selectedCompany) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    company: selectedCompany,
                    companyId: selectedCompany.id,
                },
            });
        } else if (item) {
            this.setData(item);
        }
    };

    componentWillUnmount = () => {
        this.clearData();
    };

    setData = (selectedItem) => {
        if (selectedItem !== null && selectedItem !== undefined) {
            const selectedLabels = [];
            selectedItem.labels && selectedItem.labels.map((_i) => selectedLabels.push(_i.id));
            this.setState({
                initLabels: selectedItem.labels
                    ? selectedItem.labels.map((item) => ({
                          value: item.id,
                          color: item.color,
                          label: <strong style={{color: item.color}}>{item.title}</strong>,
                      }))
                    : [],
                selectedLabels,
                formData: {
                    ...selectedItem,
                    company: selectedItem.company ? selectedItem.company : "",
                    companyId: selectedItem.company ? selectedItem.company.id : "",
                },
            });
        }
    };

    listenToUpdateCompany = () => {};

    clearData = () => {
        this.setState(initState);
        this.props.selectItemForEditCompany(null);
        this.props.selectItem(null);
    };

    filterData = () => {
        const search = {...this.props.search};
        this.props.getList(search);
        delete search.keyword;
        delete search.content;
        localStorage.setItem("companiesFilter", stringify({...search}));
    };

    onSubmit = (e, quick) => {
        e.preventDefault();
        const pathName = this.props.location.pathname;
        const {step} = this.state;
        if (step !== 1 && !quick) {
            this.setState((state) => ({step: state.step + 1}));
            return;
        }
        const {search, selectedItem, dialogs, turnOn, showMessage, getItem, match, item, replaceParams} = this.props;
        this.setState({loading: true});
        let formData = this.state.formData;
        if (selectedItem) {
            formData.id = selectedItem.id;
        }

        if (this.props.selectedGridListType && pathName === "/marketing/activities") {
            formData.activityId = this.props.selectedActivity.id;
        }

        if (this.props.selectedGridListType && pathName === "/marketing/deals") {
            formData.dealId = this.props.selectedDeal.id;
        }

        delete formData.company;

        if (_.isArray(formData.contactInfos) === true && formData.contactInfos.length > 0) {
            formData.contactInfos = formData.contactInfos.map((t) => {
                return {...t, type: t.type.key};
            });
        }

        if (_.isString(formData.relationShip) === false && formData.relationShip !== null && formData.relationShip !== undefined) {
            formData.relationShip = formData.relationShip.key;
        }

        if (_.isString(formData.leadSource) === false && formData.leadSource !== null && formData.leadSource !== undefined) {
            formData.leadSource = formData.leadSource.key;
        }

        let request =
            selectedItem && !dialogs["duplicateItem"]
                ? axios.put(`${crmPrefix}/companyContact`, formData)
                : axios.post(`${crmPrefix}/companyContact`, formData);
        request
            .then((res) => {
                this.setState({loading: false});
                showMessage({
                    message: "message.Item Saved!",
                    variant: "success",
                });

                if (this.props.selectedGridListType) {
                    const notifiName = getGridListAsycnNotificationName(this.props.selectedGridListType);
                    EventBus.dispatch(notifiName, notifiName);
                } else if (pathName === "/marketing/activities" || pathName === "/marketing/deals") {
                    EventBus.dispatch(UPDATE_CONTACT_SELECTOR_AFTER_CREATE_A_CONTACT_NOTIFI, res.data);
                } else if (item) {
                    getItem(item.id);
                } else {
                    turnOn("contactsSideFilter");
                    replaceParams({...match.params, size: search.size}).then(() => this.filterData());
                }
                this.clearData();
                this.closeDialog();
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    onChangeDueDate = (date) => {
        this.setState({dueDate: date});
    };

    closeDialog = () => {
        this.props.turnOff("contact");
        this.props.selectItem(null);
    };

    goBack = () => {
        if (this.state.step !== -1) this.setState((state) => ({step: state.step - 1}));
    };

    render() {
        const {dialogs} = this.props;
        const {loading, formData, step} = this.state;

        const disableSubmit = formData.lastName === "" || formData.companyId === "";

        const stepsProps = {
            handleFormData: this.handleFormData,
            onSubmit: this.onSubmit,
            step,
            selectedItem: this.props.selectedItem
                ? this.props.selectedItem
                : this.props.selectedItemForEditCompany
                ? this.props.selectedItemForEditCompany
                : null,
            formData,
            goBack: this.goBack,
            closeDialog: this.closeDialog,
        };

        return (
            <div className={`overflow-visible ${loading ? "loading" : ""}`}>
                <Step0 {...stepsProps} />
                <Step1 {...stepsProps} />
                {step > 0 && <Step2 {...stepsProps} />}

                <DialogActions>
                    <Button color="default" onClick={this.closeDialog}>
                        <Translate id="_.cancel" />
                    </Button>
                    {step < 1 && (
                        <>
                            <Button disabled={disableSubmit} color="secondary" onClick={(e) => this.onSubmit(e, true)}>
                                <Translate id="_.quick save" />
                            </Button>

                            <Button variant={"contained"} color="secondary" disabled={disableSubmit} onClick={this.onSubmit}>
                                <Translate id="_.next" />
                            </Button>
                        </>
                    )}
                    {step === 1 && (
                        <Button color="secondary" variant={"contained"} onClick={this.onSubmit}>
                            <Translate id="_.save" />
                        </Button>
                    )}
                </DialogActions>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: turnOff,
            minimize: minimize,
            turnOn: turnOn,
            showMessage: showMessage,
            getList: getList,
            selectItem: selectItem,
            selectItemForEditCompany: selectItemForEditCompany,
            getItem: getItem,
            replaceParams: replaceParams,
        },
        dispatch
    );
}

function mapStateToProps({marketing, fuse, user}) {
    return {
        selectedItem: marketing.companyContacts.list.selected,
        selectedActivity: marketing.activities.item,
        selectedDeal: marketing.deals.item,
        item: marketing.companyContacts.item,
        selectedItemForEditCompany: marketing.companyContacts.list.selectedForEditCompany,
        selectedGridListType: marketing.labels.selectedGridListType,
        search: fuse.search,
        dialogs: fuse.dialogs,
        selectedCompany: marketing.companies.item,
    };
}

export default withDialog(
    "contact",
    "sm",
    true
)(
    withRouter(
        withMobileDialog()(
            withLocalize(connect(mapStateToProps, mapDispatchToProps)(CompanyContactDialog))
        )
    )
);
