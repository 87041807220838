import {Collapse, Icon, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {Fragment, memo, useEffect, useState} from "react";
import {withLocalize} from "react-localize-redux";
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import FuseNavBadge from "./../FuseNavBadge";
import FuseNavVerticalItem from "./FuseNavVerticalItem";
import FuseNavVerticalLink from "./FuseNavVerticalLink";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        margin: 0,
        "&.open": {
            backgroundColor: theme.palette.background.default,
            marginTop: 10,
            marginBottom: 10
        }
    },
    item: {
        // height      : "4.4rem",
        // width       : '100%',
        // padding: 0,
        // alignItems: "stretch",
        // borderRadius: 8,
        letterSpacing: "0.1rem",
        "&.active": {
            // color : theme.palette.secondary.light,
            // backgroundColor: lighten(theme.palette.secondary.light, 0.8),
            // borderRight: "4px solid",
            // color                      : theme.palette.secondary.light,
            // borderRightColor: theme.palette.secondary.main,
        },
        color: theme.palette.text.primary,
        // '&.square'  : {
        //     width       : '100%',
        //     borderRadius: '0'
        // },
        "&:hover": {
            backgroundColor: theme.palette.background.default
        }
    },
    icon: {
        // backgroundColor: theme.palette.primary.light
    }
}));

function needsToBeOpened(location, item) {
    return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
    if (!parent.children) {
        return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
        if (parent.children[i].children) {
            if (isUrlInChildren(parent.children[i], url)) {
                return true;
            }
        }

        if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
            return true;
        }
    }

    return false;
}

/**
 * @return {null}
 */
function FuseNavVerticalCollapse(props) {
    //    const userRole = useSelector(({user}) => user.role);

    const unread = useSelector(({user}) => user.unread);

    const classes = useStyles(props);
    const [open, setOpen] = useState(() => needsToBeOpened(props.location, props.item));
    const {item, nestedLevel, active} = props;
    useEffect(() => {
        if (needsToBeOpened(props.location, props.item)) {
            setOpen(true);
        }
    }, [props.location, props.item]);

    function handleClick() {
        setOpen(!open);
    }

    //    if (!FuseUtils.hasPermission(item.auth, userRole)) {
    //        return null;
    //    }

    return (
        <ul className={clsx(classes.root, open && "open", "rounded-8 duration-300")}>
            <ListItem
                data-cy={item.id}
                button
                // className={clsx(classes.item, listItemPadding, 'list-item', active)}
                className={clsx(classes.item, open && "active", "rounded-8 h-40 pl-0 relative")}
                onClick={handleClick}
            >
                {item.icon && (
                    <ListItemIcon className={clsx("flex justify-center min-w-48")}>
                        <Icon color="action" className="text-22">
                            {item.icon}
                        </Icon>
                    </ListItemIcon>
                )}

                <ListItemText
                    className="list-item-text"
                    primary={props.activeLanguage?.code === "cn" ? item.titleCn : item.title}
                    classes={{primary: "text-14"}}
                />

                {unread && unread[item.id] > 0 && <FuseNavBadge className="mr-4" badge={{title: unread[item.id]}} />}

                {/*{unread[item.title] > 0 &&*/}
                {/*    <Chip size={"small"} color={"secondary"} className="ml-8 mr-4" label={unread[item.title]} />*/}
                {/*}*/}

                <Icon className="text-24 arrow-icon" color="inherit">
                    {open ? "expand_less" : "expand_more"}
                </Icon>
            </ListItem>

            {item.children && (
                <Collapse in={open} className="collapse-children">
                    {item.children.map((j) => (
                        <Fragment key={j.id}>
                            {(j.children && j.children.length > 0) ?
                                <FuseNavVerticalCollapse item={j} nestedLevel={nestedLevel + 1} active={active} />
                                : (j.url && j.type !== "link") ? <FuseNavVerticalItem item={j} nestedLevel={nestedLevel + 1} active={active} /> :
                                    <FuseNavVerticalLink item={j} nestedLevel={nestedLevel + 1} active={active} />
                            }


                            {/* {item.type === "group" && <FuseNavVerticalGroup item={item} nestedLevel={nestedLevel + 1} active={active} />} */}

                            {/* {item.type === "collapse" && <NavVerticalCollapse item={item} nestedLevel={nestedLevel + 1} active={active} />} */}

                            {/* {item.type === "item" && <FuseNavVerticalItem item={item} nestedLevel={nestedLevel + 1} active={active} />} */}

                            {/* {item.type === "link" && <FuseNavVerticalLink item={item} nestedLevel={nestedLevel + 1} active={active} />} */}
                        </Fragment>
                    ))}
                </Collapse>
            )}
        </ul>
    );
}

FuseNavVerticalCollapse.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        icon: PropTypes.string,
        children: PropTypes.array
    })
};


const NavVerticalCollapse = withRouter(memo(FuseNavVerticalCollapse));

export default withLocalize(NavVerticalCollapse);
