import {Chip, Dialog} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useMemoizedFn} from "ahooks";
import * as fuseActions from "app/store/actions";
import {useMemo, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {NewAyncSelectWrap} from "../../../../../utils/RootComponents";
import {components} from "../../../../../utils/Select2Components";
import {searchContact} from "../../../../../utils/utilities";
import {useUserTaskStore} from "../user-tasks/state";

function NewChangeUserForUserTaskDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.changeUserForUserTask
    });

    const dispatch = useDispatch();

    const selectedUserTask = useUserTaskStore((state) => state.selectedUserTask);

    const [user, setUser] = useState("");

    const [loading, setLoading] = useState(false);

    const changeUser = useMemoizedFn((value) => {
        if (value) {
            setUser({
                ...user,
                value: value.value,
                label: value.label
            });
        } else {
            setUser("");
        }
    });

    const canSunmit = useMemo(() => {
        return user === "" || user === null || user === undefined;
    }, [user]);

    function handleClose(e) {
        e.stopPropagation();
        onTurnOffDialog();
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        const params = {
            taskId: selectedUserTask?.id,
            targetUserId: user.value
        };
        setLoading(true);
        axios
        .put(`${flowPrefix}/taskChangeUserAdmin`, params)
        .then((res) => {
            setLoading(false);
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "_.task change user success!"
                })
            );
            finshParams?.onFinish();
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <Dialog classes={{paper: "md:overflow-visible ", root: "md:overflow-visible"}} {...dialogProps}>
            <div className={loading ? "loading" : ""}>
                <DialogTitle id="responsive-dialog-title">
                    {
                        <>
                            {<Translate id={"process.endorsement for usertask"} />}
                            {selectedUserTask && selectedUserTask?.name && (
                                <Chip
                                    label={selectedUserTask?.name}
                                    className="ml-3"
                                    color={"secondary"}
                                    size="small"
                                />
                            )}
                        </>
                    }
                </DialogTitle>
                <DialogContent className={"overflow-visible"}>
                    <Translate>
                        {({translate}) => (
                            <NewAyncSelectWrap
                                isRequired
                                label={translate("process.Change agent")}
                                loadOptions={searchContact}
                                defaultOptions
                                components={components}
                                value={user}
                                onChange={changeUser}
                                placeholder={translate("process.Search a user")}
                                isClearable
                                noOptionsMessage={translate("process.No options")}
                            />
                        )}
                    </Translate>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>
                        {<Translate id={"_.cancel"} />}
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={canSunmit}
                        onClick={handleSubmit}
                        color="secondary"

                    >
                        {<Translate id={"_.sure"} />}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default NewChangeUserForUserTaskDialog;
