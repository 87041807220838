import {showMessage, turnOff} from "@fuseActions";
import {DialogActions, DialogTitle, Icon, IconButton, TextField, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {DialogWrapper, SubmitButton} from "@ui";
import {useThrottleEffect} from "ahooks";
import json from "app/main/workplans/translate/wps.json";
import FaveButton from "components/layout/fav/FaveButton";
import axios, {firmPrefix, userPrefix} from "myaxios";
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import EditorInput from "utils/EditorInput/EditorInput";
import ListComponent from "../../issues/tests/dialogs/ListComponent";
import type {WPPost} from "../types";
import useWPS from "../useWPS";
import {useWorkPlan} from "../WP/useWorkPlan";
import Details from "./Details";

const timeoutDuration = 5000;

const initFormdata: WPPost = {
    description: "",
    title: "",
};

const WPDialog = ({setFullscreen, fullscreen}: {setFullscreen: (fullscreen: boolean) => void; fullscreen: boolean}) => {
    // region hooks and states
    const {t} = useTranslation("wp");
    const selectedWP = useSelector(({fuse}) => fuse.dialogs.selectedWP);
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const project = useSelector(({commerce}) => commerce?.project?.data);
    const [loading, setLoading] = useState(false);
    const [autoSaveLoading, setAutoSaveLoading] = useState(false);
    const [autoSaved, setAutoSaved] = useState(false);
    const [formData, setFormData] = useState<WPPost>(initFormdata);
    const {getData} = useWPS();
    const {refresh} = useWorkPlan(selectedWP?.id);
    const dispatch = useDispatch();
    // endregion

    // useDebounceEffect(
    //     () => {
    //         autoSave(formData.description || "");
    //     },
    //     [formData.description],
    //     {wait: timeoutDuration}
    // );

    const autoSave = (desc: string) => {
        if (!autoSaved) {
            setAutoSaveLoading(true);
            const data = {
                key: "wpDescription",
                value: desc,
            };
            axios.put(`${userPrefix}/setting`, data).then(() => {
                setAutoSaveLoading(false);
                setAutoSaved(true);
            });
        }
    };

    const onChangeData = (name: keyof WPPost) => (event) => {
        setFormData({...formData, [name]: event?.target ? event.target.value : event});
    };

    const removeSaveContent = () => {
        onChangeData("description")("");
        setAutoSaveLoading(true);
        const data = {
            key: "wpDescription",
            value: "",
        };
        axios.put(`${userPrefix}/setting`, data).then(() => {
            setAutoSaveLoading(false);
            setAutoSaved(false);
        });
    };

    const setData = () => {
        const user = selectedWP.leader ? selectedWP.leader.user : undefined;
        setFormData({
            leader: user?.id,
            project: selectedWP.project ? selectedWP.project : undefined,
            participants: selectedWP.participants?.map((p) => p.user.id),
            title: selectedWP.title,
            description: selectedWP.description,
            attachments: selectedWP.attachments,
        });
    };

    const clearData = () => {
        setFormData(initFormdata);
        dispatch(turnOff("selectedWP"));
        if (autoSaved) {
            const data = {
                key: "wpDescription",
                value: null,
            };
            axios.put(`${userPrefix}/setting`, data).finally(() => {
                setAutoSaveLoading(false);
                dispatch(turnOff("wp"));
            });
        } else {
            dispatch(turnOff("wp"));
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (selectedWP && !dialogs["duplicateWP"]) {
            formData.workplanId = selectedWP.id;
        }

        const request =
            selectedWP && !dialogs["duplicateWP"] ? axios.put(`${firmPrefix}/workplan`, formData) : axios.post(`${firmPrefix}/workplan`, formData);
        request
            .then(({data}) => {
                setLoading(false);
                dispatch(
                    showMessage({
                        message: "message.WPDetail Saved!",
                        variant: "success",
                        gotoLink: `/workplan/${data.id}`,
                        gotoTitle: "navi.workPlan",
                    })
                );
                refresh();
                getData();
                clearData();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const uploadFileCallback = (item) => {
        setTimeout(() => {
            if (formData.attachments && formData.attachments.length > 0) {
                // @ts-ignore doesn't understand that the formdata.attachemnt is an array
                setFormData((f) => ({...f, attachments: [...formData.attachments, ...item]}));
            } else setFormData((f) => ({...f, attachments: item}));
        }, 500);
    };

    const changeDescription = (e) => {
        setAutoSaved(false);
        onChangeData("description")(e);
    };

    useThrottleEffect(
        () => {
            autoSave(formData.description || "");
        },
        [formData.description],
        {
            wait: timeoutDuration,
            leading: false,
        }
    );

    useEffect(() => {
        if (project) {
            onChangeData("project")(project);
        }
        if (selectedWP) {
            setData();
        } else {
            axios.get(`${userPrefix}/setting?key=wpDescription`).then((r) => {
                if (r.data.value && r.data.value !== "") {
                    onChangeData("description")(r.data.value);
                    setAutoSaved(true);
                }
            });
            if (dialogs.description) onChangeData("description")(dialogs.description);
        }
        return () => {
            clearData();
        };
    }, []);

    return (
        <div className={loading ? "loading  h-full" : "h-full"}>
            <DialogTitle id="alert-dialog-title" disableTypography classes={{root: "flex drag justify-between items-center px-24 py-8 rounded-t"}}>
                {selectedWP && !dialogs["duplicateWP"] ? (
                    <Translate>
                        {({translate}) => (
                            <Typography className={"my-12"} variant={"h6"}>
                                {translate("wps.Edit wp")}
                            </Typography>
                        )}
                    </Translate>
                ) : (
                    <>
                        <Typography variant={"h6"}>
                            {project ? (
                                <Translate>
                                    {({translate}) => <span>{translate("wps.New WPDetail for parameter", {name: project.name})}</span>}
                                </Translate>
                            ) : (
                                <Translate id="wps.New WPDetail" />
                            )}
                        </Typography>
                    </>
                )}
                <div>
                    {!selectedWP && <FaveButton className={"-mr-8"} sourceId={"wp"} title={"wps.New WPDetail"} type={"dialog"} />}
                    {fullscreen ? (
                        <IconButton
                            data-cy={"fullscreen"}
                            className={"no-drag"}
                            onClick={() => {
                                setFullscreen(false);
                            }}>
                            <Icon>fullscreen_exit</Icon>
                        </IconButton>
                    ) : (
                        <IconButton
                            data-cy={"fullscreen"}
                            className={"no-drag"}
                            onClick={() => {
                                setFullscreen(true);
                            }}>
                            <Icon>fullscreen</Icon>
                        </IconButton>
                    )}
                </div>
            </DialogTitle>
            <DialogContent className={"overflow-visible"}>
                <div className="flex mb-20 items-center">
                    <Translate>
                        {({translate}) => (
                            <TextField
                                autoFocus={true}
                                data-cy={"wp-title"}
                                InputLabelProps={{
                                    shrink: true,
                                    required: true,
                                }}
                                inputProps={{maxLength: 40}}
                                fullWidth
                                className={"md:w-2/3 mt-2"}
                                placeholder={translate("wps.No more than 30 character")}
                                label={translate("wps.Title")}
                                value={formData.title}
                                onChange={onChangeData("title")}
                            />
                        )}
                    </Translate>
                </div>
                {/*<div className={"flex justify-between mb-1"}>*/}
                {/*    <Typography variant={"button"}>{<Translate id={"wps.Participants"}/>}</Typography>*/}
                {/*    <Button size={"small"} color={"secondary"} onClick={()=>{setState(state=> ({openHistory: !state.openHistory}))}}>{openHistory? <Translate id={"wps.close"}/> : <Translate id={"wps.Select from history"}/>}</Button>*/}
                {/*</div>*/}
                {/*{openHistory &&*/}
                {/*<div>{history.length > 0 && history.map(h =>*/}
                {/*    <Chip variant={"default"} size={"small"}*/}
                {/*          className={"mb-2 mr-1"}*/}
                {/*          onClick={() => selectHistory(h)}*/}
                {/*          label={h.description + " (" + h.participantCount + ")"}/>)}</div>*/}
                {/*}*/}
                {/*<Translate>*/}
                {/*    {({ translate }) =>*/}
                {/*        <TreeSelect*/}
                {/*            className="mb-16 text-16 w-full"*/}
                {/*            transitionName="rc-tree-select-dropdown-slide-up"*/}
                {/*            choiceTransitionName="rc-tree-select-selection__choice-zoom"*/}
                {/*            dropdownStyle={{ height: 220, opacity: 0.9, zIndex:"99999999", overflow: 'auto' }}*/}
                {/*            dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}*/}
                {/*            // onDropdownVisibleChange={(e)=>console.log(e)}*/}
                {/*            // placeholder={<i>请下拉选择</i>}*/}
                {/*            searchPlaceholder={translate("wps.Select participants")}*/}
                {/*            // treeLine*/}
                {/*            maxTagTextLength={10}*/}
                {/*            autoClearSearchValue*/}
                {/*            value={value}*/}
                {/*            treeData={gData}*/}
                {/*            treeNodeLabelProp="label"*/}
                {/*            treeNodeFilterProp="title"*/}
                {/*            treeCheckable*/}
                {/*            showCheckedStrategy={SHOW_CHILD}*/}
                {/*            onChange={onChange}*/}
                {/*            // onSelect={onSelect}*/}
                {/*            maxTagCount={10}*/}
                {/*            maxTagPlaceholder={valueList => {*/}
                {/*                // console.log('Max Tag Rest Value:', valueList);*/}
                {/*                return `${valueList.length} more...`;*/}
                {/*            }}*/}
                {/*            switcherIcon={(obj)=>obj.children.length > 0 && (obj.expanded ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>)}*/}
                {/*        />*/}
                {/*    }*/}
                {/*</Translate>*/}
                {/* region content */}

                <EditorInput
                    notFocused
                    name={"wp-description"}
                    value={formData.description}
                    style={{
                        maxHeight: fullscreen && "calc(100vh - 270px)",
                        overflowY: fullscreen && "scroll !important",
                    }}
                    uploadFileCallback={uploadFileCallback}
                    onChange={changeDescription}
                />

                <Typography variant={"subtitle2"} className={"mt-8"}>
                    {autoSaveLoading ? (
                        <Translate id={"wps.Saving..."} />
                    ) : autoSaved ? (
                        <span className={"text-green-dark flex items-center"}>
                            <Icon fontSize={"inherit"} className={"mr-2"}>
                                check
                            </Icon>{" "}
                            <Translate id={"wps.Content auto saved"} />
                            <a className={"text-red-light hover:text-red-dark ml-12 cursor-pointer"} onClick={removeSaveContent}>
                                <Icon fontSize={"small"} className={"align-middle"}>
                                    close
                                </Icon>{" "}
                                <Translate id={"_.remove"} />
                            </a>
                        </span>
                    ) : (
                        <span className={"text-gray-400"}>
                            {formData.description === "" ? null : (
                                <>
                                    <Translate id={"wps.Content not saved"} />
                                </>
                            )}
                        </span>
                    )}
                </Typography>

                {/* endregion */}
                <ListComponent className={"mt-20"} title={t("Checklists")} name={"checklists"} onChange={onChangeData("checklists")} />
            </DialogContent>
            <Details onChange={onChangeData} formData={formData} />
            <DialogActions className={"bg-gray-50 rounded-b-12"}>
                <SubmitButton data-cy={"wp-submit"} success disabled={formData.title.trim() === ""} loading={loading} onClick={onSubmit}>
                    <Translate id="_.save" />
                </SubmitButton>
            </DialogActions>
        </div>
    );
};

const WPWrapped = ({addTranslation}) => {
    const [fullscreen, setFullscreen] = useState(false);

    useEffect(() => {
        addTranslation(json);
    }, []);

    return (
        <DialogWrapper maxWidth="xl" fullScreen={fullscreen || isMobile} name={"wp"}>
            {() => <WPDialog fullscreen={fullscreen} setFullscreen={setFullscreen} />}
        </DialogWrapper>
    );
};

export default withLocalize(WPWrapped);
