import {useMemoizedFn} from "ahooks";
import {isString} from "lodash";

function useStringFunc() {
    const trimAll = useMemoizedFn((str) => {
        if (isString(str) && str !== "") {
            return str.split(/[\t\r\f\n\s]*/g).join("");
        }
        return "";
    });

    return {
        trimAll,
    };
}

export default useStringFunc;
