import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import List from "@material-ui/core/List";
import {MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import {Component} from "react";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import AsyncSelect from "react-select/async";
import {bindActionCreators} from "redux";
import DatePickerWrapper from "utils/DatePickerWrapper/DatePickerWrapper";
import Empty from "utils/Empty";
import {components} from "utils/Select2Components";

import withDialog from "utils/withDialog";

import FaveButton from "../../../../../components/layout/fav/FaveButton";
import axios, {crmPrefix, firmPrefix} from "../../../../../myaxios";
import {CompanySelector} from "../../marketingTools";
import PageItem from "../list/ItemView";

class SaleExpenseDialog extends Component {
    state = {
        loading: false,
        flows: [],
        paymentCompany: [],
        project: "",
        availableActivities: {},
        formData: {
            startDate: +new Date().setHours(0, 0, 0, 0),
            endDate: +new Date().setHours(23, 59, 59, 999),
            companyId: "",
            sum: 0,
            projectId: "",
            flowId: "",
            paymentCompanyId: "",
            note: ""
        }
    };

    changeProject = (value) => {
        if (value) {
            this.change("projectId")({target: {value: value.item.id}});
            this.setState({project: value});
        } else {
            this.change("projectId")({target: {value: ""}});
            this.setState({project: ""});
        }
    };

    searchProjects = (inputValue, callback) => {
        axios
        .get(`${firmPrefix}/myProjects?size=8`, {
            params: {
                keyword: inputValue
            }
        })
        .then((res) => {
            const projects = res.data.content.map((item) => ({
                value: item.id,
                item,
                label: (
                    <span className={"flex items-center"}>
                            <strong className={"mr-2"}>{item.name}</strong>
                        </span>
                )
            }));
            callback(projects);
        });
    };

    componentDidMount = () => {
        this.getFlows();
    };

    getAvailableActivities = (startDate, endDate, companyId) => {
        axios
        .get(`${crmPrefix}/activities`, {
            params: {
                startDate,
                toDate: endDate,
                companyId
            }
        })
        .then(({data}) => {
            this.setState({
                availableActivities: {
                    ...data
                }
            });
            if (!startDate && !endDate)
                this.setState((state) => ({
                    formData: {
                        ...state.formData,
                        startDate: +new Date(data.startDate),
                        endDate: +new Date(data.endDate)
                    }
                }));
        });
    };

    changeStartDate = (date) => {
        const {formData} = this.state;
        this.setState((state) => ({formData: {...state.formData, startDate: +new Date(date).setHours(0, 0, 0, 0)}}));
        this.getAvailableActivities(+new Date(date).setHours(0, 0, 0, 0), formData.endDate, formData.companyId);
    };

    changeEndDate = (date) => {
        const {formData} = this.state;
        this.setState((state) => ({formData: {...state.formData, endDate: +new Date(date).setHours(23, 59, 59, 999)}}));
        this.getAvailableActivities(formData.startDate, +new Date(date).setHours(23, 59, 59, 999), formData.companyId);
    };

    change = (name) => (event) => {
        const {formData} = this.state;
        this.setState({formData: {...formData, [name]: event.target.value}});
    };

    changeCompany = (name) => (event) => {
        const {formData} = this.state;
        this.change(name)(event);
        this.getAvailableActivities(formData.startDate, formData.endDate, event.target.value);
    };

    getFlows = () => {
        axios.get(`${firmPrefix}/myFlows`).then(({data}) => {
            this.setState({flows: data.flows, paymentCompany: data.paymentCompany});
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        axios
        .post(`${crmPrefix}/salesExpense`, {...this.state.formData})
        .then(() => {
            this.setState({loading: false});
            this.props.showMessage({
                message: "message.sales Expense Saved!",
                variant: "success"
            });
            this.closeDialog();
        })
        .catch(() => {
            this.setState({loading: false});
            this.closeDialog();
        });
    };

    closeDialog = () => {
        this.props.turnOff("salesExpense");
    };

    render() {
        const {formData, availableActivities, flows, paymentCompany, loading} = this.state;
        const labelContent = (
            <div className="flex flex-row items-center">
                {<Translate id={"market.Project"} />}
                <strong className="text-red mx-1">*</strong>
                {
                    <Translate>
                        {({translate}) => (
                            <MyTooltip
                                title={
                                    <div className="flex flex-col">
                                        <Typography variant="body2">{translate("market.Warm reminder:")}</Typography>
                                        <Typography variant="body1">
                                            {translate("market.You can use two keywords to search. It is recommended that the first keyword is item")}
                                        </Typography>
                                        <Typography className="text-center" variant="subtitle2">
                                            ({translate("market.eg:")} {translate("market.project name")} + {translate("market.space")}+{" "}
                                            {translate("market.customer name")})
                                        </Typography>
                                    </div>
                                }
                            >
                                <Icon fontSize={"small"} className="text-red ml-1">
                                    help
                                </Icon>
                            </MyTooltip>
                        )}
                    </Translate>
                }
            </div>
        );

        return (
            <div className={this.props.loading ? "loading  h-full" : "h-full"}>
                <DialogTitle id="alert-dialog-title" disableTypography classes={{root: "flex justify-between items-center px-24 py-8 rounded-t"}}>
                    <Typography variant={"h6"}>
                        <Translate id="market.sales Expense" />
                    </Typography>
                    <div>
                        <FaveButton colored className={"-mr-8"} sourceId={"tr"} title={"_.sales Expense"} type={"dialog"} />
                        {/*<IconButton className={'no-drag'} onClick={()=>this.props.minimize("salesExpense")}><Icon>remove</Icon></IconButton>*/}
                        <IconButton className={"no-drag"} onClick={this.closeDialog}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="pb-24 pt-0 overflow-visible max-w-full">
                    {/*---------------------- dates ----------------------*/}
                    <div className="flex items-end">
                        <CompanySelector customClassName={"mr-8  w-full"} formData={formData} handleFormData={this.changeCompany} />
                        <Translate>
                            {({translate}) => (
                                <DatePickerWrapper
                                    className="mr-8"
                                    disableToolbar
                                    fullWidth
                                    minDateMessage={translate("market.Date should not be before minimal date")}
                                    maxDateMessage={translate("market.Date should not be after maximal date")}
                                    required
                                    variant="inline"
                                    label={translate("market.start date")}
                                    autoOk
                                    value={formData.startDate}
                                    onChange={(date) => this.changeStartDate(date)}
                                />
                            )}
                        </Translate>
                        <Translate>
                            {({translate}) => (
                                <DatePickerWrapper
                                    required
                                    minDateMessage={translate("market.Date should not be before minimal date")}
                                    maxDateMessage={translate("market.Date should not be after maximal date")}
                                    fullWidth
                                    disableToolbar
                                    variant="inline"
                                    label={translate("market.end date")}
                                    autoOk
                                    disabled={!formData.startDate}
                                    value={formData.endDate}
                                    onChange={(date) => this.changeEndDate(date)}
                                />
                            )}
                        </Translate>
                    </div>

                    <div className="max-h-360 overflow-y-auto bg-grey-lighter mt-16">
                        {!availableActivities.content || availableActivities.content.length === 0 ? (
                            <Empty />
                        ) : (
                            <List dense>
                                {availableActivities.content.map((_, index) => (
                                    <PageItem item={_} small key={index} />
                                ))}
                            </List>
                        )}
                    </div>

                    <div className="flex mt-16">
                        <FormControl className="mr-8 flex-1">
                            <InputLabel id="Flow" required>
                                {<Translate id={"market.Flow"} />}
                            </InputLabel>
                            <Select required labelId="Flow" fullWidth value={formData.flowId} onChange={this.change("flowId")}>
                                {flows.map((item) => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={"flex-1"}>
                            <InputLabel id="Payment" required>
                                {<Translate id={"market.Payment company"} />}
                            </InputLabel>
                            <Select labelId="Payment" fullWidth required value={formData.paymentCompanyId} onChange={this.change("paymentCompanyId")}>
                                {paymentCompany.map((item) => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    {/*---------------------- days ----------------------*/}
                    <div className="flex mt-16 w-full items-end">
                        <TextField
                            disabled={!availableActivities.content}
                            min={1}
                            type={"number"}
                            required
                            className={"flex-1 mr-8"}
                            value={formData.sum}
                            label={<Translate id={"market.Total sum"} />}
                            onChange={this.change("sum")}
                        />
                        {/*---------------------- Project ----------------------*/}
                        <div className="flex-1">
                            <Translate>
                                {({translate}) => (
                                    <AsyncSelect
                                        textFieldProps={{
                                            label: labelContent,
                                            InputLabelProps: {
                                                shrink: true
                                            }
                                        }}
                                        required
                                        loadOptions={this.searchProjects}
                                        defaultOptions
                                        // styles={selectStyles}
                                        components={components}
                                        value={this.state.project}
                                        onChange={this.changeProject}
                                        placeholder={translate("market.Choose a project")}
                                        isClearable
                                    />
                                )}
                            </Translate>
                        </div>
                    </div>

                    {/*---------------------- note ----------------------*/}
                    <TextField
                        className={"mt-16"}
                        fullWidth
                        value={formData.note}
                        label={<Translate id={"market.Note"} />}
                        onChange={this.change("note")}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.closeDialog}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button
                        disabled={
                            loading ||
                            formData.projectId === "" ||
                            formData.flowId === "" ||
                            formData.paymentCompanyId === "" ||
                            !availableActivities.content
                        }
                        color="secondary"
                        onClick={this.onSubmit}
                    >
                        <Translate id="_.save" />
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: fuseActions.turnOn,
            turnOff: fuseActions.turnOff,
            minimize: fuseActions.minimize,
            showMessage: fuseActions.showMessage
        },
        dispatch
    );
}

function mapStateToProps({marketing}) {
    return {
        data: marketing.activities.list.data
    };
}

export default withDialog("salesExpense", "sm", true)(connect(mapStateToProps, mapDispatchToProps)(SaleExpenseDialog));
