import { turnOff, showMessage, replaceParams } from "@fuseActions";
import {Button, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TextField, Typography, withMobileDialog} from "@material-ui/core";
import { stringify } from "qs";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";

import withDialog from "utils/withDialog";
import axios, {crmPrefix} from "../../../../../myaxios";
import transJson from "../../translates/translate.json";
import { getList, getItem } from "../store/actions";

class ArchiveDialog extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(transJson);
    }

    state = {
        loading: false,
        users: [],
        name: "",
        key: "",
        preArchives: [],
        anchorEl: null,
        open: false,
    };

    handleFormData = (name) => (event) => {
        const formData = {...this.state};
        formData[name] = event.target.value;
        this.setState({
            formData,
        });
    };

    handleKey = (e) => {
        this.setState({
            key: e.target.value,
        });
    };

    handleName = (e) => {
        this.setState({
            name: e.target.value,
        });
    };

    componentDidMount = () => {
        const {selectedItem} = this.props;
        if (selectedItem) {
            this.setData(selectedItem);
        }
    };

    componentWillUnmount = () => {
        this.clearData();
    };

    setData = (selectedItem) => {
        this.setState({
            formData: {
                ...selectedItem,
            },
        });
    };

    clearData = () => {
        this.setState({
            loading: false,
            users: [],
            formData: {
                name: "",
                key: "",
            },
        });
    };

    filterData = () => {
        const search = {...this.props.search};
        this.props.getList(search);
        delete search.keyword;
        localStorage.setItem("meetingFilter", stringify({...search}));
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {search, showMessage, getItem, match, item, replaceParams} = this.props;
        this.setState({loading: true});
        let formData = {
            key: this.state.key,
            name: this.state.name,
        };
        let request = axios.post(`${crmPrefix}/archive`, formData);
        request
            .then((res) => {
                this.setState({loading: false});
                showMessage({
                    message: "message.Archive Saved!",
                    variant: "success",
                });

                replaceParams({...match.params, size: search.size}).then(() => this.filterData());
                this.closeDialog();
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    closeDialog = () => {
        this.clearData();
        this.props.turnOff("archive");
    };
    //
    //    getArchives = () => {
    //        axios
    //            .get(`${crmPrefix}/archives`, {
    //                params: {
    //                    size: 5,
    //                },
    //            })
    //            .then((res) => {
    //                let data = res.data.content;
    //                // setPreArchives(data);
    //                this.setState({
    //                    preArchives: data,
    //                });
    //            });
    //    };
    //
    //    handleArchiveFocus = (e) => {
    //        // console.log('handleArchiveFocus', e);
    //
    //        this.setState((preState) => {
    //            return {
    //                anchorEl: e.currentTarget,
    //                open: !preState.open,
    //            };
    //        });
    //    };

    render() {
        const {loading, name, key} = this.state;

        const disableSubmit = name.trim() === "" || key.trim() === "";

        return (
            <div className={loading ? "loading" : null}>
                <DialogTitle
                    id="alert-dialog-title"
                    disableTypography
                    classes={{root: "drag flex justify-between items-center px-24 py-8 rounded-t"}}>
                    <>
                        <Typography variant={"h6"}>
                            <span className={"font-normal"}>
                                <Translate id="market.New archive" />
                            </span>
                        </Typography>
                    </>
                    <div>
                        {/*{!selectedItem && <FaveButton colored className={"-mr-8"} sourceId={"meeting"} title={"_.meeting"} type={"dialog"} />}*/}
                        <IconButton className={"no-drag"} onClick={this.closeDialog}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={"overflow-visible"}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <Translate>
                                {({translate}) => (
                                    <TextField
                                        required
                                        fullWidth
                                        type={"password"}
                                        label={translate("market.key")}
                                        value={key}
                                        onChange={this.handleKey}
                                    />
                                )}
                            </Translate>
                        </Grid>

                        <Grid item sm={6}>
                            <>
                                <TextField
                                    label={<Translate id={"market.archive name"} />}
                                    value={this.state.name}
                                    fullWidth
                                    // aria-describedby={id}
                                    onChange={this.handleName}
                                />
                            </>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="default" onClick={this.closeDialog}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button color="secondary" variant={"contained"} disabled={disableSubmit} onClick={this.onSubmit}>
                        <Translate id="_.save" />
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: turnOff,
            showMessage: showMessage,
            replaceParams: replaceParams,
            getList: getList,
            getItem: getItem,
        },
        dispatch
    );
}

function mapStateToProps({marketing, fuse, user}) {
    return {
        selectedItem: marketing.archives.list.selected,
        item: marketing.archives.item,
        search: fuse.search,
    };
}

export default withDialog("archive")(
    withRouter(
        withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ArchiveDialog)))
    )
);
