import {Fab, Snackbar} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import _ from "lodash";
import {forwardRef, useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import {useGlobalDialog} from "../../../../../@fuse/hooks";
import {FULL_SCREEN_IMAGE_DIALOG} from "../../utils/constent";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    preFab: {
        position: "absolute",
        bottom: theme.spacing(2),
        left: theme.spacing(2),
    },
    nextFab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    containerAnchorOriginBottomLeft: {
        zIndex: 999999999,
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FullImageDialog() {
    const classes = useStyles();
    const [isNext, setIsNext] = useState(false);
    const [snackOptions, setSnackOptions] = useState({
        open: false,
        message: "",
    });
    const [checkIns, setCheckIns] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [src, setSrc] = useState("");
    const {closeDialog} = useGlobalDialog();
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const selectedCheckinParams = useSelector(({externalM}) => externalM.checkin_Results.selectedCheckinParams);

    const language = localStorage.getItem("i18nextLng");
    const hasWindow = typeof window !== "undefined";
    const windowWidth = hasWindow ? window.innerWidth : 0;
    const windowHeight = hasWindow ? window.innerHeight : 0;

    useEffect(() => {
        setSnackOptions({
            ...snackOptions,
            open: false,
        });
        return function cleanup() {
            setSnackOptions({
                ...snackOptions,
                open: false,
            });
        };
    }, []);

    useEffect(() => {
        if (selectedCheckinParams) {
            setCheckIns(selectedCheckinParams.checkIns);
            setCurrentIndex(selectedCheckinParams.currentIndex);
            // updateImage();
        }
    }, [selectedCheckinParams]);

    useEffect(() => {
        updateImage();
    }, [currentIndex, windowHeight, windowWidth, checkIns]);

    const handleClose = () => {
        closeDialog(FULL_SCREEN_IMAGE_DIALOG);
    };

    const disAbleNext = () => {
        return currentIndex === checkIns.length - 1;
    };

    const disAblePrevious = () => {
        return currentIndex === 0;
    };

    const updateImage = () => {
        const tw = _.toInteger(windowWidth * 2);
        const th = _.toInteger(windowHeight * 2);
        const photo = checkIns[currentIndex] ? checkIns[currentIndex].photo : "";
        const tSrc = `${photo}/resize/${tw}x${th}`;
        setSrc(tSrc);
    };

    const handleNext = useMemoizedFn(() => {
        if (currentIndex === checkIns.length - 1) {
            return;
        }
        showLastTips();
        setCurrentIndex((prevState) => prevState + 1);
    });

    const handlePrevious = useMemoizedFn(() => {
        if (currentIndex === 0) {
            return;
        }
        showFirstTips();
        setCurrentIndex((prevState) => prevState - 1);
    });

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackOptions({
            ...snackOptions,
            open: false,
        });
    };

    const showLastTips = useMemoizedFn(() => {
        if (currentIndex === checkIns.length - 2) {
            const message = language === "cn" ? "这是最后一张照片,请往前查看" : "This is the last photo, please go ahead to view";
            setSnackOptions({
                ...snackOptions,
                open: true,
                message: message,
            });
        }
    });

    const showFirstTips = useMemoizedFn(() => {
        if (currentIndex === 1) {
            const message = language === "cn" ? "这是第一张照片,请往后查看" : "This is the first photo, please check back";
            setSnackOptions({
                ...snackOptions,
                open: true,
                message: message,
            });
        }
    });

    return (
        <Dialog fullScreen scroll={"body"} open={!!dialogs[FULL_SCREEN_IMAGE_DIALOG]} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    {/*<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">*/}
                    {/*    <CloseIcon />*/}
                    {/*</IconButton>*/}
                    <Typography variant="h6" className={classes.title}>
                        {<Translate id={"exm.large image"} />}({currentIndex + 1}/{checkIns.length})
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Card>
                <CardContent>
                    <div className="p-7 m-3">
                        <div className="flex justify-center items-center">
                            <img className="w-auto h-auto  max-w-full object-contain" src={src} />
                        </div>
                        {checkIns.length > 1 && (
                            <Fab className={classes.preFab} color={disAblePrevious() ? "" : "secondary"}>
                                <MyTooltip title={<Translate id={"exm.Previous image"} />}>
                                    <IconButton
                                        disabled={disAblePrevious()}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsNext(false);
                                            handlePrevious();
                                        }}>
                                        <Icon fontSize="large">swipe_left</Icon>
                                    </IconButton>
                                </MyTooltip>
                            </Fab>
                        )}
                        {checkIns.length > 1 && (
                            <Fab className={classes.nextFab} color={disAbleNext() ? "" : "secondary"}>
                                <MyTooltip title={<Translate id={"exm.Next image"} />}>
                                    <IconButton
                                        disabled={disAbleNext()}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsNext(true);
                                            handleNext();
                                        }}>
                                        <Icon fontSize="large">swipe_right</Icon>
                                    </IconButton>
                                </MyTooltip>
                            </Fab>
                        )}
                    </div>
                    <Snackbar
                        autoHideDuration={3000}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        open={snackOptions.open}
                        onClose={handleCloseSnack}>
                        <Alert onClose={handleCloseSnack} severity="warning">
                            {snackOptions.message}
                        </Alert>
                    </Snackbar>
                </CardContent>
            </Card>
        </Dialog>
    );
}
