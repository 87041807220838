// @ts-nocheck
import {setFullImage, turnOn} from "@fuseActions";
import parse, {domToReact} from "html-react-parser";
import {useDispatch} from "react-redux";
import sanitizeHtml from "sanitize-html";
import {DataGrid} from "./DataGrid";
import {LazyLoader} from "./LazyLoader";

export const ParseHTML = ({html, ...rest}) => {
    //    console.log(html);
    const dispatch = useDispatch();
    const reg = /\/resize\/*.*/i;

    // console.log("html", html);
    //    console.log(
    //        sanitizeHtml(html, {
    //            allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "font"]),
    //        })
    //    );
    // console.log(parse(html));
    return (
        <div className={"overflow-x-auto editor-input"} {...rest} suppressContentEditableWarning={true}>
            {html &&
                parse(html, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
                    replace: (domNode) => {
                        if (domNode?.attribs?.disableshowinparse === "true") {
                            return <></>;
                        }
                        // console.log("sanitized:", sanitizeHtml(html, {
                        //     allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ])
                        // }));
                        // console.log("html:", html);
                        if (domNode.name === "img") {
                            return (
                                <button
                                    className={"inline-block mr-2"}
                                    onClick={() => {
                                        dispatch(setFullImage("", domNode.attribs.src.replace(reg, "")));
                                        dispatch(turnOn("fullImage"));
                                    }}>
                                    <img src={domNode.attribs.src} alt={domNode.name} />
                                </button>
                            );
                        }
                        if (domNode.name === "datagrid") {
                            return (
                                <LazyLoader>
                                    <DataGrid id={domNode.attribs.id} key={domNode.attribs.id} />
                                </LazyLoader>
                            );
                        }
                        if (domNode.name === "a") {
                            return (
                                <a target={"_blank"} className={"text-blue-light underline"} href={domNode.attribs.href}>
                                    {domToReact(domNode.children)}
                                </a>
                            );
                        }
                    },
                })}
        </div>
    );
};
