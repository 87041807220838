// noinspection JSDeprecatedSymbols
import {FuseSuspense} from "@fuse";
import {setCache, turnOff, turnOn} from "@fuseActions";
import {Button, Icon, IconButton, Paper, Popover, TextField} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";
import {CreatableDataGrid, DialogWrapper, MyTooltip, ParseHTML, SubmitButton} from "@ui";
import classNames from "classnames";
import clsx from "clsx";
import type {ReactElement} from "react";
import {useCallback, useEffect, useId, useRef, useState} from "react";
import type {ContentEditableEvent} from "react-contenteditable";
import ContentEditable from "react-contenteditable";
import {useTranslation} from "react-i18next";
import type {LocalizeContextProps} from "react-localize-redux";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import type {AttachmentType, dataType, StaffType, userType} from "types";
import {v1 as uuidv1} from "uuid/dist/esm-node";
import {saveFile, uploadFiles} from "../fileUploader";
import {isImage, retrieveImageFromClipboardAsBlob, validURL} from "../utilities";
import CameraDialog from "./CameraDialog";
import MentionUsers from "./MentionUsers";

function placeCaretAtEnd(el) {
    el.focus();
    if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        // @ts-ignore
        sel.removeAllRanges();
        // @ts-ignore
        sel.addRange(range);
    } else {
        // @ts-ignore
        if (typeof document.body.createTextRange != "undefined") {
            // @ts-ignore
            const textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    }
}

const useStyles = makeStyles(() => ({
    editable: {
        border: "1px solid #dee2e6",
        borderTop: 0,
        "&:focus": {
            borderBottom: "2px solid #666",
        },
    },
}));

function EditButton(props) {
    return (
        <MyTooltip title={props.name}>
            <IconButton
                className="mr-1 w-24 h-24"
                {...props}
                key={props.cmd}
                onMouseDown={(evt) => {
                    evt.preventDefault(); // Avoids loosing focus from the editable area
                    document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
                }}>
                {props.icon ? <Icon fontSize={"small"}>{props.icon}</Icon> : <small className={"font-bold uppercase"}>{props.arg}</small>}
            </IconButton>
        </MyTooltip>
    );
}

type Props = {
    /**
     * For when user uploads and we want to add it somewhere else
     * @param {AttachmentType[]} arg
     */
    uploadFileCallback?: (arg: AttachmentType[]) => void;
    disableMention?: boolean;
    /**
     * Default mention users
     */
    mentionUsers?: userType[];
    value?: string;
    notFocused?: boolean;
    rows?: "96" | "106" | "128" | "160" | "192" | "200" | "224" | "256" | "288" | "320" | "360" | "400" | "512";
    placeholder?: string | ReactElement;
    onSubmit?: (e?: any) => void;
    onKeyDown?: () => void;
    onChange: (e: ContentEditableEvent) => void;
    showToolbar?: boolean;
    getUsersReq?: Promise<{data: dataType<StaffType>}>;
    style?: any;
    name: string;
    loading?: boolean;
};

export const sanitizeFromEditable = (str) => {
    return str.replaceAll('contenteditable="false"', "").replaceAll('contenteditable="true"', "");
};
const EditorInput = ({
    uploadFileCallback,
    disableMention,
    mentionUsers,
    value,
    onSubmit,
    style,
    onKeyDown,
    onChange,
    rows = "160",
    notFocused,
    name,
    loading,
    getUsersReq,
}: Props & LocalizeContextProps) => {
    const {t} = useTranslation("datagrid");
    const dispatch = useDispatch();

    const mainInputRef = useRef<HTMLElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);

    const [fileLoading, setFileLoading] = useState(false);
    const [openMention, setOpenMention] = useState(false);
    const [code, setCode] = useState(false);

    const [linkTitle, setLinkTitle] = useState("");
    const [linkHref, setLinkHref] = useState("");
    const [openLink, setOpenLink] = useState<any>(null);
    const [hrefError, setHrefError] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        if (!notFocused && mainInputRef.current) {
            placeCaretAtEnd(mainInputRef.current);
            // mainInputRef?.current?.focus();
        }
        window.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("paste", handlePaste);
        };
    }, []);

    const keyPressHandle = useCallback(
        (e) => {
            // if (e.keyCode === 13) {
            //     // insert 2 br tags (if only one br tag is inserted the cursor won't go to the next line)
            //     document.execCommand('insertHTML', false, '\n <br /><br />');
            //     // prevent the default behaviour of return key pressed
            //     // return false;
            //     e.preventDefault()
            // }
            // console.log("value form editor", value);
            if (document.activeElement === mainInputRef.current) {
                if (value?.trim() === "" && e.key === "Backspace") {
                    setOpenMention(false);
                }
                if (e.key === "Enter" && e.ctrlKey) {
                    if (typeof onSubmit === "function") {
                        onSubmit(e);
                    }
                } else if (e.keyCode === 50 && e.shiftKey) {
                    onOpenMention();
                }
            }
        },
        [value]
    );

    useEffect(() => {
        window.addEventListener("keydown", keyPressHandle);
        return () => {
            window.removeEventListener("keydown", keyPressHandle);
        };
    }, [keyPressHandle]);

    const uploadAttachment = (e) => {
        setFileLoading(true);
        uploadFiles(e.target.files, (status, data) => {
            if (status) {
                if (typeof uploadFileCallback === "function") uploadFileCallback(data);
                data.forEach((file) => {
                    addFileToText(file);
                });
            } else {
                setFileLoading(false);
            }
        });
    };

    const addFileToText = (file) => {
        if (mainInputRef.current) {
            mainInputRef.current.focus();
            if (isImage(file.visitUrl)) {
                document.execCommand("insertHTML", false, `&nbsp; <img class="inline-block mr-2" src="${file.visitUrl + "/resize/200x200"}"/>`);
            } else {
                document.execCommand(
                    "insertHTML",
                    false,
                    `&nbsp; <a class="text-blue-light underline" target="_blank" id="${file.id}" download="${file.originalName}" href="${file.visitUrl}?name=${file.originalName}">${file.originalName}</a>`
                );
            }
            setFileLoading(false);
        }
    };

    const onAddTable = (id: string, title: string) => {
        // console.log(id, title, "onAddTable");
        dispatch(turnOff("datagrid"));
        if (mainInputRef.current) {
            mainInputRef.current.focus();
            placeCaretAtEnd(mainInputRef.current);
            document.execCommand(
                "insertHTML",
                false,
                `&nbsp; <p disableshowinparse="true" class="bg-gray-100 border-1 py-2 px-4 rounded-md text-xl">${t(
                    "datagrid"
                )} <strong>${title}</strong></p> <datagrid id="${id}"></datagrid>`
            );
        }
    };

    const insertLink = () => {
        if (mainInputRef.current) {
            placeCaretAtEnd(mainInputRef.current);

            // setTimeout(() => {
            //     pasteHtmlAtCaret(
            //         `<a class="text-blue-light underline" target="_blank" href="${linkHref}">${linkTitle}</a>&nbsp;`
            //     );
            // }, 100);
            document.execCommand(
                "insertHTML",
                false,
                `&nbsp; <a class="text-blue-light underline" target="_blank" href="${linkHref}">${linkTitle}</a>`
            );
            setOpenLink(null);
            setLinkTitle("");
            setLinkHref("");
        }
    };

    const handlePaste = (e) => {
        // Handle the event
        if (!fileLoading)
            retrieveImageFromClipboardAsBlob(e, (imageBlob) => {
                // If there's an image, display it in the canvas
                if (imageBlob) {
                    e.preventDefault();
                    // const URLObj = window.URL || window.webkitURL;

                    // setImage(URLObj.createObjectURL(imageBlob));
                    setFileLoading(true);
                    // setAttachmentMenuEl(undefined);
                    saveFile(imageBlob, (status, data) => {
                        if (status) {
                            addFileToText(data);
                            if (typeof uploadFileCallback === "function") uploadFileCallback([data]);
                        }
                    });
                }
            });
    };

    const changeColor = (color) => (e) => {
        e.preventDefault();
        if (mainInputRef.current) {
            mainInputRef.current.focus();
            setTimeout(() => {
                document.execCommand("foreColor", false, color);
            }, 100);
        }
    };

    const onOpenMention = () => {
        setOpenMention(true);
    };

    return (
        <div className={classNames(fileLoading && "loading", " border-t-1")}>
            <DialogWrapper header={"Add Datagrid"} name={"datagrid"} maxWidth={"xl"}>
                <div className="pb-16">
                    <CreatableDataGrid onSave={(id, title) => onAddTable(id, title)} />
                </div>
            </DialogWrapper>

            <CameraDialog addImageToText={addFileToText} />

            {/* region popper for link */}
            <Popover onClose={() => setOpenLink(null)} open={Boolean(openLink)} anchorEl={openLink} elevation={4}>
                <Paper className={"max-w-256 text-base p-8"}>
                    <TextField
                        margin={"dense"}
                        onChange={(e) => {
                            setLinkTitle(e.target.value);
                        }}
                        placeholder={"Title"}
                        value={linkTitle}
                    />
                    <TextField
                        margin={"dense"}
                        value={linkHref}
                        placeholder={"http://"}
                        onChange={(e) => {
                            if (validURL(e.target.value)) setHrefError(false);
                            else setHrefError(true);
                            setLinkHref(e.target.value);
                        }}
                        error={hrefError}
                        helperText={hrefError && "Not a link"}
                    />

                    <Button
                        disabled={linkHref.trim() === "" || linkTitle.trim() === "" || hrefError}
                        onClick={insertLink}
                        className={"mt-4"}
                        variant={"contained"}
                        color={"primary"}>
                        Insert
                    </Button>
                </Paper>
            </Popover>
            {/* endregion */}

            {/* region Toolbar */}
            <div className={"flex justify-between   border-r-1 border-l-1 bg-grey-lightest"}>
                <div className="flex p-2 flex-wrap opacity/75">
                    <EditButton icon={"undo"} cmd={"undo"} name={<Translate id={"_.undo"} />} />
                    {!disableMention && (
                        <IconButton className="mr-1 w-24 h-24" onClick={onOpenMention}>
                            @
                        </IconButton>
                    )}
                    <EditButton icon={"format_bold"} cmd={"bold"} name={<Translate id={"_.bold"} />} />
                    <EditButton icon={"format_italic"} cmd={"italic"} name={<Translate id={"_.italic"} />} />
                    {/*<EditButton icon={"format_underlined"} cmd="underline" arg="h2" name={<Translate  id={"_.heading"}/>} />*/}
                    <EditButton cmd="formatBlock" arg="h2" name={<Translate id={"_.H2"} />} />
                    <EditButton cmd="formatBlock" arg="h3" name={<Translate id={"_.H3"} />} />
                    <EditButton cmd="formatBlock" arg="p" name={<Translate id={"_.P"} />} />

                    <EditButton cmd="insertOrderedList" icon="format_list_numbered" name={<Translate id={"_.insertUnorderedList"} />} />
                    <EditButton icon={"border_bottom"} cmd="insertHorizontalRule" name={<Translate id={"_.line"} />} />

                    <MyTooltip title={<Translate id={"_.insert link"} />}>
                        <IconButton
                            className={"w-24 h-24"}
                            onClick={(e) => setOpenLink(e.currentTarget)}
                            // onMouseDown={(evt) => {
                            //     evt.preventDefault();
                            //     const href = window.getSelection()?.toString();
                            //     if (validURL(href)) document.execCommand("createLink", false, href);
                            // }}
                        >
                            <Icon fontSize={"small"}>link</Icon>
                        </IconButton>
                    </MyTooltip>

                    <MyTooltip title={<Translate id={"_.Open camera"} />}>
                        <IconButton className={"w-24 h-24"} onClick={() => dispatch(turnOn("cameraDialog"))}>
                            <Icon fontSize={"small"}>photo_camera</Icon>
                        </IconButton>
                    </MyTooltip>

                    <input type="file" ref={fileRef} onChange={uploadAttachment} multiple className="hidden" />
                    <MyTooltip title={<Translate id={"_.Upload an attachment"} />}>
                        <IconButton onClick={() => fileRef?.current?.click()} className={"w-24 h-24"}>
                            <Icon fontSize={"small"}>attach_file</Icon>
                        </IconButton>
                    </MyTooltip>

                    <MyTooltip title={<Translate id={"_.Data Table"} />}>
                        <IconButton onClick={() => dispatch(turnOn("datagrid"))} className={"w-24 h-24"}>
                            <Icon fontSize={"small"}>table_chart</Icon>
                        </IconButton>
                    </MyTooltip>

                    <button style={{backgroundColor: "#333333"}} onClick={changeColor("#333333")} className="w-20 h-20 rounded-full ml-2 mt-2 mr-2" />
                    <button style={{backgroundColor: "#999999"}} onClick={changeColor("#999999")} className="w-20 h-20 rounded-full mt-2 mr-2" />
                    <button style={{backgroundColor: "#d54141"}} onClick={changeColor("#d54141")} className="w-20 h-20 rounded-full mt-2 mr-2" />
                    <button style={{backgroundColor: "#339ed7"}} onClick={changeColor("#339ed7")} className="w-20 h-20 rounded-full mt-2 mr-2" />
                    <IconButton className={clsx("w-24 h-24", code ? "opacity-100" : "opacity-60")} onClick={() => setCode(!code)}>
                        <Icon fontSize={"small"}>developer_mode</Icon>
                    </IconButton>
                </div>
                {!!onSubmit && (
                    <div className={"flex items-center"}>
                        <span className={"text-blue-light mr-8"}>
                            <Translate id={"_.Ctrl + Enter to submit"} />
                        </span>
                        <SubmitButton
                            data-cy={"submit-" + name}
                            disabled={value?.trim() === "" || loading}
                            className={" mt-1 mr-2"}
                            size={"small"}
                            color={"primary"}
                            loading={loading}
                            onClick={onSubmit}>
                            <Translate id={"_.Send"} />
                        </SubmitButton>
                    </div>
                )}
            </div>
            {/* endregion*/}

            {/* region Mention */}
            {!disableMention && openMention && (
                <MentionUsers
                    mentionUsers={mentionUsers}
                    getUsersReq={getUsersReq}
                    mainInputRef={mainInputRef}
                    closeMentionMenu={() => {
                        setOpenMention(false);
                        mainInputRef.current && mainInputRef.current.focus();
                    }}
                />
            )}
            {/* endregion*/}

            {code ? (
                <TextField variant={"outlined"} className={"bg-gray-50"} onChange={onChange} value={value} fullWidth multiline minRows={5} />
            ) : (
                <ContentEditable
                    id={name}
                    data-cy={name}
                    style={style}
                    onKeyDown={onKeyDown || keyPressHandle}
                    className={classNames(classes.editable, "min-h-" + rows, " p-12  text-16 overflow-y-auto bg-white editor-input")}
                    html={value || ""}
                    innerRef={mainInputRef}
                    onChange={onChange}
                />
            )}
        </div>
    );
};

const EditorWrapper = (props: Props & LocalizeContextProps) => {
    const [thisId, setThisId] = useState();
    const id = useId();
    const dispatch = useDispatch();
    const currentEditor = useSelector(({fuse}) => fuse.dialogs["currentEditor"]);
    useEffect(() => {
        if (!props.notFocused) {
            const t = uuidv1();
            setThisId(t);
            dispatch(setCache("currentEditor", t));
        }
        return () => {
            if (currentEditor === thisId) dispatch(turnOff("currentEditor"));
        };
    }, []);
    return (
        <FuseSuspense>
            {thisId === currentEditor ? (
                <EditorInput key={id} {...props} />
            ) : (
                <div
                    onClick={() => {
                        dispatch(setCache("currentEditor", thisId));
                    }}
                    className="border rounded-8 p-20 bg-white cursor-text">
                    {props.value ? (
                        <div className={"opacity-60 flex items-start"}>
                            <div className="flex-grow">
                                <ParseHTML data-cy={props.name} html={props.value} />
                            </div>
                            <Alert severity={"warning"} color={"warning"}>
                                {<Translate id={"_.You have an editor open here"} />}
                            </Alert>
                        </div>
                    ) : (
                        <FormLabel>{props.placeholder || <Translate id={"_.Write a description..."} />}</FormLabel>
                    )}
                </div>
            )}
        </FuseSuspense>
    );
};

export default withLocalize(EditorWrapper);
