import { SHOW_PROGRESS, ADD_PROGRESS, CLOSE_PROGRESS } from "@fuseActions";

const initialState = {
    state  : null,
    options: {
        anchorOrigin    : {
            vertical  : "bottom",
            horizontal: "left"
        },
        autoHideDuration: false,
    },
    files: []
};

const progress = function (state = initialState, action) {
    switch ( action.type )
    {
        case SHOW_PROGRESS:
        {
            return {
                ...state,
                state  : true,
            };
        }
        case ADD_PROGRESS:
        {
            let files =  [...state.files];
            if(files.findIndex(i=>i.id === action.file.id) > -1) {
                files[files.findIndex(i=>i.id === action.file.id)] = action.file;
            } else {
                files= [...state.files, action.file];
            }
            return {
                ...state,
                files
            };
        }
        case CLOSE_PROGRESS:
        {
            return initialState;
        }
        default:
        {
            return state;
        }
    }
};

export default progress;
