import {memo} from "react";
import {FuseNavigation} from "@fuse";
import clsx from "clsx";
import {shallowEqual, useSelector} from "react-redux";

function Navigation(props)
{
    const {layout = "vertical"} = props;
    const navigation = useSelector(({fuse}) => fuse.navigation, shallowEqual);

    return (
        <FuseNavigation className={clsx("navigation p-12", props.className)} navigation={navigation} layout={layout} dense={props.dense}/>
    );
}

// Navigation.defaultProps = {
//     layout: "vertical"
// };

export default memo(Navigation);
