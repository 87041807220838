import {AppBar, Hidden, Toolbar} from "@material-ui/core";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import NavbarMobileToggleButton from "app/layouts/shared-components/NavbarMobileToggleButton";
import clsx from "clsx";
import {memo} from "react";
import {shallowEqual, useSelector} from "react-redux";
import UserMenu from "../../../../components/layout/UserMenu";
import FavoriteButton from "../../shared-components/FavoriteButton";
import Logo from "../../shared-components/Logo";
import OldSystemButton from "../../shared-components/OldSystemButton";
import QuickPanelToggleButton from "../../shared-components/QuickPanelToggleButton";

const useStyles = makeStyles((theme) => ({
    separator: {
        width: 1,
        height: 40,
        backgroundColor: theme.palette.divider,
    },
}));

function ToolbarLayout3(props) {
    const config = useSelector(({fuse}) => fuse.settings.current.layout.config, shallowEqual);
    const toolbarTheme = useSelector(({fuse}) => fuse.settings.toolbarTheme, shallowEqual);

    const classes = useStyles(props);

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar id="fuse-toolbar" className="flex relative z-10" color="default">
                {/*{!fromApp &&*/}
                <Toolbar classes={{root: "min-h-40"}} className="drag p-0 lg:pl-24">
                    {config.navbar.display && (
                        <Hidden lgUp>
                            <NavbarMobileToggleButton className="w-52 p-0" />
                        </Hidden>
                    )}

                    {/*{isElectron() && window.remote.getCurrentWindow().webContents.canGoBack() && (*/}
                    {/*    <IconButton*/}
                    {/*        onClick={() => window.remote.getCurrentWindow().webContents.goBack()}*/}
                    {/*        className="no-drag w-52 h-52"*/}
                    {/*    >*/}
                    {/*        <Icon>arrow_back</Icon>*/}
                    {/*    </IconButton>*/}
                    {/*)}*/}

                    <Hidden mdDown>
                        <div className={clsx("flex flex-shrink-0 items-center ml-4")}>
                            <Logo />
                        </div>
                    </Hidden>

                    <div className="flex flex-1 justify-end md:justify-start">
                        <FavoriteButton />
                        <div className={classes.separator + " hidden md:block"} />
                        <OldSystemButton />
                        {/*<div className={classes.separator + " hidden md:block"} />*/}
                        {/*<AddButton />*/}
                    </div>
                    {/*<div className="flex flex-1">*/}
                    {/*    <Hidden xsDown>*/}
                    {/*        <FuseSearch className="mx-16 lg:mx-24" variant="basic"/>*/}
                    {/*    </Hidden>*/}
                    {/*</div>*/}

                    <div className="hidden md:flex">
                        <UserMenu />
                        {/*<Hidden smUp>*/}
                        {/*    <FuseSearch/>*/}
                        {/*    <div className={classes.separator}/>*/}
                        {/*</Hidden>*/}

                        <Hidden mdDown>
                            <div className={classes.separator} />
                        </Hidden>
                        <QuickPanelToggleButton />
                        {/*{isElectron() && window.remote !== undefined && <ElectronToolbarButtons />}*/}
                    </div>
                </Toolbar>
                {/*}*/}
            </AppBar>

            {/*<MobileBottomToolbar />*/}
        </ThemeProvider>
    );
}

export default memo(ToolbarLayout3);
