import {lazy} from "react";

export const ArchiveConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/archive",
            component: lazy(() => import("./Page")),
        },
    ],
};
