import {useEffect, useMemo} from "react";
import {useDropzone} from "react-dropzone";
import {Typography} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import Button from "@material-ui/core/Button";
import SRXCard from "../componenets/SRXCard";
import useLXExcelReader from "../state/useLXExcelReader";
import useSpreadsheetUtil from "../../hooks/useSpreadsheetUtil";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

function UploadfileView() {
    const {acceptedFiles, open, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} = useDropzone({
        maxFiles: 1,
        noClick: true,
        noKeyboard: true,
        accept: {
            "application/vnd.ms-excel": [".xls", ".slk", ".xla", ".xlt", ".xlw"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "text/csv": [".csv"],
            "text/xml": [".xml"],
            "application/vnd.ms-excel.sheet.binary.macroenabled.12": [".xlsb"],
            "application/vnd.ms-excel.sheet.macroenabled.12": [".xlsm"],
            "application/vnd.ms-excel.template.macroenabled.12": [".xltm"],
            "application/vnd.ms-excel.addin.macroenabled.12": [".xlam"],
        },
    });

    // @ts-ignore
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const {onChangeOriginalExcelFile} = useLXExcelReader();

    const {onParse} = useSpreadsheetUtil();

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            console.debug("*********[UploadfileView] > [useEffect] > [acceptedFiles] : ", acceptedFiles);
            handleParseFile(acceptedFiles);
        }
    }, [acceptedFiles]);

    const handleParseFile = (files) => {
        const currentFile = files[0];
        onChangeOriginalExcelFile(currentFile).then(() => {
            parseExcelToLXExcelDatasource(currentFile);
        });
    };

    const parseExcelToLXExcelDatasource = (file) => {
        onParse(file).then((wb) => {
            console.debug("****** > [UploadfileView] > [parseExcelToLXExcelDatasource] > wb:", wb);
        });
    };

    // @ts-ignore
    return (
        <>
            <SRXCard>
                <div
                    // @ts-ignore
                    {...getRootProps({style})}
                >
                    <input {...getInputProps()} />
                    <div className="flex flex-col justify-center items-center">
                        <Typography variant={"h6"} className="text-black text-center my-5">
                            {<Translate id={"_.Drag and drop the file or click the button below to get the file"} />}
                        </Typography>
                        <Button variant="contained" color="secondary" onClick={open}>
                            {<Translate id={"_.import excel"} />}
                        </Button>
                    </div>
                </div>
            </SRXCard>
        </>
    );
}

export default UploadfileView;
