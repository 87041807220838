import {lazy, useEffect, useState} from "react";
import {Paper, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {ThemeProvider, withTheme} from "@material-ui/styles";
import {EmptyView, SubmitButton} from "@ui";
import {showMessage, turnOff} from "@fuseActions";
import axios, {userPrefix} from "myaxios";

import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import DashboardApps from "./widgets/DashboardApps";
import Favorites from "./widgets/Favorites";
import SavedSearch from "./widgets/SavedSearch";
import WorkplansWidget from "./widgets/WorkplansWidget";

const UsersLocation = lazy(() => import("./widgets/UsersLocation"));
// const WorkplansWidget = lazy(() => import("./widgets/WorkplansWidget"));
const MarketingWidget = lazy(() => import("./widgets/MarketingWidget"));
// const DashboardApps = lazy(() => import("./widgets/DashboardApps"));
// const SavedSearch = lazy(() => import("./widgets/SavedSearch"));
// const Favorites = lazy(() => import("./widgets/Favorites"));

type Keys = "widgets" | "lists";

type Props = {
    removeFrom: Keys;
    remove(s: Keys, key: availableWidgets): void;
    key: number;
};

// @ts-ignore
const widgetsObject = {

    locations: (props: Props) => <UsersLocation type={"locations"} {...props} key={"locations"} />,
    workplans: (props: Props) => <WorkplansWidget type={"workplans"} {...props} key={"workplans"}/>,
    marketing: (props: Props) => <MarketingWidget type={"marketing"} {...props} key={"marketing"}/>,
    apps: (props: Props) => <DashboardApps type={"apps"} {...props} key={"apps"}/>,
    savedSearch: (props: Props) => <SavedSearch type={"savedSearch"} {...props} key={"savedSearch"} />,
    favorites: (props: Props) => <Favorites   type={"favorites"} {...props} key={"favorites"} />,
};

type availableWidgets = keyof typeof widgetsObject;

const availableWidgets: availableWidgets[] = Object.keys(widgetsObject) as Array<keyof typeof widgetsObject>;
const DashboardContent = () => {
    const dispatch = useDispatch();
    // @ts-ignore redux
    const editDashboard = useSelector(({fuse}) => fuse.dialogs["editDashboard"]);
    const mainThemeDark = useSelector(({fuse}) => fuse.settings.mainThemeDark);
    const [loading, setLoading] = useState(false);

    const [widgets, setWidgets] = useState<availableWidgets[]>([]);

    const onSave = () => {
        setLoading(true);
        const data = {
            key: "dashboard",
            value: widgets,
        };
        axios
            .put(`${userPrefix}/setting`, data)
            .then(() => {
                dispatch(turnOff("editDashboard"));
                dispatch(showMessage({message: "message.Dashboard saved.", variant: "success"}));
            })
            .finally(() => setLoading(false));
    };



    useEffect(() => {
        const getData = () => {
            setLoading(true);
            axios
                .get(`${userPrefix}/setting?key=dashboard`)
                .then((r) => {
                    // console.log(r.data.value);
                    if (r.data.value) {
                        if (Array.isArray(r.data.value)) {
                            setWidgets(r.data.value);
                        } else {
                            setWidgets(["apps", "workplans"]);
                        }
                    }
                })
                .finally(() => setLoading(false));
        };
        getData();
    }, []);

    const addTo = (key: Keys, value: availableWidgets) => () => {
        if (key === "widgets") {
            setWidgets([...widgets, value]);
        }
    };

    const removeFrom = (s: Keys, type: availableWidgets) => {
        if (s === "widgets") {
            const t = widgets.filter((__: availableWidgets) => __ !== type);
            setWidgets(t);
        }
    };

    return (
        <div className="relative w-full">
            {editDashboard ? (
                <ThemeProvider theme={mainThemeDark}>
                    <Paper
                        square
                        elevation={3}
                        // style={{width: "calc(100% - 290px)"}} className="fixed bottom-0"
                    >
                        <div className="justify-between flex items-center px-16 py-12">
                            <Typography variant={"h6"}>{<Translate id={"dashboard.Add widgets"} />}</Typography>
                            <div>
                                <Button className="mr-4" color={"default"} onClick={() => dispatch(turnOff("editDashboard"))}>
                                    {<Translate id={"_.Cancel"} />}
                                </Button>
                                <SubmitButton loading={loading} success onClick={onSave}>
                                    {<Translate id={"_.Save"} />}
                                </SubmitButton>
                            </div>
                        </div>
                        <Divider />
                        <div className="p-16">
                            {/*<Typography className="text-grey font-bold mb-1 uppercase">{<Translate id={"dashboard.Stats"} />}</Typography>*/}
                            <Typography className="text-grey font-bold mb-1 uppercase">{<Translate id={"dashboard.Widgets"} />}</Typography>
                            <div className="flex pb-8 items-center">
                                {availableWidgets.map(
                                    (__: availableWidgets, i) =>
                                        Array.isArray(widgets) &&
                                        widgets?.findIndex((s) => s === __) === -1 && (
                                            <Button key={i} variant={"outlined"} onClick={addTo("widgets", __)} className="mr-2 capitalize">
                                                <Translate id={`dashboard.${__}`} />
                                            </Button>
                                        )
                                )}
                                {(availableWidgets?.length === widgets?.length - 1 || availableWidgets?.length === 0) && (
                                    <Button variant={"outlined"} disabled>
                                        {<Translate id={"dashboard.No more items"} />}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Paper>
                </ThemeProvider>
            ) : null}
            <div className="px-12">
                {widgets?.length > 0 ? (
                    widgets.map(
                        (widget, i) =>
                            typeof widgetsObject[widget] === "function" && widgetsObject[widget]({removeFrom: "widgets", remove: removeFrom, key: i})
                    )
                ) : (
                    <EmptyView loading={loading} />
                )}
            </div>
        </div>
    );
};

export default withTheme(DashboardContent);
