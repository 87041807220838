import {useEffect} from "react";
import ListItemText from "@material-ui/core/ListItemText";
import {CodeView, ColoredDate, ItemTitle, Money, MyChip} from "@ui";
import {orderTypes} from "app/main/outsource/enums";

import trans from "../../../main/outsource/translation/index.json";

// @ts-ignore
function OsOrderItemView(props) {
    const {item, addTranslation} = props;
    useEffect(() => {
        if (addTranslation) {
            addTranslation(trans);
        }
    }, []);

    return (
        <>
            <ListItemText
                primary={
                    <>
                        <CodeView dense>{item.code}</CodeView>
                        <ItemTitle>{item.title}</ItemTitle>
                    </>
                }
                secondary={
                    <>
                        <Money amount={item.totalPrice} />
                        <ColoredDate
                            date={item.dueDate}
                            disabled={!(item.status === "OPEN" || item.status === "PENDING")}
                        />
                    </>
                }
            />
            <MyChip size={"small"} variant={"outlined"} label={orderTypes[item.type]?.label} />
        </>
    );
}

export default OsOrderItemView;
