import axios, {firmPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const  GET_RANKS = "[RED_PACKET_GET_RANKS_12345]";
export const  SELECT_ONE_RED_PACKET = "[SELECT_ONE_RED_PACKET_9878776]";
export const  UNSELECT_ONE_RED_PACKET_MODEL = "[UNSELECT_ONE_RED_PACKET_MODEL_98dsfg76]";
export const  GET_STATS = "[RED_PACKET_GET_STATS_98787]";
export const  SELECT_YEAR_AND_MONTH = "[SELECT_YEAR_AND_MONTH_ooooooijnj]";
export const  EDIT_RANK_TYPE = "[EDIT_RANK_TYPE_oooosvdooijnj]";
export const  SELECT_A_DEPARTMENT = "[SELECT_A_DEPARTMENT]";
export const  INITILIZE_THE_RANK = "[INITILIZE_THE_RANK]";
export const  SELECT_RANK_MODEL_TYPE = "[SELECT_RANK_MODEL_TYPE]";


export function getInitilizeRank(success,fail) {
const request = axios.get(`${firmPrefix}/initRanks`);

return (dispatch) => {
    dispatch(startLoading());
    request.then(() =>{
        dispatch({
            type: INITILIZE_THE_RANK,
        });
        dispatch(finishLoading());
        if(typeof success=== "function") success();
    },err =>{
        if(typeof fail=== "function") fail(err);
    });
};
}


export function getRanks(params) {

    const request = axios.get(`${firmPrefix}/redPacketRanks`,{params: params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then(res =>{
            dispatch({
                type: GET_RANKS,
                payload: res.data,
            });
            dispatch(finishLoading());
        });
    };
}

export function selectOneRedPacketMoodel(redPacket) {
    return {
        type: SELECT_ONE_RED_PACKET,
        payload: redPacket
    };
}

/*
*  type = 1 ; ====  edit the  rank model
*  type = 2 ; ====  edit the rank just one month
* */
// export function editRankType(type) {
//     return {
//         type: EDIT_RANK_TYPE,
//         payload: type
//     }
// }

export function unSelectRankModel() {
    return {
        type: UNSELECT_ONE_RED_PACKET_MODEL
    };
}

export function selectCurrentYearAndMonth(ym) {
    return {
        type: SELECT_YEAR_AND_MONTH,
        payload: ym
    };
}

export function selectADepartment(depart) {

    return {
        type: SELECT_A_DEPARTMENT,
        payload: depart
    };
}

// export function getRedPacketStats(params) {
//     const request = axios.get(`${firmPrefix}/redPacketStats`,{params: params});
//
//     return (dispatch) => {
//         dispatch(startLoading());
//         request.then(res =>{
//             dispatch({
//                 type: GET_STATS,
//                 payload: res.data,
//             });
//             dispatch(finishLoading());
//         });
//     };
// }

export function selectRankModelType(type) {

    return {
        type: SELECT_RANK_MODEL_TYPE,
        payload: type
    };

}
