import axios, {financialPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const  GET_HOOKS   = "[FINANCIAL_GET_HOOKS]";
export const  SELECT_A_HOOK  = "[FINANCIAL_SELECT_A_HOOK]";
export const  De_SELECT_HOOK = "[[DE_FINANCIAL_SELECT_A_HOOK]]";
export const  SHOW_TOKEN     = "[FINANCIAL_SHOW_TOKEN]";

export function getHooks(params) {
    const request = axios.get(`${financialPrefix}/hooks`,{
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading());
        request.then(response => {
            dispatch({
                type: GET_HOOKS,
                payload: response.data
            });
            dispatch(finishLoading());
        });
    };
}

export function selecteHook(item) {
    return {
        type: SELECT_A_HOOK,
        payload: item
    };
}

export function deSelecteHook() {
    return {
        type: SELECT_A_HOOK
    };
}

export function showClientAndHubToken(token) {
    return {
        type: SHOW_TOKEN,
        payload: token
    };
}






