import * as fuseActions from "@fuseActions";
import {useDispatch, useSelector} from "react-redux";
import {useMemoizedFn} from "ahooks";

function useGlobalDialog() {
    const dispatch = useDispatch();

    /**
     * @param {Object} dialogs  the redux store Property about the dialogs
     * @description  the params about dialogs
     */
    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    /**
     * @param {string} id  dialog's  identifier
     * @description  open dialog
     */
    const openDialog = useMemoizedFn((id) => {
        dispatch(fuseActions.turnOn(`${id}`));
    });

    /**
     * @param {string} id  dialog's  identifier
     * @description close dialog
     */
    const closeDialog = useMemoizedFn((id) => {
        dispatch(fuseActions.turnOff(`${id}`));
    });

    return {
        dialogs,
        openDialog: openDialog,
        closeDialog: closeDialog,
    };
}

export default useGlobalDialog;
