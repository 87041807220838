import {useEffect} from "react";
import ListItemText from "@material-ui/core/ListItemText";
import {CodeView, ItemTitle, Money, MyChip} from "@ui";
import {invoiceStatuses} from "app/main/outsource/enums";

import {withLocalize} from "react-localize-redux";
import trans from "../../../main/outsource/translation/index.json";

// @ts-ignore
function OsInvoicesItemView(props) {
    const {item, addTranslation} = props;
    useEffect(() => {
        if (addTranslation) {
            addTranslation(trans);
        }
    }, []);

    return (
        <>
            {" "}
            <ListItemText
                primary={
                    <>
                        <CodeView dense>{item.code}</CodeView>
                        <ItemTitle>{item.title}</ItemTitle>
                    </>
                }
                secondary={
                    <>
                        <Money amount={item.totalPrice} />
                    </>
                }
            />
            <MyChip label={invoiceStatuses[item.status].label} color={invoiceStatuses[item.status].color} />
        </>
    );
}

export default withLocalize(OsInvoicesItemView);
