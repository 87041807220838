import {finishLoading, startLoading} from "@fuseActions";
import axios, {crmPrefix} from "myaxios";
import {MODULE_NAME, moduleLinklist, moduleLinklistAdmin, moduleLinkSingle} from "./VARIABLES";

export const GET_LIST = `[${MODULE_NAME}] GET_LIST`;
export const CLEAR_LIST = `[${MODULE_NAME}] CLEAR_LIST`;
export const GET_RELATIONSHIP = `[${MODULE_NAME}] GET_RELATIONSHIP`;
export const GET_EXTRA_INFO = `[${MODULE_NAME}] GET_EXTRA_INFO`;
export const SELECT_ITEM = `[${MODULE_NAME}] SELECT_ITEM`;
export const SELECT_LABEL = `[${MODULE_NAME}] SELECT_LABEL'`;
export const GET_ITEM = `[${MODULE_NAME}] GET_ITEM`;
export const CLEAR_ITEM = `[${MODULE_NAME}] CLEAR_ITEM`;
export const SELECT_ITEM_FOR_EDIT_COMPANY = `[${MODULE_NAME}] SELECT_ITEM_FOR_EDIT_COMPANY `;
export const SELECT_WORK_HISTORY_FOR_COMPANY_CONTACT = `[${MODULE_NAME}] SELECT_WORK_HISTORY_FOR_COMPANY_CONTACT `;

export function getItem(id) {
    const request = axios.get(`${moduleLinkSingle}/${id}`);

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_ITEM,
                payload: response.data,
            });
            dispatch(finishLoading());
        });
    };
}

export function clearList() {
    return {
        type: CLEAR_LIST,
    };
}

export function getContactRelations() {
    const params = {type: "Sales Contact RelationShip"};
    const request = axios.get(`${crmPrefix}/salesEnumProperties`, {params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_RELATIONSHIP,
                payload: response.data,
            });
            dispatch(finishLoading());
        });
    };
}

export function getExtraContactInfo() {
    const params = {type: "SalesContactApp"};
    const request = axios.get(`${crmPrefix}/salesEnumProperties`, {params});

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_EXTRA_INFO,
                payload: response.data,
            });
            dispatch(finishLoading());
        });
    };
}

export function clearItem() {
    return {
        type: CLEAR_ITEM,
    };
}

export function getList(params) {
    return (dispatch) => {
        dispatch(startLoading());
        axios.get(moduleLinklist, {params}).then((res) => {
            if (res.data.content)
                return new Promise((resolve) => {
                    dispatch({
                        type: GET_LIST,
                        payload: res.data,
                    });
                    dispatch(finishLoading());
                    resolve();
                });
        });
    };
}

export function getAdminData(params) {
    return (dispatch) => {
        // console.log("@getTheFuckingAdminData",params);
        dispatch(startLoading());
        axios.get(moduleLinklistAdmin, {params}).then((res) => {
            if (res.data.content)
                return new Promise((resolve) => {
                    dispatch({
                        type: GET_LIST,
                        payload: res.data,
                    });
                    dispatch(finishLoading());
                    resolve();
                });
        });
    };
}

export function selectItem(data) {
    return {
        type: SELECT_ITEM,
        data,
    };
}

export function selectWorkHistory(data) {
    return {
        type: SELECT_WORK_HISTORY_FOR_COMPANY_CONTACT,
        data,
    };
}

export function selectItemForEditCompany(data) {
    return {
        type: SELECT_ITEM_FOR_EDIT_COMPANY,
        data,
    };
}

export function selectLabel(data) {
    return {
        type: SELECT_LABEL,
        data
    };
}
