import {forwardRef} from "react";
import {Typography} from "@material-ui/core";

/**
 * @param {Object} props
 * @param ref
 * @param {string} props.title
 * @param {string} props.label
 * @param {string} props.className --- style.className
 */
function StrongLabel(props,ref) {
    const { title, className,label } = props;
    return (
        <Typography {...props} ref={ref} component={"strong"} variant="h6" className={"font-black truncate mr-2"+`${className}`}>
            {title || label}
        </Typography>
    );
}

export default forwardRef(StrongLabel);
