import type {TooltipProps} from "@material-ui/core";
import {Tooltip, Zoom} from "@material-ui/core";
import type {ReactNode} from "react";

export const MyTooltip = ({disabled, title, children, ...rest}: Props) => {
    return disabled || !title ? (
        children
    ) : (
        <Tooltip {...rest} title={title} TransitionComponent={Zoom} arrow>
            {children}
        </Tooltip>
    );
};

type Props = Omit<TooltipProps, "title"> &
    (
        | {
              disabled: boolean;
              title?: NonNullable<ReactNode>;
          }
        | {
              disabled?: undefined;
              title: NonNullable<ReactNode>;
          }
    );
