import {showMessage} from "@fuseActions";
import {useMemoizedFn} from "ahooks";
import {useDispatch} from "react-redux";

function useShowMessage() {
    const dispatch = useDispatch();

    const onShowMessage = useMemoizedFn((options) => {
        dispatch(showMessage(options));
    });

    return {
        showMessage: onShowMessage,
    };
}

export default useShowMessage;
