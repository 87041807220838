import {EmptyView} from "@ui";
import SRXCard from "../componenets/SRXCard";

function CategoryTableView() {
    return (
        <>
            <SRXCard>
                <div className={"flex flex-row justify-center items-center"}>
                    <EmptyView />
                </div>
            </SRXCard>
        </>
    );
}

export default CategoryTableView;
