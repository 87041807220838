import {lazy} from "react";

export const Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: "/firm/:id",
            component: lazy(() => import("app/admin/firm-manager/DetailsPage"))
        },
        {
            path: "/firms",
            component: lazy(() => import("./Page"))
        }
        // {
        //     path: "/requestToJoinFirm/:code",
        //     component: lazy(() => import("./FirmInfo"))
        // }
    ]
};
