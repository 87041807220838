import {createContext, useContext} from "react";
import type {departmentType, SearchType} from "../../../types";

export type DepContextType = {
    departments?: departmentType[];
    getDepartments: (params?: SearchType) => void;
    selectedDep?: departmentType;
    setSelectedDep: (department?: departmentType) => void;
    loading: boolean;
};

export const DepContext = createContext<DepContextType>({
    departments: [],
    getDepartments: () => {},
    setSelectedDep: () => {},
    selectedDep: undefined,
    loading: false,
});

export const useDep = () => {
    return useContext(DepContext);
};

// export const useDepD = () => {
//     const [departments, setDepartments] = useState<departmentType[]>();
//
//     const getDepartments = (params?: SearchType) => {
//         axios.get(`${firmPrefix}/departments`, {params}).then((res) => {
//             setDepartments(res.data);
//         });
//     };
//
//     return {
//         departments,
//         getDepartments,
//     };
// };
