import {makeStyles} from "@material-ui/styles";
import {differenceInMinutes} from "date-fns";
import {useContext, useEffect, useState} from "react";
import ReactDOMServer from "react-dom/server";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {matchRoutes} from "react-router-config";
import {withRouter} from "react-router-dom";
import {FuseLayouts} from "../../../@fuse";
import _ from "../../../@lodash";
import {getUnreadData} from "../../../app/auth/store/actions";
import AppContext from "../../../app/RouteContext";
import {resetSettings, setSettings} from "@fuseActions";
import globalTranslations from "../../../translations/global.json";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        '& code:not([class*="language-"])': {
            color: theme.palette.secondary.dark,
            backgroundColor: "#F5F5F5",
            padding: "2px 3px",
            borderRadius: 2,
            lineHeight: 1.7,
        },
        "& table.simple tbody tr td": {
            borderColor: theme.palette.divider,
        },
        "& table.simple thead tr th": {
            borderColor: theme.palette.divider,
        },
        "& a:not([role=button])": {
            color: theme.palette.secondary.main,
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            },
        },
    },
}));

export const onMissingTranslation = ({translationId}) => {
    return `${translationId}`;
};

function FuseLayout(props) {
    const dispatch = useDispatch();
    const defaultSettings = useSelector(({fuse}) => fuse.settings.defaults);
    const settings = useSelector(({fuse}) => fuse.settings.current);
    const isLoggedIn = useSelector(({user}) => user.profile);
    const [path, setPath] = useState("");

    const classes = useStyles(props);
    const {routes} = useContext(AppContext);
    const langCode = localStorage.getItem("i18nextLng");

    useEffect(() => {
        let defaultCode;

        if (langCode === "" || langCode === null || langCode === undefined) {
            defaultCode = "cn";
        } else {
            defaultCode = langCode;
        }

        props.initialize({
            languages: [
                {name: "English", code: "en"},
                {name: "Chinese", code: "cn"},
            ],
            translation: globalTranslations,
            options: {
                defaultLanguage: defaultCode,
                renderToStaticMarkup: ReactDOMServer.renderToStaticMarkup,
                onMissingTranslation,
            },
        });
    }, [isLoggedIn, langCode]);

    useEffect(() => {
        const timestamp = +localStorage.getItem("getUnreadNumbersDate");
        const diff = differenceInMinutes(new Date(), new Date(timestamp));
        if (diff > 5 && !!isLoggedIn) {
            dispatch(getUnreadData());
            localStorage.setItem("getUnreadNumbersDate", +new Date());
        }

        //        console.log(new Set(routes.map((x) => x.path.split("/").join("").split(":")[0])));

        function routeSettingsCheck() {
            const matched = matchRoutes(routes, props.location.pathname)[0];

            if (matched && matched.route.settings) {
                //                console.log(matched.route.path.replace("/", ""));
                setPath(matched.route.path.split("/").join("").split(":")[0]);
                //                if (matched.route.title && props.activeLanguage) {
                //                    document.title =
                //                        (process.env.NODE_ENV === "development" && "[DEV] ") + `${matched.route.title[props.activeLanguage?.code]} - ${baseTitle}`;
                //                } else {
                //                    document.title = (process.env.NODE_ENV === "development" && "[DEV] ") + baseTitle;
                //                }

                const routeSettings = _.merge({}, defaultSettings, matched.route.settings);
                if (!_.isEqual(settings, routeSettings)) {
                    dispatch(setSettings(_.merge({}, routeSettings)));
                }
            } else {
                if (!_.isEqual(settings, defaultSettings)) {
                    dispatch(resetSettings());
                }
            }
        }

        //        console.log(matched);

        // const langCode = localStorage.getItem("i18nextLng");
        //
        // let defaultCode = "cn";
        //
        // if (langCode === "" || langCode === null || langCode === undefined) {
        //     defaultCode = "cn";
        //     localStorage.setItem("language", "cn");
        // } else {
        //     defaultCode  =  langCode;
        // }
        //
        // props.initialize({
        //     languages: [
        //         {name: "English", code: "en"},
        //         {name: "Chinese", code: "cn"},
        //     ],
        //     translation: globalTranslations,
        //     options: {defaultLanguage: defaultCode, renderToStaticMarkup: ReactDOMServer.renderToStaticMarkup, onMissingTranslation}
        // });
        routeSettingsCheck();
    }, [defaultSettings, props.location.pathname, routes, settings, props.activeLanguage]);

    const Layout = FuseLayouts[settings.layout.style];

    const {t} = useTranslation(["titles", "_"]);
    return (
        <>
            <Helmet
                titleTemplate={process.env.NODE_ENV === "development" ? `DEV: ${t(path)} - %s` : `${t(path)} - %s`}
                defaultTitle={process.env.NODE_ENV === "development" ? `DEV: ${t(path)}` : t(path)}>
                <meta name="description" content={t("Pandda Master")} />
            </Helmet>

            <Layout classes={{root: classes.root}} {...props} />
        </>
    );
}

export default withLocalize(withRouter(FuseLayout));
