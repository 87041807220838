import {FormControl} from "@material-ui/core";
import PropTypes from "prop-types";
import {useMemo} from "react";
import Select from "react-select/async";

NewAyncSelectWrap.propTypes = {
    label: PropTypes.any,
    isRequired: PropTypes.bool,
    loadOptions: PropTypes.func,
    defaultOptions: PropTypes.bool.isRequired,
    components: PropTypes.object,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isMulti: PropTypes.bool,
    noOptionsMessage: PropTypes.string.isRequired,
};

/**
 *  给 react select 组件封装的增加 label 的组件
 */
function NewAyncSelectWrap(props) {
    const style = {
        label: {
            fontSize: ".75rem",
            fontWeight: "bold",
            lineHeight: 2,
        },
    };
    const {label, isMulti, isRequired, defaultOptions, components, value, onChange, placeholder, isClearable, loadOptions, noOptionsMessage} = props;
    const language = localStorage.getItem("i18nextLng");
    const local = useMemo(() => {
        return language;
    }, [language]);
    return (
        <>
            <FormControl className="w-full" required={isRequired || false}>
                <label style={style.label} id="AyncSelect-label" htmlFor="AyncSelect-input">
                    {label || ""}
                </label>
                <Select
                    required={isRequired || false}
                    aria-labelledby="AyncSelect-label"
                    inputId="AyncSelect-input"
                    textFieldProps={{
                        margin: "normal",
                        required: true,
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    margin={"normal"}
                    loadOptions={loadOptions}
                    defaultOptions={defaultOptions || false}
                    components={components}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder || ""}
                    isClearable={isClearable || false}
                    isMulti={isMulti || false}
                    noOptionsMessage={() => noOptionsMessage || ""}
                    loadingMessage={() => (local === "en" ? "Loading..." : "查询中...")}
                />
            </FormControl>
        </>
    );
}

export default NewAyncSelectWrap;
