import Typography from '@material-ui/core/Typography';
import {Translate} from 'react-localize-redux';

TaskStepInfoTipView.propTypes = {

};

function TaskStepInfoTipView(props) {
    return (
        <div className='flex flex-row justify-center items-center'>
            <div className="w-24 h-24 border border-solid border-black" style={{ height:'24px',width:'24px', backgroundColor: 'lawngreen'}} />
            <Typography className={"inline-flex items-center text-grey-darker"}> <pre> : </pre>{ <Translate id={"_.RESOLVED"}/> }</Typography>

            <div className="border border-solid border-black ml-3" style={{height:'24px',width:'24px', backgroundColor: 'yellow'}} />
            <Typography className={"inline-flex items-center text-grey-darker"}> <pre> : </pre>{ <Translate id={"_.PENDING task"}/> }</Typography>

            <div className="border border-solid border-black ml-3" style={{height:'24px',width:'24px', backgroundColor: 'rgb(116, 158, 229)'}} />
            <Typography className={"inline-flex items-center text-grey-darker"}> <pre> : </pre>{ <Translate id={"_.Multiple, double click to view details"}/> }</Typography>
        </div>
    );
}

export default TaskStepInfoTipView;
