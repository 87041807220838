import {SelectUsers} from "@forms";
import {Chip, Icon, ListItemSecondaryAction, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {MyAvatar} from "@ui";
import {showMessage, turnOn} from "@fuseActions";
import classNames from "classnames";
import axios, {firmPrefix} from "myaxios";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import type {dataType, userType} from "types";
import {nameText} from "utils/NameTooltipComponent";
import {useWorkPlan} from "../useWorkPlan";

type participantType = {
    user: userType;
    isRead: boolean;
};

const ParticipantsInfo = () => {
    const {wp, refresh} = useWorkPlan();

    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState<dataType<participantType>>({
        page: 0,
        size: 0,
        totalPages: 0,
        totalElements: 0,
        content: [],
    });
    const [users, setUsers] = useState<userType[]>([]);

    const dispatch = useDispatch();

    const getParticipants = () => {
        setLoading(true);
        axios.get(`${firmPrefix}/participants?workplanId=${wp?.id}&size=1000`).then(({data}) => {
            setLoading(false);
            setParticipants(data);
            setUsers(data.content.map((u) => u.user));
        });
    };

    // getParticipantForTree = (page) => {
    //     axios.get(`${firmPrefix}/participants?workplanId=${wp.id}&size=1000`).then(({data})=>{
    //         this.setState(state=>({value: data.content.map(i=>i.user.id)}))
    //     })
    // };

    // onChange = (value, ...rest) => {
    //     // console.log('onChange', value, ...rest);
    //     this.setState({ value });
    // };

    const submitUsers = (users) => {
        setLoading(true);
        axios.put(`${firmPrefix}/updateParticipant`, {userIds: users.map((u) => u.id), workplanId: wp?.id}).then(() => {
            refresh().then(() => {
                getParticipants();
                setLoading(false);
                // setEdit(false);
                dispatch(
                    showMessage({
                        message: "message.WPDetail Saved!",
                        variant: "success",
                    })
                );
            });
        });
    };

    return (
        wp && (
            <List dense className={loading ? "loading py-0" : "py-0"}>
                <SelectUsers
                    members={users}
                    title={
                        <>
                            {wp.title} <Translate id={"_.Participant"} />{" "}
                        </>
                    }
                    disableWithoutDepartment
                    onSubmit={(users) => submitUsers(users)}
                />

                <ListItem disableGutters className={"pl-8"}>
                    <ListItemIcon className={"min-w-32"}>
                        <Icon>people</Icon>
                    </ListItemIcon>
                    <Translate>
                        {({translate}) => (
                            <ListItemText
                                primary={
                                    <>
                                        <Translate id={"wps.Participant"} /> ({wp.participantCount})
                                    </>
                                }
                                secondary={
                                    wp.unreadParticipantCount > 0 &&
                                    translate("wps.Unread Participants with number", {
                                        number: wp.unreadParticipantCount,
                                    })
                                }
                            />
                        )}
                    </Translate>

                    <ListItemSecondaryAction>
                        {participants.content.length > 0 ? (
                            <>
                                <IconButton
                                    data-cy={"edit-participants"}
                                    onClick={() => {
                                        dispatch(turnOn("selectUsers"));
                                    }}
                                    size={"small"}>
                                    <Icon>{"edit"}</Icon>
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        setParticipants({
                                            page: 0,
                                            size: 0,
                                            totalPages: 0,
                                            totalElements: 0,
                                            content: [],
                                        })
                                    }
                                    size={"small"}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </>
                        ) : (
                            <IconButton data-cy={"view-participants"} onClick={getParticipants} size={"small"}>
                                <Icon>visibility</Icon>
                            </IconButton>
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
                {participants.content.length > 0 && (
                    <div className="px-8 pb-16">
                        {participants.content.map(({user, isRead}) => (
                            <Chip
                                size={"small"}
                                key={user.id}
                                avatar={<MyAvatar size={16} user={user} />}
                                className={classNames("m-1", !isRead && "bg-yellow-lighter border")}
                                // label={user.lastName + user.firstName}
                                label={
                                    <Tooltip title={"@" + user.username}>
                                        <span>{nameText(user)}</span>
                                    </Tooltip>
                                }
                            />
                        ))}
                    </div>
                )}
            </List>
        )
    );
};
export default ParticipantsInfo;
