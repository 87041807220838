import {useMemoizedFn} from "ahooks";
import {upperCase} from "lodash";


/**
 * @function  useRegularX - 使用正则表达式的 hook
 */
function useRegularX() {

    /**
     * @function checkCarNumber -  验证车牌号码的正则表达式
     * @returns {boolean} - true: 车牌验证通过，false: 车牌验证失败
     */
    const checkCarNumber = useMemoizedFn((text)=>{
        const _t = upperCase(text);
        const _end = _t.replace(/\s+/g,"");
        // console.log('检测的车牌号码 upperCase ： ',_end);
        const _regularX = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/;
        return _regularX.test(_end);
    });

    /**
     * @function checkPhoneNumber -  验证手机号码的正则表达式
     * @returns {boolean} - true: 手机号码验证通过，false: 手机号码验证失败
     */
    const checkPhoneNumber = useMemoizedFn((text)=>{
        const _regularX = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/;
        return _regularX.test(text);
    });

    return {
        checkCarNumber,
        checkPhoneNumber
    };
}

export default useRegularX;
