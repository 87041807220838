import PinWidget from "../../../main/workplans/Dashboard/Widgets/PinWidget";
import UnreadWidget from "../../../main/workplans/Dashboard/Widgets/UnreadWidget";
import TrWidget from "../../../main/workplans/Dashboard/Widgets/TRWidget";
import EngineerWidget from "../../../main/workplans/Dashboard/Widgets/EngineerWidget";
import WidgetWrapper from "./WidgetWrapper";
import {Translate, withLocalize} from "react-localize-redux";

const WorkplansWidget = (props) => {
    return (
        <WidgetWrapper actions={""} title={<Translate id={"dashboard.Workplan widget"} />} {...props}>
            <div className={"flex justify-stretch"}>
                <PinWidget />
                <UnreadWidget />
                <TrWidget />
                <EngineerWidget />
            </div>
        </WidgetWrapper>
    );
};

export default withLocalize(WorkplansWidget);
