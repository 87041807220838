import { SHOW_MESSAGE, HIDE_MESSAGE } from "@fuseActions";

const initialState = {
    state: null,
    options: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        autoHideDuration: 5000,
        message: "",
        parameter: null,
    },
};

const message = function (state = initialState, action) {
    switch (action.type) {
        case SHOW_MESSAGE: {
            return {
                ...state,
                state: true,
                options: {
                    ...state.options,
                    anchorOrigin: {
                        ...state.options.anchorOrigin,
                        ...action.options.anchorOrigin,
                    },
                    ...action.options,
                },
            };
        }
        case HIDE_MESSAGE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default message;
