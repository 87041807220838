// import {setCache, showMessage, turnOff, turnOn} from "@fuseActions";
import axios from "axios";
import {stringify} from "flatted";
import i18n from "i18next";
import {parse} from "qs";
import toast from "react-hot-toast";
import history from "./@history";
// import store from "./app/store";
import {baseURL, version} from "./ENV.VARIABLE";

export const userModule = "srx-user";
export const firmModule = "srx-fms";
export const chatModule = "srx-mqs";
export const fileModule = "srx-oss";
export const crmModule = "srx-crm";
export const pmsModule = "srx-pms";
export const commonModule = "srx-comm";
export const financialModule = "srx-fin";
export const flowModule = "srx-flow";
export const scmModule = "srx-scm";

export const API_VERSION = "/v1";
export const userPrefix = userModule + API_VERSION;
export const firmPrefix = firmModule + API_VERSION;
export const chatPrefix = chatModule + API_VERSION;
export const filePrefix = fileModule + API_VERSION;
export const scmPrefix = scmModule + API_VERSION;
export const crmPrefix = crmModule + API_VERSION;
export const pmsPrefix = pmsModule + API_VERSION;
export const commonPrefix = commonModule + API_VERSION;
export const financialPrefix = financialModule + API_VERSION;
export const flowPrefix = flowModule + API_VERSION;

export const headers = {
    "Content-Type": "application/json",
};

const instance = axios.create({
    baseURL: baseURL,
    headers: headers,
});
//
let verificationInterval;
const waitForVerification = (error) =>
    new Promise((resolve, reject) => {
        console.log({error, resolve, reject});
        // store.dispatch(setCache("verifyNumber", error.response.data.message));
        // store.dispatch(turnOn("twoFactorWaiting"));
        // console.log("##### Save verify number", error.response.data.message);
        // console.log("##### Show Waiting UI");
        // let timer = 0;

        verificationInterval = setInterval(() => {
            // console.log("##### Try every 1 second to get redux");

            // const shouldRetry = store.getState().fuse.dialogs["shouldRetry"];

            // const verificationCancelled = store.getState().fuse.dialogs["verificationCancelled"];
            // if (timer > 60 || verificationCancelled) {
            clearInterval(verificationInterval);
            //     reject("Timeout");
            //     store.dispatch(setCache("messageToSign", null));
            //     store.dispatch(setCache("verifyNumber", null));
            //     store.dispatch(setCache("headerSignature", null));
            //     store.dispatch(turnOff("shouldRetry"));
            //     store.dispatch(turnOff("twoFactorWaiting"));
            //     store.dispatch(turnOff("verificationCancelled"));
            //     store.dispatch(
            //         showMessage({
            //             message: verificationCancelled ? "message.Verification cancelled" : "message.Verification timeout",
            //             variant: "error",
            //         })
            //     );
            // }
            // if (shouldRetry) {
            //     const headerSignature = store.getState().fuse.dialogs["headerSignature"];
            //     if (headerSignature) {
            //         const newConfig = {
            //             signature: encodeURIComponent(headerSignature.signature),
            //             plainText: encodeURIComponent(headerSignature.plainText),
            //         };
            //         store.dispatch(setCache("messageToSign", null));
            //         store.dispatch(setCache("verifyNumber", null));
            //         store.dispatch(turnOff("shouldRetry"));
            //         store.dispatch(setCache("headerSignature", null));
            //         store.dispatch(turnOff("verifying"));
            //         store.dispatch(turnOn("verified"));
            //         store.dispatch(turnOff("twoFactorWaiting"));
            //         // store.dispatch(turnOff("twoFactor"));
            //         // console.log("##### Should retry is true", shouldRetry);
            //         // console.log("##### Set new config and fire again", newConfig);
            //         clearInterval(verificationInterval);
            //         resolve(newConfig);
            //     }
            // } else {
            //     timer++;
            //     // return Promise.reject({message: "Shit"})
            //     // console.log("##### Try again", shouldRetry);
            // }
        }, 1000);
    });

// // Add a request interceptor
//noinspection JSUnresolvedReference
instance.interceptors.request.use(
    function (config) {
        const tokenFromQuery = parse(window.location.search, {ignoreQueryPrefix: true}).token;
        const tokenFromStorage = localStorage.getItem("token");

        if (tokenFromQuery) {
            config.headers.token = tokenFromQuery;
        } else if (tokenFromStorage) {
            config.headers.token = tokenFromStorage;
        }

        config.headers.lang = localStorage.getItem("i18nextLng") === "cn" ? "zh" : "en";
        config.headers.version = version;
        config.headers.device = "web";
        config.headers.timezone = new Date().getTimezoneOffset();
        config.paramsSerializer = (params) => stringify(params);
        return config;
    },
    function (error) {
        toast.error(error.response.data.error);
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        const {data} = response;
        const tokenFromStorage = localStorage.getItem("token");
        if (data.token && ((tokenFromStorage && data.token !== tokenFromStorage) || !tokenFromStorage)) {
            localStorage.setItem("token", data.token);
        }

        return response;
    },
    function (error) {
        // store.dispatch(finishLoading());
        // const {t} = useTranslation(["errors", "_"]);
        // const isLoginOrRegisterPage = history.location.pathname === '/login' || history.location.pathname === '/register';
        // const refreshToken = localStorage.getItem("refreshToken");
        // const isRemember = refreshToken !== "" && refreshToken;

        if (error.response) {
            if (error.response && error.response.status === 402) {
                return waitForVerification(error)
                    .then((_) => {
                        // console.log("###############  resolve ", _);

                        // @ts-ignore
                        error.config.headers["signature"] = _.signature;

                        // @ts-ignore
                        error.config.headers["plainText"] = _.plainText;
                        return instance.request(error.config);
                    })
                    .catch(() => {
                        // console.log("###############  rejected", er);
                        return Promise.reject(error);
                    });
            }

            // store.dispatch(

            // showMessage({
            //     variant: "error",
            //     message: error.response.data.message || "message.Something is wrong",
            // })
            // );

            // if ( error.response.status === 401) {
            //     store.dispatch(pageError({
            //         message: "message.You have no permission to do this!",
            //     }));
            // }
            // if ( error.response.status === 500) {
            //     store.dispatch(pageError({
            //         message: "message.Server down!",
            //     }));
            // }

            // console.log(error.response);
            if (error.response.status === 441) {
                localStorage.clear();
                history.push({pathname: "/login"});
                // document.location.reload();
                // if (!error.response.data.token) {
                //     localStorage.clear();
                //     document.location.reload();
                //     // if (noAuthPaths()) {
                //     //
                //     //     // store.dispatch(removeUserData());
                //     // } else {
                //     //
                //     //     // store.dispatch(logoutUser());
                //     // }
                // } else {
                //     if (error.response.data.token) {
                //
                //         // store.dispatch(setToken(error.response.data.token));
                //         localStorage.setItem("token", error.response.data.token);
                //     }
                // }
            } else {
                if (error.response && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else if (error.response.message) {
                    toast.error(error.response.message);
                } else {
                    // @ts-ignore
                    toast.error(i18n.t([`errorCodes.${error.response.status}`, "errorCodes.unknown"], {ns: "errors"}));
                }
            }
            //     if(history.location.pathname !== '/login' && history.location.pathname !== '/register') {
            //         store.dispatch(logoutUser());
            //     }
            // } else {
            // if (error.response.status === 441) {
            //     store.dispatch(logoutUserData());
            // store.dispatch(setUserData());
            // const tokenDefined = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== "";
            // instance.get("/checkToken").then(res => {
            //     const sessionValid = res.data.result;
            //     switch (sessionValid) {
            //         case "valid":
            //             if (tokenDefined) {
            //                 store.dispatch(setUserData());
            //             } else {

            //             }
            //             break;
            //         case "invalid":
            //             localStorage.setItem("lastPathName", history.location.pathname);

            //             break;
            //         case "refresh":
            //             localStorage.setItem("lastPathName", history.location.pathname);
            //             if (localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== res.data.token) {
            //                 localStorage.setItem("token", res.data.token);
            //                 localStorage.setItem("refreshToken", res.data.refreshToken);
            //                 localStorage.setItem("new", res.data.token);
            //                 store.dispatch(setUserData());
            //             }
            //             break;
            //         default:
            //             console.log(sessionValid);
            //     }
            // })
            // }
            // } else
            // if (error.response.data.error)
            //     store.dispatch(showMessage({
            //         message: error.response.status + ": " + error.response.data.error
            //     }));
            // else
            //     store.dispatch(showMessage({
            //         message: error.response.status + ": " + error.response.data
            //     }));
            // }
        }
        return Promise.reject(error);
    }
);

export const swrFetcher = (p: never[]) => {
    const t = typeof p === "string" ? p : {url: p[0], params: p[1]};

    return instance(t).then((res) => res?.data);
};

export default instance;
