import {useEffect, useState} from "react";
import {Icon, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {Translate, withLocalize} from "react-localize-redux";
import TextField from "@material-ui/core/TextField";
import axios, {crmPrefix} from "../../../../../myaxios";
import CompanyLabel from "../../utils/CompanyLabel";
import {DingDingSvgIcon, LinkedInIcon, QQChatIcon, WeChatIcon} from "../../activities/VendorIcons/VendorIcons";
import _ from "lodash";
import Owner from "../../utils/Owner";
import {CreateAndActiveDateItem} from "../../marketingTools";
import Card from "@material-ui/core/Card";


export const AddressItem = ({address,language}) => {
    return <ListItem >
        <ListItemText
            primary={address && address.city && address.city.trim() !== "" ?  language.code === "en" ? address.street + address.city + address.province + address.country : address.country + address.province + address.city + address.street  : <Translate id={"market.No address"}/>}
            secondary={address && address.city && address.city.trim() !== "" && <Translate id={"market.Address"}/>}
        />
        <ListItemSecondaryAction>
            {address && address.city && address.city.trim() !== "" &&
            <IconButton disabled color={"secondary"} edge="end" aria-label="delete">
                <Icon>location_on</Icon>
            </IconButton>
            }
        </ListItemSecondaryAction>
    </ListItem>;
};

const ContactItem = ({type, icon ,sourceItem, onClickButton, refresh}) => {
    const [edit, setEdit] = useState(sourceItem[type] ? sourceItem[type].trim() === "" : true);
    const isEdit = sourceItem[type] ? sourceItem[type].trim() !== "" && edit : false;
    const [value, setValue] = useState(sourceItem[type]);

    const addContactItem = () => {

        const data = {
            "id"  : sourceItem.id,
            // "lastName": sourceItem.lastName,
            // "firstName": sourceItem.firstName,
            // "title": sourceItem.title,
            // "email": sourceItem.email,
            // "phone": sourceItem.phone,
            // "department": sourceItem.department,
            // "assistant": sourceItem.assistant,
            // "leadSource": _.isString(sourceItem.leadSource) === false && sourceItem.leadSource !== null && sourceItem.leadSource !== undefined ? sourceItem.leadSource.key : sourceItem.leadSource,
            // "birthday": sourceItem.birthday,
            // "address": sourceItem.address,
            // "description": sourceItem.description,
            // "user": sourceItem.user,
            // "labels": sourceItem.labels,
            // "tag": sourceItem.tag,
            // "isDecisionMaker": sourceItem.isDecisionMaker,
            // "relationShip": _.isString(sourceItem.relationShip) === false && sourceItem.relationShip !== null && sourceItem.relationShip !== undefined ? sourceItem.relationShip.key : sourceItem.relationShip ,
            // "avatar": sourceItem.avatar,
            // "companyId": sourceItem.company ?  sourceItem.company.id : "",
            // "contactInfos": _.isArray(sourceItem.contactInfos) === true && sourceItem.contactInfos.length > 0 ? sourceItem.contactInfos.map(t=> { return {...t,type: t.type.key} })  : [],
            [type]: value
        };
        delete data.company ;

        axios.put(`${crmPrefix}/companyContact`, data).then(() => {
            refresh();
            setEdit(false);
        });
    };

    return <ListItem >
        {
            !edit ?
            <ListItemText
                primary={sourceItem[type]}
                secondary={<Translate id={`market.${type}`}/>}
            />
            :
            <div className={"flex items-center"}>
                {
                    <Translate>
                    {({ translate }) =>
                        <TextField
                            fullWidth
                            placeholder={`${isEdit ? translate("market.Edit") : translate("market.Add") + translate(`market.${type}`)}`}
                            value={value}
                            onChange={(e)=>setValue(e.target.value)}
                        />
                    }
                 </Translate>
                }
                <IconButton onClick={addContactItem}><Icon>check</Icon></IconButton>
                {isEdit && <IconButton  onClick={()=>setEdit(false)}><Icon>close</Icon></IconButton>}
            </div>
        }
        <ListItemSecondaryAction>
            {!edit &&
            <>
                <IconButton className="mr-1" onClick={()=>setEdit(true)} size={"small"}>
                    <Icon fontSize={"small"}>edit</Icon>
                </IconButton>
                <IconButton  color={"secondary"} disabled={!onClickButton} edge="end" aria-label="delete">
                    <Icon>{icon}</Icon>
                </IconButton>
            </>
            }
        </ListItemSecondaryAction>
    </ListItem>;
};

const ExtraContactInfoItem = ({ icon ,sourceItem, target, changeTarget ,onClickButton, refresh}) => {


    const [edit, setEdit] = useState( target.value.trim() === "" );

    const isEdit =  target.value.trim() !== "" && edit ;

    useEffect(()=>{

        setEdit(target.value.trim() === "");

        // console.log('ExtraContactInfoItem => target ;  sourceItem',target, sourceItem);
    },[]);


    const addContactItem = () => {

        // let  extras = sourceItem.contactInfos;
        //
        // const selectedIndex = extras.map(t=>t.type.key).indexOf(target.type.key);
        //
        // console.log('sourceItem.contactInfos', extras, target);
        //
        // const re2 = extras.splice(selectedIndex,1,target);
        //
        // console.log('sourceItem.contactInfos splice', re2, target);
        //
        // const re3 = re2.map(t=> { return { ...t, "type": t.type.key } });
        //
        // console.log('sourceItem.contactInfos re3', re3, target);

        const tempRes = sourceItem.contactInfos.map(t=>  t.type.key === target.type.key ?  { type: target.type.key, value: target.value }  :  {...t,type: t.type.key}  );


        const data = {
            "id"  : sourceItem.id,
            contactInfos:   tempRes
        };

        // delete data.company;
        axios.put(`${crmPrefix}/companyContact`, data).then(() => {
            refresh();
            setEdit(false);
        });
    };

    return <ListItem >
        {
            edit === false ?
                <ListItemText
                    primary={target.value}
                    secondary={<Translate id={`market.${target.type.key}`}/>}
                />
                :
                <div className={"flex items-center"}>
                    {
                        <Translate>
                            {({ translate }) =>
                                <TextField
                                    fullWidth
                                    placeholder={`${isEdit ? translate("market.Edit") : translate("market.Add") + translate(`market.${target.type.key}`)}`}
                                    value={target.value}
                                    onChange={(e)=>{
                                        const resObject = {
                                            "type": target.type,
                                            "value": e.target.value
                                        };
                                        changeTarget(resObject);
                                    }}
                                />
                            }
                        </Translate>
                    }
                    <IconButton onClick={addContactItem}><Icon>check</Icon></IconButton>
                    {isEdit && <IconButton  onClick={()=>setEdit(false)}><Icon>close</Icon></IconButton>}
                </div>
        }
        <ListItemSecondaryAction>
            {edit === false &&
            <>
                <IconButton className="mr-1" onClick={()=>setEdit(true)} size={"small"}>
                    <Icon fontSize={"small"}>edit</Icon>
                </IconButton>
                <IconButton  color={"secondary"} disabled={!onClickButton} edge="end" aria-label="delete">
                    {/*<Icon>{icon}</Icon>*/}
                    {icon}
                </IconButton>
            </>
            }
        </ListItemSecondaryAction>
    </ListItem>;
};


const RightSidebar = (props) => {

    const {sourceItem, refresh, activeLanguage} = props;

    // const extraInfos =  useSelector(({marketing}) => marketing.companyContacts.list.extraContactInfos);

    // const [contactInfos, setContactInfos] = useState(extraInfos.map(item=>{return {"type":item,"value":""}}));

    const [contactInfos, setContactInfos] = useState([...sourceItem.contactInfos]);

    useEffect(()=>{


        ( _.isArray(sourceItem.contactInfos) === true && sourceItem.contactInfos.length > 0 ) ?

            setContactInfos([...sourceItem.contactInfos])

            :

            setContactInfos([]);

    },[sourceItem && sourceItem.contactInfos]);


   const handleChangeExtra = (object) => {

       // const index = contactInfos.map(t=>t.type.key).indexOf(object.type.key);
       //
       // const res = contactInfos.splice(index,1,object);

        const res = contactInfos.map(t=> t.type.key === object.type.key ? {...t,value: object.value} : t );

        setContactInfos(res);
    };

    const getExtraInfoIcon = (key) => {

        switch (key) {
            case "DingTalk":
                return <DingDingSvgIcon />;
            case "LinkIn":
                return <LinkedInIcon />;
            case "WeChat":
                return <WeChatIcon />;
            case "qq":
                return <QQChatIcon />;
            default:
                return  <DingDingSvgIcon />;
        }
    };



    return (
        <Card  className="m-20 ml-0  ">
            <Owner user={sourceItem.user}/>
            <CompanyLabel link={"companyContact"} {...props}/>



            <List dense classes={{root: "pt-0"}}>



                { _.isObject(sourceItem.address) && <AddressItem address={sourceItem.address} language={activeLanguage}/> }
                <ContactItem type={"phone"} refresh={refresh} onClickButton sourceItem={sourceItem} icon={"local_phone"}/>
                <ContactItem type={"email"} refresh={refresh} sourceItem={sourceItem} icon={"scanner"}/>


                { contactInfos.map(object=>
                    <ExtraContactInfoItem key={object.type.key} icon={getExtraInfoIcon(object.type.key)} target={object} changeTarget={handleChangeExtra} sourceItem={sourceItem}  refresh={refresh}/>
                )}

                {/*{*/}
                {/*    sourceItem.createDate && <CreateDateItem sourceItem={sourceItem} />*/}
                {/*}*/}

                {/*<ListItem>*/}
                {/*    <ListItemText*/}
                {/*        primary={*/}
                {/*            <LocalTimeStamp datetime={sourceItem.createDate}/>*/}
                {/*        }*/}
                {/*        secondary={<Translate id={"market.create date"}/>}*/}
                {/*    />*/}

                {/*    <ListItemSecondaryAction>*/}
                {/*        <IconButton disabled color={"secondary"} edge="end" aria-label="delete">*/}
                {/*            <Icon>looks_one</Icon>*/}
                {/*        </IconButton>*/}
                {/*    </ListItemSecondaryAction>*/}

                {/*</ListItem>*/}
            </List>

            {
                (sourceItem.createDate || sourceItem.activeDate) && <CreateAndActiveDateItem sourceItem={sourceItem} />
            }
            {/*<ContactExtraInfos refresh={refresh} sourceItem={sourceItem}/>*/}

        </Card>
    );
};

export default withLocalize(RightSidebar);
