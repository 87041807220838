import '../diagram/BPMN/bpmn.css';
import Typography from '@material-ui/core/Typography';
import {Translate} from 'react-localize-redux';

ActivitiesTipView.propTypes = {

};

function ActivitiesTipView(props) {
    return (
        <div className='flex flex-row justify-center items-center'>
            <div className="instance-overlay">
                <p>1</p>
            </div>
            <Typography className={"inline-flex flex-row items-center text-grey-darker"}>
                <pre className="flex items-center"> : </pre>
                {
                 <Translate id={"_.Running Activity instances"}/>
                 }
            </Typography>
        </div>
    );
}

export default ActivitiesTipView;
