// import thunk from 'redux-thunk';
//import {enhancer as storybookEnhancer} from 'addon-redux'
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import apis from "../admin/api-manager/store/reducers";
import user from "../auth/store/reducers/user.reducer";
import formBuilder from "../components/form-builder/store/reducers/formBuilder.reducer";
import process from "../main/camunda-process/store/reducers";
import dashboard from "../main/commerce/projects/dashboard/store/dashboard.reducer";
import commerce from "../main/commerce/store/reducers";
import contractsModuleReducer from "../main/contracts/store/reducers";
import externalM from "../main/external_management/store/reducers";
import fileManager from "../main/files/store/reducers";
import financialReducer from "../main/financials/store/reducers/financial.reducer";
import income from "../main/incomes/store/reducers";
import marketing from "../main/marketing/marketingReducer";
import redPacket from "../main/redPackets/store/reducers";
import stock from "../main/stock/store/reducers";
import todo from "../main/tasks/store/reducers";
import notification from "../notifications/store/reducers";
// import {__DO_NOT_USE__ActionTypes} from "redux";
// import {applyMiddleware, compose, createStore} from 'redux';
// import createReducer from "./reducers";
import fuse from "./reducers/fuse";
/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
// __DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

// const composeEnhancers =
//     process.env.NODE_ENV !== 'production' &&
//     typeof window === 'object' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//             // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//         }) : compose;
//
// const composedEnhancer = composeEnhancers(
//     applyMiddleware(thunk), enhancer
// );

// const store = createStore(createReducer(), composedEnhancer);

// if (process.env.NODE_ENV === 'development') {
//     const { createLogger } = require(`redux-logger`);
//     const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });
//
//     middlewares.push(logger);
//     // middlewares.push(enhancer);
// }

/**
 *
 * old store
 * @type {EnhancedStore<{}, AnyAction, MiddlewareArray<ExcludeFromTuple<[ThunkMiddlewareFor<{}, {serializableCheck: boolean, immutableCheck: boolean}>], never>>, [StoreEnhancer<{}, {}>][number][]>}
 */
// const store = configureStore({
//     reducer: createReducer(),
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware({
//             immutableCheck: false,
//             serializableCheck: false,
//         }),
//     devTools: process.env.NODE_ENV === "development",
//
//     enhancers: (defaultEnhancers) => [...defaultEnhancers],
// });
// if (process.env.NODE_ENV === "development" && module.hot) {
//     module.hot.accept("./reducers", () => {
//         const newRootReducer = require("./reducers").default;
//         store.replaceReducer(newRootReducer.createReducer());
//     });
// }
// store.asyncReducers = {};

// src/store.js

const combinedReducer = combineReducers({
    user,
    fuse,
    notification,
    apis,
    process,
    commerce,
    formBuilder,
    marketing,
    stock,
    income,
    dashboard,
    redPacket,
    todo,
    contractsModuleReducer,
    externalM,
    fileManager,
    financialReducer,
    invoiceGP: income,
});

const store = configureStore({
    reducer: combinedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
});

export default store;
