import {List, ListItemAvatar, ListItemText, Paper} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import {EmptyView, MyAvatar} from "@ui";
import {firmPrefix} from "myaxios";
import {memo, useEffect} from "react";
import {Translate} from "react-localize-redux";
import {Link} from "react-router-dom";
import useSWR from "swr";
import type {ContactType} from "types";
import {userViewTitle} from "utils/utilities";

const EngineerWidget = () => {
    const {data: engineers, isLoading, mutate} = useSWR<ContactType[]>(`${firmPrefix}/myFieldEngineers`);
    useEffect(() => {
        mutate();
    }, []);

    return (
        engineers &&
        engineers.length > 0 && (
            <Paper className="rounded-8 mb-16">
                <List
                    classes={{root: "pb-0"}}
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {<Translate id={"wps.My Engineers"} />}
                        </ListSubheader>
                    }
                >
                    {isLoading ? (
                        <EmptyView loading={isLoading} />
                    ) : (
                        engineers.map((engineer, i) => (
                            <ListItem button key={i} component={Link} to={`/engineer/${engineer.user.id}/wps`}>
                                <ListItemAvatar>
                                    <MyAvatar user={engineer.user} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        userViewTitle(engineer.user)
                                        // <NameTooltipComponent user={engineer.user} showUsername strongTagFLname />
                                    }
                                />
                            </ListItem>
                        ))
                    )}
                </List>
            </Paper>
        )
    );
};

export default memo(EngineerWidget);
