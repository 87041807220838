import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initialState = {
    contract: null,
    selectedBill: null,
    selectedAttachment: null,
    logsObject: null,
};

const contractReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_CONTRACT_DETAIL:{
            const tobj = cloneDeep(state);
            tobj.contract = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     contract: action.payload
            // };
        }
        case Actions.SELECT_A_BILL:{
            const tobj = cloneDeep(state);
            tobj.selectedBill = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedBill: action.payload
            // };
        }
        case Actions.GET_CONTRACT_LOGS:{
            const tobj = cloneDeep(state);
            tobj.logsObject = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     logsObject: action.payload
            // };
        }
        case Actions.SELECT_A_ATTACHMENT:{
            const tobj = cloneDeep(state);
            tobj.selectedAttachment = action.payload;
            return tobj;
            // return {
            //     ...state,
            //    selectedAttachment : action.payload
            // };
        }
        default:{
            return state;
        }
    }
};

export default contractReducer;
