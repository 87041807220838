import * as Actions from "../actions";

const init = {
    list: [],
    selected: null,
    selectedType: "company",
    selectedWorkHistory: null,
    selectedGridListType: null,
};

const labelsReducer = function (state = init, action) {
    switch ( action.type )
    {
        case Actions.GET_LIST:
            return {
                ...state,
                list: action.payload
            };
        case Actions.SELECT_ITEM:
            return {
                ...state,
                selected: action.data
            };
        case Actions.SELECT_ITEM_FOR_DELETE_WORK_HISTORY:
            return {
                ...state,
                selectedWorkHistory: action.payload
            };
        case Actions.REFRESH_GRID_LIST_TYPE:
            return {
                ...state,
                selectedGridListType: action.payload
            };
        case Actions.SELECT_TYPE:
            return {
                ...state,
                selectedType: action.data
            };
        case Actions.CLEAR_ITEM:
            return null;
        default:
            return state;
    }
};

export default labelsReducer;
