import {useEffect} from "react";
import ListItemText from "@material-ui/core/ListItemText";
import {CodeView, ItemTitle, MyChip} from "@ui";

import {Translate, withLocalize} from "react-localize-redux";
import trans from "../../../main/production/translates/translate.json";
import {taskStatuses} from "../../../main/production/variable";

function PTItemView(props) {

    const {item, addTranslation} = props;
    useEffect(() => {
        if (addTranslation) {
            addTranslation(trans);
        }
    }, []);

    return (
        <>
            <ListItemText
                secondary={<CodeView dense>{item.oaRecordId}</CodeView>}
                primary={
                    <ItemTitle>
                        {`${item.factoryOrder?.clientName} - ${item.craft}`}
                    </ItemTitle>
                }
            />
            <MyChip
                label={<Translate id={`SCMs.${item.status}`} />}
                size={"small"}
                color={taskStatuses.find(s => item.status === s.value)?.color}
                variant={"default"}
            />
        </>
    );
}

export default withLocalize(PTItemView);
