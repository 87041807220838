import {startLoading} from "@fuseActions";
import {Icon, IconButton} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {MyTooltip} from "@ui";
import FaveButton from "components/layout/fav/FaveButton";
import {isString} from "lodash";
import type {ReactElement} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import type {SearchType} from "types";

export type HeaderProps = {
    statsComponent?: ReactElement;
    actionComponent?: ReactElement;
    /**
     * string with a key of translate
     * @example _.Tran
     */
    title?: string;
    getData?: (arg0: SearchType) => void;
    onAdd?: () => void;
    icon?: string;
    total?: number;
    selectorComponent?: ReactElement;
    subheaderComponent?: ReactElement;
};

export const ListHeader = ({
    total,
    title,
    icon,
    getData,
    onAdd,
    selectorComponent,
    statsComponent,
    actionComponent,
    subheaderComponent,
    ...rest
}: HeaderProps) => {
    // const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const search: SearchType = useSelector(({fuse}) => fuse.search);

    const fuseLoading = useSelector(({fuse}) => fuse.loading);
    const refresh = () => {
        dispatch(startLoading());
        if (getData && typeof getData === "function") {
            getData(search);
        }
    };
    return (
        <div className={"p-12 flex items-center w-full justify-between"}>
            <div className="flex  items-center" {...rest}>
                {icon && <Icon className="text-36 mr-16">{icon}</Icon>}
                <div className={"md:flex items-center"}>
                    <div>
                        <div className={"md:text-32 text-24 font-bold mr-20"}>
                            {isString(title) ? <Translate id={title} /> : title}
                            {!!total && <small className={"mx-3 text-grey"}>({total})</small>}

                            {typeof getData === "function" && (
                                <IconButton onClick={refresh} className={fuseLoading ? "animate-spin" : ""}>
                                    <Icon>refresh</Icon>
                                </IconButton>
                            )}

                            <FaveButton sourceId={location.pathname} title={title} type={"link"} />
                        </div>
                        {subheaderComponent && subheaderComponent}
                    </div>
                    {selectorComponent && selectorComponent}
                </div>
            </div>

            <div className="flex flex-1 items-center mt-12 md:mt-0 justify-end pr-0 sm:pl-12 ">
                {statsComponent && statsComponent}
                {actionComponent && actionComponent}
                {!!onAdd && (
                    <MyTooltip title={<Translate id={"_.add"} />}>
                        <Button
                            data-cy={"new-item"}
                            className={"ml-12"}
                            startIcon={<Icon>add</Icon>}
                            color={"secondary"}
                            variant={"contained"}
                            onClick={onAdd}>
                            <Translate id={"_.new"} />
                        </Button>
                    </MyTooltip>
                )}
            </div>
        </div>
    );
};

export default ListHeader;
