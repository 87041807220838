import {combineReducers} from "redux";
import external_users from "./external_users.reducer";
import checkin_Results from "./checkIn_results.reducer";
import qrcodeReducer from "./qrcode.reducer";

const process = combineReducers({
    external_users,
    checkin_Results,
    qrcodeReducer
});

export default process;
