import useQueryStore from "./useQueryStore";
import {useShallow} from "zustand/react/shallow";
import {useMyIncomeStore} from "./useMyIncomeStore";

function useMyIncomeStoreUtils() {

    const {
        _hasHydrated,
        query,
        actions,
        onChangeQuery,
    } = useQueryStore(useShallow((state)=>({
        _hasHydrated: state._hasHydrated,
        query: state.query,
        actions: state.actions,
        onChangeQuery: state.actions.onChangeQuery,
    })));

    const {
        isMyIncome,
        onChangeIsMyIncome,
        myIncomeSingleSelected,
        onChangeMyIncomeSingleSelected,
        incomeSureSingle,
        onChangeIncomeSureSingle,
    } = useMyIncomeStore(useShallow((state)=>({
        isMyIncome: state.isMyIncome,
        onChangeIsMyIncome: state.onChangeIsMyIncome,
        myIncomeSingleSelected: state.myIncomeSingleSelected,
        onChangeMyIncomeSingleSelected: state.onChangeMyIncomeSingleSelected,
        incomeSureSingle: state.incomeSureSingle,
        onChangeIncomeSureSingle: state.onChangeIncomeSureSingle,
    })));



    return {
        hasHydrated: _hasHydrated,
        query,
        actions,
        onChangeQuery,
        isMyIncome,
        onChangeIsMyIncome,
        myIncomeSingleSelected,
        onChangeMyIncomeSingleSelected,
        incomeSureSingle,
        onChangeIncomeSureSingle,
    };
}

export default useMyIncomeStoreUtils;
