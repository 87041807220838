import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import clsx from "clsx";
import {forwardRef, ReactNode, useImperativeHandle, useRef} from "react";
import {FuseScrollbars} from "../../../../@fuse";
import FusePageSimpleHeader from "./FusePageSimpleHeader";
import FusePageSimpleSidebar from "./FusePageSimpleSidebar";

const headerHeight = "12rem";
const drawerWidth = "24rem";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        position: "relative",
        flex: "1 0 auto",
        height: "auto",
        borderRadius: 8,
        // @ts-ignore
        backgroundColor: theme.palette.background.default,
    },
    innerScroll: {
        flex: "1 1 auto",
        height: "100%",
    },
    wrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "row",
        flex: "1 1 auto",
        zIndex: 2,
        maxWidth: "100%",
        minWidth: 0,
        // height         : '100%',
        borderRadius: 8,
        // @ts-ignore
        backgroundColor: theme.palette.background.default,
    },
    header: {
        // height         : headerHeight,
        // minHeight      : headerHeight,
        // display        : 'flex',
        // background     : 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.main + ' 100%)',
        // color          : theme.palette.primary.contrastText,
        backgroundSize: "cover",
        // backgroundColor: "white"
    },
    topBg: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: headerHeight,
        pointerEvents: "none",
    },
    /* contentCardWrapper            : {
         display : 'flex ',
         flex    : '1 1 auto',
         overflow: 'visible!important',
         minWidth: 0,
         '&.ps'  : {
             overflow: 'visible!important'
         }
     },
     contentCardWrapperInnerSidebar: {
         display                     : 'block',
         overflow                    : 'auto!important',
         '-webkit-overflow-scrolling': 'touch',
         '&.ps'                      : {
             overflow: 'hidden!important'
         },
         '& $contentCard'            : {
             borderRadius: 8
         }
     },*/
    contentWrapper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        flex: "1 1 auto",
        // overflow                    : 'auto',
        "-webkit-overflow-scrolling": "touch",
        zIndex: 9999,
    },
    toolbar: {
        // height    : toolbarHeight,
        // minHeight : toolbarHeight,
        display: "flex",
        alignItems: "center",
    },
    content: {
        flex: "1 0 auto",
        width: "100%",
    },
    contentDown: {
        paddingBottom: 80,
    },
    sidebarWrapper: {
        backgroundColor: "transparent",
        position: "absolute",
        "&.permanent": {
            // @ts-ignore
            [theme.breakpoints.up("lg")]: {
                position: "relative",
            },
        },
    },
    sidebar: {
        position: "absolute",
        "&.permanent": {
            // @ts-ignore
            [theme.breakpoints.up("lg")]: {
                // @ts-ignore
                backgroundColor: theme.palette.background.default,
                // @ts-ignore
                color: theme.palette.text.primary,
                position: "relative",
            },
        },
        width: drawerWidth,
        height: "100%",
    },
    leftSidebar: {
        // @ts-ignore
        [theme.breakpoints.up("lg")]: {
            // borderRight: '1px solid ' + theme.palette.divider,
            borderLeft: 0,
        },
    },
    rightSidebar: {
        // @ts-ignore
        [theme.breakpoints.up("lg")]: {
            // borderLeft : '1px solid ' + theme.palette.divider,
            borderRight: 0,
        },
    },
    sidebarHeader: {
        height: headerHeight,
        minHeight: headerHeight,
        // @ts-ignore
        backgroundColor: theme.palette.primary.dark,
        // @ts-ignore
        color: theme.palette.primary.contrastText,
    },
    sidebarHeaderInnerSidebar: {
        backgroundColor: "transparent",
        color: "inherit",
        height: "auto",
        minHeight: "auto",
    },
    sidebarContent: {},
    backdrop: {
        position: "absolute",
    },
}));

type Props = {
    breadcrumb?: ReactNode;
    onXReachEnd?: any;
    onYReachEnd?: any;
    header?: ReactNode;
    content?: ReactNode;
    innerScroll?: boolean;
    sidebarInner?: boolean;
    leftSidebarHeader?: ReactNode;
    leftSidebarContent?: ReactNode;
    leftSidebarVariant?: string;
    contentToolbar?: ReactNode;
    rightSidebarHeader?: ReactNode;
    rightSidebarContent?: ReactNode;
    rightSidebarVariant?: string;
};

const FusePageSimple = forwardRef(function (props: Props, ref) {
    // console.info("render::FusePageSimple");
    const leftSidebarRef = useRef(null);
    const rightSidebarRef = useRef(null);
    const rootRef = useRef(null);
    const classes = useStyles(props);

    useImperativeHandle(ref, () => {
        return {
            rootRef: rootRef,
            toggleLeftSidebar: () => {
                // @ts-ignore
                leftSidebarRef.current.toggleSidebar();
            },
            toggleRightSidebar: () => {
                // @ts-ignore
                rightSidebarRef.current.toggleSidebar();
            },
        };
    });

    return (
        <div className={clsx(classes.root, props.innerScroll && classes.innerScroll)} ref={rootRef}>
            {/*<div className={clsx(classes.header, classes.topBg, "items-end pb-12 min-h-72 sm:h-128 sm:min-h-128")}/>*/}

            {props.header && props.sidebarInner && <FusePageSimpleHeader header={props.header} breadcrumb={props.breadcrumb} classes={classes} />}

            <div className={classes.wrapper}>
                {(props.leftSidebarHeader || props.leftSidebarContent) && (
                    <FusePageSimpleSidebar
                        position="left"
                        header={props.leftSidebarHeader}
                        content={props.leftSidebarContent}
                        variant={props.leftSidebarVariant || "permanent"}
                        innerScroll={props.innerScroll}
                        sidebarInner={props.sidebarInner}
                        classes={classes}
                        ref={leftSidebarRef}
                        rootRef={rootRef}
                    />
                )}

                {/*<FuseScrollbars*/}
                {/*    className={clsx(classes.contentCardWrapper, props.sidebarInner && classes.contentCardWrapperInnerSidebar)}*/}
                {/*    enable={props.innerScroll && props.sidebarInner}*/}
                {/*>*/}
                {/*// @ts-ignore*/}
                <FuseScrollbars
                    onXReachEnd={props.onXReachEnd}
                    onYReachEnd={props.onYReachEnd}
                    className={classes.contentWrapper}
                    enable={props.innerScroll && !props.sidebarInner}
                >
                    {props.header && !props.sidebarInner && (
                        <FusePageSimpleHeader header={props.header} breadcrumb={props.breadcrumb} classes={classes} />
                    )}

                    {props.contentToolbar && <div className={classes.toolbar}>{props.contentToolbar}</div>}

                    {props.content && <div className={classNames(classes.content, "block  sm:pb-0  md:flex")}>{props.content}</div>}
                </FuseScrollbars>
                {/*</FuseScrollbars>*/}

                {(props.rightSidebarHeader || props.rightSidebarContent) && (
                    <FusePageSimpleSidebar
                        position="right"
                        header={props.rightSidebarHeader}
                        content={props.rightSidebarContent}
                        variant={props.rightSidebarVariant || "permanent"}
                        innerScroll={props.innerScroll}
                        sidebarInner={props.sidebarInner}
                        classes={classes}
                        ref={rightSidebarRef}
                        rootRef={rootRef}
                    />
                )}
            </div>
        </div>
    );
});

// FusePageSimple.propTypes = {
//     leftSidebarHeader: PropTypes.node,
//     leftSidebarContent: PropTypes.node,
//     leftSidebarVariant: PropTypes.node,
//     rightSidebarHeader: PropTypes.node,
//     rightSidebarContent: PropTypes.node,
//     rightSidebarVariant: PropTypes.node,
//     header: PropTypes.node,
//     content: PropTypes.node,
//     breadcrumb: PropTypes.node,
//     contentToolbar: PropTypes.node,
//     sidebarInner: PropTypes.bool,
//     innerScroll: PropTypes.bool,
//     onYReachEnd: PropTypes.func,
//     onXReachEnd: PropTypes.func,
// };

// FusePageSimple.defaultProps = {};

export default FusePageSimple;
