import {useProcessStore} from "../../state/useProcessStore";
import {useMemo} from "react";

function useBackdropLoading() {
    const openBackDrop = useProcessStore((state) => state.openBackDrop);

    const onOpenBackDrop = useProcessStore((state) => state.onOpenBackDrop);

    return useMemo(() => {
        return {
            openBackDrop: openBackDrop,
            onOpenBackDrop: onOpenBackDrop,
        };
    }, [openBackDrop, onOpenBackDrop]);
}

export default useBackdropLoading;
