import * as Actions from '../actions';

const initializeState = {
    percent: 0
};

const mainReducer = function (state = initializeState, action) {
    if (action.type === Actions.LOADED_PERCENT) {
        return {...state, percent : action.percent};
    } else {
        return state;
    }
};

export default mainReducer;
