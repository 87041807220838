import {turnOff} from "@fuseActions";
import {Typography} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import {MyCard} from "@ui";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import WidgetWrapper from "./WidgetWrapper";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import myaxios, {userPrefix} from "@api";
import toast from "react-hot-toast";

const apps = [
    {
        id: "e988fe40-7414-11ec-92d1-89aeb0fe2400",
        icon: "/assets/images/apps/flow-tasks.png",
    },
    {
        id: "73557070-8f7b-11ea-b26e-0d2a4a46abbf",
        icon: "/assets/images/apps/workplans.png",
    },
    {
        id: "6fc8d990-4a01-11ed-9455-03b75148167f",
        icon: "/assets/images/apps/outsource.png",
    },
    {
        id: "4eb4c9e0-1d58-11ea-99d1-41cd7d4028d5",
        icon: "/assets/images/apps/issues.png",
    },
    {
        id: "b3806840-6983-11e9-9ae7-9f1f7d399dd0",
        icon: "/assets/images/apps/todo.png",
    },
    {
        id: "c5182b90-6f16-11e9-9a1c-7d486c5579eb",
        icon: "/assets/images/apps/projects.png",
    },
    {
        id: "da9e9730-ca6b-11eb-8b47-95610d9cdb1f",
        icon: "/assets/images/apps/product-tasks.png",
    },
    {
        id: "6fc8d990-4a01-11ed-9455c-03b75148167f",
        icon: "/assets/images/apps/outsource.png",
    },
];

function findTreeNode(tree, id) {
    let result;

    if (tree.id === id) {
        result = tree;
    } else if (tree.children) {
        tree.children.some((node) => {
            result = findTreeNode(node, id);
            return result; // break loop
        });
    }

    return result;
}

const DashboardApps = (props) => {
    const {i18n, t} = useTranslation();
    const unread = useSelector(({user}) => user.unread);
    const navigation = useSelector(({fuse}) => fuse.navigation);
    const [link, setLink] = useState(null);
    const appsDetails = [];
    apps.forEach((i) => {
        const f = findTreeNode({id: "root", children: navigation}, i.id);
        if (f) {
            appsDetails.push({...f, ...i});
        }
    });
    const dispatch = useDispatch();

    const closeDialog = () => {
        dispatch(turnOff("dailyDashboard"));
        localStorage.setItem("showDashboardDate", +new Date());
    };

    useEffect(() => {
        myaxios
            .get(`${userPrefix}/accessOA`)
            .then((res) => {
                if (res.data.url) {
                    // window.open(res.data.url);
                    setLink(res.data.url);
                }
            })
            .catch(() => {
                toast("You don't have access to the old system");
            });
    }, []);

    return (
        <WidgetWrapper actions={""} title={<Translate id={"dashboard.Applications"} />} {...props}>
            <MyCard title={<Translate id={"dashboard.Applications"} />} contentProps={{className: "grid grid-cols-3 md:grid-cols-6 gap-20"}}>
                <a
                    onClick={closeDialog}
                    href={link}
                    target={"_blank"}
                    role={"button"}
                    className={"flex flex-col items-center text-gray-400 font-normal justify-center hover:no-underline duration-150"}>
                    {unread && (
                        <Badge overlap="rectangular" color="error">
                            <img width={72} height={72} src={"/assets/images/apps/old-computer.png"} alt={t("oldSystemBtn")} />
                        </Badge>
                    )}
                    <Typography className={"mt-8 text-20"}>{t("oldSystemBtn")}</Typography>
                </a>

                {appsDetails.map((item) => (
                    <Link
                        onClick={closeDialog}
                        key={item.id}
                        to={item.url}
                        role={"button"}
                        className={
                            "flex bg-red-ligsht flex-col items-center text-gray-400 font-normal justify-center hover:no-underline duration-150"
                        }>
                        {unread && (
                            <Badge overlap="rectangular" badgeContent={unread[item.id]} color="error">
                                <img width={72} height={72} src={item.icon} alt={item.titleCn} />
                            </Badge>
                        )}
                        <Typography className={"mt-8 text-20"}>{i18n.language === "cn" ? item.titleCn : item.title}</Typography>
                    </Link>
                ))}
            </MyCard>
        </WidgetWrapper>
    );
};

export default withLocalize(DashboardApps);
