import { turnOff, minimize, turnOn, startLoading, finishLoading, showMessage, replaceParams } from "@fuseActions";
import {Button, DialogActions, withMobileDialog} from "@material-ui/core";
import { stringify } from "qs";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import EventBus, {UPDATE_COMPANY_SELECTOR_AFTER_CREATE_A_COMPANY_NOTIFI} from "utils/EventBus";

import withDialog from "utils/withDialog";
import axios, {crmPrefix} from "../../../../../myaxios";
import {getGridListAsycnNotificationName} from "../../marketingTools";
import transJson from "../../translates/translate.json";
import { getList, selectItem, getItem } from "../store/actions";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";

const initState = {
    loading: false,
    step: 0,
    formData: {
        name: "",
        fullName: "",
        industry: "",
        annualRevenue: "",
        status: "",
        phone: "",
        fax: "",
        website: "",
        tickerSymbol: "",
        employees: "",
        address: {
            country: "",
            province: "",
            city: "",
            street: "",
        },
        description: "",
        ownership: "",
        labels: [],
        avatar: "",
    },
};

// const TabPanel = (props) =>  {
//     const { children, value, index, ...other } = props;
//
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`vertical-tabpanel-${index}`}
//             className="bg-grey-lighter flex-grow"
//             style={{borderRadius: "0 5px 5px 0"}}
//             aria-labelledby={`vertical-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <div>
//                     {children}
//                 </div>
//             )}
//         </div>
//     );
// };

class CompanyDialog extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(transJson);
    }

    state = initState;

    handleFormData = (name) => (event) => {
        const formData = {...this.state.formData};
        formData[name] = event.target.value;
        this.setState({
            formData,
        });
    };

    componentDidMount = () => {
        const {selectedItem} = this.props;
        if (selectedItem) {
            this.setData(selectedItem);
        }
    };

    componentDidUpdate = (prevProps) => {
        const {selectedItem} = this.props;
        if (selectedItem !== prevProps.selectedItem) {
            selectedItem ? this.setData(selectedItem) : this.clearData();
        }
    };

    componentWillUnmount = () => {
        this.clearData();
    };

    setData = (selectedItem) => {
        const selectedLabels = [];
        selectedItem.labels.map((_i) => selectedLabels.push(_i.id));
        this.setState({
            initLabels: selectedItem.labels.map((item) => ({
                value: item.id,
                color: item.color,
                label: <strong style={{color: item.color}}>{item.title}</strong>,
            })),
            selectedLabels,
            formData: {
                ...selectedItem,
                status: selectedItem.status ? selectedItem.status.key : "",
                industry: selectedItem.industry ? selectedItem.industry.key : "",
                ownership: selectedItem.ownership ? selectedItem.ownership.key : "",
            },
        });
    };

    clearData = () => {
        this.setState(initState);
    };

    filterData = () => {
        const search = {...this.props.search};
        this.props.getList(search);
        delete search.keyword;
        delete search.content;
        localStorage.setItem("companiesFilter", stringify({...search}));
    };

    onSubmit = (e, quick) => {
        e.preventDefault();
        const pathName = this.props.location.pathname;
        const {step} = this.state;
        if (step !== 2 && !quick) {
            this.setState((state) => ({step: state.step + 1}));
            return;
        }
        const {search, selectedItem, turnOn, showMessage, getItem, match, item, replaceParams} = this.props;
        this.setState({loading: true});
        let formData = this.state.formData;
        if (selectedItem) {
            formData.id = selectedItem.id;
        }

        let request = selectedItem ? axios.put(`${crmPrefix}/company`, formData) : axios.post(`${crmPrefix}/company`, formData);
        request
            .then((res) => {
                this.setState({loading: false});
                showMessage({
                    message: "message.Item Saved!",
                    variant: "success",
                });
                if (item) {
                    getItem(item.id);
                } else {
                    turnOn("companiesSideFilter");
                    replaceParams({...match.params, size: search.size}).then(() => this.filterData());
                }
                if (this.props.selectedGridListType) {
                    const notifiName = getGridListAsycnNotificationName(this.props.selectedGridListType);
                    EventBus.dispatch(notifiName, notifiName);
                } else if (pathName === "/marketing/contacts") {
                    EventBus.dispatch(UPDATE_COMPANY_SELECTOR_AFTER_CREATE_A_COMPANY_NOTIFI, res.data);
                }
                this.clearData();
                this.closeDialog();
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    onChangeDueDate = (date) => {
        // console.log(date);
        this.setState({dueDate: date});
    };

    closeDialog = () => {
        this.props.turnOff("company");
        this.props.selectItem(null);
    };

    goBack = () => {
        if (this.state.step !== -1) this.setState((state) => ({step: state.step - 1}));
    };

    render() {
        const {selectedItem, dialogs} = this.props;
        const {loading, formData, step} = this.state;

        const disableSubmit = formData.name.trim() === "";
        // function a11yProps(index) {
        //     return {
        //         id: `vertical-tab-${index}`,
        //         'aria-controls': `vertical-tabpanel-${index}`,
        //     };
        // }

        const stepsProps = {
            handleFormData: this.handleFormData,
            onSubmit: this.onSubmit,
            step,
            selectedItem,
            formData,
            goBack: this.goBack,
            closeDialog: this.closeDialog,
        };

        return (
            <div className={loading ? "loading" : null}>
                <Step0 {...stepsProps} />
                {step > 0 && <Step1 {...stepsProps} />}
                {step > 1 && <Step2 {...stepsProps} />}

                <DialogActions>
                    <Button color="default" onClick={this.closeDialog}>
                        <Translate id="_.cancel" />
                    </Button>
                    {step < 2 && (
                        <>
                            <Button disabled={disableSubmit} color="secondary" onClick={(e) => this.onSubmit(e, true)}>
                                <Translate id="_.quick save" />
                            </Button>

                            <Button color="secondary" variant={"contained"} disabled={disableSubmit} onClick={this.onSubmit}>
                                <Translate id="_.next" />
                            </Button>
                        </>
                    )}
                    {step === 2 && (
                        <Button color="secondary" variant={"contained"} onClick={this.onSubmit}>
                            <Translate id="_.save" />
                        </Button>
                    )}
                </DialogActions>
            </div>
        );

        // return ( isElectron() ? dialogContent :
        //     <Dialog
        //         maxWidth="md"
        //         fullScreen={this.props.fullScreen}
        //         open={!!dialogs["company"]}
        //         className={"overflow-visible"}
        //         classes={{paper: step === 0 ?  this.props.fullScreen ? "overflow-scroll": "overflow-visible" : "overflow-y-scroll"}}
        //         onClose={this.closeDialog} fullWidth>
        //         {dialogContent}
        //     </Dialog>
        // );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: turnOff,
            minimize: minimize,
            turnOn: turnOn,
            startLoading: startLoading,
            finishLoading: finishLoading,
            showMessage: showMessage,
            replaceParams: replaceParams,
            getList: getList,
            selectItem: selectItem,
            getItem: getItem,
        },
        dispatch
    );
}

function mapStateToProps({marketing, fuse, user}) {
    return {
        selectedItem: marketing.companies.list.selected,
        selectedGridListType: marketing.labels.selectedGridListType,
        item: marketing.companies.item,
        search: fuse.search,
        user: user.profile,
    };
}

export default withDialog(
    "company",
    "sm"
)(
    withRouter(
        withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(CompanyDialog)))
    )
);
