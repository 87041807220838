import {Grid, Icon, IconButton, MenuItem, TextField} from "@material-ui/core";
import {MyTooltip} from "@ui";
import {Fragment, useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useSelector} from "react-redux";

const ContactSocial = ({formData, handleFormData, activeLanguage}) => {

    /*extraContactInfos*/

    const extraInfos = useSelector(({marketing}) => marketing.companyContacts.list.extraContactInfos);

    const [contactInfoTypes, setContactInfoTypes] = useState([]);

    useEffect(() => {
        if (extraInfos) {
            // const temp = extraInfos.map(item=> { return  {"type": item, "value": "" } });
            setContactInfoTypes([...extraInfos]);
        }
    }, []);


    return (
        <>
            <Grid item xs={6} md={6}>
                <Translate>
                    {({translate}) => <TextField
                        inputProps={{maxLength: 60}}
                        fullWidth
                        label={translate("market.Phone")}
                        value={formData.phone}
                        onChange={handleFormData("phone")} />}
                </Translate>
            </Grid>
            <Grid item xs={formData.contactInfos === undefined || formData.contactInfos === null || formData.contactInfos.length === 0 ? 5 : 6}>
                <Translate>
                    {({translate}) => <TextField
                        inputProps={{maxLength: 60}}
                        fullWidth
                        placeholder={translate("market.Main email")}
                        label={translate("market.Email")}
                        value={formData.email}
                        onChange={handleFormData("email")} />}
                </Translate>
            </Grid>
            {formData.contactInfos === undefined || formData.contactInfos === null || formData.contactInfos.length === 0 && <>
                <Grid item xs={1} md={1} className={"flex items-end"}>
                    <MyTooltip title={<Translate id={"market.add extra contacts"} />}>
                        <IconButton size={"small"} onClick={() => {
                            // console.log(' 点击 添加 额外的 类型 ', contactInfoTypes);
                            handleFormData("contactInfos")({target: {value: [{type: contactInfoTypes[0], value: ""}]}});
                        }}>
                            <Icon className={"text-green"}>add_circle</Icon>
                        </IconButton>
                    </MyTooltip>
                </Grid>
            </>}
            {formData.contactInfos !== undefined && formData.contactInfos !== null && formData.contactInfos.length > 0 && <>
                {
                    formData.contactInfos.map((ite, index) =>
                        <Fragment key={index}>
                            <Grid item md={4}>
                                {<Translate>
                                    {({translate}) =>
                                        <TextField
                                            id={`standard-select-currency-${ite.type ? ite.type.key : index}`}
                                            select
                                            fullWidth
                                            label={translate("market.type")}
                                            // value={ activeLanguage.code === "cn" ? ite.type.value : ite.type.key }
                                            value={ite.type ? ite.type.key : ""}
                                            onChange={e => {
                                                // console.log('类型 onChange', e);
                                                if (formData.contactInfos.map(tem => tem.type.key).indexOf(e.target.value) === -1) {
                                                    let t = [...formData.contactInfos];
                                                    t = t.map(temp => temp.type.key === ite.type.key ? {
                                                        ...temp,
                                                        type: contactInfoTypes.filter(item2 => item2.key === e.target.value)[0]
                                                    } : temp);
                                                    // console.log('类型 onChange', t);
                                                    handleFormData("contactInfos")({target: {value: t}});
                                                }
                                            }}
                                        >
                                            {contactInfoTypes.map((option) => (
                                                <MenuItem key={option.key}
                                                          disabled={formData.contactInfos.map(tem => tem.type.key).indexOf(option.key) !== -1}
                                                          value={option.key}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                </Translate>}
                            </Grid>

                            <Grid item md={5}>
                                {
                                    <Translate>
                                        {({translate}) =>
                                            <TextField
                                                id={`standard-basic-${ite.type}`}
                                                fullWidth
                                                label={translate("market.value")}
                                                value={ite.value}
                                                onChange={e => {
                                                    let t = [...formData.contactInfos];
                                                    t = t.map(opt => opt.type.key === ite.type.key ? {
                                                        ...opt,
                                                        value: e.target.value
                                                    } : opt);
                                                    handleFormData("contactInfos")({target: {value: t}});
                                                }} />
                                        }
                                    </Translate>
                                }
                            </Grid>

                            <Grid item md={3} className={"flex items-center"}>
                                <MyTooltip title={<Translate id={"market.remove current contact"} />}>
                                    <IconButton onClick={() => {
                                        let t = [...formData.contactInfos];
                                        t = t.filter(opt => opt.type.key !== ite.type.key);
                                        handleFormData("contactInfos")({target: {value: t}});
                                    }}>

                                        {/*<MinusSvgIcon style={{ color: "#ff5252" }}/>*/}
                                        <Icon className={"text-red"}>remove_circle</Icon>
                                    </IconButton>
                                </MyTooltip>
                                <MyTooltip title={<Translate id={"market.add extra contacts"} />}>
                                  <span>
                                      <IconButton
                                          disabled={formData.contactInfos !== undefined && formData.contactInfos !== null && contactInfoTypes !== undefined && formData.contactInfos.length === contactInfoTypes.length}
                                          onClick={() => {

                                              const tempType = contactInfoTypes.filter(opti => formData.contactInfos.map(temObj => temObj.type.key).indexOf(opti.key) === -1)[0];
                                              const t = [
                                                  ...formData.contactInfos,
                                                  {"type": tempType, "value": ""}
                                              ];
                                              handleFormData("contactInfos")({target: {value: t}});
                                          }}>
                                        <Icon className={"text-green"}>add_circle</Icon>
                                    </IconButton>
                                  </span>
                                </MyTooltip>
                            </Grid>
                        </Fragment>)
                }

            </>}
        </>
    );
};

export default withLocalize(ContactSocial);
