import {useEffect, useState} from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import axios, {crmPrefix} from "../../../../myaxios";
import StepButton from "@material-ui/core/StepButton";
import * as fuseActions from "@fuseActions";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import Card from "@material-ui/core/Card";

export default function StageLine({sourceItem, canEdit, refresh}) {
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);
    const [completed, setCompleted] = useState(new Set());
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        getSteps();
    }, []);

    const getSteps = () => {
        axios.get(`${crmPrefix}/salesEnumProperties`, {
            params: {type: "SalesDealStage"}
        }).then(({data}) => {
            setSteps(data);
            handleStep(data.findIndex(__ => __.key === sourceItem.stage.key));
        });
    };

    const onChangeStep = (index, step) => ()=> {
        const data = {
            dealId: sourceItem.id,
            stage: step
        };
        setLoading(true);
        axios.put(`${crmPrefix}/dealSetStage`, data).then(() => {
            setLoading(false);
            handleStep(index);

            refresh();
            dispatch(fuseActions.showMessage({message: "message.Stage has changed.",
                variant: "success",}));
        }).catch(()=>{
            setLoading(false);
        });
    };

    const handleStep = (index) => {
        const newCompleted = new Set();
        for (let i = 0; i < index; i++) {
            newCompleted.add(i);
        }
        setCompleted(newCompleted);
        setActiveStep(index);
    };

    return (
        <Card className={classNames("m-20 mb-0 w-full ", loading && "loading")}>
            <Stepper classes={{root: " overflow-x-scroll"}} activeStep={activeStep}  alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={step.key}>
                        <StepButton disabled={!canEdit} onClick={onChangeStep(index, step)} completed={completed.has(index)}>
                            {step.value}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Card>
    );
}
