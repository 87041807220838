import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios, {firmPrefix} from "myaxios";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import SelectButtons from "../../../../utils/Forms/SelectButtons";
import { showMessage } from "@fuseActions";
import useWPS from "../useWPS";
import {useWorkPlan} from "./useWorkPlan";

const pins = [
    {
        value: 3,
        label: <Translate id={"wps.3 days"} />
    },
    {
        value: 7,
        label: <Translate id={"wps.7 days"} />
    },
    {
        value: 15,
        label: <Translate id={"wps.15 days"} />
    },
    {
        value: 30,
        label: <Translate id={"wps.30 days"} />
    }
];

const PinWP = () => {
    const dispatch = useDispatch();
    const {wp, refresh} = useWorkPlan();
    const {getData} = useWPS();

    const [loading, setLoading] = useState(false);
    // const location = useLocation();

    const pinWP = () => (days) => {
        if (days === wp?.pin) {
            setLoading(true);
            axios.put(`${firmPrefix}/pinWorkplan`, {workplanId: wp?.id, days: 0}).then(() => {
                setLoading(false);
                refresh();
                dispatch(showMessage({message: "message.Workplan pinned!", variant: "success"}));
            });
        } else {
            setLoading(true);
            axios.put(`${firmPrefix}/pinWorkplan`, {workplanId: wp?.id, days}).then(() => {
                setLoading(false);
                refresh();
                // if (location.pathname === "/wps/myWorkplans" || location.pathname === "/wps/list")
                getData();
                dispatch(showMessage({message: "message.Workplan pinned!", variant: "success"}));
            });
        }
    };

    return (
        wp && (
            <Card className={loading ? "loading mb-20" : "mb-20"}>
                <CardContent>
                    <Typography className={"mb-4"} variant={"h6"}>

                        <Translate id={"wps.Pin"} />
                    </Typography>
                    <SelectButtons
                        data-cy={"pin-wp"}
                        type={"pin"}
                        items={pins}
                        formData={{pin: wp.pin}}
                        onChange={pinWP}
                    />
                </CardContent>
            </Card>
        )
    );
};

export default PinWP;
