import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import type {FC} from "react";
import {useRef} from "react";
import {useDg, useDGDef, useDGFilters} from "../../hooks/hooks";
import useDatagrids from "../../hooks/useDatagrids";
import type {DGColType} from "../../types";
import {colDefaultMinWidth} from "../../types";
import EditColHeadMenu from "./EditTH";
import FilterBtn from "./THFilterBtn";

type Props = {
    col: DGColType;
};

// const viewItemIcon: Record<ViewType, string> = {
//     number: "pin",
//     date: "date_range",
//     currency: "attach_money",
//     file: "folder",
//     singleOption: "arrow_drop_down",
//     multiOption: "list",
//     checkbox: "check_box",
//     user: "person",
//     thumb: "image",
//     text: "",
// };

const TH: FC<Props> = (props) => {
    const {col} = props;
    const {permission, isOwner, colsWidth, tempColsWidth, id, cols} = useDGDef();
    const {onResizeCol} = useDg();
    const {onSort, sortedBy, sortOrder} = useDGFilters();
    const {updateDataGrid} = useDatagrids(id);

    // const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    const minW = col.viewType === "checkbox" ? 29 : colDefaultMinWidth;
    const resizeColumn = (event) => {
        // if (col.viewType === "checkbox") return;

        const element = event.target.parentElement;
        const rect = element.getBoundingClientRect();
        const startX = event.clientX;
        document.addEventListener("mousemove", moveAt);

        function moveAt(e) {
            document.body.style.cursor = "col-resize";
            document.body.style.userSelect = "none";
            const spanHeight = Math.round(document.getElementById("datagrid-inner")?.offsetHeight || 0);
            if (!!spanRef && !!spanRef.current) spanRef.current.style.height = spanHeight + "px";

            event.target.classList.add("bg-blue");
            event.target.classList.add("border-none");

            const diff = e.clientX - startX;
            const newWidth = Math.round(Math.max(minW, rect.width + diff)); // Prevent values less than 100

            if (newWidth > minW && newWidth < 1000) {
                onResizeCol(col.id, newWidth);
            }
        }

        function mouseUp(e: MouseEvent) {
            document.removeEventListener("mousemove", moveAt);
            document.body.style.removeProperty("cursor");
            document.body.style.removeProperty("user-select");
            event.target.classList.remove("bg-blue");
            event.target.classList.remove("border-none");
            const diff = e.clientX - startX;
            const newWidth = Math.round(Math.max(minW, rect.width + diff));
            if (newWidth >= minW && newWidth < 1000) {
                if (isOwner) {
                    const t = [...cols];
                    const index = t.findIndex((x) => x.id === col.id);
                    t[index] = {...col, minWidth: newWidth, modifiedTime: +new Date()};
                    updateDataGrid({
                        cols: t,
                        lastModified: +new Date(),
                        colsWidth: {...colsWidth, ...tempColsWidth, [col.id]: newWidth},
                    }).then(() => {
                        document.removeEventListener("mouseup", mouseUp);
                    });
                } else {
                    updateDataGrid({colsWidth: {...colsWidth, ...tempColsWidth, [col.id]: newWidth}}).then(() => {
                        document.removeEventListener("mouseup", mouseUp);
                    });
                }
            }
            if (!!spanRef && !!spanRef.current) spanRef.current.style.removeProperty("height");
        }

        document.addEventListener("mouseup", mouseUp);
    };

    const colWidth = tempColsWidth && tempColsWidth[col.id] ? tempColsWidth[col.id] : colsWidth[col.id] ? colsWidth[col.id] : minW;

    return (
        <div
            // onMouseEnter={() => setHoveredCol(col.id)}
            // onMouseLeave={() => setHoveredCol("")}
            ref={ref}
            className={clsx(
                "font-bold  hover:bg-gray-100 flex justify-between bg-white flex-shrink-0  min-h-24  items-center  relative  border-gray-200  dghider",
                col.className,
                col.viewType === "checkbox" ? "" : "min-w-52",
                col.modifiedTime && "bg-orange-lightest"
            )}
            style={{width: colWidth, backgroundColor: col.color}}>
            {/* region sort and title */}
            <div
                className={clsx(col.sort && "cursor-pointer", sortedBy === col.id && "text-green", "pl-2 flex items-center")}
                onClick={() => {
                    col.sort && col.viewType !== "checkbox" && onSort(col.id);
                }}>
                {/*{col.viewType !== "text" && <Icon className={"align-middle  text-22 mr-1"} children={viewItemIcon[col.viewType]} />}*/}
                {col.label && <span>{col.label}</span>}
                {col.sort && col.viewType !== "checkbox" && (
                    <Icon className={clsx(sortedBy !== col.id && "dghid", "align-middle  text-16")} fontSize={"inherit"}>
                        {sortOrder === "asc" ? "arrow_upward" : "arrow_downward"}
                    </Icon>
                )}
            </div>
            {/* endregion */}

            {/* region resize indicator */}
            <span
                className={"border-r-2 border-l-2 bg-white border-dotted   border-gray-300 h-full w-6 absolute right-0  top-0"}
                onMouseDown={(e) => resizeColumn(e)}
                style={{cursor: "col-resize"}}
            />

            <span ref={spanRef} className={"absolute w-1 bg-blue top-0 right-0"} />
            {/* endregion */}

            {/* region filter and edit btn */}
            <div
                className={clsx("flex items-center dghid bg-gray-100 h-full mr-8 absolute", colWidth < 50 ? "left-0 top-full" : "  right-0  top-0 ")}>
                <FilterBtn col={col} />
                {(permission === "manage" || isOwner) && <EditColHeadMenu key={col.id} col={col} />}
            </div>
            {/* endregion */}
        </div>
    );
};

export default TH;
