import {Translate} from "react-localize-redux";
import type {EnumType} from "types";

export const deliveryOrderStatuses: {[key: string]: EnumType} = {
    OPEN: {icon: "work_outline", value: "OPEN", color: "green", label: <Translate id={"os.status.OPEN"} />},
    PENDING: {
        icon: "hourglass_empty",
        value: "PENDING",
        color: "orange",
        label: <Translate id={"os.status.PENDING"} />,
    },
    SHIPPING: {
        icon: "local_shipping",
        value: "SHIPPING",
        color: "blue",
        label: <Translate id={"os.status.SHIPPING"} />,
    },
    CONFIRMED: {icon: "done_all", value: "CONFIRMED", color: "purple", label: <Translate id={"os.status.CONFIRMED"} />},
    BILLABLE: {icon: "attach_money", value: "BILLABLE", color: "pink", label: <Translate id={"os.status.BILLABLE"} />},
    CLOSE: {icon: "cancel", value: "CLOSE", color: "black", label: <Translate id={"os.status.CLOSE"} />},
    DELETE: {icon: "delete", value: "DELETE", color: "grey", label: <Translate id={"os.status.DELETE"} />},
};

export const orderStatuses: {[key: string]: EnumType} = {
    OPEN: {value: "OPEN", color: "green", label: <Translate id={"os.status.OPEN"} />},
    PENDING: {value: "PENDING", color: "orange", label: <Translate id={"os.status.PENDING"} />},
    CLOSE: {value: "CLOSE", color: "black", label: <Translate id={"os.status.CLOSE"} />},
    DELETE: {value: "DELETE", color: "grey", label: <Translate id={"os.status.DELETE"} />},
};

export const orderTypes: {[key: string]: EnumType} = {
    STOCK: {value: "STOCK", label: <Translate id={"os.STOCK"} />},
    ORDER: {value: "ORDER", label: <Translate id={"os.ORDER"} />},
};

export const deliveryOrderTypes: {[key: string]: EnumType} = {
    PROJECT: {value: "PROJECT", label: <Translate id={"os.PROJECT"} />, color: "pink"},
    WAREHOUSE: {value: "WAREHOUSE", label: <Translate id={"os.WAREHOUSE"} />, color: "green"},
};

export const receiptStatuses: {[key: string]: EnumType} = {
    OPEN: {icon: "work_outline", value: "OPEN", color: "green", label: <Translate id={"os.status.OPEN"} />},
    SHIPPING: {
        icon: "local_shipping",
        value: "SHIPPING",
        color: "blue",
        label: <Translate id={"os.status.SHIPPING"} />,
    },
    CLOSE: {icon: "cancel", value: "CLOSE", color: "black", label: <Translate id={"os.status.CLOSE"} />},
    DELETE: {icon: "delete", value: "DELETE", color: "grey", label: <Translate id={"os.status.DELETE"} />},
};

export const invoiceStatuses: {[key: string]: EnumType} = {
    OPEN: {value: "OPEN", color: "green", label: <Translate id={"os.invoice.OPEN"} />},
    CLOSE: {value: "CLOSE", color: "black", label: <Translate id={"os.invoice.CLOSE"} />},
};

export const validationStatuses: {[key: string]: EnumType} = {
    OPEN: {value: "OPEN", color: "green", label: <Translate id={"_.template OPEN"} />},
    CLOSE: {value: "CLOSE", color: "black", label: <Translate id={"_.template CLOSE"} />},
    DELETE: {icon: "delete", value: "DELETE", color: "grey", label: <Translate id={"os.status.DELETE"} />},
};

export const isDeliveryOrderValid = (status: string) => {
    return (
        status === deliveryOrderStatuses.OPEN.value ||
        status === deliveryOrderStatuses.PENDING.value ||
        status === deliveryOrderStatuses.SHIPPING.value
    );
};
