import {TableRow, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {green, red} from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import TableCell from "@material-ui/core/TableCell";

//const useStyles = makeStyles({
//    root: {
//        borderBottomWidth: (props) => (props.noBorder ? 0 : 1),
//    },
//});

//const useGStyles = makeStyles((theme) => {
//    return {
//        root: {
//            [theme.breakpoints.up("sm")]: {
//                "&:nth-of-type(even)": {
//                    backgroundColor: (props) => (props.xs === 12 ? theme.palette.grey[100] : theme.palette.background),
//                },
//                "&:nth-of-type(4n)": {
//                    backgroundColor: (props) => props.xs === 6 && theme.palette.grey[100],
//                },
//                "&.cus:nth-of-type(4n+1)": {
//                    backgroundColor: (props) => props.xs === 6 && theme.palette.grey[100],
//                },
//                "&.pus:nth-of-type(4n-1)": {
//                    backgroundColor: (props) => props.xs === 6 && theme.palette.grey[100],
//                },
//            },
//            [theme.breakpoints.down("sm")]: {
//                "&:nth-of-type(even)": {
//                    backgroundColor: theme.palette.grey[100],
//                },
//                "&:nth-of-type(odd)": {
//                    backgroundColor: theme.palette.background,
//                },
//            },
//        },
//    };
//});

// export function StyledTableCell(props) {
//     // const listView = useSelector(({fuse})=> fuse.dialogs["listView"]);
//     // const noBorder = props.dense || listView || props.last || true;
//     // const classes = useStyles({noBorder});
//     return <TableCell  {...props} />;
// }

//export function StyledGrid(props) {
//    const classes = useGStyles({xs: props.sm});
//    return (
//        <Grid {...props} classes={{...classes, ...props.classes, root: classNames(classes.root, props.classes.root)}} />
//    );
//}

/**
 *  @description - table 行高 当 listview 时，行高为 24，否则为 64
 */
//export const TABLE_ROW_HEIGHT_24 = 24;

/**
 *  @description - ViewportList行高 30，
 */
export const TABLE_ROW_HEIGHT_30 = 30;

/**
 *  @description - table 行高 当 listview 时，主要用于 Header 行高为 36，否则为 64
 */
export const TABLE_ROW_HEIGHT_36 = 36;

/**
 *  @description - table 的正常行高 64
 */
export const TABLE_ROW_HEIGHT_64 = 64;

export const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.grey[100],
        // },
        // '&.Mui-selected': {
        //     backgroundColor: theme.palette.primary[100],
        // },
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        },
    },
}))(TableRow);

export const StyledTableCell = withStyles((theme) => ({
    root: {
        borderRight: "1px solid " + theme.palette.grey[100],
        padding: "2px 5px",
    },
}))(TableCell);

StyledTableCell.defaultProps = {
    padding: "none",
    scope: "row",
};

// export const StyledGrid = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.grey[100],
//         },
//         '&:hover': {
//             backgroundColor: theme.palette.grey[200],
//         }
//     },
// }))(Grid);

export const StyledListItem = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.grey[100],
        //
        // },
        // '&.Mui-selected': {
        //     backgroundColor: theme.palette.primary[100],
        // },
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        },
    },
}))(ListItem);

export const ErrorButton = withStyles(() => ({
    root: {
        color: "white",
        backgroundColor: red[400],
        "&:hover": {
            backgroundColor: red[700],
        },
        "&.load .MuiButton-label": {
            "&:after": {
                marginLeft: 5,
                marginRight: -2,
                animation: " spin .7s linear infinite",
                content: "'\\E028'",
                font: "normal normal normal 15px/1 'Material Icons'",
            },
        },
    },
    disabled: {
        backgroundColor: red[200] + " !important",
        color: "white !important",
    },
}))(Button);

export const ChatColorButton = withStyles(() => ({
    root: {
        color: "white",
        background: "#07c160",
        "&:hover": {
            background: "#079d4f",
        },
    },
}))(Button);

//export const ChatColorIconButton = withStyles(() => ({
//    root: {
//        color: "white",
//        background: "#07c160",
//        "&:hover": {
//            background: "#079d4f",
//        },
//    },
//    label: {
//        color: "red",
//    },
//}))(IconButton);

export const SuccessButton = withStyles(() => ({
    root: {
        color: "white",
        backgroundColor: green[400],
        "&:hover": {
            backgroundColor: green[700],
        },
        "&.load .MuiButton-label": {
            "&:after": {
                marginLeft: 5,
                marginRight: -2,
                animation: " spin .7s linear infinite",
                content: "'\\E028'",
                font: "normal normal normal 15px/1 'Material Icons'",
            },
        },
    },
    disabled: {
        backgroundColor: green[200] + " !important",
        color: "white !important",
    },
}))(Button);

export const LoadingButton = withStyles(() => ({
    root: {
        "&.load .MuiButton-label": {
            "&:after": {
                marginLeft: 5,
                marginRight: -2,
                animation: " spin .7s linear infinite",
                content: "'\\E028'",
                font: "normal normal normal 14px/1 'Material Icons'",
            },
        },
    },
}))(Button);

//
// export const StyledTableCell = withStyles((theme) => ({
//     root: {
//         borderBottom: 0,
//     },
// }))(TableCell);
