import { useMUIDialog } from "@fuse/hooks";
import {GDialogRegistries} from "../../../../../../../@fuse/state/constant";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Translate} from "react-localize-redux";
import Button from "@material-ui/core/Button";
import {FirtStepView} from "./sub-views";
import {useEffect} from "react";

function LXExcelStartupDialog() {

    const {dialogProps,onTurnOffDialog} = useMUIDialog({
        registry: GDialogRegistries.lxExcelStartup,
        fullScreen: true,
        keepMounted:true,
    });

    useEffect(() => {
        console.debug("[LXExcelStartupDialog] > [useEffect] > dialogProps: ",dialogProps);
    }, [dialogProps]);



    const handleClose = () => {
        onTurnOffDialog();
    };


    return (
        <>
            <Dialog {...dialogProps}>
                <DialogTitle id="alert-dialog-slide-title">
                    {<Translate id={"_.LXExcelStartupDialog"}/>}
                </DialogTitle>
                <DialogContent className="p-5">
                    <FirtStepView />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {<Translate id={"_.cancel"}/>}
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        {<Translate id={"_.next"}/>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default LXExcelStartupDialog;

