import {finishLoading, startLoading} from "@fuseActions";
import axios, {flowPrefix} from "../../../../../myaxios";

export const GET_DEPLOYMENT_XML = "[GET_DEPLOYMENT_XML]";

export function getXML(params, callback, catchError) {

    const request = axios.get(`${flowPrefix}/getDeploymentXml`, {
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_DEPLOYMENT_XML,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        }).catch(() => {
            if (catchError instanceof Function) {
                catchError();
            }
        });
    };
}



