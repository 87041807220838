import {useState} from "react";
import {DialogContent, Grid, TextField} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import {EnumFormControl, LabelsFormControl} from "../../marketingTools";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";

const Step2 = ({formData, handleFormData}) => {
    const [complete, setComplete] = useState(false);

    return (
        <div className={classNames("max-h-0", complete ? "overflow-visible" : " overflow-hidden")}>
            <Divider className="mt-16" />
            <DialogContent className={"overflow-visible"}>
                <Typography variant={"h6"}>
                    <Translate id={"market.More Information"} />
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <EnumFormControl
                            type={"SalesContactRelationShip"}
                            name={"relationShip"}
                            formData={formData}
                            handleFormData={handleFormData}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <EnumFormControl type={"SalesContactLeadSource"} name={"leadSource"} formData={formData} handleFormData={handleFormData} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="standard-department"
                                    fullWidth
                                    value={formData.department}
                                    onChange={(e) => handleFormData("department")({target: {value: e.target.value}})}
                                    placeholder={translate("market.Enter department name")}
                                    label={translate("market.Department")}
                                />
                            )}
                        </Translate>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <LabelsFormControl source={"contact"} formData={formData} handleFormData={handleFormData} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => (
                                <TextField
                                    id="standard-street"
                                    value={formData.assistant}
                                    fullWidth
                                    onChange={(e) => handleFormData("assistant")({target: {value: e.target.value}})}
                                    label={translate("market.assistant")}
                                />
                            )}
                        </Translate>
                    </Grid>
                </Grid>
            </DialogContent>
        </div>
    );
};

export default Step2;
