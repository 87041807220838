import {Icon, TableCell, TableSortLabel} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {MyTooltip} from "@ui";
import clsx from "clsx";
import {Translate} from "react-localize-redux";
import type {columnType} from "types";

type Props<T> = {
    onClick(): void;
    column: columnType<T>;
    hides?: Set<string>;
    orderBy?: string;
    direction?: "asc" | "desc";
    setHides?: (arg: Set<string>) => void;
    toggleHide?: () => void;
};
const HeadCellView = <T, >({column, orderBy, direction, hides, onClick, toggleHide}: Props<T>) => {
    return (
        <TableCell
            sortDirection={orderBy === column.id ? direction : false}
            className={clsx(column.className, "relative hider nowrap", column.hideInMobile && "hidden md:table-cell")}
            padding={column.padding}
            align={column.align}
        >
            {!column.noHide && (
                <IconButton onClick={toggleHide} size={"small"} className={"hid absolute top-1/5 z-99 right-0"}>
                    <Icon fontSize={"small"}>{!hides?.has(column.id) ? "visibility_off" : "double_arrow"}</Icon>
                </IconButton>
            )}
            {column.sort ? (
                <MyTooltip
                    title={<Translate id="_.Click to Sort" />}
                    enterDelay={300}
                >
                    <TableSortLabel active={orderBy === column.id} direction={direction} onClick={onClick}>
                        {column.label && <Translate id={column.label} />}
                        {typeof column.extraLabel === "function" && column.extraLabel()}
                    </TableSortLabel>
                </MyTooltip>
            ) : (
                column.label && (
                    <>
                        {column.customLabel ? column.label : <Translate id={column.label} />}
                        {typeof column.extraLabel === "function" && column.extraLabel()}
                    </>
                )
            )}
        </TableCell>
    );
};

export default HeadCellView;
