import axios, {userPrefix} from "@api";
import {showMessage} from "@fuseActions";
import {ClickAwayListener, Icon, Paper, Popper, Tab, Tabs} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import type {TextFieldProps} from "@material-ui/core/TextField";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import type {AutocompleteProps} from "@material-ui/lab/Autocomplete/Autocomplete";
import {ThemeProvider} from "@material-ui/styles";
import {UserView} from "@ui";

import {useDebounceEffect} from "ahooks";
import clsx from "clsx";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {usePersonalized} from "../../hooks/usePersonalized";
import type {ContactType} from "../../types";

type Props = {
    top?: boolean;
    name?: string;
    /**
     * @example user.id
     */
    defaultValue?: string;
    onChange: (contact?: ContactType) => void;
} & Omit<Partial<AutocompleteProps<ContactType, false, true, false>>, "onChange" | "defaultValue"> &
    Omit<TextFieldProps, "onChange" | "defaultValue">;

function UserPreviewSearch({
    name = "recommendUsers",
    multiple,
    defaultValue,
    label,
    required,
    onChange,
    variant,
    className,
    margin,
    InputProps,
    ...rest
}: Props) {
    // region hooks and states
    const dispatch = useDispatch();
    const {addLatest, getLatest} = usePersonalized();
    const mainTheme = useSelector(({fuse}) => fuse.settings.mainTheme);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [tabs, setTabs] = useState(new Set<string>());
    const [selectedTab, setSelectedTab] = useState<string>("all");
    const [latestReason, setLatestReason] = useState<string>();
    const [secondTab, setSecondTab] = useState("friend");
    // @ts-ignore if there is no null, it can't set the detault value
    const [value, setValue] = useState<AutocompleteProps<ContactType, false, true, false>["value"]>(null);
    const [searchText, setSearchText] = useState("");
    const [hasRec, setHasRec] = useState(false);
    // endregion

    useDebounceEffect(
        () => {
            if (searchText && latestReason === "input") {
                getOptions(searchText);
                setSelectedTab("keyword");
            }
        },
        [searchText],
        {wait: 800}
    );

    const getUser = () => {
        if (defaultValue) {
            setLatestReason(undefined);
            axios.get(`${userPrefix}/myContacts?userId=${defaultValue}`).then(({data}) => {
                if (data.content[0]) setValue(data.content[0]);
                else dispatch(showMessage({message: "You don't have this contact", variant: "success"}));
            });
        }
    };

    useEffect(() => {
        if (defaultValue && (!latestReason || latestReason === "reset")) {
            getUser();
        }
    }, [defaultValue]);

    const getOptions = (input?: string) => {
        setLoading(true);

        axios
            .get(`${userPrefix}/myContacts?showMyFirmUser=true&size=10`, {params: {keyword: input}})
            .then((res) => {
                const content = res.data ? (res.data.content ? res.data.content : res.data) : [];
                setOptions(content);
                const t = new Set<string>();
                content.forEach((i) => i.departmentNames.forEach((d) => t.add(d)));
                setTabs(t);
            })
            .finally(() => setLoading(false));
    };

    const setRecommended = (item: any) => {
        if (!name) return;
        addLatest(name, item);
        setHasRec(true);
    };

    const countSameElements = (data: any[]) => {
        const counts: {echo: number; item: any}[] = [];

        for (const item of data) {
            const key = item.value.id; // Create a unique key based on the object value
            const index = counts.findIndex((u) => key === u.item.id);
            if (index > -1) {
                counts[index].echo += 1;
            } else {
                counts.push({item: item.value, echo: 1});
            }
        }

        return counts;
    };

    const getRecommends = async () => {
        const w = await getLatest(name);

        if (w) {
            const es = countSameElements(w);
            if (es.length > 0) {
                setOptions(
                    es
                        .sort((a, b) => a.echo - b.echo)
                        .reverse()
                        .map((item) => item.item)
                );
                setHasRec(true);
                setSelectedTab("recommends");
            } else getOptions(undefined);
        } else getOptions(undefined);
    };

    useEffect(() => {
        if (name) getRecommends();
        else {
            getOptions();
            setHasRec(false);
        }
    }, []);

    const onChangeValue: AutocompleteProps<ContactType, false, true, false>["onChange"] = (event, newValue, reason) => {
        onChange(newValue);

        switch (reason) {
            case "clear":
                setValue(newValue);
                setLatestReason(undefined);
                break;
            case "select-option":
                setValue(newValue);
                setRecommended(newValue);
                setLatestReason(reason);
                break;
            default:
                break;
        }
    };

    const onInputChange: AutocompleteProps<ContactType, false, true, false>["onInputChange"] = (event, newInputValue, reason) => {
        setLatestReason(reason);
        setSearchText(newInputValue);
        // if(reason === "input") {
        //     handleTabChange(event, "keyword");
        // }
        // switch (reason) {
        //     case "clear":
        //         break;
        //     case "input":
        //         handleTabChange(event, "keyword");
        //         break;
        //     case "reset":
        //         break;
        //     default:
        //         break;
        // }
    };

    const handleTabChange = (event, newValue) => {
        if (newValue === "recommends") {
            getRecommends();
        } else if (newValue === "all") {
            getOptions();
        } else if (newValue === "keyword" && searchText.trim() !== "") {
            getOptions(searchText);
        }
        setSelectedTab(newValue);
    };

    const handleSecondTabChange = (event, newValue) => {
        setSecondTab(newValue);
    };

    const customPaper = (props) => {
        return (
            <ThemeProvider theme={mainTheme}>
                <Paper className={clsx(variant === "outlined" ? "" : "-mt-8", "min-w-360 ")} data-cy={"ups"}>
                    <Tabs
                        className={"border-b-1"}
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        onChange={handleTabChange}>
                        <Tab
                            data-cy={"ups-recommends"}
                            disabled={!hasRec}
                            value={"recommends"}
                            classes={{root: "min-w-0"}}
                            label={<Translate id={"_.Recent"} />}
                        />
                        <Tab data-cy={"ups-all"} value={"all"} classes={{root: "min-w-0"}} label={<Translate id={"_.All"} />} />
                        {searchText && !value && (
                            <Tab
                                value={"keyword"}
                                classes={{root: "min-w-0"}}
                                label={
                                    <div>
                                        <Translate id={"_.search"} />
                                    </div>
                                }
                            />
                        )}
                        {/*{[...tabs].map((t) => (*/}
                        {/*    <Tab key={t} value={t} classes={{root: "min-w-0"}} label={t} />*/}
                        {/*))}*/}
                    </Tabs>
                    {tabs.size > 1 && searchText && selectedTab === "keyword" && (
                        <Tabs
                            className={"border-b-1  min-h-36"}
                            value={secondTab}
                            indicatorColor="secondary"
                            textColor="primary"
                            variant="scrollable"
                            onChange={handleSecondTabChange}>
                            {[...tabs].map((t) => (
                                <Tab key={t} value={t} classes={{root: "min-w-0 py-0 min-h-36"}} label={t} />
                            ))}
                        </Tabs>
                    )}
                    {props.children}
                </Paper>
            </ThemeProvider>
        );
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={className}>
                <Autocomplete<ContactType, false, true, false>
                    data-cy={`ups-${name}`}
                    {...rest}
                    disableListWrap
                    disablePortal
                    classes={{endAdornment: variant === "outlined" ? "top-1" : "-top-2", listbox: "py-0"}}
                    inputValue={searchText}
                    onInputChange={onInputChange}
                    open={open}
                    onOpen={() => {
                        // handleTabChange(e, "recommends");
                        setOpen(true);
                    }}
                    onClose={(event, reason) => {
                        if (reason !== "blur") {
                            setOpen(false);
                            //     // handleTabChange(event, "recommends");
                        }
                    }}
                    openOnFocus
                    clearOnBlur={false}
                    multiple={multiple}
                    renderOption={(option) => <UserView departments={option?.departmentNames} user={option?.user} />}
                    getOptionLabel={(option) => option.user?.lastName + option.user?.firstName + "(@" + option.user?.username + ")"}
                    includeInputInList
                    onChange={onChangeValue}
                    value={value}
                    getOptionSelected={(option, value) => option.id === value.id}
                    options={options}
                    loading={loading}
                    blurOnSelect={false}
                    filterOptions={(options) => {
                        let t = [...options];
                        if (secondTab !== "friend") {
                            t = options.filter((op) => op.departmentNames?.includes(secondTab));
                        }
                        return t;
                    }}
                    popupIcon={<Icon fontSize={"large"}>expand_more</Icon>}
                    noOptionsText={<Translate id={"_.No options"} />}
                    loadingText={<Translate id={"_.Loading..."} />}
                    PaperComponent={customPaper}
                    PopperComponent={(props) => {
                        return <Popper {...props} />;
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                margin={margin || "normal"}
                                {...params}
                                label={label}
                                required={required}
                                variant={variant}
                                size={variant === "outlined" ? "small" : "medium"}
                                InputProps={{
                                    ...params.InputProps,
                                    ...InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        );
                    }}
                />
            </div>
        </ClickAwayListener>
    );
}

export default UserPreviewSearch;
