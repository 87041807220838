import clsx from "clsx";
import {useSelector} from "react-redux";

// import Card from "@material-ui/core/Card";

function FusePageSimpleHeader(props) {
    const mainThemeDark = useSelector(({fuse}) => fuse.settings.mainThemeDark);

    return (
        <div
            className={clsx(
                props.classes.header,
                "px-12 block pt-12  pb-0 md:flex md:items-center w-full md:justify-between md:mb-0  print:border-1 print:border-solid print:border-gray-300 print:rounded-8 print:pb-20"
            )}>
            {props.header && props.header}
        </div>
    );
}

export default FusePageSimpleHeader;
// <ThemeProvider theme={mainThemeDark}>
