import {lazy} from "react";

export const ConfigMeeting = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/InnerMeeting/:id/",
            component: lazy(() =>
                import("./single/SinglePage")
            )
        },
        {
            path     : "/marketing/meeting",
            component: lazy(() =>
                import("./list/Page")
            )
        },
        {
            path     : "/InnerMeeting",
            component: lazy(() =>
                import("./list/Page")
            )
        }
    ]
};
