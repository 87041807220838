import {lazy} from "react";

export const ConfigBCMProject = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/bcm/project/:id/",
            component: lazy(() => import("./single/Page")),
            title: {cn: "工程施工", en: "BCM Project Details"},
        },
        {
            path: "/bcm/projects",
            component: lazy(() => import("./list/Page")),
            title: {cn: "工程施工", en: "BCM Projects"},
        },
        {
            path: "/bcm/clients",
            component: lazy(() => import("./clients/Page")),
            title: {cn: "客户", en: "Clients"},
        },
    ],
};
