import {lazy} from "react";

export const contractConfig = {
    settings: {
        layout: {
        }
    },
    routes  : [
        {
            path     : "/contract/dashboard",
            component: lazy(() =>
                import("./dashboard/index")
            )
        },
        {
            path     : "/contract/:id/:type",
            component: lazy(() =>
                import("./contract/ContactPage")
            )
        },
        {
            path     : "/contract/:id/",
            component: lazy(() =>
                import("./contract/ContactPage")
            )
        },
        {
            path     : "/contract/:id",
            component: lazy(() =>
                import("./contract/ContactPage")
            )
        },

        {
            path     : "/contracts/list/:list",
            component: lazy(() =>
                import("./contracts/Contracts")
            )
        },
        {
            path     : "/contracts",
            component: lazy(() =>
                import("./contracts/Contracts")
            )
        }
    ]
};
