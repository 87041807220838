import {Fragment, useMemo, useState} from "react";
import {useMUIDialog, useRouterSwitch} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import {useList} from "../definitions/hooks";
import {useMemoizedFn, useMount} from "ahooks";
import useCommonUtils from "../../state/useCommonUtils";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Translate, withLocalize} from "react-localize-redux";
import {TableRoot} from "../../../../../utils/RootComponents";
import TableEmpty from "../../../../../utils/empty_placeholder/TableEmpty";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import {useProcessStore} from "../../state/useProcessStore";
import {useDefinitionStore} from "../definitions/state/useDefinitionStore";
import json from "../../translations/process_sources.json";
import {useStoreUtils} from "../definitions/state";

function NewDefinitionsInCategoryDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.definitionsInCategory,
    });

    const {goto} = useRouterSwitch();

    const {query, actions} = useStoreUtils();

    const {onChangeQuery} = actions;

    const {currentCategory, onChangeCurrentCategory} = useCommonUtils();

    const [params, setParams] = useState({category: currentCategory?.name, page: 1, size: 10});

    const {run, isMutating, data} = useList();

    const onChangeSelectedDefinition = useProcessStore((state) => state.onChangeSelectedDefinition);

    const onChangeCurrentDefinition = useDefinitionStore((state) => state.onChangeCurrentDefinition);

    useMount(() => {
        props.addTranslation(json);
        run(params).then(() => {});
    });

    const handleGoToDetail = useMemoizedFn((row) => (e) => {
        e.stopPropagation();
        onChangeSelectedDefinition(row);
        onChangeCurrentDefinition(row);
        goto(`/management/process/definition/${row.id}`);
        onTurnOffDialog();
    });

    const handleGotoDefinitions = useMemoizedFn((e) => {
        e.stopPropagation();
        const tq = {
            ...query,
            category: currentCategory?.name,
        };
        onChangeQuery(tq);
        goto(`/management/process/definitions`);
        onTurnOffDialog();
    });

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleQueryChange = useMemoizedFn((tps) => {
        setParams({
            ...params,
            ...tps,
        });
    });

    const rows = useMemo(() => {
        return [
            {
                id: "key",
                className: "text-nowrap",
                label: "process.key",
                align: "left",
                sort: true,
            },
            {
                id: "name",
                className: "text-nowrap",
                label: "process.name",
                align: "center",
                sort: true,
            },
            {
                id: "process.version",
                className: "text-nowrap text-center",
                label: "process.version",
                align: "center",
                sort: false,
            },
            {
                id: "category",
                className: "text-nowrap text-center",
                label: "process.category",
                align: "center",
                sort: true,
            },
            {
                id: "process.status",
                className: "text-nowrap text-center",
                label: "process.status",
                align: "center",
                sort: false,
            },
        ];
    }, []);

    return (
        <Dialog {...dialogProps}>
            <div className={isMutating ? "loading flex flex-col" : "flex flex-col"}>
                <DialogTitle className={"w-full"}>
                    <div className="flex flex-row flex-grow justify-between items-center">
                        {
                            <Translate>
                                {({translate}) => (
                                    <Typography className={"inline-flex"} variant={"h6"}>
                                        {translate("_.definitions in category with params", {
                                            name: currentCategory?.name,
                                        })}
                                    </Typography>
                                )}
                            </Translate>
                        }
                        <div className={"flex items-center"}>
                            <Button
                                disabled={!data?.data?.content || data?.data?.content?.length === 0}
                                onClick={handleGotoDefinitions}
                                variant="contained"
                                color="secondary"
                                startIcon={<Icon fontSize={"small"}>east</Icon>}
                            >
                                {<Translate id={"_.Go to definitions"} />}
                            </Button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <TableRoot
                        query={query}
                        onChangeQuery={handleQueryChange}
                        mutate={run}
                        totalPages={data?.data?.totalPages}
                        count={data?.data?.totalElements}
                        colSpan={5}
                    >
                        <TableHead>
                            <TableRow>
                                {rows.map((item, i) => (
                                    <TableCell align={item?.align} key={i}>
                                        {<Translate id={`${item.label}`} />}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!data && !!data?.data?.content && data?.data?.content?.length > 0 ? (
                                data?.data?.content?.map((row, index) => {
                                    return (
                                        <Fragment key={row?.id}>
                                            <TableRow
                                                className="h-64 cursor-pointer"
                                                hover
                                                key={row.id}
                                                onClick={handleGoToDetail(row)}
                                            >
                                                <TableCell align="left">{row?.key}</TableCell>

                                                <TableCell align="center">{row.name ? row.name : ""}</TableCell>

                                                <TableCell align="center" className=" justify-center">
                                                    {/*<VersionView data={row} />*/}
                                                    {row?.version}
                                                </TableCell>

                                                <TableCell align="center">{row.category}</TableCell>

                                                <TableCell align="center">
                                                    <Chip
                                                        color={"primary"}
                                                        size={"small"}
                                                        className={classNames(`font-bold text-white `)}
                                                        style={{
                                                            backgroundColor: row.suspended === false ? "green" : "red",
                                                        }}
                                                        label={
                                                            row.suspended === false ? (
                                                                <Translate id={`process.running`} />
                                                            ) : (
                                                                <Translate id={`process.suspended`} />
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    );
                                })
                            ) : (
                                <TableEmpty />
                            )}
                        </TableBody>
                    </TableRoot>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"} color={"secondary"}>
                        {<Translate id={"_.cancel"} />}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(NewDefinitionsInCategoryDialog);
