import {Button, Dialog, DialogContent, Icon} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import * as fuseActions from "@fuseActions";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import axios, {crmPrefix} from "../../../../myaxios";
import * as Actions from "../store/actions/index";

class SolutionDialog extends Component {
    state = {
        loading: false,

        license: "",
        brandId: "",
        brand: "",
        name: "",
        tag: "",
        city: "",

        description: "",

        error: false,

        openBrand: false,

        brands: [],

        anchorEl: null,
        anchorEl2: null,

        solutionID: "",

        value: "",
        errorMsg: "",
        checkerLoading: false,
    };

    error = (name, e) => {
        this.setState({[name]: e});
    };

    componentDidMount = () => {
        if (this.props.selectSolution) {
            this.setState({
                name: this.props.selectSolution.name,
                description: this.props.selectSolution.description,
                brand: this.props.selectSolution.brand.name,
                tag: this.props.selectSolution.tag,
                solutionID: this.props.selectSolution.id,
            });
        }
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleChangeCategory = (e) => {
        this.setState({cat: e.target.value});
    };

    onSubmit = () => {
        this.setState({loading: true});
        // if(!this.state.user) return;
        let formData;
        if (this.props.selectSolution)
            formData = {
                name: this.state.name,
                brand: this.props.brand.id,
                description: this.state.description,
                tag: this.state.tag,
                id: this.props.selectSolution.id,
            };
        else
            formData = {
                name: this.state.name,
                brand: this.props.brand.id,
                description: this.state.description,
                tag: this.state.tag,
            };
        // if (this.props.selectedBrand) formData.id = this.props.selectedBrand.id;
        let request = this.props.selectSolution ? axios.put(`${crmPrefix}/solution`, formData) : axios.post(`${crmPrefix}/solution`, formData);

        request
            .then(() => {
                this.setState({loading: false});
                this.props.turnOff("solution");
                this.props.showMessage({message: "message.Solution Saved!"});
                // this.props.getBrands();
                this.props.getSolutions({...this.props.location.search, brandId: this.props.brand.id});
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    render() {
        const {selectSolution, dialogs} = this.props;
        const {loading, anchorEl2, openBrand, value, error, errorMsg} = this.state;
        const id2 = openBrand ? "tag-menu" : null;
        return (
            <Dialog
                open={!!dialogs["solution"]}
                onClose={() => {
                    this.props.turnOff("solution");
                }}
                fullWidth
            >
                <div className={loading ? "loading" : null}>
                    <DialogTitle id="alert-dialog-title">
                        {selectSolution ? (
                            <>
                                <Translate id="crm.Edit" /> {selectSolution.name}{" "}
                            </>
                        ) : (
                            <Translate id="crm.New Solution" />
                        )}
                    </DialogTitle>

                    <DialogContent>
                        <Grid container>
                            <Grid item sm={6}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>build</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="mb-24 pr-20"
                                    fullWidth
                                    required
                                    onChange={this.handleChange("name")}
                                    label={<Translate id={"crm.Solution"} />}
                                    value={this.state.name}
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>branding_watermark</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="mb-24 pr-20"
                                    fullWidth
                                    required
                                    disabled
                                    label={<Translate id={"crm.Brand"} />}
                                    value={this.props.brand.name}
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>description</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="mb-24 pr-20"
                                    fullWidth
                                    required
                                    onChange={this.handleChange("description")}
                                    label={<Translate id={"crm.Description"} />}
                                    value={this.state.description}
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>bookmark</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="mb-24"
                                    fullWidth
                                    onChange={this.handleChange("tag")}
                                    label={<Translate id={"crm.Tag"} />}
                                    value={this.state.tag}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.props.turnOff("solution");
                            }}
                        >
                            <Translate id="_.cancel" />
                        </Button>
                        <Button
                            disabled={this.state.fileLoading || this.state.name === "" || this.state.nameError}
                            color="primary"
                            onClick={this.onSubmit}
                        >
                            <Translate id="_.submit" />
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: fuseActions.turnOff,
            showMessage: fuseActions.showMessage,
            // getBrands                   : Actions.getBrands
            getSolutions: Actions.getSolutions,
        },
        dispatch
    );
}

function mapStateToProps({fuse, commerce}) {
    return {
        dialogs: fuse.dialogs,
        categories: commerce.brands.categories,
        // selectedBrand   : crm.brands.selectedBrand,
        selectSolution: commerce.solutions.selectedSolution,
        brand: commerce.brand.data,
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(SolutionDialog)));
