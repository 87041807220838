import {Icon, IconButton, TablePagination, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {MyTooltip} from "@ui";
import _ from "lodash";
import {type ChangeEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Translate} from "react-localize-redux";
import {usePersonalized} from "../../../../hooks/usePersonalized";
import {useFilters} from "../hooks/filtersContext";

const Pagination = () => {
    const {t} = useTranslation();
    const {addFilter, totalElements, totalPages, filters} = useFilters();

    const {updateSettings} = usePersonalized();

    const [tempPage, setTempPage] = useState<string>("1");

    useEffect(() => {
        if (filters.page && filters.page !== _.toNumber(tempPage)) {
            setTempPage(String(filters.page));
        }
    }, [filters.page]);

    const onPageChange = (event: any, page: number) => {
        addFilter({page: _.toNumber(page) + 1});
    };

    const onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateSettings({rowPerPage: event.target.value});
        // localStorage.setItem("rowPerPage", event.target.value);
        addFilter({size: event.target.value});
    };

    const submitAble = _.toNumber(filters.page) > totalPages || _.toNumber(filters.page) === 0 || totalPages < 2;

    return (
        <div className={"md:flex items-center justify-end"}>
            <div className="justify-end items-center md:pt-0 pt-12 pr-16 flex ">
                <Typography>{t("Page")}:</Typography>

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        addFilter({page: _.toNumber(tempPage)});
                    }}>
                    <TextField
                        size={"small"}
                        disabled={totalPages < 2}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment classes={{root: "ml-0"}} position="end">
                                    <MyTooltip title={<Translate id={"_.Jump to"} />}>
                                        <span>
                                            <IconButton
                                                size={"small"}
                                                disabled={submitAble}
                                                aria-label="toggle password visibility"
                                                onClick={() => addFilter({page: _.toNumber(tempPage)})}>
                                                <Icon>fast_forward</Icon>
                                            </IconButton>
                                        </span>
                                    </MyTooltip>
                                </InputAdornment>
                            ),
                        }}
                        type={"number"}
                        className={"w-72 mx-2 pl-2 rounded-4 border-1 border-gray-200 border-solid "}
                        value={tempPage}
                        onChange={(e) => setTempPage(e.target.value)}
                    />
                </form>

                <Typography>
                    <Translate id={"_.totalPages"} />: &nbsp;
                    <span className={"text-20 align-middle"}>{totalPages}</span>
                </Typography>
            </div>
            <Translate>
                {({translate}: any) => (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 1000, 10000]}
                        classes={{toolbar: "pl-2"}}
                        component="div"
                        data-cy={"pagination"}
                        count={totalElements}
                        rowsPerPage={_.toNumber(filters.size)}
                        page={_.toNumber(filters.page) - 1}
                        labelDisplayedRows={({from, to, count}) => {
                            return translate("_.display rows with params", {from, to, count});
                        }}
                        size={"medium"}
                        labelRowsPerPage={<Translate id={"_.rowsPerPage"} />}
                        backIconButtonProps={{
                            "aria-label": translate("_.previous"),
                            className: "prev-page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": translate("_.next"),
                            className: "next-page",
                        }}
                        SelectProps={{className: "select-rpp"}}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                )}
            </Translate>
            {/*)}*/}
        </div>
    );
};

export default Pagination;
