import {GET_MASTER_FIRM, GET_REQUESTS, GET_UNREAD_DATA, REMOVE_USER_DATA, SET_USER_DATA, USER_LOGGED_OUT} from "../actions";

const user = function (
    state = {
        loggedIn: false,
    },
    action
) {
    switch (action.type) {
        case GET_MASTER_FIRM: {
            return {
                ...state,
                masterFirm: action.masterFirm,
            };
        }
        case GET_REQUESTS: {
            return {
                ...state,
                requests: action.requests,
            };
        }
        case GET_UNREAD_DATA: {
            return {
                ...state,
                unread: {...state.unread, [action.key]: action.value},
            };
        }
        //        case SET_TOKENS: {
        //            return {
        //                ...state,
        //                token: action.payload,
        //                loggedIn: true,
        //            };
        //        }
        case SET_USER_DATA: {
            return {
                ...state,
                ...action.payload,
                loggedIn: true,
            };
        }
        case REMOVE_USER_DATA: {
            return {loggedIn: false};
        }
        case USER_LOGGED_OUT: {
            return {loggedIn: false};
        }
        default: {
            return state;
        }
    }
};

export default user;
