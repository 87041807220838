import {Grow, Icon, IconButton, ListItem, ListItemText, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {Fragment, memo, useState} from "react";
import * as ReactDOM from "react-dom";
import {withLocalize} from "react-localize-redux";
import {Manager, Popper, Reference} from "react-popper";
import {withRouter} from "react-router-dom";
import {useDebounce} from "../../../hooks";
import FuseNavHorizontalCollapse from "./FuseNavHorizontalCollapse";
import FuseNavHorizontalItem from "./FuseNavHorizontalItem";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        "& .list-item-text": {
            padding: "0 0 0 16px"
        },
        "&.level-0": {
            height: 48,
            borderRadius: 4,
            "&:hover": {
                background: "transparent"
            }
        },
        "&.dense": {
            padding: "8px 12px 8px 12px",
            minHeight: 40,
            "&.level-0": {
                height: 44
            },
            "& .list-item-text": {
                padding: "0 0 0 8px"
            }
        }
    },
    children: {},
    popper: {
        zIndex: 999
    },
    popperClose: {
        pointerEvents: "none"
    }
}));

/**
 * @return {null}
 * @return {null}
 */
function FuseNavHorizontalGroup(props) {
    //    const userRole = useSelector(({user}) => user.role);

    const classes = useStyles(props);
    const [opened, setOpened] = useState(false);
    const {item, nestedLevel, dense} = props;

    const handleToggle = useDebounce((open) => {
        setOpened(open);
    }, 150);

    //    if (!FuseUtils.hasPermission(item.auth, userRole)) {
    //        return null;
    //    }

    return (
        <Manager>
            <Reference>
                {({ref}) => (
                    <div ref={ref}>
                        <ListItem
                            button
                            className={clsx("list-item ", classes.root, "relative", "level-" + nestedLevel, dense && "dense")}
                            onMouseEnter={() => handleToggle(true)}
                            onMouseLeave={() => handleToggle(false)}
                            aria-owns={opened ? "menu-list-grow" : null}
                            aria-haspopup="true"
                        >
                            {item.icon && (
                                <Icon color="action" className="text-20 flex-shrink-0">
                                    {item.icon}
                                </Icon>
                            )}
                            <ListItemText
                                className="list-item-text"
                                primary={props.activeLanguage.code === "cn" ? item.titleCn : item.title}
                                classes={{primary: "font-bold text-16 whitespace-pre"}}
                            />
                            {nestedLevel > 0 && (
                                <IconButton disableRipple className="w-16 h-16 ml-4 p-0">
                                    <Icon className="text-20 arrow-icon">keyboard_arrow_right</Icon>
                                </IconButton>
                            )}
                        </ListItem>
                    </div>
                )}
            </Reference>
            {ReactDOM.createPortal(
                <Popper placement={nestedLevel === 0 ? "bottom-start" : "right"} eventsEnabled={opened} positionFixed>
                    {({ref, style, placement, arrowProps}) =>
                        opened && (
                            <div
                                ref={ref}
                                style={{
                                    ...style,
                                    zIndex: 999 + nestedLevel
                                }}
                                data-placement={placement}
                                className={clsx(classes.popper, {[classes.popperClose]: !opened})}
                            >
                                <Grow in={opened} id="menu-list-grow" style={{transformOrigin: "0 0 0"}}>
                                    <Paper onMouseEnter={() => handleToggle(true)} onMouseLeave={() => handleToggle(false)}>
                                        {item.children && (
                                            <ul className={clsx(classes.children, "pl-0")}>
                                                {item.children.map((j) => (
                                                    <Fragment key={j.id}>
                                                        {(j.children && j.children.length > 0) ?
                                                            <FuseNavHorizontalCollapse item={j} nestedLevel={nestedLevel} dense={dense} />
                                                            : j.url && <FuseNavHorizontalItem item={j} nestedLevel={nestedLevel} dense={dense} />
                                                        }

                                                        {/* {item.type === "group" && ( */}
                                                        {/*     <NavHorizontalGroup item={item} nestedLevel={nestedLevel} dense={dense} /> */}
                                                        {/* )} */}

                                                        {/* {item.type === "collapse" && ( */}
                                                        {/*     <FuseNavHorizontalCollapse item={item} nestedLevel={nestedLevel} dense={dense} /> */}
                                                        {/* )} */}

                                                        {/* {item.type === "item" && ( */}
                                                        {/*     <FuseNavHorizontalItem item={item} nestedLevel={nestedLevel} dense={dense} /> */}
                                                        {/* )} */}

                                                        {/* {item.type === "link" && ( */}
                                                        {/*     <FuseNavHorizontalLink item={item} nestedLevel={nestedLevel} dense={dense} /> */}
                                                        {/* )} */}
                                                    </Fragment>
                                                ))}
                                            </ul>
                                        )}
                                    </Paper>
                                </Grow>
                            </div>
                        )
                    }
                </Popper>,
                document.querySelector("#root")
            )}
        </Manager>
    );
}

FuseNavHorizontalGroup.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        children: PropTypes.array
    })
};

FuseNavHorizontalGroup.defaultProps = {};

const NavHorizontalGroup = withRouter(memo(FuseNavHorizontalGroup));

export default withLocalize(NavHorizontalGroup);
