import {useMemoizedFn} from "ahooks";
import {useFMStore} from "./state/useFMStore";
import {useEffect} from "react";

function useFuseModel() {
    const open = useFMStore((state) => state.open);

    const onChangeOpen = useFMStore((state) => state.onChangeOpen);

    const onChangeClose = useFMStore((state) => state.onChangeClose);

    const options = useFMStore((state) => state.options);

    const onChangeModelOptions = useFMStore((state) => state.onChangeModelOptions);

    useEffect(() => {
        return () => {
            reSetOptions();
        };
    }, []);

    const reSetOptions = useMemoizedFn(() => {
        onChangeModelOptions(null);
        onChangeClose();
    });

    /**
     * @param {null} options
     * @param {string} options.type - Model Type，不同 type 对应不同的 lottie 动画
     * @param {array} options.actions - dialog actions
     * @param {any} options.content - dialog content
     */
    const showModel = useMemoizedFn((options) => {
        onChangeModelOptions(options);
        onChangeOpen();
    });

    const hideModel = useMemoizedFn(() => {
        onChangeClose();
    });

    return {
        open,
        options,
        showModel,
        hideModel,
    };
}

export default useFuseModel;
