import * as Actions from "../actions";

const itemReducer = function (state = null, action) {
    switch ( action.type )
    {
        case Actions.GET_ITEM:
            return {
                ...action.payload
            };
        case Actions.CLEAR_ITEM:
            return null;
        default:
            return state;
    }
};

export default itemReducer;
