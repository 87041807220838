import Card from "@material-ui/core/Card";
import WidgetWrapper from "app/dashboards/main/widgets/WidgetWrapper";
import FavoritePanel from "components/layout/fav/FavoritePanel";
import {Translate} from 'react-localize-redux';

const Favorites = (props: any) => {
    return (
        <WidgetWrapper {...props} title={<Translate id={"_.Favorite"} />} >
            <Card>
                <FavoritePanel />
            </Card>
        </WidgetWrapper>
    );
};

export default Favorites;
