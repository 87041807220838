import {Chip, Icon, IconButton} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {MyAvatar} from "@ui";
import {useDebounce} from "ahooks";
import {showMessage} from "app/store/actions";
import _ from "lodash";
import axios, {firmPrefix} from "myaxios";
import type {RefObject} from "react";
import {useEffect, useRef, useState} from "react";
import {renderToString} from "react-dom/server";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import type {dataType, SearchType, StaffType, userType} from "types";

export function pasteHtmlAtCaret(html) {
    let sel, range;
    if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            // Range.createContextualFragment() would be useful here but is
            // only relatively recently standardized and is not supported in
            // some browsers (IE9, for one)
            const el = document.createElement("div");
            el.innerHTML = html;
            const frag = document.createDocumentFragment();
            let node, lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            // const firstNode = frag.firstChild;
            range.insertNode(frag);

            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    }
    // else if (sel === document.selection && sel.type !== "Control") {
    //
    //     // IE < 9
    //     const originalRange = sel.createRange();
    //     originalRange.collapse(true);
    //     sel.createRange().pasteHTML(html);
    //     // if (selectPastedContent) {
    //     //     range = sel.createRange();
    //     //     range.setEndPoint("StartToStart", originalRange);
    //     //     range.select();
    //     // }
    // }
}

type Props = {
    mentionUsers?: userType[];
    mainInputRef: RefObject<HTMLElement>;
    getUsersReq?: Promise<{data: dataType<StaffType>}>;
    closeMentionMenu(): void;
};

const MentionUsers = ({mentionUsers, getUsersReq, mainInputRef, closeMentionMenu}: Props) => {
    const dispatch = useDispatch();
    const language = localStorage.getItem("i18nextLng");
    const mentionInputRef = useRef<HTMLInputElement>();
    const [users, setUsers] = useState<userType[]>();
    const [loading, setLoading] = useState(false);
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    const [searchText, setSearchText] = useState("");
    const debouncedValue = useDebounce(searchText, {wait: 1000}); // todo update to useDebounceEffect

    useEffect(() => {
        if (debouncedValue) getStaff({keyword: debouncedValue});
    }, [debouncedValue]);

    useEffect(() => {
        getStaff();
        // setTimeout(() => {
        //     mentionInputRef.current && mentionInputRef.current.focus();
        // }, 100);
    }, []);

    const setRecommended = (item) => {
        const w = localStorage.getItem("suggestedMention");
        const es = w ? JSON.parse(w) : [];
        const index = es.findIndex((u) => item.id === u.item.id);
        if (index > -1) {
            es[index].echo += 1;
        } else {
            es.push({item, echo: 1});
        }
        localStorage.setItem("suggestedMention", JSON.stringify(es));
    };

    const getRecommends = () => {
        const w = localStorage.getItem("suggestedMention");
        const es = w ? JSON.parse(w) : [];
        return es
            .sort((a, b) => a.echo - b.echo)
            .reverse()
            .map((item) => item.item);
    };

    const getStaff = (params?: SearchType) => {
        setLoading(true);
        const users: userType[] = [];

        const req =
            getUsersReq ||
            axios.get<dataType<StaffType>>(`${firmPrefix}/firm/staffs?size=10`, {
                params,
            });
        req.then(({data}) => {
            if (params) {
                data.content.map((_) => {
                    users.push(_.user);
                });
                setUsers(users);
            } else {
                const t = getRecommends();
                data.content.map((staff) => {
                    if (_.findIndex(t, ["id", staff.user.id]) === -1) users.push(staff.user);
                });
                setUsers([...t, ...users]);
            }
        }).finally(() => setLoading(false));
    };

    const regx =
        /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/;

    const mentionUser = (user: userType) => {
        closeMentionMenu();
        setRecommended(user);
        if (mainInputRef.current) {
            mainInputRef.current.focus();
            // const selection = window.getSelection();
            // const range = selection.getRangeAt(0);

            const htmlString = renderToString(
                <span>
                    <strong style={{fontSize: 16}}>
                        {regx.test(`${user.firstName}${user.lastName}`) ? `${user.lastName}${user.firstName}` : `${user.firstName} ${user.lastName}`}
                    </strong>
                    <small
                        style={{
                            fontSize: 14,

                            paddingLeft: 4,
                        }}>
                        @{user.username}
                    </small>
                </span>
            );
            setTimeout(() => {
                pasteHtmlAtCaret(
                    ` <em suppressContentEditableWarning={true} contenteditable="false" style="padding: 1px 5px;font-weight: bold;background-color: #e5e7eb;border: 0;display: inline-block;border-radius: 5px;"> ${htmlString} </em> &nbsp;`
                );
            }, 100);
            // setTimeout(() => {
            //     document.execCommand(
            //         "insertHTML",
            //         false,
            //         `<em contenteditable="false" class="px-2 py-1 font-bold bg-grey-light border-0 inline-block rounded-4">${htmlString}</em> &nbsp;`
            //     );
            // }, 100);
        } else {
            dispatch(showMessage({message: "_.Something went wrong, Please try again", variant: "success"}));
        }
    };

    const mentionableUsers = mentionUsers || users;

    return (
        <div className={"p-8 bg-white border-l-1 border-r-1"}>
            <Translate>
                {({translate}) => (
                    <TextField
                        InputProps={{
                            endAdornment: loading && <Icon className={"animate-spin"}>refresh</Icon>,
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        inputRef={mentionInputRef}
                        size={"small"}
                        placeholder={translate("_.Search user")}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="w-128 mr-8"
                    />
                )}
            </Translate>
            {mentionableUsers &&
                mentionableUsers.length > 0 &&
                mentionableUsers.map((_user, i) => (
                    <Chip
                        size={"small"}
                        className={"mr-8"}
                        key={i}
                        avatar={<MyAvatar size={16} user={_user} />}
                        label={language === "cn" ? _user.lastName + _user.firstName : _user.firstName + _user.lastName}
                        onClick={() => mentionUser(_user)}
                    />
                ))}
            <IconButton size={"small"} onClick={closeMentionMenu}>
                <Icon>close</Icon>
            </IconButton>
            {focused && (
                <Typography className={"text-red-light mt-2"}>
                    {<Translate id={"_.Please select a position in the content you want to add the mention"} />}
                </Typography>
            )}
        </div>
    );
};

export default MentionUsers;
