import {useDispatch, useSelector} from "react-redux";
import {useMemoizedFn} from "ahooks";
import * as Actions from "../../store/actions";

function useFetchDatas() {

    const dispatch = useDispatch();

    const search = useSelector(({fuse}) => fuse.search);

    /**
     * @param {Object} response  账号过滤列表返回数据
     * @param {array} response.content  账号过滤列表数组
     * @param {number} response.page  账号过滤列表的页数
     * @param {number} response.totalElements  账号总数
     */
    const response = useSelector(({income}) => income.accountFilterReducer.accountFilterRes);

    /**
     * @param {Object} detailsObject  账号过滤详情
     */
    const detailsObject = useSelector(({income}) => income.accountFilterReducer.accountFilterDetails);

    /**
     * @function fetchAccountFilters 获取账号列表的函数
     * @param {Object} options  筛选账号的参数
     */
    const fetchAccountFilters = useMemoizedFn((options=null)=>{
        const params = options || search;
        console.log("fetchAccountFilters options:", options);
        dispatch(Actions.getAccountFilters(params));
    });

    /**
     * @function fetchAccountFilterDetails 获取账号详情的函数
     * @param {string} id  账号的id
     */
    const fetchAccountFilterDetails = useMemoizedFn((id="")=>{
        dispatch(Actions.getAccountFilterDetails(id));
    });

    return {
        search,
        response,
        detailsObject,
        fetchAccountFilters,
        fetchAccountFilterDetails
    };
}

export default useFetchDatas;
