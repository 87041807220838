import {Button, Dialog, DialogContent} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {LocalTimeStamp, MyAvatar, NameLabel} from "@ui";
import {useMemoizedFn} from "ahooks";
import _ from "lodash";
import {Translate} from "react-localize-redux";
import TableEmpty from "../../../../../utils/empty_placeholder/TableEmpty";
import {useProcessStore} from "../../state/useProcessStore";
import {StatusColor} from "../utils";

function ViewMuiltTaskInAStepDialog(props) {
    const open = useProcessStore((state) => state.openMuiltTask);
    const muiltSteps = useProcessStore((state) => state.muiltSteps);
    const onChangeMuiltTaskInATaskDialog = useProcessStore((state) => state.onChangeMuiltTaskInATaskDialog);

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onChangeMuiltTaskInATaskDialog(false);
    });

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <div>
                {
                    <Translate>
                        {({translate}) => (
                            <DialogTitle>
                                {translate("_.muilt steps for task params", {name: _.last(muiltSteps)?.name})}
                            </DialogTitle>
                        )}
                    </Translate>
                }
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{<Translate id={"_.user"} />}</TableCell>
                                    <TableCell>{<Translate id={"_.name"} />}</TableCell>
                                    <TableCell align="right">{<Translate id={"_.status"} />}</TableCell>
                                    <TableCell align="right">{<Translate id={"_.create date"} />}</TableCell>
                                    <TableCell align="right">{<Translate id={"_.handle date"} />}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!muiltSteps && muiltSteps.length > 0 ? (
                                    muiltSteps.map((row, index) => {
                                        const task = _.head(row.tasks);
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    <div className={"flex flex-row"}>
                                                        <MyAvatar user={task.user} />
                                                        <div className={"flex flex-col ml-2"}>
                                                            <NameLabel user={task.user} />
                                                            <small className={"inline-flex"}>
                                                                {task.user.username}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Chip
                                                        className={`font-bold ${StatusColor(task)}`}
                                                        size="small"
                                                        label={<Translate id={`_.${task.status}`} />}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {(!!task.createTime && task.createTime !== 0) ||
                                                    task.createTime !== "" ? (
                                                        <LocalTimeStamp
                                                            datetime={task.createTime}
                                                            format={"YYYY-MM-DD"}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {(!!task.completeTime && task.completeTime !== 0) ||
                                                    task.completeTime !== "" ? (
                                                        <LocalTimeStamp
                                                            datetime={task.completeTime}
                                                            format={"YYYY-MM-DD"}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmpty />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        <Translate id="_.cancel" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default ViewMuiltTaskInAStepDialog;
