import { turnOff } from "@fuseActions";
import {Dialog} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {Component, forwardRef} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Empty from "utils/Empty";

const Transition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});
const marketingDialogWrapper = (dialogName, reducerName, maxWidth) => (ComposedComponent) => {
    class DialogWrapper extends Component {
        render() {
            const {item, fullScreen, dialogs} = this.props;
            return (
                <Dialog
                    scroll={"body"}
                    classes={{paper: "overflow-visible", root: "overflow-visible"}}
                    className={"overflow-visible"}
                    style={{zIndex: 1200}}
                    TransitionComponent={Transition}
                    transitionDuration={300}
                    fullScreen={fullScreen}
                    maxWidth={maxWidth ? maxWidth : "xl"}
                    open={!!dialogs[dialogName]}
                    onClose={() => {
                        this.props.turnOff(dialogName);
                    }}
                    fullWidth>
                    {item ? <ComposedComponent {...this.props} /> : <Empty />}
                </Dialog>
            );
        }
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators(
            {
                turnOff: turnOff,
            },
            dispatch
        );
    }

    function mapStateToProps({marketing, fuse}) {
        return {
            item: marketing[reducerName].item,
            dialogs: fuse.dialogs,
        };
    }

    return withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(DialogWrapper));
};

export default marketingDialogWrapper;
