import { REMOVE_PARAM, REPLACE_PARAMS, ADD_PARAM, CLEAR_PARAMS } from "@fuseActions";

function removeParam(state, {filter}) {
    // if(value !== state[filter]) {
    //     return {...state, [filter]: value}
    // } else {
        const newParams = {...state};
        delete newParams[filter];
        return({...newParams});
    // }
}

const search = function (state = {}, action) {
    switch ( action.type )
    {
        case REMOVE_PARAM: { return removeParam(state, action);}
        case REPLACE_PARAMS: {
            return {
                ...action.params,
            };
        }
        case ADD_PARAM: {
            return {
                ...state,
                ...action.params,
            };
        }
        case CLEAR_PARAMS: {
            return {};
        }
        default: {
            return state;
        }
    }
};

export default search;
