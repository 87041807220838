import * as Actions from "../actions";

const initState = {
    stockOrder: null,
    bonus: null,
};

const singleReducer = function (state = initState, action) {
    switch ( action.type )
    {
        case Actions.GET_ORDER:
            return {
                stockOrder: action.payload
            };
        case Actions.GET_BONUS:
            return {
                bonus: action.payload
            };
        case Actions.CLEAR_BONUS:
            return {
                bonus: null
            };
        default:
            return state;
    }
};

export default singleReducer;
