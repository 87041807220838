import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    xmlStream: null,
};


const xml = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_DEPLOYMENT_XML: {
            let tobj = cloneDeep(state);
            tobj.xmlStream = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     xmlStream: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default xml;
