import {useMemo} from "react";
import PropTypes from "prop-types";
import {useLocaleStore} from "../../@fuse/hooks";

LFSvgIcon.propTypes = {
    svg: PropTypes.any.isRequired,
    style: PropTypes.any.isRequired,
};

function LFSvgIcon(props) {
    const {svg, ...rest} = props;

    const {language} = useLocaleStore();

    const errorString = useMemo(() => {
        return language === "en" ? "Error" : "错误";
    }, [language]);

    return <img src={svg} {...rest} alt={`${errorString}`} />;
}

export default LFSvgIcon;
