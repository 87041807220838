import {combineReducers} from 'redux';
import files from './files.reducer';
import selectedItem from './selectedItem.reducer';
import main from './main.reducer';

const reducer = combineReducers({
    files,
    selectedItem,
    main
});

export default reducer;
