import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import {EmptyView} from "@ui";
import PropTypes from "prop-types";
import PaginationFilter from "./PaginationFilter";
import SearchBox from "./SearchBox";

ListRoot.propTypes = {
    data: PropTypes.any.isRequired,
    query: PropTypes.object.isRequired,
    onChangeQuery: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    searchable: PropTypes.bool,
    searchBy: PropTypes.string
};

function ListRoot(props) {
    const {children, query, onChangeQuery, mutate, totalPages, count, data, searchable, searchBy} = props;
    return (
        <Card className=" w-full mx-20 flex flex-col">
            {searchable && (
                <SearchBox searchBy={searchBy} query={query} onChangeQuery={onChangeQuery} mutate={mutate} />
            )}
            {!!data && data?.totalElements > 0 ? (
                <>
                    <div className={"relative overflow-hidden h-full"}>
                        <List dense>{children}</List>
                    </div>

                    <PaginationFilter
                        colSpan={"100%"}
                        query={query}
                        onChangeQuery={onChangeQuery}
                        mutate={mutate}
                        totalPages={totalPages}
                        count={count}
                    />
                </>
            ) : (
                <EmptyView />
            )}
        </Card>
    );
}

export default ListRoot;
