import {createContext} from "react";

export const NoHandlerContext = createContext(null);

/**
 * @description - MatchingOrderContext 自动匹配发票的 Dialog 组件的 Context 上下文；
 */
export const MatchingOrderContext = createContext(null);

/**
 * @description - INCOME_LOCAL_ID 回款列表和待处理回款详情的 id,
 */
export const INCOME_LOCAL_ID = "[InComeSure-MyIncome] details > id";
