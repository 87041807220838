import CircularProgress from "@material-ui/core/CircularProgress";

import {useState} from "react";
import {useTimeout} from "../../../@fuse/hooks";

/**
 * @return {null}
 */
function FuseLoading(props: {className?: string; delay?: boolean; label?: string}) {
    const {delay = false} = props;
    const [showLoading, setShowLoading] = useState(!delay);

    useTimeout(() => {
        setShowLoading(true);
    }, delay);

    if (!showLoading) {
        return null;
    }

    return (
        <div data-cy={"loading-indicator"} className={"flex my-36 flex-1 flex-col items-center justify-center " + props.className}>
            {props.label ? <h6>{props.label}</h6> : ""}
            <CircularProgress color="secondary" />
        </div>
    );
}

export default FuseLoading;
