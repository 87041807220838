import {Icon, IconButton} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import {DeleteWrapper, EmptyView, MyCard} from "@ui";
import Error404Page from "app/Error404Page.js";
import classNames from "classnames";
import clsx from "clsx";
import axios, {firmPrefix} from "myaxios";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import useSWR from "swr";
import {FuseLoading} from "../../../../../@fuse";
import {useWorkPlan} from "../useWorkPlan";

type ChecklistType = {
    id: string;
    title: string;
    checked: true;
};

const Checklists = ({...rest}) => {
    const [val, setVal] = useState("");
    const [loading, setLoading] = useState(false);
    const [checkingLoading, setCheckingLoading] = useState<string | boolean>(false);

    const {wp, canEdit} = useWorkPlan();

    const {data: checklist, error, mutate: refreshChecklist, isLoading} = useSWR<ChecklistType[]>(wp && `${firmPrefix}/workplan/${wp.id}/checklists`);

    useEffect(() => {
        refreshChecklist();
    }, []);

    const onChange = (e) => {
        const id = e.target.name;
        const value = e.target.checked;
        setCheckingLoading(id);

        // @ts-ignore
        const j = [...checklist];
        j[j.findIndex((_) => _.id === id)].checked = value;

        // setChecklist(j);
        const data = {
            id: id,
            checked: value,
        };
        axios
            .put(`${firmPrefix}/workplan/${wp?.id}/checklist`, data)
            .then(() => {
                refreshChecklist().then(() => {
                    setCheckingLoading(false);
                });
            })
            .catch(() => setCheckingLoading(false));
    };

    let checkedAmount = 0;
    checklist?.forEach((_) => _.checked && checkedAmount++);

    const onAddNew = (e) => {
        e.preventDefault();
        if (val.trim() === "") return;
        setLoading(true);
        const data = {
            wpId: wp?.id,
            title: val,
        };
        axios
            .post(`${firmPrefix}/workplan/${wp?.id}/checklist`, data)
            .then(() => {
                refreshChecklist().then(() => {
                    setVal("");
                    setLoading(false);
                });
            })
            .catch(() => setLoading(false));
    };

    if (isLoading) return <FuseLoading />;
    if (error) return <Error404Page />;
    return (
        <MyCard
            data-cy={"checklist-wrapper"}
            className={"mb-0"}
            noPadding
            title={
                <>
                    <Translate id={"wps.Checklists"} />
                    {checklist && (
                        <Chip
                            data-cy={"checklist-count"}
                            size={"small"}
                            className={classNames(
                                "font-bold ml-2",
                                checklist.length > 0 && checkedAmount === checklist.length && "bg-green text-white"
                            )}
                            label={checklist.length > 0 ? `${checkedAmount}/${checklist.length}` : "0"}
                        />
                    )}
                </>
            }
            {...rest}>
            {checklist &&
                checklist.length > 0 &&
                checklist.map((_) => (
                    <FormControl key={_.id} className={"w-full"} component="fieldset">
                        <FormGroup className={"w-full"}>
                            <FormControlLabel
                                data-cy={"checklist-item"}
                                disabled={!!checkingLoading}
                                classes={{
                                    root: clsx(
                                        checkingLoading === _.id && "loading",
                                        "mb-0 w-full duration-100 even:bg-gray-100 flex px-8 m-0 hover:bg-grey-lighter"
                                    ),
                                    label: "w-full",
                                }}
                                control={<Checkbox data-cy={"check-item"} checked={_.checked} onChange={onChange} name={_.id} />}
                                label={
                                    <span className={classNames("flex justify-between items-center w-full")}>
                                        <span className={classNames(_.checked && "line-through")}>{_.title}</span>
                                        {canEdit && (
                                            <DeleteWrapper
                                                item={_.title}
                                                callback={refreshChecklist}
                                                children={(onClick) => (
                                                    <IconButton data-cy={"delete-item"} onClick={onClick}>
                                                        <Icon color={"error"} fontSize={"small"}>
                                                            delete
                                                        </Icon>
                                                    </IconButton>
                                                )}
                                                deleteReq={() => axios.delete(`${firmPrefix}/workplan/${wp?.id}/checklist?id=${_.id}`)}
                                            />
                                        )}
                                    </span>
                                }
                            />
                        </FormGroup>
                    </FormControl>
                ))}
            {checklist?.length === 0 && !canEdit && <EmptyView noIcon dense label={<Translate id="wps.There is no checklist." />} />}
            {wp?.status === "ACTIVE" && canEdit && (
                <form data-cy={"checklist-form"} onSubmit={onAddNew} className={"px-16 mb-16"}>
                    <Translate>
                        {({translate}) => (
                            <TextField
                                data-cy={"checklist-input"}
                                className={loading ? "loading" : ""}
                                style={{minWidth: "50%"}}
                                value={val}
                                label={translate("wps.Add an item")}
                                onChange={(event) => setVal(event.target.value)}
                                fullWidth
                            />
                        )}
                    </Translate>
                </form>
            )}
        </MyCard>
    );
};

export default Checklists;
