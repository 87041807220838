import {Translate} from "react-localize-redux";
import type {EnumType} from "types";

export const issueStatuses: Record<string, EnumType> = {
    Open: {value: "Open", label: <Translate id={"issues.Open"} />, color: "red"},
    InProcess: {value: "InProcess", label: <Translate id={"issues.InProcess"} />, color: "blue"},
    Solved: {value: "Solved", label: <Translate id={"issues.Solved"} />, color: "purple"},
    Close: {value: "Close", label: <Translate id={"issues.Close"} />, color: "green"},
};

export const issueTypes: Record<string, EnumType> = {
    owner: {value: "owner", label: <Translate id={"issues.owner"} />, color: "blue"},
    assignee: {value: "assignee", label: <Translate id={"issues.assignee"} />, color: "blue"},
    follower: {value: "follower", label: <Translate id={"issues.follower"} />, color: "blue"},
};

export const issueSeverities: Record<string, EnumType> = {
    critical: {value: "critical", label: <Translate id={"issues.Critical"} />, color: "red"},
    major: {value: "major", label: <Translate id={"issues.Major"} />, color: "orange"},
    minor: {value: "minor", label: <Translate id={"issues.Minor"} />, color: "green"},
};

export const testResultStatus: Record<string, EnumType> = {
    passed: {icon: "mood", value: "passed", label: <Translate id={"issues.Passed"} />, color: "green"},
    critical: {icon: "error", value: "critical", label: <Translate id={"issues.Critical"} />, color: "red"},
    major: {icon: "sentiment_dissatisfied", value: "major", label: <Translate id={"issues.Major"} />, color: "orange"},
    minor: {icon: "sentiment_satisfied", value: "minor", label: <Translate id={"issues.Minor"} />, color: "yellow"},
};

export const testsTypes: Record<string, EnumType> = {
    USABILITY: {value: "USABILITY", label: <Translate id={"issues.USABILITY"} />, color: "blue"},
    FUNCTIONALITY: {value: "FUNCTIONALITY", label: <Translate id={"issues.FUNCTIONALITY"} />, color: "blue"},
    PERFORMANCE: {value: "PERFORMANCE", label: <Translate id={"issues.PERFORMANCE"} />, color: "blue"},
    SECURITY: {value: "SECURITY", label: <Translate id={"issues.SECURITY"} />, color: "blue"},
    DATABASE: {value: "DATABASE", label: <Translate id={"issues.DATABASE"} />, color: "blue"},
    REGRESSION: {value: "REGRESSION", label: <Translate id={"issues.REGRESSION"} />, color: "blue"},
};
