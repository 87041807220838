import {combineReducers} from "redux";
import deploy from "./deploy.reducer";
import definition_list from "./definition_list.reducer.js";
import definition from "./definition.reducer.js";
import instances_list from "./instances_list.reducer.js";
import instance from "./instance.reducer";
import decision_list from "./decision_list.reducer";
import decision from "./decision.reducer";
import userTask_list from "./userTask_list.reducer";
import userTask from "./userTask.reducer";
import xml from "./xml.reducer";
import dmn from "./dmn.reducer";
import tab from "./tab.reducer";

const process = combineReducers({
    deploy,
    dmn,
    tab,
    definition,
    definition_list,
    instances_list,
    instance,
    decision_list,
    decision,
    userTask_list,
    userTask,
    xml
});

export default process;
