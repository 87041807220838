import {Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {Component} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../../app/auth/store/actions';
import * as fuseActions from '../../../app/store/actions';
import axios, {userPrefix} from '../../../myaxios';
import DatePickerWrapper from '../../../utils/DatePickerWrapper/DatePickerWrapper';

class General extends Component {

    state = {
        username: this.props.user.username,
        bio: this.props.user.bio,
        birthday: null,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        gender: this.props.user.gender,
        usernameMsg: "Username must be unique",
        error: false,
        loaded: false
    };

    componentDidMount() {
        if (this.props.user.birthday) {
            const date = new Date(this.props.user.birthday);
            const format = date.toLocaleDateString();
            this.setState({
                birthday: format
            });
        }
    }

    onSubmitGeneral = (e) => {
        e.preventDefault();
        this.props.startLoading();
        const formData = {
            username: this.state.username,
            birthday: this.state.birthday,
            bio: this.state.bio,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            gender: this.state.gender
        };
        axios.put(`${userPrefix}/myProfile`, formData)
        .then(() => {
            this.props.showMessage({
                variant: "success",
                message: "message.Information saved!"
            });
            this.props.getBaseInfo();
            this.props.finishLoading();
        });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    handleBirthdayChange = (date) => {

        const or = new Date(date);

        const format = or.toLocaleDateString();
        this.setState({
            birthday: format
        });
    };

    error = (name, e) => {
        this.setState({[name]: e});
    };

    render() {

        return (
            this.props.user &&
            <>
                {/*<Grid container spacing={4}>*/}
                {/*    /!*<Grid item md={3} xs={12}>*!/*/}
                {/*    /!*    <CheckerInput*!/*/}
                {/*    /!*        label={<Translate id="profile.username"/>}*!/*/}
                {/*    /!*        name="username"*!/*/}
                {/*    /!*        editValue={this.state.username}*!/*/}
                {/*    /!*        min={5}*!/*/}
                {/*    /!*        margin="normal"*!/*/}
                {/*    /!*        max={20}*!/*/}
                {/*    /!*        error={(e) => this.error("nameError", e)}*!/*/}
                {/*    /!*        changeHandler={(val) => this.setState({username: val})}*!/*/}
                {/*    /!*        url={`${userPrefix}/checkUsernameAvailability`}*!/*/}
                {/*    /!*    />*!/*/}
                {/*    /!*</Grid>*!/*/}
                {/*    */}
                {/*</Grid>*/}
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            onChange={this.handleChange("firstName")}
                            value={this.state.firstName}
                            label={<Translate id="profile.fistName" />}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                            onChange={this.handleChange("lastName")}
                            value={this.state.lastName}
                            label={<Translate id="profile.LastName" />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <Translate>
                            {({translate}) => <DatePickerWrapper
                                TextFieldComponent={(props) => {
                                    return (
                                        <TextField
                                            {...props}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={this.state.birthday ? this.state.birthday : ""}
                                            fullWidth
                                        />
                                    );
                                }}
                                onChange={this.handleBirthdayChange}
                                format={"yyyy/MM/dd"}
                                label={translate("profile.Birthday")}
                                value={this.state.birthday} />}
                        </Translate>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            select
                            fullWidth
                            onChange={this.handleChange("gender")}
                            value={this.state.gender}
                            label={<Translate id="profile.gender" />}
                        >
                            <MenuItem value="MALE"><Translate id={"profile.MALE"} /></MenuItem>
                            <MenuItem value="FEMALE"><Translate id={"profile.FEMALE"} /></MenuItem>
                            <MenuItem value="NONE"><Translate id={"profile.NONE"} /></MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            onChange={this.handleChange("bio")}
                            value={this.state.bio === null ? "" : this.state.bio}
                            helperText={<Translate
                                id="profile.Write something about yourself ( You can user Markdown formatting )" />}
                            label={<Translate id="profile.Biography" />}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item>
                        <Button variant={"contained"} color={"secondary"} className={"mt-20"} onClick={this.onSubmitGeneral}><Translate
                            id="profile.Submit" /></Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBaseInfo: Actions.setUserData,
        showMessage: fuseActions.showMessage,
        startLoading: fuseActions.startLoading,
        finishLoading: fuseActions.finishLoading
    }, dispatch);
}

export default withLocalize(connect(null, mapDispatchToProps)(General));

