import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    definitionObject: null,
    suspended: false,
    focusId:  "",
    usersOfDefinition:[]
};


const definitionSingle = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_PROCESSDEFINE_SINGLE: {
            const obj = cloneDeep(state);
            obj.definitionObject = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     definitionObject: action.payload
            // }
        }
        case Actions.RESET_PROCESSDEFINE_SINGLE: {
            const obj = cloneDeep(state);
            obj.definitionObject = null;
            return  obj;
            // return {
            //     ...state,
            //     definitionObject: action.payload
            // }
        }
        case Actions.GET_PROCESSDEFINE_SINGLE_USERS_AND_TASKS: {
            const obj = cloneDeep(state);
            obj.usersOfDefinition = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     usersOfDefinition: action.payload
            // }
        }
        case Actions.START_PAUSE_DEFINITION_ACTION: {
            const obj = cloneDeep(state);
            obj.suspended = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     suspended: action.payload
            // }
        }
        case Actions.HANDLE_FOCUS_DIAGRAM: {
            const obj = cloneDeep(state);
            obj.focusId = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     focusId: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default definitionSingle;
