import myaxios, {commonPrefix} from "@api";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import {SubmitButton} from "@ui";
import type {FC} from "react";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import SelectButtons from "../../../../utils/Forms/SelectButtons";
import {useDGDef} from "../hooks/hooks";

const DGShare: FC = () => {
    const {t} = useTranslation(["datagrid", "_"]);
    const [shareEl, setShareEl] = useState<HTMLButtonElement | null>(null);
    const [loading, setLoading] = useState(false);
    // const [url, setUrl] = useState<string>();
    const {permission, title, id} = useDGDef();
    // const {cols} = useDGCols();
    // const {rows} = useDGRows();

    // const dispatch = useDispatch();
    // const initialState: DGDefType = {
    //     // type: "table",
    //     // limit: "unlimited",
    //     permission,
    //     title,
    //     // selectable: true,
    //     // paginated: false,
    // };

    const [shareFormData, setShareFormData] = useState({
        title,
        permission,
    });

    // useEffect(() => {
    //     setUrl(undefined);
    // }, [shareFormData]);

    // const divRef = useRef<HTMLDivElement>(null);

    // const downloadQRCode = async () => {
    //     const canvas = divRef.current?.children[0] as HTMLCanvasElement;
    //     const name = shareFormData.title;
    //     if (!canvas) return;
    //     const response = await fetch(canvas.toDataURL());
    //     const blob = await response.blob();
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = name;
    //     a.click();
    //
    //     // this.downloadRef.href = canvas.toDataURL();
    //     // this.downloadRef.download = 'mypainting.png';
    // };

    const onChange = (key: string) => (e) => {
        setShareFormData({
            ...shareFormData,
            [key]: e.target ? e.target.value : e,
        });
    };

    useEffect(() => {
        setShareFormData({
            ...shareFormData,
            permission,
            title,
        });
    }, [title, permission]);

    const generateLink = () => {
        setLoading(true);
        // /datagrid
        myaxios
            .put(`${commonPrefix}/datagrids/${id}/setting`, shareFormData)
            .then(() => {
                // myaxios
                //     .put(`${commonPrefix}/datagridData/${id}`, {cols, rows})
                //     .then(() => {
                toast.success(t("Successfully saved"));
                // setShareFormData(initialState);
                setShareEl(null);
                // })
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Button
                variant={"contained"}
                onClick={(e) => setShareEl(e.currentTarget)}
                size={"small"}
                className={"mr-4"}
                startIcon={<Icon children={"share"} />}>
                {t("setting")}
            </Button>

            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(shareEl)}
                onClose={() => setShareEl(null)}
                anchorEl={shareEl}>
                <div className={"p-12 flex flex-col min-w-320"}>
                    <TextField required autoFocus value={shareFormData.title} onChange={onChange("title")} label={t("title")} margin={"dense"} />
                    {/*<TextField*/}
                    {/*    multiline*/}
                    {/*    minRows={3}*/}
                    {/*    value={shareFormData.description}*/}
                    {/*    onChange={onChange("description")}*/}
                    {/*    label={t("description")}*/}
                    {/*    margin={"dense"}*/}
                    {/*/>*/}

                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox checked={shareFormData.permission === "add"} />}*/}
                    {/*    onChange={() => onChange("permission")("add")}*/}
                    {/*    label={t("asForm")}*/}
                    {/*/>*/}

                    {/*<SelectButtons*/}
                    {/*    className={"mt-16"}*/}
                    {/*    label={"How to share"}*/}
                    {/*    type={"permission"}*/}
                    {/*    items={[*/}
                    {/*        {label: "As the Table", value: ""},*/}
                    {/*        {label: "As a form", value: "add"},*/}
                    {/*    ]}*/}
                    {/*    formData={shareFormData}*/}
                    {/*    onChange={onChange}*/}
                    {/*/>*/}

                    <SelectButtons
                        className={"mt-16"}
                        label={t("permissions.label")}
                        type={"permission"}
                        items={[
                            {label: t("permissions.disabled"), value: "disabled", color: "black"},
                            {label: t("permissions.readonly"), value: "readonly", color: "green"},
                            {label: t("permissions.add"), value: "add", color: "blue"},
                            {label: t("permissions.edit"), value: "edit", color: "orange"},
                            // {label: t("permissions.manage"), value: "manage", color: "red"},
                        ]}
                        formData={shareFormData}
                        onChange={onChange}
                    />

                    {/*<SelectButtons*/}
                    {/*    className={"mt-16"}*/}
                    {/*    label={"Limitation"}*/}
                    {/*    type={"limit"}*/}
                    {/*    items={[*/}
                    {/*        {label: "Unlimited", value: "unlimited", color: "red"},*/}
                    {/*        {label: "Daily", value: "daily", color: "orange"},*/}
                    {/*        {label: "Once", value: "once", color: "green"},*/}
                    {/*    ]}*/}
                    {/*    formData={shareFormData}*/}
                    {/*    onChange={onChange}*/}
                    {/*/>*/}

                    <SubmitButton
                        disabled={shareFormData.title.trim().length === 0}
                        loading={loading}
                        onClick={generateLink}
                        success
                        className={"mt-20"}
                        startIcon={<Icon children={"save"} />}>
                        {t("save")}
                    </SubmitButton>

                    {/*{url && (*/}
                    {/*    <div className="p-12 bg-gray-100 rounded-12 mt-20 max-w-320">*/}
                    {/*        <h4>Share Link</h4>*/}
                    {/*        /!*<strong className="text-gray-400">http://srx.signabc.com/shared_form?source={url}</strong>*!/*/}
                    {/*        <div ref={divRef} className="w-full my-16 flex justify-center">*/}
                    {/*            <QRCode size={220} value={`http://srx.signabc.com/shared_form?source=${url}`} />*/}
                    {/*        </div>*/}

                    {/*        <div className="flex items-center justify-center mt-8">*/}
                    {/*            <Button*/}
                    {/*                onClick={downloadQRCode}*/}
                    {/*                variant={"outlined"}*/}
                    {/*                className={"mr-8"}*/}
                    {/*                size={"small"}*/}
                    {/*                startIcon={<Icon>download</Icon>}>*/}
                    {/*                QR Code*/}
                    {/*            </Button>*/}
                    {/*            <CopyToClipboard*/}
                    {/*                text={url}*/}
                    {/*                onCopy={() =>*/}
                    {/*                    dispatch(*/}
                    {/*                        showMessage({*/}
                    {/*                            variant: "success",*/}
                    {/*                            message: "message.Copied params",*/}
                    {/*                            params: url,*/}
                    {/*                        })*/}
                    {/*                    )*/}
                    {/*                }>*/}
                    {/*                <Button variant={"outlined"} size={"small"} startIcon={<Icon>link</Icon>}>*/}
                    {/*                    Copy Link*/}
                    {/*                </Button>*/}
                    {/*            </CopyToClipboard>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </Popover>
        </>
    );
};

export default DGShare;
