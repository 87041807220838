import {createTheme} from "@material-ui/core/styles";
import {fuseDark} from "../@fuse/fuse-colors";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import qs from "qs";
import _ from "../@lodash";

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
    customScrollbars: true,
    theme: {
        main: "default",
        navbar: "mainThemeDark",
        toolbar: "mainThemeLight",
        footer: "mainThemeDark",
    },
};

export function getParsedQuerySettings() {
    const parsedQueryString = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    if (parsedQueryString && parsedQueryString.defaultSettings) {
        return JSON.parse(parsedQueryString.defaultSettings);
    }
    return {};

    // Generating route params from settings
    /*const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings);*/
}

/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
    typography: {
        fontFamily: ["SamsungOne", "\"Helvetica\"", "Arial", "sans-serif"].join(","),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        useNextVariants: true,
        suppressDeprecationWarnings: true,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 700,
            lg: 850,
            xl: 1400,
        },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    "&[style*=\"opacity: 0\"]": {
                        pointerEvents: "none",
                    },
                },
            },
        },
    },
};

export const mustHaveThemeOptions = {
    // overrides: {
    //     MuiButton: {
    //         root: {
    //             s
    //         },
    //     },
    // },
    shape: {
        borderRadius: 8,
    },

    shadows: [
        "0px 0px 20px 0px rgba(76, 87, 125, 0.02)",
        "0px 0px 13px -3px rgba(76, 87, 125, 0.2)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
        "0px 1px 5px -2px rgba(76, 87, 125, 0.4)",
    ],

    typography: {
        htmlFontSize: 10,
        body1: {
            fontSize: "1.4rem",
        },
        body2: {
            fontSize: "1.4rem",
        },
    },
};

export const defaultThemes = {
    default: {
        palette: {
            type: "light",
            primary: fuseDark,
            secondary: {
                light: lightBlue[400],
                main: lightBlue[600],
                dark: lightBlue[700],
            },
            error: red,
        },
        status: {
            danger: "orange",
        },
    },
    // defaultDark: {
    //     palette: {
    //         type     : 'dark',
    //         primary  : fuseDark,
    //         secondary: {
    //             light: lightBlue[400],
    //             main : lightBlue[600],
    //             dark : lightBlue[700]
    //         },
    //         error    : red
    //     },
    //     status : {
    //         danger: 'orange'
    //     }
    // }
};

export function extendThemeWithMixins(obj) {
    const theme = createTheme(obj);
    return {
        border: (width = 1) => ({
            borderWidth: width,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
        }),
        borderLeft: (width = 1) => ({
            borderLeftWidth: width,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
        }),
        borderRight: (width = 1) => ({
            borderRightWidth: width,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
        }),
        borderTop: (width = 1) => ({
            borderTopWidth: width,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
        }),
        borderBottom: (width = 1) => ({
            borderBottomWidth: width,
            borderStyle: "solid",
            borderColor: theme.palette.divider,
        }),
    };
}

export function mainThemeVariations(theme) {
    return {
        mainThemeDark: createTheme(
            _.merge({}, defaultThemeOptions, theme, {palette: {type: "dark"}, ...mustHaveThemeOptions})
        ),
        mainThemeLight: createTheme(
            _.merge({}, defaultThemeOptions, theme, {palette: {type: "light"}, ...mustHaveThemeOptions})
        ),
    };
}
