import {FuseScrollbars} from "@fuse";
import {AppBar, Hidden, Icon} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import NavbarFoldedToggleButton from "app/layouts/shared-components/NavbarFoldedToggleButton";
import NavbarMobileToggleButton from "app/layouts/shared-components/NavbarMobileToggleButton";
import Navigation from "app/layouts/shared-components/Navigation";
import clsx from "clsx";
import {memo} from "react";
import Logo from "../../shared-components/Logo";

const useStyles = makeStyles({
    content: {
        overflowX: "hidden",
        overflowY: "auto",
        "-webkit-overflow-scrolling": "touch",
        background: "linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 40px, 100% 10px",
        backgroundAttachment: "local, scroll",
    },
});

function NavbarMobileLayout3(props) {
    const classes = useStyles(props);

    return (
        <div className="flex flex-col h-full overflow-hidden">
            <AppBar color="primary" position="static" elevation={0} className="flex flex-row items-center shrink h-52 min-h-52 pl-20 pr-12">
                <div className="flex flex-1 pr-8">
                    <Logo />
                </div>

                <Hidden mdDown>
                    <NavbarFoldedToggleButton className="w-40 h-40 p-0" />
                </Hidden>

                <Hidden lgUp>
                    <NavbarMobileToggleButton className="w-40 h-40 p-0">
                        <Icon>arrow_back</Icon>
                    </NavbarMobileToggleButton>
                </Hidden>
            </AppBar>

            <FuseScrollbars className={clsx(classes.content)}>
                {/*<UserNavbarHeader/>*/}

                <Navigation layout="vertical" />
            </FuseScrollbars>
        </div>
    );
}

export default memo(NavbarMobileLayout3);
