import {CLOSE_PROCESS_STEPS, VIEW_PROCESS_STEPS} from "../../actions";

const INIT_PARAMS = {
    dialog: false,
    datas: []
};

const process_util = function(state = INIT_PARAMS, action) {
    switch (action.type) {
        case VIEW_PROCESS_STEPS:
            return {
                ...state,
                ...action.payload,
                dialog: true
            };
        case CLOSE_PROCESS_STEPS:
            return {
                ...state,
                dialog: false
            };
        default: {
            return state;
        }
    }
};

export default process_util;
