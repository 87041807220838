import {Suspense} from "react";
import {FuseLoading} from "../../../@fuse";
import ErrorReporter from "../../../components/layout/reportError/ErrorReporter";

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */
function FuseSuspense(props) {
    return (
        <Suspense fallback={<FuseLoading />}>
            <ErrorReporter>{props.children}</ErrorReporter>
        </Suspense>
    );
}

export default FuseSuspense;
