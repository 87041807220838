import {useEffect, useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Dialog, DialogContent, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch, useSelector} from "react-redux";
import * as fuseActions from "@fuseActions";
import * as Actions from "../../store/actions";
import axios, {flowPrefix} from "../../../../../myaxios";
import {useLocation, useParams} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";

function StartPauseDefinitionDialog(props) {
    const dispatch = useDispatch();

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const {id} = useParams();

    const location = useLocation();

    const search = useSelector(({fuse}) => fuse.search);

    const selectedDefinition = useSelector(({process}) => process.definition_list.selectedDefinition);

    const suspended = useSelector(({process}) => process.definition.suspended);

    const [loading, setLoading] = useState(false);

    const [pause, setPause] = useState(false);

    useEffect(() => {
        setPause(suspended);
        console.log("StartPauseDefinitionDialog => useEffect => 打印路由 location 值", location);
        console.log("StartPauseDefinitionDialog => useEffect => 打印路由 id 值", id);
        return function cleanup() {
            setLoading(false);
        };
    }, []);

    function handleDelete() {
        dispatch(fuseActions.turnOff("PauseDefinitionTipsDialog"));
    }

    function canSubmit() {
        return loading === true;
    }

    function handleSubmit() {
        setLoading(true);
        const params = {
            id: selectedDefinition.id,
            suspended: !selectedDefinition.suspended,
        };
        axios
            .put(`${flowPrefix}/processDefinitionSetSuspended`, params)
            .then((res) => {
                setLoading(false);
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: selectedDefinition.suspended
                            ? "message.Definition started!"
                            : "message.Definition suspended!",
                    })
                );
                if (location.pathname === "/management/process/definitions") {
                    dispatch(Actions.getProcessDefinitions(search));
                } else {
                    dispatch(Actions.getProcessDefinitionSingle(id));
                    dispatch(Actions.getProcessDefinitions(search));
                }
                handleDelete();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Dialog
            open={!!dialogs["PauseDefinitionTipsDialog"]}
            onClose={() => {
                handleDelete();
            }}
            fullWidth
        >
            <div className={loading ? "loading" : null}>
                <DialogTitle id="PauseDefinitionTipsDialog-dialog-title">
                    <Translate id="process.Start or pause this definition" />
                    <Chip
                        className={classNames(
                            "font-bold",
                            selectedDefinition.suspended === false
                                ? "bg-green text-white ml-2"
                                : "bg-red text-white ml-3"
                        )}
                        label={<Translate id={`process.${selectedDefinition.suspended ? "suspended" : "running"}`} />}
                    />
                </DialogTitle>
                <DialogContent>
                    <div className="flex flex-col justify-center">
                        <Typography variant="button" className={"text-center font-black"} display="block">
                            {selectedDefinition.suspended ? (
                                <Translate id={"process.Sure you want to start the current process definition?"} />
                            ) : (
                                <Translate id={"process.Sure you want to suspend the current process definition?"} />
                            )}
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete()}>
                        <Translate id="_.cancel" />
                    </Button>
                    <Button disabled={canSubmit()} onClick={() => handleSubmit()} color="secondary">
                        <Translate id="_.sure" />
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withLocalize(StartPauseDefinitionDialog);
