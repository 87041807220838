import {FuseLoading} from "@fuse";
import {showMessage, turnOn} from "@fuseActions";
import {ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";
// import * as Actions from "../store/actions";
import {SingleContent} from "@page";
import {LocalTimeStamp} from "@ui";
import Error404Page from "app/Error404Page.js";
import axios, {firmPrefix} from "myaxios";
import {useEffect, useRef, useState} from "react";
import {withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {useSWRConfig} from "swr";
import transJson from "../translate/wps.json";
import type {wpHistoryType} from "../types";
import useWPS from "../useWPS";
import Comments from "./Comments";
import HistoryDetail from "./dialogs/HistoryDetail";
import HeaderWP from "./Header";
import InformationWP from "./InformationWP";
import Sidebar from "./Sidebar";
import {useWorkPlan} from "./useWorkPlan";

const WPPage = (props) => {
    const filesWrapperRef = useRef(null);
    const dispatch = useDispatch();
    const historyDetailDialog = useSelector(({fuse}) => fuse.dialogs["historyDetail"]);
    const {mutate} = useSWRConfig();

    const {getData} = useWPS();

    const {isError, isLoading, refresh} = useWorkPlan();

    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [historyData, setHistoryData] = useState<wpHistoryType[]>([]);
    const [selectedHistory, setSelectedHistory] = useState<wpHistoryType>();
    const location = useLocation();

    const getHistory = (sourceId, el) => {
        setLoading(true);
        axios.get(`${firmPrefix}/workplanHistory`, {params: {sourceId, sord: "desc", sidx: "updateTimestamp"}}).then(({data}) => {
            if (data.content.length > 0) {
                setHistoryData(data.content);
                setAnchorEl(el);
            } else {
                dispatch(showMessage({message: "_.No history", variant: "error"}));
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        props.addTranslation(transJson);

        refresh().then((data) => {
            if (data && (data.updateArea.length > 0 || data.unreadLevel > 0)) {
                if (location.pathname === "/wps/myWorkplans" || location.pathname === "/wps/list") getData();
                else {
                    mutate(`${firmPrefix}/workplans?pin=true&size=5`);
                    mutate(`${firmPrefix}/workplans?unread=true&size=5`);
                }
            }
        });
        return () => {
            getData();
        };
    }, []);

    if (isLoading) return <FuseLoading />;
    if (isError) return <Error404Page />;
    return (
        <>
            <HeaderWP isDialog={props.isDialog} />

            <SingleContent
                content={
                    <>
                        {!!historyDetailDialog && !!selectedHistory && <HistoryDetail selectedHistory={selectedHistory} />}
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            className="max-w-512 min-w-256"
                            classes={{paper: "max-w-512 min-w-288 overflow-y-scroll max-h-224"}}>
                            {loading ? (
                                <div className={"m-12"}>
                                    <FuseLoading />{" "}
                                </div>
                            ) : (
                                <List dense>
                                    {historyData.map((h, i) => (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                dispatch(turnOn("historyDetail"));
                                                setSelectedHistory(h);
                                            }}
                                            classes={{root: "whitespace-normal"}}
                                            key={i}>
                                            <ListItemText primary={h.title} secondary={<LocalTimeStamp datetime={h.updateTimestamp} />} />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Popover>

                        <InformationWP getHistory={getHistory} />

                        <Comments getHistory={getHistory} filesWrapperRef={filesWrapperRef} />
                    </>
                }
                sidebar={<Sidebar ref={filesWrapperRef} />}
            />
        </>
    );
};

export default withLocalize(WPPage);
