import {Chip, TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {SubmitButton} from "@ui";
import {setCache, showMessage, turnOff} from "app/store/actions";
import FaveButton from "components/layout/fav/FaveButton";
import axios, {pmsPrefix} from "myaxios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import useSWR from "swr";
import {ProjectView} from "utils/utilities";
import EditorInput from "../../../../utils/EditorInput/EditorInput";
import AsyncSelect from "../../../../utils/Forms/AsyncSelect";
import SelectButtons from "../../../../utils/Forms/SelectButtons";
import UserPreviewSearch from "../../../../components/forms/UserPreviewSearch";
import {issueSeverities} from "../enums";
import {useIssue} from "../Issue/useIssue";
import ListComponent from "../tests/dialogs/ListComponent";
import type {IssuePost, LabelType, ProjectType} from "../types";

const IssueDialog = ({onClose}: {onClose(): void}) => {
    const {t} = useTranslation("issues");
    const dispatch = useDispatch();
    const {pathname} = useLocation();

    const {data: labels} = useSWR<LabelType[]>(`${pmsPrefix}/issueLabels`, {revalidateOnMount: true});

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<Partial<IssuePost>>({
        severity: "major",
    });
    const [defaultAssignee, setDefaultAssignee] = useState<string>();
    const [project, setProject] = useState<ProjectType>();

    const selectedIssue = useSelector(({fuse}) => fuse.dialogs["selectedIssue"]);
    const duplicateIssue = useSelector(({fuse}) => fuse.dialogs["duplicateIssue"]);
    const {refresh} = useIssue(selectedIssue?.id);

    useEffect(() => {
        if (selectedIssue) {
            setFormData({
                id: selectedIssue.id,
                assigneeUserId: selectedIssue.assignee?.user.id,
                projectId: selectedIssue.projectId,
                title: selectedIssue.title || "",
                description: selectedIssue.description || "",
                dueDate: selectedIssue.dueDate,
                severity: selectedIssue.severity,
                labels: selectedIssue.labels,
            });
            setDefaultAssignee(selectedIssue.assignee?.user.id);
            setProject(selectedIssue.project);
        }
    }, [selectedIssue]);

    // console.log(labels, formData.labels);

    const clearData = () => {
        setFormData({
            assigneeUserId: "",
            projectId: "",
            title: "",
            description: "",
            dueDate: 0,
            severity: "major",
        });
        setProject(undefined);
    };

    useEffect(() => {
        const t = localStorage.getItem("issues_latestProject");

        if (t && !selectedIssue) {
            const c = JSON.parse(t).id;
            setTimeout(() => {
                setFormData({
                    ...formData,
                    projectId: c,
                    description: !pathname.includes("myIssues") ? `<a href="${window.location.href}">${window.location.href}</a>` : "",
                });
            }, 400);
            // onChange("projectId")(JSON.parse(t).id)
            setProject(JSON.parse(t));
        }
        return () => {
            dispatch(setCache("selectedIssue", null));
            dispatch(turnOff("duplicateIssue"));
            clearData();
        };
    }, []);

    const onChange = (name) => (event) => {
        setFormData({...formData, [name]: event?.target ? event.target.value : event});
    };

    const onChangeProject = (e, newValue) => {
        setProject(newValue);
        if (newValue) {
            localStorage.setItem("issues_latestProject", JSON.stringify(newValue));
            const user = newValue.user;
            if (!formData.assigneeUserId || (formData.assigneeUserId && defaultAssignee)) {
                setFormData({...formData, projectId: newValue?.id, assigneeUserId: user.id});
                setDefaultAssignee(user.id);
            } else {
                setFormData({...formData, projectId: newValue?.id});
            }
        } else {
            onChange("projectId")(null);
        }
    };

    //
    // const setUser = (user) => {
    //     const es = JSON.parse(localStorage.getItem(name)) || []
    //     const userIndex = es.findIndex(u => user.id === u.user.id);
    //     if(userIndex > -1) {
    //         es[userIndex].echo= es[userIndex].echo + 1
    //     } else {
    //         es.push({user, echo: 1});
    //     }
    //     localStorage.setItem(name, JSON.stringify(es));
    // }

    const onChangeUser = (newValue) => {
        setDefaultAssignee(undefined);
        if (newValue) {
            onChange("assigneeUserId")(newValue.user?.id);
        } else {
            onChange("assigneeUserId")(null);
        }
    };

    // const chooseMyself = () => {
    //     // setAssignee(me);
    //     onChange("assigneeUserId")(me.id)
    // };

    // const onChangeLabel = (e, newValue) => {
    //     if (newValue) onChange("labels")(newValue.map((n) => n.id));
    // };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const t = {
            ...formData,
            labels: formData?.labels?.map((i) => i.id),
        };

        const request = selectedIssue && !duplicateIssue ? axios.put(`${pmsPrefix}/projectIssue`, t) : axios.post(`${pmsPrefix}/projectIssue`, t);
        request
            .then(({data}) => {
                dispatch(
                    showMessage({
                        message: "message.Issue Saved!",
                        variant: "success",
                        gotoLink: `/issue/${data.id}`,
                        gotoTitle: "navi.issue",
                    })
                );
                onClose();

                if (selectedIssue) {
                    refresh();
                }
            })
            .finally(() => setLoading(false));
    };

    const canSubmit = () => {
        return (
            !formData.assigneeUserId ||
            formData.assigneeUserId === "" ||
            !formData.projectId ||
            formData.projectId === "" ||
            !formData.title ||
            formData.title === ""
        );
    };

    return (
        <div className={loading ? "loading" : ""} data-cy={"new-issue-dialog"}>
            <DialogTitle disableTypography className={"flex items-center pb-0"}>
                {/* region Only title */}

                {selectedIssue && !duplicateIssue ? (
                    <Typography variant={"h6"}>{t("Edit issue with parameter", {name: selectedIssue.title})}</Typography>
                ) : (
                    <>
                        <Typography variant={"h6"}>{duplicateIssue ? t("Copy Issue") : t("New Issue")}</Typography>
                    </>
                )}
                {/* endregion */}
                <FaveButton type={"dialog"} title={"issues.New Issue"} sourceId={"issue"} colored />

                <AsyncSelect
                    disableClearable
                    required
                    data-cy={"project"}
                    defaultValue={project}
                    name={"issueProject"}
                    className={"w-216 ml-16 "}
                    onChange={onChangeProject}
                    link={`${pmsPrefix}/projects?size=8`}
                    renderOption={(option) => <ProjectView item={option} />}
                    getOptionLabel={(option) => option.name}
                    variant={"outlined"}
                    textFieldProps={{
                        size: "small",
                        required: true,
                        label: t("Project"),
                    }}
                />
            </DialogTitle>
            <DialogContent className={"flex flex-col"}>
                <div className={"flex-grow"}>
                    <div className="flex">
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                required: true,
                            }}
                            data-cy={"title"}
                            margin={"normal"}
                            autoFocus={true}
                            className={"w-2/3 mab-20 mr-20"}
                            inputProps={{maxLength: 60}}
                            placeholder={t("No more than 60 character")}
                            label={t("Title")}
                            value={formData.title || ""}
                            onChange={onChange("title")}
                        />

                        <UserPreviewSearch
                            data-cy={"select-assignee"}
                            required
                            label={t("Assign to")}
                            defaultValue={defaultAssignee}
                            name={"issueUsers"}
                            className={"w-1/3"}
                            onChange={onChangeUser}
                        />
                    </div>

                    {/*<EditorInput name={"description"} notFocused value={formData.description} disableMention onChange={onChange("description")} />*/}
                </div>

                <div className="my-20">
                    <EditorInput name={"description"} notFocused value={formData.description} disableMention onChange={onChange("description")} />
                </div>

                <ListComponent
                    className={"mb-12"}
                    title={t("Checklists")}
                    name={"checklists"}
                    onChange={onChange("checklists")}
                    // defaultItems={formData.checklists}
                />
            </DialogContent>
            {/*<Divider className={"mt-12"} />*/}
            <div className="bg-gray-50 rounded-b-12">
                <DialogContent>
                    <SelectButtons
                        data-cy={"severity"}
                        className={"mr-24 mb-20 flex-shrink-0"}
                        label={t("Severity")}
                        onChange={onChange}
                        formData={formData}
                        items={Object.values(issueSeverities)}
                        type={"severity"}
                    />

                    <Autocomplete
                        multiple
                        value={formData.labels || []}
                        getOptionSelected={(option, value) => option.id === value.id}
                        disableCloseOnSelect
                        renderTags={(option, getTagProps) =>
                            option.map((_, index) => (
                                <Chip
                                    {...getTagProps({index})}
                                    size={"small"}
                                    key={_.id}
                                    label={_.title}
                                    className={"text-white mr-2  capitalize"}
                                    style={{backgroundColor: _.color}}
                                />
                            ))
                        }
                        id="tags-standard"
                        options={labels || []}
                        renderOption={(option) => (
                            <Chip
                                size={"small"}
                                key={option.id}
                                label={option.title}
                                className={"text-white mr-2  capitalize"}
                                style={{backgroundColor: option.color}}
                            />
                        )}
                        onChange={(e, v) => onChange("labels")(v)}
                        getOptionLabel={(option) => option.title}
                        // defaultValue={[top100Films[13]]}
                        renderInput={(params) => <TextField {...params} className={"min-w-360"} fullWidth={false} label={t("Add labels")} />}
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton
                        startIcon={<Icon children="send" />}
                        loading={loading}
                        success
                        data-cy={"submit"}
                        size={"large"}
                        disabled={canSubmit()}
                        onClick={onSubmit}>
                        {t("submit")}
                    </SubmitButton>
                </DialogActions>
            </div>
        </div>
    );
};

export default IssueDialog;
