import {Icon} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
// import chroma from "chroma-js";

// function NoOptionsMessage(props) {
//     return (
//         <Typography color="textSecondary" style={{padding: `1rem 2rem`}} {...props.innerProps}>
//             {props.children}
//         </Typography>
//     );
// }

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            margin={"none"}
            multiline={props.isMulti}
            size={"small"}
            // className={"bg-red"}
            InputProps={{
                inputComponent,
                // style: {
                //     padding: 0,
                // },
                inputProps: {
                    inputRef: props.innerRef,
                    children: props.children,
                    className: "flex",
                    // className: clsx(" pl-3 py-0 ", props.className),
                    ...props.innerProps,
                },
                ...props.selectProps.textFieldProps?.inputProps,
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

// function Option(props) {
//     return (
//         <MenuItem
//             ref={props.innerRef}
//             selected={props.isSelected}
//             component="div"
//             style={{
//                 fontWeight: props.isSelected ? 500 : 400,
//             }}
//             disabled={props.disabled}
//             {...props.innerProps}
//         >
//             {props.children}
//         </MenuItem>
//     );
// }

// export function SelectOption(props) {
//     return (
//         <MenuItem
//             ref={props.innerRef}
//             selected={props.isSelected}
//             component="div"
//             style={{
//                 fontWeight: props.isSelected ? 500 : 400,
//             }}
//             disabled={props.disabled}
//             {...props.innerProps}
//         >
//             {props.children}
//         </MenuItem>
//     );
// }

// function Placeholder(props) {
//     return (
//         <span
//             className={"text-grey-dark"}
//             style={{
//                 position: "absolute",
//                 left: 10,
//                 top: 10,
//
//                 fontSize: 16,
//             }}
//             {...props.innerProps}
//         >
//             {props.children}
//         </span>
//     );
// }

// function SingleValue(props) {
//     return <span className={"flex"}>{props.children}</span>;
// }

function ValueContainer(props) {
    // console.log(props);
    return (
        <div
            data-cy={"select-component"}
            style={{
                display: "flex",
                // backgroundColor: "red",
                // height: 50,
                flexWrap: props.isMulti ? "wrap" : "nowrap",
                flex: 1,
                alignItems: "center",
                // overflow: 'auto',
            }}>
            {props.children}
        </div>
    );
}

function Menu(props) {
    return (
        <Paper
            square
            style={{
                position: "absolute",
                zIndex: 10,
                // top: 0,
                left: 0,
                right: 0,
            }}
            data-cy={"menu-selector"}
            {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

// const GroupHeading = props => (
//     <div style={groupStyles}>
//         <components.GroupHeading {...props} />
//         <Button color="secondary">
//             {<Translate id={"_.all"}/>}
//         </Button>
//     </div>
// );

const ClearIndicator = (props) => {
    const {
        getStyles,
        innerProps: {ref, ...restInnerProps},
    } = props;
    return (
        <span
            {...restInnerProps}
            ref={ref}
            data-cy={"clear-selector"}
            className={"cursor-pointer opacity-75 hover:opacity-100"}
            style={getStyles("clearIndicator", props)}>
            <Icon>close</Icon>
        </span>
    );
};

export const components = {
    Control,
    // Input: (props) => <Input {...props} inputRef={props.innerRef} />,
    Menu,
    ClearIndicator,
    // NoOptionsMessage,
    // Option,
    // Placeholder,
    // SingleValue,
    ValueContainer,
};

export const variantComponents = {
    Control,
    Menu,
};

export const selectStyles = {
    input: (base) => ({
        ...base,
        width: "100%",
        marginTop: 10,
        height: 34,
        "& input": {
            font: "inherit",
        },
        // singleValue: {
        //     background: "red"
        // },
        // valueContainer: {
        //     background: "blue"
        // },
        // zIndex: 99999999999,
        overflow: "visible",
    }),
    // zIndex: 99999999999,
    divider: {
        height: 20,
    },
    // clearIndicator: base => ({
    //     ...base,
    //     zIndex: 2,
    //     cursor: "pointer",
    //     background: "white"
    // }),
    // dropdownIndicator: base => ({
    //     ...base,
    //     zIndex: 2,
    //     cursor: "pointer",
    //     background: "white"
    // }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return {...provided, opacity, transition};
    },
    option: (styles, {isDisabled}) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            // backgroundColor: isDisabled
            //     ? null
            //     : isSelected
            //         ? data.color
            //         : isFocused
            //             ? color.alpha(0.1).css()
            //             : null,
            // zIndex: 99999999999,
            overflow: "visible",
            color: isDisabled ? "#ccc" : "black",
            cursor: isDisabled ? "not-allowed" : "default",
            ":active": {
                ...styles[":active"],
                // backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
};
// export const selectStylesSmall = {
//     input: (base) => ({
//         ...base,
//         width: "100%",
//         marginTop: 0,
//         // height: 40,
//         "& input": {
//             font: "inherit",
//         },
//         // backgroundColor: "red",
//         // placeholder: {
//         //     backgroundColor: "red",
//         // },
//         // singleValue: {
//         //     background: "red"
//         // },
//         // valueContainer: {
//         //     background: "blue"
//         // },
//         // zIndex: 99999999999,
//         overflow: "visible",
//     }),
//     clearIndicator: (base) => ({
//         ...base,
//         zIndex: 1,
//         cursor: "pointer",
//         background: "white",
//     }),
//     // dropdownIndicator: (base) => ({
//     //     ...base,
//     //     zIndex: 1,
//     //     cursor: "pointer",
//     //     background: "white",
//     // }),
//     // option: base => ({
//     //     ...base,
//     //     background: "red"
//     // }),
//     divider: {
//         height: 20,
//     },
//     singleValue: (provided, state) => {
//         const opacity = state.isDisabled ? 0.5 : 1;
//         const transition = "opacity 300ms";
//
//         return {...provided, opacity, transition};
//     },
//     option: (styles, {data, isDisabled, isFocused, isSelected}) => {
//         // const color = chroma(data.color);
//         return {
//             ...styles,
//             background: "red",
//             // backgroundColor: isDisabled
//             //     ? null
//             //     : isSelected
//             //         ? data.color
//             //         : isFocused
//             //             ? color.alpha(0.1).css()
//             //             : null,
//             // zIndex: 99999999999,
//             overflow: "visible",
//             color: isDisabled ? "#ccc" : "black",
//             cursor: isDisabled ? "not-allowed" : "default",
//             ":active": {
//                 ...styles[":active"],
//                 // backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
//             },
//         };
//     },
// };
// export const selectStylesDark = {
//     input: (base) => ({
//         ...base,
//         width: "100%",
//         marginTop: 10,
//         color: "white",
//         height: 34,
//         "& input": {
//             font: "inherit",
//         },
//         singleValue: {
//             background: "red",
//         },
//         valueContainer: {
//             background: "blue",
//         },
//         // zIndex: 99999999999,
//         overflow: "visible",
//     }),
//     // zIndex: 99999999999,
//     divider: {
//         height: 20,
//     },
//     singleValue: (provided, state) => {
//         const opacity = state.isDisabled ? 0.5 : 1;
//         const transition = "opacity 300ms";
//
//         return {...provided, opacity, transition};
//     },
//     option: (styles, {data, isDisabled, isFocused, isSelected}) => {
//         // const color = chroma(data.color);
//         return {
//             ...styles,
//             // backgroundColor: isDisabled
//             //     ? null
//             //     : isSelected
//             //         ? data.color
//             //         : isFocused
//             //             ? color.alpha(0.1).css()
//             //             : null,
//             // zIndex: 99999999999,
//             overflow: "visible",
//             color: isDisabled ? "#ccc" : "black",
//             cursor: isDisabled ? "not-allowed" : "default",
//             ":active": {
//                 ...styles[":active"],
//                 // backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
//             },
//         };
//     },
// };

// export const colourStyles = {
//     control: (styles) => ({...styles, backgroundColor: "white"}),
//     option: (styles, {data, isDisabled, isFocused, isSelected}) => {
//         const color = chroma(data.color);
//         return {
//             ...styles,
//             backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(1).css() : null,
//             color: isDisabled ? "#ccc" : isSelected ? (chroma.contrast(color, "white") > 2 ? "white" : "black") : data.color,
//             cursor: isDisabled ? "not-allowed" : "default",
//
//             ":active": {
//                 ...styles[":active"],
//                 backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
//             },
//         };
//     },
//     // multiValue: (styles, {data}) => {
//     //     const color = chroma(data.color);
//     //     return {
//     //         ...styles,
//     //         // backgroundColor: color.alpha(1).css(),
//     //         backgroundColor: color,
//     //     };
//     // },
//     // multiValueLabel: (styles, {data}) => ({
//     //     ...styles,
//     //     color: data.color,
//     // }),
//     // multiValueRemove: (styles, {data}) => ({
//     //     ...styles,
//     //     color: data.color,
//     //     ":hover": {
//     //         backgroundColor: data.color,
//     //         color: "white",
//     //     },
//     // }),
// };

// export const mySelectStyle = {};
//
// const groupStyles = {
//     // border: `2px dotted #ccc`,
//     color: "white",
//     background: "blue",
//     padding: "5px 0px",
//     display: "flex",
// };
