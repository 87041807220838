import useSWRMutation from "swr/mutation";
import axios, {financialPrefix} from "../../../../../myaxios";

function sendRequest(url, {arg}) {
    console.log("sendRequest", url, arg);
    return axios.get(`${url}/${arg?.id}`);
}

function useDetail() {
    const {trigger, isMutating, data, error} = useSWRMutation(`${financialPrefix}/income`, sendRequest);

    return {
        run: trigger,
        isMutating,
        data,
        error,
    };
}

export default useDetail;
