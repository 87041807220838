import Typography from "@material-ui/core/Typography";
import {MyTooltip} from "@ui";
import {Translate} from "react-localize-redux";

export const OrderLabel = ({order}) => (
    <div className={"flex flex-1 w-full overflow-x-auto justify-between divide-y-5 divide-gray-500"}>
        <MyTooltip title={<Translate id={"income.orderCode"} />}>
            <Typography component="span" align={"center"} variant="body2" className="mr-3 text-nowrap">
                {order?.orderCode}
            </Typography>
        </MyTooltip>
        <MyTooltip title={<Translate id={"income.projectCode"} />}>
            <Typography component="span" align={"center"} variant="body2" className="mr-3 text-nowrap">
                {order?.projectCode}
            </Typography>
        </MyTooltip>
        <MyTooltip title={<Translate id={"income.clientName"} />}>
            <Typography component="span" align={"center"} variant="body2" className="mr-3 text-nowrap">
                {order?.clientName}
            </Typography>
        </MyTooltip>
        <MyTooltip title={<Translate id={"income.projectName"} />}>
            <Typography component="span" align={"left"} variant="body2" className="mr-3 text-nowrap">
                {order?.projectName}
            </Typography>
        </MyTooltip>
        <MyTooltip title={<Translate id={"income.orderAmount"} />}>
            <Typography component="span" align={"center"} variant="body2" className="mr-3 text-nowrap">
                <strong>{order?.orderAmount}</strong>
            </Typography>
        </MyTooltip>
        <MyTooltip title={<Translate id={"income.availableAmount"} />}>
            <Typography component="span" align={"center"} variant="body2" className="text-nowrap">
                <strong>{order?.availableAmount}</strong>
            </Typography>
        </MyTooltip>
    </div>
);

export const  getExcelInfos = (wb) => {
    console.log('[MyWorker] > [onmessage] > wb:',wb);
    let resSheets=null;
    let resSheetNames = [];
    let resActiveTab = 0;
    if (wb&&wb.Sheets){
        // setSheets(dataSources.Sheets);
        resSheets = wb.Sheets;
    }
    if (wb&&wb.SheetNames&&wb.SheetNames.length>0){
        // setSheetNames(dataSources.SheetNames)
        resSheetNames = wb.SheetNames;
    }
    if (wb && wb.Workbook && wb.Workbook.WBView && wb.Workbook.WBView.length >0){
        // setValue(settingTab);
        resActiveTab = wb.Workbook.WBView[0].activeTab;
    }
    const result = {
        sheets: resSheets,
        sheetNames: resSheetNames,
        activeTab: resActiveTab
    };
    console.log('[MyWorker] > [postMessage] > result:',result);
    return result;
}
