const createSelectSlice = (set) => ({
    refreshId: null,
    selectActions: {
        onChangeRefreshId: (id) => {
            set({
                refreshId: id,
            });
        },
    },
});

export default createSelectSlice;
