import axios, {commonPrefix} from "@api";
import {showMessage} from "./message.actions";

export const FAVORITE = "[MIKE] FAVORITE";
export const DELETE_FAVORITE = "[MIKE] DELETE_FAVORITE";
export const GET_FAVORITE = "[MIKE] GET_FAVORITE";

export const favorite = (fType, sourceId, title) => {
    const data = {
        title,
        type: fType,
        sourceId: sourceId,
    };
    const req = axios.post(`${commonPrefix}/favorite`, data);

    axios.post(`${commonPrefix}/clickCount`, {
        type: "FAVE_BTN",
        name: fType,
    });
    return (dispatch) =>
        req.then((res) => {
            dispatch({
                type: FAVORITE,
                id: res.data.id,
                fType,
                sourceId,
            });
            dispatch(showMessage({message: "message.Favorite saved!", variant: "success"}));
        });
};
export const deleteFavorite = (id, fType, callback) => {
    const req = axios.delete(`${commonPrefix}/favorite/${id}`);

    return (dispatch) =>
        req.then(() => {
            dispatch({
                type: DELETE_FAVORITE,
                id,
                fType,
            });
            dispatch(showMessage({message: "message.Favorite deleted!", variant: "success"}));
            if (typeof callback === "function") {
                callback();
            }
        });
};

export const getFavorites = (fType) => {
    // here have 翻页(从 1 开始)

    const req = axios.get(`${commonPrefix}/favorites?type=${fType}&size=50`);
    return (dispatch) =>
        req.then((res) =>
            dispatch({
                type: GET_FAVORITE,
                data: res.data.content,
                fType,
            })
        );
};
