import {RootView} from "../componenets";
import Grid from "@material-ui/core/Grid";
import UploadfileView from "./UploadfileView";
import CategoryTableView from "./CategoryTableView";
import {makeStyles} from "@material-ui/styles";
import {createStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    })
);

function FirtStepView() {
    const classes = useStyles();
    return (
        <>
            <RootView>
                <Grid container className={classes.root} spacing={3}>
                    <Grid item xs={3}>
                        <UploadfileView />
                    </Grid>
                    <Grid item xs={9}>
                        <CategoryTableView />
                    </Grid>
                </Grid>
            </RootView>
        </>
    );
}

export default FirtStepView;
