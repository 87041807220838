import {setCache, turnOff} from "@fuseActions";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {DeleteWrapper, LocalTimeStamp, MyAvatar, NameLabel, ParseHTML} from "@ui";
import classNames from "classnames";
import clsx from "clsx";
import type {ReactNode, RefObject} from "react";
import {useEffect, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import type {userType} from "../types";
import EditorInput, {sanitizeFromEditable} from "./EditorInput/EditorInput";

export type CommentItemType = {
    editable?: boolean;
    isRead?: boolean;
    content: string;
    replies?: CommentItemType[];
    isChanged?: boolean;
    id: string;
    user: userType;
    deleteDate: number;
    timestamp: number;
    notDeletable?: boolean;
    replyTo?: string;
};

type Props = {
    commentsRef?: RefObject<HTMLFormElement>;
    item: CommentItemType;
    // if it's a reply so it shows indented
    isReply?: boolean;
    // third level reply
    isThirdLevel?: boolean;
    // refresh button
    refresh: () => void;
    /**
     * ### Shows in front of the title
     * @param item - the comment item itself
     * @return {ReactNode} - A react node
     *
     */
    extraTitle?: (item: CommentItemType) => ReactNode;
    /**### Axios request
     * example:
     *   ```
     *   const deleteReq = (id) => {
     *       return axios.post(`${firmPrefix}/workplanComment`, {...payload, "workplanId": id,})
     *   };
     *   ```
     */
    deleteReq?: (id: string) => Promise<any>;
    /**
     * ### Axios request
     * example:
     *   ```
     *   const editReq = (commentItem) => {
     *          return axios.post(`${firmPrefix}/workplanComment`, {...payload, "workplanId": id,})
     *   };
     *   ```
     */
    editReq?: (commentItem: Partial<CommentItemType>) => Promise<any>;
    /**
     * ### Axios request
     * example:
     *   ```
     *   const replyReq = (commentItem) => {
     *       return axios.post(`${firmPrefix}/workplanComment`, {...payload, "workplanId": id,})
     *   };
     *   ```
     */
    replyReq?: (commentItem: Partial<CommentItemType>) => Promise<any>;
    /**
     * ### Axios request
     * example:
     *   ```
     *   const fileUploadReq = (files) => {
     *       return axios.post(`${firmPrefix}/workplanComment`, {...payload, "workplanId": id,})
     *   };
     *   ```
     */
    fileUploadReq?: (data: any) => Promise<any>;
};

const CommentItem = (props: Props) => {
    const {item, commentsRef, isReply, refresh, deleteReq, editReq, replyReq, fileUploadReq, isThirdLevel, extraTitle} = props;
    const dispatch = useDispatch();
    const replyForm = useRef<HTMLFormElement>(null);
    const currentReplyId = useSelector(({fuse}) => fuse.dialogs["currentReplyId"]);
    const currentEditId = useSelector(({fuse}) => fuse.dialogs["currentEditId"]);
    const [loading, setLoading] = useState(false);
    const [editComment, setEditComment] = useState<Partial<CommentItemType>>();
    const [showReplies, setShowReplies] = useState(true);
    const [reply, setReply] = useState("");

    useEffect(() => {
        return () => {
            dispatch(turnOff("currentReplyId"));
            dispatch(turnOff("currentEditId"));
        };
    }, []);

    const changeEditComment = (e) => {
        setEditComment(editComment ? {id: editComment.id, content: e.target.value} : undefined);
    };

    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };

    const toggleReply = () => {
        const language = localStorage.getItem("i18nextLng");

        dispatch(turnOff("currentEditId"));
        if (currentReplyId === item.id) {
            dispatch(turnOff("currentReplyId"));
        } else {
            dispatch(setCache("currentReplyId", item.id));
            setTimeout(() => {
                replyForm?.current?.scrollIntoView({behavior: "smooth", block: "center"});
            }, 100);
        }
        setReply(
            `<em suppressContentEditableWarning={true}  contenteditable="false" class="px-2 py-1 font-bold bg-blue-lighter border-0 inline-block rounded-4">@${
                language === "cn" ? item.user.lastName + item.user.firstName : item.user.firstName + item.user.lastName
            } <small>(@${item.user.username})</small></em> &nbsp;`
        );
    };

    const toggleEdit = () => {
        dispatch(turnOff("currentReplyId"));
        if (currentEditId === item.id) {
            dispatch(turnOff("currentEditId"));
            setEditComment(undefined);
        } else {
            dispatch(setCache("currentEditId", item.id));
            setEditComment(item);
        }
    };

    const submitEditComment = (e) => {
        e.preventDefault?.();
        if (!editComment || (editComment.content && editComment.content.trim() === "")) return;
        setLoading(true);

        editReq?.(editComment)
            .then(() => {
                refresh();
                dispatch(turnOff("currentEditId"));
                setEditComment(undefined);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submitReply = (e) => {
        e.preventDefault?.();
        if (reply.trim() === "") return;
        setLoading(true);
        const data: Partial<CommentItemType> = {
            content: sanitizeFromEditable(reply),
            replyTo: currentReplyId,
        };
        replyReq?.(data)
            .then(() => {
                refresh();
                setReply("");
                commentsRef?.current?.scrollIntoView({behavior: "smooth"});

                dispatch(turnOff("currentReplyId"));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const uploadFileCallback = (attachments) => {
        const data = {
            attachments,
        };
        fileUploadReq?.(data).then(() => {
            refresh();
        });
    };

    return (
        <>
            <ListItem
                data-cy={"comment-item"}
                classes={{
                    root: classNames(loading && "loading", "py-0 hider", !item.isRead && "bg-yellow-lighter"),
                }}
                alignItems="flex-start">
                <ListItemAvatar className={clsx(isReply ? "min-w-28" : "min-w-36", "mr-3", item.deleteDate && "opacity-40")}>
                    <Badge overlap="rectangular" invisible={item.isRead} color={"error"} variant={"dot"}>
                        <MyAvatar user={item.user} size={isReply ? 20 : 32} />
                    </Badge>
                </ListItemAvatar>

                <ListItemText
                    disableTypography
                    // region Comment title
                    primary={
                        <div className={clsx(item.deleteDate && "line-through opacity-40", "flex justify-between")}>
                            <div className="flex flex-row items-end">
                                <span className={classNames(isReply && "text-xs", "text-bold mr-2 ")}>
                                    <NameLabel user={item.user} />
                                </span>

                                <small className={"text-grey mr-12"}>
                                    (
                                    <LocalTimeStamp datetime={item.timestamp} format={"YYYY-MM-DD HH:mm"} />)
                                </small>

                                {!!extraTitle && extraTitle(item)}
                            </div>

                            <div className={classNames(isReply && "text-xs", "flex items-center")}>
                                {!isThirdLevel && !!replyReq && (
                                    <button data-cy={"toggle-reply"} className={classNames("mr-12", "text-grey-dark hid")} onClick={toggleReply}>
                                        {currentReplyId === item.id ? (
                                            <>
                                                <Icon className={"mr-1 text-16 align-middle"}>close</Icon>
                                                <span className={"align-middle"}>
                                                    <Translate id={"_.close"} /> <Translate id={"_.Reply"} />
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <Icon className={"mr-1 text-16 align-middle"}>reply</Icon>
                                                <span className={"align-middle"}>
                                                    <Translate id={"_.Reply"} />
                                                </span>
                                            </>
                                        )}
                                    </button>
                                )}

                                {item.editable && !item.deleteDate && !!editReq && (
                                    <>
                                        <button className={"text-grey mr-12 hid"} data-cy={"toggle-edit"} onClick={toggleEdit}>
                                            {currentEditId === item.id ? (
                                                <>
                                                    <Icon className={"mr-1 text-16 align-middle"}>close</Icon>
                                                    <span data-cy={"close-edit"} className={"align-middle"}>
                                                        <Translate id={"_.cancel"} />
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <Icon className={"mr-1 text-16 align-middle"}>edit</Icon>
                                                    <span data-cy={"open-edit"} className={"align-middle"}>
                                                        <Translate id={"_.edit"} />
                                                    </span>
                                                </>
                                            )}
                                        </button>
                                    </>
                                )}

                                {!!deleteReq && !item.notDeletable && !currentEditId && item.editable && !(item.deleteDate > 0) && (
                                    <DeleteWrapper item={item.content} deleteReq={() => deleteReq(item.id)} callback={refresh}>
                                        {(onClick) => (
                                            <button data-cy={"delete-comment"} className={"text-grey hid"} onClick={onClick}>
                                                <Icon className={"mr-1 text-16 align-middle"}>delete</Icon>
                                                <span className={"align-middle"}>
                                                    <Translate id={"_.delete"} />
                                                </span>
                                            </button>
                                        )}
                                    </DeleteWrapper>
                                )}
                                {!isReply && item.replies && item.replies?.length > 0 && (
                                    <button
                                        data-cy={"toggle-replies"}
                                        className={clsx(
                                            showReplies ? "bg-grey" : " bg-grey-dark",
                                            "rounded-full flex items-center pl-12 pr-3 text-white ml-2"
                                        )}
                                        onClick={toggleReplies}>
                                        <span>{item.replies.length}</span>
                                        <Icon fontSize={"inherit"} className={"ml-4"}>
                                            {showReplies ? "expand_more" : "expand_less"}
                                        </Icon>
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                    // endregion

                    secondary={
                        <div>
                            {/*// region Comment Description and edit*/}
                            {editComment && currentEditId === item.id ? (
                                <form onSubmit={submitEditComment} className={"pt-20"}>
                                    <EditorInput
                                        showToolbar
                                        name={"edit-comment"}
                                        uploadFileCallback={uploadFileCallback}
                                        onSubmit={submitEditComment}
                                        value={editComment.content}
                                        onChange={changeEditComment}
                                        placeholder={<Translate id="wps.Write comment" />}
                                    />
                                </form>
                            ) : item.deleteDate ? (
                                <div
                                    className={clsx(
                                        isReply ? "bg-grey-light p-2 mt-0" : "bg-grey-lighter p-8 mt-2 border",
                                        "opacity-40 line-through text-grey"
                                    )}>
                                    {<Translate id={"wps.Comment has been deleted!"} />}
                                </div>
                            ) : (
                                <div
                                    className={clsx(
                                        isReply ? "bg-grey-light p-2 mt-0" : "bg-grey-lighter p-16 mt-2 ",
                                        " rounded-8  overflow-hidden "
                                    )}
                                    style={{overflowWrap: "anywhere"}}>
                                    <ParseHTML html={item.content} />
                                </div>
                            )}
                            {/*//endregion*/}

                            {/*region  Reply form */}
                            {currentReplyId === item.id && (
                                <form onSubmit={submitReply} ref={replyForm} className="pt-16 pl-28">
                                    <EditorInput
                                        showToolbar
                                        uploadFileCallback={uploadFileCallback}
                                        onSubmit={submitReply}
                                        value={reply}
                                        name={"reply-comment"}
                                        onChange={(e) => setReply(e.target.value)}
                                        placeholder={<Translate id="wps.Write comment" />}
                                    />
                                    <div className="flex justify-end items-center pt-8">
                                        <Button data-cy={"close-reply"} className={classNames("mr-12", "text-grey-dark")} onClick={toggleReply}>
                                            <>
                                                <Icon className={"mr-1 text-16 align-middle"}>close</Icon>
                                                <span className={"align-middle"}>
                                                    <Translate id={"_.close"} />
                                                </span>
                                            </>
                                        </Button>
                                        <Button data-cy={"submit-reply"} onClick={submitReply} variant={"contained"} color={"primary"}>
                                            <Translate id={"_.send"} />
                                        </Button>
                                    </div>
                                </form>
                            )}
                            {/*endregion*/}
                        </div>
                    }
                />
            </ListItem>

            {/* region Replies*/}
            {item.replies && item.replies.length > 0 && showReplies && (
                <li data-cy={"replies-wrapper"}>
                    <List dense className={classNames(!isThirdLevel ? "pl-36" : "pl-16", "pt-0")}>
                        {item.replies.map((_r, i) => (
                            <CommentItem {...props} isReply isThirdLevel={isReply} item={_r} key={i} />
                        ))}
                    </List>
                </li>
            )}
            {/* endregion*/}
        </>
    );
};

export default CommentItem;
