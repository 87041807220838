import axios, {userPrefix} from "@api";
import qs from "qs";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import history from "../../@history";
import {baseURL} from "../../ENV.VARIABLE";
import {db} from "../../hooks/db";
import {usePersonalized} from "../../hooks/usePersonalized";
import type {userType} from "../../types";
import {logoutUser, setUserData} from "./store/actions";

type AuthContextType = {
    token: string | null;
    isAuthenticated: boolean;
    login: (token: string) => Promise<any>;
    logout: () => void;
    user?: userType;
    roles?: string[];
    loading: boolean;
};

const AuthContext = createContext<AuthContextType>({
    token: null,
    isAuthenticated: false,
    // @ts-ignore
    login: () => {},
    logout: () => {},
    loading: false,
});

export const isSecurePath = (pathname?: string) => {
    const paths = ["/login", "/register", "/support", "/forgot-password"];
    return !paths.includes(pathname || history.location.pathname);
};

const AuthProvider = ({children}: {children: ReactNode}) => {
    // region states and hooks
    const dispatch = useDispatch() as (action: any) => void;
    const [token, setToken] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<userType>();
    const [roles, setRoles] = useState<string[]>();
    const [loading, setLoading] = useState(false);

    const {init} = usePersonalized();
    const {i18n} = useTranslation();
    // endregion

    const login = async (token: string) => {
        localStorage.setItem("token", token);
        setToken(token);
        setIsAuthenticated(true);

        // set user data in redux
        dispatch(setUserData());

        // set user in context
        const {data} = await axios.get<{profile: userType; roles: {name: string}[]}>(`${userPrefix}/baseInfo`);

        setUser(data.profile);
        setRoles(data.roles.map((x) => x.name));
        await init(data.profile.id);
        const settings = await db.settings.get(data.profile.id);
        if (settings) {
            localStorage.setItem("i18nextLng", settings.language);
            await i18n.changeLanguage(settings.language);
        }
        setLoading(false);
    };

    const logout = () => {
        setToken(null);
        setIsAuthenticated(false);
        setUser(undefined);
        setRoles(undefined);
        dispatch(logoutUser());
        setLoading(false);
    };

    useEffect(() => {
        if (isSecurePath()) {
            const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});

            const tokenFromStorage = localStorage.getItem("token");
            const token = tokenFromStorage || query.token;

            if (token !== undefined && token !== "" && token !== null) {
                setLoading(true);
                axios({
                    method: "get",
                    url: `${baseURL}/${userPrefix}/checkToken`,
                    headers: {
                        "Content-Type": "application/json",
                        token: token,
                    },
                })
                    .then((res) => {
                        if (res.data.result === "valid" || res.data.result === "refresh") {
                            login(res.data.token || token);
                        } else {
                            logout();
                        }
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                logout();
            }
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                token,
                isAuthenticated,
                user,
                roles,
                login,
                logout,
                loading,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export {AuthContext, AuthProvider};
