import Icon from "@material-ui/core/Icon";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import {defaultStyles, FileIcon} from "react-file-icon";
import {Translate} from "react-localize-redux";
import type {AttachmentType} from "../../types";
import {createDownloadLink, extExtractor, isImage} from "../../utils/utilities";
import {MyTooltip} from "./MyTooltip";
import {styleDefObj} from "./style-customize";
import type {ThumbSizes} from "./Thumbnail";
import {Thumbnail} from "./Thumbnail";

const useStyles = makeStyles(() => ({
    root: {
        "&:hover .icon": {
            opacity: 0.6,
        },
    },
}));

type Props = {
    file: AttachmentType;
    download?: boolean;
    size?: ThumbSizes;
    onClickImage?: () => void;
};
/**
 * @param class Add max-width, class or style
 */
export default function FileThumb({file, download, size = "106", onClickImage}: Props) {
    const classes = useStyles();
    const ext = extExtractor(file.visitUrl);
    // If custom style def do not have labelColor, use #777
    const customDefaultLabelColor = styleDefObj[ext] ? styleDefObj[ext]["labelColor"] ?? "#777" : "#777";

    // Library defined default labelCOlor
    const libDefaultGlyphColor = defaultStyles[ext] && defaultStyles[ext]["labelColor"];
    return (
        <div className={clsx(classes.root, "hider relative", `w-${size}`)}>
            {download && !(isImage(file.visitUrl) && onClickImage) && (
                <div className={"right-0 items-center  justify-center flex bottom-0 top-0 left-0 absolute hid text-gray-dark z-99"}>
                    <MyTooltip
                        title={
                            <>
                                <Translate id={"_.download"} /> {file.originalName}
                            </>
                        }>
                        <button
                            className={"text-gray hover:text-blue"}
                            onClick={() => {
                                if (download) createDownloadLink(file.visitUrl, file.originalName);
                            }}>
                            <Icon>download</Icon>
                        </button>
                    </MyTooltip>
                </div>
            )}

            <span className={download ? "icon duration-200" : ""}>
                {isImage(file.visitUrl) ? (
                    onClickImage ? (
                        <button className="block" onClick={onClickImage}>
                            <Thumbnail size={size} src={file.visitUrl} />
                        </button>
                    ) : (
                        <Thumbnail size={size} src={file.visitUrl} />
                    )
                ) : (
                    <FileIcon
                        extension={ext}
                        glyphColor={libDefaultGlyphColor ?? customDefaultLabelColor}
                        labelColor={customDefaultLabelColor}
                        {...defaultStyles[ext]}
                        {...styleDefObj[ext]}
                    />
                )}
            </span>
        </div>
    );
}
