import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {Translate, withLocalize} from "react-localize-redux";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import {useRef} from "react";

function NumberFormatCustom(props) {
    const {onChange, inputRef, ...rest} = props;
    return (
        <NumberFormat
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.floatValue,
                    },
                });
            }}
            {...rest}
        />
    );
}

NumberFormatCustom.propTypes = {
    // inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

function NumberLimitInput(props) {
    useRef(null);
    const {label, size, variant, unit, classNames, id, disabled, value, onChange} = props;

    return (
        <TextField
            size={size}
            variant={variant}
            disabled={disabled}
            onChange={onChange}
            className={classNames}
            type={"number"}
            label={
                unit ? (
                    <>
                        <Translate id={label} />(<Translate id={unit} />)
                    </>
                ) : (
                    label && <Translate id={label} />
                )
            }
            id={id ? id : "formatted-numberformat-input"}
            value={value}
            // InputProps={{
            //     ref,
            //     inputComponent: (props) => (
            //         <NumberFormatCustom
            //             {...props}
            //             {...numberInputProps}
            //             inputRef={ref}
            //             defaultValue={value}
            //             onChange={onChange}
            //         />
            //     ),
            // }}
            //{...rest}
        />
    );
}

NumberLimitInput.propTypes = {
    id: PropTypes.any,
    // input 提示文字
    label: PropTypes.string,
    // input 内容单位
    unit: PropTypes.string,
    classNames: PropTypes.string,
    // 将 input 输入的值传出去
    // passInputValue: PropTypes.func.isRequired,
};

export default withLocalize(NumberLimitInput);
