export const OPEN_DIALOG = "[API] OPEN_DIALOG";
export const CLOSE_DIALOG = "[API] CLOSE_DIALOG";

export const turnOn = (dialog) => {
    return {
        type   : OPEN_DIALOG,
        dialog
    };
};
export const turnOff = (dialog) => {
    return {
        type   : CLOSE_DIALOG,
        dialog
    };
};
