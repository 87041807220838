export const createListSlice = (set, get) => ({
    instanceSelected: [],
    openMore: [],
    selectedUserTask: null,
    onChangeSelectedUserTask: (data) => {
        set({
            selectedUserTask: data,
        });
    },
    onChangeOpenMore: (datas) => {
        set({
            openMore: datas,
        });
    },
    onChangeSelectedInstance: (datas) => {
        set({
            instanceSelected: datas,
        });
    },
});
