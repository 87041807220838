import {finishLoading, showMessage, startLoading, turnOn} from "@fuseActions";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import axios, {pmsPrefix} from "myaxios";
import PropTypes from "prop-types";
import {Component} from "react";
import {Map, Marker} from "react-amap";
import Autocomplete from "react-amap-plugin-autocomplete";
import Geolocation from "react-amap-plugin-geolocation";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {clearToMapBrandId, passNearProjectsDatas, passSelectedMapGPS} from "../../../store/actions";

import mapjson from "./GeocoderUtitl/map.json";
import ReGeocoderTool from "./GeocoderUtitl/ReGeocoderTool";

const styles = () => ({
    root: {
        height: "100%",
        width: "100%",
    },
    map: {
        height: "100%",
        width: "100%",
        visibility: "visible",
        zIndex: 98888,
    },
    justShowMap: {
        height: "100%",
        width: "100%",
    },
    textField: {
        width: "100%",
    },
    input: {
        height: "20%",
    },
});

/**
 *    高德
 * eg: 搜索: (新航路1号)
 * res.poi:  {
 *          id, （全局唯一ID）（值 : B0FFGC9ACZ）
 *          name, （名称）(值: 桑瑞标识系统股份有限公司)
 *          district,（所属区域）(四川省成都市郫都区)
 *          adcode, （区域编码）(510117)
 *          location, （兴趣点经纬度）(103.96264,30.73692)
 *          address, （地址） (高新西区新航路1号)
 *          typecode, (170200)
 *          city
 * }
 *
 *
 * mapbox
 *
 * res.result: {
 *      id,   (全局唯一ID： )
 *      type, （类型: ）
 *      place_type,
 *      relevance, /可信度 (0 ~ 1)
 *      properties,
 *      text,
 *      place_name,
 *      bbox,
 *      center,
 *      geometry,
 *      context
 *  }
 *
 *  geometry: {
 *      type,
 *      coordinates
 *  }
 *
 *  coordinates: {
 *      lng,
 *      lat
 *  }
 * */

class MapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            lng: 0.0,
            lat: 0.0,
            marketPosition: null,
            target: "",
            locationInfo: null,
            disabledButton: true,
            listener: "",
            center: null,
            geocoderLatitude: null,
            geocoderLongitude: null,
            //en- country(国家) / cn - province(省)
            levelOne: "",
            //en- region（州） / cn - city（市）
            levelTwo: "",
            //en - place （地方）/ cn - district （区）
            levelThree: "",
            //详细地址
            fullAddress: "",
            language: "cn",
            navigatorFinshed: false,
            removeMap: false,
        };
        this.props.addTranslation(mapjson);
    }

    componentDidMount() {
        if (!!this.props.gps && _.isArray(this.props.gps) && this.props.gps.length > 0) {
            this.setState({
                marketPosition: {
                    longitude: this.props.gps[0],
                    latitude: this.props.gps[1],
                },
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            lng: 0.0,
            lat: 0.0,
            marketPosition: undefined,
            target: "",
            locationInfo: undefined,
            disabledButton: true,
            listener: "",
            center: null,
            geocoderLatitude: null,
            geocoderLongitude: null,
            removeMap: true,
        });
    }

    // on select item
    selectfunc = (e) => {
        if (e.poi.location) {
            // console.log(`getlocation ${e.poi.location.lng} ,${e.poi.location.lat}`);
            this.setState({
                marketPosition: {longitude: e.poi.location.lng, latitude: e.poi.location.lat},
                geocoderLatitude: e.poi.location.lat,
                geocoderLongitude: e.poi.location.lng,
            });
        }
        if (this.props.useType === "project") {
            if (this.props.brandIdToMap) {
                this.handleNearByProject(e.poi.location.lng, e.poi.location.lat);
            }
        }
    };

    /*
     * 根据GPS搜素该位置是否有同品牌的项目
     * */
    handleNearByProject = (lng, lat) => {
        // console.log(`选择客户的brandid = > ${this.props.customerOfBrandId} `);

        const parameter = {
            gps: [lng, lat],
            brandId: this.props.brandIdToMap,
        };
        const request = axios.put(`${pmsPrefix}/nearbyProjects`, parameter);
        request.then(
            (res) => {
                // for (const t in res.data) {
                //     // console.log(`附近的项目 key = ${t}, 对应的值 => ${res.data[t]}`);
                // }
                if (res.data.length > 0) {
                    // this.props.passGPS([e.poi.location.lng, e.poi.location.lat]);
                    this.props.passNearProjectsDatas(res.data);
                    this.props.turnOn("nearByProjects");
                } else {
                    this.props.showMessage({message: "No nearby project.", variant: "default"});
                }
            },
            (errors) => {
                this.setState({
                    err: errors,
                });
            }
        );
    };

    /*
     * 返回逆解析的结果
     * */
    getRegeoAllData = (res) => {
        this.props.finishedLoading();
        const {geocoderLatitude, geocoderLongitude} = this.state;
        // for (const temp in res) {
        //     console.log(`MapComponent 得到 逆解析后传上来的结果 =====>key = ${temp}; 值是 => ${res[temp]}`);
        // }
        this.setState({
            levelOne: res.levelOne,
            levelTwo: res.levelTwo,
            levelThree: res.levelThree,
            fullAddress: res.fullAddress,
        });
        const param = {
            lng: geocoderLongitude,
            lat: geocoderLatitude,
            levelOne: res.levelOne,
            levelTwo: res.levelTwo,
            levelThree: res.levelThree,
            fullAddress: res.fullAddress,
        };
        this.props.haveGetLocation(param);
    };

    /*
     * 返回正向解析的GPS信息
     * */
    callbackGPS = (res) => {
        this.props.finishedLoading();
        // console.log(`正向解析成功!`);
        this.setState({
            marketPosition: res.lnglat,
        });
    };

    render() {
        const {classes, address, gps} = this.props;

        const {geocoderLatitude, geocoderLongitude} = this.state;

        const plugins = [
            "Scale",
            // 'ToolBar'
        ];

        const style = {
            width: "100%",
            position: "absolute",
            visibility: "visible",
            borderColor: "#621b18",
            borderWidths: "2px",
            top: "0px",
            zIndex: 999999999,
        };

        const geopluginProps = {
            enableHighAccuracy: true, //是否使用高精度定位，默认:true
            timeout: 10000, //超过10秒后停止定位，默认：无穷大
            buttonPosition: "RB", //定位按钮停靠位置，默认：'LB'，左下角
            zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            events: {
                created: (o) => {
                    (this.props.gps === null || (_.isArray(this.props.gps) && this.props.gps.length === 0)) &&
                        o.getCurrentPosition((status, result) => {
                            if (result && result.position) {
                                console.log("当前位置信息 => ", result);
                                const {lng, lat} = result.position;
                                this.setState({
                                    marketPosition: {
                                        longitude: lng,
                                        latitude: lat,
                                    },
                                });
                            }
                        });
                },
            },
        };

        const pluginProps = {
            datatype: "all",
            input: this.props.inputId,
        };

        const events = {
            created: () => {},
            click: (e) => {
                // console.log("这里是地图点击事件");
                if (this.props.useType === "project") {
                    if (this.props.brandIdToMap) {
                        /** 这个函数是上传给上层组件显示 loading 的事件传递函数 **/
                        this.props.showLoading();
                        this.props.startLoading();
                        this.handleNearByProject(e.lnglat.getLng(), e.lnglat.getLat());
                        this.props.canClick === true &&
                            e.lnglat &&
                            this.setState(
                                {
                                    marketPosition: {longitude: e.lnglat.getLng(), latitude: e.lnglat.getLat()},
                                    geocoderLatitude: e.lnglat.getLat(),
                                    geocoderLongitude: e.lnglat.getLng(),
                                },
                                () => {
                                    this.props.finishLoading();
                                }
                            );
                        // console.log("点击地图事件获取成功!");
                    } else {
                        this.props.showErrorTips();

                        // this.props.showMessage("message.Please choose a customer or brand first!");

                        // console.log("点击地图事件因为没有 brandId 出错!");
                    }
                } else {
                    this.props.showLoading();
                    this.props.startLoading();
                    // this.handleNearByProject(e.lnglat.getLng(), e.lnglat.getLat());
                    this.props.canClick === true &&
                        this.setState(
                            {
                                marketPosition: {longitude: e.lnglat.getLng(), latitude: e.lnglat.getLat()},
                                geocoderLatitude: e.lnglat.getLat(),
                                geocoderLongitude: e.lnglat.getLng(),
                            },
                            () => {
                                this.props.finishLoading();
                            }
                        );
                }
            },
        };

        return (
            <>
                {this.state.removeMap === false && (
                    <div className={classes.map}>
                        <Map
                            amapkey="db41b38ab7028e90c33f2baa9e1330ab"
                            version="1.4.15"
                            plugins={plugins}
                            center={this.state.marketPosition ? this.state.marketPosition : false}
                            events={events}>
                            <Autocomplete options={pluginProps} onSelect={(e) => this.selectfunc(e)} style={style} placeholder="搜索" />
                            {(!gps || !address) && <Geolocation {...geopluginProps} />}

                            {!!this.state.marketPosition && (
                                <Marker
                                    bubble
                                    position={this.state.marketPosition}
                                    // animation="AMAP_ANIMATION_BOUNCE"
                                />
                            )}
                            {!!geocoderLatitude && !!geocoderLongitude && (
                                <ReGeocoderTool
                                    inputId={this.props.inputId}
                                    latitude={geocoderLatitude}
                                    longitude={geocoderLongitude}
                                    passRegeoAllInfomation={this.getRegeoAllData}
                                    language={this.state.language}
                                />
                            )}
                            {!!address && (
                                <ReGeocoderTool address={address} language={this.state.language} callbackGeocodeAllInfomation={this.callbackGPS} />
                            )}
                        </Map>
                    </div>
                )}
            </>
        );
    }
}

MapComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    listener: PropTypes.string,
    text: PropTypes.string,
    haveError: PropTypes.func,
    /*
     * 逆向解析的回调
     * */
    haveGetLocation: PropTypes.func,
    /*
     * 正向解析的回调
     * */
    haveGeocodeInfo: PropTypes.func,
    /*
     *输入框的id
     * */
    inputId: PropTypes.string,
    /*
     * gps     ===>   当编辑项目时，需要显示该项目的位置，此时需要回传该项目的 gps 参数
     * */
    gps: PropTypes.array,
    /*
     * address    ====>  当编辑客户时，客户只有地址信息，此时需要根据该地址信息解析出 gps 信息，然后在地图上显示该点
     * */
    address: PropTypes.string,
    /*
     * 当地图出现耗时操作的时候，提示用户的 loading 函数
     * **/
    showLoading: PropTypes.func,
    /*
     * 当地土出现错误时，需要提醒上层引用组件
     * **/
    showErrorTips: PropTypes.func,
    /*
     * 当地图耗时操作完成之后，取消 loading的 函数
     * **/
    finishedLoading: PropTypes.func,

    /**
     * 当  新建客户的时候，地图使用没有限制 (useType = customer)
     * 当 新建项目时，因为需要检查附近是否有相同品牌的项目，所以需要先获取到 品牌id (userType = project)
     * **/
    useType: PropTypes.string,
};

MapComponent.defaultProps = {
    /*
     * canClick   =====>  地图能够被点击,默认值是能够被点击
     *
     * */
    canClick: true,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: turnOn,
            finishLoading: finishLoading,
            startLoading: startLoading,
            passGPS: passSelectedMapGPS,
            passNearProjectsDatas: passNearProjectsDatas,
            showMessage: showMessage,
            clearToMapBrandId: clearToMapBrandId,
        },
        dispatch
    );
}

export default withStyles(styles, {withTheme: true})(withLocalize(connect(null, mapDispatchToProps)(MapComponent)));
