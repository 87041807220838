import {Icon, ListItem, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import {memo} from "react";
import {withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import * as Actions from "../../../../app/store/actions";
import FuseNavBadge from "./../FuseNavBadge";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 48,
        "&.active": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + "!important",
            pointerEvents: "none",
            "& .list-item-text-primary": {
                color: "inherit",
            },
            "& .list-item-icon": {
                color: "inherit",
            },
        },
        "& .list-item-icon": {},
        "& .list-item-text": {
            padding: "0 0 0 16px",
        },
        color: "red !important",
        textDecoration: "none!important",
        "&.dense": {
            padding: "8px 12px 8px 12px",
            minHeight: 40,
            "& .list-item-text": {
                padding: "0 0 0 8px",
            },
        },
    },
}));

/**
 * @return {null}
 */
function FuseNavHorizontalLink(props) {
    const dispatch = useDispatch();
    //    const userRole = useSelector(({user}) => user.role);

    const classes = useStyles(props);
    const {item, dense} = props;

    //    if (!FuseUtils.hasPermission(item.auth, userRole)) {
    //        return null;
    //    }

    return (
        <ListItem
            button
            component="a"
            href={item.url}
            target={item.target ? item.target : "_blank"}
            className={clsx(classes.root, dense && "dense")}
            // className={clsx("list-item", classes.root, dense && "dense")}
            onClick={(ev) => dispatch(Actions.navbarCloseMobile())}
        >
            {item.icon && (
                <Icon className="list-item-icon text-12 flex-shrink-0" color="action">
                    {item.icon}
                </Icon>
            )}
            <ListItemText
                className="list-item-text"
                primary={props.activeLanguage.code === "cn" ? item.titleCn : item.title}
                classes={{primary: "text-12 list-item-text-primary"}}
            />
            {item.badge && <FuseNavBadge className="ml-8" badge={item.badge} />}
        </ListItem>
    );
}

FuseNavHorizontalLink.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        icon: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
    }),
};

FuseNavHorizontalLink.defaultProps = {};

const NavHorizontalLink = withRouter(memo(FuseNavHorizontalLink));

export default withLocalize(NavHorizontalLink);
