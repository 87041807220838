import { GET_ORDER, SAVE_ORDER } from "../actions";

const initialState = null;

const orderReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case GET_ORDER:
        {
            return {
                ...action.payload
            };
        }
        case SAVE_ORDER:
        {
            return {
                ...action.payload
            };
        }
        default:
        {
            return state;
        }
    }
};

export default orderReducer;
