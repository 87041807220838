import FormLabel from "@material-ui/core/FormLabel";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import type {ChangeEvent} from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

type Props = {
    title: string;
    name: string;
    onChange(arg: string[]): void;
    defaultItems?: string[];
    className?: string;
};
const ListComponent = ({name, defaultItems, onChange, title, className}: Props) => {
    const {t} = useTranslation("issues");
    const [items, setItems] = useState<string[]>([]);

    const onAdd = () => {
        const t = [...items];
        t?.push("");
        setItems(t);
    };

    useEffect(() => {
        if (defaultItems) {
            setItems(defaultItems);
        }
    }, []);

    const onChangeText = (i: number) => (e: ChangeEvent<HTMLInputElement>) => {
        const t = [...items];
        t[i] = e.target.value;
        setItems(t);
    };

    const onDeleteItem = (index: number) => () => {
        const t = [...items];
        t?.splice(index, 1);
        setItems(t);
    };

    useEffect(() => {
        onChange(items);
    }, [items]);

    return (
        <div className={clsx(className, "bg-gray-50 px-20 py-2 rounded-8 border-1 border-gray-200")}>
            <div className="flex justify-between items-center">
                <FormLabel>{title}</FormLabel>
                <IconButton data-cy={`add-item-${name}`} onClick={onAdd}>
                    <Icon>add</Icon>
                </IconButton>
            </div>
            {items?.map((_, i) => (
                <TextField
                    data-cy={`input-${name}`}
                    key={i}
                    autoFocus={true}
                    onKeyDown={({key}) => {
                        if (key === "Enter") onAdd();
                    }}
                    margin={"normal"}
                    fullWidth
                    // @ts-ignore
                    placeholder={t(name) + " " + (i + 1)}
                    className={"flex-1 mr-20"}
                    required
                    size={"small"}
                    value={_}
                    InputProps={{
                        endAdornment: (
                            <IconButton data-cy={`delete-item-${name}`} size={"small"} onClick={onDeleteItem(i)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        ),
                    }}
                    onChange={onChangeText(i)}
                />
            ))}
        </div>
    );
};

export default ListComponent;
