import {useLXStore} from "./useLXStore";
import {useShallow} from "zustand/react/shallow";

function useLxExcelReader() {

    const {
        originalExcelFile,
        lxExcelDatasource,
        onChangeOriginalExcelFile,
        onChangeLXExcelDatasource
    } = useLXStore(useShallow((state) => ({
        originalExcelFile: state.originalExcelFile,
        lxExcelDatasource: state.lxExcelDatasource,
        onChangeOriginalExcelFile: state.onChangeOriginalExcelFile,
        onChangeLXExcelDatasource: state.onChangeLXExcelDatasource,
    })));

    return {
        originalExcelFile,
        lxExcelDatasource,
        onChangeOriginalExcelFile,
        onChangeLXExcelDatasource,
    };

}

export default useLxExcelReader;
