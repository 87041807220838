import {Icon, IconButton, ListItemSecondaryAction, ListItemText, TextField} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import {LocalTimeStamp, MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import Moment from "react-moment";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import DatePickerWrapper from "utils/DatePickerWrapper/DatePickerWrapper";
import axios, {crmPrefix} from "../../../../../myaxios";
import {compareToNow, haveDeleteAble} from "../../marketingTools";
import * as Actions from "../store/actions";
import {generateRoundActivityTypeIcon} from "../VendorIcons/VendorIcons";

const styles = () => ({
    item: {
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        "&.general": {
            borderLeftColor: "#FFA500"
        },
        "&.low": {
            borderLeftColor: "#36B85F"
        },
        "&.high": {
            borderLeftColor: "#EB144C"
        }
    },
    triangle: {
        width: 12,
        right: 15,
        height: 12,
        background: "#DC353E",
        position: "absolute",
        borderRadius: 50,
        zIndex: 10
    }
});

class ItemView extends Component {
    state = {
        reminder: this.props.item.reminder,
        anchorEl: null
    };

    setReminder = (r) => {
        // e.stopPropagation();
        this.props.startLoading();
        const {item, search} = this.props;
        const data = {
            activityId: item.id,
            reminder: r.getTime()
        };
        axios.put(`${crmPrefix}/activitySetReminder`, data).then(() => {
            this.props.getList(search);
            this.props.finishLoading();
            this.props.showMessage({
                variant: "success",
                message: "message.Activity reminder changed!"
            });
        });
    };

    onClickItem = () => {
        const {item, getItem, turnOn, width} = this.props;
        if (width === "sm" || width === "xs") {
            this.props.history.push(`/activity/${item.id}`);
        } else {
            turnOn("activityDetail");
            getItem(item.id);
        }
    };

    render() {
        const {item, classes, small, fontSizeType, admin, listView, checked, name} = this.props;
        const {anchorEl, reminder} = this.state;

        const isChecked = checked[name] ? checked[name].includes(item.id) : false;
        return (
            <>
                <Popover
                    id="simple-menu"
                    classes={{paper: "w-160 p-12"}}
                    anchorEl={anchorEl}
                    keepMounted
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => this.setState({anchorEl: null})}
                >
                    <Translate>
                        {({translate}) => (
                            <DatePickerWrapper
                                disableToolbar
                                variant="inline"
                                label={translate("market.reminder date")}
                                autoOk
                                minDate={new Date()}
                                format={"yyyy-mm-dd"}
                                TextFieldComponent={(_prop) => (
                                    <TextField
                                        {..._prop}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder={translate("market.choose date")}
                                        label={translate("market.reminder date")}
                                        value={
                                            reminder > 0 && reminder !== "" && reminder !== undefined && reminder !== null
                                                ? new Date(reminder).toLocaleDateString()
                                                : ""
                                        }
                                    />
                                )}
                                onChange={(r) => this.setReminder(r)}
                            />
                        )}
                    </Translate>
                </Popover>
                <MyTooltip title={<Translate id={`market.${item.type.key}`} />} arrow>
                    <div className={listView ? "w-24 h-24 mr-12" : " mr-12"}>{generateRoundActivityTypeIcon(item.type.key)}</div>
                </MyTooltip>
                <ListItemText
                    classes={{root: listView ? "my-0" : ""}}
                    className={"text-base"}
                    primary={
                        <>
                            <MyTooltip title={haveDeleteAble(item) === true ? <Translate id={"market.deleted"} /> : ""}>
                                {fontSizeType === "small" ? (
                                    <div className={"capitalize "}>
                                        <button onClick={this.onClickItem} className={"text-xl nowrap max-w-full"}>
                                            <span className={haveDeleteAble(item) === true ? "line-through text-gray-500" : ""}>{item.title}</span>
                                        </button>
                                        <small className={"text-grey ml-2"}>{item.company && item.company.name}</small>
                                    </div>
                                ) : (
                                    <span className={"capitalize "}>
                                        <button onClick={this.onClickItem} className={"text-xl nowrap max-w-full"}>
                                            <span className={haveDeleteAble(item) === true ? "line-through text-gray-500" : ""}>{item.title}</span>
                                        </button>
                                        <small className={"text-grey ml-2"}>{item.company && item.company.name}</small>
                                    </span>
                                )}
                            </MyTooltip>

                            {item.createDate && (
                                <MyTooltip title={<Translate id={"market.create date"} />}>
                                    <small className={"text-grey-dark ml-2"}>
                                        (
                                        <Moment local format={"YYYY-MM-DD"}>
                                            {item.createDate}
                                        </Moment>
                                        )
                                    </small>
                                </MyTooltip>
                            )}
                        </>
                    }
                />
                {!small && (
                    <ListItemSecondaryAction>
                        <Translate>
                            {({translate}) => (
                                <MyTooltip title={translate(`market.Status ${item.status}`)}>
                                    <Chip
                                        className={classNames("text-white mr-3", item.status === "open" ? "bg-red" : "bg-green")}
                                        label={<Translate id={`market.${item.status}`} />}
                                        size={"small"}
                                        variant={"default"}
                                    />
                                </MyTooltip>
                            )}
                        </Translate>
                        {!admin && (
                            <MyTooltip
                                title={item.reminder > 0 ? <LocalTimeStamp date={item.reminder} /> : <Translate id={"market.Set reminder"} />}
                            >
                                <IconButton onClick={(e) => this.setState({anchorEl: e.currentTarget})}>
                                    <Icon
                                        className={item.reminder ? (compareToNow(item.reminder) === "old" ? "text-red" : "") : ""}
                                        color={item.reminder ? (compareToNow(item.reminder) !== "old" ? "primary" : "inherit") : "disabled"}
                                    >
                                        {item.reminder ? "notifications_active" : "notifications"}
                                    </Icon>
                                </IconButton>
                            </MyTooltip>
                        )}
                    </ListItemSecondaryAction>
                )}
            </>
        );
    }
}

ItemView.propTypes = {
    item: PropTypes.object.isRequired,
    /*
     * small for item details page
     * */
    fontSizeType: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getList: Actions.getList,
            getItem: Actions.getItem,
            selectItem: Actions.selectItem,
            toggleCheck: fuseActions.toggleCheck,
            turnOn: fuseActions.turnOn,
            startLoading: fuseActions.startLoading,
            showMessage: fuseActions.showMessage,
            finishLoading: fuseActions.finishLoading
        },
        dispatch
    );
}

function mapStateToProps({fuse}) {
    return {
        checked: fuse.checked
    };
}

export default withWidth()(withRouter(withStyles(styles)(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ItemView)))));
