import {useEffect} from "react";
import {Chip} from "@material-ui/core";
import {ItemTitle} from "@ui";
import {statusMapColor} from "../../../main/production/variable";
import classNames from "classnames";

import {Translate, withLocalize} from "react-localize-redux";
import trans from "../../../main/production/translates/translate.json";

// @ts-ignore
function PRItemView(props) {
    const {item, addTranslation} = props;
    useEffect(() => {
        if (addTranslation) {
            addTranslation(trans);
        }
    }, []);

    return (
        <>
            <ItemTitle>{item.factoryOrderCode}</ItemTitle>
            <ItemTitle>{item.clientName}</ItemTitle>
            <strong className={"text-sm "}>{item.type === "report" && item.quantity}</strong>
            <Chip
                // @ts-ignore
                className={classNames("text-white px-2 mx-3", "bg-" + statusMapColor[item.type])}
                label={<Translate id={`SCMs.${item.type}`} />}
                size={"small"}
                variant={"default"}
            />
        </>
    );
}

export default withLocalize(PRItemView);
