import {Chip, Dialog, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import * as fuseActions from "app/store/actions";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useMUIDialog} from "../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../@fuse/state/constant";
import axios, {flowPrefix} from "../../../../../myaxios";
import {NewAyncSelectWrap} from "../../../../../utils/RootComponents";
import {components} from "../../../../../utils/Select2Components";
import {searchContact} from "../../../../../utils/utilities";
import {useProcessStore} from "../../state/useProcessStore";
import {searchInstanceUsers} from "../../utils/utils";

function NewTransferForInstanceDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.transferForInstance
    });

    const dispatch = useDispatch();

    const selectedInstance = useProcessStore((state) => state.selectedInstance);

    const [originalUser, setOriginalUser] = useState(null);

    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(false);

    function changeOriginUser(value) {
        if (value) {
            setOriginalUser({
                ...user,
                value: value.value,
                label: value.label
            });
        } else {
            setOriginalUser("");
        }
    }

    function changeUser(value) {
        if (value) {
            setUser({
                ...user,
                value: value.value,
                label: value.label
            });
        } else {
            setUser("");
        }
    }

    function canSunmit() {
        return (
            user?.value === "" ||
            !user?.value ||
            originalUser?.value === "" ||
            !originalUser?.value ||
            loading
        );
    }

    function handleClose(e) {
        e.stopPropagation();
        onTurnOffDialog();
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        const params = {
            processInstanceId: selectedInstance.id,
            originalUserId: originalUser.value,
            targetUserId: user.value
        };
        setLoading(true);
        axios
        .put(`${flowPrefix}/changeInstanceUser`, params)
        .then((res) => {
            setLoading(false);
            dispatch(
                fuseActions.showMessage({
                    variant: "success",
                    message: "message.instance Transferred!"
                })
            );
            finshParams?.onFinish();
            onTurnOffDialog();
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <Dialog classes={{paper: "md:overflow-visible ", root: "md:overflow-visible"}} {...dialogProps}>
            <DialogTitle>
                <div className={"flex flex-row items-center"}>
                    <Typography className={"inline-flex"} variant={"h6"}>
                        <Translate id={"process.Instance Transfer"} />
                    </Typography>
                    {!!selectedInstance?.name && (
                        <Chip className={"ml-3"} label={selectedInstance.name} color={"secondary"} size="small" />
                    )}
                </div>
            </DialogTitle>
            <DialogContent className={"overflow-visible"}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {
                            <Translate>
                                {({translate}) => (
                                    <NewAyncSelectWrap
                                        isRequired
                                        label={translate("process.original user")}
                                        loadOptions={searchInstanceUsers(selectedInstance)}
                                        defaultOptions
                                        components={components}
                                        value={originalUser}
                                        onChange={changeOriginUser}
                                        placeholder={translate("process.Search a user")}
                                        isClearable
                                        noOptionsMessage={translate("process.No options")}
                                    />
                                )}
                            </Translate>
                        }
                        {/*<Translate>*/}
                        {/*    {({translate}) => (*/}
                        {/*        <form>*/}
                        {/*            <label style={style.label} id="aria-label" htmlFor="aria-example-input">*/}
                        {/*                {translate("process.original user")}*/}
                        {/*            </label>*/}
                        {/*            <Select*/}
                        {/*                required*/}
                        {/*                aria-labelledby="aria-label"*/}
                        {/*                inputId="aria-example-input"*/}
                        {/*                textFieldProps={{*/}
                        {/*                    margin: "normal",*/}
                        {/*                    required: true,*/}
                        {/*                    InputLabelProps: {*/}
                        {/*                        shrink: true,*/}
                        {/*                    },*/}
                        {/*                }}*/}
                        {/*                margin={"normal"}*/}
                        {/*                loadOptions={searchInstanceUsers(selectedInstance)}*/}
                        {/*                defaultOptions*/}
                        {/*                components={components}*/}
                        {/*                value={originalUser}*/}
                        {/*                onChange={changeOriginUser}*/}
                        {/*                placeholder={translate("process.Search a user")}*/}
                        {/*                isClearable*/}
                        {/*            />*/}
                        {/*        </form>*/}
                        {/*    )}*/}
                        {/*</Translate>*/}
                    </Grid>

                    <Grid item xs={6}>
                        <Translate>
                            {({translate}) => (
                                <NewAyncSelectWrap
                                    isRequired
                                    label={translate("process.target user")}
                                    loadOptions={searchContact}
                                    defaultOptions
                                    components={components}
                                    value={user}
                                    onChange={changeUser}
                                    placeholder={translate("process.Search a user")}
                                    noOptionsMessage={translate("process.No options")}
                                    isClearable
                                />

                                // <Select
                                //     required
                                //     textFieldProps={{
                                //         margin: "normal",
                                //         required: true,
                                //         label: translate("process.target user"),
                                //         InputLabelProps: {
                                //             shrink: true,
                                //         },
                                //     }}
                                //     margin={"normal"}
                                //     loadOptions={searchContact}
                                //     defaultOptions
                                //     components={components}
                                //     value={user}
                                //     onChange={changeUser}
                                //     placeholder={translate("process.Search a user")}
                                //     isClearable
                                // />
                            )}
                        </Translate>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={handleClose}>
                    {<Translate id={"_.cancel"} />}
                </Button>
                <Button
                    variant={"contained"}
                    disabled={canSunmit()}
                    onClick={handleSubmit}
                    color="secondary"

                >
                    {<Translate id={"_.sure"} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewTransferForInstanceDialog;
