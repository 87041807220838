import { TOGGLE_CHECK, ADD_CHECKED, CLEAR_ALL, REMOVE_CHECKED, CLEAR_CHECKED, CHECK_ARRAY } from "../../actions";

const toggleCheck = (state, {id, name}) => {
    let temp = state[name];
    if (temp)
        if (temp.includes(id)) {
            temp = temp.filter((_) => _ !== id);
        } else {
            temp.push(id);
        }
    else {
        temp = [id];
    }
    return {
        ...state,
        [name]: temp,
    };
};
const addChecked = (state, {id, name}) => {
    return {
        ...state,
        [name]: [...state[name], id],
    };
};

const checkArray = (state, {array, name}) => {
    return {
        ...state,
        [name]: array,
    };
};
const removeChecked = (state) => {
    return {
        ...state,
    };
};
const clearChecked = (state, {name}) => {
    const t = {...state};
    delete t[name];
    return t;
};

function clearAll() {
    return {};
}

const checked = function (state = {}, action) {
    switch (action.type) {
        case TOGGLE_CHECK:
            return toggleCheck(state, action);
        case ADD_CHECKED:
            return addChecked(state, action);
        case CLEAR_ALL:
            return clearAll(state, action);
        case REMOVE_CHECKED:
            return removeChecked(state, action);
        case CLEAR_CHECKED:
            return clearChecked(state, action);
        case CHECK_ARRAY:
            return checkArray(state, action);
        default: {
            return state;
        }
    }
};

export default checked;
