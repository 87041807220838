import Chip from "@material-ui/core/Chip";
import {MyAvatar, MyTooltip} from "@ui";
import {Translate} from "react-localize-redux";
import NameTooltipComponent from "utils/NameTooltipComponent";

const Owner = ({user}) => {
    return (
        <Translate>
            {({translate}) => <MyTooltip title={translate("market.Owner")}>
                <Chip className="mt-16 ml-16" avatar={<MyAvatar user={user} />} size={"small"} label={
                    // <NameLabel firstName={user.firstName} lastName={user.lastName}/>
                    <NameTooltipComponent user={user} strongTagFLname />
                } />
            </MyTooltip>}
        </Translate>
    );
};

export default Owner;
