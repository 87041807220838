import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    Paper,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/styles";
import {MyTooltip} from "@ui";
import {useMemoizedFn} from "ahooks";
import {useContext, useMemo, useState} from "react";
import {Translate} from "react-localize-redux";
import Moment from "react-moment";
import {useSelector} from "react-redux";
import {FModelType, useFuseModel} from "../../../../../../@fuse/components/FuseModel";
import {useMUIDialog} from "../../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../../@fuse/state/constant";
import {MyPriceLabel} from "../../../../../../utils/RootComponents";
import {useIncomeSureUtils} from "../../../IncomeSureManager/state";
import {IncomeSureContext} from "../../../IncomeSureManager/state/constant";
import {useMyIncomeStoreUtils} from "../../../MyIncomes/state";
import {useIncomeCommonUtils} from "../../../utils/state";
import useDeleteIncomeOrder from "../../hooks/useDeleteIncomeOrder";
import useSetIncomeTag from "../../hooks/useSetIncomeTag";
import OrderTable from "../OrderTable";

DetailInfoView.propTypes = {};

const useStyles = makeStyles((theme) => ({
    root: {},
    accordion: {
        width: "100%"
    },
    accordionHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

function DetailInfoView(props) {
    const classes = useStyles();
    const context = useContext(IncomeSureContext);
    const {showModel, hideModel} = useFuseModel();
    const deleteOrderActions = useDeleteIncomeOrder();
    const putIncomeTagActions = useSetIncomeTag();
    const profile = useSelector(({user}) => user.profile);
    const [stateLoading, setStateLoading] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [tempNotes, setTempNotes] = useState("");
    const {onChangeCurrentIncome} = useIncomeCommonUtils();
    const {isIncomeSure} = useIncomeSureUtils();
    const {isMyIncome} = useMyIncomeStoreUtils();
    const {onTurnOnDialog} = useMUIDialog({
        registry: GDialogRegistries.orderDistribute,
        maxWidth: "lg",
        fullWidth: true
    });

    const handleChangeTag = useMemoizedFn((e) => {
        setTempNotes(e.target.value);
    });

    const incomeDtails = useMemo(() => {
        return context?.data;
    }, [context?.data]);

    const canDistributeIncomeToOrder = useMemo(() => {
        // if (incomeDtails?.user) {
        //     return profile?.id !== incomeDtails?.user?.id;
        //
        // }
        return incomeDtails?.status === "FINISH" || incomeDtails?.type !== "INCOME";
    }, [incomeDtails?.user, incomeDtails?.status, incomeDtails?.type, profile?.id]);

    const handleSureToDelete = useMemoizedFn((e) => {
        e.stopPropagation();
        const params = {
            id: currentOrder?.id
        };
        deleteOrderActions.run(params).then((response) => {
            if (response?.status === 200) {
                context.onRefresh();
                hideModel();
            }
        });
    });

    const actions = (
        <>
            <Button
                variant={"contained"}
                onClick={(e) => {
                    e.stopPropagation();
                    hideModel();
                }}
            >
                <Translate id={"_.cancel"} />
            </Button>
            <Button variant={"contained"} color={"secondary"} onClick={handleSureToDelete}>
                <Translate id={"_.delete"} />
            </Button>
        </>
    );

    const handleDeleteOrder = useMemoizedFn((data) => {
        setCurrentOrder(data);
        showModel({
            type: FModelType.warn,
            actions: actions,
            content: <Translate id={"_.Whether to confirm that you want to delete the current option"} />
        });
    });

    const handleIncomeTagSave = useMemoizedFn((e) => {
        e.stopPropagation();
        const params = {
            incomeIds: [context?.data?.id],
            tag: tempNotes
        };
        putIncomeTagActions.run(params).then((response) => {
            if (response?.status === 200) {
                context.onRefresh();
            }
        });
    });

    const handleAddOrder = useMemoizedFn((e) => {
        e.stopPropagation();
        console.log("handleAddOrder > income", incomeDtails);
        onChangeCurrentIncome(incomeDtails);
        onTurnOnDialog({
            onFinish: handleRefresh
        });
    });

    const handleRefresh = useMemoizedFn(() => {
        context?.onRefresh();
    });

    return (
        <div className={"p-16"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="pb-16 flex items-center">
                        <Icon className="mr-16" color="action">
                            label_important
                        </Icon>
                        <Typography className="h2" color="textSecondary">
                            {<Translate id={"income.revenue details"} />}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3}>
                        <List disablePadding>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.amount"} />} />
                                <ListItemSecondaryAction>
                                    <MyPriceLabel price={incomeDtails?.amount} />
                                    {/*{numeral(incomeDtails?.amount).format("0,0.00")}*/}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.availableAmount"} />} />
                                <ListItemSecondaryAction>
                                    <MyPriceLabel price={incomeDtails?.availableAmount} />
                                    {/*{numeral(incomeDtails?.availableAmount).format("0,0.00")}*/}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.collectAmount"} />} />
                                <ListItemSecondaryAction>
                                    <MyPriceLabel price={incomeDtails?.collectAmount} emptyPlaceholder={"-"} />
                                    {/*{numeral(incomeDtails?.collectAmount).format("0,0.00")}*/}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.collectPercent"} />} />
                                <ListItemSecondaryAction>{`${incomeDtails?.collectPercent} (%)`}</ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.refundAmount"} />} />
                                <ListItemSecondaryAction>
                                    <MyPriceLabel price={incomeDtails?.refundAmount} emptyPlaceholder={"-"} />
                                    {/*{numeral(incomeDtails?.refundAmount).format("0,0.00")}*/}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.refundNote"} />} />
                                <ListItemSecondaryAction>
                                    <span className="text-wrap w-11/12">{incomeDtails?.refundNote ? incomeDtails?.refundNote : "-"}</span>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.revenue status"} />} />
                                <ListItemSecondaryAction>
                                    {incomeDtails?.status ? (
                                        <Chip
                                            size={"small"}
                                            label={<Translate id={"income." + incomeDtails?.status} />}
                                            className={`ml-2 bg-${incomeDtails?.status}`}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.revenue type"} />} />
                                <ListItemSecondaryAction>
                                    {incomeDtails?.type ? (
                                        <MyTooltip title={<Translate id={"income.revenue type"} />}>
                                            {<Chip label={<Translate id={`income.${incomeDtails?.type}`} />} />}
                                        </MyTooltip>
                                    ) : (
                                        "-"
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3}>
                        <List disablePadding>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.transferName"} />} />
                                <ListItemSecondaryAction>
                                    <span className="text-wrap w-11/12">{incomeDtails?.transferName}</span>
                                </ListItemSecondaryAction>
                            </ListItem>

                            <ListItem>
                                <ListItemText primary={<Translate id={"income.transferAccount"} />} />
                                <ListItemSecondaryAction>
                                    <span className="text-wrap w-11/12">{incomeDtails?.transferAccNo}</span>
                                </ListItemSecondaryAction>
                            </ListItem>

                            <ListItem>
                                <ListItemText primary={<Translate id={"income.Our account"} />} />
                                <ListItemSecondaryAction>
                                    <span className="text-wrap w-11/12">{incomeDtails?.account ? incomeDtails?.account : "-"}</span>
                                </ListItemSecondaryAction>
                            </ListItem>

                            <ListItem>
                                <ListItemText primary={<Translate id={"income.bankType"} />} />
                                <ListItemSecondaryAction>{incomeDtails?.bankType ? incomeDtails?.bankType : "-"}</ListItemSecondaryAction>
                            </ListItem>

                            <ListItem>
                                <ListItemText primary={<Translate id={"income.transferNote"} />} />
                                <ListItemSecondaryAction>
                                    <span className="text-wrap w-11/12">
                                        {
                                            <strong>
                                                {/* 这个 transferType 不能翻译， 因为这个数据是 来自用户导入的数据， 有可能是中文也有可能是英文*/}
                                                {incomeDtails?.transferNote ? incomeDtails?.transferNote : "-"}
                                            </strong>
                                        }
                                    </span>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.transferDate"} />} />
                                <ListItemSecondaryAction>
                                    <Moment format={"YYYY-MM-DD"}>{incomeDtails?.transferDate}</Moment>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.Datasource"} />} />
                                <MyTooltip
                                    title={
                                        <>
                                            <small>{<Translate id={"income.transferType"} />}</small>
                                        </>
                                    }
                                >
                                    <ListItemSecondaryAction>
                                        {incomeDtails?.dataType ? <Translate id={`income.${incomeDtails?.dataType}`} /> : "-"}
                                    </ListItemSecondaryAction>
                                </MyTooltip>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Translate id={"income.transferType"} />} />
                                <ListItemSecondaryAction>
                                    {incomeDtails?.transferType ? (
                                        <MyTooltip title={<Translate id={"income.transferType"} />}>
                                            {<Chip label={incomeDtails?.transferType} />}
                                        </MyTooltip>
                                    ) : (
                                        "-"
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className={classes.heading}>{<Translate id={"income.revenue note"} />}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{incomeDtails?.note}</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className={classes.heading}>{<Translate id={"income.revenue tag"} />}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={stateLoading ? "loading" : ""}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Translate>
                                        {({translate}) => (
                                            <TextField
                                                placeholder={translate("income.input income tag here")}
                                                multiline
                                                fullWidth
                                                disabled={isMyIncome}
                                                maxRows={10000}
                                                value={tempNotes === "" ? incomeDtails?.tag : tempNotes}
                                                onChange={handleChangeTag}
                                                variant="outlined"
                                            />
                                        )}
                                    </Translate>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="flex flex-row-reverse">
                                        <Button
                                            disabled={tempNotes.trim() === "" || isMyIncome}
                                            size="small"
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleIncomeTagSave}
                                        >
                                            {<Translate id={"income.save"} />}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <div className="flex w-full flex-row justify-between pt-10">
                        <div className="pb-16 flex items-center">
                            <Icon className="mr-16" color="action">
                                list
                            </Icon>
                            <Typography className="h2" color="textSecondary">
                                {<Translate id={"income.Assigned order"} />}
                            </Typography>
                        </div>

                        <MyTooltip
                            title={
                                canDistributeIncomeToOrder === true ? (
                                    <Translate id={"income.Can only allocate their own or unfinished income"} />
                                ) : (
                                    <Translate id={"income.Distribution of income"} />
                                )
                            }
                        >
                            <span>
                                <IconButton disabled={canDistributeIncomeToOrder} onClick={handleAddOrder}>
                                    <Icon>add</Icon>
                                </IconButton>
                            </span>
                        </MyTooltip>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <OrderTable datas={context?.data ? context?.data?.orderList : []} handleDeleteOrder={handleDeleteOrder} />
                </Grid>
            </Grid>
        </div>
    );
}

export default DetailInfoView;
