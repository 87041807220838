import {lazy} from "react";

export const DatagridConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/datagrids/:id/",
            component: lazy(() => import("./DatagridDetails")),
        },
        {
            path: "/datagrids",
            component: lazy(() => import("./MyDatagrids")),
        },
    ],
};
