import {firmPrefix} from "@api";
import {Button, IconButton, InputAdornment} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import TextField from "@material-ui/core/TextField";
import {EmptyView, MyAvatar} from "@ui";
import {useDebounceEffect} from "ahooks";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ViewportList} from "react-viewport-list";
import useSWR from "swr";
import type {dataType, DepartmentStaffType, SearchType, StaffType} from "../../../types";
import {userViewTitle} from "../../../utils/utilities";
import {useDep} from "./useDep";
import {useTransferList} from "./useTransferList";

const NotSelectedUsers = () => {
    const {t} = useTranslation();
    // region states

    const viewportRef = useRef(null);
    const [allUsersSearch, setAllUsersSearch] = useState<string>();
    // const [totalElements, setTotalElements] = useState<number>();
    // const [staff, setStaff] = useState<StaffType[] | DepartmentStaffType[]>();
    const {addAll, add, isSelected} = useTransferList();
    const {selectedDep} = useDep();

    const [params, setParams] = useState<SearchType>({});

    // endregion

    const {data, isLoading, error} = useSWR<dataType<StaffType | DepartmentStaffType>>([
        params.departmentId ? `${firmPrefix}/departmentStaffs` : `${firmPrefix}/firm/staffs`,
        params,
    ]);

    const staff = data ? data.content : [];

    // const getUsers = (params?: SearchType) => {
    //     setLoading(true);
    //     axios
    //         .get<dataType<StaffType>>(`${firmPrefix}/firm/staffs`, {params})
    //         .then(({data}) => {
    //             setStaff(data.content);
    //         })
    //         .finally(() => setLoading(false));
    //     // axios.get(`${firmPrefix}/departments`, {params}).then((res) => {
    //     //     setDepartments(res.data);
    //     // });
    // };

    // const getAllStaff = (params?: SearchType) => {
    //     setLoading(true);
    //     axios.get<dataType<StaffType>>(`${firmPrefix}/firm/staffs?size=2000`, {params}).then(({data}) => {
    //         // setStaff(data.content);
    //         setTotalElements(data.totalElements);
    //         setLoading(false);
    //     });
    // };

    // const getDepStaff = () => {
    //     if (selectedDep) {
    //         setLoading(true);
    //         const params = {
    //             departmentId: selectedDep.id.replace("root", ""),
    //             size: 1000,
    //             sub: selectedDep.name !== "root",
    //         };
    //         axios.get<dataType<DepartmentStaffType>>(`${firmPrefix}/departmentStaffs`, {params}).then(({data}) => {
    //             // setStaff(data.content);
    //             setTotalElements(data.totalElements);
    //             // setSelectedDep(selectedDep);
    //             setLoading(false);
    //         });
    //     } else {
    //         getAllStaff();
    //     }
    // }

    const onChangeAllUserSearch = (e) => {
        // setSelectedDep(undefined);
        setAllUsersSearch(e.target.value);
    };

    const clearSearchAll = () => {
        setAllUsersSearch(undefined);
        // setSelectedDep(undefined);
        let p = {};
        if (selectedDep) {
            if (selectedDep.id === "withoutDepartment") {
                p = {
                    list: "withoutDepartment",
                    size: 1000,
                };
            } else
                p = {
                    departmentId: selectedDep.id.replace("root", ""),
                    size: 1000,
                    sub: selectedDep.name !== "root",
                };
        }
        setParams(p);
        // getAllStaff();
    };

    const addAllStaff = () => {
        // @ts-ignore
        if (staff) addAll(staff.map((u) => u.user));
    };

    useDebounceEffect(
        () => {
            if (allUsersSearch !== undefined) {
                let p = {};
                if (selectedDep) {
                    if (selectedDep.id === "withoutDepartment") {
                        p = {
                            list: "withoutDepartment",
                            size: 1000,
                        };
                    } else
                        p = {
                            departmentId: selectedDep.id.replace("root", ""),
                            size: 1000,
                            sub: selectedDep.name !== "root",
                        };
                }
                if (allUsersSearch.trim() === "") setParams({});
                else setParams({...p, keyword: allUsersSearch});
            }
            // axios.get<dataType<StaffType>>(`${firmPrefix}/firm/staffs?size=10&keyword=${allUsersSearch}`).then(({data}) => {
            //     setStaff(data.content);
            // });
        },
        [allUsersSearch],
        {
            wait: 800,
        }
    );

    // const getUsersWithoutDepartment = () => {
    //     getAllStaff({list: "withoutDepartment"});
    //     setSelectedDepartment({name: "withoutDepartment", id: "withoutDepartment", active: true});
    // };

    useEffect(() => {
        if (selectedDep)
            if (selectedDep.id === "withoutDepartment") {
                setParams({
                    list: "withoutDepartment",
                    size: 1000,
                });
            } else
                setParams({
                    departmentId: selectedDep.id.replace("root", ""),
                    size: 1000,
                    sub: selectedDep.name !== "root",
                });
        else {
            setParams({
                size: 1000,
            });
        }
    }, [selectedDep]);

    return (
        <Grid
            item
            md={4}
            style={{maxHeight: "calc(100vh -  200px)"}}
            data-cy={"non-selected-users"}
            className={" overflow-y-auto border-l-1  border-r-1"}
            xs={12}>
            <List
                subheader={
                    <ListSubheader className={" justify-between flex bg-white"}>
                        {selectedDep ? (
                            selectedDep.name === "root" ? (
                                <>
                                    {t("userSelector.root")} {selectedDep.parentDepartment?.name}
                                </>
                            ) : selectedDep.id === "withoutDepartment" ? (
                                t("userSelector.noDepartment")
                            ) : (
                                selectedDep.name
                            )
                        ) : (
                            t("userSelector.allUsers")
                        )}{" "}
                        ({staff?.length})
                        <div>
                            <Button
                                disabled={!staff || staff.length === 0}
                                variant={"contained"}
                                color={"secondary"}
                                size={"small"}
                                onClick={addAllStaff}>
                                {t("userSelector.addAll")}
                            </Button>
                        </div>
                    </ListSubheader>
                }
                dense>
                <ListItem>
                    <TextField
                        placeholder={t("Search")}
                        InputProps={{
                            endAdornment: allUsersSearch && (
                                <InputAdornment position="end">
                                    <IconButton onClick={clearSearchAll}>
                                        <Icon fontSize={"small"}>close</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon fontSize={"small"}>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        className={"md:mt-0 mb-12"}
                        value={allUsersSearch || ""}
                        onChange={onChangeAllUserSearch}
                    />
                </ListItem>

                <div ref={viewportRef} className="overflow-y-scroll">
                    <ViewportList viewportRef={viewportRef} items={staff} overscan={30}>
                        {({user}, index) =>
                            !isSelected(user) && (
                                <ListItem disableTouchRipple disableRipple data-cy={"user-item"} button onClick={() => add(user)} key={index}>
                                    <ListItemAvatar>
                                        <MyAvatar user={user} />
                                    </ListItemAvatar>
                                    <ListItemText primary={userViewTitle(user)} />
                                    <Icon className={"text-green"}>arrow_forward_ios</Icon>
                                </ListItem>
                            )
                        }
                    </ViewportList>

                    {(!(staff && staff.length > 0 && !isLoading) || error) && <EmptyView loading={isLoading} error={error} />}
                </div>
            </List>
        </Grid>
    );
};

export default NotSelectedUsers;
