import {Tooltip, Typography, Zoom} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import {MyAvatar} from "@ui";
import PropTypes from "prop-types";
import {nameText} from "./NameTooltipComponent";

NameTooltip.propTypes = {
    user: PropTypes.object,
    emptyText: PropTypes.object,
    placement: PropTypes.string
};

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        minWidth: 120
    }
}))(Tooltip);

function NameTooltip(props) {
    const {user, emptyText, placement} = props;

    const titleContent = () => (
        <div className="flex flex-row items-center justify-center my-6 mx-3">
            <MyAvatar src={user.avatar} className={"flex"} />
            <div className="flex flex-col ml-3">
                <Typography className="flex">
                    <strong>{nameText(user)}</strong>
                </Typography>
                <Typography className="flex">
                    <small className="text-gray-500">@{user.username}</small>
                </Typography>
            </div>
        </div>
    );

    const emptyContent = () => (
        <div className="flex flex-row my-6 mx-3">
            {/*<Skeleton animation="wave" variant="circle" width={40} height={40} />*/}
            <div className="flex flex-col ml-2">
                <Skeleton animation="wave" height={10} width={120} style={{marginBottom: 6}} />
                <Skeleton animation="wave" height={10} width={100} />
            </div>
        </div>
    );

    return (
        <>
            {!user && emptyText ? (
                <Tooltip placement={placement} title={emptyText} TransitionComponent={Zoom}>
                    {props.children}
                </Tooltip>
            ) : (
                <LightTooltip title={!!user ? titleContent() : emptyContent()} TransitionComponent={Zoom} arrow>
                    {props.children}
                </LightTooltip>
            )}
        </>
    );
}

export default NameTooltip;
