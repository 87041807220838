import { GET_FAVORITE, FAVORITE, DELETE_FAVORITE } from "../../actions";

const initialState = {
    //    comment     : [],
    //    stage       : [],
    //    brand       : [],
    //    action      : [],
    //    product     : [],
    //
    //    link        : [],
    //    customer    : [],
    //    project     : [],
    //    dialog      : [],
    //    template    : [],
    //    task        : [],
    //    issue       : [],
    //    company     : [],
    //    activity    : [],
    //    deal        : [],
    //    contact     : [],
    //    wp          : [],
    //    SCM          : [],
    //    meeting     : [],
    //    osTemplate: [],
};

const favorites = function (state = initialState, action) {
    switch (action.type) {
        case GET_FAVORITE: {
            return {
                ...state,
                [action.fType]: action.data,
            };
        }

        case FAVORITE: {
            let newState = {};
            if (action.fType in newState) newState[action.fType] = [...state[action.fType], {id: action.id, sourceId: action.sourceId}];
            else newState[action.fType] = [{id: action.id, sourceId: action.sourceId}];
            return {...state, ...newState};
        }

        case DELETE_FAVORITE: {
            let newState = {};
            if (state[action.fType] === undefined) return state;
            newState[action.fType] = state[action.fType].filter((_s) => _s.id !== action.id);
            return {...state, ...newState};
        }

        default: {
            return state;
        }
    }
};

export default favorites;
