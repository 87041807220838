import {lazy} from "react";

export const FormBuilderConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/form-builder/new",
            component: lazy(() => import("./FormBuilder")),
        },
        {
            path: "/form-builder/:editId",
            component: lazy(() => import("./Single")),
        },
        {
            path: "/form-builder",
            component: lazy(() => import("./Forms/Page")),
        },
    ],
};
