import {GET_PERMISSIONS} from "@fuseActions";

const initialState = {};

const error = function(state = initialState, action) {
    switch (action.type) {
        case GET_PERMISSIONS: {
            return {
                ...state,
                [action.module]: action.data
            };
        }
        default: {
            return state;
        }
    }
};

export default error;

// 0(pin):"GET/srx-fms/v1/redPacket/{id}"
// 1(pin):"PUT/srx-fms/v1/masterFirm"
// 2(pin):"DELETE/srx-fms/v1/department/{id}"
// 3(pin):"GET/srx-fms/v1/myRedPacketBalance"
// 4(pin):"GET/srx-fms/v1/firm/{id}/roles"
// 5(pin):"POST/srx-fms/v1/departmentStaffs"
// 6(pin):"PUT/srx-fms/v1/relatives"
// 7(pin):"GET/srx-fms/v1/redPacketStats"
// 8(pin):"PUT/srx-fms/v1/department"
// 9(pin):"PUT/srx-fms/v1/redPacketRank"
// 10(pin):"PUT/srx-fms/v1/department/{id}/roles"
// 11(pin):"GET/srx-fms/v1/relativesList"
// 12(pin):"GET/srx-fms/v1/firmInvitation"
// 13(pin):"PUT/srx-fms/v1/updateStaffName"
// 14(pin):"GET/srx-fms/v1/firm/roles"
// 15(pin):"DELETE/srx-fms/v1/departmentStaff/{id}"
// 16(pin):"GET/srx-fms/v1/departmentStaff/{id}"
// 17(pin):"GET/srx-fms/v1/department/{id}"
// 18(pin):"PUT/srx-fms/v1/staff/{id}/roles"
// 19(pin):"POST/srx-fms/v1/redPacketChat"
// 20(pin):"GET/srx-fms/v1/department/{id}/roles"
// 21(pin):"GET/srx-fms/v1/openPacket"
// 22(pin):"GET/srx-fms/v1/departments"
// 23(pin):"GET/srx-fms/v1/redPacketRanks"
// 24(pin):"GET/srx-fms/v1/myRelativesMembers"
// 25(pin):"GET/srx-fms/v1/firms"
// 26(pin):"POST/srx-fms/v1/relatives"
// 27(pin):"DELETE/srx-fms/v1/redPacketRank/{id}"
// 28(pin):"PUT/srx-fms/v1/setDefaultRank"
// 29(pin):"DELETE/srx-fms/v1/staff/{id}"
// 30(pin):"PUT/srx-fms/v1/staff/{id}/active"
// 31(pin):"GET/srx-fms/v1/requests"
// 32(pin):"POST/srx-fms/v1/addStaff"
// 33(pin):"GET/srx-fms/v1/firm/{id}/staffs"
// 34(pin):"GET/srx-fms/v1/userEvents"
// 35(pin):"POST/srx-fms/v1/department"
// 36(pin):"GET/srx-fms/v1/staff/{id}"
// 37(pin):"GET/srx-fms/v1/redPackets"
// 38(pin):"GET/srx-fms/v1/relatives/{id}"
// 39(pin):"GET/srx-fms/v1/firmInfo"
// 40(pin):"GET/srx-fms/v1/myFirms"
// 41(pin):"GET/srx-fms/v1/firm/{id}"
// 42(pin):"GET/srx-fms/v1/myRedPacketBalanceStat"
// 43(pin):"PUT/srx-fms/v1/departmentStaff"
// 44(pin):"GET/srx-fms/v1/firm/staffs"
// 45(pin):"POST/srx-fms/v1/firm"
// 46(pin):"POST/srx-fms/v1/redPacketRank"
// 47(pin):"GET/srx-fms/v1/initRanks"
// 48(pin):"PUT/srx-fms/v1/request/{id}"
// 49(pin):"POST/srx-fms/v1/request"
// 50(pin):"PUT/srx-fms/v1/updateStaffEntryTime"
// 51(pin):"PUT/srx-fms/v1/firm/toggleStatus"
// 52(pin):"PUT/srx-fms/v1/firm"
// 53(pin):"GET/srx-fms/v1/masterFirm"
// 54(pin):"PUT/srx-fms/v1/firm/{id}/roles"
// 55(pin):"GET/srx-fms/v1/staff/{id}/roles"
// 56(pin):"GET/srx-fms/v1/departmentStaffs"
// 57(pin):"POST/srx-fms/v1/redPacket"
// 58(pin):"GET/srx-fms/v1/redPacketRank/{id}"
