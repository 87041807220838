import {FusePageSimple} from "@fuse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {Component} from "react";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import Empty from "utils/Empty";
import ActivityItem from "../../activities/list/ItemView";
import ContactItem from "../../contacts/list/ItemView";
import DealItem from "../../deals/list/ItemView";
import jsons from "../../translates/translate.json";
import Description from "../../utils/Description";
import GridList from "../../utils/GridList";
import Notes from "../../utils/Notes";
import ChangeCompanyUserDialog from "../dialogs/ChangeCompanyUserDialog";
import DeleteCompanyDialog from "../dialogs/DeleteCompanyDialog";
import { clearItem, selectItem, getItem } from "../store/actions";
import Header from "./Header";
import RightSidebar from "./RightSidebar";

class SinglePage extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(jsons);
    }

    componentDidMount = () => {
        const {id} = this.props.match.params;
        if (id) this.props.getItem(id);
    };

    componentDidUpdate = (prevProps) => {
        const {match, item, getItem} = this.props;
        if (item && prevProps.item && match.params.id) {
            if (match.params.id !== prevProps.match.params.id) {
                const {id} = match.params;
                getItem(id);
            }
        }
    };

    componentWillUnmount = () => {
        this.props.clearItem();
        this.props.selectItem(null);
    };

    render() {
        const {item, selectItem, getItem, dialogs, profile} = this.props;
        const subProps = {
            canEdit: item && profile.id === item.user.id,
            sourceType: "company",
            sourceItem: item,
            selectItem,
            refresh: () => getItem(item.id),
        };
        return !item ? (
            <Empty />
        ) : (
            <FusePageSimple
                classes={{
                    header: "h-128",
                }}
                header={<Header {...subProps} />}
                content={
                    <div className={"w-full"}>
                        {dialogs["deletecompany"] && <DeleteCompanyDialog />}
                        {dialogs["companyChangeUser"] && <ChangeCompanyUserDialog />}
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <Description {...subProps} />
                                <Notes {...subProps} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <RightSidebar {...subProps} />
                            </Grid>
                        </Grid>
                        <Divider className={"mt-20"} />
                        <div className={"p-20"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <GridList {...subProps} link={"companyContacts"} type={"contact"} GridItem={ContactItem} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <GridList {...subProps} link={"activities"} type={"activity"} GridItem={ActivityItem} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <GridList {...subProps} link={"deals"} type={"deal"} GridItem={DealItem} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearItem: clearItem,
            selectItem: selectItem,
            getItem: getItem,
        },
        dispatch
    );
}

function mapStateToProps({fuse, user, marketing}) {
    return {
        item: marketing.companies.item,
        dialogs: fuse.dialogs,
        profile: user.profile,
    };
}

export default withRouter(withLocalize(connect(mapStateToProps, mapDispatchToProps)(SinglePage)));
