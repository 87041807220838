/**
 * todo add network error
 */
import axios, {filePrefix} from "@api";
import {showMessage} from "@fuseActions";
import {Icon, IconButton, LinearProgress, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {EmptyView, MyTooltip} from "@ui";
import clsx from "clsx";
import {useCallback, useMemo, useState} from "react";
import type {ErrorCode} from "react-dropzone";
import {useDropzone} from "react-dropzone";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import type {AttachmentType} from "../../types";
import FileThumb from "../ui/FileThumb";

const FileErrorMessages = ({file, errors}: {file: File; errors: {message: string; code: ErrorCode | string}[]}) => (
    <>
        <ListItem className={"px-0"}>
            <ListItemText
                classes={{primary: "text-gray-500"}}
                primary={file.name}
                secondary={
                    <Typography color={"error"}>
                        {errors.map((error) => (
                            <>
                                {error.code} - {<Translate id={`_.${error.message}`} />} <br />
                            </>
                        ))}
                    </Typography>
                }
            />
        </ListItem>
    </>
);

const baseStyle = {
    flex: 1,
    // display: 'flex',
    // flexDirection: 'column',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#0bcc6e",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

type Props = {
    value: AttachmentType[];
    disabled?: boolean;
    onSave: (value: AttachmentType[]) => Promise<void>;
};

function FileUploader({value, disabled, onSave}: Props) {
    const [progress, setProgress] = useState(0);
    // const [files, setFiles] = useState<AttachmentType[]>(value || []);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (value) {
    //         setFiles(value);
    //     }
    // }, [value]);

    const onDrop = useCallback(
        (accepted, rejected) => {
            if (rejected.length) {
                dispatch(
                    showMessage({
                        variant: "error",
                        message: "message.File rejected. Please check the requirement",
                    })
                );
            } else {
                const data = new FormData();
                for (let i = 0; i < accepted.length; i++) {
                    const file = accepted[i];
                    data.append("file", file, file.name);
                }
                const config = {
                    headers: {"content-type": "multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p"},
                    onUploadProgress: function (progressEvent) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted);
                    },
                };
                // setLoading(true);
                axios.post<AttachmentType[]>(`${filePrefix}/file`, data, config).then((res) => {
                    setProgress(0);
                    console.log(value);
                    onSave([...value, ...res.data]);
                    // onAddFile(res.data);
                });
            }
        },
        [value]
    );

    const {getRootProps, getInputProps, isFocused, fileRejections, isDragAccept, isDragReject, isDragActive} = useDropzone({
        onDrop: onDrop,
        disabled: disabled,
        // accept: {'image/*': []}
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const onDeleteFile = (id) => (e) => {
        e.stopPropagation();
        const d = [...value];
        const index = d.findIndex((file) => file.id === id);
        d.splice(index, 1);
        onSave(d);
    };

    return (
        <div {...getRootProps({style})} className={clsx("pt-0 px-16 pb-16")}>
            <input {...getInputProps()} />

            <div className="flex flex-wrap">
                {/* region files */}

                {value.map((file) => (
                    <div key={file.id} className={"relative mr-16"}>
                        <IconButton
                            onClick={onDeleteFile(file.id)}
                            className={"top-8 z-99 absolute -left-12 bg-red hover:bg-red-dark text-white"}
                            size={"small"}>
                            <Icon fontSize={"small"}>close</Icon>
                        </IconButton>
                        <MyTooltip title={file.originalName}>
                            <div className="mt-16">
                                <FileThumb size={"72"} file={file} />
                            </div>
                        </MyTooltip>
                    </div>
                ))}

                {/* endregion */}

                {/* region actionButtons */}

                {!disabled && (
                    <div
                        className={
                            "w-76 h-76 rounded-8 text-32 mt-16 cursor-pointer hover:text-gray-800 hover:bg-gray-200 bg-gray-100 text-gray-500 flex justify-center items-center"
                        }>
                        {isDragActive ? (
                            isDragReject ? (
                                <Icon fontSize={"inherit"} color={"error"}>
                                    cancel
                                </Icon>
                            ) : (
                                <Icon fontSize={"inherit"}>upload</Icon>
                            )
                        ) : (
                            <Icon fontSize={"inherit"} id={"uploadButton"}>
                                upload_file
                            </Icon>
                        )}
                    </div>
                )}
                {/* endregion */}

                {disabled && value?.length === 0 && <EmptyView noIcon />}
            </div>

            {progress > 0 && <LinearProgress variant="determinate" value={progress} className="rounded mt-16 max-w-full" color="secondary" />}

            {fileRejections.length > 0 && (
                <List dense className={"pb-0"}>
                    {fileRejections.map((p) => (
                        <FileErrorMessages {...p} />
                    ))}
                </List>
            )}
        </div>
    );
}

export default FileUploader;
