import * as fuseActions from '@fuseActions';
import Switch from '@material-ui/core/Switch';
import {LocalTimeStamp} from '@ui';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {dateToColor} from '../../utils/utilities';

type Props = {
    date?: Date | number;
    size?: number;
    title?: string;
    disabled?: boolean;
    className?: string;
    switchable?: boolean;
    ago?: boolean;
};

export const ColoredDate = ({date, size = 16, title, disabled, className, switchable, ago}: Props) => {
    const agoDueDate = useSelector(({fuse}) => fuse.dialogs["agoDueDate"]);

    return (
        <span
            className={clsx(
                className,
                `px-3 py-2 rounded-8 text-${size} font-bold`,
                disabled
                    ? "bg-grey-lightest text-grey-dark"
                    : `bg-${dateToColor(date)}-lightest text-${dateToColor(date)}`
            )}
        >
            {title}{" "}
            <LocalTimeStamp
                dateStyleAgo={(switchable && agoDueDate && !disabled) || (ago && !disabled)}
                datetime={date}
            />
        </span>
    );
};

export const SwitchDueDate = () => {
    const ago = useSelector(({fuse}) => fuse.dialogs["agoDueDate"]);
    const dispatch = useDispatch();
    return (
        <Switch
            color={"primary"}
            checked={ago || false}
            size={"small"}
            className={"ml-2 print:hidden"}
            onChange={() => dispatch(fuseActions.toggle("agoDueDate"))}
            onClick={(e) => e.stopPropagation()}
        />
    );
};

