import {combineReducers} from "redux";
import redPacketManagerReducers from "./red-packet-manager.reducer";
import myRedPacketReducers from "./my-red-packet.reducer";
import relativesReducer from "./relative.reducer";
import sendRedPacketReducer from "./send-red-packet.reducer";

const redPacketReducer = combineReducers({
    redPacketManagerReducers,
    myRedPacketReducers,
    relativesReducer,
    sendRedPacketReducer
});

export default redPacketReducer;