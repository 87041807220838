import axios, {pmsPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_TEMPLATE = "[COMMERCE|TEMPLATE] GET_TEMPLATE";
export const GET_STAGES = "[COMMERCE|TEMPLATE] GET_STAGES";
export const CHANGE_STEP = "[COMMERCE|TEMPLATE] CHANGE_STEP";
export const SELECT_STAGE = "[COMMERCE|TEMPLATE] SELECT_STAGE";
export const CHOOSE_FORM = "[COMMERCE|TEMPLATE] CHOOSE_FORM";
export const SELECT_CATEGORY = "[COMMERCE|TEMPLATE] SELECT_CATEGORY";

export function getTemplate(id){
    const request = axios.get(`${pmsPrefix}/template/${id}`);
    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch(finishLoading());
                dispatch({
                    type: GET_TEMPLATE,
                    payload: response.data
                });
                dispatch(getStages(id));
            }
        );
    };
}

export function getStages(templateId){
    const request = axios.get(`${pmsPrefix}/stages?templateId=${templateId}`);

    return (dispatch) => {
        request.then((response) => {
                dispatch({
                    type: GET_STAGES,
                    payload: response.data
                });
            }
        );
    };
}

export function changeStep(step) {
    return {
        type: CHANGE_STEP,
        step
    };
}
export function selectStage(stage) {
    return {
        type: SELECT_STAGE,
        stage
    };
}

export function chooseForm(form) {
    return {
        type: CHOOSE_FORM,
        form
    };
}
