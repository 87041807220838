import {finishLoading, startLoading} from "@fuseActions";
import axios, {commonPrefix} from "../../../../../myaxios";

export const GET_CHECKIN_RESULTS = "[GET_CHECKIN_RESULTS]";

export const SELECT_CHECKIN_PHOTOS = "[SELECT_CHECKIN_PHOTOS]";


export function getCheckinResults(params, callback) {

    const request = axios.get(`${commonPrefix}/extConstCheckinResults`, {
        params: params
    });
    return (dispatch) => {
        dispatch(startLoading);
        request.then(response => {
            dispatch({
                type: GET_CHECKIN_RESULTS,
                payload: response.data
            });
            if (callback instanceof Function) {
                callback();
            }
            dispatch(finishLoading());
        });
    };
}

/*
* params : {
*           checkIns: [],
*           currentIndex: 0,
*            }
* */
export function selectCheckInPhotos(params) {
    return {
        type: SELECT_CHECKIN_PHOTOS,
        payload: params
    };
}
