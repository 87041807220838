import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import { showMessage } from "@fuseActions";
import axios, {firmPrefix} from "myaxios";
import {useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import CreatableSelect from "react-select/async-creatable";
import {components} from "utils/Select2Components";
import {SuccessButton} from "utils/utilComponents";
import {wpSearchTags} from "utils/utilities";
import {useWorkPlan} from "../useWorkPlan";

const Tags = () => {
    const dispatch = useDispatch();
    const {wp, refresh, canEdit} = useWorkPlan();

    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState<string[]>([]);
    const [editTags, setEditTags] = useState(false);

    const initTags =
        wp?.tag.trim() !== "" &&
        wp?.tag.split(",").map((item) => ({
            value: item,
            label: item
        }));

    const changeTags = (value) => {
        const selectedTags: string[] = [];
        if (value) {
            value.map((_i) => selectedTags.push(_i.value));
            setTags(selectedTags);
        } else {
            setTags([]);
        }
    };

    const submitTags = () => {
        if (tags.toString() === undefined || tags.toString() === null) {
            dispatch(
                showMessage({
                    variant: "error",
                    message: "message.Tag can't be null!"
                })
            );
        } else {
            setLoading(true);
            const data = {
                workplanId: wp?.id,
                tag: tags.toString()
            };
            axios
                .put(`${firmPrefix}/changeTag`, data)
                .then(() => {
                    refresh().then(() => setEditTags(false));
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        wp && (
            <>
                <div className={"flex items-center"}>
                    {/*<Typography className={"mb-4"} variant={"h6"}>*/}
                    {/*    <Translate id={"wps.Tags:"} />*/}
                    {/*</Typography>*/}
                    {editTags ? (
                        <div className={"flex-1"}>
                            <Translate>
                                {({translate}) => (
                                    <CreatableSelect
                                        textFieldProps={{
                                            fullWidth: true,
                                            className: "min-w-320"
                                            // required: true,
                                        }}
                                        isMulti
                                        loadOptions={wpSearchTags}
                                        defaultOptions
                                        // styles={colourStyles}
                                        components={components}
                                        // @ts-ignore
                                        defaultValue={initTags}
                                        onChange={changeTags}
                                        noOptionsMessage={() => translate("_.no option")}
                                        placeholder={translate("wps.create tag")}
                                        isClearable
                                    />
                                )}
                            </Translate>
                        </div>
                    ) : (
                        wp.tag.trim() !== "" &&
                        wp.tag
                            .split(",")
                            .map((t, i) => (
                                <Chip color={"secondary"} key={i} size={"small"} className="mr-2  mt-3" label={t} />
                            ))
                    )}
                    {canEdit &&
                        (editTags ? (
                            <div className={"mt-12 flex items-end justify-end"}>
                                <Button onClick={() => setEditTags(false)} className={"mr-3"}>
                                    {<Translate id={"_.Cancel"} />}
                                </Button>
                                <SuccessButton
                                    data-cy={"submit-tags"}
                                    className={loading ? "load" : ""}
                                    variant={"contained"}
                                    onClick={submitTags}
                                    startIcon={<Icon fontSize={"small"}>check</Icon>}
                                >
                                    {<Translate id={"_.Save"} />}
                                </SuccessButton>
                            </div>
                        ) : (
                            <div className={"mt-12 flex items-end justify-end"}>
                                <Button
                                    data-cy={"edit-tags"}
                                    onClick={() => setEditTags(!editTags)}
                                    startIcon={<Icon>{wp.tag.trim() === "" ? "add" : "edit"}</Icon>}
                                >
                                    {wp.tag.trim() === "" ? (
                                        <Translate id={"wps.Add tags"} />
                                    ) : (
                                        <Translate id={"wps.Edit"} />
                                    )}
                                </Button>
                            </div>
                        ))}
                </div>
            </>
        )
    );
};

export default Tags;
