import {lazy} from "react";

export const RolesConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/roles",
            component: lazy(() => import("./Page")),
        },
        // {
        //     path: "/role/add",
        //     component: lazy(() => import("./EditPermissions"))
        // },
        // {
        //     path: "/role/:id",
        //     component: lazy(() => import("./DetailsPage")),
        // },
        {
            path: "/editPermissions/:id",
            component: lazy(() => import("./permissions/Edit")),
        },
    ],
};
