import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    dmnViewType: "drd",
};


const dmn = function (state=initState,action) {
    switch (action.type) {
        case Actions.SET_DMNVIEW_TYPE: {
            const obj = cloneDeep(state);
            obj.dmnViewType = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     dmnViewType: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default dmn;
