import {setDefaultSettings, setNavigation} from "@fuseActions";
import history from "../../../../@history.js";
import axios, {firmPrefix, flowPrefix, pmsPrefix, userPrefix} from "../../../../myaxios";
import {mutate} from "swr";

export const SET_USER_DATA = "[USER] SET USER DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const GET_MASTER_FIRM = "[USER] GET_MASTER_FIRM";
export const GET_REQUESTS = "[USER] GET_REQUESTS";
export const GET_UNREAD_DATA = "[USER] GET_UNREAD_DATA";

const getUnread = (dispatch) => {
    axios.get(`${firmPrefix}/statUnreadWorkplanCount`).then(({data}) => {
        dispatch({
            type: GET_UNREAD_DATA,
            key: "4ab840c0-8f7b-11ea-b26e-0d2a4a46abbf",
            value: data.week,
        });
        dispatch({
            type: GET_UNREAD_DATA,
            key: "73557070-8f7b-11ea-b26e-0d2a4a46abbf",
            value: data.today,
        });
    });
    axios.get(`${pmsPrefix}/myIssues?size=1&unread=true`).then(({data}) => {
        dispatch({
            type: GET_UNREAD_DATA,
            key: "4eb4c9e0-1d58-11ea-99d1-41cd7d4028d5",
            value: data.totalElements,
        });
    });
    axios.get(`${flowPrefix}/myTasksCount`).then(({data}) => {
        dispatch({
            type: GET_UNREAD_DATA,
            key: "f5ec2fa0-7413-11ec-92d1-89aeb0fe2400",
            value: data.unfinished,
        });
        dispatch({
            type: GET_UNREAD_DATA,
            key: "e988fe40-7414-11ec-92d1-89aeb0fe2400",
            value: data.unRead,
        });
    });
};

export function getUnreadData() {
    return (dispatch) => {
        getUnread(dispatch);
    };
}

export function setUserData() {
    return (dispatch) => {
        const reqNavigations = axios.get(`${userPrefix}/navigations`).then((res) => {
            dispatch(setNavigation(res.data));
            // axios.get(`${firmPrefix}/workplans?unread=true&size=1`).then(({data}) => {
            //     dispatch({
            //         type: GET_UNREAD_DATA,
            //         key: "Workplans",
            //         value: data.totalElements,
            //     });
            // });
            // axios.get(`${flowPrefix}/myTasksCount`).then(({data}) => {
            //     dispatch({
            //         type: GET_UNREAD_DATA,
            //         key: "Flow Center",
            //         value: data.unRead,
            //     });
            // });
            // axios.get(`${firmPrefix}/workplans?unread=true&size=1`).then(({data}) => {
            //     dispatch({
            //         type: GET_UNREAD_DATA,
            //         key: "Workplans", value: data.totalElements
            //     });
            //
            // });
        });

        //        axios.get(`${firmPrefix}/myFirms`).then((res) => {
        //            dispatch({
        //                type: GET_MY_FIRMS,
        //                myFirms: res.data,
        //            });
        //        });

        const reqSettings = axios.get(`${userPrefix}/settings`).then((res) => {
            if (res.data.length > 0) {
                // const urlParams = new URLSearchParams(window.location.search);
                const settings = res.data[0].value;
                // if(urlParams.get('from') === "electron") {
                // }
                dispatch(setDefaultSettings(settings));
            }
        });

        const reqMasterFirm = axios.get(`${firmPrefix}/masterFirm`);

        const reqBaseInfo = axios.get(`${userPrefix}/baseInfo`).then((res) => {
            const payload = res.data;
            payload.profile.avatar = !payload.profile.avatar || payload.profile.avatar === "" ? undefined : payload.profile.avatar;
            dispatch({
                type: SET_USER_DATA,
                payload: res.data,
            });
        });

        Promise.all([reqNavigations, reqSettings, reqBaseInfo]).then(() => {
            reqMasterFirm.then(({data}) => {
                dispatch({
                    type: GET_MASTER_FIRM,
                    masterFirm: data,
                });
                if (data.id) getUnread(dispatch);
            });
        });
    };
}

// fixme new get requests should open notification and dialog for firm admin or hr to accept request
//export function getRequests() {
//    return (dispatch) => {
//        axios.get(`${firmPrefix}/requests`).then((res) => {
//            // if (res.data.length > 0) {
//            //     dispatch(turnOn("requests"));
//            dispatch({
//                type: GET_REQUESTS,
//                requests: res.data
//            });
//            // }
//        });
//    };
//}

export function removeUserData() {
    return {
        type: REMOVE_USER_DATA,
    };
}

export function logoutUser() {
    //    const path = history.location.pathname + history.location.search;
    // console.log("path: ", path);
    // if(path !== '/login' && path !== '/register'){
    //    if (noAuthPaths()) {
    //        localStorage.setItem("lastPathName", path);
    //    }
    return (dispatch) => {
        mutate(() => true, undefined, {revalidate: false});
        localStorage.clear();
        dispatch(removeUserData());
        dispatch({
            type: USER_LOGGED_OUT,
        });
        axios.get(`${userPrefix}/logout`).then(() => {});
        history.push({pathname: "/login"});
    };
}
