import { turnOff, showMessage } from "@fuseActions";
import {Button, DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {Component} from "react";
import {TwitterPicker} from "react-color";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import withDialog from "utils/withDialog";
import axios, {crmPrefix} from "../../../../myaxios";
import { getList } from "../store/actions";

const initState = {
    loading: false,
    formData: {
        title: "",
        color: "#333333",
    },
};

class MarketingLabelDialog extends Component {
    state = initState;

    componentDidMount = () => {
        const {selected} = this.props;
        if (selected) this.setState({formData: selected});
    };

    componentDidUpdate = (prevProps) => {
        const {selected} = this.props;
        if (prevProps.selected !== selected) {
            selected ? this.setState({formData: selected}) : this.clearData();
        }
    };

    clearData = () => {
        this.setState(initState);
    };

    handleChange = (name) => (event) => {
        const value = event.target.value;
        this.setState((state) => ({
            formData: {
                ...state.formData,
                [name]: value,
            },
        }));
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {selected, selectedType} = this.props;
        this.setState({loading: true});
        const formData = {
            source: selectedType,
            ...this.state.formData,
        };
        if (selected) formData.id = selected.id;
        let request = selected ? axios.put(`${crmPrefix}/label`, formData) : axios.post(`${crmPrefix}/label`, formData);

        request
            .then(() => {
                this.setState({loading: false});
                this.props.turnOff("marketingLabel");
                this.props.showMessage({
                    variant: "success",
                    message: "message.Label Saved!",
                });
                this.props.getData({source: selectedType});
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };

    render() {
        const {selected, selectedType} = this.props;
        const {loading, formData} = this.state;
        return (
            <div className={loading ? "loading" : null}>
                <DialogTitle id="alert-dialog-title">
                    {selected ? (
                        <>
                            <Translate id="market.Edit" /> {selected.title}{" "}
                        </>
                    ) : (
                        <Translate id={`_.New ${selectedType} label`} />
                    )}
                </DialogTitle>
                <form onSubmit={this.onSubmit}>
                    <DialogContent className={"py-0"}>
                        <TextField
                            autoFocus={true}
                            fullWidth
                            required
                            onChange={(e) => this.handleChange("title")(e)}
                            label={<Translate id={"crm.Name"} />}
                            value={formData.title}
                        />
                        <TwitterPicker
                            colors={[
                                "#333333",
                                "#795548",
                                "#FF6900",
                                "#FCB900",
                                "#7BDCB5",
                                "#00D084",
                                "#8ED1FC",
                                "#0693E3",
                                "#ABB8C3",
                                "#EB144C",
                                "#F78DA7",
                                "#9900EF",
                            ]}
                            color={formData.color}
                            className={"my-12"}
                            triangle={"hide"}
                            width={"100%"}
                            onChangeComplete={(color) => this.setState((state) => ({formData: {...state.formData, color: color.hex}}))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.props.turnOff("marketingLabel");
                            }}>
                            <Translate id="_.cancel" />
                        </Button>
                        <Button disabled={formData.title === ""} color="primary" type={"submit"}>
                            <Translate id="_.submit" />
                        </Button>
                    </DialogActions>
                </form>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOff: turnOff,
            showMessage: showMessage,
            getData: getList,
        },
        dispatch
    );
}

function mapStateToProps({marketing}) {
    return {
        selected: marketing.labels.selected,
        selectedType: marketing.labels.selectedType,
    };
}

export default withDialog(
    "marketingLabel",
    "xs"
)(withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(MarketingLabelDialog))));
