import {useState} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "react-select/async";
import {searchContact} from "utils/utilities";
import {components} from "utils/Select2Components";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import * as Actions from "../../store/actions";
import * as fuseActions from "@fuseActions";
import Grid from "@material-ui/core/Grid";
import {FormControl, TextField} from "@material-ui/core";
import axios, {flowPrefix} from "../../../../../myaxios";
import DatePickerWrapper from "utils/DatePickerWrapper/DatePickerWrapper";
import {searchInstance} from "../../utils/utils";

const initState = {
    name: "",
    description: "",
    priority: "",
};

function CreateUserTask(props) {
    const dispatch = useDispatch();

    const search = useSelector(({fuse}) => fuse.search);

    const dialogs = useSelector(({fuse}) => fuse.dialogs);

    const [state, setState] = useState(initState);

    const [user, setUser] = useState({value: "", label: ""});

    const [userId, setUserId] = useState("");

    const [tenant, setTenant] = useState({value: "", label: ""});

    const [tenantId, setTenantId] = useState("");

    const [instance, setInStance] = useState({value: "", label: ""});

    const [instanceId, setInstanceId] = useState("");

    const [loading, setLoading] = useState(false);

    const [followUp, setFollowUp] = useState("");

    const [due, setDue] = useState("");

    const handleChange = (name) => (e) => {
        setState({
            ...state,
            [name]: e.target.value,
        });
    };

    function changeUser(value) {
        if (value) {
            setUser({
                ...user,
                value: value.value,
                label: value.label,
            });
            setUserId(value.value);
        } else {
            setUserId("");
            setUser({value: "", label: ""});
        }
    }

    function changeInstance(value) {
        if (value) {
            setInStance({
                ...instance,
                value: value.value,
                label: value.label,
            });
            setInstanceId(value.value);
        } else {
            setInstanceId("");
            setInStance({value: "", label: ""});
        }
    }

    function changeTenant(value) {
        if (value) {
            setTenant({
                ...tenant,
                value: value.value,
                label: value.label,
            });
            setTenant(value.value);
        } else {
            setTenantId("");
            setTenant({value: "", label: ""});
        }
    }

    function canSunmit() {
        return (
            userId === "" || userId === undefined || state.name === "" || instanceId === "" || instanceId === undefined
        );
    }

    function handleClose() {
        dispatch(fuseActions.turnOff("CreateUserTask"));
    }

    const handleSubmit = () => {
        setLoading(true);
        const params = {
            name: state.name,
            description: state.description,
            priority: state.priority,
            assignee: userId,
            due: due,
            followUp: followUp,
            instanceId: instanceId,
            tenantId: tenantId,
        };

        axios
            .post(`${flowPrefix}/userTask`, params)
            .then(() => {
                dispatch(Actions.getUserTasks(search));
                setLoading(false);
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.task created!",
                    })
                );
                handleClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"xs"}
            open={!!dialogs["CreateUserTask"]}
            onClose={handleClose}
            classes={{paper: "overflow-visible", root: "overflow-visible"}}
            className={"overflow-visible"}
            aria-labelledby="responsive-dialog-title"
        >
            {
                <Translate>
                    {({translate}) => (
                        <DialogTitle id="responsive-dialog-title">
                            {<>{translate("process.create usertask")}</>}
                        </DialogTitle>
                    )}
                </Translate>
            }
            <DialogContent className={"overflow-visible"}>
                <div className={loading ? "loading" : null}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-name"
                                fullWidth
                                required
                                label={<Translate id={"process.name"} />}
                                onChange={handleChange("name")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-name"
                                fullWidth
                                type={"number"}
                                label={<Translate id={"process.priority"} />}
                                onChange={handleChange("priority")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Translate>
                                {({translate}) => (
                                    <Select
                                        required
                                        textFieldProps={{
                                            margin: "normal",
                                            required: true,
                                            label: translate("process.assignee"),
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                        margin={"normal"}
                                        loadOptions={searchContact}
                                        defaultOptions
                                        // styles={selectStyles}
                                        components={components}
                                        value={user}
                                        onChange={changeUser}
                                        placeholder={translate("process.Search a user")}
                                        isClearable
                                    />
                                )}
                            </Translate>
                        </Grid>
                        <Grid item xs={6}>
                            <Translate>
                                {({translate}) => (
                                    <Select
                                        required
                                        textFieldProps={{
                                            margin: "normal",
                                            required: true,
                                            label: translate("process.instance"),
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                        margin={"normal"}
                                        loadOptions={searchInstance}
                                        defaultOptions
                                        // styles={selectStyles}
                                        components={components}
                                        value={instance}
                                        onChange={changeInstance}
                                        placeholder={translate("process.Search a instance")}
                                        isClearable
                                    />
                                )}
                            </Translate>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={"px-12 mb-1 w-full"}>
                                <Translate>
                                    {({translate}) => (
                                        <DatePickerWrapper
                                            disableToolbar
                                            variant="inline"
                                            format={"yyyy-MM-dd"}
                                            label={translate("process.Start date")}
                                            autoOk
                                            TextFieldComponent={(_prop) => (
                                                <TextField
                                                    {..._prop}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin={"normal"}
                                                    style={{marginTop: 22}}
                                                    value={
                                                        followUp !== "" ? new Date(followUp).toLocaleDateString() : ""
                                                    }
                                                />
                                            )}
                                            onChange={(date) => setFollowUp(date)}
                                        />
                                    )}
                                </Translate>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={"px-12 mb-1 w-full"}>
                                <Translate>
                                    {({translate}) => (
                                        <DatePickerWrapper
                                            disableToolbar
                                            variant="inline"
                                            label={translate("process.due")}
                                            autoOk
                                            format={"yyyy-MM-dd"}
                                            TextFieldComponent={(_prop) => (
                                                <TextField
                                                    {..._prop}
                                                    fullWidth
                                                    // label={translate("cmb.Start date")}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin={"normal"}
                                                    style={{marginTop: 22}}
                                                    value={due !== "" ? new Date(due).toLocaleDateString() : ""}
                                                />
                                            )}
                                            onChange={(date) => setDue(date)}
                                        />
                                    )}
                                </Translate>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-name"
                                fullWidth
                                margin={"normal"}
                                label={<Translate id={"process.description"} />}
                                onChange={handleChange("description")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Translate>
                                {({translate}) => (
                                    <Select
                                        textFieldProps={{
                                            margin: "normal",
                                            label: translate("process.tenant"),
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                        margin={"normal"}
                                        loadOptions={searchContact}
                                        defaultOptions
                                        // styles={selectStyles}
                                        components={components}
                                        value={tenant}
                                        onChange={changeTenant}
                                        placeholder={translate("process.Search a tenant")}
                                        isClearable
                                    />
                                )}
                            </Translate>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{<Translate id={"_.cancel"} />}</Button>
                <Button color={"secondary"} disabled={canSunmit()} onClick={handleSubmit}>
                    {<Translate id={"_.sure"} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withLocalize(CreateUserTask);
