import { turnOff, turnOn, startLoading, finishLoading, showMessage, replaceParams, minimize } from "@fuseActions";
import {Button, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Typography, withMobileDialog} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { stringify } from "qs";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import EventBus from "utils/EventBus";

import withDialog from "utils/withDialog";
import axios, {crmPrefix} from "../../../../../myaxios";
import {yearMonthDayTimestamp} from "../../../incomes/utils/DateUtils";
import {ContactInputItem, getGridListAsycnNotificationName} from "../../marketingTools";
import transJson from "../../translates/translate.json";
import { selectItem, getItem, getList } from "../store/actions";
import Step0 from "./Step0";
import Step1 from "./Step1";

const initState = {
    loading: false,
    step: 0,
    deal: "",

    formData: {
        type: "GeneralMeeting",
        dealId: "",
        contactIds: "",
        title: "",
        startDate: 0,
        endDate: 0,
        status: "open",
        priority: "general",
        reminder: 0,
        description: "",
        result: "",
    },
};

class ActivityDialog extends Component {
    constructor(props) {
        super(props);
        this.props.addTranslation(transJson);
    }

    state = initState;

    componentDidMount = () => {
        const {selectedItem, selectedCompany, selectedContact, selectedDeal} = this.props;
        if (selectedItem) {
            this.setData(selectedItem);
        } else {
            if (selectedCompany) {
                this.setState({
                    formData: {
                        ...initState.formData,
                        ...selectedItem,
                        companyId: selectedCompany.id,
                    },
                });
            }
            if (selectedContact) {
                this.setState({
                    formData: {
                        ...initState.formData,
                        ...selectedItem,
                        contactIds: [
                            {
                                value: selectedContact.id,
                                label: <ContactInputItem item={selectedContact} />,
                            },
                        ],
                    },
                });
            }
            if (selectedDeal) {
                this.setState({
                    formData: {
                        ...initState.formData,
                        ...selectedItem,
                        dealId: selectedDeal.id,
                    },
                });
            }
        }
    };

    componentDidUpdate = (prevProps) => {
        const {selectedItem} = this.props;
        if (selectedItem !== prevProps.selectedItem) {
            selectedItem ? this.setData(selectedItem) : this.clearData();
        }
    };

    componentWillUnmount = () => {
        this.clearData();
    };

    setData = (selectedItem) => {
        this.setState({
            formData: {
                ...initState.formData,
                ...selectedItem,
                type: selectedItem.type ? selectedItem.type.key : "",
                companyId: selectedItem.company ? selectedItem.company.id : "",
                dealId: selectedItem.deal ? selectedItem.deal.id : "",
                contactIds:
                    selectedItem.contacts &&
                    selectedItem.contacts.map((__) => ({
                        value: __.id,
                        label: <ContactInputItem item={__} />,
                    })),
            },
        });
    };

    clearData = () => {
        this.setState(initState);
    };

    filterData = () => {
        const search = {...this.props.search};
        this.props.getActivities(search);
        delete search.keyword;
        delete search.content;
        localStorage.setItem("companiesFilter", stringify({...search}));
    };

    canSubmit = () => {
        const {formData} = this.state;
        let checkContacts = formData.contactIds === "" || formData.contactIds === null;
        return formData.type === "" || checkContacts || formData.title === "";
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {search, selectedItem, turnOn, showMessage, getActivity, match, activity, replaceParams} = this.props;
        this.setState({loading: true});
        let formData = this.state.formData;
        // let extensionParams = null;

        formData = {
            ...formData,
            contactIds: formData.contactIds.map((t) => t.value),
            startDate:
                formData.startDate > 0 &&
                formData.startDate !== "" &&
                formData.startDate !== 0 &&
                formData.startDate !== undefined &&
                formData.startDate !== null
                    ? yearMonthDayTimestamp(new Date(formData.startDate))
                    : "",
            endDate:
                formData.endDate > 0 &&
                formData.endDate !== "" &&
                formData.endDate !== 0 &&
                formData.endDate !== undefined &&
                formData.endDate !== null
                    ? yearMonthDayTimestamp(new Date(formData.endDate))
                    : "",
            reminder:
                formData.reminder > 0 &&
                formData.reminder !== "" &&
                formData.reminder !== 0 &&
                formData.reminder !== undefined &&
                formData.reminder !== null
                    ? yearMonthDayTimestamp(new Date(formData.reminder))
                    : "",
        };
        delete formData.deal;
        if (selectedItem) {
            formData.id = selectedItem.id;
        }
        delete formData.company;
        let request = selectedItem ? axios.put(`${crmPrefix}/activity`, formData) : axios.post(`${crmPrefix}/activity`, formData);
        request
            .then(() => {
                this.setState({loading: false});
                showMessage({
                    message: "message.Activity Saved!",
                    variant: "success",
                });
                if (activity) {
                    getActivity(activity.id);
                } else if (this.props.selectedGridListType) {
                    const notifiName = getGridListAsycnNotificationName(this.props.selectedGridListType);
                    EventBus.dispatch(notifiName, notifiName);
                } else {
                    turnOn("companiesSideFilter");
                    replaceParams({...match.params, size: search.size}).then(() => this.filterData());
                }
                this.clearData();
                this.closeDialog();
            })
            .catch(() => {
                this.setState({loading: false});
            });
    };
    //
    //    changeLabel = (value) => {
    //        const selectedLabels = [];
    //        if (value) {
    //            value.map((_i) => selectedLabels.push(_i.value));
    //            this.setState({selectedLabels});
    //        }
    //    };

    closeDialog = () => {
        this.props.turnOff("activity");
        this.props.selectItem(null);
    };

    handleFormData = (name) => (event) => {
        const formData = {...this.state.formData};
        formData[name] = event.target.value;
        this.setState({
            formData,
        });
    };
    //
    //    filterEndDate = (date) => {
    //        this.setState({
    //            endDate: date,
    //        });
    //    };

    render() {
        const {selectedItem} = this.props;
        const {loading, formData, step} = this.state;
        let stepContent = <div>step 0</div>;
        switch (step) {
            case 1:
                stepContent = <div>step 1</div>;
                break;
            case 2:
                stepContent = <div>step 2</div>;
                break;
        }

        const stepsProps = {
            handleFormData: this.handleFormData,
            onSubmit: this.onSubmit,
            step,
            selectedItem,
            formData,
            goBack: this.goBack,
            closeDialog: this.closeDialog,
        };

        return (
            <div className={loading ? "loading" : ""}>
                {/*{stepContent}*/}
                <DialogTitle
                    id="alert-dialog-title"
                    disableTypography
                    classes={{root: "drag flex justify-between items-center px-24 py-8 rounded-t"}}>
                    {selectedItem ? (
                        <Translate>
                            {({translate}) => (
                                <Typography className={"my-12"} variant={"h6"}>
                                    {translate("market.Edit activity with parameter", {name: selectedItem.title})}
                                </Typography>
                            )}
                        </Translate>
                    ) : (
                        <>
                            <Typography variant={"h6"}>
                                <span className={"font-normal"}>
                                    <Translate id="market.New Activity" />
                                </span>
                            </Typography>
                        </>
                    )}
                    <div>
                        {/*<IconButton className={'no-drag'} onClick={()=>this.props.minimize("activity")}><Icon>remove</Icon></IconButton>*/}
                        <IconButton className={"no-drag"} onClick={this.closeDialog}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={"overflow-visible"}>
                    <Step0 {...stepsProps} />
                    {step === 1 && (
                        <>
                            {" "}
                            <Divider className="mb-20 mt-16" />
                            <Step1 {...stepsProps} />
                        </>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button color={"default"} onClick={this.closeDialog}>
                        {<Translate id={"market.cancel"} />}
                    </Button>
                    {step === 1 ? (
                        <>
                            <Button variant={"contained"} disabled={this.canSubmit()} color="secondary" onClick={this.onSubmit}>
                                <Translate id={"_.save"} />
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button disabled={this.canSubmit()} onClick={this.onSubmit} color="secondary">
                                <Translate id={"_.quick save"} />
                            </Button>
                            <Button variant={"contained"} disabled={this.canSubmit()} color="secondary" onClick={() => this.setState({step: 1})}>
                                <Translate id={"_.next"} />
                            </Button>
                        </>
                    )}
                </DialogActions>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            selectItem: selectItem,
            getActivity: getItem,
            getActivities: getList,
            turnOff: turnOff,
            turnOn: turnOn,
            startLoading: startLoading,
            finishLoading: finishLoading,
            showMessage: showMessage,
            replaceParams: replaceParams,
            minimize: minimize,
        },
        dispatch
    );
}

function mapStateToProps({marketing, fuse, user}) {
    return {
        search: fuse.search,
        user: user.profile,
        selectedItem: marketing.activities.list.selected,
        selectedGridListType: marketing.labels.selectedGridListType,
        activity: marketing.activities.item,
        selectedCompany: marketing.companies.item,
        selectedContact: marketing.companyContacts.item,
        selectedDeal: marketing.deals.item,
    };
}

export default withDialog(
    "activity",
    "sm",
    true
)(withRouter(withMobileDialog()(withLocalize(connect(mapStateToProps, mapDispatchToProps)(ActivityDialog)))));
