import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import type {FC} from "react";
import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {SuccessButton} from "../../../../../utils/utilComponents";
import {useDGDef} from "../../hooks/hooks";
import useRows from "../../hooks/useRows";

import type {DGRowType} from "../../types";
import {viewTypeToView} from "../cell/DGCell";

type Props = {
    row: DGRowType;
    onClose(): void;
};

const EditRecord: FC<Props> = (props) => {
    const {row, onClose} = props;
    const [data, setData] = useState(row);
    const {onChangeRow} = useRows();
    const {cols} = useDGDef();

    useEffect(() => {
        setData(row);
    }, [row]);

    return (
        <>
            <DialogContent>
                {cols
                    .filter((x) => !x.deleted)
                    .map((col) => {
                        const onDone = (value) => {
                            setData((r) => ({...r, [col.id]: value}));
                        };
                        return (
                            <div key={col.id} className={"p-12"}>
                                <FormLabel>{col.label}</FormLabel>
                                <div className="relative">
                                    {viewTypeToView[col.viewType].renderEdit({
                                        initValue: row[col.id],
                                        onDone,
                                        onCancel: () => {},
                                        col,
                                        fullView: true,
                                        row,
                                    })}
                                </div>
                            </div>
                        );
                    })}
            </DialogContent>

            <DialogActions>
                <SuccessButton
                    variant={"contained"}
                    onClick={() => {
                        onChangeRow(data);
                        onClose();
                    }}>
                    <Translate id={"_.save"} />
                </SuccessButton>
            </DialogActions>
        </>
    );
};

export default EditRecord;
