import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    userId: "",
    title: "",
    relativesList: [],
    relativeMembersByID:[],
    selectedRelative: null,
    selectedMembersToRelative: []
};

const relativesReducer = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_RELATIVES_LIST: {
            let tobj = cloneDeep(state);
            tobj.relativesList = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     relativesList: action.payload
            // };
        }
        case Actions.GET_RELATIVES_BY_ID: {
            let tobj = cloneDeep(state);
            tobj.relativeMembersByID = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     relativeMembersByID: action.payload
            // };
        }

        case Actions.SELECT_A_RELATIVE: {
            let tobj = cloneDeep(state);
            tobj.selectedRelative = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedRelative: action.payload
            // };
        }

        case Actions.UNSELECT_RELATIVE: {
            let tobj = cloneDeep(state);
            tobj.selectedRelative = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedRelative: []
            // };
        }

        case Actions.PASS_THE_SELECTED_MEMBERS_TO_ADD: {
            let tobj = cloneDeep(state);
            tobj.selectedMembersToRelative = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedMembersToRelative: [...action.payload]
            // };
        }
        default:
            return  cloneDeep(state);
    }
};

export default relativesReducer;
