import {AppBar, Hidden, Icon, Toolbar} from "@material-ui/core";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import NavbarMobileToggleButton from "app/layouts/shared-components/NavbarMobileToggleButton";
import {memo, useEffect} from "react";
import {useSelector} from "react-redux";
import UserMenu from "../../../../components/layout/UserMenu";
import ToggleView from "../../../components/ToggleView";
import ZoomControlButtons from "../../../components/ZoomControlButtons";
import FavoriteButton from "../../shared-components/FavoriteButton";
import NavbarFoldedToggleButton from "../../shared-components/NavbarFoldedToggleButton";
import OldSystemButton from "../../shared-components/OldSystemButton";
import QuickPanelToggleButton from "../../shared-components/QuickPanelToggleButton";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    separator: {
        width: 1,
        height: "4.4rem",
        backgroundColor: theme.palette.divider,
    },
}));

function ToolbarLayout1(props) {
    const {t} = useTranslation("nav");
    const config = useSelector(({fuse}) => fuse.settings.current.layout.config);
    const toolbarTheme = useSelector(({fuse}) => fuse.settings.toolbarTheme);
    //    const loading = useSelector(({fuse}) => fuse.loading);

    const classes = useStyles(props);

    useEffect(() => {
        const isExist = localStorage.getItem("TopToolbarLayout_user_tour");
        if (!isExist) {
            localStorage.setItem("TopToolbarLayout_user_tour", "TopToolbarLayout_user_tour");
        }
    }, []);

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar id="fuse-toolbar" elevation={2} className="flex relative z-10 bg-white">
                {/* {loading && <LinearProgress className={"w-full top-0 absolute"} color="secondary" />} */}

                <Toolbar classes={{root: "min-h-44 h-44"}} className="p-0">
                    {config.navbar.display && config.navbar.position === "left" && (
                        <Hidden lgUp>
                            <NavbarMobileToggleButton className="w-52 p-0" />
                        </Hidden>
                    )}

                    {config.navbar.folded && (
                        <NavbarFoldedToggleButton className="h-44 animate-pulse py-0 min-w-52 text-gray-600">
                            <Icon className={"mr-2"} style={{transform: "rotate(180deg)"}}>
                                menu_open
                            </Icon>
                            {t("navbarBtn")}
                        </NavbarFoldedToggleButton>
                    )}

                    <div className="flex flex-1 justify-start">
                        <div className={classes.separator + " hidden md:block h-44"} />
                        <FavoriteButton />
                        <div className={classes.separator + " hidden md:block h-44"} />
                        <OldSystemButton />
                        {/*<div className={classes.separator + " hidden md:block h-44"} />*/}
                        {/*<Hidden lgDown>*/}
                        {/*    <AddButton />*/}
                        {/*</Hidden>*/}
                    </div>

                    <div className={"flex "}>
                        <ZoomControlButtons />
                        <ToggleView />
                        <div className={classes.separator} />
                        <UserMenu />

                        <div className={classes.separator} />

                        {/*<FuseSearch/>*/}

                        {/*<Hidden lgUp>*/}
                        {/*    <ChatPanelToggleButton data-tut={"quikly-open-chatPan"} />*/}
                        {/*</Hidden>*/}

                        {/*<div className={classes.separator}/>*/}

                        <QuickPanelToggleButton />
                    </div>

                    {config.navbar.display && config.navbar.position === "right" && (
                        <Hidden lgUp>
                            <NavbarMobileToggleButton />
                        </Hidden>
                    )}
                </Toolbar>
                {/*}*/}
            </AppBar>
            {/*<MobileBottomToolbar />*/}
        </ThemeProvider>
    );
}

export default memo(ToolbarLayout1);
