import {useDispatch, useSelector} from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import {Translate} from "react-localize-redux";
import {Dialog} from "@material-ui/core";
import { turnOff, turnOn } from "@fuseActions";

const TwoFactorWaiting = () => {
    const dispatch = useDispatch();
    const number = useSelector(({fuse}) => fuse.dialogs["verifyNumber"]);
    const twoFactorWaiting = useSelector(({fuse}) => fuse.dialogs["twoFactorWaiting"]);
    const onClose = () => {
        dispatch(turnOff("twoFactorWaiting"));
        dispatch(turnOn("verificationCancelled"));
    };

    return (
        <Dialog
            scroll={"body"}
            classes={{paper: "overflow-visible", root: "overflow-visible"}}
            className="overflow-visible z-max"
            // TransitionComponent={Transition}
            // transitionDuration={300}
            maxWidth={"xs"}
            keepMounted
            open={twoFactorWaiting === true}
            onClose={onClose}
            fullWidth
        >
            <Card>
                <CardHeader title={<Translate id={"_.Waiting for verification"} />} />
                <CardContent>
                    <Typography variant={"h6"} className={"mb-12 -mt-16"}>
                        {<Translate id={"_.Please verify this number on your phone."} />}
                    </Typography>
                    <Box className={"bg-grey-light p-16 rounded text-center"}>
                        <Typography color={"secondary"} className={"font-bold"} variant={"h2"}>
                            {number}
                        </Typography>
                    </Box>
                    <Typography variant={"caption"} color={"error"}>
                        {<Translate id={"_.This number is valid only 1 minute!"} />}
                    </Typography>
                </CardContent>
            </Card>
        </Dialog>
    );
};

export default TwoFactorWaiting;
