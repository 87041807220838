import {create} from "zustand";
import {createListSlice} from "./createListSlice";
import {createSingleSlice} from "./createSingleSlice";

const useUserTaskStore = create((...a) => ({
    ...createListSlice(...a),
    ...createSingleSlice(...a),
}));

export default useUserTaskStore;
