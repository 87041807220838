import axios, {financialPrefix} from "../../../../../myaxios";
import {finishLoading, startLoading} from "@fuseActions";

export const GET_ORDER = "[STOCK] GET_ORDER";
export const GET_BONUS = "[STOCK] GET_BONUS";
export const CLEAR_BONUS = "[STOCK] CLEAR_BONUS";

export function getOrder(id){
    const request = axios.get(`${financialPrefix}/stockOrder/${id}`);

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_ORDER,
                payload: response.data
            });
            dispatch(finishLoading());
        });
    };
}

export function getBonus(id){
    const request = axios.get(`${financialPrefix}/bonusRequests/${id}`);

    return (dispatch) => {
        dispatch(startLoading());
        request.then((response) => {
            dispatch({
                type: GET_BONUS,
                payload: response.data
            });
            dispatch(finishLoading());
        });
    };
}


export function clearBonus() {
    return {
        type: CLEAR_BONUS
    };
}
