import {Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import classNames from "classnames";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import axios, {crmPrefix} from "../../../../../myaxios";
import ItemHeader from "../../utils/ItemHeader";

class Header extends Component {
    state = {
        checkbox: false,
        itemChangesCount: 0,
        user: "",
        userId: "",
        anchorEl: null
    };

    handleFormData = () => (event) => {
        const value = event.target.value;
        const data = {
            companyId: this.props.sourceItem.id,
            statusKey: value
        };
        axios.put(`${crmPrefix}/companySetStatus`, data).then(() => {
            this.props.refresh();
            this.setState({anchorEl: null});
        });
    };

    render() {
        const {sourceItem} = this.props;
        return (
            <>
                <ItemHeader
                    {...this.props}
                    title={
                        <Typography variant={"h5"}>
                            <span className={"font-bold"}>{sourceItem.name}</span>
                        </Typography>
                    }
                    actions={
                        <>
                            <MyTooltip title={<Translate id={"market.Amount"} />}>
                                <Typography variant={"h6"} className="mr-3">
                                    <small className="opacity/50">{<Translate id={"market.Amount:"} />}</small> {sourceItem.amount}万
                                </Typography>
                            </MyTooltip>

                            <MyTooltip title={<Translate id={"market.status"} />}>
                                <Chip
                                    variant={"outlined"}
                                    label={sourceItem.probability + "%"}
                                    className={classNames(
                                        "ml-2 font-bold",
                                        sourceItem.probability < 100 && sourceItem.probability > 0
                                            ? "bg-orange"
                                            : sourceItem.probability === 100
                                                ? "bg-green"
                                                : "bg-red"
                                    )}
                                />
                            </MyTooltip>
                        </>
                    }
                />
                {/*<>*/}
                {/*    <div className="flex-1">*/}
                {/*        <Breadcrumbs separator={<Icon children="chevron_right"/>} aria-label="breadcrumb" className="mb-8">*/}
                {/*            <Typography className={"opacity/75"}>{<Translate id={"market.Marketing"}/>}</Typography>*/}
                {/*            <Typography color="textPrimary">{<Translate id={"market.Deal"}/>}</Typography>*/}
                {/*        </Breadcrumbs>*/}
                {/*        <div className={"flex items-center"}>*/}
                {/*        <div className={"flex flex-col md:pt-0 md:w-auto w-full"}>*/}

                {/*            */}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    </div>*/}
                {/*    <div className={"flex  flex-col items-end"}>*/}
                {/*        <Owner user={sourceItem.user}/>*/}
                {/*        <div className={"relative flex items-center mt-3"}>*/}

                {/*            <Tooltip title={<Translate id="_.edit"/>}>*/}
                {/*                <IconButton onClick={() => {*/}
                {/*                    selectItem(item);*/}
                {/*                    turnOn('deal')*/}
                {/*                }}>*/}
                {/*                    <Icon>edit</Icon>*/}
                {/*                </IconButton>*/}
                {/*            </Tooltip>*/}

                {/*            <Tooltip title={<Translate id={"_.delete"}/>}>*/}
                {/*                <IconButton onClick={ e => {*/}
                {/*                    e.stopPropagation();*/}
                {/*                    selectItem(item);*/}
                {/*                    turnOn('deleteDeal');*/}
                {/*                }}><Icon>delete</Icon>*/}
                {/*                </IconButton>*/}
                {/*            </Tooltip>*/}

                {/*            <FaveButton sourceId={sourceItem.id} title={sourceItem.name}  type={"deal"}/>*/}

                {/*            */}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</>*/}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: fuseActions.turnOn
        },
        dispatch
    );
}

export default withLocalize(connect(null, mapDispatchToProps)(Header));
