import {showMessage} from "@fuseActions";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import type {TypographyProps} from "@material-ui/core/Typography";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import {MyTooltip} from "@ui";
import clsx from "clsx";
// @ts-ignore
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        // @ts-ignore
        backgroundColor: lighten(theme.palette.secondary.light, 0.8),
    },
}));

type Props = {
    dense?: boolean;
    noButton?: boolean;
    "data-cy"?: string;
};

export const CodeView = (props: Props & TypographyProps) => {
    const classes = useStyles();
    const {dense, noButton, children} = props;

    const language = localStorage.getItem("i18nextLng");

    const ls = useSelector(({fuse}) => fuse.dialogs["listView"]);

    const listView = ls && dense !== false;

    const dispatch = useDispatch();
    const inside = (
        <Typography
            variant={dense || listView ? "body1" : "h6"}
            component={"span"}
            color={"secondary"}
            data-cy={props["data-cy"]}
            className={clsx(
                classes.root,
                props.className,
                dense || listView ? "max-w-52" : "max-w-96",
                " font-bold px-3 inline-block mr-2 rounded-8 nowrap align-middle"
            )}>
            {children}
        </Typography>
    );

    if (!props.children) return <></>;
    return noButton ? (
        <MyTooltip title={props.children}>{inside}</MyTooltip>
    ) : (
        <CopyToClipboard
            text={props.children}
            onCopy={() =>
                dispatch(
                    showMessage({
                        variant: "success",
                        message: "message.Copied params",
                        params: props.children,
                    })
                )
            }>
            <MyTooltip
                disableHoverListener={noButton}
                // @ts-ignore
                onClick={(e) => e.stopPropagation()}
                title={language === "en" ? "Copy " : "复制 " + props.children}>
                <button className={"mx-0 p-0"} disabled={noButton}>
                    {inside}
                </button>
            </MyTooltip>
        </CopyToClipboard>
    );
};
