import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    deploys: [],
    selected: null,
};

const deployReducer = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_PROCESS_DEPLOYMENTS: {
            const obj = cloneDeep(state);
            obj.deploys = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     deploys: action.payload,
            // }
        }
        case Actions.SELECT_A_PROCESS_DEPLOY: {
            const obj = cloneDeep(state);
            obj.selected = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     selected: action.payload,
            // }
        }
        default:
            return  state;
    }
};

export default deployReducer;
