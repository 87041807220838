import {combineReducers} from "redux";
import list from "./list.reducer";
import item from "./item.reducer";

const reducer = combineReducers({
    list,
    item,
});

export default reducer;
