import {Button, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import {Alert} from "@material-ui/lab";
import {EmptyView} from "@ui";
import { showMessage, turnOff } from "@fuseActions";
import axios, {firmPrefix} from "myaxios";
import {useEffect, useState} from "react";
import type {LocalizeContextProps} from "react-localize-redux";
import { Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import DatePickerWrapper from "utils/DatePickerWrapper/DatePickerWrapper";
import {SuccessButton} from "utils/utilComponents";
import {ProjectView} from "utils/utilities";
import {v1 as uuidv1} from "uuid";
import AsyncSelect from "../../../../utils/Forms/AsyncSelect";
import type {ProjectType} from "../../issues/types";
import transJson from "../translate/wps.json";
import type {AvailableTravelsType, TripType, TRPost} from "../types";

const initFormData: TRPost = {
    projectId: "",
    startDate: null,
    endDate: null,
    fieldDays: "",
    amount: "",
    flowId: "",
    paymentCompanyId: "",
    note: ""
};

const TRDialog = ({getData, addTranslation}: {getData(): void} & LocalizeContextProps) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState<TRPost>(initFormData);
    const [flows, setFlows] = useState<{id: string; name: string}[]>();
    const [paymentCompany, setPaymentCompany] = useState<{id: string; name: string}[]>();
    const [availableTravels, setAvailableTravels] = useState<AvailableTravelsType>({
        availableStartDate: +new Date(),
        availableEndDate: +new Date(),
        data: []
    });
    const [selectedCity, setSelectedCity] = useState<string[]>([]);
    const [checkedDays, setCheckedDays] = useState<TripType[]>([]);
    const [amountPerDay, setAmountPerDay] = useState(0);

    const change = (name: keyof TRPost) => (value) => {
        setFormData({...formData, [name]: value?.target ? value.target.value : value});
    };

    useEffect(() => {
        // getFlows();
        addTranslation(transJson);
        getInitAvailableTravels();
    }, []);

    const getFlows = () => {
        axios.get(`${firmPrefix}/myFlows`).then(({data}) => {
            setFlows(data.flows);
            setPaymentCompany(data.paymentCompany);
        });
    };

    const getInitAvailableTravels = () => {
        console.log("init");
        axios.get(`${firmPrefix}/availableTravels`).then(({data}) => {
            setAvailableTravels({
                ...data,
                data: [...data.data.map((i) => ({...i, id: uuidv1()}))]
            });
            const st = +new Date(data.startDate).setHours(0, 0, 0, 0);
            const en = +new Date(data.endDate).setHours(23, 59, 59, 999);
            setFormData((f) => ({
                ...f,
                startDate: st,
                endDate: en
            }));
        });
    };

    const getAvailableTravels = (startDate?: number | null, endDate?: number | null) => {
        axios
            .get(`${firmPrefix}/availableTravels`, {
                params: {
                    startDate,
                    endDate
                }
            })
            .then(({data}) => {
                setAvailableTravels((dd) => ({
                    ...dd,
                    data: [...data.data.map((i) => ({...i, id: uuidv1()}))]
                }));
                if (!startDate && !endDate) {
                    setFormData((f) => ({
                        ...f,
                        startDate: +new Date(data.startDate),
                        endDate: +new Date(data.endDate)
                    }));
                }
            });
    };

    const changeProject = (e, value) => {
        if (value) {
            change("projectId")(value.id);
        } else {
            change("projectId")(null);
        }
    };

    const changeStartDate = (date) => {
        change("startDate")(+new Date(date).setHours(0, 0, 0, 0));
        setCheckedDays([]);
        getAvailableTravels(+new Date(date).setHours(0, 0, 0, 0), formData?.endDate);
    };

    const changeEndDate = (date) => {
        change("endDate")(+new Date(date).setHours(23, 59, 59, 999));
        setCheckedDays([]);
        getAvailableTravels(formData?.startDate, +new Date(date).setHours(23, 59, 59, 999));
    };

    const calculateAmount = (e) => {
        const amountPerDay = e.target.value;
        setAmountPerDay(amountPerDay);
        change("amount")(amountPerDay * checkedDays.length);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        axios
            .post(`${firmPrefix}/travelReceipt`, {
                ...formData,
                fieldDays: checkedDays.length,
                data: checkedDays
            })
            .then(() => {
                dispatch(
                    showMessage({
                        message: "message.Travel receipt Saved!",
                        variant: "success"
                    })
                );
                getData();
                dispatch(turnOff("tr"));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleToggle = (value) => () => {
        const currentIndex = checkedDays.indexOf(value);
        let newChecked = [...checkedDays];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            // newChecked.splice(currentIndex, 1);
            newChecked = checkedDays.filter((i) => i !== value);
        }
        change("amount")(amountPerDay * newChecked.length);
        setCheckedDays(newChecked);
    };

    const isCitySelected = (city) => {
        return selectedCity.includes(city);
    };

    const idNextDisabled = availableTravels.data.length === 0 || formData.amount === "" || formData.startDate === null || checkedDays.length === 0;

    const toggleCity = (city) => (e) => {
        e.stopPropagation();
        const currentIndex = selectedCity.indexOf(city);
        const newChecked = [...selectedCity];
        let newCheckedDays = [...checkedDays];
        if (currentIndex === -1) {
            newChecked.push(city);

            availableTravels.data.map((trip) => {
                const isSelectedIndex = checkedDays.indexOf(trip);
                if (isSelectedIndex === -1 && trip.cities.includes(city)) {
                    newCheckedDays.push(trip);
                }
            });
        } else {
            newChecked.splice(currentIndex, 1);
            newCheckedDays = checkedDays.filter((i) => !i.cities.includes(city));
            // availableTravels.data.map((trip) => {
            //     const isSelectedIndex = checkedDays.indexOf(trip);
            //     // console.log(trip,  checkedDays);
            //     // newCheckedDays = checkedDays.filter(i => i !== trip.id)
            //     if(isSelectedIndex !== -1 && trip.cities.includes(city)) {
            //         newCheckedDays.splice(isSelectedIndex, 1);
            //     }
            // })
        }
        change("amount")(amountPerDay * newCheckedDays.length);
        setSelectedCity(newChecked);
        setCheckedDays(newCheckedDays);
    };

    console.log("formData:", formData);

    return (
        <div className={loading ? "loading  h-full" : "h-full"}>
            <DialogTitle id="alert-dialog-title" disableTypography classes={{root: "flex justify-between items-center px-24 py-8 rounded-t"}}>
                <Typography variant={"h5"}>
                    <Translate id="wps.New travel receipt" />
                </Typography>
            </DialogTitle>
            <DialogContent className="pb-24 pt-0 overflow-visible max-w-full">
                {step === 0 && (
                    <div id="step0">
                        {/*---------------------- dates ----------------------*/}
                        <div className="flex">
                            <Translate>
                                {({translate}) => (
                                    <DatePickerWrapper
                                        className="mr-8"
                                        disableToolbar
                                        fullWidth
                                        maxDate={new Date(availableTravels.availableEndDate)}
                                        minDate={new Date(availableTravels.availableStartDate)}
                                        minDateMessage={translate("wps.Date should not be before minimal date")}
                                        maxDateMessage={translate("wps.Date should not be after maximal date")}
                                        required
                                        variant="inline"
                                        label={translate("wps.start date")}
                                        autoOk
                                        value={formData?.startDate}
                                        onChange={changeStartDate}
                                    />
                                )}
                            </Translate>
                            <Translate>
                                {({translate}) => (
                                    <DatePickerWrapper
                                        minDate={new Date(availableTravels.availableStartDate)}
                                        maxDate={new Date(availableTravels.availableEndDate)}
                                        minDateMessage={translate("wps.Date should not be before minimal date")}
                                        maxDateMessage={translate("wps.Date should not be after maximal date")}
                                        fullWidth
                                        disableToolbar
                                        variant="inline"
                                        label={translate("wps.end date")}
                                        autoOk
                                        disabled={!formData?.startDate}
                                        value={formData?.endDate}
                                        onChange={changeEndDate}
                                    />
                                )}
                            </Translate>
                        </div>

                        <div className="max-h-360 overflow-y-auto bg-grey-lighter mt-16">
                            {availableTravels.data.length === 0 ? (
                                <EmptyView />
                            ) : (
                                <div className="flex flex-col">
                                    <List dense>
                                        {availableTravels.data.map((tr, index) => (
                                            <ListItem key={index} dense button onClick={handleToggle(tr)}>
                                                <ListItemIcon classes={{root: "min-w-auto"}}>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checkedDays.indexOf(tr) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        // inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <div>
                                                            {new Date(tr.date).getMonth() + 1 + "/" + new Date(tr.date).getDate()}
                                                            {tr.cities.map((city, i) => (
                                                                <Chip
                                                                    key={i}
                                                                    size={"small"}
                                                                    className={"ml-2"}
                                                                    color={isCitySelected(city) ? "secondary" : "default"}
                                                                    onClick={toggleCity(city)}
                                                                    label={city}
                                                                />
                                                            ))}
                                                        </div>
                                                    }
                                                />
                                                {tr.isField && (
                                                    <ListItemSecondaryAction>
                                                        <Chip size={"small"} label={<Translate id={"wps.Is Field"} />} />
                                                    </ListItemSecondaryAction>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Alert color={"error"} severity={"error"}>
                                        {<Translate id={"wps.Please reimburse in date order"} />}
                                    </Alert>
                                </div>
                            )}
                        </div>

                        {/*---------------------- days ----------------------*/}
                        <div className="flex mt-16">
                            <TextField
                                disabled
                                aria-readonly
                                InputProps={{readOnly: true}}
                                // disabled={availableTravels.data.length === 0}
                                // min={1}
                                // type={"number"}
                                // required
                                value={checkedDays.length}
                                label={<Translate id={"wps.Field Days"} />}
                                onChange={change("fieldDays")}
                            />
                            <TextField
                                disabled={availableTravels.data.length === 0}
                                type={"number"}
                                className={"mx-8"}
                                value={amountPerDay}
                                label={<Translate id={"wps.Amount per day"} />}
                                onChange={calculateAmount}
                            />
                            <TextField
                                disabled={availableTravels.data.length === 0}
                                type={"number"}
                                required
                                value={formData?.amount}
                                label={<Translate id={"wps.Total amount"} />}
                                onChange={change("amount")}
                            />
                        </div>
                    </div>
                )}
                {/*---------------------- flow & payment company ----------------------*/}
                {step === 1 && (
                    <div id="step1">
                        <div className="flex my-16">
                            <FormControl className="mr-8 flex-1">
                                <InputLabel id="Flow" required>
                                    {<Translate id={"wps.Flow"} />}
                                </InputLabel>
                                <Select required labelId="Flow" fullWidth value={formData.flowId} onChange={change("flowId")}>
                                    {flows?.map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl className={"flex-1"}>
                                <InputLabel id="Payment" required>
                                    {<Translate id={"wps.Payment company"} />}
                                </InputLabel>
                                <Select labelId="Payment" fullWidth required value={formData.paymentCompanyId} onChange={change("paymentCompanyId")}>
                                    {paymentCompany?.map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        {/*---------------------- Project ----------------------*/}
                        <AsyncSelect<ProjectType>
                            defaultOptions
                            data-cy={"project"}
                            name={"trProject"}
                            // value={project}
                            onChange={changeProject}
                            // @ts-ignore
                            classes={{endAdornment: "top-0"}}
                            link={`${firmPrefix}/myProjects?scope=expense`}
                            renderOption={(option) => <ProjectView item={option} />}
                            getOptionLabel={(option) => option.name}
                            textFieldProps={{
                                margin: "normal",
                                size: "small",
                                required: true,
                                label: <Translate id={"_.Project"} />
                            }}
                        />
                        {/*<Translate>*/}
                        {/*    {({translate}) => (*/}
                        {/*        <AsyncSelect*/}
                        {/*            textFieldProps={{*/}
                        {/*                margin: "normal",*/}
                        {/*                required: true,*/}
                        {/*                label: translate("wps.Project"),*/}
                        {/*                InputLabelProps: {*/}
                        {/*                    shrink: true,*/}
                        {/*                },*/}
                        {/*            }}*/}
                        {/*            required*/}
                        {/*            loadOptions={searchProjects}*/}
                        {/*            defaultOptions*/}
                        {/*            margin={"normal"}*/}
                        {/*            // styles={selectStyles}*/}
                        {/*            components={components}*/}
                        {/*            value={project}*/}
                        {/*            onChange={changeProject}*/}
                        {/*            placeholder={translate("wps.Choose a project")}*/}
                        {/*            isClearable*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*</Translate>*/}

                        {/*---------------------- note ----------------------*/}
                        <TextField
                            multiline
                            minRows={4}
                            className={"mt-16"}
                            fullWidth
                            value={formData.note}
                            label={<Translate id={"wps.Note"} />}
                            onChange={change("note")}
                        />
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                {step === 0 ? (
                    <Button
                        variant={"contained"}
                        disabled={idNextDisabled}
                        color="secondary"
                        onClick={() => {
                            setStep(1);
                            getFlows();
                        }}
                    >
                        <Translate id="_.next" />
                    </Button>
                ) : (
                    <>
                        <Button onClick={() => setStep(0)}>
                            <Translate id="_.back" />
                        </Button>
                        <SuccessButton
                            variant={"contained"}
                            className={loading ? "load" : ""}
                            disabled={formData.projectId === "" || formData.flowId === "" || formData.paymentCompanyId === "" || loading}
                            color="secondary"
                            onClick={onSubmit}
                        >
                            <Translate id="_.save" />
                        </SuccessButton>
                    </>
                )}
            </DialogActions>
        </div>
    );
};

export default withLocalize(TRDialog);
