import axios, {financialPrefix} from "../../../../../myaxios";
import useSWRMutation from "swr/mutation";

function sendRequest(url, {arg}) {
    return axios.delete(`${url}/${arg.id}`);
}

function useDeleteIncomeOrder() {
    const {trigger, isMutating, data, error} = useSWRMutation(`${financialPrefix}/incomeOrder`, sendRequest);

    return {
        run: trigger,
        isMutating,
        data,
        error,
    };
}

export default useDeleteIncomeOrder;
