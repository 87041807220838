import {lazy} from "react";


export const MembersLocConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : "/locations/membersLocation",
            component: lazy(() =>
                import("./NewMap")
            )
        },
        {
            path     : "/locations/myLocations",
            component: lazy(() =>
                import("./NewUserLocations")
            )
        },
        {
            path     : "/locations/userLocation",
            component: lazy(() =>
                import("./NewUserLocations")
            )
        },
        {
            path     : "/locations/:userId/",
            component: lazy(() =>
                import("./NewUserLocations")
            )
        },
    ]
};
