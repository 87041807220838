import PropTypes from "prop-types";
import {Collapse, TableBody, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import {StyledTableCell} from "../utilComponents";

TableExtraRowRoot.propTypes = {
    open: PropTypes.bool.isRequired,
};

function TableExtraRowRoot(props) {
    const {open, children} = props;
    return (
        <TableRow className={"print:hidden "}>
            <StyledTableCell colSpan={"100%"}>
                <Collapse in={open} className={"w-full"} timeout="auto" unmountOnExit>
                    {/*<Box margin={1}>*/}
                    <Table className={"w-full"} size="small">
                        <TableBody>
                            {/*{row.history.map((historyRow) => (*/}
                            {/*    <TableRow key={historyRow.date}>*/}
                            {/*        <TableCell>*/}
                            {/*            {label} */}
                            {/*        </TableCell>*/}
                            {/*        <TableCell>{value}</TableCell> */}
                            {/*    </TableRow>*/}
                            {/*))}*/}
                            {children}
                        </TableBody>
                    </Table>
                    {/*</Box>*/}
                </Collapse>
            </StyledTableCell>
        </TableRow>
    );
}

export default TableExtraRowRoot;
