import {combineReducers} from "redux";
import companies from "./companies/store/reducers";
import activities from "./activities/store/reducers";
import companyContacts from "./contacts/store/reducers";
import deals from "./deals/store/reducers";
import archives from "./Archives/store/reducers";
import labels from "./store/reducers/labels.reducer";
import meeting from "./meetings/store/reducers";

const marketingReducer = combineReducers({
    labels,
    companies,
    activities,
    companyContacts,
    deals,
    archives,
    meeting
});

export default marketingReducer;
