export const createCommonSlice = (set, get) => ({
    currentIncome: null,
    incomeIdForIncomeOrders: null,
    onChangeIncomeIdForIncomeOrders: (id) => {
        set({
            incomeIdForIncomeOrders: id,
        });
    },
    onChangeCurrentIncome: (data) => {
        set({
            currentIncome: data,
        });
    },
});
