import { OPEN_DIALOG, CLOSE_DIALOG } from "../actions";

const initialState = {
    edit: false,
};

const dialogs = function (state = initialState, action) {
    switch ( action.type )
    {
        case OPEN_DIALOG: {
            return {
                ...state,
                [action.dialog]: true
            };
        }
        case CLOSE_DIALOG: {
            return {
                ...state,
                [action.dialog]: false
            };
        }
        default:
        {
            return state;
        }
    }
};

export default dialogs;
