import * as Actions from "../actions";
// import produce from 'immer'
import { cloneDeep } from "lodash";

const initState = {
    instanceObject: null,
    sourceType: "instance_list",
    variablesSourceType: "instance",// or "userTask"
    variables: [],
    selectedVariable: null
};


const instance =  (state={...initState},action) => {
    switch (action.type) {
        case Actions.GET_INSTANCE_SINGLE: {
            let tobj = cloneDeep(state);
            tobj.instanceObject = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     instanceObject: action.payload
            // }
            // draft.instanceObject = action.payload;
        }
        case Actions.RESET_INSTANCE_SINGLE: {
            let tobj = cloneDeep(state);
            tobj.instanceObject = null;
            return tobj;
            // return {
            //     ...state,
            //     instanceObject: action.payload
            // }
            // draft.instanceObject = action.payload;
        }
        case Actions.INSTANCE_SOURCE_TYPE: {
            let tobj = cloneDeep(state);
            tobj.sourceType = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     sourceType: action.payload
            // }
        }
        case Actions.VARIABLES_SOURCE_TYPE: {
            let tobj = cloneDeep(state);
            tobj.sourceType = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     sourceType: action.payload
            // }
        }
        case Actions.GET_VARIABLES_FOR_INSTANCE_SINGLE: {
            let tobj = cloneDeep(state);
            tobj.variables = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     variables: action.payload
            // }
        }
        case Actions.SELECT_A_VARIABLE_FOR_INSTANCE: {
            let tobj = cloneDeep(state);
            tobj.selectedVariable = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedVariable: action.payload
            // }
        }
        default:
            return state;
        // }
    }
};
    // produce((state,draft)=>{




export default instance;
