import {Icon, IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import {useDebounceFn, useMemoizedFn} from "ahooks";
import PropTypes from "prop-types";

import {useEffect, useState} from "react";
import {Translate} from "react-localize-redux";

TextFieldQuery.propTypes = {
    /**
     * @description > 是否 加入 "filterBy" 参数标记当前的筛选条件 key,用于当存在多个参数互斥时，清空相关参数
     */
    haveFilterBy: PropTypes.bool,
    /**
     * @description > 该筛选的名字
     */
    titleLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    /**
     * @description > 网络请求的参数
     */
    query: PropTypes.object.isRequired,
    /**
     * @description > 修改网络请求的参数的回调函数
     */
    onChangeQuery: PropTypes.func.isRequired,

    /**
     * @description > 修改网络请求的参数的 key
     */
    name: PropTypes.string.isRequired,

    mutate: PropTypes.func.isRequired,

    disabled: PropTypes.bool
};

TextFieldQuery.defaultProps = {
    haveFilterBy: false
};

function TextFieldQuery(props) {
    const {titleLabel, query, onChangeQuery, name, mutate, haveFilterBy, disabled} = props;
    const [businessKey, setBusinessKey] = useState("");

    useEffect(() => {
        if (!!query[`${name}`]) {
            setBusinessKey(query[`${name}`]);
        }
    }, [query[`${name}`]]);

    const {run} = useDebounceFn(
        () => {
            let params = {...query, [name]: businessKey};
            if (haveFilterBy) {
                params = {...params, filterBy: `${name}`};
            } else {
                delete params.filterBy;
            }
            onChangeQuery(params);
        },
        {wait: 500}
    );

    const handleClear = useMemoizedFn((e) => {
        e.stopPropagation();
        let params = {...query};
        delete params[name];
        mutate(params).then((response) => {
            if (response.status === 200) {
                setBusinessKey("");
                onChangeQuery(params).then(() => {
                });
            }
        });
    });

    return (
        <div className="flex flex-col w-full">
            {titleLabel && (
                <Typography className={"text-grey"} variant={"subtitle1"}>
                    {titleLabel}
                </Typography>
            )}
            <div className={" bg-white flex items-stretch w-full truncate mt-1 h-45 overflow-hidden"}>
                <Translate>
                    {({translate}) => (
                        <TextField
                            variant={"outlined"}
                            size={"small"}
                            id="deploymentId-search-definition"
                            value={businessKey}
                            fullWidth
                            disabled={disabled || false}
                            placeholder={translate(`_.enter ${name} here...`)}
                            onChange={(e) => {
                                setBusinessKey(e.target.value);
                                run();
                            }}
                            endAdornment={
                                businessKey !== "" ? (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClear}>
                                            <Icon fontSize={"small"}>close</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    <></>
                                )
                            }
                        />
                    )}
                </Translate>
            </div>
        </div>
    );
}

export default TextFieldQuery;
