import useMatchingState from "./useMatchingState";
import {useMemo} from "react";
import {useMemoizedFn} from "ahooks";

function useIncomeStore() {
    const matchingOrdersLength = useMatchingState((state) => state.matchingOrdersLength);
    const onChangeMatchingOrdersLength = useMatchingState((state) => state.onChangeMatchingOrdersLength);
    const matchingOrdersSelected = useMatchingState((state) => state.matchingOrdersSelected);
    const onChangeMatchingOrdersSelected = useMatchingState((state) => state.onChangeMatchingOrdersSelected);
    const memoOrdersLength = useMemo(() => {
        return matchingOrdersLength;
    }, [matchingOrdersLength]);
    const memoOrdersSelected = useMemo(() => {
        return matchingOrdersSelected;
    }, [matchingOrdersSelected]);

    const handleChangeMatchingOrdersLength = useMemoizedFn((number) => {
        onChangeMatchingOrdersLength(number);
    });

    const handleChangeMatchingOrdersSelected = useMemoizedFn((selected) => {
        onChangeMatchingOrdersSelected(selected);
    });

    return {
        matchingOrdersCount: memoOrdersLength,
        matchingOrdersSelected: memoOrdersSelected,
        onChangeMatchingOrdersCount: handleChangeMatchingOrdersLength,
        onChangeMatchingOrdersSelected: handleChangeMatchingOrdersSelected,
    };
}

export default useIncomeStore;
