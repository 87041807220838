import {Icon, IconButton, Typography} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {MyTooltip} from "@ui";
import * as fuseActions from "@fuseActions";
import {Component} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import EventBus from "utils/EventBus";
import FaveButton from "../../../../components/layout/fav/FaveButton";
import axios, {crmPrefix} from "../../../../myaxios";
import * as activityActions from "../activities/store/actions";
import * as companyActions from "../companies/store/actions";
import * as contactActions from "../contacts/store/actions";
import * as dealActions from "../deals/store/actions";
import {getGridListAsycnNotificationName} from "../marketingTools";

class ItemHeader extends Component {
    deleteActivity = (item) => {
        if (this.props.company) {
            const notifiName = getGridListAsycnNotificationName("company-activity");
            // console.log('发送  通知 : ', notifiName);
            EventBus.dispatch(notifiName, notifiName);
        } else if (this.props.contact) {
            const notifiName = getGridListAsycnNotificationName("contact-activity");
            // console.log('发送  通知 : ', notifiName);
            EventBus.dispatch(notifiName, notifiName);
        }
        // this.props.turnOff('deleteactivity');
        this.props.getActivities(this.props.search);
        this.props.turnOff("activityDetail");
    };

    deleteCompany = (item) => {
        this.props.getCompanies(this.props.search);
        this.props.turnOff("companyDetail");
        if (this.props.match.params.id) {
            this.props.history.push("/marketing/companies");
        }
    };

    deleteContact = (item) => {
        if (this.props.company) {
            const notifiName = getGridListAsycnNotificationName("company-contact");
            EventBus.dispatch(notifiName, notifiName);
        } else if (this.props.activity) {
            const notifiName = getGridListAsycnNotificationName("activity-contact");
            EventBus.dispatch(notifiName, notifiName);
        }
        this.props.turnOff("contactDetail");
        this.props.selectContact(null);
        this.props.selectContactForEditCompany(null);
        this.props.getContacts(this.props.search);
    };

    deleteDeal = (item) => {
        if (this.props.company) {
            const notifiName = getGridListAsycnNotificationName("company-deal");
            EventBus.dispatch(notifiName, notifiName);
        } else if (this.props.contact) {
            const notifiName = getGridListAsycnNotificationName("contact-deal");
            EventBus.dispatch(notifiName, notifiName);
        }
        this.props.getDeals(this.props.search);
        this.props.turnOff("dealDetail");
    };

    deleteAction = () => {
        const {sourceItem, sourceType, selectedCompany, selectedActivity, selectedDeal, selectedContact} = this.props;
        const language = this.props.activeLanguage.code;
        // const tRequest = axios.delete(`${pmsPrefix}/task/${task.id}`);
        const itemName =
            language === "en"
                ? sourceType
                : sourceType === "company"
                    ? "公司"
                    : sourceType === "activity"
                        ? "活动"
                        : sourceType === "contact"
                            ? "客户联系人"
                            : "交易";
        const tTtile = language === "en" ? `Do you want to delete this ${itemName}?` : `确定要删除该${itemName}吗?`;
        const tConfirmButtonText = language === "en" ? "Delete" : "删除";
        const tSuccessM = language === "en" ? "Delete success!" : "删除成功!";
        const successFunc = (success) => {
            sourceType === "company"
                ? this.deleteCompany(sourceItem)
                : sourceType === "activity"
                    ? this.deleteActivity(sourceItem)
                    : sourceType === "contact"
                        ? this.deleteContact(sourceItem)
                        : this.deleteDeal(sourceItem);
            if (success instanceof Function) {
                success();
            }
        };
        const request = (success, failed) => {
            const tRequest =
                sourceType === "company"
                    ? axios.delete(`${crmPrefix}/company/${this.props.sourceItem.id}`)
                    : sourceType === "activity"
                        ? axios.delete(`${crmPrefix}/activity/` + this.props.sourceItem.id)
                        : sourceType === "contact"
                            ? axios.delete(`${crmPrefix}/companyContact/${this.props.sourceItem.id}`)
                            : axios.delete(`${crmPrefix}/deal/${this.props.sourceItem.id}`);
            tRequest
            .then((res) => {
                sourceType === "company"
                    ? this.deleteCompany(sourceItem)
                    : sourceType === "activity"
                        ? this.deleteActivity(sourceItem)
                        : sourceType === "contact"
                            ? this.deleteContact(sourceItem)
                            : this.deleteDeal(sourceItem);
                if (success instanceof Function) {
                    success();
                }
            })
            .catch(() => {
                if (failed instanceof Function) {
                    failed();
                }
            });
        };
        this.props.showMyDialog(tTtile, request, successFunc, tSuccessM, tConfirmButtonText);
        this.props.turnOn("MyDialog");
    };

    render() {
        const {sourceItem, selectItem, turnOn, sourceType, title, canEdit, actions, match, turnOff, noDelete} = this.props;
        return (
            <>
                <div className="flex-1">
                    <Breadcrumbs separator={<Icon children="chevron_right" />} aria-label="breadcrumb" className="mb-8">
                        <Typography className={"opacity/75"}>{<Translate id={"market.Marketing"} />}</Typography>
                        <Typography color="textPrimary">{<Translate id={`market.${sourceType}`} />}</Typography>
                    </Breadcrumbs>
                    <div className={"flex flex-row items-center"}>{title}</div>
                </div>
                <div className="flex md:flex-col md:items-end md:justify-end items-center mt-2 justify-between">
                    <div className="flex items-center">
                        {match.params.id ? (
                            <div className={"mr-2"}>
                                <IconButton size={"small"} onClick={() => this.props.history.goBack()}>
                                    <Icon className="text-4xl">arrow_back</Icon>
                                </IconButton>
                            </div>
                        ) : (
                            <IconButton className={"no-drag -mt-16 -mr-12"} onClick={() => turnOff(sourceType + "Detail")}>
                                <Icon>close</Icon>
                            </IconButton>
                        )}
                        {/*<Owner user={sourceItem.user}/>*/}
                    </div>
                    <div className={"relative  flex items-center"}>
                        {canEdit && (
                            <MyTooltip title={<Translate id="_.edit" />}>
                                <IconButton
                                    onClick={() => {
                                        selectItem(sourceItem);
                                        turnOn(sourceType);
                                    }}
                                >
                                    <Icon>edit</Icon>
                                </IconButton>
                            </MyTooltip>
                        )}

                        {canEdit && !noDelete && (
                            <MyTooltip title={<Translate id={"_.delete"} />}>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // selectItem(sourceItem);
                                        // turnOn(`delete${sourceType}`);
                                        this.deleteAction();
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </MyTooltip>
                        )}

                        <FaveButton sourceId={sourceItem.id} title={sourceItem.name} type={sourceType} />
                        {actions}
                    </div>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            turnOn: fuseActions.turnOn,
            turnOff: fuseActions.turnOff,

            getActivities: activityActions.getList,
            getCompanies: companyActions.getList,
            getDeals: dealActions.getList,
            showMyDialog: fuseActions.showMyDialog,
            getContacts: contactActions.getList,
            selectContact: contactActions.selectItem,
            selectContactForEditCompany: contactActions.selectItemForEditCompany
        },
        dispatch
    );
}

function mapStateToProps({fuse, marketing}) {
    return {
        dialogs: fuse.dialogs,
        company: marketing.companies.item,
        contact: marketing.companyContacts.item,
        activity: marketing.activities.item,
        deal: marketing.deals.item,
        selectedGridListType: marketing.labels.selectedGridListType,
        selectedActivity: marketing.activities.list.selected,
        selectedCompany: marketing.companies.list.selected,
        selectedDeal: marketing.deals.list.selected,
        selectedContact: marketing.companyContacts.list.selected,
        search: fuse.search
    };
}

export default withRouter(withLocalize(connect(null, mapDispatchToProps)(ItemHeader)));
