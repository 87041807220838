import * as Actions from "../actions";
import { cloneDeep } from "lodash";
const initialState = {
    /*
    * {
  "page": 0,
  "size": 0,
  "totalPages": 0,
  "totalElements": 0,
  "content": [
    {
      "id": "string",
      "description": "string",
      "importCount": 0,
      "incomeCount": 0,
      "user": {},
      "timestamp": 0
    }
  ]
}
    * */
    incomesResForManager: null,
    incomeDetails: null,
    selectedIncomesList: [],
    selectedIncomeForManager: null,
    selectedOrderForIncome: null,
    isManagerIncome: false,
    incomeMatchingInvoices: [],
    selectedNoHandlerRevenues: [],
    incomeMatching: [],
    selectedAutoMatchings: [],
    matchingOrdersCount: 0
};

const incomeSureManagerReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_INCOMES_FOR_MANAGER :{
            let tobj = cloneDeep(state);
            tobj.incomesResForManager = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     incomesResForManager: action.payload
            // };
        }
        case Actions.GET_NO_HANDLER_INCOMES_FOR_MANAGER :{
            let tobj = cloneDeep(state);
            tobj.incomeMatchingInvoices = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     incomeMatchingInvoices: action.payload
            // };
        }
        case Actions.GET_INCOME_DETAILS :{
            let tobj = cloneDeep(state);
            tobj.incomeDetails = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     incomeDetails: action.payload
            // };
        }
        case Actions.GET_INCOME_MATCHING :{
            let tobj = cloneDeep(state);
            tobj.incomeMatching = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     incomeMatching: action.payload
            // };
        }
        case Actions.GET_INCOME_MATCHING_LENGTH :{
            let tobj = cloneDeep(state);
            tobj.matchingOrdersCount = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     matchingOrdersCount: action.payload
            // };
        }
        case Actions.CLEAR_INCOME_MATCHING :{
            let tobj = cloneDeep(state);
            tobj.incomeMatching = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     incomeMatching: []
            // };
        }
        case Actions.SELECT_A_INCOME_FOR_MANAGER :{
            let tobj = cloneDeep(state);
            tobj.selectedIncomeForManager = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedIncomeForManager: action.payload
            // };
        }
        case Actions.SELECT_NO_HANDLER_INCOMES_FOR_MANAGER :{
            let tobj = cloneDeep(state);
            tobj.selectedNoHandlerRevenues = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedNoHandlerRevenues: action.payload
            // };
        }
        case Actions.SELECT_AUTO_MATCHING_REVENUES :{
            let tobj = cloneDeep(state);
            tobj.selectedAutoMatchings = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedAutoMatchings: action.payload
            // };
        }
        case Actions.SELECT_INCOMES_LIST_FOR_MANAGER :{
            let tobj = cloneDeep(state);
            tobj.selectedIncomesList = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedIncomesList: action.payload
            // };
        }
        case Actions.SELECT_A_ORDER_FOR_INCOME :{
            let tobj = cloneDeep(state);
            tobj.selectedOrderForIncome = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     selectedOrderForIncome: action.payload
            // };
        }
        case Actions.IS_MANAGER_INCOME :{
            let tobj = cloneDeep(state);
            tobj.isManagerIncome = action.payload;
            return tobj;
            // return {
            //     ...state,
            //     isManagerIncome: action.payload
            // };
        }
        default:{
            return state;
        }
    }
};

export default incomeSureManagerReducer;
