import {Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {useState} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as fuseActions from '../../../app/store/actions';
import axios, {userPrefix} from '../../../myaxios';

const Password = (props) => {

    const [passwordChecker, setPasswordChecker] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        const {oldPassword, newPassword, reEnterPassword} = e.target;
        if (newPassword.value !== reEnterPassword.value) {
            props.showMessage({
                variant: "error",
                message: "message.Passwords don't match"
            });
        } else {
            props.startLoading();
            const formData = {
                oldPassword: oldPassword.value,
                newPassword: newPassword.value,
                reEnterPassword: reEnterPassword.value
            };
            // this.setState({formData});
            axios.put(`${userPrefix}/password`, formData).then(() => {
                props.showMessage({
                    variant: "success",
                    message: "message.Password has been changed!"
                });
                props.finishLoading();
            });
        }
    };

    const checkPassword = (e) => {
        const value = e.target.value;
        // "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
        if (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value))
            setPasswordChecker(true);
        else
            setPasswordChecker(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                    <TextField
                        required
                        margin="normal"
                        label={<Translate id={"profile.Old password"} />} id={"oldPassword"}
                        name={"oldPassword"} type={"password"} fullWidth />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                    <TextField
                        required
                        onChange={checkPassword}
                        margin="normal"
                        label={<Translate id={"profile.New password"} />} id={"newPassword"}
                        name={"newPassword"} type={"password"} fullWidth

                        helperText={passwordChecker ? <span className={"text-success"}><Translate id={"profile.Strong enough"} /></span> :
                            <Translate id={"profile.Must be greater than 8 and contain letters and numbers"} />} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        required
                        margin="normal"
                        label={<Translate id={"profile.Re-enter new password"} />}
                        id={"reEnterPassword"}
                        name={"reEnterPassword"} type={"password"} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!passwordChecker} variant={"contained"} color={"secondary"}
                            type={"submit"}>
                        <Translate id="profile.Submit" />
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: fuseActions.showMessage,
        startLoading: fuseActions.startLoading,
        finishLoading: fuseActions.finishLoading
    }, dispatch);
}

export default withLocalize(connect(null, mapDispatchToProps)(Password));

