import {read} from "xlsx";
import {useMemoizedFn} from "ahooks";


function useSpreadsheetUtil() {

    const onParse =  useMemoizedFn((file) => {
        return  new Promise((resolve, reject)=>{
            file.arrayBuffer().then((f)=>{
                const wb = read(f, {
                    type: "array",
                    codepage: 65001,
                    WTF: true,
                    dense: true,
                    cellNF: true,
                    cellDates: true,
                    cellStyles: true,
                });
                // const wb = read(f);
                resolve(wb);
            }).catch((err)=>{
                console.log("file.arrayBuffer() error: ", err);
                reject();
            });
        });
    });

    const getSheetOptions = useMemoizedFn(()=>{
        return {
            showToolbar: false,
        };
    });


    return {
        onParse,
        getSheetOptions,
    };
}

export default useSpreadsheetUtil;
