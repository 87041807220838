import * as Actions from "../actions";
import {cloneDeep} from "lodash";

const initState = {
    definitionsResponse: null,
    selectedDefinition: null,
    currentDefinitionsStatus: "Active",
};

const definition_list = function (state=initState,action) {
    switch (action.type) {
        case Actions.GET_PROCESSDEFINES_LIST: {
            const obj = cloneDeep(state);
            obj.definitionsResponse = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     definitionsResponse: action.payload
            // }
        }
        case Actions.SELECT_A_PROCESSDEFINITION: {
            const obj = cloneDeep(state);
            obj.selectedDefinition = action.payload;
            return  obj;
            // return {
            //     ...state,
            //     selectedDefinition: action.payload
            // }
        }
        default:
            return  state;
    }
};

export default definition_list;
